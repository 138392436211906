import {useEffect, useState} from "react";
import {SessionState} from "sip.js";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    addOneToast,
    selectAllCalls,
    selectCurrentUserId,
    selectDragAndDropData,
    selectHuntGroups,
    selectPhoneSettingByKey,
    selectTransferringCall,
    selectUsersDictionary, setHasDevices,
    setTransferringCall
} from "../../redux/slices";
import {IHuntGroup} from "../../types";
import {useKeypadContext} from "../../context/KeypadContext/context";
import {useHeadsetContext} from "../../context/HeadsetContext/context";
import {YeaLinkOps} from "../../context/HeadsetContext/hooks";
import {useAuxCodeContext} from "../../pages/home/children/AuxCodeController/context";
import {usePutAuxCodeMutation} from "../../redux/services/sipApi";
import {useCallerDisplayNameGeneric, useNavigatorPermissions} from "../../helpers";

interface UseDragContactProps {
    handleKeypadToggle?: (val: boolean) => void
}

export const useDragContact = ({handleKeypadToggle}: UseDragContactProps) => {
    const dadData = useTypedSelector(selectDragAndDropData);
    const userDictionary = useTypedSelector(selectUsersDictionary);
    const huntGroups: IHuntGroup[] = useTypedSelector(selectHuntGroups) || [];
    const isSettingBlf = useTypedSelector(state => state.app.active_phone_tab === 'provision')

    const huntGroupsDictionary = {};
    huntGroups.forEach((g, i) => {
        huntGroupsDictionary[g.uuid] = huntGroups[i]
    })

    let dragContact: any = null;

    switch (true) {
        case !dadData:
            break;
        case !!dadData.extraData:
            dragContact = dadData.extraData
            break;
        case !!userDictionary?.[dadData?.id]:
            dragContact = userDictionary[dadData.id];
            break;
        case !!huntGroupsDictionary?.[dadData?.id]:
            dragContact = huntGroupsDictionary[dadData?.id]
            break;
        default:
            dragContact = null;
    }

    useEffect(() => {
        if (dadData && handleKeypadToggle && !isSettingBlf && dadData.id !== 'short_code') {
            handleKeypadToggle(true)
        }
    }, [dadData])

    return {dragContact}
}

export const useKeypad = () => {
    const {keypadState, useKeypadAction} = useKeypadContext();
    const {headset, handleOperation} = useHeadsetContext();

    const keepActiveCall = useTypedSelector(state => selectPhoneSettingByKey(state, 'keepActiveCall'));
    const transferringCall = useTypedSelector(selectTransferringCall);
    const calls = useTypedSelector(selectAllCalls);

    const dispatch = useTypedDispatch();

    const activeCall = calls.find(c => c.id === keypadState.activeCall);

    const handleKeypadToggle = (req: boolean) => {
        if (req && !keypadState.wasDragged && !keypadState.keypadActiveStatus) {
            useKeypadAction({
                type: 'SetActiveStatus',
                payload: true,
            })
        } else if (!req && !keypadState.wasDragged && keypadState.keypadActiveStatus) {
            useKeypadAction({
                type: 'SetActiveStatus',
                payload: false,
            })
        }
    };

    useEffect(() => {
        if (transferringCall?.call && !calls.some(call => call.id === transferringCall?.call)) {
            dispatch(
                setTransferringCall()
            )
        }

        if (calls.length < 2) {
            useKeypadAction({
                type: 'SetWidgetActive',
                payload: false,
            })
        }

        if (calls.length > 0 && [SessionState.Initial, SessionState.Establishing].includes(calls?.[calls.length - 1].state || SessionState.Terminated)) {
            handleKeypadToggle(true)
        }

        /**
         * Setting active call:
         * new call comes in:
         * If active is established, set active call to initial call
         * If active is not established, do nothing
         */
        switch (true) {
            case !!activeCall:
                if (activeCall && activeCall.state === SessionState.Established) {
                    const ringingCalls = calls.filter(c => c?.state && c.state === SessionState.Initial)

                    if (ringingCalls.length < 1) break;

                    if (!keepActiveCall) {
                        useKeypadAction({
                            type: 'SetActiveCall',
                            payload: ringingCalls[ringingCalls.length - 1].id,
                        })
                        break;
                    }

                    const found = ringingCalls.find(c => c.isOutbound);

                    if (found) {
                        useKeypadAction({
                            type: 'SetActiveCall',
                            payload: found.id,
                        })
                    }
                }
                break;
            case calls.length > 0:
                useKeypadAction({
                    type: 'SetActiveCall',
                    payload: calls[calls.length - 1].id,
                });
                break;
            case calls.length === 0:
                useKeypadAction({
                    type: 'SetActiveCall',
                    payload: '',
                });
                break;
        }
    }, [calls.length, calls[0]?.id]);

    useEffect(() => {
        if (!activeCall) {
            handleOperation(['onHook'], headset)
            return;
        }

        if (!keypadState.keypadActiveStatus) {
            useKeypadAction({
                type: 'SetReadyToClose',
                payload: true,
            })
        }

        if (activeCall.answered) {
            useKeypadAction({
                type: 'SetReadyToClose',
                payload: false,
            })
        }

        if (activeCall.state === SessionState.Initial && calls.length === 1) {
            handleOperation(['ring'], headset)
        } else {
            let opts: YeaLinkOps[] = [];

            if (activeCall.onHold) {
                opts.push('hold')
            }

            if (activeCall.isMuted) {
                opts.push('mute')
            }


            if (!activeCall.onHold && activeCall.isMuted) {
                opts.push('resume')
            }

            if (opts.length < 1) {
                opts = ['resume']
            }

            handleOperation(opts, headset)
        }
    }, [activeCall]);


    return {
        handleKeypadToggle
    }
}

export const useKeypadAux = () => {
    const {code_to_change_to, useAuxAction} = useAuxCodeContext();
    const {keypadState} = useKeypadContext();
    const calls = useTypedSelector(selectAllCalls)

    const activeCall = keypadState.activeCall ? calls.find(c => c.id === keypadState.activeCall) : undefined;

    const {
        name,
    } = useCallerDisplayNameGeneric(activeCall?.callee)

    const userUuid = useTypedSelector(selectCurrentUserId);

    const dispatch = useTypedDispatch();

    const [putAuxCode] = usePutAuxCodeMutation();

    const [auxCallWatch, setAuxCallWatch] = useState<{
        call: string,
        code: string
    } | undefined>();

    useEffect(() => {
        if (activeCall && code_to_change_to && !auxCallWatch) {
            setAuxCallWatch({
                call: activeCall.id,
                code: code_to_change_to
            })
            dispatch(addOneToast({
                type: 'info',
                title: "Queue Status",
                content: `Changing status to ${code_to_change_to} after call with ${name}`,
            }))
        }
    }, [code_to_change_to, activeCall, auxCallWatch]);

    useEffect(() => {
        if (auxCallWatch && !calls.some(c => c.id === auxCallWatch.call)) {
            setAuxCallWatch(undefined)
            putAuxCode({
                voip_user_uuid: userUuid,
                auxCode: {
                    code: auxCallWatch.code,
                    set_by: userUuid,
                }
            }).then(() => {
                dispatch(addOneToast({
                    type: 'success',
                    title: "Queue Status",
                    content: `Queue Status changed to ${code_to_change_to}`,
                }))
            })
            useAuxAction({
                type: "set_change_after_call_code",
            })
        }
    }, [keypadState.activeCall, calls?.length, auxCallWatch]);
}

export const useHasAudioDevice = () => {
    const microphonePermission = useNavigatorPermissions('microphone' as PermissionName).status;

    const dispatch = useTypedDispatch();

    useEffect(() => {
        switch (microphonePermission) {
            case 'denied':
                dispatch(setHasDevices(false))
                break;
            case 'prompt':
                navigator.mediaDevices
                    .getUserMedia({video: false, audio: true})
                    .catch((err) => {
                        console.error("Unable to receive microphone permission", err);
                        dispatch(setHasDevices(false))
                    });
                break;
            case 'granted':
                navigator.mediaDevices.enumerateDevices()
                    .then(r => {
                        let hasIn = false;
                        let hasOut = false;

                        for (let i = 0; i < r.length; i += 1) {
                            if (hasIn && hasOut) {
                                break;
                            }

                            const {kind, deviceId} = r[i];

                            if (kind === 'audioinput' && deviceId) {
                                hasIn = true;
                            }

                            if (kind === 'audiooutput' && deviceId) {
                                hasOut = true;
                            }
                        }

                        dispatch(setHasDevices(hasIn && hasOut));
                    })
                    .catch(e => {
                        console.error("Unable to enumerate devices", e);
                        dispatch(setHasDevices(false));
                    })
                break;
            default:
                if (!microphonePermission) {
                    return;
                }
                console.log('unknown Permission status ', microphonePermission)
        }

    }, [microphonePermission]);

}