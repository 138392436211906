import React, {useLayoutEffect} from "react";
import {useMessageContext} from "../../../context/MessageContext/context";
import {
    focusOnMessage,
    selectCurrentUserId,
} from "../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import styles from "./Reply.module.scss";
import {MessageSender} from "../ChatMessages/ChatMessage/MessageSender";
import {MarkdownContainer} from "../MarkdownComponents";
import {formatChatPreviewTime} from "../../../helpers";
import {selectMessageIds} from "../../../redux/slices/messageSlice";
import {useChatContext} from "../../../context/ChatContext/context";

interface Props {
    input?: boolean;
    scrollToBottom?: (val: 'smooth' | 'auto') => void;
}

const Reply: React.FC<Props> = ({input, scrollToBottom}) => {
    const {message} = useMessageContext();
    const {chat} = useChatContext();

    const messageIdsLength = useTypedSelector(state => selectMessageIds(state, chat.uuid, chat.active_chunk))?.length
    const userUuid = useTypedSelector(selectCurrentUserId);

    const dispatch = useTypedDispatch();

    let parentChatId;
    switch (message.to_type) {
        case 'channel':
            parentChatId = message.to;
            break;
        case 'user':
            if (message?.to?.includes(userUuid)) {
                parentChatId = message.from;
            } else {
                parentChatId = message.to
            }
            break;
    }

    useLayoutEffect(() => {
        if (scrollToBottom && (messageIdsLength || 1) < 4) {
            scrollToBottom('auto')
        }
    }, []);

    const goToMessage = () => {
        dispatch(focusOnMessage({chat_uuid: parentChatId, message}));
    }

    if (!message) {
        return null;
    }
    return (
        <div
            className={styles.reply_container}
            data-is-reply="true"
            onClick={() => goToMessage()}
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
            data-is-input={!!input}
        >
            <span className={styles.vertical_line}/>
            <div>
                <MessageSender/>
                {message.content ? (
                    <div
                        className={styles.message_content}

                    >
                        <p data-date-space={!message.files ? formatChatPreviewTime(message.time || '') : null}>
                            <MarkdownContainer
                                plain
                            >
                                {message.content}
                            </MarkdownContainer>
                        </p>
                    </div>
                ) : null}
                {message.files ? (
                    <div className={styles.attachments} data-date-space={formatChatPreviewTime(message.time || '')}>
                        <ul>
                            {message.files.map(file => (
                                <li
                                    key={`RF_${file.uuid}`}
                                >
                                    <p>
                                        {file.name}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
                <div className={styles.status}>
                    <p>
                        {formatChatPreviewTime(message.time || '')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Reply;
