import {IPhonebookContact} from "../../../../../types";

export interface ChatTagState {
    tagSearchVal?: string;
    selectedContact?: IPhonebookContact;
    selected: number;
    availableTags: number;
    toSelect?: number;
}

export const initialState: ChatTagState = {
    selected: 0,
    availableTags: 0,
}

export type ChatTagAction = {
    type: 'setTagSearchVal',
    payload?: string,
} | {
    type: 'setSelectedContact',
    payload?: IPhonebookContact
} | {
    type: 'clearState',
    payload?: never
} | {
    type: 'setSelected',
    payload: number,
} | {
    type: 'setAvailableTags',
    payload: number,
} | {
    type: 'setToSelect',
    payload?: number
}

export const reducer = (state: ChatTagState, {type, payload}: ChatTagAction): ChatTagState => {

    switch (type) {
        case "setTagSearchVal":
            return {
                ...state,
                tagSearchVal: payload
            }
        case "setSelectedContact":
            return {
                ...state,
                selectedContact: payload
            }
        case "clearState":
            return initialState
        case "setSelected":
            return {
                ...state,
                selected: payload
            }
        case "setAvailableTags":
            return {
                ...state,
                availableTags: payload
            }
        case "setToSelect":
            return {
                ...state,
                toSelect: payload
            }
        default:
            return state
    }
}