 import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {
    Department, ICallRoute, IHuntGroup,
    IPhonebook,
    IPhonebookContact,
    IPhoneNumber, IShortcode, ITimeDiary,
    IUser,
    LoadingTypes,
} from "../../../types";
import {phonebookEntityAdapter} from "./slice";

const phonebookEntitySelectors = phonebookEntityAdapter.getSelectors();

export const selectLoggedInUser = (state: RootState) => state.auth.loggedInUser;

export const selectSelectableUsers = (state: RootState) => state.auth.selectableUsers;

export const selectSelectableAccounts = (state: RootState) => state.auth.selectableAccounts;

export const selectCanSwitchUser = (state: RootState) => (state.auth.selectableUsers?.length || 0) > 1;

export const selectCurrentDevice = (state: RootState) => state.auth.loggedInUser?.device_uuid;

export const selectIsAus = (state: RootState) => state.auth.isAus;

export const selectAuthLoading = createSelector([(state:RootState) => state.auth.loading],
    (authLoading: string) => authLoading
);

export const selectAllPhonebookEntities = (state: RootState) => phonebookEntitySelectors.selectEntities(state.auth.phonebookEntities);

export const selectShortCodes = createSelector([
        (state: RootState) => state.auth.shortcodes
    ],
    (shortcodes: IShortcode[] | undefined) => shortcodes
)

export const selectAuthPhonebooks = createSelector([
        (state: RootState) => state.auth.phonebooks
    ],
    (phonebooks: IPhonebook[] | undefined) => phonebooks
)

export const selectHuntGroups = createSelector([
        (state: RootState) => state.auth?.huntGroups
    ],
    (huntGroups: IHuntGroup[] | undefined) => huntGroups
)

export const selectAllUsers = createSelector([
        (state: RootState) => state.auth?.users
    ],
    (users: IUser[] | undefined) => users
)

export const selectAuthNumbers = createSelector([
        (state: RootState) => state.auth.phoneNumbers
    ],
    (phoneNumbers: IPhoneNumber[] | undefined) => phoneNumbers
)

export const selectAuthCallRoutes = createSelector([
        (state: RootState) => state.auth.callRoutes
    ],
    (callRoutes: ICallRoute[] | undefined) => callRoutes
)

export const selectAuthTimeDiaries = createSelector([
        (state: RootState) => state.auth.timeDiaries
    ],
    (timeDiaries: ITimeDiary[] | undefined) => timeDiaries
)

export const selectUsersDictionary = createSelector([selectAllUsers],
    (users): Record<string, IUser> =>
        (users ? Object.fromEntries(users.map(user => [user.uuid, user])) : {})
);

export const selectAuthUserByUuid = createSelector([selectUsersDictionary, (_: RootState, uuid?: string) => uuid],
    (authUsers: Record<string, IUser>, uuid?: string): IUser | undefined =>
        uuid ? authUsers[uuid] : undefined
);

export const selectPhonebookEntityInArr = createSelector([
    (state: RootState) => state.auth.phonebookEntities,
    (_: any, numbers: string[]) => numbers
], (entities, numbers) => {
    for (let i = 0; i < numbers.length; i += 1) {
        const found = phonebookEntitySelectors.selectById(entities, numbers[i])
        if (found) {
            return found
        }
    }
    return undefined
})

const selectAllowedPhonebooks = createSelector([
        (state: RootState) => state.user.allowed_phone_books
    ],
    (allowedPhonebooks: string[] | undefined) => allowedPhonebooks
)

export const selectUsablePhonebooks = createSelector([
        selectAuthPhonebooks,
        (state: RootState) => state.user.restrict_phone_books,
        selectAllowedPhonebooks
    ],
    (
        allPhonebooks: IPhonebook[] | undefined,
        restrictPhonebooks: boolean,
        allowedPhonebooks: string[] | undefined
    ): IPhonebook[] => {
        if (!restrictPhonebooks || !(Array.isArray(allPhonebooks))) {
            return allPhonebooks || [];
        }

        if (!Array.isArray(allowedPhonebooks)) {
            return []
        }

        return allPhonebooks.filter(pb => allowedPhonebooks.includes(pb.uuid));
    }
)

export const selectAuthNumberDictionary = createSelector([
        selectAuthNumbers
    ], (numbers: IPhoneNumber[] | undefined): Record<string, string> =>
        Object.fromEntries((numbers || []).map(n => [n.uuid, n.name]))
)
export const selectPhonebookDictionary = createSelector([
        selectUsablePhonebooks
    ], (phonebooks: IPhonebook[]): Record<string, string> =>
        Object.fromEntries((phonebooks || []).map(n => [n.uuid, n.name]))
)

export const selectCallRouteDictionary = createSelector([
        selectAuthCallRoutes
    ], (routes: ICallRoute[] | undefined): Record<string, string> =>
        Object.fromEntries((routes || []).map(callRoute => [callRoute.uuid, callRoute.name]))
)

export const selectTimeDiaryDictionary = createSelector([
        selectAuthTimeDiaries
    ], (diaries: ITimeDiary[] | undefined): Record<string, string> =>
        Object.fromEntries((diaries || []).map(d => [d.uuid, d.name]))
)

export const selectIsFetchingPhonebooks = createSelector([(state: RootState) => state.auth.phonebookContactsLoading],
    (phonebookContactsLoading: Record<string, LoadingTypes | undefined>): boolean =>
        Object.values(phonebookContactsLoading).includes('pending')
);

export const selectAllPhonebookContacts = createSelector([(state: RootState) => state.auth.phonebookContacts],
    (phonebookContacts: Record<string, IPhonebookContact[] | undefined>): IPhonebookContact[] =>
        Object.values(phonebookContacts).filter(Boolean).flat() as IPhonebookContact[]
)

export const selectAllDepartments = (state: RootState): Department[] | undefined => state.auth?.departments;

export const selectDepartmentByUuid = createSelector([
        selectAllDepartments,
        (_: any, departmentId: string) => departmentId,
    ], (departments: Department[] | undefined, departmentId: string): Department | undefined =>
        departments?.find(department => department.uuid === departmentId)
)

export const selectDepartmentByUserUuid = createSelector([
        selectAllDepartments,
        (_: any, userUuid: string) => userUuid,
    ], (departments: Department[] | undefined, userUuid: string): Department[] | undefined =>
        departments?.filter(dept => dept.users.includes(userUuid))
)


export const selectDepartmentDictionary = createSelector([
    selectAllDepartments,
], (departments: Department[] | undefined): Record<string, Department> =>
    Object.fromEntries(
        (departments || []).map(department => [department.uuid, department])
    ))

export const selectUserExtensionsToNickDict = createSelector([
        selectAllUsers
    ], (users: IUser[] | undefined): Record<number, { name: string, uuid: string, sip_name: string }> =>
        Object.fromEntries(
            (users || []).map(user => [user.extension, {name: user.nickname, uuid: user.uuid, sip_name: user.name}])
        )
)

const selectContactsOnBook = createSelector([
        (state: RootState) => state.auth.phonebookContacts,
        (_: any, bookUuid: string) => bookUuid
    ],
    (phonebookContacts: Record<string, IPhonebookContact[] | undefined>, bookUuid: string): undefined | IPhonebookContact[] =>
        phonebookContacts[bookUuid]
)

export const selectContactInBook = createSelector([
        selectContactsOnBook,
        (_: any, __: any, contactUuid: string) => contactUuid
    ],
    (phonebookContacts: undefined | IPhonebookContact[], contactUuid: string): undefined | IPhonebookContact => {
        if (!phonebookContacts) {
            return undefined;
        }

        return phonebookContacts.find(c => c.uuid === contactUuid)
    }
)

export const selectIsTenantUser = createSelector([
    selectUsersDictionary,
    (_: any, userUuid: string) => userUuid
], (users, userUuid) => !users[userUuid])