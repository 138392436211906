import React, { useEffect } from 'react';
import MenuHeader from '../../menus/MenuHeader';
import Icon, { IconName } from '../../Icon';
import {
    logout,
    resetUser,
    selectCanConfigLineKeys,
    selectCanSwitchUser,
    selectCurrentProvisioningMeta,
    selectSettingsPage,
    selectSupportPhoneNumber,
    setProvisioningOpen,
    startCall,
    updateSettingsPage
} from '../../../redux/slices';
import { SettingPageType } from '../../../types';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { usePolyglot } from '../../../context/Polyglot';
import styles from './styles.module.scss';
import AriaButton from '../../AriaComponents/AriaButton';
import { useHotkeyContext } from '../../../context/HotKeysContext/context';
import AriaText from '../../AriaComponents/AriaText';
import { useAppContext } from '../../../context/AppContext/AppContext';
import { isPreview } from '../../../helpers';
import UserAgentChecker from '../../buttons/UserAgentChecker';

export const SettingsList: React.FC = () => {
    const hardwarePhone = useTypedSelector(selectCurrentProvisioningMeta);
    const supportNumberState = useTypedSelector(selectSupportPhoneNumber);
    const canSwitchUser = useTypedSelector(selectCanSwitchUser);
    const settingsPage = useTypedSelector(selectSettingsPage);
    const canConfigLineKeys = useTypedSelector(selectCanConfigLineKeys);

    const { hotKeyNavigate } = useHotkeyContext();

    const { t } = usePolyglot();

    const { isYay, packageVersion, siteVersion } = useAppContext();

    const dispatch = useTypedDispatch();

    const handlePageChange = (e: any, newPage: SettingPageType) => {
        dispatch(updateSettingsPage(newPage)).then(() => {
            if (e.pointerType === 'keyboard') {
                hotKeyNavigate('main');
            }
        });
    };

    useEffect(
        () => () => {
            dispatch(updateSettingsPage('account'));
        },
        []
    );

    const supportNumber: string | undefined = isYay ? '+44330 122 6000' : supportNumberState;

    const options: {
        page: SettingPageType;
        name: string;
        icon: IconName;
        activeIcon: IconName;
    }[] = [
        {
            page: 'account',
            name: t('terms.account_and_general'),
            icon: 'settingsAccount',
            activeIcon: 'settingsAccountFilled'
        },
        {
            page: 'calls',
            name: t('terms.call', 2),
            icon: 'settingsCalls',
            activeIcon: 'settingsCallsFilled'
        },
        {
            page: 'chat',
            name: t('terms.chat', 2),
            icon: 'settingsChat',
            activeIcon: 'settingsChatFilled'
        },
        {
            page: 'notifications',
            name: t('terms.notification', 2),
            icon: 'settingsNotifications',
            activeIcon: 'settingsNotificationsFilled'
        },
        {
            page: 'contacts',
            name: t('terms.contact', 2),
            icon: 'settingsContacts',
            activeIcon: 'settingsContactsFilled'
        },
        {
            page: 'audio',
            name: isPreview ? `${t('terms.audio')} & ${t('terms.video', 1)}` : t('terms.audio'),
            icon: 'settingsAudio',
            activeIcon: 'settingsAudioFilled'
        }
    ];

    return (
        <div className={styles.container}>
            <MenuHeader title={t('terms.setting', 2)} />
            <div className={styles.options}>
                {options.map(option => (
                    <AriaButton
                        key={`SP_${option.page}`}
                        onClick={e => handlePageChange(e, option.page)}
                        dataTags={{
                            'data-active': settingsPage === option.page ? 'active' : null
                        }}
                    >
                        <AriaText text='Settings' />
                        <div className={styles.optionIcon}>
                            <Icon
                                name={
                                    settingsPage === option.page ? option.activeIcon : option.icon
                                }
                            />
                        </div>
                        <p>{option.name}</p>
                    </AriaButton>
                ))}
            </div>
            <div className={styles.extraOptions}>
                {canConfigLineKeys ? (
                    <AriaButton
                        onClick={() => {
                            dispatch(setProvisioningOpen(true));
                        }}
                        disabled={!hardwarePhone}
                        title={
                            hardwarePhone
                                ? `Provision your ${hardwarePhone.device_name}`
                                : `You do not have any configurable
handsets registered on 
your account.

If you have a handset it may not
be configurable via the app yet.`
                        }
                    >
                        <p>{t('phrases.line_key_configuration')}</p>
                    </AriaButton>
                ) : null}
                <AriaButton onClick={e => handlePageChange(e, 'shortcodes')}>
                    <p>{t('terms.short_code', 2)}</p>
                </AriaButton>
                {supportNumber ? (
                    <AriaButton
                        onClick={() => {
                            dispatch(startCall(supportNumber, t('phrases.customer_support')));
                        }}
                    >
                        <p className={styles.warning}>{t('phrases.call_customer_support')}</p>
                    </AriaButton>
                ) : null}
                {canSwitchUser ? (
                    <AriaButton
                        className={styles.userSwitchBtn}
                        onClick={() => dispatch(resetUser())}
                    >
                        <p>{t('actions.switch_user')}</p>
                        <Icon name='switchUser' width={24} height={24} />
                    </AriaButton>
                ) : null}
                <AriaButton onClick={() => dispatch(logout())} className={styles.logout}>
                    <p>{t('actions.logout')}</p>
                    <Icon name='logout' ariaHidden />
                </AriaButton>
            </div>
            <div className={styles.version}>
                <UserAgentChecker />
                {packageVersion ? (
                    <>
                        <p>Site: {siteVersion}</p>
                        <p>Package: v{packageVersion}</p>
                    </>
                ) : (
                    <p>{siteVersion}</p>
                )}
            </div>
        </div>
    );
};

export default SettingsList;
