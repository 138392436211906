/* eslint-disable import/no-extraneous-dependencies */
// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

// App Begins
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './redux/store';
import './sass/master.scss';
import App from './App';
import AppProvider from './context/AppContext/AppContext';

function isIE() {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones */
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}

const sentryConfig = {
    dsn: window.location.hostname.includes('localhost')
        ? ''
        : 'https://3f38e90f5b494cee9ccbaa60fb8acbd5@o44967.ingest.sentry.io/5509624',
    environment: process.env.NODE_ENV,
    release: `Desktop-app@${process.env.REACT_APP_RELEASE_VERSION}`,
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 0,
    beforeBreadcrumb(breadcrumb) {
        return breadcrumb.category !== 'ui.click' ? null : breadcrumb;
    },
    maxBreadcrumbs: 5,
    tracesSampleRate: 0.1
};

Sentry.init(sentryConfig);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

function render() {
    if (!isIE()) {
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </Provider>
            </React.StrictMode>
        );
    }
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
