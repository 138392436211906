import React from "react";
import {usePolyglot} from "../../context/Polyglot";
import {AriaCheckbox, AriaCheckboxGroup} from "../AriaComponents";
import StyledDropdown from "../StyledComponents/StyledDropdown";
import {StyledButton} from "../StyledComponents";

export interface Filter {
    title: string;
    value: string;
    disabled?: boolean;
}

interface Props {
    filter: string[];
    filters: Filter[][];
    onChange: (val: string) => void;
    reset?: () => void;
    children?: JSX.Element;
    label: string
}

type FilterOpt = {
    label: string,
    value: string,
    divider: boolean,
    reset: boolean,
    disabled?: boolean,
}

export const Filters: React.FC<Props> = ({filter, filters, onChange, reset, children, label}) => {

    const {t} = usePolyglot()

    const filterOpts: FilterOpt[] =
        filters.flat().map((f, idx) => ({
            label: f.title,
            value: f.value,
            divider: idx === 0,
            reset: false,
            disabled: f.disabled || false,
        }))

    if (reset) {
        filterOpts.unshift({
            label: t("phrases.reset_filters"),
            value: '',
            divider: false,
            reset: true,
            disabled: false,
        })
    }

    return (
        <AriaCheckboxGroup
            onChange={(e) => {
                const dif = e
                    .filter(x => !filter.includes(x))
                    .concat(filter.filter(x => !e.includes(x)));
                onChange(dif[0])
            }}
            value={filter}
            label={label}
        >
            <StyledDropdown
                className='menu-header__filters'
                options={filterOpts}
                placeHolder={t('actions.filter')}
                component={(props: FilterOpt) => {
                    if (props.reset && reset) {
                        return (
                            <StyledButton
                                buttonStyle='text'
                                onClick={reset}
                                size='small'
                                context='danger'
                                className='menu-header__remove-filters'
                            >
                                <p>
                                    {t("phrases.reset_filters")}
                                </p>
                            </StyledButton>
                        )
                    }

                    if (props.divider) {
                        if (props.label === 'misc') {
                            return null;
                        }
                        return (
                            <p>
                                {props.label}
                            </p>
                        )
                    }

                    return (
                        <AriaCheckbox
                            value={props.value}
                            key={props.value}
                            name={`${props.label} checkbox`}
                            isDisabled={props.disabled}
                        >
                            {props.label}
                        </AriaCheckbox>
                    )
                }}
            >
                {children}
            </StyledDropdown>
        </AriaCheckboxGroup>
    )
}
