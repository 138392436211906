import React, {Fragment, ReactNode, useEffect, useRef} from 'react';
import {motion} from 'framer-motion/dist/framer-motion';
import AriaButton from "../AriaComponents/AriaButton";
import Icon from "../Icon";
import {StyledDropdownOption} from "./StyledDropdown";
import styles from './styles.module.scss';
import {useOnClickOutside} from "../../helpers";

export type StyledListBaseProps<T> = ({
    options: StyledDropdownOption[];
    onSelect?: (val: string) => void;
    selected?: string | string[];
    component?: never;
} | {
    options: T[];
    onSelect?: never;
    selected?: never;
    component: React.FC<T>;
} | {
    options: {
        label: string;
        value: T;
    }[];
    onSelect: (val: T) => void;
    selected?: never;
    component?: never;
}) & SharedProps;

interface SharedProps {
    direction?: 'right'
    children?: ReactNode;
    className?: string;
    setDropdownOpen?: (val: boolean) => void;
    closeList?: () => void
}

const StyledList = <T, >({
                             options,
                             onSelect,
                             selected,
                             component,
                             direction,
                             children,
                             className,
                             setDropdownOpen,
                             closeList
                         }: StyledListBaseProps<T>) => {

    const minWidth = useRef<number>();
    const listRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (listRef.current) {
            minWidth.current = listRef.current.offsetWidth
        }
    }, []);

    useOnClickOutside(listRef, () => {
        if (closeList) {
            closeList()
        }
    })

    return (
        <motion.ul
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
            style={minWidth.current ? {minWidth: minWidth.current} : null}
            data-menu-direction={direction || null}
            className={[styles.styled_list, className].join(' ')}
            ref={listRef}
        >
            {options.map((opt, idx) => {

                if (component) {
                    return (
                        <Fragment key={opt.value || idx}>
                            {component(opt, () => setDropdownOpen && setDropdownOpen(false))}
                        </Fragment>
                    )
                }

                let isSelected;

                if (typeof selected === 'string' && selected === opt.value) {
                    isSelected = true
                }

                if (Array.isArray(selected) && selected.includes(opt.value)) {
                    isSelected = true
                }

                const title = typeof opt.label === 'string' ? opt.label : '';

                if (opt.divider) {
                    return (
                        <p key={`l-${opt.label}-${idx}`}>
                            {opt.label}
                        </p>
                    )
                }

                return (
                    <AriaButton
                        key={opt.value || idx}
                        dataTags={{
                            'data-selected': isSelected || null,
                            'data-is-large': opt.large || null,
                            'data-is-danger': opt.danger || null,
                        }}
                        onClick={(e) => {
                            if (opt.onSelect) {
                                opt.onSelect()
                            } else if (onSelect) {
                                onSelect(opt.value)
                            }
                            if (!e.shiftKey && setDropdownOpen) {
                                setDropdownOpen(false)
                            }
                        }}
                        title={`Select ${title}`}
                        disabled={opt.disabled}
                    >
                        {typeof opt.label === 'string' ? (
                            <p>
                                {opt.label}
                            </p>
                        ) : opt.label}
                        {isSelected ? (
                            <Icon name='tick'/>
                        ) : null}
                    </AriaButton>
                )
            })}
            {children}
        </motion.ul>
    );
}

export default StyledList;
