import React, {FunctionComponent, useMemo} from 'react';
import {Department} from "../../../types";
import {useTypedSelector} from "../../../redux/hooks";
import {selectFavouriteContacts, selectUsersDictionary} from "../../../redux/slices";
import ContactList from "./ContactList";
import {ContactDisplayItem} from "./ContactListHooks";

interface Props {
    departments: Department[];
    searchVal?: string;
    favourites: ContactDisplayItem[],
    outerDisplay: ContactDisplayItem[],
    getStrForCDI: (val: ContactDisplayItem) => string,
    apiContactsLoading: boolean,
}

const DepartmentContacts: FunctionComponent<Props> = ({
                                                          departments,
                                                          searchVal,
                                                          favourites,
                                                          outerDisplay,
                                                          getStrForCDI,
                                                          apiContactsLoading,
                                                      }) => {
    const userDictionary = useTypedSelector(selectUsersDictionary);
    const favouriteContacts: Record<string, boolean> = useTypedSelector(selectFavouriteContacts) || {};

    const departmentMembers: ContactDisplayItem[] = useMemo(() => {
        if (!departments) return [];

        const uuidArr: Set<string> = new Set();

        departments.forEach(dept => {
            dept.users.forEach(u => uuidArr.add(u))
        })

        const memberArr: ContactDisplayItem[] = [];

        uuidArr.forEach(u => {
            const usr = userDictionary[u]

            if (
                usr
                && !(searchVal && !usr.nickname.toLowerCase().includes(searchVal))
            ) {
                if (!favouriteContacts[usr.uuid]) {
                    memberArr.push({
                        type: 1,
                        data: usr
                    })
                }
            }
        })

        memberArr
            .sort((a, b) => {
                if (a.type !== 1 || b.type !== 1) return 0;

                return a.data.nickname.localeCompare(b.data.nickname)
            })

        return memberArr;
    }, [departments.length, favouriteContacts, searchVal]);

    return (
        <ContactList
            key='departments_contact_list'
            searchQuery={searchVal || ""}
            favourites={favourites}
            mainDisplay={departmentMembers}
            outerDisplay={outerDisplay}
            initialLoad={false}
            apiContactsLoading={apiContactsLoading}
            getStrForCDI={getStrForCDI}
        />
    );
};

export default DepartmentContacts;
