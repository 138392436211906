import React from 'react';
import {IChat, isIntegration, IThread} from '../../types';
import {useTypedSelector} from "../../redux/hooks";
import {selectChatById, selectCurrentUserId} from "../../redux/slices";
import Icon, {IconName} from "../Icon";
import {usePolyglot} from "../../context/Polyglot";
import {useMessageContext} from "../../context/MessageContext/context";

interface Props {
    chat: IChat | IThread;
}

export const ChatMessageStatusIcon: React.FC<Props> = ({chat}) => {
    const {message} = useMessageContext()
    let parentChat: IChat | undefined;


    const userUuid = useTypedSelector(selectCurrentUserId);
    if (chat.type === 'thread' && message.to) {
        parentChat = useTypedSelector(state => selectChatById(state, message.to))
    }

    const { t } = usePolyglot();

    if (!message || message?.from !== userUuid || isIntegration(chat)) return null;

       // The member.user id and userUuid are the same, so the totalDelivered will be always 0 that why tick status are not working properly
    const totalDelivered = message.delivered?.filter(member => member.user !== userUuid)?.length || 0;
    const totalRead = message.read?.filter(member => member.user !== userUuid)?.length || 0;

    let totalParticipants: number | undefined;
    let iconName: IconName = 'singleTick';
    let status = "sent";

    const iconTitleDictionary = {
        "sent": t("phrases.message_sent"),
        "delivered": t("phrases.message_delivered"),
        "read": t("phrases.message_read")
    }

    switch (chat.type) {
        case 'user':
            if (totalDelivered === 1) {
                iconName = 'doubleTick';
                status = "delivered";
            }

            if (totalRead === 1) {
                iconName = 'doubleTick';
                status = "read";
            }
            break

        case 'channel':
            if (totalDelivered > 0) {
                iconName = 'doubleTick';
                status = "delivered";
            }

            // if all channel members have read the message show the read status
            totalParticipants = chat.channel?.members.filter(member => member.member !== userUuid).length

            if (totalRead === totalParticipants) {
                iconName = 'doubleTick';
                status = "read";
            }
            break

        case 'thread':
            if (!parentChat) break;

            // depending on parent chat type set participants number
            if (parentChat.type === 'user') {
                totalParticipants = 1;
            } else if (parentChat.channel) {
                totalParticipants = parentChat.channel?.members.filter(member => member.member !== userUuid).length
            }

            if (totalDelivered > 0) {
                iconName = 'doubleTick';
                status = "delivered";
            }

            if (totalRead === totalParticipants) {
                iconName = 'doubleTick';
                status = "read";
            }
    }

    // return <i className={iconClassName} title={iconTitle}/>;
    return (
        <span title={iconTitleDictionary[status]} data-is-read={status === 'read' ? 'true' : 'false'}>
            <Icon name={iconName} width={28} height={28} ariaHidden def/>
        </span>
    )
};
