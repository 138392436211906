import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    GlobalSettings,
    GlobalSettingsDictionaryName,
    IApp,
    IPhonebookContact,
    PhonePageType,
    SettingPageType,
    SmartDiallerError,
    ViewContactType
} from '../../../types';
import { PageTypes } from '../../../types/navigation';
import { IToast } from '../../../components/toastCenter';
import { updateMutedChat } from '../userSlice';
import { sipApi } from '../../services/sipApi';

const initialState: IApp = {
    active_page: 'phone',
    active_phone_tab: 'history',
    apiOnlyPhonebookUuids: [],
    drag_and_drop: null,
    editPhonebookContact: undefined,
    focus_on_department: '',
    global_settings: {
        do_not_disturb: false,
        selected_phonebooks: {},
        favourite_contact: {},
        pinned_chats: {},
        muted_chats: {},
        mark_chat_unread: {},
        sync_dnd_to_phones: false,
        attended_transfer: false
    },
    global_settings_updated: false,
    grid_view: false,
    has_devices: false,
    provisioning_open: false,
    settings_page: 'account',
    smartDiallerError: undefined,
    smart_dial: { apps: [], location_ids: [], registrations: [] },
    status_subscriptions: [],
    toasts: [],
    viewContact: undefined
};

export const appSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateActivePage: (state, { payload }: PayloadAction<PageTypes>): IApp => ({
            ...state,
            active_page: payload
        }),
        updateActivePhoneTab: (state, { payload }: PayloadAction<PhonePageType>): IApp => ({
            ...state,
            active_phone_tab: payload
        }),
        updateSettingsPage: (state, { payload }: PayloadAction<SettingPageType>): IApp => ({
            ...state,
            settings_page: payload
        }),
        updateGridView: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            grid_view: payload
        }),
        updateDragAndDrop: (state, { payload }: PayloadAction<any>): IApp => ({
            ...state,
            drag_and_drop: payload
        }),
        cancelDragAndDrop: state => ({
            ...state,
            drag_and_drop: null
        }),
        addOneToast: (state, { payload }: PayloadAction<IToast>): IApp => ({
            ...state,
            toasts: [...state.toasts, payload]
        }),
        removeOneToast: (state, { payload }: PayloadAction<string>): IApp => ({
            ...state,
            toasts: state.toasts.filter(t => t.id !== payload)
        }),
        setProvisioningOpen: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            provisioning_open: payload
        }),
        setStatusSubscription: (state, { payload }: PayloadAction<string[]>): IApp => ({
            ...state,
            status_subscriptions: payload
        }),
        setFocusOnDepartment: (state, { payload }: PayloadAction<string | undefined>): IApp => ({
            ...state,
            focus_on_department: payload
        }),
        setGlobalSettings: (state, { payload }: PayloadAction<GlobalSettings>): IApp => ({
            ...state,
            global_settings: payload
        }),
        setViewContact: (state, { payload }: PayloadAction<ViewContactType | undefined>): IApp => ({
            ...state,
            viewContact: payload
        }),
        setEditPhonebookContact: (
            state,
            { payload }: PayloadAction<IPhonebookContact | undefined>
        ): IApp => ({
            ...state,
            editPhonebookContact: payload
        }),
        updateSpecificGlobalSetting: (
            state,
            {
                payload
            }: PayloadAction<{
                section: GlobalSettingsDictionaryName;
                key: string;
                value: boolean;
            }>
        ): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                [payload.section]: {
                    ...state.global_settings[payload.section],
                    [payload.key]: payload.value
                }
            },
            global_settings_updated: true
        }),
        setAllSpecificGlobalSetting: (
            state,
            {
                payload
            }: PayloadAction<{
                section: GlobalSettingsDictionaryName;
                record: Record<string, boolean>;
            }>
        ): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                [payload.section]: payload.record
            },
            global_settings_updated: true
        }),
        mergeSpecificGlobalSetting: (
            state,
            {
                payload
            }: PayloadAction<{
                section: GlobalSettingsDictionaryName;
                record: Record<string, boolean>;
            }>
        ): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                [payload.section]: {
                    ...state.global_settings[payload.section],
                    ...payload.record
                }
            },
            global_settings_updated: true
        }),
        updateGlobalDnd: (
            state,
            { payload }: PayloadAction<{ dnd: boolean; from_sip?: boolean }>
        ): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                do_not_disturb: payload.dnd
            },
            global_settings_updated: payload.from_sip ? state.global_settings_updated : true
        }),
        updateDndPhoneSync: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                sync_dnd_to_phones: payload
            },
            global_settings_updated: true
        }),
        updateAttendedTransfer: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                attended_transfer: payload
            },
            global_settings_updated: true
        }),
        mergeGlobalSettings: (
            state,
            { payload }: PayloadAction<Partial<GlobalSettings>>
        ): IApp => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                selected_phonebooks: {
                    ...state.global_settings.selected_phonebooks,
                    ...payload.selected_phonebooks
                },
                favourite_contact: {
                    ...state.global_settings.favourite_contact,
                    ...payload.favourite_contact
                },
                pinned_chats: {
                    ...state.global_settings.pinned_chats,
                    ...payload.pinned_chats
                },
                muted_chats: {
                    ...state.global_settings.muted_chats,
                    ...payload.muted_chats
                },
                mark_chat_unread: {
                    ...state.global_settings.mark_chat_unread,
                    ...payload.mark_chat_unread
                }
            },
            global_settings_updated: true
        }),
        setGlobalSettingsUpdates: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            global_settings_updated: payload
        }),
        setSmartDiallerError: (
            state,
            { payload }: PayloadAction<SmartDiallerError | undefined>
        ): IApp => ({
            ...state,
            smartDiallerError: payload
        }),
        addOneApiOnlyPhonebook: (state, { payload }: PayloadAction<string>): IApp => ({
            ...state,
            apiOnlyPhonebookUuids: [
                ...(state?.apiOnlyPhonebookUuids?.filter(i => i !== payload) || []),
                payload
            ]
        }),
        removeOneApiOnlyPhonebook: (state, { payload }: PayloadAction<string>): IApp => ({
            ...state,
            apiOnlyPhonebookUuids: [...(state.apiOnlyPhonebookUuids || [])].filter(
                i => i !== payload
            )
        }),
        setHasDevices: (state, { payload }: PayloadAction<boolean>): IApp => ({
            ...state,
            has_devices: payload
        }),
        setSmartDialLocations: (state, { payload }: PayloadAction<string[]>): IApp => ({
            ...state,
            smart_dial: {
                ...state.smart_dial,
                location_ids: payload
            }
        })
    },
    extraReducers: builder => {
        builder.addCase(updateMutedChat.fulfilled, (state, { payload }) => ({
            ...state,
            global_settings: {
                ...state.global_settings,
                muted_chats: {
                    ...state.global_settings.muted_chats,
                    [payload.key]: payload.value
                }
            },
            global_settings_updated: true
        }));
        builder.addMatcher(
            sipApi.endpoints.getSmartDiallerDevices.matchFulfilled,
            (state, { payload: { apps, registrations } }) => ({
                ...state,
                smart_dial: {
                    apps,
                    registrations,
                    location_ids:
                        state.smart_dial.location_ids ||
                        registrations.reduce(
                            (acc: string[], curr) => acc.concat(curr.location_id),
                            []
                        )
                }
            })
        );
    }
});

export const app = appSlice.reducer;
