import {AppDispatch, AppThunk} from "../../store";
import {videoSlice} from "./slice";
import {VideoPage} from "../../../types/video";


export const setVideoPage =
    (page: VideoPage): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(videoSlice.actions.setVideoPage(page));

export const setActiveRoomId =
    (uuid: string | null): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(videoSlice.actions.setActiveRoomId(uuid));