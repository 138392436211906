import React, {FunctionComponent, useRef, useState} from 'react';
import {FormProvider} from "react-hook-form";
import formStyles from "../../../sass/globalModules/forms.module.scss";
import Icon from "../../Icon";
import {useOnClickOutside} from "../../../helpers";
import {useChatGroupEditor} from "./hooks";
import styles from './styles.module.scss';
import {StyledButton} from "../../StyledComponents";
import GroupGeneralEdit from "./GroupGeneralEdit";
import StyledTabs from "../../StyledComponents/StyledTabs";
import {useChatContext} from "../../../context/ChatContext/context";
import GroupMemberEdit from "./GroupMembersEdit";
import {usePolyglot} from "../../../context/Polyglot";

interface Props {
    closeEditor: () => void;
}


const ChatGroupForm: FunctionComponent<Props> = ({
                                                     closeEditor,
                                                 }) => {
    const {chat} = useChatContext();

    const [selectedTab, setSelectedTab] = useState<'general' | 'members'>('general');

    const editorRef = useRef<HTMLDivElement>(null);

    const {t} = usePolyglot()

    useOnClickOutside(editorRef, closeEditor)

    const {
        formMethods,
        editGroup,
    } = useChatGroupEditor({
        closeEditor
    })

    return (
        <div
            className={[formStyles.formContainer, styles.container].join(' ')}
            ref={editorRef}
        >
            <section className={formStyles.formHeader}>
                <StyledButton
                    buttonStyle='text'
                    onClick={() => closeEditor()}
                    iconOnly
                >
                    <Icon name='arrowLeft'/>
                </StyledButton>
                <h2>
                    {
                        chat.display_name
                    }
                </h2>
            </section>
            <StyledTabs
                options={[
                    {
                        label: 'General',
                        value: 'general'
                    },
                    {
                        label: 'Members',
                        value: 'members'
                    },
                ]}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form
                    onSubmit={formMethods.handleSubmit(editGroup)}
                >
                    {selectedTab === 'general' ? (
                        <GroupGeneralEdit/>
                    ) : null}
                    {selectedTab === 'members' ? (
                        <GroupMemberEdit/>
                    ) : null}
                    <div className={formStyles.formControls}>
                        <StyledButton
                            buttonStyle='primary'
                            size='large'
                            type="submit"
                            disabled={!Object.values(formMethods.formState.dirtyFields).includes(true)}
                        >
                            <p>
                                {t("actions.save")}
                            </p>
                        </StyledButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
};

export default ChatGroupForm;
