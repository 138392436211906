import React, {ReactNode, useRef} from 'react';
import {List, AutoSizer, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import moment from "moment/moment";
import {useHeightObserver} from "../../../helpers";
import {useAllCallHistory} from "./CallHistoryHooks";
import CallHistorySkeleton from "../../../components/Skeletons/CallHistorySkeleton";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    getCallHistory,
    selectCallHistoryLoading,
    selectCallHistoryWeek,
    selectCurrentUserSipName
} from "../../../redux/slices";
import {useCallHistoryKeyDown} from "./CallHistoryRow/hooks";
import AriaListFocusContainer from "../../../components/AriaComponents/AriaListFocusContainer";
import {StyledButton} from "../../../components/StyledComponents";
import CallHistoryRow from "./CallHistoryRow";

interface CallHistoryProps {
    missedCallsOnly?: boolean;
    search?: string;
    filter?: string[]
}

const CallHistoryRender = ({call}) => {
    if (!call) return null

    if (call.type === 'divider') {
        return (
            <div className='list-divider'>
                <p>{call.data}</p>
            </div>
        );
    }

    return (
        <CallHistoryRow call={call}/>
    )
}

const GetMoreHistoryBtn = () => {
    const callHistoryWeek = useTypedSelector(selectCallHistoryWeek);
    const userSipName = useTypedSelector(selectCurrentUserSipName);
    const gettingHistory = useTypedSelector(selectCallHistoryLoading);

    const dispatch = useTypedDispatch();

    return (
        <div
            className='call_history_loader'
        >
            <StyledButton
                buttonStyle='tertiary'
                onClick={() => dispatch(getCallHistory({
                    username: userSipName,
                    week: callHistoryWeek
                }))}
                loading={gettingHistory === 'pending'}
                iconOnly={gettingHistory === 'pending'}
            >
                {moment.utc().subtract((7 * callHistoryWeek), 'days').format('DD MMM YYYY')}
            </StyledButton>
        </div>
    )
}

export const CallHistory: React.FC<CallHistoryProps> = ({
                                                            missedCallsOnly,
                                                            search = '',
                                                            filter = [],
                                                        }) => {
    const gettingHistory = useTypedSelector(selectCallHistoryLoading);

    const heightRef = useRef<HTMLDivElement>(null);
    const handleKeyDown = useCallHistoryKeyDown();

    const {listHeight} = useHeightObserver(heightRef);

    const callsHistoryFiltered = useAllCallHistory({
        searchQuery: search,
        filter,
        missedCallsOnly,
    })

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 69,
    });

    const rowRenderer = ({
                             key,
                             index,
                             style,
                             parent
                         }) => {
        const call = callsHistoryFiltered[index];

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div
                    style={style}
                    onKeyDown={(e) => handleKeyDown(e)}
                >
                    <CallHistoryRender call={call}/>
                    {index === callsHistoryFiltered.length - 1 ? (
                        <GetMoreHistoryBtn/>
                    ) : null}
                </div>
            </CellMeasurer>
        )
    }

    let display: ReactNode;

    switch (true) {
        case gettingHistory === 'pending' && (!callsHistoryFiltered || callsHistoryFiltered.length < 1):
            display = <CallHistorySkeleton/>
            break;
        case (filter.length > 0 || !!search) && callsHistoryFiltered.length < 1:
            display =
                (
                    <div className='phone_page_empty_display'>
                        <p>No Call History Matches Your Search</p>
                        <span>
                    No call history to display, make a call or load historic data.
                </span>
                        <GetMoreHistoryBtn/>
                    </div>
                )
            break;
        case callsHistoryFiltered.length > 0:
            display =
                <AutoSizer>
                    {({width}) => (
                        <List
                            width={width - 1}
                            height={listHeight}
                            rowCount={callsHistoryFiltered?.length}
                            rowRenderer={rowRenderer}
                            overscanRowCount={3}
                            rowHeight={cache.rowHeight}
                            deferredMeasurementCache={cache}
                            className='windowed-call-history-list-container'
                            tabIndex={-1}
                        />
                    )}
                </AutoSizer>
            break;
        default:
            display = (
                <div className='phone_page_empty_display'>
                    <p>No Call History</p>
                    <span>
                    No call history to display, make a call or load historic data.
                </span>
                    <GetMoreHistoryBtn/>
                </div>
            )
    }

    return (
        <AriaListFocusContainer
            className='list-container call-history__list'
            ref={heightRef}
            title='Call history'
        >
            {
                display
            }
        </AriaListFocusContainer>
    );
};

export default CallHistory;