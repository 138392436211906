import { polyglot} from "../lib/polyglot.lib";

export default function getDefaultISO() {
    switch (polyglot.locale()) {
        // case 'es':
        //     return 'ES'
        // case 'fr':
        //     return 'FR'
        case 'pl':
            return 'PL'
        default:
            return 'GB'
    }
}