import {CallTabOptions} from "../../components/keypad/displays/CallEstablishedKeypad/CallEstablishedKeypad";

export interface CallContextState {
    speakerActive: boolean;
    currentTab: CallTabOptions;
    membersTabOpen: boolean;
}

export const initialState: CallContextState = {
    speakerActive: false,
    currentTab: 'options_menu',
    membersTabOpen: false
}


export type CallAction =
    {
        type: 'set_speaker_active',
        payload: boolean
    } |
    {
        type: 'set_current_tab',
        payload: CallTabOptions,
    } |
    {
        type: 'set_member_tab_open',
        payload: boolean
    };

export const reducer = (state: CallContextState, action: CallAction): CallContextState => {

    switch (action.type) {
        case "set_speaker_active":
            return {
                ...state,
                speakerActive: action.payload,
            }
        case "set_current_tab":
            return {
                ...state,
                currentTab: action.payload
            }
        case "set_member_tab_open":
            return {
                ...state,
                membersTabOpen: action.payload
            }
        default:
            return state
    }
}