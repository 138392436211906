import { createSelector, EntityState } from '@reduxjs/toolkit';
import { CountryCode } from 'libphonenumber-js';
import { RootState } from '../../store';
import {
    AppPageFilters,
    IAppMetaSettings,
    IChat,
    IChatSettings,
    ILoggedInUser,
    IPhoneSettings,
    SmartDiallerNumber,
    SmartDiallerSelected
} from '../../../types';

export const selectCurrentUser = createSelector(
    [(state: RootState): ILoggedInUser => state.user],
    user => user
);

export const selectCurrentUserSipName = (state: RootState): string => state.user.name;

export const selectCurrentUserNickname = (state: RootState) => state.user.nickname;

export const selectCurrentUserSipPassword = (state: RootState) => state.user.password;

export const selectCurrentCallerId = (state: RootState): string => state.user.caller_id;

const selectPhoneSettings = (state: RootState) => state.user.settings.phone.settings;

const selectChatSettings = (state: RootState) => state.user.settings.chat.settings

const selectAppSettings = (state: RootState) => state.user.settings.appMeta.settings

export const selectAppDisableDnd = (state: RootState) => state.user.app_disable_dnd;

export const selectCallHandling = (state: RootState) => state.user.call_handling;

export const selectDashboardUrl = (state: RootState) => state.user.dashboard_url;

export const selectGlobalCallRecording = (state: RootState): boolean | undefined => state.user.global_call_recording;

export const selectAppDisableHuntGroups = (state: RootState) => state.user.app_hide_hunt_groups;

export const selectAppAllowConference = (state: RootState): boolean => state.user.app_allow_conference || false;

export const selectAppAllowMeet = (state: RootState): boolean => state.user.app_allow_meet || false;

export const selectCallHandlingSimple = (state: RootState) => state.user.call_handling_simple;

export const selectCurrentUserId = (state: RootState): string => state.user.uuid;

export const selectUserCountryCode = (state: RootState): CountryCode => state.user.country_code;

export const selectDevices = (state: RootState) => state.user.devices;

export const selectChatEnabled = (state: RootState) => state.user.chat_enabled;

export const selectCanAddFriend = (state: RootState) => state.user.chat_config?.can_add_friend || false;

export const selectPageFilters = (state: RootState) => state.user.settings.appMeta.filters;

export const selectSupportPhoneNumber = (state: RootState) => state.user.support_phone_number;

export const selectSmartDiallerSelected = (state: RootState): SmartDiallerSelected | undefined => state.user.settings.appMeta.smartDiallerSelection;

export const selectSmartDiallerNumbers = (state: RootState): SmartDiallerNumber[] | undefined => state.user.settings.appMeta.smartDiallerNumbers;

export const selectCanConfigLineKeys = (state: RootState): boolean => state.user.can_config_line_keys;

export const selectCanCreateFeedbackTags = (state: RootState): boolean => state.user.can_create_feedback_tag;

export const selectPersonalMailbox = (state: RootState): string | undefined => state.user.personal_mailbox;

export const selectFiltersByPage = createSelector([selectPageFilters, (_: RootState, key: keyof AppPageFilters) => key],
    (pageFilters: AppPageFilters, key: keyof AppPageFilters): string[] => pageFilters?.[key]
);

export const selectPhoneSettingByKey = createSelector([selectPhoneSettings, (_: RootState, key: keyof IPhoneSettings) => key],
    (phoneSettings: IPhoneSettings, key: keyof IPhoneSettings): any => phoneSettings?.[key]
);

export const selectChatSettingByKey = createSelector([selectChatSettings, (_: RootState, key: keyof IChatSettings) => key],
    (chatSettings: IChatSettings, key: keyof IChatSettings): any => chatSettings?.[key]
);

export const selectAppSettingByKey = createSelector([selectAppSettings, (_: RootState, key: keyof IAppMetaSettings) => key],
    (appSettings: IAppMetaSettings, key: keyof IAppMetaSettings): any => appSettings?.[key]
);

export const selectActiveChat = createSelector([(state: RootState): EntityState<IChat> => state.chat.chats.entities, (state: RootState): string | undefined => state.chat.activeChat],
    (chatEntities: EntityState<IChat>, uuid: string | undefined) =>
        (chatEntities && uuid) ? chatEntities[uuid] : undefined
)

export const selectUserAuth = createSelector([
        selectCurrentUserSipName,
        selectCurrentUserSipPassword
    ],
    (username: string, password: string) => ({
        username,
        password
    })
)

// export const selectCanMakeCall = createSelector([
//     selectHasDevices,
//     selectSmartDiallerSelected
// ], (hasDevice, selectedDialler): boolean => hasDevice || !!selectedDialler)