import React, {useEffect, useState} from 'react';
import {clearTimeout} from "timers";
import styles from '../keypad/Keypad.module.scss'
import Icon from '../Icon'
import KeypadIcon from "../Icon/KeypadIcons";
import {usePolyglot} from "../../context/Polyglot";

interface HangupProps {
    onClick: () => void;
    showText?: boolean;
    cancel?: boolean;
}

export const Hangup: React.FC<HangupProps> = ({ onClick, showText = false , cancel}) => {
    const [clicked, setClicked] = useState<boolean>(false);

    const { t } = usePolyglot();

    const clickHandler = () => {
        onClick();

        if (!clicked) {
            setClicked(true);
        }
    };

    useEffect(() => {
        let timeout;
        if (clicked) {
            timeout = setTimeout(() => {
                setClicked(false);
                return () => timeout && clearTimeout(timeout)
            }, 500)
        }
    }, [clicked])

    return (
        <button
            type='button'
            className={styles.hangupButton}
            onMouseDown={e => e.stopPropagation()}
            onTouchStart={clickHandler}
            onClick={clickHandler}
            disabled={clicked}
        >
            {cancel ? (
                <KeypadIcon name='kDecline' />
                ) : (
                <Icon name="phoneDecline" width={45} height={45}/>
                )}
            {showText && (<p>{t("actions.decline")}</p>)}
        </button>
    );
};
