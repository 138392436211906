import { createAsyncThunk } from '@reduxjs/toolkit';
import store, { AppDispatch, AppThunk } from '../../store';
import {
    CallFeedbackTag,
    ICall,
    ICallState,
    IHuntGroup,
    IMailboxMessage,
    InterTenantClient,
    InterTenantUser,
    IPhonebookContact,
    IUser,
    IYeOldeCall,
    MailboxDisplay,
    RoomMember,
    TransferringCall,
    voicemailValidator
} from '../../../types';
import { api } from '../../../api';
import { callsSlice } from './slice';
import { selectPersonalMailbox } from '../userSlice';
import { selectAllCalls, selectVoicemailByUuid } from './selectors';
import { conferenceSockets } from '../../../components/keypad/ConferenceHooks';
import { SIPLogin } from '../../middleware/sipMiddleware';

export const getCallState = (data: IUser): ICallState | undefined => {
    if (!data) return undefined;

    return {
        callFeedback: {
            enabled: data.collect_call_feedback || false,
            show: false,
            forms: []
        },
        callHistory: [],
        callHistoryFillerLoading: 'idle',
        callHistoryLoading: 'idle',
        callHistoryWeek: 1,
        call_recording: data.call_recording || false,
        callerIds: [],
        callerIdsLoading: 'idle',
        calls: [],
        conferenceInvitations: [],
        feedback_tags: [],
        global_call_recording: data.global_call_recording || false,
        inter_tenant_clients: {},
        last_called: undefined,
        mailbox_gotten: {},
        mailbox_messages: {},
        mailboxes: [],
        parkingSlotsLoading: 'idle',
        transferring_call: undefined,
        usersForGroupCall: []
    };
};

export const setShowFeedbackForm =
    (payload: boolean): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.setShowFeedbackForm(payload));
    };

export const deleteAllFeedbackForms = (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(callsSlice.actions.deleteAllFeedbackForms());
};

export const deleteSingleFeedbackForm =
    (inviteId: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.deleteSingleFeedbackForm(inviteId));
    };

export const connectSip =
    (details: SIPLogin): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/connect',
            payload: details
        });

export const startCall =
    (
        callee: string,
        displayName?: string,
        beingTransferredTo?: string,
        tenant?: string
    ): AppThunk =>
    (dispatch: AppDispatch) => {
        const state = store.getState();
        const callWithTransfer = state.sip?.calls?.find(call => call.beingTransferred);
        // const canCall = selectCanMakeCall(state);
        //
        // if (!canCall) {
        //     dispatch(addOneToast({
        //         type: 'error',
        //         content: `Unable to find valid microphone.
        //             Please check your microphone permissions or
        //             select a device to call through in the dial pad.`,
        //         title: "Unable to Initialise Call",
        //     }))
        //
        //     return;
        // }

        if (callWithTransfer !== undefined) {
            dispatch(
                setTransferringCall({
                    call: callWithTransfer.id,
                    transferringData: {
                        callee,
                        displayName,
                        beingTransferredTo
                    }
                })
            );
        } else {
            dispatch({
                type: 'sip/call',
                payload: { callee, displayName, beingTransferredTo, tenant }
            });
        }
    };

export const acceptCall =
    (callId: string): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/answer',
            payload: callId
        });

export const endCall =
    (callId: string): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/end-call',
            payload: callId
        });

export const toggleMuteCall =
    (callId: string, muted: boolean): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/toggle-mute',
            payload: { callId, muted }
        });

export const toggleHoldCall =
    (callId: string, onHold: boolean): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/toggle-hold',
            payload: { callId, onHold }
        });

export const blindTransferCall =
    (callee: string, numberToTransferTo: string, callId: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: 'sip/blind-transfer',
            payload: {
                callee,
                secondCallee: numberToTransferTo,
                callId
            }
        });
    };

export const attendedTransferCall =
    (currentCallId: string, originalCallId: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: 'sip/attended-transfer',
            payload: {
                currentCallId,
                originalCallId
            }
        });
    };

export const sendDTMF =
    (callId: string, dtmf: string): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: 'sip/send-dtmf',
            payload: { callId, dtmf }
        });

export const setBeingTransferred =
    (callId: string, beingTransferred: boolean): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(
            callsSlice.actions.setBeingTransferred({
                callId,
                beingTransferred
            })
        );
    };

// export const setFullscreenCall =
//     (callId?: string, time?: number, user?: IUser): AppThunk =>
//     (dispatch: AppDispatch) =>
//         dispatch(
//             callsSlice.actions.setFullscreenCall(
//                 callId && time && user ? { callId, time, user } : undefined
//             )
//         );
//
// export const showShortcodePage =
//     (showPage: boolean): AppThunk =>
//     (dispatch: AppDispatch) =>
//         dispatch(callsSlice.actions.showShortcodePage(showPage));
//
// export const updateDeviceList =
//     (devices: IDevice[]): AppThunk =>
//     (dispatch: AppDispatch) =>
//         dispatch(callsSlice.actions.updateDeviceList(devices));

export const addUserForGroupCall =
    (user: IUser | IPhonebookContact | IHuntGroup): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.addUserForGroupCall(user));

export const removeUserForGroupCall =
    (uuid: string): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.removeUserForGroupCall(uuid));

export const clearUserForGroupCall = (): AppThunk => (dispatch: AppDispatch) =>
    dispatch(callsSlice.actions.clearUserForGroupCall());

export const updateCallDirect =
    (updates: Partial<ICall>): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.updateCall(updates));

export const updateCallHistoryItem =
    (updates: Partial<IYeOldeCall>): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.updateCallHistoryItem(updates));

export const setFeedbackTags =
    (tags: CallFeedbackTag[]): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.setFeedbackTags(tags));

export const updateCallRecording =
    (bool: boolean): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.updateCallRecording(bool));

export const groupMemberJoined =
    ({ roomId, newMember }: { roomId: string; newMember: RoomMember }): AppThunk =>
    (dispatch: AppDispatch) => {
        const state = store.getState();
        const call = state.sip?.calls.find((c: ICall) => c.roomId === roomId);
        const userUuid = state.user.uuid;

        const found = call.roomMembers?.find(u => u.user === newMember.user) || undefined;
        if (found) {
            dispatch(callsSlice.actions.removeGroupMember({ roomId, userUuid: found.id }));
        }

        if (newMember.user === userUuid) {
            dispatch(
                callsSlice.actions.updateCall({
                    id: call.id,
                    localRoomId: newMember.id
                })
            );
        } else {
            dispatch(callsSlice.actions.groupMemberJoined({ roomId, newMember }));
        }
    };

export const groupMemberLeft =
    ({ roomId, userUuid }: { roomId: string; userUuid: string }): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.groupMemberLeft({ roomId, userUuid }));
    };

export type DeACKRoomRinger =
    | {
          roomId: string;
          callId: string;
          ref?: never;
      }
    | {
          roomId: string;
          callId?: never;
          ref: string;
      };

export const deactivateGroupRinger =
    (data: DeACKRoomRinger): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.deactivateGroupRinger(data));
    };

export const updateCallGroupMember =
    ({
        callId,
        memberId,
        changes
    }: {
        callId: string;
        memberId: string;
        changes: Record<string, any>;
    }): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.updateCallGroupMember({ callId, memberId, changes }));
    };

export const newCall =
    (val: Partial<ICall>): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.newCall(val as ICall));

// export const invalidateConferenceCall =
//     (roomId: string): AppThunk =>
//     (dispatch: AppDispatch, getState) => {
//         const state = getState();
//         const calls = selectAllCalls(state);
//
//         if (!calls) {
//             return;
//         }
//
//         const found = calls.find(c => c.roomId === roomId && c.state !== SessionState.Established);
//
//         if (found) {
//             dispatch(endCall(found.id));
//         }
//     };

// export const addConferenceInvitation =
//     (val: RoomInvite): AppThunk =>
//     (dispatch: AppDispatch) =>
//         dispatch(callsSlice.actions.addConferenceInvitation(val));

export const removeConferenceInvitation =
    (val: string): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.removeConferenceInvitation(val));

export const setTransferringCall =
    (val?: TransferringCall): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.setTransferringCall(val));

export const setCallHistoryWeek =
    (val: number): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(callsSlice.actions.setCallHistoryWeek(val));

export const getCallHistory = createAsyncThunk(
    '/get-call-history',
    async (
        {
            username,
            week,
            page = 1
        }: {
            username: string;
            week: number;
            page?: number;
        },
        { dispatch }
    ) => {
        const [responseFrom, responseTo] = await Promise.all([
            api.getCallHistory({
                username,
                call_from: true,
                week,
                page
            }),
            api.getCallHistory({
                username,
                call_to: true,
                week,
                page
            })
        ]);

        const res = (responseFrom.data?.result || []).concat(responseTo.data?.result || []);

        // if (Math.max(responseFrom.data?.total_pages || 0, responseTo.data?.total_pages || 0) > page) {
        //     console.log('rec')
        //     dispatch(getCallHistory({username, week, page: page + 1}))
        // }
        //
        // if (page <= 1) {
        dispatch(setCallHistoryWeek(week + 1));
        // }

        return res;
    }
);

export const getCallHistoryFiller = createAsyncThunk(
    '/get-call-history-filler',
    async (
        {
            username,
            startTimestamp
        }: {
            username: string;
            startTimestamp: number;
        },
    ) => {
        const responseFrom = await api.getCallHistoryFiller({
            username,
            startTimestamp
        });

        return responseFrom?.data?.result || [];
    }
);

export const getCallerIds = createAsyncThunk('/get-caller-ids', async (userUuid: string) => {
    const response = await api.getCallerIds(userUuid);
    return response.data;
});

export const getParkingSlots = createAsyncThunk('/api/get-parking-slots', async () => {
    const response = await api.getParkingSlots();
    return response.data;
});

export const moveCallToFollow = createAsyncThunk(
    '/put-follow-me-call',
    async ({ callSipId, target }: { callSipId: string; target: string }) => {
        const response = await api.moveCallToFollow({ callSipId, target });
        return response.data?.result;
    }
);

export const returnCallToDevice = createAsyncThunk(
    '/put-follow-me-back',
    async (callSipId: string) => {
        const response = await api.moveCallToFollow({ callSipId });
        return response.data?.result;
    }
);

export const addVoicemailBoxes =
    (mailboxes: MailboxDisplay[]): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.addVoicemailBoxes(mailboxes));
    };

export const addManyVoicemail =
    (mailbox_uuid: string, voicemails: IMailboxMessage[]): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.addManyVoicemails({ mailbox_uuid, voicemails }));
    };

export const removeOneVoicemail =
    (mailbox_uuid: string, voicemail: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.removeOneVoicemail({ mailbox_uuid, voicemail }));
    };

export const setVoicemailGotten =
    (gotten: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.setVoicemailGotten(gotten));
    };

export const addOneVoicemail =
    (mailbox_uuid: string, voicemail: IMailboxMessage): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.addOneVoicemail({ mailbox_uuid, voicemail }));
    };

export const setVoicemailSeen =
    (mailbox_uuid: string, voicemailUuid: string): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(
            callsSlice.actions.updateOneVoicemail({
                mailbox_uuid,
                id: voicemailUuid,
                changes: {
                    is_new: false
                }
            })
        );
    };

export const handleNewVoicemail =
    (mailbox_uuid: string, messageUuid: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const state = getState();

        // If message already in app, do nothing
        if (selectVoicemailByUuid(state, mailbox_uuid, messageUuid)) {
            return;
        }

        const mailboxUuid = selectPersonalMailbox(state);
        if (!mailboxUuid) {
            return;
        }

        await api
            .getSingleVoicemail({
                mailboxUuid,
                messageUuid
            })
            .then(r => {
                const newMessage = r.data.result;
                const isValid = voicemailValidator.safeParse(newMessage);

                if (!isValid.success) {
                    return;
                }

                dispatch(
                    addOneVoicemail(mailbox_uuid, {
                        ...newMessage,
                        is_new: true
                    })
                );
            });
    };

export const addUsersToTenant =
    (tenantUuid: string, users: InterTenantUser[]): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.addUsersToTenant({ tenantUuid, users }));
    };

export const addInterTenantClient =
    (client: InterTenantClient): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(callsSlice.actions.addInterTenantClient(client));
    };

// export const setLastCalledNumber =
//     (callee: LastCalled | undefined): AppThunk =>
//         (dispatch: AppDispatch) => {
//             dispatch(callsSlice.actions.setLastCalledNumber(callee))
//         }

export const leaveConferenceCall =
    (socketId: string | undefined, callId?: string): AppThunk =>
    (dispatch: AppDispatch, getState) => {
        const allCalls = selectAllCalls(getState());

        let call: ICall | undefined;

        if (callId) {
            call = allCalls.find(c => c.id === callId);
        }

        if (socketId) {
            const socRef = conferenceSockets[socketId];

            if (socRef) {
                socRef.socket.call('leave_room', {}, { onOne: true, noId: true }).then(() => {
                    socRef.socket.close(1000, 'leaving room');
                    socRef.peerConnection.getSenders().forEach(sender => {
                        socRef.peerConnection.removeTrack(sender);
                    });
                    delete conferenceSockets[socketId];
                });
            }

            call = call || allCalls.find(c => c.socketId === socketId);
        }

        if (!call) return;

        (window as any).electron?.send('set-touch-bar', { type: 'clear' });
        dispatch(
            callsSlice.actions.callTerminating({
                id: call.id
            })
        );

        setTimeout(() => {
            if (call) {
                dispatch(
                    callsSlice.actions.callTerminated({
                        id: call.id
                    })
                );
            }
        }, 1500);
    };
