/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import {useTypedSelector} from "../../../redux/hooks";
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import styles from '../SettingsPage.module.scss';
import {IShortcode} from "../../../types";
import {
    selectAuthNumberDictionary,
    selectCallerIdDictionary,
    selectCallRouteDictionary,
    selectTimeDiaryDictionary
} from "../../../redux/slices";
import {useShortcodeList} from "../../../helpers";

interface ShortcodesProps {
}

export const useShortCodeName = () => {
    const {t} = usePolyglot();

    const shortcodeNames: {
        [key: string]: {
            name: string;
            displayName: string
        };
    } = {
        assigncallroute: {
            name: t("phrases.assign_call_route"),
            displayName: t("phrases.change_active_call_route_on_number")
        },
        barge: {
            name: t("actions.barge"),
            displayName: t("phrases.join_live_call_taking_place_on_an_extension")
        },
        globalpickup: {
            name: t("phrases.global_pickup"),
            displayName: t("phrases.pick_up_any_incoming_call")
        },
        listen: {
            name: t("actions.listen"),
            displayName: t("phrases.listen_to_an_extension")
        },
        parkretrieve: {
            name: t("phrases.park_retrieve"),
            displayName: t("phrases.park_or_resume_a_call")
        },
        pickup: {
            name: t("actions.pick_up"),
            displayName: t("phrases.pick_up_an_incoming_call_to_an_extension")
        },
        playsound: {
            name: t("phrases.play_sound"),
            displayName: t("phrases.play_an_audio_file_to_the_caller")
        },
        record: {
            name: t("actions.record"),
            displayName: t("phrases.record_new_audio_file")
        },
        setcallerid: {
            name: t("phrases.set_caller_id"),
            displayName: t("phrases.modify_outgoing_caller_id")
        },
        togglequeueavailable: {
            name: t("phrases.toggle_queue_group_availability"),
            displayName: t("phrases.toggle_queue_group_availability_for_extension")
        },
        togglerecording: {
            name: t("phrases.toggle_recording"),
            displayName: t("phrases.pause_and_resume_call_recording")
        },
        whisper: {
            name: t("actions.whisper"),
            displayName: t("phrases.whisper_to_the_user_on_extension")
        },
        clearqueuetimeout: {
            name: t("phrases.clear_queue_timeout"),
            displayName: t("phrases.clears_your_queue_timeout_to_allow_answering")
        },
        pickupqueue: {
            name: 'Pick Up Queue',
            displayName: 'Pick Up an incoming call to selected queue'
        },
        hotdesk: {
            name: 'Hot Desk',
            displayName: 'Log in or out from a hot desk phone'
        },
        paging: {
            name: 'Paging',
            displayName: 'Page hunt group on an extension'
        },
        loginasuser: {
            name: 'Call queue agent login',
            displayName: 'Log in as a call queue agent',
        },
        logoutall: {
            name: 'Call queue agent logout',
            displayName: 'Log out from all call queue agents',
        },
    };

    return shortcodeNames;
}

export const Shortcode = ({shortcode}) => {
    const {t} = usePolyglot();

    const numbersDictionary = useTypedSelector(selectAuthNumberDictionary);
    const callerIdDictionary = useTypedSelector(selectCallerIdDictionary);
    const callRouteDictionary = useTypedSelector(selectCallRouteDictionary);
    const timeDiariesDictionary = useTypedSelector(selectTimeDiaryDictionary);

    const shortcodeNames = useShortCodeName();

    const shortcodeFormatter = ({feature}: IShortcode): JSX.Element => {
        switch (feature.feature) {
            case 'SetCallerid':
                return (
                    <p>
                        {(feature.callerid && callerIdDictionary[feature.callerid]?.cli_display) ? (
                            t("phrases.modify_outgoing_caller_id_to_%name", {
                                name: callerIdDictionary[feature.callerid]?.cli_display
                            })) : t("phrases.modify_outgoing_caller_id_to_unknown")}
                        {(feature.callerid && callerIdDictionary[feature.callerid]?.cli_name) ? (
                            t("phrases.modify_outgoing_caller_id_to_%name", {
                                name: callerIdDictionary[feature.callerid]?.cli_name
                            })) : t("phrases.modify_outgoing_caller_id_to_unknown")}
                    </p>
                );

            case 'ParkRetrieve':
                return (
                    <p>
                        {t("phrases.park_or_retrieve_call_in_parking_slot", {slot: feature.slot})}
                    </p>
                );

            case 'assigncallroute':
                return (
                    <p>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {feature.time_diary && timeDiariesDictionary[feature.time_diary] ? (
                            t("phrases.change_active_call_route_%name_to_time_diary", {
                                name: feature.number ? numbersDictionary[feature.number] : ''
                            })
                        ) : feature.call_route && callRouteDictionary[feature.call_route] ? (
                            t("phrases.change_active_call_route_%name_to_call_route", {
                                name: feature.number ? numbersDictionary[feature.number] : ''
                            })
                        ) : (<span>{t("phrases.none_set")}</span>)}
                        <span>
                            {(feature.time_diary && timeDiariesDictionary[feature.time_diary]) ||
                                (feature.call_route && callRouteDictionary[feature.call_route]) ||
                                null}
                        </span>
                    </p>
                );

            default:
                break;
        }

        return <p>{shortcodeNames[feature.feature.toLowerCase()]?.displayName || t("adjective.shortcode_unknown")}</p>;
    };
    return (
        <div key={shortcode.uuid} className={styles.shortcode}>
            <h2>{shortcode.short_code}</h2>
            <div>
                <p>
                    {shortcodeNames[shortcode.feature.feature.toLowerCase()]?.name || t("adjective.shortcode_unknown")}
                </p>
                <div>
                    {shortcodeFormatter(shortcode)}
                </div>
            </div>
        </div>
    )
}

export const Shortcodes: React.FC<ShortcodesProps> = () => {
    const {t} = usePolyglot();

    const {displayShortcodes} = useShortcodeList()

    return (
        <div className={styles.container}>
            <MenuHeader
                title={t("terms.short_code", 2)}
                description='Manage repetitive tasks by using these codes in your dial pad.'
            />
            <div className={styles.section_group}>
                <div className={styles.shortcodes_container}>
                    {
                        displayShortcodes.map((shortcode, i) =>
                            <Shortcode key={i} shortcode={shortcode}/>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Shortcodes
