/* eslint-disable */
import defaultAxios, { AxiosResponse, ResponseType } from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';
import { isCallSwitch, isPulseHd } from '../helpers';
import {
    ApiGlobalSetting,
    ChatType,
    Department,
    IAccount,
    IAuthUser,
    ICallHandlingPutData,
    ICallRoute,
    IChat,
    IChatMessage,
    ICRMIntegration,
    IHuntGroup,
    IMailboxMenu,
    IParkingSlot,
    IPhonebook,
    IPhonebookContact,
    IPhoneNumber,
    ISendChatMessageResponse,
    IShortcode,
    ISound,
    ITelecommunicationsIdentificationDisplay,
    ITimeDiary,
    IUser,
    IVerification,
    IYeOldeCall
} from '../types';

export interface ILoginResponse {
    auth: IAuthUser;
    call_routes: ICallRoute[];
    caller_ids: null;
    hunt_groups: IHuntGroup[];
    numbers: IPhoneNumber[];
    parking_slots: null;
    phone_books: IPhonebook[];
    selectable_users: [];
    selectable_accounts: IAccount[];
    matched_accounts: IAccount[];
    short_codes: IShortcode[];
    integrations: ICRMIntegration[] | undefined;
    time_diaries: ITimeDiary[];
    users: IUser[];
    sounds: ISound[];
    mailboxes: IMailboxMenu[];
    departments: Department[];
}

// const baseUrl = window.location.pathname.split('/')[1] === 'x' ? '/x/' : '';

const axios = defaultAxios.create({
    baseURL: ''
});

type TypedAxiosResponse<T> = Promise<AxiosResponse<{ result: T }>>;

axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

export const api = {
    getAppVersion: () =>
        axios({
            method: 'GET',
            url: '/'
        }),
    postAllMessagesRead: ({ voip_user_uuid, type, chat_uuid }) =>
        axios({
            method: 'PUT',
            url: '/api/chat-mark-all-read',
            params: { voip_user_uuid, type, chat_uuid }
        }),
    getIpCountryCode: async (): Promise<AxiosResponse<string>> => {
        const response = await axios.get('/get-ip-country-code');
        return response?.data === '' ? 'GB' : response?.data;
    },
    loginWithEmailAndPassword: (
        email: string,
        password: string
    ): Promise<AxiosResponse<ILoginResponse>> =>
        axios({
            method: 'POST',
            url: '/login',
            data: {
                email_address: email,
                password,
                app_name: isPulseHd ? 'pulsehd' : isCallSwitch ? 'callswitchone' : 'yay'
            }
        }),
    checkMeetingId: ({ meetingId }: { meetingId: string }): Promise<AxiosResponse<any>> =>
        axios.post('/api/check-meeting-room', {
            custom_url: meetingId
        }),
    loginWithToken: (): Promise<AxiosResponse<ILoginResponse>> =>
        axios({
            method: 'GET',
            url: '/login'
        }),
    loginWithGoogle: (tokenId: string): Promise<AxiosResponse<ILoginResponse>> =>
        axios({
            method: 'POST',
            url: '/login',
            data: {
                id_token: tokenId
            }
        }),
    putCallHandling: (
        userUuid: string,
        data: ICallHandlingPutData
    ): Promise<AxiosResponse<{ result: IUser }>> =>
        axios({
            method: 'PUT',
            url: `/api/put-call-handling?user_uuid=${userUuid}`,
            data
        }),
    getIntegrationDetails: (
        chat_uuid: string
    ): Promise<
        AxiosResponse<{
            result: {
                channel: number;
                name: string;
                number: string;
                uuid: string;
            };
        }>
    > =>
        axios({
            method: 'GET',
            url: '/api/chat-get-name',
            params: {
                type: 'integration',
                chat_uuid
            }
        }),
    getPhonebookContacts: (
        phonebook_uuid?: string,
        limit?: number,
        page?: number
    ): Promise<
        AxiosResponse<{
            result: IPhonebookContact[];
            total_count: number;
            total_pages: number;
        }>
    > =>
        axios({
            method: 'GET',
            url: '/api/get-phonebook-contacts',
            params: {
                phonebook_uuid,
                page: page || 1,
                limit
            }
        }),
    getSingleContact: (
        phonebook_uuid: string,
        contact_uuid: string
    ): Promise<AxiosResponse<IPhonebookContact>> =>
        axios({
            method: 'GET',
            url: '/api/get-phonebook-single-contact',
            params: {
                phonebook_uuid,
                contact_uuid
            }
        }),
    matchIntegrationNumber: ({
        uriUser,
        integrationUuid
    }: {
        uriUser: string | undefined;
        integrationUuid: string;
    }): Promise<AxiosResponse<any>> =>
        axios({
            method: 'GET',
            url: `/api/match-integration-number?number=${uriUser}&integration_uuid=${integrationUuid}`
        }),
    getCallHistory: ({
        username,
        call_from,
        call_to,
        week,
        page
    }: {
        username: string;
        call_from?: boolean;
        call_to?: boolean;
        week: number;
        page: number;
    }): Promise<AxiosResponse<{ result: IYeOldeCall[]; total_pages: number }>> => {
        const params: any = {
            sip_username: username,
            limit: 250,
            start: moment
                .utc()
                .subtract(7 * week, 'days')
                .format(),
            call_to: call_to ? username : null,
            call_from: call_from ? username : null,
            page
        };

        if (week > 1) {
            params.end = moment
                .utc()
                .subtract(7 * (week - 1), 'days')
                .format();
        }

        return axios({
            method: 'GET',
            url: '/api/get-call-history',
            params
        });
    },
    getCallHistoryFiller: ({
        username,
        startTimestamp
    }: {
        username: string;
        startTimestamp: number;
    }): Promise<AxiosResponse<{ result: IYeOldeCall[]; total_pages: number }>> => {
        const params = {
            sip_username: username,
            limit: 250,
            start: moment.unix(startTimestamp).utc().format(),
            call_to: null,
            call_from: username,
            page: 1
        };

        return axios({
            method: 'GET',
            url: '/api/get-call-history',
            params
        });
    },
    sendMessageWithImage: (
        message: IChatMessage
    ): Promise<AxiosResponse<ISendChatMessageResponse>> =>
        axios({
            method: 'POST',
            url: '/api/send-chat-file',
            params: { voip_user_uuid: message.from },
            data: message
        }),
    postChatMessagesRead: ({
        voip_user_uuid,
        type,
        chat_uuid
    }: {
        voip_user_uuid: string;
        type: ChatType;
        chat_uuid: string;
    }): Promise<AxiosResponse<{ result: null }>> =>
        axios({
            method: 'PUT',
            url: '/api/chat-mark-all-read',
            params: { voip_user_uuid, type, chat_uuid }
        }),
    getCallerIds: (
        uuid: string
    ): Promise<AxiosResponse<{ result: ITelecommunicationsIdentificationDisplay[] }>> =>
        axios({
            method: 'GET',
            url: '/api/get-caller-ids',
            params: { user_uuid: uuid }
        }),
    changeCallerId: (
        user_uuid: string,
        callerid_uuid: string | undefined
    ): Promise<AxiosResponse<{ result: null }>> =>
        axios({
            method: 'PUT',
            url: '/api/change-caller-id',
            params: { user_uuid },
            data: {
                caller_id: {
                    uuid: callerid_uuid
                }
            }
        }),
    getParkingSlots: (): Promise<AxiosResponse<{ result: IParkingSlot[] }>> =>
        axios({
            method: 'GET',
            url: '/api/get-parking-slots'
        }),
    muteUser: (voip_user_uuid: string, muted_user_uuid: string): Promise<AxiosResponse> =>
        axios({
            method: 'PUT',
            url: '/api/mute-user',
            params: {
                voip_user_uuid,
                muted_user_uuid
            }
        }),
    unmuteUser: (voip_user_uuid: string, muted_user_uuid: string): Promise<AxiosResponse> =>
        axios({
            method: 'PUT',
            url: '/api/unmute-user',
            params: {
                voip_user_uuid,
                muted_user_uuid
            }
        }),
    // TODO BELOW
    muteChannel: (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> =>
        axios({
            method: 'PUT',
            url: '/api/mute-channel',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        }),
    unmuteChannel: (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> =>
        axios({
            method: 'PUT',
            url: '/api/unmute-channel',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        }),

    // // deleteChannel: (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
    //     return axios({
    //         method: 'DELETE',
    //         url: '/api/delete-channel',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid
    //         }
    //     });
    //
    //
    // },

    // removeChannelUser: (
    //     voip_user_uuid: string,
    //     channel_uuid: string,
    //     removed_user_uuid: string
    // ): Promise<AxiosResponse> => {
    //     return axios({
    //         method: 'DELETE',
    //         url: '/api/remove-channel-user',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid,
    //             removed_user_uuid
    //         }
    //     });
    //
    //
    // },

    // leaveChannel: (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
    //     return axios({
    //         method: 'DELETE',
    //         url: '/api/leave-channel',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid
    //         }
    //     });
    //
    //
    // },
    getFileContent: ({
        userUuid,
        fileUuid,
        responseType
    }: {
        userUuid: string;
        fileUuid: string;
        responseType?: ResponseType;
    }): Promise<AxiosResponse<string>> =>
        axios({
            method: 'GET',
            url: `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${fileUuid}&content=true&thumbnail=false`,
            responseType
        }),
    postDeliveredMessageById: (
        voip_user_uuid: string,
        message_uuid: string
    ): Promise<AxiosResponse<{ result: null }>> =>
        axios({
            method: 'PUT',
            url: '/api/chat-message-received',
            params: { voip_user_uuid, message_uuid }
        }),
    postReadMessageById: (
        voip_user_uuid: string,
        message_uuid: string
    ): Promise<AxiosResponse<{ result: null }>> =>
        axios({
            method: 'PUT',
            url: '/api/chat-message-read',
            params: { voip_user_uuid, message_uuid }
        }),
    getChatMessagesUntil: ({
        voip_user_uuid,
        type,
        chat_uuid,
        until_message_uuid
    }: {
        voip_user_uuid: string;
        type: string;
        chat_uuid: string;
        until_message_uuid: string;
    }): Promise<AxiosResponse<{ result: any }>> =>
        axios({
            method: 'GET',
            url: '/api/get-chat-messages-until',
            params: {
                voip_user_uuid,
                type,
                uuid: chat_uuid,
                until_uuid: until_message_uuid
            }
        }),
    getChatMessagesById: ({
        uuid,
        type,
        chatId,
        offset,
        limit
    }: {
        uuid: string;
        type: ChatType;
        chatId: string;
        offset?: number;
        limit?: number;
    }): Promise<AxiosResponse<{ result: IChat }>> =>
        axios({
            method: 'GET',
            url: '/api/get-chat-messages',
            params: {
                voip_user_uuid: uuid,
                type,
                uuid: chatId,
                limit,
                offset,
                newest_first: 'yes',
                filter_by_updated: 'no'
                // TODO - waiting on backend for this
                // include_deleted: 'yes',
            }
        }),
    getChatMessageByIdAPI: ({
        voip_user_uuid,
        message_uuid
    }: any): Promise<AxiosResponse<{ result: IChatMessage }>> =>
        axios({
            method: 'GET',
            url: '/api/get-chat-message',
            params: { voip_user_uuid, message_uuid }
        }),
    getChatById: ({
        voip_user_uuid,
        channel_uuid
    }: {
        voip_user_uuid: string;
        channel_uuid: string;
    }): Promise<AxiosResponse> =>
        axios({
            method: 'GET',
            url: '/api/get-chat',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        }),
    getChatFile: ({
        userUuid,
        fileUuid
    }: {
        userUuid: string;
        fileUuid: string;
    }): Promise<AxiosResponse> =>
        axios({
            method: 'GET',
            url: `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${fileUuid}&content=true&thumbnail=false`,
            responseType: 'arraybuffer'
        }),
    deleteDevice: ({
        userUuid,
        deviceUuid
    }: {
        userUuid: string;
        deviceUuid: string;
    }): Promise<AxiosResponse<any>> =>
        axios({
            method: 'DELETE',
            url: `/api/delete-device?voip_user_uuid=${userUuid}&device_uuid=${deviceUuid}`
        }),
    resendVerification: ({
        userUuid,
        isResend,
        isEmail
    }: {
        userUuid: string;
        isResend: boolean;
        isEmail: boolean;
    }): Promise<AxiosResponse<any>> => {
        return axios.post(
            `/api/send-user-verification-code?user_uuid=${userUuid}&resend=${
                isResend ? 'true' : 'false'
            }`,
            {
                verification_type: isEmail ? 'EMAIL' : 'MOBILE'
            }
        );
    },
    checkUserVerification: (
        userUuid: string
    ): Promise<
        AxiosResponse<{
            result: IVerification[];
        }>
    > => axios.get(`/api/check-user-verification?user_uuid=${userUuid}`),
    setUpVerification: ({
        userUuid,
        data
    }: {
        userUuid: string;
        data: {
            verification_code: string;
            verification_type: string;
        };
    }): Promise<AxiosResponse<any>> =>
        // eslint-disable-next-line no-return-await
        axios.put(`/api/submit-user-verification-code?user_uuid=${userUuid}`, data),
    moveCallToFollow: ({
        callSipId,
        target
    }: {
        callSipId: string;
        target?: string;
    }): Promise<AxiosResponse<any>> =>
        axios.put(`/api/put-follow-me-call?call_uuid=${callSipId}`, {
            target: target || ''
        }),
    getSingleVoicemail: ({
        mailboxUuid,
        messageUuid
    }: {
        mailboxUuid: string;
        messageUuid: string;
    }): Promise<AxiosResponse<any>> =>
        axios.get(
            `/api/get-single-voicemail?mailboxUuid=${mailboxUuid}&messageUuid=${messageUuid}`
        ),
    putUserGlobalDnd: ({
        userUuid,
        dnd
    }: {
        userUuid: string;
        dnd: boolean;
    }): Promise<AxiosResponse<any>> =>
        axios.put(`/api/put-sip-user-global-dnd?voip_user_uuid=${userUuid}`, { dnd }),
    getGlobalSettings: (userUuid: string): TypedAxiosResponse<ApiGlobalSetting[]> =>
        axios.get(`/api/get-sip-user-settings?voip_user_uuid=${userUuid}`),
    getFriend: ({
        voip_user_uuid,
        friend_uuid
    }: {
        voip_user_uuid: string;
        friend_uuid: string;
    }): Promise<AxiosResponse<{ result: unknown }>> => {
        return axios({
            method: 'GET',
            url: '/api/anonymous-chatter',
            params: { voip_user_uuid, friend_uuid }
        });
    }
};
