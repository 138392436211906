import React, {useCallback, useEffect, useState} from "react";
import debounce from "lodash.debounce";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import styles from '../SettingsPage.module.scss'
import {
    selectChatSettingByKey,
    selectPhoneSettingByKey,
    updateChatSettings,
    updatePhoneSettings
} from "../../../redux/slices";
import notificationAudio from "../../../sounds/notification.mp3";
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import SettingsSlider from "./SettingsComponents/SettingsSlider";
import SettingsVolume from "./SettingsComponents/SettingsVolume";
import {useDnd} from "../../../helpers";

interface NotificationProps {
}

export const Notifications: React.FC<NotificationProps> = () => {

    const allowInternal = useTypedSelector(state => selectPhoneSettingByKey(state, 'allowInternalCalls'));

    const disableChatNotifications = useTypedSelector(state => selectChatSettingByKey(state, 'disableChatNotifications'));
    const chatNotificationVolume = useTypedSelector(state => selectChatSettingByKey(state, 'chatNotificationVolume'));

    const [notificationVolume, setNotificationVolume] = useState<number>(chatNotificationVolume ?? 100);

    const {
        localDnd,
        globalDnd,
        globalDndLoading,
        dndDisabled,
        updateDnd,
    } = useDnd();

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    const audio = new Audio(notificationAudio);

    const updateChatSettingsCallback = useCallback(
        debounce((setting: string, value: number) =>
            dispatch(updateChatSettings({
                setting,
                value
            })), 10),
        []
    );

    useEffect(() => {
        updateChatSettingsCallback('chatNotificationVolume', notificationVolume);
    }, [notificationVolume, updateChatSettingsCallback]);

    return (
        <div className={styles.container}>
            <MenuHeader
                title={t("terms.notifications_settings")}
                description={t("phrases.make_changes_to_notifications")}
            />
            {!dndDisabled ? (
                <div className={styles.section_group}>
                    <div className={styles.heading}>
                        <h2>{t("terms.user_status")}</h2>
                        <p>{t("phrases.set_your_user_status")}</p>
                    </div>
                    <div>
                        <SettingsSlider
                            title="Global Do Not Disturb"
                            checked={globalDnd}
                            onChange={() => updateDnd(!globalDnd, true)}
                            loading={globalDndLoading}
                            infoText="Applies Do Not Disturb settings to your account.
                            This will prevent calls with all of your apps and devices (Yealink handsets only)
                            and be displayed to other users on your presence."
                        />
                        <SettingsSlider
                            title="App Do Not Disturb"
                            checked={localDnd}
                            onChange={() => updateDnd(!localDnd, false)}
                            loading={globalDndLoading}
                            infoText="Applies Do Not Disturb settings to this application only.
                            Other apps and devices on your account will continue to receive
                            calls and your presence will not be changed."
                        />
                        {localDnd ? (
                            <SettingsSlider
                                title={t("phrases.allow_internal_calls")}
                                checked={allowInternal}
                                onChange={() =>
                                    dispatch(
                                        updatePhoneSettings({
                                            setting: 'allowInternalCalls',
                                            value: !allowInternal
                                        })
                                    )
                                }
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.system_notification", 2)}</h2>
                    <p>{t("phrases.set_whether_you_want_notifications")}</p>
                </div>
                <div>
                    <SettingsSlider
                        title={t("phrases.chat_notifications")}
                        checked={!disableChatNotifications}
                        onChange={() =>
                            dispatch(
                                updateChatSettings({
                                    setting: 'disableChatNotifications',
                                    value: !disableChatNotifications
                                })
                            )
                        }
                    />
                    {!disableChatNotifications ? (
                        <SettingsVolume
                            title={t("phrases.notification_volume")}
                            volume={notificationVolume}
                            onAudioTest={() => {
                                audio.volume = (chatNotificationVolume ?? 100) / 100;
                                audio.play();
                            }}
                            onChange={(val => setNotificationVolume(val))}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default Notifications;
