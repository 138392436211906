/* eslint-disable react/jsx-props-no-spreading */

import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {createEditor, Editor, Element, Transforms} from 'slate';
import {ReactEditor, Slate, withReact} from 'slate-react';
import debounce from "lodash.debounce";
import {withHistory} from "slate-history";
import styles from './ChatInput.module.scss'
import {Editor as EditorComponent} from "./Editor";
import CodeToText from './Elements/CodeToText/CodeToText'
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {clearActiveChatInputText, updateActiveChatInputText} from "../../../redux/slices";
import withTag from "./plugins/withTag";
import Toolbar from './Toolbar/Toolbar'
import Attachments from "./Attachments";
import ReplyTo from "./ReplyTo";
import {useChatInputContext} from "../../../context/ChatInputContext/context";
import {useSendMessage, useUserTyping} from "./hooks";
import {slateToMarkdown} from './utils/slateToMarkdown';
import TypingUsers from "./TypingUsers";
import ChatTagProvider from "./TagPopup/ChatTagsContext/context";

interface Props {
    uploadInput: RefObject<HTMLInputElement>;
}

const ChatInput: React.FC<Props> = ({
                                        uploadInput
                                    }) => {
    const {chat, files} = useChatInputContext()

    const inProgressTextInput = useTypedSelector(
        state => state.chat.inProgressTextInputs?.[chat.uuid]
    );

    const dispatch = useTypedDispatch();


    const initialValue = [{
        type: 'paragraph',
        children: [{text: ''}],
    }];

    const editor = useRef<ReactEditor>(withTag(withReact(withHistory(createEditor())))).current;
    const [value, setValue] = useState<Element[]>(inProgressTextInput || initialValue);
    const [htmlAction, setHtmlAction] = useState({
        showInput: false,
        html: '',
        action: '',
        location: '',
    });
    const characterByteCount = useRef<number>(0);
    const encoder = useRef<TextEncoder>(new TextEncoder()).current;

    const {
        handleTypingStatus,
        sendUserStillTyping,
        sendUserStopTyping,
    } = useUserTyping()

    const handleSubmit = useSendMessage({
        value,
        setValue,
        editor,
        characterByteCount: characterByteCount.current,
        sendUserStillTyping,
        sendUserStopTyping,
    })

    // eslint-disable-next-line
    const sendInputTextToStore = useCallback(
        debounce((chatId: string, textValue: any) => {
            dispatch(
                updateActiveChatInputText({
                    chat_uuid: chatId,
                    text: textValue
                })
            );
        }, 500),
        []
    );

    const clearInputTextFromStore = useCallback(
        debounce((chatId: string) => {
            dispatch(
                clearActiveChatInputText({
                    chat_uuid: chatId,
                })
            );
        }, 500),
        []
    );

    const handleEditorChange = (newValue) => {
        const markdownString = newValue.map(val => slateToMarkdown(val.children)).join(' ')
        characterByteCount.current = markdownString.length
        setValue(newValue)
        handleTypingStatus(markdownString.length)
    }

    const handleCodeToText = (partialState) => {
        setHtmlAction(prev => ({
            ...prev,
            ...partialState,
        }))
    };

    const focusOnEditor = () => {
        Transforms.select(editor, {
                anchor: Editor.end(editor, []),
                focus: Editor.end(editor, []),
            }
        );
        ReactEditor.focus(editor);
    }

    useEffect(() => {
        focusOnEditor()
    }, [files.length]);

    useEffect(() => {
        if (encoder.encode(slateToMarkdown(value[0]?.children)).length > 1) {
            sendInputTextToStore(chat.uuid, value);
        } else {
            clearInputTextFromStore(chat.uuid);
        }
    }, [value]);

    return (
        <div
            className={styles.editor_container}
            data-comp='editor_container'
            onKeyDown={(e) => {
                if (
                    /\d/.test(e.key) && !e.metaKey
                    || e.key.toLowerCase().includes('arrow')
                ) {
                    e.stopPropagation()
                }
            }}
        >
            <Slate
                editor={editor}
                initialValue={value}
                onChange={handleEditorChange}
            >
                <TypingUsers/>
                <ReplyTo
                    focusOnEditor={() => focusOnEditor()}
                />
                <Attachments/>
                <ChatTagProvider>
                    <EditorComponent
                        handleSubmit={handleSubmit}
                    />
                </ChatTagProvider>
                {
                    htmlAction.showInput &&
                    <CodeToText
                        {...htmlAction}
                        handleCodeToText={(e) => handleCodeToText(e)}
                    />
                }
                <Toolbar
                    uploadInput={uploadInput}
                    onSubmit={() => handleSubmit()}
                    characterCount={characterByteCount.current}
                />
            </Slate>
        </div>
    )
}

export default ChatInput;
