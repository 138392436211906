import React from 'react';
import styles from './AuthContainer.module.scss';

interface AuthContainerProps {
    children?: React.ReactNode;
}

export const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => (
    <main className={styles.main}>{children}</main>
);

export default AuthContainer;
