import React, { useEffect, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import {
    resetChatMessagesSearch,
    selectAllChatEntries,
    selectAllUsers,
    selectChatEntities,
    selectChatList,
    selectCurrentUserId,
    selectFavouriteChats,
    selectFiltersByPage,
    selectLastUpdatedChatId,
    selectMessageSearchResult,
    selectMutedChats,
    selectThreadsList
} from '../../../redux/slices';
import { useSearchChatMessagesQuery } from '../../../redux/services/chatApi';
import { focusNext } from '../../../helpers';
import { ChatDataType, ChatListPageType } from './ChatSidebar';
import { usePolyglot } from '../../../context/Polyglot';
import { ChatType, IChat, IThread } from '../../../types';

interface UseChatDataSetProps {
    page: ChatListPageType;
    searchRegexDebounced: RegExp | undefined;
    searchQueryDebounced: string;
}

export const useChatDataSet = ({
    searchRegexDebounced,
    searchQueryDebounced,
    page
}: UseChatDataSetProps) => {
    const messageSearchResults = useTypedSelector(state =>
        selectMessageSearchResult(state, 'global')
    );
    const filter = useTypedSelector(state => selectFiltersByPage(state, 'chatList'));
    const chatDictionary = useTypedSelector(selectChatEntities);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const allUsers = useTypedSelector(selectAllUsers);

    const { t } = usePolyglot();

    const { chats, threads } = useAllChats({
        searchRegex: searchRegexDebounced,
        filter
    });
    const { isSearching } = useMessageSearch({
        searchQuery: searchQueryDebounced,
        chat_type: undefined
    });

    const dataSet = useMemo(() => {
        const data: ChatDataType[] = [
            {
                divider: 'message',
                message: page === 'chats' ? t('terms.chat', 2) : t('terms.thread', 2)
            }
        ];

        // If no chats when on chats or threads on thread show empty placeholder
        if ((page === 'chats' && chats?.length < 1) || (page === 'threads' && threads.length < 1)) {
            data.push({
                divider: 'empty',
                message:
                    searchRegexDebounced || filter.length > 0
                        ? t('phrases.no_results_match_your_search')
                        : t('phrases.no_chats')
            });
        }

        // If user is searching show messages section
        if (searchRegexDebounced) {
            data.push({ divider: 'message', message: t('terms.contact', 2) });

            const addrUsers: any[] = (allUsers || []).filter(
                u =>
                    !chatDictionary[u.uuid] &&
                    (searchRegexDebounced.test(u.nickname) ||
                        searchRegexDebounced.test(String(u.extension)))
            );

            if (addrUsers.length > 0) {
                data.push(...addrUsers);
            } else {
                data.push({
                    divider: 'empty',
                    message: t('phrases.no_results_match_your_search')
                });
            }

            data.push({ divider: 'message', message: t('terms.message', 2) });

            if (isSearching) {
                data.push({ divider: 'loader' });
            }

            if (messageSearchResults && messageSearchResults.length > 0) {
                const filteredResults = messageSearchResults.filter(searchResult => {
                    const chatId =
                        searchResult.to && userUuid !== searchResult.to
                            ? searchResult.to
                            : searchResult.from;
                    return !!chatDictionary[chatId];
                });
                data.push(...filteredResults);
            } else if (!isSearching) {
                data.push({
                    divider: 'empty',
                    message: t('phrases.no_results_match_your_search')
                });
            }
        }

        return data;
    }, [messageSearchResults?.length, allUsers?.length, page, isSearching, filter]);

    return {
        dataSet,
        chats,
        threads
    };
};

const useAllChats = ({
    searchRegex,
    filter
}: {
    searchRegex: RegExp | undefined;
    filter: string[];
}) => {
    const chatsList = useTypedSelector(selectChatList);
    const threadsList = useTypedSelector(selectThreadsList);
    const mutedChats = useTypedSelector(selectMutedChats);
    const favouriteChats = useTypedSelector(selectFavouriteChats);
    const lastUpdatedChatId = useTypedSelector(selectLastUpdatedChatId);
    const chatDictionary = useTypedSelector(selectAllChatEntries);

    const filterBloom = [
        filter.includes('Unread'),
        filter.includes('Read'),
        filter.includes('Pinned'),
        filter.includes('Muted'),
        filter.includes('SMS'),
        filter.includes('Groups'),
        filter.includes('Individual')
    ];

    const filterVal = filterBloom.reduce((p, c, i) => p + (c ? 2 ** i : 0), 0);

    const hasFilter = filterBloom.includes(true);

    const checkFilter = (c: IChat) => {
        switch (true) {
            case filterBloom[0] && c.unread_count === 0:
            case filterBloom[1] && c.unread_count > 0:
            case filterBloom[2] && !favouriteChats?.[c.uuid]:
            case filterBloom[3] && !mutedChats[c.uuid]:
            case filterBloom[4] && (!c.integration || !/sms/i.test(c.integration.channel)):
            case filterBloom[5] && c.type === 'user':
            case filterBloom[6] && c.type === 'channel':
                return false;
            default:
                return true;
        }
    };

    const testChat = (c: IChat): boolean => {
        if (hasFilter && !checkFilter(c)) {
            return false;
        }

        return !(
            searchRegex &&
            !(
                searchRegex.test(c.display_name) ||
                (c.integration?.number && searchRegex.test(c.integration?.number))
            )
        );
    };

    const baseChats = useMemo(() => {
        if (!hasFilter && !searchRegex) {
            return chatsList;
        }

        return chatsList.filter(testChat);
    }, [filterVal, chatsList.length, lastUpdatedChatId, searchRegex]);

    const threadsChats = useMemo(() => {
        if (!hasFilter && !searchRegex) {
            return threadsList;
        }

        return threadsList.filter((t: IThread) => {
            if (!t.source?.source_uuid) return false;

            const baseChat = chatDictionary[t.source.source_uuid];

            if (!baseChat) return false;

            return testChat(baseChat);
        });
    }, [filterVal, threadsList.length, lastUpdatedChatId, searchRegex]);

    return {
        chats: baseChats,
        threads: threadsChats
    };
};

export const useMessageSearch = ({
    searchQuery,
    chatId,
    chat_type
}: {
    searchQuery: string;
    chatId?: string;
    chat_type: ChatType | undefined;
}) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const chatEnabled = useTypedSelector(state => state.user.chat_enabled);

    const dispatch = useTypedDispatch();

    const { isFetching: isSearching } = useSearchChatMessagesQuery(
        {
            voip_user_uuid: userUuid,
            search_term: searchQuery,
            chat_key: chatId || 'global',
            target_type: chat_type
        },
        {
            skip: !searchQuery || !chatEnabled,
            refetchOnMountOrArgChange: true
        }
    );

    useEffect(() => {
        if (!searchQuery) {
            dispatch(resetChatMessagesSearch(chatId || 'global'));
        }
    }, [searchQuery]);

    useEffect(
        () => () => {
            dispatch(resetChatMessagesSearch(chatId || 'global'));
        },
        []
    );

    return {
        isSearching
    };
};

export const useChatKeydown = () => {
    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement;

        if (
            (target?.parentNode as HTMLElement).className === 'recent-row__options' &&
            target.nextSibling?.firstChild?.nodeName === 'UL'
        ) {
            focusNext({
                container: target.parentNode as HTMLElement,
                before
            });
            return;
        }

        if (target.parentNode?.nodeName === 'UL') {
            focusNext({
                container: target.parentNode?.parentNode?.parentNode as HTMLElement,
                before
            });
            return;
        }

        let navElement: any = before
            ? e.currentTarget.previousSibling
            : e.currentTarget.nextSibling;

        if (!navElement) return;

        if ((navElement?.firstChild as HTMLElement).className === 'list-divider') {
            navElement = before ? navElement?.previousSibling : navElement?.nextSibling;
        }

        if (!navElement) return;

        if (navElement) {
            focusNext({
                container: navElement
            });
        }
    };

    const handleArrowX = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        focusNext({
            container: e.currentTarget,
            before
        });
    };

    const ACTIONS = {
        ArrowRight: e => handleArrowX(e),
        ArrowLeft: e => handleArrowX(e, true),
        ArrowDown: e => handleArrowY(e),
        ArrowUp: e => handleArrowY(e, true),
        Enter: e => e.target.click(),
        Space: e => e.target.click()
    };

    return e => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
};
