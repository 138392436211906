import React, {FunctionComponent} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import Select, {StylesConfig} from "react-select";
import CreatableSelect from "react-select/creatable";
import styles from "./styles.module.scss";
import AriaLabel from "../AriaComponents/AriaLabel";
import ariaStyles from "../AriaComponents/styles.module.scss";
import {CustomDropdownIndicator} from "./FormSelect";

interface Props {
    name: string;
    label: string;
    required?: boolean;
    readOnly?: boolean;
    options: multiValueOption[];
    getColor?: (val: string) => string;
    noCreate?: boolean
}

interface multiValueOption {
    label: string;
    value: string;
}

const FormMultiselectCreatable: FunctionComponent<Props> = ({name, options, label, required, readOnly, getColor, noCreate}) => {
    const {control} = useFormContext();

    const multiselectCreatableStyles: StylesConfig = {
        multiValueLabel: (base) => ({
            ...base,
            color: 'var(--font-color-white)',
        }),
        multiValueRemove: (base, props) => ({
            ...base,
            margin: '2px 0 0 2px',
            width: 14,
            height: 14,
            borderRadius: 4,
            background: props.isFocused ? 'var(--secondary-background)' : undefined,
            color: props.isFocused ? 'var(--color-warning)' : undefined,
            ':hover': {
                background: 'var(--secondary-background)',
                color: 'var(--color-warning)',
            }
        }),
        multiValue: (base, props) => ({
            ...base,
            backgroundColor: getColor ? getColor((props.data as multiValueOption).value) : '',
            color: 'var(--font-color-white)',
            padding: 4,
            borderRadius: 4,
        }),
        valueContainer: (base) => ({
            ...base,
            display: 'flex',
            gap: 4,
        }),
        control: (base) => ({
            ...base,
            height: 'max-content !important',
        }),
        container: (base) => ({
            ...base,
            height: 'max-content !important',
        }),
        option: (base, props) => ({
            ...base,
            // eslint-disable-next-line no-nested-ternary
            backgroundColor: props.isDisabled
                ? undefined
                // eslint-disable-next-line no-nested-ternary
                : props.isSelected
                    ? 'var(--tertiary-color)'
                    : props.isFocused
                        ? 'var(--secondary-background)'
                        : undefined,
            // eslint-disable-next-line no-nested-ternary
            color: props.isDisabled
                ? 'var(--secondary-font-color)'
                // eslint-disable-next-line no-nested-ternary
                : props.isSelected
                    ? 'var(--font-color-white)'
                    : 'var(--primary-font-color)',
            cursor: props.isDisabled ? 'not-allowed' : 'default',
            "::before": {
                content: '"•"',
                marginRight: 4,
                width: 8,
                height: 8,
                color: getColor ? getColor((props.data as multiValueOption).value) : '',
            },
        }),
    };

    return (
        <div className={styles.form_select_multiple_creatable_input}>
            <AriaLabel label={label}/>
            {required ? (
                <mark>*</mark>
            ) : null}
            <Controller
                name={name}
                control={control}
                render={({
                             field: {onChange},
                             fieldState: {invalid, isTouched, isDirty, error},
                         }) => (
                    <div
                        data-invalid={invalid || null}
                        data-is-touched={isTouched || null}
                        data-is-dirty={isDirty || null}
                        data-error={!!error || null}
                    >
                        {noCreate ? (
                            <Select
                                unstyled
                                styles={multiselectCreatableStyles}
                                options={options}
                                onChange={(e) => {
                                    onChange((e as multiValueOption[]).map(option => option.value))
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                isDisabled={readOnly}
                                components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                }}
                                isMulti
                            />
                        ) : (
                            <CreatableSelect
                                options={options}
                                isMulti
                                allowCreateWhileLoading
                                isDisabled={readOnly}
                                components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                }}
                                unstyled
                                styles={multiselectCreatableStyles}
                                onChange={(e) => {
                                    onChange((e as multiValueOption[]).map(option => option.value))
                                }}
                            />
                        )}
                        {error?.message ? (
                            <div
                                className={ariaStyles.errorMessage}
                                // {...errorMessageProps}
                            >
                                {error.message}
                            </div>
                        ) : null}
                    </div>
                )
                }
            />
        </div>
    );
};

export default FormMultiselectCreatable;
