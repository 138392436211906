import React from "react";
import {ICall, IPhonebookContact} from "../../../../../types";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import {addUserForGroupCall, selectUserCountryCode, startCall} from "../../../../../redux/slices";
import {getValidNumber} from "../../../../sidebars/Contact/Contact";
import styles from "../DragAndDropKeypad.module.scss";
import Icon from "../../../../Icon";
import {usePolyglot} from "../../../../../context/Polyglot";
import {useContactContext} from "../../../../../context/ContactContext/context";
import {ContinuingDAD} from "../../../index";
import AriaButton from "../../../../AriaComponents/AriaButton";
import {useConference} from "../../../ConferenceHooks";

interface PhonebookContactKeypadProps {
    context: 'new' | 'group' | 'transfer';
    setContinueWith: (val: ContinuingDAD | undefined) => void;
    call?: ICall;
}

export const PhonebookContactKeypad: React.FC<PhonebookContactKeypadProps> = ({context, setContinueWith, call}) => {
    const contact = useContactContext().contact as IPhonebookContact;

    const countryCode = useTypedSelector(selectUserCountryCode);

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();
    const {inviteToGroup} = useConference();

    const validNumbers: any[] = [];

    contact.details.forEach((detail) => {
        const validNumber = getValidNumber(detail?.value, countryCode);

        if (validNumber) {
            validNumbers.push(detail);
        }
    })

    const tempName = `${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''}`

    const onPhonebookContactCall = (detail, validNumber, displayName) => {
        if (!detail?.value || !validNumber) return;

        dispatch(startCall(validNumber, displayName))
    }

    return (
        <div
            className={styles.drag_and_drop_keypad}
            data-active-call={!!call || null}
            data-continuing='true'
        >
            <button
                onClick={() => setContinueWith(undefined)}
                className={styles.cancelBtn}
            >
                {t("actions.cancel")}
            </button>
            {context === 'new' ? (
                <p>
                    {t("phrases.new_call_with_%name", {name: tempName})}
                </p>
            ) : null}
            {context === 'group' ? (
                <p>
                    {t("phrases.add_%name_to_group_call", {name: tempName})}
                </p>

            ) : null}
            {context === 'transfer' ? (
                <p>
                    {tempName}
                </p>
            ) : null}
            {validNumbers.length > 0 ? (
                validNumbers.map((detail, index) => {
                    const validNumber = getValidNumber(detail?.value, countryCode);

                    let displayName: string;

                    if (contact.first_name && contact.last_name) {
                        displayName = `${contact.first_name} ${contact.last_name} (${detail.label})`;
                    } else {
                        displayName = `${contact.first_name || contact.last_name || contact.company_name} (${detail.label})`
                    }
                    return (
                        <div key={index}>
                            {(context === 'new') ? (
                                <div>
                                    <p>{`${detail.label}: ${detail.value}`}</p>
                                    <AriaButton
                                        onClick={() => {
                                            onPhonebookContactCall(detail, validNumber, displayName)
                                            setContinueWith(undefined)
                                        }}
                                    >
                                        <Icon name='addCall' width={28} height={28}/>
                                    </AriaButton>
                                </div>
                            ) : null}
                            {(context === 'group') ? (
                                <div>
                                    <p>{`${detail.label}: ${detail.value}`}</p>
                                    <AriaButton
                                        title={t("actions.add_to_call")}
                                        onClick={() => {
                                            if (!!call && !!call.roomId && validNumber) {
                                                inviteToGroup({
                                                    invitationNumbers: [validNumber],
                                                    call
                                                })
                                            } else {
                                                dispatch(
                                                    addUserForGroupCall({
                                                        ...contact,
                                                        selected_number: validNumber,
                                                    })
                                                )
                                            }
                                            setContinueWith(undefined)
                                        }}
                                        data-exempt='true'
                                    >
                                        <Icon name='plusCircleFill' width={28} height={28}/>
                                    </AriaButton>
                                </div>
                            ) : null}
                            {(context === 'transfer') ? (
                                <div>
                                    <p>{`${detail.label}: ${detail.value}`}</p>
                                    <AriaButton
                                        onClick={() => {
                                            onPhonebookContactCall(detail, validNumber, displayName)
                                            setContinueWith(undefined)
                                        }}
                                    >
                                        <Icon name='transfer' width={28} height={28}/>
                                    </AriaButton>
                                </div>
                            ) : null}
                        </div>
                    )
                })
            ) : (
                <p>{t("phrases.no_valid_numbers_contact")}</p>
            )}
        </div>
    )
}

export default PhonebookContactKeypad;