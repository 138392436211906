import {api} from ".";
import {
    fromDeletePhonebookContact,
    fromPostPhonebookContact,
    fromPutPhonebookContact,
} from "../slices";
import {IPhonebookContact} from "../../types";

interface PhonebookContact {
    first_name: string;
    last_name: string;
    company_name?: string;
    details: { type: number; label: string; value: string }[];
}

export const contactsApi = api.injectEndpoints({
    endpoints: builder => ({

        /**
         * GET Requests
         */


        /**
         * POST Requests
         */
        postPhonebookContact: builder.mutation<{result: IPhonebookContact }, {
            phonebookUuid: string;
            data: PhonebookContact;
        }>({
            query: ({
                        phonebookUuid,
                        data
                    }) => ({
                method: 'POST',
                url: '/api/post-phonebook-contact',
                params: {
                    phonebook_uuid: phonebookUuid
                },
                data
            }),
            async onQueryStarted({phonebookUuid}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;

                    if (result === undefined) return;

                    dispatch(fromPostPhonebookContact({phonebookUuid, newContact: result}))

                } catch {
                    // do nothing
                }
            }
        }),

        /**
         * PUT Requests
         */
        putPhonebookContact: builder.mutation<{result: IPhonebookContact }, {
            phonebookUuid: string;
            contactUuid: string;
            data: PhonebookContact;
        }>({
            query: ({
                        phonebookUuid,
                        contactUuid,
                        data
                    }) => ({
                method: 'PUT',
                url: '/api/put-phonebook-contact',
                params: {
                    phonebook_uuid: phonebookUuid,
                    contact_uuid: contactUuid
                },
                data
            }),
            async onQueryStarted({phonebookUuid, contactUuid}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;

                    if (result === undefined) return;


                    dispatch(fromPutPhonebookContact({phonebookUuid, contactUuid, newContact: result}))

                } catch {
                    // do nothing
                }
            }
        }),

        /**
         * DELETE Requests
         */
        deletePhonebookContact: builder.mutation<any, { phonebookUuid: string; contactUuid: string }>({
            query: ({
                        phonebookUuid,
                        contactUuid
            }) => ({
                method: 'DELETE',
                url: '/api/delete-phonebook-contact',
                params: {
                    phonebook_uuid: phonebookUuid,
                    contact_uuid: contactUuid
                }
            }),
            async onQueryStarted({phonebookUuid, contactUuid}, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(fromDeletePhonebookContact({phonebookUuid, contactUuid}))

                } catch {
                    // do nothing
                }
            }
        }),
    })
})

export const {
    usePostPhonebookContactMutation,

    usePutPhonebookContactMutation,

    useDeletePhonebookContactMutation,
} = contactsApi;