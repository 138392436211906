import moment from "moment";
import {polyglot} from "../lib/polyglot.lib";

export const getDisplayColor = (uuid?: string, background?: boolean, darkMode?: boolean): string => {
    if (!uuid) return background ? 'blue-700' : 'blue-700-t';

    const numbers = uuid.replace(/\D/g, '');
    const colors = [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'teal',
        'amber'
    ];
    const colorTones = ['400', '500', '600', '700', '800', '900', '600', '700', '800', '900'];

    const firstIndex =
        parseInt(numbers.substring(numbers.length - 2)) > 20
            ? numbers.substring(numbers.length - 1)
            : parseInt(numbers.substring(numbers.length - 2));
    const secondIndex = numbers[0];

    let color = `${colors[firstIndex]}-${colorTones[secondIndex]}`;

    if (!firstIndex || !secondIndex) {
        color = 'red-400';
    }

    if (background) return color;
    return `${color}-t ${darkMode ? 'brighten' : ''}`;
};

// export const handlePhoneBookNameDisplay = (
//     callee: string,
//     contactDictionary?: { [key: string]: string },
//     countryCode?: CountryCode,
//     nickname?: string
// ) => {
//     if (!countryCode) return nickname || callee;
//     if (!contactDictionary) return nickname || callee;
//
//     if (contactDictionary[callee]) {
//         return contactDictionary[callee];
//     }
//
//     const parsedPhoneNumber = parsePhoneNumberFromString(callee, countryCode);
//
//     if (parsedPhoneNumber) {
//
//         return (
//             getReturnFromParsedNumber(parsedPhoneNumber, contactDictionary) ||
//             nickname ||
//             callee
//         );
//     }
//
//     return nickname || callee;
// };

// export const getReturnFromParsedNumber = (
//     parsedPhoneNumber: PhoneNumber,
//     contactDictionary: { [key: string]: string },
// ) => {
//     const nationalNumber = parsedPhoneNumber.nationalNumber as string;
//     const internationalNumber = parsedPhoneNumber.number as string;
//
//     return (
//         (nationalNumber && contactDictionary[nationalNumber]) ||
//         (nationalNumber && contactDictionary[`0${nationalNumber}`]) ||
//         (internationalNumber && contactDictionary[internationalNumber]) ||
//         false
//     );
// }

export const formatChatPreviewTime = (messageDateTime: string, verbose = false) => {
    const momentTime = moment(messageDateTime);

    if (momentTime.isSame(moment(), 'day')) {
        return momentTime.format('HH:mm');
    }

    if (momentTime.isSame(moment().subtract(1, 'day'), 'day')) {
        return `${polyglot.t("adverbs.yesterday")} ${momentTime.format('HH:mm')}`;
    }

    if (momentTime.isAfter(moment().subtract(1, 'week'))) {
        return momentTime.format('ddd HH:mm');
    }

    const finalFormat = verbose ? 'ddd D MMM HH:mm' : 'ddd D MMM';

    return momentTime.format(finalFormat);
};

interface FocusByRefProps {
    ref?: HTMLElement;
    container?: HTMLElement;
    before?: boolean;
    includeContainer?: boolean;
    querySelector?: string;
}

export const getFocusableElements = ({container}: { container?: HTMLElement | null }) => {
    const focusScope = container || document;

    // Get all focusable elements on the page
    return focusScope.querySelectorAll<HTMLElement>('a[href], button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])')
}

export const getFocusableInputFields = ({container}: { container?: HTMLElement | null }) => {
    const focusScope = container || document;

    // Get all focusable elements on the page
    return focusScope.querySelectorAll<HTMLElement>('input:not([disabled]):not([tabindex="-1"])[type="text"], textarea:not([disabled]), [role="textbox"]')
}

export const focusNext = ({ref, before, container, includeContainer, querySelector}: FocusByRefProps) => {
    const focusScope = container || document;

    // Get all focusable elements on the page
    const focusableElements = Array.from(
        focusScope.querySelectorAll<HTMLElement>(querySelector || 'a[href], button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])')
    )

    if (includeContainer && container) {
        focusableElements.unshift(focusScope as HTMLElement)
    }
    // Get the current active element
    const currentElement = ref || document.activeElement as HTMLElement;

    // Find the index of the current active element in the focusable elements array
    const currentIndex = focusableElements.indexOf(currentElement);

    // Calculate the index of the next focusable element
    const nextIndex = (focusableElements.length + currentIndex + (before ? -1 : 1)) % focusableElements.length;

    // Set focus on the next element
    if (focusableElements[nextIndex]) {
        focusableElements[nextIndex].focus();
    }
}
