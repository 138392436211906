import React, {useEffect, useRef, useState} from 'react';
import styles from '../styles.module.scss';
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {Shortcode} from "../../../../pages/settings";
import DragContainer from "../../../DragAndDrop/DragContainer";
import {getFocusableElements, useShortcodeList} from "../../../../helpers";
import {selectProvisionItemUuid, setProvisionItem} from "../../../../redux/slices";
import {IShortcode} from "../../../../types";
import AriaButton from "../../../AriaComponents/AriaButton";

interface Props {
    searchTerm: string;
}

const ShortcodesList: React.FC<Props> = ({searchTerm}) => {
    const [res, setRes] = useState<number>(0);

    const provisionItemUuid = useTypedSelector(selectProvisionItemUuid);

    const dispatch = useTypedDispatch();

    const {displayShortcodes} = useShortcodeList(searchTerm)

    const listRef = useRef<HTMLDivElement>(null);

    const elemArr = Array.from(getFocusableElements({container: listRef.current}));

    useEffect(() => {
        setRes(res + 1)
    }, []);

    return (
        <div
            className={styles.short_codes}
            ref={listRef}
        >
            {displayShortcodes.map((shortcode: IShortcode, i) => (
                <DragContainer
                    key={i}
                    id='short_code'
                    extraData={shortcode}
                >
                    <AriaButton
                        className={styles.short_code}
                        onClick={() => {
                            if (provisionItemUuid === shortcode.uuid) {
                                dispatch(
                                    setProvisionItem()
                                )
                            } else {
                                dispatch(
                                    setProvisionItem(shortcode)
                                )
                            }
                        }}
                        title={elemArr[i]?.innerText || ''}
                        dataTags={{
                            'data-is-prov-item': provisionItemUuid === shortcode.uuid || null
                        }}
                    >
                        <Shortcode
                            shortcode={shortcode}
                        />
                    </AriaButton>
                </DragContainer>
            ))}
        </div>
    )
};

export default ShortcodesList;
