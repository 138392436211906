import {useRef, useState} from "react";
import styles from "./Selected.module.scss";
import Icon from "../../../../../components/Icon";
import {useOnClickOutside} from "../../../../../helpers";
import {IPhonebookContact} from "../../../../../types";
import {useInsertTag} from "../hooks";
import {useChatTagContext} from "../ChatTagsContext/context";
import {StyledButton} from "../../../../../components/StyledComponents";

const DetailRow = ({detail, selected, toggleSelected}) => {
    const {label, value} = detail;

    if (!value) {
        return null;
    }

    return (
        <div
            className={styles.detail_row_container}
            onClick={toggleSelected}
            data-is-selected={selected}
        >
            {label && <p>{label}</p>}
            <span>{value}</span>
        </div>
    )
}

const SelectedPhonebookContact: React.FC<{ contact: IPhonebookContact }> = ({contact}) => {
    const insertTag = useInsertTag();
    const {useChatTagAction} = useChatTagContext();

    const {first_name, last_name, company_name} = contact;
    const fullName = [first_name, last_name].join(" ");

    const containerRef = useRef<HTMLDivElement>(null);

    const [selectedDetails, setSelectedDetails] = useState<number[]>(Array.from(Array(contact.details.length).keys()));

    const toggleSelected = (i) => {
        if (selectedDetails.includes(i)) {
            setSelectedDetails(selectedDetails.filter(d => d !== i));
        } else {
            setSelectedDetails(prev => [...prev, i])
        }
    };

    const clearContact = () => {
        useChatTagAction({
            type: 'clearState'
        })
    }

    const handleShareContact = () => {
        const contactToShare = {
            first_name,
            last_name,
            company_name,
            _details: [
                ...(
                    selectedDetails.map(detail => contact.details[detail])
                )
            ]
        } as unknown as IPhonebookContact
        insertTag({
            tagType: 'contact',
            data: contactToShare,
            text: `#${[first_name, last_name].join(' ') || 'Unknown'}`
        })
        clearContact()
    }

    const detailsList: JSX.Element[] = [];
    if (contact.details?.length > 0) {
        contact.details.forEach((detail, i) => (
            detailsList.push(
                <DetailRow
                    selected={selectedDetails.includes(i)}
                    toggleSelected={() => toggleSelected(i)}
                    key={i}
                    detail={detail}
                />
            )
        ))
    }

    useOnClickOutside(containerRef, () => clearContact());

    return (
        <div
            className={styles.container}
            ref={containerRef}
        >
            <div className={styles.main_info}>
                <div className={styles.actions_container}>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        onClick={() => clearContact()}
                    >
                        <Icon name="cross"/>
                    </StyledButton>
                    <StyledButton
                        buttonStyle='text'
                        className={styles.button_add}
                        disabled={!(selectedDetails.length > 0)}
                        onClick={() => handleShareContact()}
                    >
                        <Icon name="addContact"/>
                    </StyledButton>
                </div>
                <div className={styles.text}>
                    <p>{fullName}
                    </p>
                    {company_name &&
                        <span>{company_name}</span>
                    }
                </div>
            </div>
            {detailsList.length > 0 && (
                <div className={styles.details_list_container}>
                    {detailsList}
                </div>
            )}
        </div>
    )
}

export default SelectedPhonebookContact;
