export interface KeypadState {
    activeCall: string;
    readyToClose: boolean;
    keypadActiveStatus: boolean;
    widgetActive: boolean;
    wasDragged: boolean;
}

export const initialState: KeypadState = {
    activeCall: '',
    readyToClose: true,
    keypadActiveStatus: false,
    widgetActive: false,
    wasDragged: false,
}

export type KeypadAction =
    {
        type: 'SetActiveCall',
        payload: string,
    } |
    {
        type: 'SetActiveStatus',
        payload: boolean,
    } |
    {
        type: 'SetWidgetActive',
        payload: boolean,
    } |
    {
        type: 'SetReadyToClose',
        payload: boolean,
    } |
    {
        type: 'SetWasDragged',
        payload: boolean,
    }

export const reducer = (state: KeypadState, action: KeypadAction): KeypadState => {

    switch (action.type) {
        case "SetActiveCall":
            if (action.payload === state.activeCall) return state;
            return {
                ...state,
                activeCall: action.payload
            }
        case "SetActiveStatus":
            if (action.payload === state.keypadActiveStatus) return state;
            return {
                ...state,
                keypadActiveStatus: action.payload
            }
        case "SetWidgetActive":
            if (action.payload === state.widgetActive) return state;
            return {
                ...state,
                widgetActive: action.payload
            }
        case "SetReadyToClose":
            if (action.payload === state.readyToClose) return state;
            return {
                ...state,
                readyToClose: action.payload
            }
        case "SetWasDragged":
            if (action.payload === state.wasDragged) return state;
            return {
                ...state,
                wasDragged: action.payload
            }
        default:
            return state
    }
}