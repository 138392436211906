import React, {forwardRef, ReactNode, useRef} from 'react';
import AriaText from "./AriaText";
import styles from './styles.module.scss'
import {focusNext} from "../../helpers";

interface Props {
    children: ReactNode;
    className: string;
    title: string;
    extraOnKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    dataTags?: Record<string, string | boolean | null>;
}

const AriaListFocusContainer = forwardRef<any, Props>(({children, className, title, extraOnKeyDown, dataTags}, ref) => {
    const topOfContainerRef = useRef<HTMLDivElement>(null);
    const bottomOfContainerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={className}
            ref={ref}
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    if (topOfContainerRef.current && e.target !== topOfContainerRef.current) {
                        e.stopPropagation();
                        e.preventDefault();
                        topOfContainerRef.current.focus()
                    }
                }
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...dataTags}
        >
            <div
                ref={topOfContainerRef}
                className={styles.aria_top_of_container}
                /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                tabIndex={0}
                title={title}
                onKeyDown={(e) => {
                    if (e.key === 'Tab' && e.shiftKey) return;
                    if (e.key === 'Tab') {
                        e.preventDefault()
                        e.stopPropagation()
                        if (bottomOfContainerRef.current) {
                            focusNext({
                                ref: bottomOfContainerRef.current,
                            })
                        }
                    }
                    if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Enter', 'Space'].includes(e.key)) {
                        e.stopPropagation()
                        e.preventDefault()
                        if (topOfContainerRef.current) {
                            focusNext({
                                container: topOfContainerRef.current.parentElement as HTMLElement
                            })
                        }
                    }
                    if (extraOnKeyDown) {
                        extraOnKeyDown(e)
                    }
                }}
            >
                <AriaText text='Use arrow keys or press Enter to use '/>
            </div>
            {children}
            <div
                ref={bottomOfContainerRef}
                className={styles.aria_bottom_of_container}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                onFocus={() => topOfContainerRef.current && topOfContainerRef.current.focus()}
            >
                <AriaText text='Bottom of container'/>
            </div>
        </div>

    );
});

export default AriaListFocusContainer;
