import React, {forwardRef, useRef, useState} from 'react';
import {useFocusManager} from "react-aria";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    selectActivePage,
    selectAllChatUnreadMessages, selectAppAllowMeet,
    selectCurrentUser,
    updateActivePage,
} from '../../redux/slices';
import {isPreview, useDnd, useOnClickOutside} from '../../helpers';
import Icon from "../Icon";
import {IPages, PageTypes} from "../../types/navigation";
import {usePolyglot} from "../../context/Polyglot";
import NavigationQuickMenu from "./children/NavigationQuickMenu";
import AriaText from "../AriaComponents/AriaText";
import EntityIcon from "../icons/EntityIcon";
import AriaButton from "../AriaComponents/AriaButton";

interface NavbarProps {
}

export const Navbar = forwardRef<HTMLElement, NavbarProps>((props, ref) => {
    const user = useTypedSelector(selectCurrentUser);
    const unreadMessages = useTypedSelector(selectAllChatUnreadMessages);
    const page = useTypedSelector(selectActivePage);
    const allowMeet = useTypedSelector(selectAppAllowMeet);

    const {
        dnd,
    } = useDnd();

    const [showQuickMenu, setShowQuickMenu] = useState(false);

    const quickSettingsRef = useRef<HTMLDivElement>(null);

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    const focusManager = useFocusManager();
    const onKeyDown = (e: { key: string }) => {
        if (!focusManager) return;
        switch (e.key) {
            case 'ArrowUp':
                focusManager.focusPrevious({wrap: true});
                break;
            case 'ArrowDown':
                focusManager.focusNext({wrap: true});
                break;
        }
    };

    useOnClickOutside(quickSettingsRef, () => setShowQuickMenu(false))

    const tabs: IPages[] = [
        {title: t("terms.call", 2), page: 'phone', icon: 'phoneCall'},
        {title: t("terms.chat", 2), page: 'chat', icon: 'messageFilled'},
    ];

    if (isPreview || allowMeet) {
        tabs.push({title: t("terms.video", 2), page: 'video', icon: 'videoFilled'})
    }

    tabs.push({title: t("terms.setting", 2), page: 'settings', icon: 'settings'})

    if (isPreview) {
        tabs.push(
            {title: 'Design', page: 'design', icon: 'folder'}
        )
    }


    const getTabClass = (tabPage: PageTypes) => {
        const classNames = ['nav-bar__menu-item']

        if ((page === tabPage)) {
            classNames.push('active')
        }

        return classNames.join(' ');
    }

    const onTabClick = (tabPage: PageTypes) => {
        dispatch(
            updateActivePage(tabPage)
        )
    }

    return (
        <nav className='nav-bar' ref={ref}>
            <div
                className='nav-bar__user-settings'
                ref={quickSettingsRef}
                onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget) {
                        setShowQuickMenu(false)
                    }
                }}
            >
                <AriaButton
                    className='nav-bar__menu-item'
                    onClick={() => setShowQuickMenu(!showQuickMenu)}
                    data-user-icon='true'
                    onKeyDown={(e) => onKeyDown(e)}
                    title='User Menu'
                >
                    <AriaText text='Click to open'/>
                    <div className={`nav-bar__icon-container nav-bar__user ${dnd ? 'nav-bar__dnd-warning' : ''}`}>
                        <EntityIcon
                            type='user'
                            details={{
                                avatar_key: user.avatar_key,
                                display_name: user.nickname,
                                sip_name: user.name,
                                blf: true,
                                uuid: user.uuid
                            }}
                        />
                    </div>
                </AriaButton>
                {showQuickMenu ? (
                    <NavigationQuickMenu/>
                ) : null}
            </div>
            {tabs.map(tab => (
                <button
                    key={tab.page}
                    id={tab.title}
                    className={getTabClass(tab.page)}
                    onClick={() => onTabClick(tab.page)}
                    onKeyDown={onKeyDown}
                    title={tab.title}
                >
                    <Icon name={tab.icon} width={32} height={32} ariaHidden/>
                    {tab.page === 'chat' && unreadMessages > 0 &&
                        <div className='nav-bar__chat-notification'/>
                    }
                </button>
            ))}
        </nav>
    );
});
