import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    blindTransferCall, selectAttendedTransferOpt,
    selectTransferringCall, setTransferringCall
} from "../../../../redux/slices";
import {useCallerDisplayNameGeneric, useOnClickOutside} from "../../../../helpers";
import CallInfo from "../../CallInfo";
import CallProvider from "../../../../context/CallContext/context";
import Icon from "../../../Icon";
import {TransferringCall} from "../../../../types";
import {AnimatedDiv} from "../../../../animation";
import {usePolyglot} from "../../../../context/Polyglot";
import AriaButton from "../../../AriaComponents/AriaButton";
import {useKeypadContext} from "../../../../context/KeypadContext/context";

interface Props {
}

export const TransferringSectionData: React.FC<{ callee: string; displayName: string; }> = ({callee, displayName}) => {

    const {
        type,
        name,
    } = useCallerDisplayNameGeneric(callee === displayName ? callee : undefined)

    return (
        <>
            <p>
                {type === 'unknown' ? displayName || name : name}
            </p>
            <p>
                {callee !== name ? callee : null}
            </p>
        </>
    )
}

const TransferringKeypad: React.FC<Props> = () => {
    const {keypadState, useKeypadAction} = useKeypadContext();

    const alwaysAttendedTransfer = useTypedSelector(selectAttendedTransferOpt);
    const transferringCall: TransferringCall = useTypedSelector(selectTransferringCall);
    const callWithTransfer = useTypedSelector(state => state.sip?.calls?.find(call => call.id === transferringCall?.call));

    const [showTransferOptions, setShowTransferOptions] = useState<boolean>(false);
    const [transferOption, setTransferOption] = useState<'attended' | 'blind'>(alwaysAttendedTransfer ? 'attended' : 'blind');

    const transferDropdownRef = useRef<HTMLDivElement>(null);

    const {t} = usePolyglot();

    useOnClickOutside(transferDropdownRef, () => setShowTransferOptions(false))

    const dispatch = useTypedDispatch()

    useEffect(() => {
        setTransferOption(alwaysAttendedTransfer ? 'attended' : 'blind')
    }, [alwaysAttendedTransfer]);

    useEffect(() => {
        if (transferringCall?.call && !keypadState.keypadActiveStatus) {
            useKeypadAction({
                type: "SetActiveStatus",
                payload: true
            })
        }

    }, [transferringCall]);

    if (!callWithTransfer || !transferringCall) {
        return null
    }


    const handleTransfer = () => {
        if (transferOption === 'attended') {
            dispatch({
                type: 'sip/call',
                payload: {
                    callee: transferringCall.transferringData.callee,
                    displayName: transferringCall.transferringData.displayName,
                    beingTransferredTo: callWithTransfer.id
                }
            });
        } else {
            dispatch(
                blindTransferCall(callWithTransfer.callee || '', transferringCall.transferringData.callee, callWithTransfer.id)
            );
        }

        dispatch(
            setTransferringCall()
        )
    }

    const handleTransferOptionChange = () => {
        setTransferOption(transferOption === 'attended' ? 'blind' : 'attended')
        setShowTransferOptions(false);
    }

    return (
        <div
            className={styles.container}
        >
            <div className={styles.original_call}>
                <CallProvider call={callWithTransfer}>
                    <CallInfo/>
                </CallProvider>
            </div>
            <div
                className={styles.transferring_data}
            >
                <div
                    className={styles.options_dropdown}
                >
                    <div
                        ref={transferDropdownRef}
                        onBlur={(e) => {
                            if (!e.currentTarget.contains(e.relatedTarget)) {
                                setShowTransferOptions(false)
                            }
                        }}
                    >
                        <AriaButton onClick={() => setShowTransferOptions(!showTransferOptions)}>
                            <p>
                                {transferOption === 'attended'
                                    ? t("adjective.transfer_attended")
                                    : t("adjective.transfer_blind")
                                }
                            </p>
                            {showTransferOptions ? (
                                <Icon name='arrowDown' width={22} height={22}/>
                            ) : (
                                <Icon name='arrowRight' width={22} height={22}/>
                            )}
                        </AriaButton>
                        <AnimatedDiv visible={showTransferOptions}>
                            <ul>
                                <AriaButton onClick={() => handleTransferOptionChange()}>
                                    {transferOption === 'blind'
                                        ? t("adjective.transfer_attended")
                                        : t("adjective.transfer_blind")
                                    }
                                </AriaButton>
                            </ul>
                        </AnimatedDiv>
                    </div>
                    <p>
                        {t("phrases.transfer_to")}
                    </p>
                </div>
                <div className={styles.transfer_to_data}>
                    <TransferringSectionData
                        callee={transferringCall.transferringData.callee}
                        displayName={transferringCall.transferringData.displayName || ''}
                    />
                </div>
            </div>
            <div className={styles.button_container}>
                <AriaButton
                    onClick={() => {
                        dispatch(
                            setTransferringCall()
                        )
                    }}
                >
                    <p>
                        {t("actions.cancel")}
                    </p>
                </AriaButton>
                <AriaButton
                    onClick={() => handleTransfer()}
                >
                    <p>
                        {t("actions.confirm")}
                    </p>
                </AriaButton>
            </div>
        </div>
    );
};

export default TransferringKeypad;
