import {useEffect, useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {useChatInputContext} from "../../../../context/ChatInputContext/context";
import {AnimatedDiv} from "../../../../animation";
import Reply from "../../Reply";
import MessageProvider from "../../../../context/MessageContext/context";
import styles from "./ReplyTo.module.scss";
import Icon from "../../../../components/Icon";
import {clearActiveMessageReply} from "../../../../redux/slices";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

interface Props {
    focusOnEditor: () => void;
}

const ReplyTo: React.FC<Props> = ({focusOnEditor}) => {
    const {chat} = useChatInputContext();
    const [showPreview, setShowPreview] = useState(false);

    const dispatch = useTypedDispatch();

    const handleDelete = () => {
        dispatch(clearActiveMessageReply(chat.uuid));
    }

    const messageReply = useTypedSelector(state => state.chat.messageReplies[chat.uuid]);

    useEffect(() => {
        focusOnEditor()
    }, [messageReply?.message.uuid]);

    return (
        <AnimatedDiv visible={!!messageReply} className={styles.reply_to_container}
                     onMouseOver={() => setShowPreview(true)}
                     onMouseOut={() => setShowPreview(false)}
        >
            <MessageProvider message={messageReply?.message}>
                <Reply input/>
            </MessageProvider>
            <div className={styles.primary_action_container} data-is-download={!handleDelete}>
                <AnimatedDiv visible={showPreview}>
                    <AriaButton
                        onClick={() => handleDelete()}
                    >
                        <Icon name='cross'/>
                    </AriaButton>
                </AnimatedDiv>
            </div>
        </AnimatedDiv>
    )
}

export default ReplyTo;
