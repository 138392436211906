import React, {FunctionComponent, ReactNode} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import styles from "./styles.module.scss";
import {AriaRadio, AriaRadioGroup} from "../AriaComponents/AriaRadioGroup";

type Props = {
    name: string;
    label: string;
    required?: boolean;
    readOnly?: boolean;
    orientation?: 'vertical' | 'horizontal';
    options: {
        icon: ReactNode;
        value: string;
    } []
};

const FormRadioGroup: FunctionComponent<Props> = ({name, label, required, readOnly, options, orientation}) => {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                         field: {onChange, value},
                         fieldState: {invalid, isTouched, isDirty, error},
                     }) => (
                <div
                    className={styles.form_radio_group}
                    data-invalid={invalid || null}
                    data-is-touched={isTouched || null}
                    data-is-dirty={isDirty || null}
                    data-error={!!error || null}
                >
                    <AriaRadioGroup
                        label={label}
                        onChange={onChange}
                        orientation={orientation || 'horizontal'}
                        isRequired={required}
                        isReadOnly={readOnly}
                    >
                        {options.map((radioItem, index) => (
                            <div
                                key={`RI_${index}_${radioItem.value}`}
                                data-is-active={value === radioItem.value || null}
                            >
                                <AriaRadio
                                    value={radioItem.value}
                                    inputHidden
                                >
                                    {radioItem.icon}
                                </AriaRadio>
                            </div>
                        ))}
                    </AriaRadioGroup>
                </div>
            )}
            rules={{
                required,
            }}
        />
    );
};

export default FormRadioGroup;
