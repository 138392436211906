import {SingleRowContactSkeleton} from "./SingleRowContactSkeleton";
import RowsContactsSkeleton from "./RowsContactsSkeleton";
import {GridContactsSkeleton} from "./GridContactsSkeleton";

interface ContactsSkeletonProps {
    skeletonType: 'singleRow' | 'singleRowPhoneBooks' | 'manyRow' | 'manyGrid';
    text?: string;
}

export const ContactsSkeleton: React.FC<ContactsSkeletonProps> = ({skeletonType, text}) => {
    switch(skeletonType) {
        case 'singleRowPhoneBooks':
        case 'singleRow':
            return <SingleRowContactSkeleton phonebookLoader={skeletonType === 'singleRowPhoneBooks'} text={text}/>
        case "manyRow":
            return <RowsContactsSkeleton />
        case 'manyGrid':
            return (
                <div style={{marginLeft: 28}}>
                    <GridContactsSkeleton />
                </div>
            )
        default:
            return null;
    }
}