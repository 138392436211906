import {SessionState} from 'sip.js';
import {EntityState} from "@reduxjs/toolkit";
import {TypeOf, z} from "zod";
import {LoadingTypes} from './generic';
import {IDevice} from './settings';
import {IUser} from './user';
import {IHuntGroup, IMailboxMessage, IPhonebookContact, MailboxDisplay} from "./auth";
import {RoomInvite} from "../redux/services/sipApi";
import { ConferenceRingingUser } from '../components/keypad/ConferenceHooks';

export interface CdrForm {
    invite_uuid: string | undefined;
    call_uuid: string | undefined;
    note: string | undefined;
    mood: number | undefined;
    active_tags: { name: string, color: string }[],
}

interface CallFeedback {
    enabled: boolean;
    show: boolean;
    forms: CdrForm[]
}

export interface ICallState {
    calls: ICall[];
    callHistoryLoading: LoadingTypes;
    callHistoryFillerLoading: LoadingTypes;
    callHistory: IYeOldeCall[];
    callHistoryWeek: number;
    callerIdsLoading: LoadingTypes;
    callerIds: ITelecommunicationsIdentificationDisplay[];
    parkingSlotsLoading: LoadingTypes;
    parkingSlots?: IParkingSlot[];
    fullscreenCall?: {
        id: string;
        time: number;
        user: IUser;
    };
    showShortcodePage?: boolean;
    beingTransferred?: boolean;
    beingTransferredTo?: string;
    call_recording: boolean;
    global_call_recording: boolean | null;
    devices?: IDevice[];
    activeMeetingId?: string;
    callFeedback: CallFeedback;
    usersForGroupCall: (IUser | IPhonebookContact | IHuntGroup)[];
    conferenceInvitations: RoomInvite[],
    feedback_tags: CallFeedbackTag[],
    transferring_call?: TransferringCall;
    mailbox_messages: Record<string, EntityState<IMailboxMessage>>;
    mailbox_gotten: Record<string, boolean>;
    mailboxes: MailboxDisplay[];
    inter_tenant_clients: Record<string, InterTenantClient>;
    last_called: LastCalled | undefined;
}

export interface LastCalled {
    callee: string,
    display_name: string | undefined
}

export const InterTenantUserValidator = z.object({
    user_display_name: z.string(),
    extension: z.number(),
    user_sip_name: z.string(),
    user_uuid: z.string(),
    user_avatar_key: z.string().optional(),
    can_chat: z.boolean().optional(),
})

export const InterTenantValidator = z.object({
    uuid: z.string(),
    name: z.string(),
    prefix: z.number(),
    users: z.array(InterTenantUserValidator).optional(),
})

export type InterTenantClient = TypeOf<typeof InterTenantValidator>


export type InterTenantUser = TypeOf<typeof InterTenantUserValidator>

export interface TransferringCall {
    call: string;
    transferringData: {
        beingTransferredTo?: string;
        callee: string;
        displayName?: string;
    }
}

export interface ICall {
    id: string;
    startTime?: number;
    answeredTime?: number;
    terminatedTime?: number;
    createdOn?: string;
    displayName?: string;
    callee?: string;
    state?: SessionState;
    answered?: boolean;
    isOutbound?: boolean;
    isMuted?: boolean;
    isVideoActive?: boolean;
    onHold?: boolean;
    audioTrack?: MediaStreamTrack;
    videoTracks?: MediaStreamTrack[];
    smartDialOnly?: boolean;
    beingTransferred?: boolean;
    beingTransferredTo?: string;
    callSipId?: string;
    roomId?: string;
    roomMembers?: RoomMember[];
    roomRingingMembers?: ConferenceRingingUser[];
    isAdmin?: boolean;
    localRoomId?: string;
    auth_token?: string;
    socketId?: string;
    subscribed?: boolean;
    isParking?: boolean;
    smartDiallerData?: CallSmartDiallerProps;
    assertedIdentity?: AssertedIdentity;
    interTenant?: string;
    isKeepAlive?: boolean;
    cdr?: string;
    getCdr?: boolean;
}

export interface RoomMember {
    name: string,
    id: string,
    user?: string
    active: boolean
}

export interface CallSmartDiallerProps {
    type: SmartDiallerType;
    data: string;
    title: string;
}

export interface AssertedIdentity {
    name?: string,
    number?: string
}

export type SmartDiallerType = 'app' | 'registration' | 'number' | 'Unregistered';

export type CallType = 'inbound' | 'outgoing' | 'internal' | 'outbound' | 'room';

export interface IYeOldeCall {
    answered: boolean;
    call_duration: number;
    call_route?: string;
    call_start_time: number;
    call_type: CallType;
    cost?: number;
    created_on: string;
    from?: {
        name: string;
        nickname?: string;
        number: string;
        type?: string;
        area_name?: string;
        country?: string;
        uuid: string;
    };
    has_recording?: boolean;
    is_fax?: boolean;
    to?: {
        name: string;
        nickname?: string;
        number: string;
        type?: string;
        area_name?: string;
        country?: string;
        uuid: string;
    };
    roomId?: string;
    room_members?: RoomMember[];
    uuid: string;
    getCdr?: string;
}

export interface ITelecommunicationsIdentificationDisplay {
    cli_display: string;
    cli_name: string;
    created_on: string;
    number: string;
    status_id: number;
    uuid: string;
}

export interface IParkingSlot {
    direction: string;
    short_code: string;
    slot_number: number;
}

export interface ICRMIntegration {
    name: string;
    uuid: string;
    supports_call_feedback?: boolean;
    supports_screen_pop?: boolean;
    display_name?: string;
}

export interface CallFeedbackTag {
    name: string;
    created_on: string;
}
