import React from "react";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    CallWhenAvailableUser,
    deleteCallWhenAvailable,
    selectUserStatus,
    startCall
} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";
import AppNotification from "./AppNotification";
import {StyledButton} from "../StyledComponents";

interface UserAvailableNotificationProps {
    user: CallWhenAvailableUser;
}

export const UserAvailableNotification: React.FC<UserAvailableNotificationProps> = ({user}) => {
    const status = useTypedSelector((state) => selectUserStatus(state, user.sip_name));

    const dispatch = useTypedDispatch()
    const {t} = usePolyglot();


    if (status?.status !== 'open' || status?.global_dnd) return null;

    return (
        <AppNotification
            title={t("phrases.%name_is_now_available", {name: user.display_name})}
            message={undefined}
            onClose={() => dispatch(deleteCallWhenAvailable(user.user_uuid))}
            controls={(<>
                <StyledButton
                    buttonStyle='primary'
                    context="success"
                    size='large'
                    onClick={() => {
                        dispatch(startCall(user.extension, user.display_name));
                        dispatch(deleteCallWhenAvailable(user.user_uuid))
                    }}
                >
                    <p>
                        {t("actions.call")}
                    </p>
                </StyledButton>
                <StyledButton
                    buttonStyle='text'
                    context="danger"
                    size='medium'
                    onClick={() => {
                        dispatch(deleteCallWhenAvailable(user.user_uuid))
                    }}
                >
                    <p>
                        {t("actions.cancel")}
                    </p>
                </StyledButton>
            </>)}
        />
    )
}

export default UserAvailableNotification;