import React, {ReactNode, useMemo} from 'react';
import moment from "moment";
import FileSaver from 'file-saver';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    addOneToast,
    selectAuthUserByUuid,
    selectCurrentUserId,
    selectFocusAttachment,
    setFilePreview
} from "../../../redux/slices";
import {formatBytes} from "../Attachment";
import styles from "./FilePreview.module.scss";
import Icon from "../../../components/Icon";
import {DownloadButton} from "../Attachment/children";
import ModalFormContainer from "../../../components/forms/modalFormContainer/ModalFormContainer";
import {FocusMessageAttachment} from "../../../types";
import {StyledButton} from "../../../components/StyledComponents";

interface FilePreviewProps {
    file: FocusMessageAttachment,
    onClose: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({file, onClose}) => {
    const {
        src,
        file_name,
        file_size,
        file_type,
        shared_at,
        shared_by,
        uuid
    } = file;

    const userUuid = useTypedSelector(selectCurrentUserId);
    const sharedUserUser = useTypedSelector(state => selectAuthUserByUuid(state, shared_by));

    const dispatch = useTypedDispatch();

    const previewContent = useMemo((): ReactNode => {
        switch (file_type) {
            case 'image':
                return (
                    <img
                        src={src}
                        alt='Preview'
                    />
                );
            case 'video':
                return (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                            src={src}
                            controls
                        />
                    </>
                );
            case 'pdf':
                return (
                    <div className={styles.iframe_container}>
                        <iframe
                            src={`${src}#toolbar=0`}
                            title='PDF'
                        />
                    </div>
                );
            default:
                return null
        }
    }, []);

    if (!previewContent) {
        onClose();
    }

    const downloadFile = () => {
        try {
            fetch(`/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${uuid}&content=true&thumbnail=false`)
                .then(resp => resp.blob())
                .then(blob => {
                    FileSaver.saveAs(blob, file_name);
                })
        } catch (error) {
            dispatch(addOneToast({
                title: "Failed Download",
                content: `Failed to download ${file_name}`,
                type: "error"
            }))
        }
    }

    return (
        <div
            className={styles.preview_container}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose()
                }
            }}
        >
            <div className={styles.preview}>
                <div className={styles.preview_info}>
                    {sharedUserUser ? (
                        <h1>
                            {sharedUserUser.nickname}
                        </h1>
                    ) : null}
                    <p>Sent {moment(shared_at).format("dddd, hh:mm A")}</p>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        onClick={onClose}
                    >
                        <Icon name='cross'/>
                    </StyledButton>
                </div>
                <div className={styles.preview_content}>
                    {previewContent}
                </div>
                {file?.uuid ? (
                    <div className={styles.preview_download}>
                        {file_name && file_size ? (
                            <p>{file_name} ({formatBytes(file_size)})</p>
                        ) : null}
                        <DownloadButton
                            download={() => downloadFile()}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

const FilePreviewWrapper: React.FC = () => {
    const file = useTypedSelector(selectFocusAttachment);

    const dispatch = useTypedDispatch();

    const onClose = () => {
        dispatch(setFilePreview());
    }

    return (
        <ModalFormContainer
            showModal={!!file}
            setShowModal={() => onClose()}
        >
            <FilePreview
                file={file}
                onClose={onClose}
            />
        </ModalFormContainer>
    )
}

export default FilePreviewWrapper;
