/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { ChatHeader } from '../ChatHeader/ChatHeader';
import ChatInputProvider from '../../../context/ChatInputContext/context';
import { isIntegration } from '../../../types';
import FilePreview from '../FilePreview';
import { useChatContext } from '../../../context/ChatContext/context';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import ChatInput from '../ChatInput';
import { addOneToast, selectDragAndDropData } from '../../../redux/slices';
import { DragAndDrop } from '../../../components';
import LoadingSpinner from '../../../components/loading/LoadingSpinner';
import ChatBodySkeleton from '../../../components/Skeletons/ChatBodySkeleton';
import ChatSettings from '../ChatSettings';

const MessageDetails = lazy(() => import('../MessageDetails/MessageDetails'));
const ChatMessages = lazy(() => import('../ChatMessages'));

const MAX_FILE_SIZE_MB = 8;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const fallback = (
    <div
        style={{
            maxHeight: '100%',
            overflow: 'hidden',
            position: 'relative',
            height: '100%'
        }}
    >
        <div
            style={{
                height: 'fit-content',
                position: 'absolute',
                width: '100%',
                bottom: 0,
                padding: 12
            }}
        >
            <ChatBodySkeleton />
        </div>
    </div>
);

const ChatBody: React.FC = () => {
    const { chat, expandMessage } = useChatContext();

    const dadData = useTypedSelector(selectDragAndDropData);

    const uploadInputRef = useRef<HTMLInputElement>(null);

    const dispatch = useTypedDispatch();

    useEffect(() => {
        const checkFileSize = () => {
            const files = uploadInputRef.current?.files;

            if (!files) {
                return;
            }

            if (files) {
                const fineFiles: File[] = [];
                const exceededFiles: File[] = [];

                Array.from(files).forEach(file => {
                    if (file.size > MAX_FILE_SIZE_BYTES) {
                        exceededFiles.push(file);
                    } else {
                        fineFiles.push(file);
                    }
                });

                const exceededFilesCount = exceededFiles.length;
                const exceededFilesMessage =
                    exceededFilesCount > 1
                        ? `${exceededFilesCount} files exceed`
                        : 'a file exceeds';

                if (exceededFilesCount > 0) {
                    dispatch(
                        addOneToast({
                            content: `Upload failed: ${exceededFilesMessage} the ${MAX_FILE_SIZE_MB} MB limit`,
                            title: 'Upload failed',
                            type: 'error'
                        })
                    );
                    const dataTransfer = new DataTransfer();
                    fineFiles.forEach(file => dataTransfer.items.add(file));
                    if (uploadInputRef.current) {
                        uploadInputRef.current.files = dataTransfer.files;
                    }
                }
            }
        };

        const inputElement = uploadInputRef.current;
        inputElement?.addEventListener('change', checkFileSize);

        return () => {
            inputElement?.removeEventListener('change', checkFileSize);
        };
    }, [uploadInputRef]);

    const [getMessagesAfterDisabled] = useState(false);

    if (expandMessage) {
        return (
            <Suspense fallback={<LoadingSpinner color='mamba' />}>
                <MessageDetails />
            </Suspense>
        );
    }

    return (
        <DragAndDrop uploadInputRef={uploadInputRef} disabled={isIntegration(chat) || !!dadData}>
            <div className='chat'>
                <ChatHeader />
                <ChatSettings />
                <Suspense fallback={fallback}>
                    <ChatMessages />
                </Suspense>
                <ChatInputProvider chat={chat} shouldReset={!getMessagesAfterDisabled}>
                    <ChatInput uploadInput={uploadInputRef} />
                </ChatInputProvider>
                <FilePreview />
            </div>
        </DragAndDrop>
    );
};

export default ChatBody;
