import {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/hooks";
import {selectApiOnlyPhonebooks} from "../../redux/slices";
import {useGetContactNameByNumberQuery} from "../../redux/services/sipApi";

interface Props {
    addNumberForReq: (number: string) => void
}

export const ContactNamesContext = createContext<Props | null>(null)

export const useContactNamesContext = () => {
    const context = useContext(ContactNamesContext)

    if (!context?.addNumberForReq) {
        throw new Error(
            'Component requiring addNumberForReq rendered outside ContactNamesProvider'
        )
    }

    return {
        addNumberForReq: context.addNumberForReq
    };
}

export const ContactNamesProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);

    const [pushNumbers, setPushNumbers] = useState<string[]>([])

    useGetContactNameByNumberQuery(
        {
            numbers: pushNumbers,
            phonebooks: apiPhonebooks
        },
        {
            skip: apiPhonebooks.length < 1 || pushNumbers.length < 1
        }
    )

    const numbersArr = useRef<string[]>([]);
    const timer = useRef<NodeJS.Timeout>();

    const addNumberForReq = (num: string) => {
        if (apiPhonebooks.length < 1 || numbersArr.current.includes(num)) return;

        numbersArr.current.push(num);

        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setPushNumbers(numbersArr.current)
            numbersArr.current = [];
        }, 1000)
    }

    useEffect(() => () => {
        clearTimeout(timer.current);
    }, []);

    return (
        <ContactNamesContext.Provider value={{addNumberForReq}}>
            {children}
        </ContactNamesContext.Provider>
    )
}

export default ContactNamesProvider