import {useLayoutEffect, useState} from "react";

interface useAudioAttachmentProps {
    initialSrc?: string;
    srcName?: string;
    initialFile?: File;
}

export const useAudioAttachment = ({initialSrc, initialFile, srcName}: useAudioAttachmentProps) => {
    const [file, setFile] = useState<File>();
    const [decodedAudio, setDecodedAudio] = useState<AudioBuffer | undefined>(undefined);
    const [failed, setFailed] = useState(false);

    const getDataFromSrc = () => {
        if (!initialSrc) return;

        fetch(initialSrc)
            .then((initialAudio) => {
                initialAudio.blob()
                    .then(audioBlob => {
                        if (audioBlob.size > 0 && audioBlob.type !== 'text/plain') {
                            setFile(new File([audioBlob], srcName || 'audio'))
                            getDataFromFile(audioBlob)
                        }
                    })
            })
    }

    const getDataFromFile = (queryFile: File | Blob) => {
        if (!queryFile) return;
        // Create instance of FileReader
        const reader = new FileReader();

        // When the file has been succesfully read
        reader.onload = function (event) {
            if (!event || !event.target || !event.target.result) {
                setFailed(true);
                return;
            }


            // Create an instance of AudioContext
            const audioContext = new AudioContext();
            // Asynchronously decode audio file data contained in an ArrayBuffer.
            audioContext.decodeAudioData(event.target.result as ArrayBuffer, (buffer) => {
                if (buffer) {
                    setDecodedAudio(buffer)
                }
            })
                .catch(() => {
                    setFailed(true);
                });
        };

        // In case that the file couldn't be read
        reader.onerror = function (event) {
            setFailed(true);
            console.error("An error occurred reading the file: ", event);
        };

        // Read file as an ArrayBuffer, important !
        reader.readAsArrayBuffer(queryFile);
    }

    useLayoutEffect(() => {
        if (initialSrc) getDataFromSrc();

        if (initialFile) {
            setFile(initialFile)
            getDataFromFile(initialFile)
        }
    }, []);

    return {
        file,
        decodedAudio,
        failed
    }
}