import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {v4 as uuidv4} from "uuid";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {addSmartDiallerNumber, selectUserCountryCode} from "../../../redux/slices";
import {getValidNumber} from "../../sidebars/Contact/Contact";

export const usePhonebookContactEditor = ({closeEditor}: { closeEditor: () => void; }) => {
    const countryCode = useTypedSelector(selectUserCountryCode);

    const dispatch = useTypedDispatch();

    const RegisterNumberSchema = object({
        name: z.string().optional(),
        value: z.string().min(1, {
            message: 'Number is required'
        })
            .refine((val: string) => !!getValidNumber(val, countryCode), {
                message: 'Invalid phone number'
            }),
    })
    type RegisterNumberFormInput = TypeOf<typeof RegisterNumberSchema>;

    const formMethods = useForm<RegisterNumberFormInput>({
        resolver: zodResolver(RegisterNumberSchema),
        defaultValues: {
            name: '',
            value: '',
        }
    });

    const saveNumber = (formData: RegisterNumberFormInput) => {
        dispatch(addSmartDiallerNumber({
            name: formData.name,
            value: formData.value,
            uuid: uuidv4(),
        }))

        closeEditor();
    };

    return {
        formMethods,
        saveNumber,
    }
}