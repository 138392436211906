/* eslint-disable no-return-await */
import React, {useState, useEffect} from 'react';
import {Zoom} from "@mui/material";
import {Button} from '../buttons';
import {GenericNotification} from './GenericNotification';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    selectActivePage,
    selectSettingsPage,
    updateActivePage,
    updatePhoneSettings,
    updateSettingsPage
} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";

interface AudioNotificationProps {
}

export const AudioNotification: React.FC<AudioNotificationProps> = () => {
    const page = useTypedSelector(selectActivePage);
    const settingsPage = useTypedSelector(selectSettingsPage)

    const [showNotification, setShowNotification] = useState(false);

    const outputDeviceIdRingtone = useTypedSelector(
        state => state.user.settings.phone.settings?.outputDeviceIdRingtone
    );
    const outputDeviceIdCall = useTypedSelector(
        state => state.user.settings.phone.settings?.outputDeviceIdCall
    );
    const inputDeviceId = useTypedSelector(
        state => state.user.settings.phone.settings?.inputDeviceId
    );

    const dispatch = useTypedDispatch()
    const {t} = usePolyglot();

    const getMediaDevices = async () =>
        await navigator.mediaDevices
            .enumerateDevices()
            .then(enumerateDevices =>
                enumerateDevices.flat().map(device => ({
                    deviceId: device.deviceId,
                    label: device.label
                }))
            )
            .catch(() => []);

    useEffect(() => {
        if (navigator && "mediaDevices" in navigator && (page === 'settings' && settingsPage === 'audio')) {
            navigator.mediaDevices.ondevicechange = async () => {
                const devices = await getMediaDevices();

                const outputRingtoneExists = devices.some(
                    device => device.deviceId === outputDeviceIdRingtone
                );
                const outputCallExists = devices.some(device => device.deviceId === outputDeviceIdCall);
                const inputExists = devices.some(device => device.deviceId === inputDeviceId);

                switch (true) {
                    case !outputRingtoneExists:
                        dispatch(
                            updatePhoneSettings({
                                setting: 'outputDeviceIdRingtone',
                                value: 'default'
                            })
                        );
                        dispatch(
                            updatePhoneSettings({
                                setting: 'outputDeviceNameRingtone',
                                value: 'Default'
                            })
                        );
                        setShowNotification(true);
                        break;

                    case !outputCallExists:
                        dispatch(
                            updatePhoneSettings({
                                setting: 'outputDeviceIdCall',
                                value: 'default'
                            })
                        );
                        dispatch(
                            updatePhoneSettings({
                                setting: 'outputDeviceNameCall',
                                value: 'Default'
                            })
                        );
                        setShowNotification(true);
                        break;

                    case !inputExists:
                        dispatch(
                            updatePhoneSettings({
                                setting: 'inputDeviceId',
                                value: 'default'
                            })
                        );
                        setShowNotification(true);
                        break;

                    default:
                        setShowNotification(false);
                }
            };
        }

        return () => {
            if (navigator && "mediaDevices" in navigator) {
                navigator.mediaDevices.ondevicechange = null;
            }
        };
    }, [outputDeviceIdRingtone, outputDeviceIdCall, inputDeviceId]);

    if (!showNotification || (page === 'settings' && settingsPage === 'audio')) return null;

    return (
        <Zoom in>
            <div>
                <GenericNotification
                    className='info'
                    title={t("phrases.audio_device_changed")}
                    message={t("phrases.go_to_settings_to_change_audio_preferences")}
                    icon='headphones'
                    crossClick={() => setShowNotification(false)}
                    button={
                        <Button
                            onClick={() => {
                                setShowNotification(false);
                                dispatch(updateActivePage('settings'))
                                dispatch(updateSettingsPage('audio'))
                            }}
                        >
                            {t("actions.go_to_settings")}
                        </Button>
                    }
                />
            </div>
        </Zoom>
    );
};
