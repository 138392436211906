import React from 'react';
import styles from './styles.module.scss';
import {StyledButton} from "./StyledButton";
import {SharedOptionProps} from "./StyledDropdown";
import {PhonePageType} from "../../types";

interface Props {
    options: ITab[],
    selected: string;
    onChange: (val: string) => void;
}

export interface ITab extends SharedOptionProps {
    value: PhonePageType;
    notification_count?: number;
}

const StyledPageTabs: React.FC<Props> = ({options, selected, onChange}) => (
    <div className={styles.styled_page_tabs}>
        {options.map(opt => (
            <StyledButton
                key={opt.value}
                buttonStyle='text'
                disabled={!!opt.disabled}
                onClick={() => onChange(opt.value)}
                dataTags={{
                    "data-selected": selected === opt.value || null
                }}
            >
                <h2>
                    {opt.label}
                    {opt.notification_count ? (
                        <i>
                            {opt.notification_count > 99 ? "+99" : opt.notification_count}
                        </i>
                    ) : null}
                </h2>
            </StyledButton>
        ))}
    </div>
);

export default StyledPageTabs;
