import {Editor, Transforms} from 'slate';
import {ReactEditor} from "slate-react";

const selectedNode = editor => editor.selection && Editor.node(editor, editor.selection.focus);

const isTag = editor => !!selectedNode(editor)?.[0]?.tagType

const withTag = (editor: ReactEditor) => {
    const {deleteBackward, insertText} = editor;

    // eslint-disable-next-line no-param-reassign
    editor.deleteBackward = unit => {

        if (isTag(editor)) {
            Transforms.removeNodes(editor, {
                at: selectedNode(editor)[1]
            })
        } else {
            deleteBackward(unit)
        }
    }

    // eslint-disable-next-line no-param-reassign
    editor.insertText = text => {
        if (isTag(editor)) {
            const node = selectedNode(editor);
            Transforms.insertNodes(editor,
                {
                    text: ` ${text}`
                },
                {
                    at:
                        {
                            path: node[1], offset: node[0].text.length
                        },
                },
            );
            const newPath = [node[1][0], node[1][1] + 1];
            Transforms.select(editor, {
                path: newPath, offset: text.length + 1
            })
        } else {
            insertText(text)
        }
    }
    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        if (isTag(editor)) {
            const node = selectedNode(editor);
            Transforms.insertNodes(editor,
                {
                    text: " "
                },
                {
                    at:
                        {
                            path: node[1], offset: node[0].text.length
                        },
                },
            );
        } else {
            // insertBreak()
        }
    }

    return editor;
}

export default withTag;
