import React, { useState, useEffect } from 'react';

interface CounterProps {
    initialTime?: number;
}

export const Counter: React.FC<CounterProps> = ({ initialTime }) => {
    const [counter, setCounter] = useState<number>(initialTime || 0);

    useEffect(() => {
        const timer = setInterval(() => setCounter(preState => preState + 1), 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, []);

    return <>{new Date(1000 * counter).toISOString().substr(11, 8)}</>;
};
