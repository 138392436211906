import React, {FunctionComponent, ReactNode, useEffect, useRef} from 'react';
import styles from './styles.module.scss'

interface Props {
    showModal: boolean;
    setShowModal: (val: boolean) => void;
    children: ReactNode;
    asDialogue?: boolean;
}

const ModalFormContainer: FunctionComponent<Props> = ({showModal, setShowModal, children, asDialogue}) => {

    const formDialogueRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (!formDialogueRef.current) return;

        if (asDialogue) {
            if (showModal) {
                formDialogueRef.current.show();
                return
            }

            if (formDialogueRef.current.open) {
                formDialogueRef.current.close()
            }
        } else {
            if (showModal) {
                formDialogueRef.current.showModal();
                return
            }

            if (formDialogueRef.current.open) {
                formDialogueRef.current.close()
            }
        }

    }, [showModal]);

    return (
        <dialog
            ref={formDialogueRef}
            onClose={() => setShowModal(false)}
            className={styles.form_dialogue}
            data-as-dialogue={asDialogue || null}
        >
            {showModal ? (
                children
            ) : null}
        </dialog>
    );
};

export default ModalFormContainer;
