import StyledSkeleton from "../StyledSkeleton";


export const CallHistorySkeleton = () => {
    const middle = {
        right: '50%',
        transform: 'translateX(50%)',
    }

    return (
        <div style={{position: 'relative'}}>
            <StyledSkeleton name="Date Divider"
                            style={{top: 3.198486328125, ...middle, width: 145.4429931640625, height: 9.934326171875}}/>

            <StyledSkeleton name="Icon"
                            style={{top: 27.333251953125, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 29.604248046875,
                left: 45.9376220703125,
                width: 217.5081787109375,
                height: 16.370361328125
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 49.993896484375,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called"
                            style={{top: 30.898193359375, right: 17, width: 38.05517578125, height: 11.590087890625}}/>
            <StyledSkeleton name="Call Time"
                            style={{top: 50.13330078125, right: 17, width: 120.52734375, height: 10.332763671875}}/>

            <StyledSkeleton name="Icon"
                            style={{top: 87, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 89.37939453125,
                left: 45.8447265625,
                width: 219.429443359375,
                height: 16.207763671875
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 109.66064453125,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called"
                            style={{top: 90.56494140625, right: 17, width: 38.05517578125, height: 11.590087890625}}/>
            <StyledSkeleton name="Call Time" style={{
                top: 109.800048828125,
                right: 17,
                width: 68.205810546875,
                height: 10.332763671875
            }}/>

            <StyledSkeleton name="Icon"
                            style={{top: 146.66650390625, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 149.7666015625,
                left: 45.9144287109375,
                width: 75.9942626953125,
                height: 12.054931640625
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 169.3271484375,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called"
                            style={{top: 150.2314453125, right: 17, width: 38.05517578125, height: 11.590087890625}}/>
            <StyledSkeleton name="Call Time" style={{
                top: 169.466552734375,
                right: 17,
                width: 68.205810546875,
                height: 10.332763671875
            }}/>

            <StyledSkeleton name="Date Divider"
                            style={{top: 219.73193359375, ...middle, width: 142.580810546875, height: 9.934326171875}}/>

            <StyledSkeleton name="Icon"
                            style={{top: 248.39990234375, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 250.6708984375,
                left: 45.9376220703125,
                width: 217.5081787109375,
                height: 16.370361328125
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 271.060546875,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called"
                            style={{top: 251.5, right: 17, width: 112.19775390625, height: 15.285400390625}}/>
            <StyledSkeleton name="Call Time"
                            style={{top: 271.199951171875, right: 17, width: 120.52734375, height: 10.332763671875}}/>

            <StyledSkeleton name="Icon"
                            style={{top: 308.066650390625, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 310.337646484375,
                left: 45.9376220703125,
                width: 217.5081787109375,
                height: 16.370361328125
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 330.727294921875,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called" style={{
                top: 311.166748046875,
                right: 17,
                width: 112.19775390625,
                height: 15.285400390625
            }}/>
            <StyledSkeleton name="Call Time"
                            style={{top: 330.86669921875, right: 17, width: 120.52734375, height: 10.332763671875}}/>

            <StyledSkeleton name="Icon"
                            style={{top: 367.733154296875, left: 17, width: 17, height: 17}}/>
            <StyledSkeleton name="Display Name" style={{
                top: 370.004150390625,
                left: 45.9376220703125,
                width: 217.5081787109375,
                height: 16.370361328125
            }}/>
            <StyledSkeleton name="Location / Call Type" style={{
                top: 390.393798828125,
                left: 46.1435546875,
                width: 108.7535400390625,
                height: 13.1748046875
            }}/>
            <StyledSkeleton name="When Called" style={{
                top: 370.833251953125,
                right: 17,
                width: 112.19775390625,
                height: 15.285400390625
            }}/>
            <StyledSkeleton name="Call Time"
                            style={{top: 390.533203125, right: 17, width: 120.52734375, height: 10.332763671875}}/>
        </div>
    )
}

export default CallHistorySkeleton;