import React, { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import styles from './DragAndDropKeypad.module.scss';
import Icon from '../../../Icon';
import {
    addUserForGroupCall,
    selectAllCalls,
    selectAppAllowConference,
    selectDragAndDropData,
    setBeingTransferred,
    toggleHoldCall
} from '../../../../redux/slices';
import { isPhonebookContact, isUser } from '../../../../types';
import PhonebookContactKeypad from './children/PhonebookContactKeypad';
import { usePolyglot } from '../../../../context/Polyglot';
import { useCallContact } from '../../../sidebars/Contact/hooks';
import { useKeypadContext } from '../../../../context/KeypadContext/context';
import { ContinuingDAD } from '../../index';
import { useContactContext } from '../../../../context/ContactContext/context';
import { useConference } from '../../ConferenceHooks';

interface DragAndDropKeypadProps {
    continueWith?: ContinuingDAD;
    setContinueWith: (val: ContinuingDAD | undefined) => void;
}

type DndOption = 'call' | 'transfer_blind' | 'transfer_attended' | 'conference';

export const DragAndDropKeypad: React.FC<DragAndDropKeypadProps> = ({
    continueWith,
    setContinueWith
}) => {
    const { contact } = useContactContext();
    const { keypadState } = useKeypadContext();

    const conferenceAllowed = useTypedSelector(selectAppAllowConference);
    const dadData = useTypedSelector(selectDragAndDropData);
    const calls = useTypedSelector(selectAllCalls);

    const call = calls.find(c => c.id === keypadState.activeCall);

    const [activeOption, setActiveOption] = useState<DndOption | undefined>();

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const { inviteToGroup } = useConference();

    if (!continueWith && (!dadData || !dadData.id || dadData.id === 'short_code')) return null;

    const { callContact } = useCallContact();

    const handleCallClick = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        event.stopPropagation();

        if (isPhonebookContact(contact)) {
            setContinueWith({
                context: 'new',
                contact
            });
        } else {
            callContact(event);
        }
    };

    const handleTransfer = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        event.stopPropagation();

        if (call && !call?.onHold) {
            dispatch(toggleHoldCall(call.id, true));
        }

        if (isPhonebookContact(contact)) {
            setContinueWith({
                context: 'transfer',
                contact
            });
        } else {
            handleCallClick(event);
        }
    };

    const groupCallAdd = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        event.stopPropagation();

        if (isPhonebookContact(contact)) {
            setContinueWith({
                context: 'group',
                contact
            });
        } else if (call && call.roomId) {
            const inviteNumber = isUser(contact) ? contact.extension : contact.extension_number;
            inviteToGroup({
                invitationNumbers: [String(inviteNumber)],
                call
            });
        } else {
            dispatch(addUserForGroupCall(contact));
        }
    };

    if (continueWith) {
        return (
            <PhonebookContactKeypad
                context={continueWith.context}
                setContinueWith={setContinueWith}
                call={call || undefined}
            />
        );
    }

    return (
        <div className={styles.drag_and_drop_keypad} data-active-call={!!call || null}>
            <button
                className={styles.call}
                data-active={activeOption === 'call' || null}
                onDragEnter={() => setActiveOption('call')}
                onDragLeave={() => setActiveOption(undefined)}
                onDrop={event => handleCallClick(event)}
            >
                <Icon name='phoneCall' />
                <span>
                    {t('actions.call')}
                    <mark>{dadData?.nickname}</mark>
                </span>
            </button>
            {call?.state === 'Established' && !call.beingTransferredTo && !call.roomId ? (
                <button
                    data-active={activeOption === 'transfer_blind' || null}
                    onDragEnter={() => {
                        setActiveOption('transfer_blind');
                        dispatch(setBeingTransferred(call.id, true));
                    }}
                    onDragLeave={() => {
                        setActiveOption(undefined);
                        dispatch(setBeingTransferred(call.id, false));
                    }}
                    onDrop={event => {
                        handleTransfer(event);
                    }}
                >
                    <Icon name='transfer' />
                    <span>{t('actions.transfer')}</span>
                </button>
            ) : null}
            {((call?.roomId && call?.isAdmin) || !call?.roomId) && conferenceAllowed ? (
                <button
                    className={styles.call}
                    data-active={activeOption === 'conference' || null}
                    onDragEnter={() => setActiveOption('conference')}
                    onDragLeave={() => setActiveOption(undefined)}
                    onDrop={event => groupCallAdd(event)}
                >
                    <Icon name='group' />
                    <span>
                        {(!!call && !!call.roomId && t('phrases.invite_to_group')) ||
                            (!!call && t('phrases.create_group_call')) ||
                            t('phrases.make_group')}
                    </span>
                </button>
            ) : null}
        </div>
    );
};

export default DragAndDropKeypad;
