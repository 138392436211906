import {memo, useEffect, useRef, useState} from "react";
import {AnimatedDiv} from "../../../../animation";
import Icon from "../../../../components/Icon";
import styles from "../Attachment.module.scss";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import {useAudioAttachment} from "../hooks";
import {AttachmentNameDisplay, GenericAttachment} from "./GenerticAttachment";
import StyledTrack from "../../../../components/StyledComponents/StyledTrack";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

export const convertToMinutesSeconds = (s: number, end = false) => {
    let seconds = Math.floor(s);
    const minutes = Math.floor(s / 60);
    if (s < 1 && !end) {
        seconds = 0;
    }

    seconds -= (minutes * 60);

    return [
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
}

const Preview = ({
                     playing,
                     togglePlay,
                     loading,
                     showPreview
                 }) => (
    <div
        className={styles.preview_button_container}
        data-comp='preview_btn'
    >
        <AnimatedDiv visible={!showPreview}>
            <Icon name='microphone'/>
        </AnimatedDiv>
        <AnimatedDiv visible={loading && playing && showPreview}>
            <AriaButton className={styles.loading}>
                <LoadingSpinner/>
            </AriaButton>
        </AnimatedDiv>
        <AnimatedDiv visible={!loading && showPreview}>
            <AriaButton onClick={() => togglePlay()}>
                <Icon name={playing ? 'pauseFilled' : 'playFilled'}/>
            </AriaButton>
        </AnimatedDiv>
    </div>
)

type AudioAttachmentProps = {
    initialSrc: string;
    srcName: string;
    initialFile?: never;
} | {
    initialSrc?: never;
    srcName?: string;
    initialFile: File;
}

export interface AudioAttachmentPlayerProps {
    audioFile: File;
    trackLength: number;
    playing: boolean;
    setPlaying: (val: boolean) => void;
}

const AudioAttachmentPlayer: React.FC<AudioAttachmentPlayerProps> = ({
                                                                         audioFile,
                                                                         trackLength,
                                                                         playing,
                                                                         setPlaying
                                                                     }) => {
    const [trackProgress, setTrackProgress] = useState<number>(0);

    const audioElementSrc = useRef(URL.createObjectURL(audioFile));
    const audioElementRef = useRef<HTMLAudioElement>(null);
    const interval = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (audioElementRef.current) {
            if (playing) {
                audioElementRef.current.play();
            } else {
                audioElementRef.current.pause();
            }
        }
    }, [playing])

    const onScrub = (value) => {
        clearInterval(interval.current);
        if (audioElementRef.current) {
            audioElementRef.current.currentTime = value;
            setTrackProgress(audioElementRef.current.currentTime);
        }
        interval.current = setInterval(() => {
            if (audioElementRef.current) {
                setTrackProgress(audioElementRef.current.currentTime || 0);
            }
        }, 100);
    };

    return (<>
        <div className={styles.audio_display} data-comp='audio-attachment'>
            <AttachmentNameDisplay
                file={audioFile}
                noSizeDisplay
            />
            <span
                className={styles.audio_start}
            >
                {convertToMinutesSeconds(trackProgress)}
            </span>
            <StyledTrack
                max={trackLength}
                duration={trackLength}
                value={trackProgress}
                onScrub={onScrub}
            />
            <span
                className={styles.audio_end}
            >
                {`-${convertToMinutesSeconds(Math.ceil(trackLength) - trackProgress, true)}`}
            </span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
            src={audioElementSrc.current}
            onPlay={() => {
                clearInterval(interval.current);
                interval.current = setInterval(() => {
                    if (audioElementRef.current) {
                        setTrackProgress(audioElementRef.current.currentTime || 0);
                    }
                }, 100);
            }}
            ref={audioElementRef}
            onPause={() => clearInterval(interval.current)}
            onEnded={() => {
                setPlaying(false)
                setTrackProgress(trackLength)
            }}
            hidden
        />
    </>);
};


export const AudioAttachment: React.FC<AudioAttachmentProps> = memo(({
                                                                         initialFile,
                                                                         initialSrc,
                                                                         srcName,
                                                                     }) => {
    const [playing, setPlaying] = useState<boolean>(false);

    const {
        file,
        failed,
        decodedAudio
    } = useAudioAttachment({
        initialFile,
        initialSrc,
        srcName
    })

    if (failed) {
        return (
            <GenericAttachment file={file}/>
        )
    }

    if (!file || !decodedAudio) {
        return (
            <div className={styles.audio_attachment}>
                <LoadingSpinner color='iron'/>
            </div>
        )
    }

    return (
        <div className={styles.audio_attachment}>
            <Preview
                playing={playing}
                togglePlay={() => setPlaying(!playing)}
                loading={false}
                showPreview
            />
            <AudioAttachmentPlayer
                audioFile={file}
                trackLength={decodedAudio.duration}
                playing={playing}
                setPlaying={setPlaying}
            />
        </div>
    );
}, (prevProps, nextProps) => {
    if (prevProps.initialFile !== nextProps.initialFile) {
        return false;
    }

    if (prevProps.initialSrc !== nextProps.initialSrc) {
        return false;
    }

    return prevProps.srcName === nextProps.srcName;
});

export default AudioAttachment;
