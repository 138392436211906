import { KeyboardEvent } from '@react-types/shared/src/events';
import React, {ReactNode} from 'react';
import {LoadingSpinner} from '../loading/LoadingSpinner';
import AriaButton from "../AriaComponents/AriaButton";
import styles from './styles.module.scss';
import {PressEvent} from "../AriaComponents/types";

export type ButtonSize = 'large' | 'medium' | 'small' | '';

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'text';

export interface ButtonProps {
    active?: boolean;
    buttonStyle: ButtonStyle;
    children?: ReactNode;
    className?: string;
    context?:
        | 'branded'
        | 'info'
        | 'success'
        | 'warning'
        | 'danger'
        | 'white'
        | 'shadow'
        | 'underline'
        | 'disabled';
    dataTags?: Record<string, string | boolean | null>;
    disabled?: boolean;
    error?: string;
    fontWeight?: 'medium';
    iconOnly?: boolean;
    loading?: boolean;
    noBg?: boolean;
    onClick?: (event: PressEvent) => void;
    onKeydown?: (event: KeyboardEvent) => void;
    popupText?: string;
    popupTitle?: string;
    popupType?: 'info' | 'warning';
    showPopup?: boolean;
    size?: ButtonSize;
    title?: string;
    type?: 'button' | 'submit' | 'reset';
}

export const StyledButton: React.FC<ButtonProps> = ({
    className,
    onClick,
    type,
    showPopup,
    buttonStyle,
    title,
    popupType,
    popupTitle,
    popupText,
    disabled,
    loading,
    active,
    error,
    iconOnly,
    size,
    children,
    dataTags,
    fontWeight,
    context,
    noBg,
    onKeydown
}) => (
    <AriaButton
        type={type}
        className={[className || '', styles.styled_button].join(' ')}
        onClick={onClick}
        disabled={disabled}
        dataTags={{
            ...dataTags,
            'data-button-style': buttonStyle || null,
            'data-font-weight': fontWeight || null,
            'data-icon-only': iconOnly || loading || null,
            'data-context': context || null,
            'data-is-active': active || null,
            'data-no-bg': noBg || null,
            'data-size': size || null
        }}
        title={title}
        onKeyDown={onKeydown}
    >
        {showPopup && (
            <dialog
                className={`button__popup ${popupType || 'warning'}${
                    popupTitle ? '' : ' no-title'
                }`}
                open={showPopup}
            >
                <h6>{popupTitle}</h6>
                <p>{popupText}</p>
            </dialog>
        )}
        {loading ? (
            <div className={styles.loading_container}>
                <LoadingSpinner color={buttonStyle === 'primary' ? 'iron' : 'mamba'} />
            </div>
        ) : (
            children
        )}
        {error && (
            <div className='button__error'>
                <p>{error}</p>
            </div>
        )}
    </AriaButton>
);
