import {useMemo} from "react";
import {selectCurrentDevice, selectCurrentUserId} from "../../../../redux/slices";
import {useDeleteUserAvatarMutation, usePostUserAvatarMutation} from "../../../../redux/services/userApi";
import {useTypedSelector} from "../../../../redux/hooks";
import {blobToBase64} from "../../../../helpers";


export const useDevices = () => {
    const currentDevice = useTypedSelector(selectCurrentDevice);
    const user = useTypedSelector(state => state.user);

    const displayDevices = useMemo(() => {
        const devices: any[] = [];

        if (!user.devices || user.devices.length < 1) {
            return [];
        }

        let current: any;

        for (let i = 0; i < user.devices.length; i += 1) {
            const device = user.devices[i];

            if (device.uuid !== currentDevice) {
                devices.push(device)
            } else {
                current = device;
            }

            if (i === user.devices.length - 1) {
                devices.sort((a, b) => a.device_platform - b.device_platform)

                if (current) {
                    devices.unshift(current)
                }
            }
        }

        return devices;
    }, [user.devices, currentDevice])

    return {
        displayDevices
    }
}

export const useAccountAvatarChange = () => {
    const userUuid = useTypedSelector(selectCurrentUserId);

    const [postUserAvatar, {
        isLoading: postAvatarLoading,
        error: postAvatarError,
        isSuccess: postAvatarSuccess
    }] = usePostUserAvatarMutation();

    const [deleteUserAvatar, {
        isLoading: deleteAvatarLoading,
        error: deleteAvatarError,
        isSuccess: deleteAvatarSuccess
    }] = useDeleteUserAvatarMutation();

    const upload = async (blobToUpload?: Blob) => {
        if (blobToUpload) {
            blobToBase64(blobToUpload)
                .then((baseString) => {
                    postUserAvatar({
                        userUuid,
                        base64Img: baseString
                    })
                })
        } else {
            deleteUserAvatar({userUuid})
        }
    };

    return {
        upload,
        uploadLoading: postAvatarLoading || deleteAvatarLoading,
        uploadError: postAvatarError || deleteAvatarError,
        uploadSuccess: postAvatarSuccess || deleteAvatarSuccess,
    }
}