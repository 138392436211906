import React from 'react';
import { ConferenceRingingUser } from '../../ConferenceHooks';
import { useTypedSelector } from '../../../../redux/hooks';
import { selectAuthUserByUuid } from '../../../../redux/slices';
import TypingLoader from '../../../loading/TypingLoader/TypingLoader';
import { useCallerDisplayNameGeneric } from '../../../../helpers';

interface Props {
    data: ConferenceRingingUser;
}

const ConferenceRingingMemberUser: React.FC<{ uuid: string }> = ({ uuid }) => {
    const user = useTypedSelector(state => selectAuthUserByUuid(state, uuid));

    return <p>{user?.nickname || 'User'}</p>;
};

const ConferenceRingingMemberNumber: React.FC<{ callee: string }> = ({ callee }) => {
    const { name } = useCallerDisplayNameGeneric(callee);

    return <p>{name || callee}</p>;
};

const ConferenceRingingMember: React.FC<Props> = ({ data }) => (
    <li>
        <p>
            <TypingLoader mini />
            {data.type === 'user' ? (
                <ConferenceRingingMemberUser uuid={data.ref} />
            ) : (
                <ConferenceRingingMemberNumber callee={data.ref} />
            )}
        </p>
    </li>
);

export default ConferenceRingingMember;
