import {createContext, Dispatch, ReactNode, useContext, useMemo, useReducer} from "react";
import {IChat, IChatParticipant, isThread} from "../../types";
import {ChatContextState, chatReducer, ChatReducerAction, initialState} from "./reducer";
import {useTypedSelector} from "../../redux/hooks";
import {
    selectChatEntities,
    selectChatParticipants,
    selectCurrentUserId
} from "../../redux/slices";
import {usePolyglot} from "../Polyglot";
import {useChatName} from "../../helpers";

interface Props {
    reducer: Dispatch<ChatReducerAction>;
    state: ChatContextState;
    chat: IChat;
}

const ChatContext = createContext<Props | null>(null)

export const useChatContext = () => {
    const context = useContext(ChatContext);

    const {t} = usePolyglot();

    const userUuid = useTypedSelector(selectCurrentUserId);
    const chatDictionary = useTypedSelector(selectChatEntities);

    if (!context?.chat) {
        throw new Error(
            'Component requiring chat context rendered without a chat'
        )
    }

    const participantChatId = context.chat.type === "thread" ? context.chat.source?.source_uuid : context.chat.uuid;
    const participants = useTypedSelector(state => selectChatParticipants(state, participantChatId));

    const chatName = useChatName(context.chat, undefined);

    const chatState: ChatContextState = context.state
    const useChatAction: (val: ChatReducerAction) => void = context.reducer

    const noPriorChatHistory = useMemo(() => !!(context?.chat?.uuid && !context.chat), [context.chat, context.chat.uuid]);

    let chatTitle = noPriorChatHistory ? t("phrases.new_chat") : chatName;
    let parentMessageChatId;

    if (isThread(context.chat)) {
        parentMessageChatId =
            context.chat.thread_message?.to === userUuid
                ? context.chat.thread_message?.from
                : context.chat.thread_message?.to;

        chatTitle = parentMessageChatId && chatDictionary[parentMessageChatId]?.display_name;
    }

    const memberDictionary: Record<string, IChatParticipant> = useMemo(() =>
            Object.fromEntries(participants.map(m => [m.uuid, {...m}])),
        [participants]);

    return {
        memberDictionary,
        chatTitle,
        parentMessageChatId,
        noPriorChatHistory,
        chat: context.chat,
        expandMessage: chatState.expandMessage,
        files: chatState.filesToUpload,
        sideBarVisible: chatState.sidebarVisible,
        isScrolled: chatState.isScrolled,
        chatState,
        useChatAction
    }
}

interface ProviderProps {
    children: ReactNode;
    chat: IChat;
}

const ChatProvider: React.FC<ProviderProps> = ({children, chat}) => {
    const [state, dispatch] = useReducer(chatReducer, initialState)

    return (
        <ChatContext.Provider value={{reducer: dispatch, state, chat}}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatProvider;
