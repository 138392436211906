export interface ContactState {
    showOptions: boolean;
}

export const initialState: ContactState = {
    showOptions: false,
}

export type ContactAction =
    {
        type: 'set_show_options',
        payload: boolean,
    }

const contactReducer = (state: ContactState, action: ContactAction): ContactState => {

    switch (action.type) {
        case "set_show_options":
            return {
                ...state,
                showOptions: action.payload
            }
        default:
            return state
    }
}

export default contactReducer;