import {v4 as uuidv4} from 'uuid';
import {AppDispatch, AppThunk, RootState} from "../../store";
import {
    PhonePageType,
    SettingPageType,
    ApiGlobalSetting, GlobalSettings,
    IPhonebookContact, IHuntGroup, IUser, isUser, isHuntGroup, isPhonebookContact, SmartDiallerError, ViewContactType,
} from "../../../types";
import {appSlice} from "./slice";
import {PageTypes} from "../../../types/navigation";
import {IToast} from "../../../components/toastCenter";
import {setLastUpdatedChatId} from "../chatSlice";
import {oldParseSettings, selectCurrentUserId} from "../userSlice";
import {api} from "../../../api";

export const parseApiGlobalSettings = (apiSettings: ApiGlobalSetting[]): GlobalSettings => {
    const globalSettings: GlobalSettings = {
        do_not_disturb: false,
        selected_phonebooks: {},
        favourite_contact: {},
        pinned_chats: {},
        muted_chats: {},
        mark_chat_unread: {},
        sync_dnd_to_phones: false,
        attended_transfer: false
    }

    apiSettings.forEach(apiSetting => {
        switch (apiSetting.item_type) {
            case 1:
                globalSettings.do_not_disturb = apiSetting.data_boolean;
                break;
            case 2:
                globalSettings.selected_phonebooks[apiSetting.data_uuid] = true;
                break;
            case 3:
                globalSettings.favourite_contact[apiSetting.data_uuid] = true;
                break;
            case 4:
                globalSettings.pinned_chats[apiSetting.data_uuid] = true;
                break;
            case 5:
                globalSettings.muted_chats[apiSetting.data_uuid] = true;
                break;
            case 6:
                globalSettings.mark_chat_unread[apiSetting.data_uuid] = true;
                break;
            case  7:
                globalSettings.sync_dnd_to_phones = true;
                break;
            case 8:
                globalSettings.attended_transfer = apiSetting.data_boolean
                break;
            default:
                console.error("UNABLE TO PARSE GLOBAL SETTING", apiSetting)
        }
    })

    return globalSettings;
}

export const parseGlobalSettingsForApi = (settings: GlobalSettings): ApiGlobalSetting[] => {
    const apiSettings: ApiGlobalSetting[] = [];

    if (settings.do_not_disturb) {
        apiSettings.push({
            item_type: 1,
            data_boolean: true
        })
    }

    Object.entries(settings.selected_phonebooks).forEach(([uuid, val]) => {
        if (val) {
            apiSettings.push({
                item_type: 2,
                data_uuid: uuid
            })
        }
    })

    Object.entries(settings.favourite_contact).forEach(([uuid, val]) => {
        if (val) {
            apiSettings.push({
                item_type: 3,
                data_uuid: uuid
            })
        }
    })

    Object.entries(settings.pinned_chats).forEach(([uuid, val]) => {
        if (val) {
            apiSettings.push({
                item_type: 4,
                data_uuid: uuid
            })
        }
    })

    Object.entries(settings.muted_chats).forEach(([uuid, val]) => {
        if (val) {
            apiSettings.push({
                item_type: 5,
                data_uuid: uuid
            })
        }
    })

    Object.entries(settings.mark_chat_unread).forEach(([uuid, val]) => {
        if (val) {
            apiSettings.push({
                item_type: 6,
                data_uuid: uuid
            })
        }
    })

    if (settings.sync_dnd_to_phones) {
        apiSettings.push({
            item_type: 7,
            data_boolean: true,
        })
    }

    if (settings.attended_transfer) {
        apiSettings.push({
            item_type: 8,
            data_boolean: true
        })
    }

    return apiSettings;
}

export const getGlobalSettings =
    (): AppThunk =>
        async (dispatch: AppDispatch, getState: () => RootState) => {
            const state = getState();
            const userUuid = selectCurrentUserId(state);

            if (!userUuid) return;

            await api.getGlobalSettings(userUuid)
                .then(global_settings => {

                    if (!Array.isArray(global_settings.data.result)) return;

                    const parsedData = parseApiGlobalSettings(global_settings.data.result);

                    const item = {
                        ...oldParseSettings.selected_phonebooks,
                        ...oldParseSettings.favourite_contact,
                        ...oldParseSettings.pinned_chats,
                        ...oldParseSettings.muted_chats
                    }

                    if (Object.keys(item).length > 0) {
                        const newGlobalSettings: GlobalSettings = {
                            ...parsedData,
                            selected_phonebooks: {
                                ...parsedData.selected_phonebooks,
                                ...oldParseSettings.selected_phonebooks,
                            },
                            pinned_chats: {
                                ...parsedData.pinned_chats,
                                ...oldParseSettings.favourite_contact
                            },
                            favourite_contact: {
                                ...parsedData.favourite_contact,
                                ...oldParseSettings.pinned_chats
                            },
                            muted_chats: {
                                ...parsedData.muted_chats,
                                ...oldParseSettings.muted_chats
                            },
                        }

                        oldParseSettings.selected_phonebooks = {}
                        oldParseSettings.favourite_contact = {}
                        oldParseSettings.pinned_chats = {}
                        oldParseSettings.muted_chats = {}

                        dispatch(
                            mergeGlobalSettings(newGlobalSettings)
                        )
                    } else {
                        dispatch(
                            setGlobalSettings(parsedData)
                        )
                    }
                })
                .catch(e => {
                    console.log('ERROR GETTING GLOBAL SETTINGS', e)
                })

        }

export const updateActivePage =
    (page: PageTypes): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateActivePage(page));

export const updateActivePhoneTab =
    (tab: PhonePageType): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateActivePhoneTab(tab));

export const updateDragAndDrop =
    (id: string, extraData?: Record<string, any>): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateDragAndDrop({id, extraData}))

export const cancelDragAndDrop =
    (): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.cancelDragAndDrop())

export const addOneToast =
    (toast: Pick<IToast, 'type' | 'title' | 'content'>): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.addOneToast({
                ...toast,
                id: uuidv4(),
                active: true,
            }))

export const removeOneToast =
    (toast: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.removeOneToast(toast))

export const updateSettingsPage =
    (newPage: SettingPageType): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateSettingsPage(newPage))

export const setProvisioningOpen =
    (open: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setProvisioningOpen(open))

export const setStatusSubscription =
    (payload: string[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setStatusSubscription(payload))

export const setFocusOnDepartment =
    (departmentUuid?: string): AppThunk =>
        (dispatch: AppDispatch) => {
            if (departmentUuid) {
                dispatch(updateActivePage('phone'))
            }
            dispatch(appSlice.actions.setFocusOnDepartment(departmentUuid))
        }

export const setViewContact =
    (req?: IUser | IHuntGroup | IPhonebookContact): AppThunk =>
        (dispatch: AppDispatch) => {

            if (!req) {
                dispatch(appSlice.actions.setViewContact());
            }

            if (req && isUser(req)) {
                dispatch(appSlice.actions.setViewContact({
                    type: 'user',
                    data: req,
                }));
            }

            if (req && isHuntGroup(req)) {
                dispatch(appSlice.actions.setViewContact({
                    type: 'hunt_group',
                    data: req,
                }));
            }

            if (req && isPhonebookContact(req)) {
                dispatch(appSlice.actions.setViewContact({
                    type: 'phonebook_contact',
                    data: req,
                }));
            }

        }

export const setViewContactItem =
    (req?: ViewContactType): AppThunk =>
        (dispatch: AppDispatch) => {

            dispatch(appSlice.actions.setViewContact(req));
        }

export const setEditPhonebookContact =
    (contact?: IPhonebookContact): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setEditPhonebookContact(contact))

export const setGlobalSettings =
    (globalSettings: GlobalSettings): AppThunk =>
        (dispatch: AppDispatch) => {

            dispatch(appSlice.actions.setGlobalSettings(globalSettings))
        }

export const updateSelectedPhonebook =
    (key: string, value: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateSpecificGlobalSetting({section: 'selected_phonebooks', key, value}));

export const setAllSelectedPhonebook =
    (record: Record<string, boolean>): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setAllSpecificGlobalSetting({section: 'selected_phonebooks', record}));

// export const mergeSelectedPhonebook =
//     (record: Record<string, boolean>): AppThunk =>
//         (dispatch: AppDispatch) =>
//             dispatch(appSlice.actions.mergeSpecificGlobalSetting({section: 'selected_phonebooks', record}))

export const updateFavouriteContact =
    (key: string, value: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateSpecificGlobalSetting({section: 'favourite_contact', key, value}));

export const updateFavouriteChat =
    (key: string, value: boolean): AppThunk =>
        (dispatch: AppDispatch) => {
            dispatch(appSlice.actions.updateSpecificGlobalSetting({section: 'pinned_chats', key, value}));
            dispatch(setLastUpdatedChatId(key))
        }

export const updateMarkChatUnread =
    (key: string, value: boolean): AppThunk =>
        (dispatch: AppDispatch) => {
            dispatch(appSlice.actions.updateSpecificGlobalSetting({section: 'mark_chat_unread', key, value}));
            dispatch(setLastUpdatedChatId(key))
        }

export const mergeGlobalSettings =
    (settingsToMerge: Partial<GlobalSettings>): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.mergeGlobalSettings(settingsToMerge));

export const updateGlobalDnd =
    (newDnd: boolean, from_sip?: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateGlobalDnd({dnd: newDnd, from_sip}));

export const updateDndPhoneSync =
    (newDnd: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateDndPhoneSync(newDnd));

export const updateAttendedTransfer =
    (attended: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.updateAttendedTransfer(attended));

export const setGlobalSettingsUpdates =
    (settingsUpdated: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setGlobalSettingsUpdates(settingsUpdated));

export const setSmartDiallerError =
    (smartDiallerError?: SmartDiallerError): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setSmartDiallerError(smartDiallerError));

export const addOneApiOnlyPhonebook =
    (uuid: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.addOneApiOnlyPhonebook(uuid));

export const removeOneApiOnlyPhonebook =
    (uuid: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.removeOneApiOnlyPhonebook(uuid));

export const setHasDevices =
    (hasDevices: boolean): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setHasDevices(hasDevices))

export const updateSmartDialLocations =
    (registrations: string[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(appSlice.actions.setSmartDialLocations(registrations));
