import React, {useEffect} from "react";
import {InvitationContact} from "./KeypadContacts/KeypadContacts";
import Icon from "../Icon";
import {useTypedSelector} from "../../redux/hooks";
import {selectUsersForGroupCall} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";

interface GroupInvitationsProps {
    groupMenuOpen: boolean;
    setGroupMenuOpen: (val: boolean) => void;
    inviting?: boolean;
}

export const GroupInvitations: React.FC<GroupInvitationsProps> = ({groupMenuOpen, setGroupMenuOpen, inviting}) => {
    const invitationList = useTypedSelector(selectUsersForGroupCall);

    useEffect(() => {
        if (invitationList.length < 2 && groupMenuOpen) {
            setGroupMenuOpen(false)
        }
    }, [invitationList.length]);


    const {t} = usePolyglot();

    return (
        <ul>
            {invitationList.length > 0 &&
                invitationList
                    .slice(0, groupMenuOpen ? invitationList.length : 1)
                    .map((gm, index) => {
                        if (index === 0) {
                            return (
                                <div key={`GI_${gm.uuid}`}>
                                    <InvitationContact contact={gm} notInCall/>
                                    {invitationList.length > 1 ? (
                                        <button onClick={() => setGroupMenuOpen(!groupMenuOpen)}>
                                            <p>
                                                {`+${invitationList.length - 1}`}
                                            </p>
                                            <Icon
                                                name={groupMenuOpen ? 'arrowUp' : 'arrowDown'}
                                                width={20} height={20}
                                            />
                                        </button>
                                    ) : null}
                                </div>
                            )
                        }
                        return (
                            <InvitationContact contact={gm} key={`GI_${gm.uuid}`} notInCall/>
                        )
                    })
            }
            {invitationList.length < 1 ? (
                <p>
                    {inviting ? t('phrases.add_people_to_invite') : t('phrases.add_people_to_create_group')}
                </p>
            ) : null}
        </ul>
    )
}
