import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useCallContext } from '../../../context/CallContext/context';
import { useKeypadContext } from '../../../context/KeypadContext/context';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { selectAllCalls, selectPhoneSettingByKey, toggleMuteCall } from '../../../redux/slices';
import { conferenceSockets } from '../ConferenceHooks';

interface Props {}

const CallAudio: FunctionComponent<Props> = () => {
    const { call, speakerActive } = useCallContext();
    const { useKeypadAction } = useKeypadContext();

    const outputDeviceIdCall = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'outputDeviceIdCall')
    );
    const outputDeviceIdRingtone = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'outputDeviceIdRingtone')
    );
    // const ringVolumeValue = useTypedSelector(state => selectPhoneSettingByKey(state, 'ringtoneVolumeValue'));
    // const callVolumeValue = useTypedSelector(state => selectPhoneSettingByKey(state, 'callVolumeValue'));

    const calls = useTypedSelector(selectAllCalls);

    const audioElementRef = useRef<HTMLAudioElement>(null);

    const dispatch = useTypedDispatch();

    // useEffect(() => {
    //     console.log('a')
    //     if (!audioElementRef.current) return
    //     console.log('speakerActive', speakerActive)
    //
    //     if (speakerActive) {
    //         audioElementRef.current.volume = ringVolumeValue / 100
    //         console.log('ringVolumeValue', ringVolumeValue, audioElementRef.current.volume)
    //     } else {
    //         audioElementRef.current.volume = callVolumeValue / 100
    //         console.log('callVolumeValue', callVolumeValue, audioElementRef.current.volume)
    //     }
    // }, [callVolumeValue, ringVolumeValue, speakerActive]);

    // TODO - fix for conference calling
    // useEffect(() => {
    //     if (!audioElementRef.current) return;
    //
    //     switch (true) {
    //         case call.onHold && !audioElementRef.current.paused:
    //             audioElementRef.current.pause()
    //             break;
    //         case !call.onHold && audioElementRef.current.paused:
    //             audioElementRef.current.play()
    //     }
    //
    // }, [call.onHold]);

    // todo
    useEffect(() => {
        if (!call.roomId || !call.socketId) return;

        conferenceSockets[call.socketId]?.peerConnection?.getReceivers().forEach(receiver => {
            if (receiver.track) {
                // eslint-disable-next-line no-param-reassign
                receiver.track.enabled = !call.onHold;
            }
        });

        conferenceSockets[call.socketId]?.peerConnection?.getSenders().forEach(sender => {
            if (sender.track) {
                // eslint-disable-next-line no-param-reassign
                sender.track.enabled = !(call.isMuted || call.onHold);
            }
        });
    }, [call.isMuted, call.onHold, call.socketId]);

    useEffect(() => {
        if (call.smartDiallerData && call.isMuted === false) {
            dispatch(toggleMuteCall(call.id, true));
        }
    }, [call.smartDiallerData, call.isMuted]);

    useEffect(() => {
        if (audioElementRef.current && call?.audioTrack) {
            const stream = new MediaStream();
            stream.addTrack(call?.audioTrack);

            // const ctx = new AudioContext();
            // const src = ctx.createMediaStreamSource(stream);
            // const gainNode = ctx.createGain();
            // src.connect(gainNode);
            // gainNode.connect(ctx.destination);
            // gainNode.gain.value = 0;

            audioElementRef.current.autoplay = true;
            audioElementRef.current.srcObject = stream;
            audioElementRef.current.play().catch(err => {
                console.warn(err);
            });
        }

        return () => {
            if (audioElementRef.current) {
                audioElementRef.current.srcObject = null;
                audioElementRef.current.pause();
            }
        };
    }, [call?.id, call?.audioTrack]);

    useEffect(() => {
        if (call.state === 'Terminated' && calls.length > 1) {
            const foundCall = calls.find(specCall => specCall.id !== call.id);
            if (foundCall) {
                useKeypadAction({
                    type: 'SetActiveCall',
                    payload: foundCall.id
                });
            }
        }

        if (!audioElementRef.current) return;

        let outputDeviceId;

        switch (true) {
            case (call.state === 'Initial' || call.state === 'Establishing') && !call.isOutbound:
                outputDeviceId = outputDeviceIdRingtone || 'default';
                break;
            case speakerActive:
                outputDeviceId = outputDeviceIdRingtone || 'default';
                break;

            default:
                outputDeviceId = outputDeviceIdCall || 'default';
        }

        (audioElementRef.current as any)
            .setSinkId?.(outputDeviceId)
            .catch(() => console.log('error with setSinkId in call notification'));
    }, [outputDeviceIdCall, outputDeviceIdRingtone, speakerActive, call.state]);

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio hidden ref={audioElementRef} />
    );
};

export default CallAudio;
