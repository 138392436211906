import React, {useEffect, useState} from "react";
import styles from './ContactsSearch.module.scss'
import {IUser} from "../../../../types";
import {useTypedSelector} from "../../../../redux/hooks";
import {usePolyglot} from "../../../../context/Polyglot";
import AriaButton from "../../../../components/AriaComponents/AriaButton";
import {selectAllUsers} from "../../../../redux/slices";
import EntityIcon from "../../../../components/icons/EntityIcon";


interface ContactsSearchProps {
    searchTerm: string;
    handleSelect: (val: IUser) => void;
    excluded?: string[]
}

export const ContactsSearch: React.FC<ContactsSearchProps> = ({searchTerm, handleSelect, excluded}) => {
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

    const {t} = usePolyglot();

    const users = useTypedSelector(selectAllUsers);

    const alphaSelect: string[] = [];


    useEffect(() => {
        setFilteredUsers(users?.filter(usr => usr.nickname.toLowerCase().includes(searchTerm.toLowerCase())) || [])
    }, [searchTerm]);


    return (
        <ul className={styles.container}>
            {filteredUsers &&
                filteredUsers.map((usr, index) => {
                    let exception = false;

                    if (excluded) {
                        exception = excluded.some(item => item === usr.uuid)
                    }

                    const userEntity = (
                        <AriaButton
                            key={usr.uuid}
                            className={styles.contact}
                            onClick={() => !exception && handleSelect(usr)}
                            dataTags={{
                                'data-exception': exception || null
                            }}
                        >
                            <EntityIcon
                                type='user'
                                details={{
                                    sip_name: usr.name,
                                    display_name: usr.nickname,
                                    avatar_key: usr.avatar_key,
                                    blf: true,
                                    uuid: usr.uuid,
                                }}
                                size={24}
                            />
                            <span>{usr.nickname}{exception ? ` - ${t("phrases.in_group")}` : ''}</span>
                        </AriaButton>
                    )

                    if (!alphaSelect.includes(usr.nickname[0])) {
                        alphaSelect.push(usr.nickname[0])
                        return (
                            <React.Fragment key={index}>
                                <div className='list-divider'>
                                    <p>{usr.nickname[0]}</p>
                                </div>
                                {userEntity}
                            </React.Fragment>
                        )
                    }

                    return userEntity;
                })
            }
        </ul>
    )
}

export default ContactsSearch;