import React, {useState} from "react";
import moment from "moment";
import {SessionState} from "sip.js";
import {Counter} from "../../counter";
import {
    selectAllCalls,
    selectCallerIdById,
    selectCurrentCallerId,
    toggleHoldCall,
    updateCallDirect
} from "../../../redux/slices";
import styles from "./CallsWidget.module.scss";
import {useCallerDisplayNameGeneric} from "../../../helpers";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import Icon from "../../Icon";
import {ICall} from "../../../types";
import {usePolyglot} from "../../../context/Polyglot";
import {useKeypadContext} from "../../../context/KeypadContext/context";
import {CallProvider, useCallContext} from "../../../context/CallContext/context";

const WidgetCallInfo = () => {
    const {call} = useCallContext()
    const {keypadState, useKeypadAction} = useKeypadContext()

    const {
        type,
        name,
    } = useCallerDisplayNameGeneric(call.callee === call.displayName ? call.callee : undefined)

    const {
        type: type_other,
        name: name_other,
    } = useCallerDisplayNameGeneric(call.assertedIdentity?.number)

    const calls: ICall[] = useTypedSelector(selectAllCalls);
    const curCallerId = useTypedSelector(selectCurrentCallerId);
    const callerIdData = useTypedSelector(state => selectCallerIdById(state, curCallerId));

    const activeCall = calls.find(c => c.id === keypadState.activeCall)

    const dispatch = useTypedDispatch();
    const {t} = usePolyglot();

    const [callStartTime] = useState<number>(moment().unix());

    let subInfo: any = <Counter initialTime={moment().unix() - callStartTime || 0}/>
    if (call.onHold) {
        subInfo = t("actions.hold");
    } else if (call.state === 'Initial' && call.isOutbound) {
        subInfo = t("phrases.presence_ringing");
    } else if (call.state === 'Initial' && !call.isOutbound) {
        subInfo = t("terms.inbound_call", 1);
    } else if (call.state === 'Terminated' || call.state === 'Terminating') {
        subInfo = t("phrases.call_ended");
    }

    let {displayName} = call;
    let numberStr = call.callee;

    if (displayName === call.callee) {
        displayName = name
    }

    if (displayName === 'DDI' && callerIdData) {
        displayName = callerIdData.cli_name
        if (type === 'contact') {
            numberStr = `${name} (${call.callee})`
        }
    }

    if (!call.isOutbound && type === 'contact') {
        numberStr = `${name} (${call.callee})`
    }

    if (call.assertedIdentity) {
        numberStr = `From ${displayName}`
        if (["contact", "user"].includes(type_other || "") && call.assertedIdentity.number) {
            displayName = `${name_other} (${call.assertedIdentity.number})`
        } else {
            displayName = call.assertedIdentity.number || call.assertedIdentity.name || "Transfer";
        }
    }

    const handleSwitchingCalls = () => {

        switch (true) {
            case call.state === SessionState.Established && !!call.roomId:
                dispatch(
                    updateCallDirect({
                        ...call,
                        onHold: false
                    })
                )
                break;
            case call.state === SessionState.Established && !call.roomId:
                dispatch(toggleHoldCall(call.id, false))
                break
        }

        switch (true) {
            case !!activeCall && activeCall.state === SessionState.Established && !!activeCall.roomId:
                dispatch(
                    updateCallDirect({
                        ...activeCall,
                        onHold: true
                    })
                )
                break;
            case !!activeCall && activeCall.state === SessionState.Established && !activeCall.roomId:
                dispatch(toggleHoldCall(activeCall?.id || '', true));
                break;
        }

        useKeypadAction({
            type: 'SetActiveCall',
            payload: call.id,
        })
        useKeypadAction({
            type: 'SetWidgetActive',
            payload: false,
        })
    }

    const displayMembers = call.roomMembers?.filter(rm => (rm.active && rm.id)) || []

    if (call.roomId) {
        return (
            <button
                className={styles.calls_widget_call_info}
                onClick={() => handleSwitchingCalls()}
                data-group='true'
            >
                <Icon name='group'/>
                <ul>
                    {displayMembers
                        .map((rm) => (
                            <p
                                key={`RM_${rm.id}`}
                                title={rm.name}
                            >
                                {rm.name}
                            </p>
                        ))}
                </ul>
                <p className={styles.counter}>
                    {subInfo}
                </p>
            </button>
        )
    }

    return (
        <button className={styles.calls_widget_call_info} onClick={() => handleSwitchingCalls()}>
            <h3 onMouseDown={e => e.stopPropagation()}>
                {displayName}
            </h3>
            <p className={styles.callee}>
                {numberStr !== displayName ? numberStr : null}
            </p>
            <p className={styles.counter}>
                {subInfo}
            </p>
        </button>
    )
}

export const CallsWidget = () => {
    const {keypadState, useKeypadAction} = useKeypadContext()

    const calls = useTypedSelector(selectAllCalls);
    const {t} = usePolyglot();

    const nonSelectedCalls: ICall[] = calls?.filter(c => c?.id !== keypadState.activeCall);
    if (!nonSelectedCalls) return null;

    return (
        <div
            className={styles.calls_widget}
            data-is-active={keypadState.widgetActive}
            data-is-visible={nonSelectedCalls.length >= 1}
        >
            <div className={styles.header}>
                <p>
                    {t("phrases.call_%count", calls.length)}
                </p>
                <button
                    className={styles.switch_button}
                    onClick={() => useKeypadAction({
                        type: 'SetWidgetActive',
                        payload: !keypadState.widgetActive
                    })}
                >
                    {keypadState.widgetActive ? t("phrases.hide_tab") : t("actions.switch")}
                </button>
            </div>
            {nonSelectedCalls.length >= 1 &&
                <div className={styles.call_list}>
                    {nonSelectedCalls.map(c =>
                        <CallProvider call={c} key={`WCI_${c.id}`}>
                            <WidgetCallInfo/>
                        </CallProvider>
                    )}
                </div>
            }
        </div>
    )
}

export default CallsWidget
