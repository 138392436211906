import React from "react";
import {useTypedSelector} from "../../redux/hooks";
import styles from './CallerIDDisplay.module.scss';
import {usePolyglot} from "../../context/Polyglot";
import {selectAllCallerIds, selectCurrentCallerId} from "../../redux/slices";

interface CallerIDDisplayProps {}

export const CallerIDDisplay: React.FC<CallerIDDisplayProps> = () => {

    const { t } = usePolyglot();

    const callerIds = useTypedSelector(selectAllCallerIds);
    const userCallerID = useTypedSelector(selectCurrentCallerId) || t("adjective.caller_id_anonymous");

    const userCaller = callerIds?.find(callerId => callerId.uuid === userCallerID);

    return (
        <div className={styles.container}>
            {userCaller?.cli_name ? (
                <p
                    title={userCaller?.cli_name}
                    data-animate={(!!userCaller?.cli_name && !!userCaller?.cli_display) || null}
                >
                    {userCaller?.cli_name}
                </p>
            ) : null}
            {userCaller?.cli_display ? (
                <p
                    title={userCaller?.cli_display}
                    data-animate={(!!userCaller?.cli_name && !!userCaller?.cli_display) || null}
                >
                    {userCaller?.cli_display}
                </p>
            ) : null}
            {(!userCaller?.cli_name && !userCaller?.cli_display) ? (
                <p
                    title={t("adjective.caller_id_anonymous")}
                >
                    {t("adjective.caller_id_anonymous")}
                </p>
            ) : null}
        </div>
    )
}

export default CallerIDDisplay;
