import {useEffect, useMemo} from "react";
import {ReactEditor, useSlateWithV} from "slate-react";
import {Editor, Node, Path, Range, Transforms} from "slate";
import {useTypedSelector} from "../../../../redux/hooks";
import {selectAllDepartments} from "../../../../redux/slices";
import {IPhonebookContact, IUser} from "../../../../types";
import {useChatTagContext} from "./ChatTagsContext/context";


export type ChatTagType = BaseTagType | PhonebookContactTagType;

export type BaseTagType = {
    tagType: 'user' | 'department';
    text: string;
    data: {
        uuid: string;
        name: string;
    }
}

export type PhonebookContactTagType = {
    tagType: 'contact';
    data: IPhonebookContact;
    text: string;
}

export const useTagSelector = () => {
    const users: IUser[] = useTypedSelector(state => state.auth.users || []);
    const departments = useTypedSelector(selectAllDepartments);

    const {tagSearchVal, useChatTagAction, toSelect} = useChatTagContext();

    const insertTag = useInsertTag();

    const availableTags: ChatTagType[] = useMemo(() => {
        const res: ChatTagType[] = []
        if (tagSearchVal === undefined) {
            useChatTagAction({
                type: 'setAvailableTags',
                payload: 0
            })
            return []
        }

        useChatTagAction({
            type: 'setSelected',
            payload: 0
        })

        let usersPushed = 0;

        users
            .forEach(usr => {
                if (usersPushed < 25 && usr.nickname.toLowerCase().includes(tagSearchVal.toLowerCase())) {
                    res.push({
                        tagType: 'user',
                        data: {
                            uuid: usr.uuid,
                            name: usr.nickname
                        },
                        text: `@${usr.nickname}`
                    })
                    usersPushed += 1;
                }
            })

        let departmentsPushed = 0

        departments?.forEach(dept => {
            if (
                departmentsPushed < 25 &&
                dept.name.toLowerCase().includes(tagSearchVal.toLowerCase())
            ) {
                res.push({
                    tagType: 'department',
                    data: {
                        name: dept.name,
                        uuid: dept.uuid,
                    },
                    text: dept.name
                })
                departmentsPushed += 1
            }
        })

        useChatTagAction({
            type: 'setAvailableTags',
            payload: res.length
        })

        return res
    }, [tagSearchVal]);

    useEffect(() => {
        if (toSelect === undefined) return;

        const item = availableTags[toSelect];

        if (item) {
            insertTag(item);
            useChatTagAction({
                type: 'setTagSearchVal',
            })
            useChatTagAction({
                type: 'setToSelect',
            });
        }
    }, [toSelect]);

    return {
        availableTags,
    }
}

export const useInsertTag = () => {
    const {editor} = useSlateWithV();

    const {
        tagSearchVal,
        useChatTagAction
    } = useChatTagContext();

    return (tag: ChatTagType) => {
        const tagData = tag as Node;
        const spaceLeaf: Node = {text: " "}

        for (let i = 0; i < (tagSearchVal?.length || 0) + 1; i += 1) {
            editor.deleteBackward('character');
        }

        useChatTagAction({
            type: 'setTagSearchVal',
        });

        const {selection} = editor;
        if (selection) {
            const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

            if (editor.isVoid(parent)) {
                Transforms.insertNodes(editor,
                    [tagData, spaceLeaf],
                    {
                        at: Path.next(parentPath),
                    }
                )
            } else if (Range.isCollapsed(selection)) {
                Transforms.insertNodes(editor, [tagData, spaceLeaf], {
                    select: true,
                });
            } else {
                Transforms.wrapNodes(editor,
                    {children: [tagData, spaceLeaf]},
                    {split: true}
                )
            }

        } else {
            Transforms.insertNodes(editor, tagData)
        }

        setTimeout(() => {
            ReactEditor.focus(editor);
        }, 0)
    }
}
