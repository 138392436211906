/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';
import {
    selectActiveChatUuid, selectAuthUserByUuid,
    selectChatById,
    startNewUserChat,
} from '../../redux/slices';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import ChatProvider from "../../context/ChatContext/context";
import ChatBody from "./ChatBody";
import WelcomeScreen from "./WelcomeScreen";
import ContactNamesProvider from "../phone/GetContactNamesContext";
import { isPulseHd } from '../../helpers';

export const Chat: React.FC = () => {
    const activeChatUuid = useTypedSelector(selectActiveChatUuid);
    const chatUser = useTypedSelector(state => selectAuthUserByUuid(state, activeChatUuid));
    const chat = useTypedSelector(state => selectChatById(state, activeChatUuid));

    const dispatch = useTypedDispatch();

    // if chat opened e.g. from contact add new chat
    useEffect(() => {
        if (activeChatUuid && !chat && chatUser) {
            dispatch(startNewUserChat({newChatUser: chatUser}));
        }
    }, [activeChatUuid, chatUser]);

    if (!chat) {
        return <WelcomeScreen/>;
    }

    return (
        <div className={['chat-container', isPulseHd ? 'ip' : ''].join(' ')}>
            <ContactNamesProvider>
                <ChatProvider chat={chat} key={`CC_${chat.uuid}`}>
                    <ChatBody/>
                </ChatProvider>
            </ContactNamesProvider>
        </div>
    );
};

export default Chat;
