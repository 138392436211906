import {createContext, ReactNode, useContext} from "react";

export type HotKeyNavValue = 'nav' | 'sidebar' | 'main' | 'keypad';

interface Props {
    navigate: (val: HotKeyNavValue) => void
}

interface ProviderProps {
    value: Props;
    children: ReactNode;
}

const HotkeyContext = createContext<Props | null>(null)

export const useHotkeyContext = () => {
    const context = useContext(HotkeyContext)

    if (!context?.navigate) {
        throw new Error(
            'Component requiring hotkeys context rendered without hotkeys'
        )
    }

    return {
        hotKeyNavigate: context.navigate
    }
}

export const HotKeyProvider: React.FC<ProviderProps> = ({value, children}) => (
        <HotkeyContext.Provider value={value}>
            {children}
        </HotkeyContext.Provider>
    )

export default HotKeyProvider;