import React, {memo, useEffect, useMemo} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {IChat} from '../../types';
import {
    selectAuthUserByUuid, setViewContact,
} from "../../redux/slices";
import {useUserStatusContext} from "../../pages/home/children/UserStatusContext";
import EntityIcon, {GropDisplayUser} from "./EntityIcon";

interface ChatIconProps {
    chat: IChat;
    splitGroup?: boolean;
    size?: number;
}

const ChatIconUser: React.FC<{ uuid: string, size: number, name: string }> = ({uuid, size, name}) => {
    const foundUser = useTypedSelector(state => selectAuthUserByUuid(state, uuid));
    const {addNameForReq} = useUserStatusContext();

    const dispatch = useTypedDispatch();

    useEffect(() => {
        const returnFunc = () => {
            if (foundUser) {
                addNameForReq(foundUser.name, false)
            }
        }

        if (!foundUser) return returnFunc;

        addNameForReq(foundUser.name, true)
        return returnFunc;
    }, []);

    if (!foundUser) return (
        <EntityIcon type='user' details={{
            display_name: name,
            uuid,
        }}/>
    )

    return <EntityIcon
        type='user'
        details={{
            sip_name: foundUser.name,
            display_name: foundUser.nickname,
            avatar_key: foundUser.avatar_key,
            blf: true,
            uuid
        }}
        size={size}
        onClick={() => {
            dispatch(setViewContact(foundUser))
        }}
    />
}

const ChatIconGroup: React.FC<{ chat: IChat, size: number, splitGroup?: boolean }> = ({chat, size, splitGroup}) => {

    if (chat.type !== 'channel' || !chat.channel) return <p>Err</p>;

    const displayUsers = useMemo(() => {
        const details: GropDisplayUser[] = []

        if (!chat.channel) return details;

        for (let i = 0; i < chat.channel.members.length; i += 1) {
            if (details.length === 2) break;

            const foundUser = chat.channel.members[i];

            if (foundUser) {
                details.push({
                    avatar_key: foundUser.avatar,
                    nickname: foundUser.display_name,
                    uuid: foundUser.member
                })
            }
        }
        return details
    }, []);

    return (
        <EntityIcon
            type='chat_group'
            details={{
                uuid: chat.uuid,
                display_users: displayUsers,
                avatar_set: splitGroup ? false : chat.channel.avatar_set
            }}
            size={size}
            allowDrag={false}
        />
    )
}

export const ChatIcon: React.FC<ChatIconProps> = memo(({chat, splitGroup, size = 40}) => {

    if (chat?.type === 'user') {
        return <ChatIconUser
            name={chat.display_name}
            uuid={chat.uuid}
            size={size}
        />
    }

    if (chat.integration?.channel.toLowerCase() === 'sms') {
        return <EntityIcon
            type='phonebook'
            details={{uuid: chat.uuid}}
            size={size}
            allowDrag={false}
        />
    }

    if (chat.type === 'channel') {
        return <ChatIconGroup
            chat={chat}
            size={size}
            splitGroup={splitGroup}
        />
    }

    return <></>
})