import React from 'react';
import {usePolyglot} from "../../../context/Polyglot";
import { useAppContext } from '../../../context/AppContext/AppContext';

const WelcomeScreen: React.FC = () => {
    const { isCallSwitch, isPulseHd, isYay} = useAppContext();

    const { t } = usePolyglot();

    let logo;
    switch (true) {
        case isCallSwitch:
            logo = (
                <svg
                    width="400" height="101" viewBox="0 0 752 88"
                    fill="none"
                    role='figure'
                >
                    <title id="title">CallSwitch One</title>
                    <desc id="desc">CallSwitch One Logo</desc>
                    <path d="M0 45.5905V45.3543C0 21.9724 17.4018 2.70862 41.8966 2.70862C54.8451 2.70862 63.2268 6.77555 70.6097 12.7204C71.8395 13.7244 72.9518 15.374 72.9518 17.4921C72.9518 20.7322 70.2572 23.3228 67.0338 23.3228C65.3927 23.3228 64.1628 22.6771 63.3403 22.0866C57.3635 16.9606 50.7404 13.6614 41.7752 13.6614C24.8983 13.6614 12.361 27.5629 12.361 45.1141V45.3504C12.361 62.9606 24.9022 76.9803 41.7752 76.9803C51.2104 76.9803 57.7121 73.7992 64.2177 68.0275C65.0989 67.2007 66.3875 66.5551 67.8523 66.5551C70.8408 66.5551 73.4767 69.1496 73.4767 72.1496C73.4767 73.9763 72.5993 75.4488 71.5418 76.3897C63.6302 83.5157 54.7824 87.9921 41.4227 87.9921C17.6368 88 0 69.2716 0 45.5905Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M90.2752 55.6614V55.8976C90.2752 69.0905 99.358 77.6338 109.847 77.6338C120.336 77.6338 129.771 68.8583 129.771 55.8976V55.6614C129.771 42.6457 120.218 33.9842 109.847 33.9842C99.4755 33.9842 90.2752 42.1142 90.2752 55.6614ZM129.477 30.2165C129.477 27.0354 131.996 24.3858 135.223 24.3858C138.45 24.3858 141.082 27.0354 141.082 30.2756V81.3464C141.082 84.6457 138.505 87.1771 135.34 87.1771C132.176 87.1771 129.481 84.6417 129.481 81.3464V75.2795C124.734 82.1693 117.939 87.8819 107.332 87.8819C92.7975 87.8819 78.5017 76.2795 78.5017 55.9567V55.7205C78.5017 35.4567 92.684 23.7362 107.332 23.7362C118.056 23.7362 124.91 29.3307 129.481 35.6929V30.2165H129.477Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M152.683 5.83071C152.683 2.64961 155.202 0 158.425 0C161.649 0 164.285 2.64961 164.285 5.83071V81.3465C164.285 84.6457 161.766 87.1772 158.484 87.1772C155.202 87.1772 152.683 84.6417 152.683 81.3465V5.83071Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M175.89 5.83071C175.89 2.64961 178.408 0 181.631 0C184.855 0 187.491 2.64961 187.491 5.83071V81.3465C187.491 84.6457 184.972 87.1772 181.69 87.1772C178.408 87.1772 175.89 84.6417 175.89 81.3465V5.83071Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M197.839 77.0433C196.491 76.1023 195.555 74.4488 195.555 72.5078C195.555 69.3267 198.074 66.8543 201.18 66.8543C202.762 66.8543 203.933 67.3819 204.693 68.0315C211.958 74.0393 219.459 77.1614 229.129 77.1614C238.799 77.1614 245.536 72.0945 245.536 64.9685V64.7323C245.536 57.9606 241.831 54.2598 225.612 50.5984C207.431 46.4921 198.187 40.7598 198.187 26.3858V26.1496C198.187 12.6023 209.73 2.94092 225.67 2.94092C236.218 2.94092 244.306 5.64958 251.748 10.8346C253.036 11.6614 254.325 13.3071 254.325 15.6063C254.325 18.7283 251.806 21.2047 248.696 21.2047C247.467 21.2047 246.472 20.9094 245.473 20.2598C238.854 15.6653 232.466 13.5433 225.432 13.5433C215.879 13.5433 210.019 18.6653 210.019 25.0866V25.3228C210.019 32.3937 214.014 35.9409 230.938 39.8149C248.763 43.8937 257.423 50.4173 257.423 63.5511V63.7874C257.423 78.5708 245.528 87.7598 228.769 87.7598C217.222 87.7598 206.968 84.1063 197.827 77.0393L197.839 77.0433Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M278.126 82.3465L260.607 32.9252C260.313 32.0985 259.961 30.8622 259.961 29.8622C259.961 27.0355 262.248 24.3859 265.703 24.3859C268.632 24.3859 270.567 26.3268 271.445 29.0985L285.039 70.7992L298.752 29.0394C299.633 26.3268 301.623 24.3859 304.611 24.3859H305.253C308.3 24.3859 310.353 26.3268 311.23 29.0394L324.942 70.7992L338.713 28.9213C339.473 26.4489 341.232 24.3859 344.338 24.3859C347.444 24.3859 349.907 26.9174 349.907 29.8032C349.907 30.7441 349.555 31.9252 349.324 32.63L331.628 82.3426C330.398 85.878 327.993 87.5867 325.24 87.5867H324.829C321.899 87.5867 319.553 85.878 318.499 82.4646L304.905 41.7599L291.192 82.4646C290.139 85.8819 287.793 87.5867 284.922 87.5867H284.569C281.698 87.5867 279.294 85.878 278.123 82.3426L278.126 82.3465Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M356.789 30.2756C356.789 27.0354 359.307 24.3858 362.53 24.3858C365.754 24.3858 368.39 26.9764 368.39 30.2756V81.3464C368.39 84.6457 365.871 87.1771 362.589 87.1771C359.307 87.1771 356.789 84.6417 356.789 81.3464V30.2756ZM355.853 7.12597C355.853 3.53148 358.782 1.23621 362.534 1.23621C366.287 1.23621 369.271 3.53542 369.271 7.12597V8.24802C369.271 11.7795 366.341 14.1968 362.534 14.1968C358.727 14.1968 355.853 11.7835 355.853 8.24802V7.12597Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M385.083 69.9173V35.1654H381.452C378.698 35.1654 376.411 32.8662 376.411 30.0984C376.411 27.3307 378.698 25.0315 381.452 25.0315H385.083V12.5473C385.083 9.36616 387.601 6.71655 390.883 6.71655C394.165 6.71655 396.684 9.36616 396.684 12.5473V25.0354H410.102C412.914 25.0354 415.261 27.3307 415.261 30.1024C415.261 32.874 412.918 35.1693 410.102 35.1693H396.684V68.2126C396.684 74.6929 400.142 77.1654 405.59 77.1654C407.991 77.1654 409.515 76.5788 410.102 76.5788C412.738 76.5788 415.026 78.7599 415.026 81.4685C415.026 83.5866 413.619 85.2953 411.861 86.004C409.049 87.063 406.178 87.6536 402.543 87.6536C392.466 87.6536 385.079 82.941 385.079 69.9252L385.083 69.9173Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M417.411 56.1338V55.8976C417.411 38.4606 430.888 23.7362 449.054 23.7362C458.955 23.7362 465.696 27.2716 471.026 32.0394C471.849 32.748 472.843 34.2205 472.843 36.1063C472.843 39.1693 470.384 41.5236 467.395 41.5236C465.931 41.5236 464.642 40.937 463.823 40.2874C459.836 36.7559 455.383 33.9842 448.936 33.9842C437.629 33.9842 429.13 43.7047 429.13 55.6614V55.8976C429.13 68.0315 437.742 77.6929 449.524 77.6929C455.794 77.6929 460.717 74.9842 464.877 71.2126C465.582 70.563 466.694 69.8583 468.1 69.8583C470.913 69.8583 473.2 72.2716 473.2 75.0394C473.2 76.5118 472.616 77.8071 471.618 78.6929C465.989 84.2283 459.194 88 448.94 88C430.892 88 417.415 73.5118 417.415 56.1338H417.411Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M480.493 5.83071C480.493 2.64961 483.011 0 486.234 0C489.458 0 492.094 2.64961 492.094 5.83071V35.1063C496.077 28.9213 502.113 23.7362 512.135 23.7362C526.431 23.7362 534.754 33.3976 534.754 47.8268V81.3425C534.754 84.6417 532.236 87.1732 529.012 87.1732C525.789 87.1732 523.153 84.6378 523.153 81.3425V51.122C523.153 40.6378 517.705 34.3346 508.152 34.3346C498.599 34.3346 492.094 41.0472 492.094 51.5945V81.3425C492.094 84.6417 489.575 87.1732 486.293 87.1732C483.011 87.1732 480.493 84.6378 480.493 81.3425V5.83071Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M562.919 20.1141C559.97 23.3149 557.663 27.0669 556.002 31.3661C554.353 35.6338 553.527 40.2007 553.511 45.0669C553.511 45.1023 553.511 45.2598 553.511 45.2952C553.511 50.2047 554.349 54.8267 556.03 59.1653C557.71 63.5039 560.044 67.2755 563.033 70.4763C566.021 73.6771 569.577 76.2204 573.698 78.1023C577.818 79.9881 582.322 80.9291 587.206 80.9291C592.09 80.9291 596.583 80.0078 600.684 78.1614C604.784 76.3149 608.309 73.7952 611.259 70.5905C614.208 67.3897 616.515 63.6417 618.175 59.3425C619.82 55.0748 620.651 50.5078 620.666 45.6417C620.666 45.6063 620.666 45.4488 620.666 45.4133C620.666 40.5039 619.828 35.8818 618.148 31.5433C616.468 27.2047 614.133 23.437 611.145 20.2322C608.157 17.0315 604.6 14.4921 600.48 12.6063C596.356 10.7204 591.855 9.77948 586.971 9.77948C582.087 9.77948 577.595 10.7007 573.494 12.5472C569.389 14.3937 565.864 16.9173 562.919 20.1141ZM557.028 15.5511C560.659 11.6259 565.038 8.50389 570.153 6.185C575.272 3.87004 580.955 2.70862 587.206 2.70862C593.457 2.70862 599.07 3.85822 604.169 6.1535C609.265 8.45271 613.632 11.5433 617.267 15.433C620.897 19.3189 623.702 23.8267 625.676 28.9527C627.634 34.0354 628.621 39.4015 628.637 45.0433C628.676 45.1299 628.676 45.2126 628.637 45.2952C628.637 50.9881 627.63 56.4094 625.617 61.5511C623.604 66.6968 620.784 71.2322 617.149 75.1574C613.515 79.0866 609.132 82.2047 603.993 84.5236C598.854 86.8385 593.179 88 586.971 88C580.763 88 575.057 86.8503 569.977 84.5551C564.897 82.2598 560.542 79.1653 556.911 75.2795C553.276 71.3937 550.476 66.8858 548.502 61.7598C546.543 56.6732 545.556 51.3031 545.541 45.6535C545.541 45.6141 545.541 45.4527 545.541 45.4133C545.541 39.7204 546.547 34.3031 548.561 29.1574C550.57 24.0118 553.394 19.4803 557.028 15.5511Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M643.88 86.5866H636.498V26.0944H643.88V36.9921C645.909 33.5748 648.608 30.6811 651.968 28.3031C655.329 25.9291 659.684 24.7401 665.034 24.7401C668.747 24.7401 672.057 25.3385 674.967 26.5354C677.877 27.7362 680.349 29.4251 682.381 31.6023C684.414 33.7834 685.957 36.374 687.011 39.3779C688.068 42.3818 688.593 45.6929 688.593 49.3031V86.5905H681.21V50.9527C681.21 45.0236 679.667 40.3031 676.581 36.7874C673.494 33.2716 669.08 31.5157 663.338 31.5157C660.565 31.5157 657.996 32.0078 655.63 32.9881C653.265 33.9685 651.205 35.3543 649.45 37.1417C647.691 38.9291 646.324 41.0669 645.349 43.5629C644.374 46.059 643.884 48.7952 643.884 51.7795V86.5905L643.88 86.5866Z"
                          fill="black" fillOpacity="0.1"/>
                    <path d="M744.323 53.248C744.088 50.3425 743.501 47.5629 742.569 44.9133C741.633 42.2637 740.324 39.9252 738.648 37.9055C736.968 35.8818 734.912 34.2637 732.475 33.0433C730.035 31.8267 727.2 31.2165 723.961 31.2165C721.152 31.2165 718.536 31.7755 716.119 32.8937C713.699 34.0118 711.584 35.5551 709.771 37.5157C707.957 39.4803 706.465 41.807 705.294 44.496C704.123 47.1889 703.402 50.1023 703.128 53.2441H744.323V53.248ZM703.625 33.9566C706.183 31.1102 709.21 28.8622 712.708 27.2126C716.202 25.5629 720.024 24.7401 724.164 24.7401C728.304 24.7401 732.385 25.5629 735.824 27.2126C739.263 28.8622 742.181 31.122 744.586 33.9881C746.987 36.8543 748.824 40.2204 750.093 44.0905C751.362 47.9606 751.996 52.1299 751.996 56.6063V57.37C751.996 57.8031 751.977 58.2362 751.937 58.6653C751.937 58.7834 751.456 58.9606 751.416 59.1968H703.124C703.39 62.5984 704.146 65.6299 705.396 68.2795C705.486 68.4685 705.576 68.6574 705.67 68.8464C706.884 71.2559 708.384 73.3346 710.166 75.0826C710.44 75.3504 710.718 75.6102 711 75.8622C712.72 77.3897 714.627 78.5984 716.715 79.4881C717.204 79.6968 717.706 79.8897 718.211 80.0629C720.216 80.748 722.3 81.1417 724.458 81.2362C725.167 81.2677 725.868 81.2716 726.569 81.2519C730.039 81.1417 733.086 80.5 735.715 79.3149C736.502 78.9606 737.266 78.5669 737.998 78.1338C740.324 76.7598 742.471 75.1141 744.437 73.2007C744.762 72.9055 746.004 73.433 746.305 73.1102L750.179 76.5708C747.132 79.9488 743.661 82.6968 739.768 84.8189C735.875 86.9409 731.058 88 725.308 88C721.282 88 717.459 87.244 713.84 85.7322C710.221 84.2204 707.048 82.0826 704.322 79.311C701.593 76.5433 699.434 73.2165 697.84 69.3267C696.242 65.4409 695.447 61.1378 695.447 56.4252C695.447 56.3858 695.447 56.2244 695.447 56.185C695.459 51.87 696.18 47.807 697.613 43.996C699.058 40.1496 701.06 36.8031 703.621 33.9527L703.625 33.9566Z"
                          fill="black" fillOpacity="0.1"/>
                </svg>
            );
            break;
        case isYay:
            logo = (
                <svg
                    width="170.344" height="70.898" viewBox="0 0 170.344 70.898"
                    fill="none"
                    role='figure'
                >
                    <title id="title">Yay</title>
                    <desc id="desc">Yay Logo</desc>
                    <g id="yay">
                        <g id="Group_28" data-name="Group 28">
                            <g id="Group_27" data-name="Group 27">
                                <path id="Path_753"
                                      d="M57.34 2L33.41 57.234c-2.242 5.564-4.943 9.453-8.13 11.722a19.458 19.458 0 0 1-11.587 3.4 23.483 23.483 0 0 1-7.562-1.242A16.623 16.623 0 0 1 0 67.659l4.781-8.8a13.481 13.481 0 0 0 4.078 2.593 12.2 12.2 0 0 0 4.727.945 8.172 8.172 0 0 0 5.214-1.625 14.545 14.545 0 0 0 3.781-5.456l.864-2L1.188 2h12.451L29.71 39.813 45.888 2z"
                                      className="cls-1" data-name="Path 753" transform="translate(0 -1.461)"/>
                                <path id="Path_754"
                                      d="M271.532 5.375c4 3.592 5.969 9.048 5.969 16.313v29.953h-11.29v-6.212a13.939 13.939 0 0 1-6.266 5.132 24.271 24.271 0 0 1-9.8 1.783 23.736 23.736 0 0 1-10.047-1.972 15.536 15.536 0 0 1-6.644-5.456 13.821 13.821 0 0 1-2.35-7.887 13.457 13.457 0 0 1 5.132-11.047q5.1-4.173 16.124-4.159h13.207v-.783c0-3.565-1.08-6.32-3.214-8.238s-5.321-2.863-9.534-2.863a27.7 27.7 0 0 0-8.481 1.35 20.452 20.452 0 0 0-7.022 3.727l-4.7-8.7a29.077 29.077 0 0 1 9.669-4.7A43.117 43.117 0 0 1 254.246 0c7.536-.027 13.289 1.783 17.286 5.375zm-10.911 36.192a11.122 11.122 0 0 0 4.916-5.888v-5.942h-12.344q-10.331 0-10.344 6.806a6.078 6.078 0 0 0 2.593 5.159 11.891 11.891 0 0 0 7.184 1.918 15.65 15.65 0 0 0 7.995-2.053z"
                                      className="cls-1" data-name="Path 754" transform="translate(-168.683)"/>
                                <path id="Path_755"
                                      d="M478.878 2l-23.93 55.233c-2.242 5.564-4.943 9.453-8.13 11.722a19.458 19.458 0 0 1-11.587 3.4c0-2.026-.108-8.346-.108-9.966a8.172 8.172 0 0 0 5.213-1.621 14.545 14.545 0 0 0 3.781-5.456l.864-2L422.7 2h12.451l16.07 37.812L467.4 2z"
                                      className="cls-1" data-name="Path 755" transform="translate(-308.534 -1.461)"/>
                            </g>
                        </g>
                    </g>
                    <circle id="grey_dot" cx="4.889" cy="4.889" r="4.889" className="cls-1" data-name="grey dot"
                            transform="translate(84.726 61.094)"/>
                    <circle id="orange_dot" cx="4.889" cy="4.889" r="4.889" className="cls-1" data-name="orange dot"
                            transform="translate(98.555 61.094)"/>
                    <circle id="red_dot" cx="4.889" cy="4.889" r="4.889" className="cls-1" data-name="red dot"
                            transform="translate(112.383 61.094)"/>
                </svg>
            );
            break;
        case isPulseHd:
        default:
            logo = (
                <svg
                    fill="none"
                    role='figure'
                    viewBox="0 0 214.414 79.403"
                    width="214.414"
                    height="79.403"
                >
                    <title id="title">PulseHD</title>
                    <desc id="desc">PulseHD Logo</desc>
                    <path id="Path_7"
                          d="M252.649 54.349a19.532 19.532 0 1 1 0-27.622 19.516 19.516 0 0 1 0 27.622zm-25.3-25.3a16.2 16.2 0 1 0 22.909 0 16.2 16.2 0 0 0-22.904.005z"
                          className="cls-1" data-name="Path 7" transform="translate(-88.468 -8.436)"/>
                    <path id="Path_9"
                          d="M253.1 54.8a32.061 32.061 0 1 1 0-45.341 32.047 32.047 0 0 1 0 45.341zm-43.489-43.489a29.4 29.4 0 1 0 41.582 0 29.454 29.454 0 0 0-41.582 0z"
                          className="cls-2" data-name="Path 9" transform="translate(-80.031)"/>
                    <path id="Path_8"
                          d="M293.714 48.394l-4.534-4.534a4.825 4.825 0 0 0-6.861 0 4.766 4.766 0 0 0-1.193 4.773 1.582 1.582 0 0 1-.358.3l-.656.656a4.858 4.858 0 0 0-6.741 6.741l-.537.537L257.8 41.832a4.851 4.851 0 0 0-6.861 6.861l14.974 15.094-.537.537-13.483-13.484a4.851 4.851 0 1 0-6.861 6.861l20.284 20.284 6.5 6.5-10.736-1.071a4.856 4.856 0 1 0-.955 9.665l25.116 2.506a9.812 9.812 0 0 0 7.457-1.611 2.27 2.27 0 0 0 .537.776 2.6 2.6 0 0 0 3.58 0l15.154-15.15a2.6 2.6 0 0 0 0-3.58 2.534 2.534 0 0 0-1.014-.6 9.6 9.6 0 0 0-1.611-11.455L294.37 48.991a5.938 5.938 0 0 0-.656-.597z"
                          className="cls-3" data-name="Path 8" transform="translate(-98.271 -16.262)"/>
                    <path id="Path_10"
                          d="M10.739 34.4c-3.58 0-7.159.06-10.739.06v23.088h4.3v-6.861h6.443c11.152 0 11.092-16.287-.004-16.287zm0 12.29H4.3v-8.174h6.443c5.484 0 5.484 8.174-.004 8.174zm23.028 2.864a4.265 4.265 0 0 1-4.116 4.534c-2.506 0-3.878-1.909-3.878-4.355v-8.472h-4v8.531c0 5.071 2.8 7.994 6.622 7.994a7.014 7.014 0 0 0 5.429-2.446l.239 2.207h3.52V41.261h-3.935v8.293zm7.457 7.935h3.937V34.46h-3.937zm13.96-9.963c-2.028-.119-2.983-.716-2.983-1.79 0-1.014 1.014-1.611 2.923-1.611a5.522 5.522 0 0 1 4 1.432l2.207-2.625a8.488 8.488 0 0 0-6.264-2.148c-2.923 0-6.741 1.313-6.741 5.071 0 3.7 3.58 4.832 6.622 5.071 2.267.119 3.162.6 3.162 1.73 0 1.193-1.372 1.969-2.864 1.969a9.1 9.1 0 0 1-5.546-2.268l-1.969 2.8a9.411 9.411 0 0 0 7.4 2.923c4.594 0 6.92-2.446 6.92-5.369-.006-4.291-3.824-5.011-6.866-5.186zm17.241-6.8a8.11 8.11 0 0 0-8.531 8.531c0 5.19 3.281 8.651 8.83 8.651a9.836 9.836 0 0 0 6.92-2.625l-2.565-2.565a6.476 6.476 0 0 1-4.3 1.491c-2.8 0-4.534-1.432-4.773-3.46H80.54c.596-6.625-2.74-10.025-8.114-10.025zm-4.355 6.741a4.335 4.335 0 0 1 4.475-3.167c2.267 0 3.878 1.074 4.176 3.162zm31.321-3.281H88.176V34.46h-4.3v23.088h4.3v-9.367h11.216v9.366h4.236V34.46h-4.236zm16.944-9.786h-8.949v23.088h8.949c7.994 0 11.455-5.906 11.395-11.753-.06-5.667-3.58-11.335-11.395-11.335zm0 18.912h-4.713v-14.8h4.713c4.952 0 7.04 3.58 7.1 7.219.119 3.763-1.91 7.581-7.1 7.581z"
                          className="cls-3" data-name="Path 10" transform="translate(0 -13.842)"/>
                </svg>
            );
    }

    return (
        <div className='chat'>
            <div className='chat__welcome'>
                <div className='chat__welcome-text'>
                    {logo}
                    {!isCallSwitch ? <p>{t("terms.chat", 1)}</p> : null}
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;
