import React, {useMemo} from 'react';
import {motion} from 'framer-motion/dist/framer-motion';
import {IUserStatus} from "../../types";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {CallDebounceReason, handleFindingATransfer, selectUserStatus, setCallDebounce} from "../../redux/slices";
import {StyledButton} from "../StyledComponents";
import styles from './styles.module.scss';

interface UserProps {
    user: {
        uuid: string,
        name: string,
    },
    handleCall: () => void
}

interface GroupProps {
    handleCall: () => void
    ignore_transfer: boolean;
}

type ActualProps = ({
    user: {
        uuid: string,
        name: string,
    },
    ignore_transfer?: never
} | {
    user?: never,
    ignore_transfer?: boolean
}) & {
    handleCall: () => void;
}


type IconStatus = "Available" | "Unavailable" | "Ringing" | "In_Call" | "DND" | "Transfer";

const getIconPath = (status: IconStatus) => {
    switch (status) {
        case "Available":
            return (
                <path
                    d="M10.2559 17.6885C12.8486 20.29 16.0039 22.2939 18.5791 22.2939C19.7305 22.2939 20.7412 21.8897 21.5498 20.9932C22.0244 20.4658 22.3145 19.8594 22.3145 19.2529C22.3145 18.7871 22.1299 18.3301 21.6816 18.0137L18.9834 16.0977C18.5527 15.7988 18.2012 15.6582 17.8848 15.6582C17.4717 15.6582 17.1025 15.8867 16.6982 16.291L16.0654 16.9238C15.96 17.0205 15.8369 17.0645 15.7227 17.0645C15.582 17.0645 15.4502 17.0117 15.3623 16.959C14.8086 16.6602 13.8594 15.8516 12.9805 14.9727C12.1016 14.0938 11.2842 13.1533 10.9941 12.5908C10.9414 12.4941 10.8975 12.3711 10.8975 12.2305C10.8975 12.125 10.9326 12.002 11.0293 11.8965L11.6621 11.2549C12.0576 10.8418 12.2949 10.4814 12.2949 10.0684C12.2949 9.74316 12.1543 9.3916 11.8555 8.96973L9.94824 6.29785C9.62305 5.84082 9.16602 5.64746 8.66504 5.64746C8.06738 5.64746 7.46973 5.91113 6.95117 6.41211C6.07227 7.23828 5.68555 8.2666 5.68555 9.40039C5.68555 11.9756 7.66309 15.0957 10.2559 17.6885Z"
                    fill="var(--color-success)"
                />
            )
        case "Unavailable":
            return (
                <path
                    d="M14.0187 10.1451C10.3457 10.1389 6.69762 10.953 4.87669 12.774C4.06255 13.5881 3.63373 14.5887 3.69587 15.7943C3.73316 16.5028 3.9569 17.1367 4.38572 17.5656C4.7151 17.895 5.16878 18.0876 5.70947 17.9944L8.97224 17.4413C9.48807 17.348 9.8361 17.1989 10.0598 16.9752C10.3519 16.6831 10.4514 16.2605 10.4514 15.6887V14.7938C10.4576 14.6508 10.5135 14.5327 10.5943 14.4519C10.6937 14.3525 10.8243 14.2966 10.9237 14.2717C11.5265 14.0915 12.7695 13.9921 14.0125 13.9921C15.2554 13.9921 16.4984 14.0791 17.1012 14.2717C17.2069 14.3028 17.3249 14.3587 17.4244 14.4582C17.499 14.5327 17.5611 14.6446 17.5673 14.7875L17.5735 15.6887C17.586 16.2605 17.673 16.6831 17.9651 16.9752C18.195 17.2051 18.543 17.3543 19.0527 17.4413L22.2906 17.982C22.8437 18.0752 23.3036 17.8887 23.6578 17.5345C24.0804 17.1119 24.3166 16.5028 24.329 15.7819C24.3663 14.5762 23.9126 13.5757 23.1109 12.774C21.29 10.953 17.6854 10.1451 14.0187 10.1451Z"
                    fill="#868895"
                />
            )
        case "Ringing":
            return (
                <path
                    d="M14.0187 10.6943C10.3457 10.6881 6.69762 11.5023 4.87669 13.3232C4.06255 14.1373 3.63372 15.1379 3.69587 16.3436C3.73316 17.0521 3.95689 17.686 4.38572 18.1148C4.7151 18.4442 5.16878 18.6369 5.70947 18.5436L8.97224 17.9905C9.48807 17.8973 9.8361 17.7481 10.0598 17.5244C10.3519 17.2323 10.4514 16.8097 10.4514 16.2379V15.343C10.4576 15.2001 10.5135 15.082 10.5943 15.0012C10.6937 14.9018 10.8243 14.8458 10.9237 14.821C11.5265 14.6407 12.7695 14.5413 14.0125 14.5413C15.2554 14.5413 16.4984 14.6283 17.1012 14.821C17.2069 14.852 17.3249 14.908 17.4244 15.0074C17.499 15.082 17.5611 15.1939 17.5673 15.3368L17.5735 16.238C17.586 16.8097 17.673 17.2323 17.9651 17.5244C18.195 17.7544 18.543 17.9035 19.0527 17.9905L22.2906 18.5312C22.8437 18.6244 23.3036 18.438 23.6578 18.0837C24.0804 17.6611 24.3166 17.0521 24.329 16.3312C24.3663 15.1255 23.9126 14.1249 23.1109 13.3232C21.29 11.5023 17.6854 10.6943 14.0187 10.6943Z"
                    fill="var(--color-warning)"
                />
            )
        case "In_Call":
            return (
                <>
                    <path
                        d="M9.7502 18.0331C12.343 20.6347 15.4983 22.6386 18.0735 22.6386C19.2249 22.6386 20.2356 22.2343 21.0442 21.3378C21.5188 20.8105 21.8088 20.204 21.8088 19.5976C21.8088 19.1318 21.6243 18.6747 21.176 18.3583L18.4778 16.4423C18.0471 16.1435 17.6956 16.0029 17.3792 16.0029C16.9661 16.0029 16.5969 16.2314 16.1926 16.6357L15.5598 17.2685C15.4543 17.3652 15.3313 17.4091 15.217 17.4091C15.0764 17.4091 14.9446 17.3564 14.8567 17.3036C14.303 17.0048 13.3538 16.1962 12.4749 15.3173C11.5959 14.4384 10.7786 13.498 10.4885 12.9355C10.4358 12.8388 10.3918 12.7158 10.3918 12.5751C10.3918 12.4697 10.427 12.3466 10.5237 12.2411L11.1565 11.5995C11.552 11.1865 11.7893 10.8261 11.7893 10.413C11.7893 10.0878 11.6487 9.73627 11.3499 9.31439L9.4426 6.64252C9.1174 6.18549 8.6604 5.99213 8.15942 5.99213C7.56177 5.99213 6.96411 6.2558 6.44556 6.75677C5.56665 7.58295 5.17993 8.61127 5.17993 9.74506C5.17993 12.3203 7.15747 15.4404 9.7502 18.0331Z"
                        fill="var(--color-warning)"
                    />
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.6473 11.8025C17.9376 11.8567 18.21 11.875 18.5003 11.875C20.9861 11.875 23 10.1149 23 7.93753C23 5.76017 20.9857 4 18.5 4C16.0143 4 14 5.76017 14 7.93753C14 9.18935 14.6532 10.2963 15.6876 11.0221V13L17.6473 11.8025ZM16.4858 8.44786C16.3965 8.50397 16.2907 8.53642 16.1774 8.53642C15.8567 8.53642 15.5968 8.27649 15.5968 7.95581C15.5968 7.6643 15.8116 7.42296 16.0916 7.38147C16.1196 7.37734 16.1483 7.37517 16.1774 7.37517C16.4981 7.37517 16.7581 7.63513 16.7581 7.95581C16.7581 8.16318 16.6494 8.34515 16.4858 8.44786ZM19.0807 7.95581C19.0807 8.27649 18.8207 8.53642 18.5 8.53642C18.1793 8.53642 17.9194 8.27649 17.9194 7.95581C17.9194 7.63513 18.1793 7.37517 18.5 7.37517C18.8207 7.37517 19.0807 7.63513 19.0807 7.95581ZM20.8226 8.53642C21.1433 8.53642 21.4032 8.27649 21.4032 7.95581C21.4032 7.63513 21.1433 7.37517 20.8226 7.37517C20.5019 7.37517 20.2419 7.63513 20.2419 7.95581C20.2419 8.27649 20.5019 8.53642 20.8226 8.53642Z"
                          fill="var(--color-warning)"
                    />
                </>
            )
        case "DND":
            return (<>
                <path
                    d="M9.25586 18.6885C11.8486 21.29 15.0039 23.2939 17.5791 23.2939C18.7305 23.2939 19.7412 22.8896 20.5498 21.9932C21.0244 21.4658 21.3145 20.8594 21.3145 20.2529C21.3145 19.7871 21.1299 19.3301 20.6816 19.0137L17.9834 17.0977C17.5527 16.7988 17.2012 16.6582 16.8848 16.6582C16.4717 16.6582 16.1025 16.8867 15.6982 17.291L15.0654 17.9238C14.96 18.0205 14.8369 18.0645 14.7227 18.0645C14.582 18.0645 14.4502 18.0117 14.3623 17.959C13.8086 17.6602 12.8594 16.8516 11.9805 15.9727C11.1016 15.0938 10.2842 14.1533 9.99414 13.5908C9.94141 13.4941 9.89746 13.3711 9.89746 13.2305C9.89746 13.125 9.93262 13.002 10.0293 12.8965L10.6621 12.2549C11.0576 11.8418 11.2949 11.4814 11.2949 11.0684C11.2949 10.7432 11.1543 10.3916 10.8555 9.96973L8.94824 7.29785C8.62305 6.84082 8.16602 6.64746 7.66504 6.64746C7.06738 6.64746 6.46973 6.91113 5.95117 7.41211C5.07227 8.23828 4.68555 9.2666 4.68555 10.4004C4.68555 12.9756 6.66309 16.0957 9.25586 18.6885Z"
                    fill="var(--color-danger)"
                />
                <path
                    d="M22.7979 8.41925C22.7979 10.6284 21.007 12.4193 18.7979 12.4193C16.5887 12.4193 14.7979 10.6284 14.7979 8.41925C14.7979 6.21011 16.5887 4.41925 18.7979 4.41925C21.007 4.41925 22.7979 6.21011 22.7979 8.41925Z"
                    fill="var(--color-danger)"
                />
                <rect x="16.7148" y="7.77805" width="4.16641" height="1.28203" fill="white"/>
            </>)
        case "Transfer":
            return (<>
                <i className="icon-transfer--hover"/>
            </>)
        default:
            return null
    }
}

export const UserPhoneIcon: React.FC<UserProps> = ({user, handleCall}) => {
    const userStatus: IUserStatus | undefined = useTypedSelector((state) => selectUserStatus(state, user.name));
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const dispatch = useTypedDispatch();

    const handleUnavailable = (reason: CallDebounceReason) => {
        dispatch(setCallDebounce({
            reason,
            userUuid: user.uuid,
        }))
    }

    const {
        status,
        title,
        onClick
    } = useMemo((): {
        status: IconStatus,
        title: string,
        onClick: () => void
    } => {

        let base: {
            status: IconStatus,
            title: string,
            onClick: () => void
        } | undefined;

        if (userStatus?.global_dnd) {
            base = {
                status: "DND",
                title: "Do Not Disturb",
                onClick: () => handleUnavailable("DND"),
            }
        }

        if (!base) {
            switch (userStatus?.status) {
                case 'early':
                    base = {
                        status: "Ringing",
                        title: "Ringing",
                        onClick: () => handleUnavailable("Busy"),
                    };
                    break;
                case 'confirmed':
                    base = {
                        status: "In_Call",
                        title: "In Call",
                        onClick: () => handleUnavailable("Busy"),
                    };
                    break;
                case 'open':
                    base = {
                        status: "Available",
                        title: "Available",
                        onClick: () => handleCall(),
                    };
                    break;
                default:
                    base = {
                        status: "Unavailable",
                        title: "Offline",
                        onClick: () => handleUnavailable("Unavailable"),
                    };
            }
        }

        if (!base) {
            return {
                status: "Unavailable",
                title: "Offline",
                onClick: () => dispatch(setCallDebounce({
                    reason: "Unavailable",
                    userUuid: user.uuid
                })),
            }
        }

        if (aTransferIsActive) {
            if (base.status === "Available") {
                base.status = "Transfer";
            }
            base.title = `${base.title} - Transfer`

        }

        return base
    }, [userStatus, aTransferIsActive]);

    const iconPath = getIconPath(status)

    return (
        <StyledButton
            buttonStyle="text"
            onClick={() => onClick()}
            className={styles.phone_icon}
            title={title}
            iconOnly
            noBg
        >
            {!["Ringing", "Transfer"].includes(status) ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    {iconPath}
                </svg>
            ) : null}
            {status === "Ringing" ? (
                <motion.svg
                    animate={{
                        rotate: [-15, 0],
                    }}
                    transition={{
                        type: "spring",
                        duration: 2000,
                        stiffness: 2000,
                        damping: 3,
                        mass: 1,
                        repeat: Infinity,
                        repeatDelay: 1
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"
                >
                    {iconPath}
                </motion.svg>
            ) : null}
            {status === "Transfer" ? (
                <i
                    style={{
                        fontSize: 18
                    }}
                    className="icon-transfer--hover"
                />
            ) : null}
        </StyledButton>
    );
};

const GroupPhoneIcon: React.FC<GroupProps> = ({handleCall, ignore_transfer}) => {
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const iconPath = getIconPath(aTransferIsActive && !ignore_transfer ? "Transfer" : "Available");


    return (
        <StyledButton
            buttonStyle="text"
            onClick={() => handleCall()}
            className={styles.phone_icon}
            // title={title}
            iconOnly
            noBg
        >
            {aTransferIsActive && !ignore_transfer ? (
                <i
                    style={{
                        fontSize: 18
                    }}
                    className="icon-transfer--hover"
                />
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    {iconPath}
                </svg>
            )}
        </StyledButton>
    );
}

const CallIconButton: React.FC<ActualProps> = ({user, handleCall, ignore_transfer}) => {
    if (user) {
        return (
            <UserPhoneIcon
                user={user}
                handleCall={handleCall}
            />
        )
    }

    return (
        <GroupPhoneIcon
            handleCall={handleCall}
            ignore_transfer={ignore_transfer || false}
        />
    )
}

export default CallIconButton;
