/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthSteps, IPhonebookContact, PhonebookContactEntity} from "../../../types";
import {AppDispatch, AppThunk, RootState} from "../../store";
import {googleLogoutHandler} from "../../../helpers";
import {killChatSocket} from "../../services/chatApi";
import {killStatusesSocket} from "../../services/statusApi";
import {resetApiState} from "../../services";
import {api} from "../../../api";
import {authSlice} from "./slice";
import getDefaultISO from "../../../helpers/getDefaultISO.helpers";
import {t} from "../../../lib/polyglot.lib";
import {selectApiOnlyPhonebooks} from "../appSlice";
import {selectContactInBook} from "./selectors";

export const parseNumber = (number: string) => {
    const defaultISO = getDefaultISO();
    const parsedNumber = parsePhoneNumberFromString(number, defaultISO);

    if (number.startsWith('00') && parsedNumber) {
        return parsedNumber.formatInternational().replace(/ /g, '');
    }

    return number.replace(/ /g, '');
};

export const formatContactName = (contact: IPhonebookContact) =>
    contact.first_name || contact.last_name
        ? `${contact.first_name || ''} ${contact.last_name || ''}`
        : contact.company_name || t("adjective.user_unknown");


export const updateLoginStep =
    (step: AuthSteps): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(authSlice.actions.updateLoginStep(step));

// export const addOnePhonebookEntity =
//     (newEntity: PhonebookContactEntity): AppThunk =>
//         (dispatch: AppDispatch) =>
//             dispatch(authSlice.actions.addOnePhonebookEntity(newEntity));

export const addManyPhonebookEntity =
    (newEntities: PhonebookContactEntity[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(authSlice.actions.addManyPhonebookEntity(newEntities));

export const fromPostPhonebookContact =
    (payload: {
        phonebookUuid: string,
        newContact: IPhonebookContact
    }): AppThunk =>
        (dispatch: AppDispatch) => {
            dispatch(authSlice.actions.fromPostPhonebookContact(payload));

            const entities: PhonebookContactEntity[] = payload.newContact.details
                .filter(detail => detail.type === 1)
                .map(detail => ({
                    number: parseNumber(detail.value),
                    name: formatContactName(payload.newContact),
                    company_name: payload.newContact.company_name,
                    uuid: payload.newContact.uuid,
                    phonebookUuid: payload.phonebookUuid,
                    details: [{name: formatContactName(payload.newContact)}]
                })) || []

            dispatch(authSlice.actions.addManyPhonebookEntity(entities))
        }

export const fromPutPhonebookContact =
    (payload: {
        phonebookUuid: string,
        contactUuid: string,
        newContact: IPhonebookContact
    }): AppThunk =>
        (dispatch: AppDispatch) => {
            dispatch(authSlice.actions.fromPutPhonebookContact(payload));

            const entities: PhonebookContactEntity[] = payload.newContact.details
                .filter(detail => detail.type === 1)
                .map(detail => ({
                    number: parseNumber(detail.value),
                    name: formatContactName(payload.newContact),
                    company_name: payload.newContact.company_name,
                    uuid: payload.newContact.uuid,
                    phonebookUuid: payload.phonebookUuid,
                    details: [{name: formatContactName(payload.newContact)}]
                })) || [];

            dispatch(authSlice.actions.addManyPhonebookEntity(entities))
        }

export const fromDeletePhonebookContact =
    (payload: {
        phonebookUuid: string,
        contactUuid: string
    }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(authSlice.actions.fromDeletePhonebookContact(payload));

export const logout = (): AppThunk => (dispatch: AppDispatch) => {
    googleLogoutHandler();
    dispatch({type: 'auth/logout'});
    dispatch({type: 'sip/disconnect'});
    killChatSocket('logout');
    killStatusesSocket('logout');
    dispatch(resetApiState())
    dispatch(authSlice.actions.updateLoginStep('welcome'));
};

export const resetUser = (): AppThunk => (dispatch: AppDispatch) => {
    dispatch({type: 'auth/switch-user'});
    dispatch({type: 'sip/disconnect'});
    killChatSocket('switch-user');
    killStatusesSocket('switch-user');
    dispatch(resetApiState())
};
export const resetPhonebookContacts = (): AppThunk => (dispatch: AppDispatch) =>
    dispatch(authSlice.actions.resetPhonebookContacts());

export const deleteSelectedPhonebookContacts = (uuid: string): AppThunk => (dispatch: AppDispatch) =>
    dispatch(authSlice.actions.deleteSelectedPhonebookContacts(uuid));

export const resetPhonebookContactCreationLoading = (): AppThunk => (dispatch: AppDispatch) =>
    dispatch(authSlice.actions.resetPhonebookContactCreationLoading());

export const loginWithEmailAndPassword = createAsyncThunk(
    '/login',
    async ({email, password}: {
        email: string;
        password: string
    }) => {
        const response = await api.loginWithEmailAndPassword(email, password);
        return response.data;
    }
);

export const checkUserVerification = createAsyncThunk(
    '/check-user-verification',
    async (userUuid: string) => {
        const response = await api.checkUserVerification(userUuid);
        return response.data?.result;
    }
);

export const loginWithToken = createAsyncThunk('/login-with-token', async () => {
    const response = await api.loginWithToken();
    return response.data;
});

export const loginWithGoogle = createAsyncThunk('/login-with-google', async (tokenId: string) => {
    const response = await api.loginWithGoogle(tokenId);
    return response.data;
});

export interface GetPhonebookContactsResponse {
    result: {
        contacts: IPhonebookContact[],
        entities: PhonebookContactEntity[]
    }
}

export const findContactOnBook =
    ({phonebookUuid, contactUuid}: {
        phonebookUuid: string;
        contactUuid: string;
    }): AppThunk =>
        async (dispatch: AppDispatch, getState: () => RootState): Promise<IPhonebookContact | undefined> => {
            const state = getState();

            const exists = selectContactInBook(state, phonebookUuid, contactUuid)

            if (exists) {
                return exists;
            }

            const apiOnlyPhonebooks = selectApiOnlyPhonebooks(state);

            try {
                if (apiOnlyPhonebooks.includes(phonebookUuid)) {
                    const res = await api.getSingleContact(phonebookUuid, contactUuid);

                    dispatch(
                        authSlice.actions.fromPostPhonebookContact({
                            phonebookUuid,
                            newContact: res.data
                        })
                    )

                    return res?.data
                }
            } catch (e) {
                return undefined;
            }
            return undefined;
        }


export const getPhonebookContacts =
    ({phonebookUuid}: {
        phonebookUuid: string;
        limit?: number
    }): AppThunk =>
        async (dispatch: AppDispatch) => {

            const res = await dispatch(callGetPhonebookContacts({phonebookUuid}));

            if (res?.payload?.result?.entities) {
                dispatch(authSlice.actions.addManyPhonebookEntity(res?.payload?.result?.entities))
            }
        }

export const callGetPhonebookContacts = createAsyncThunk(
    '/get-phonebook-contacts',
    async ({phonebookUuid}: {
        phonebookUuid: string;
        limit?: number
    }) => {

        const newLimit = 1000;
        const response = await api.getPhonebookContacts(phonebookUuid, newLimit, 1);

        if (!response.data) return [];

        let start = 0;

        const phonebookContacts: IPhonebookContact[] = Array(response.data.total_count);

        const pushIntoContacts = (arr2: any[], startIndex: number) => {
            for (let i = 0; i < arr2.length; i += 1) {
                phonebookContacts[startIndex + i] = arr2[i]
            }
        }

        if (response?.data?.result) {
            pushIntoContacts(response.data.result, start)
            start = response.data.result.length
        }

        if (start < phonebookContacts.length) {
            const pages = response.data.total_pages + 1;
            // 1 set = 10,000 contacts
            // const maxSets = 25;
            const maxSets = 2;

            for (let i = 0; i < Math.min(Math.ceil(pages / 10), maxSets); i += 1) {
                const promises: any[] = [];

                const jInit = (i * 10) + 2;

                for (let j = jInit; j < Math.min(jInit + 10, Math.min(pages, 20)); ++j) {
                    promises.push(api.getPhonebookContacts(phonebookUuid, newLimit, j));
                }

                await Promise.allSettled(promises)
                    // eslint-disable-next-line no-loop-func
                    .then((results) => results.forEach(({value}: any,) => {
                        if (value?.data?.result) pushIntoContacts(value.data.result, start);
                        start += value.data.result.length
                    }));
            }

        }

        const entities: PhonebookContactEntity[] = [];

        const finalRes = phonebookContacts?.map(contact => {
            let updatedContact: IPhonebookContact = {
                ...contact,
                // eslint-disable-next-line no-loop-func
                details: contact.details.map((detail: any) => {
                    if ((detail.type || detail.type_id) === 1) {
                        entities.push({
                            number: parseNumber(detail.value),
                            name: formatContactName(contact),
                            company_name: contact.company_name,
                            uuid: contact.uuid,
                            phonebookUuid,
                            details: [{
                                name: formatContactName(contact),
                            }]
                        })
                    }

                    if (detail.type_id) {
                        return {
                            ...detail,
                            type: detail.type_id
                        };
                    }

                    return detail;
                })
            };

            if (!contact.company_name) {
                updatedContact = {
                    ...updatedContact,
                    company_name: ''
                };
            }

            return updatedContact;
        }) || [];

        const finalResponse: GetPhonebookContactsResponse = {
            ...response.data,
            result: {
                contacts: finalRes,
                entities
            }
        };

        return finalResponse;
    }
);