import styles from "./ThemeSelector.module.scss";
import {DarkPreview, LightPreview, SystemDefaultPreview} from "./Themes";
import {usePolyglot} from "../../../../../context/Polyglot";
import { useAppContext } from '../../../../../context/AppContext/AppContext';

const ThemeSelector = () => {
    const {t} = usePolyglot();

    const availableThemes = [
        {
            value: "system_default",
            title: t("phrases.system_default"),
            preview: <SystemDefaultPreview/>
        },
        {
            value: "light",
            title: t("terms.light_mode"),
            preview: <LightPreview/>
        },
        {
            value: "dark",
            title: t("terms.dark_mode"),
            preview: <DarkPreview/>
        },
    ];

    const {
        currentTheme,
        setTheme
    } = useAppContext();

    return (
        <div className={styles.selector_container}>
            {availableThemes.map(theme => {
                const {value, title, preview} = theme;

                return (
                    <label
                        className={styles.label}
                        data-is-active={value === currentTheme}
                        key={value}
                    >
                        <button
                            className={styles.selector}
                            onClick={() => setTheme(value as any)}
                        >
                            {preview}
                        </button>
                        <p>
                            {title}
                        </p>
                    </label>
                )
            })}
        </div>
    )
}

    export default ThemeSelector;
