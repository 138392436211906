/* eslint-disable no-nested-ternary */
import React from 'react';
import {IYeOldeCall} from '../../../../types';
import CallHistoryRowDisplay from "./CallHistoryRowDisplay";
import {useCallHistoryBase} from "./hooks";
import {usePolyglot} from "../../../../context/Polyglot";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const InternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({call}) => {
    const {
        duration,
        date,
    } = useCallHistoryBase(call);

    const {t} = usePolyglot();

    let groupMembersText = '';

    if (call.room_members) {
        switch (true) {
            case call.room_members.length >= 3:
                groupMembersText = `${call.room_members[0].name}, ${call.room_members[1].name}, ${call.room_members[2].name}${call.room_members.length > 3 ? ` + ${call.room_members.length - 3}` : ''}`
                break;
            case call.room_members.length === 2:
                groupMembersText = `${call.room_members[0].name}, ${call.room_members[1].name}`
                break;
            case call.room_members.length === 1:
                groupMembersText = `${call.room_members[0].name}`
        }
    }

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon='group'
            rowName={t("terms.conference", 1)}
            secondaryString={undefined}
            subHeader={groupMembersText || t("adjective.selection_empty")}
            date={date}
            duration={duration}
            options={undefined}
        />
    );
};

export default InternalCallHistoryRow;