import React, {useState} from "react";
import {selectCurrentDevice, selectCurrentUserId, selectDevices, updateDevices} from "../../../../../redux/slices";
import LoadingSpinner from "../../../../../components/loading/LoadingSpinner";
import {usePolyglot} from "../../../../../context/Polyglot";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import styles from "../Account.module.scss";
import {api} from "../../../../../api";
import AriaButton from "../../../../../components/AriaComponents/AriaButton";

interface AccountPageDeviceProps {
    device: any;
}

export const AccountDevices: React.FC<AccountPageDeviceProps> = ({device}) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const currentDevice = useTypedSelector(selectCurrentDevice);
    const devices = useTypedSelector(selectDevices);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { t } = usePolyglot();

    const dispatch = useTypedDispatch()

    const disconnectDevice = async () => {
        if (!device) return;
        setIsDeleting(true)
        await api.deleteDevice({
            userUuid,
            deviceUuid: device.uuid
        })
            .then(() => {
                const filteredDevices = devices.filter(d => d.uuid !== device.uuid);
                dispatch(updateDevices(filteredDevices))
            })
            .catch(() => {
                setIsDeleting(false)
            });
    }

    return (
        <div className={styles.device} key={device.uuid}>
            <p>{device.device_nickname || (device.device_platform === 1 && 'iOS') || (device.device_platform === 2 && 'Android') || (device.device_platform === 3 && t("terms.desktop"))}</p>
            {isDeleting ? (
                <LoadingSpinner color="mamba"/>
            ) : (
                <AriaButton disabled={device.uuid === currentDevice} onClick={disconnectDevice}>
                    {device.uuid === currentDevice ? (
                        <p>{t("phrases.this_device")}</p>
                    ) : (
                        <p>{t("actions.disconnect")}</p>
                    )}
                </AriaButton>
            )}
        </div>
    )
}

export default AccountDevices;
