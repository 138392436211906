import React, { useEffect } from 'react';
import moment from 'moment';
import {
    selectAnyInterTenantUserId,
    selectAppAllowConference,
    selectAuthUserByUuid,
    selectChatEntities,
    selectInterUserTenants,
    selectUserStatus,
    setActiveChat,
    startCall
} from '../../../redux/slices';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { ChatIcon } from '../../../components';
import { isIntegration, isUser } from '../../../types';
import Icon from '../../../components/Icon';
import { ChatHeaderGroupCall } from './atoms/ChatHeaderGroupCall';
import { usePolyglot } from '../../../context/Polyglot';
import { useChatContext } from '../../../context/ChatContext/context';
import ContactProvider, { useContactContext } from '../../../context/ContactContext/context';
import { StyledButton } from '../../../components/StyledComponents';
import { useCallContact } from '../../../components/sidebars/Contact/hooks';
import CallIconButton from '../../../components/buttons/CallIconButton';
import { useGetInterTenantClientUsersQuery } from '../../../redux/services/sipApi';
import { useUserStatusContext } from '../../home/children/UserStatusContext';

interface IChatHeaderProps {}

interface IChatStatusProps {
    chatStatus?: {
        connected: boolean;
        last_seen?: number;
        status?: string;
    };
}

const ChatHeaderCallBtn: React.FC = () => {
    const { contact } = useContactContext();

    const { callContact } = useCallContact();

    return (
        <CallIconButton
            handleCall={() => callContact()}
            user={
                isUser(contact)
                    ? {
                          uuid: contact.uuid,
                          name: contact.name
                      }
                    : undefined
            }
        />
    );
};

export const ChatStatus: React.FC<IChatStatusProps> = ({ chatStatus }) => {
    const { connected, last_seen, status } = chatStatus || { connected: false };

    const TODAY = moment();
    const YESTERDAY = moment().subtract(1, 'days');
    const A_WEEK_OLD = moment().subtract(7, 'days');

    const { t } = usePolyglot();

    let statusFormatted: string;

    if (connected) {
        statusFormatted = t('phrases.presence_online');
    } else if (moment.unix(last_seen || 0).isSame(TODAY, 'day')) {
        statusFormatted = t('phrases.last_seen_today_at_%time', {
            time: moment.unix(last_seen || 0).format('HH:mm')
        });
    } else if (moment.unix(last_seen || 0).isSame(YESTERDAY, 'day')) {
        statusFormatted = t('phrases.last_seen_yesterday_at_%time', {
            time: moment.unix(last_seen || 0).format('HH:mm')
        });
    } else if (moment.unix(last_seen || 0).isAfter(A_WEEK_OLD, 'day')) {
        statusFormatted = t('phrases.last_seen_%day_at_%time', {
            day: moment.unix(last_seen || 0).format('dddd'),
            time: moment.unix(last_seen || 0).format('HH:mm')
        });
    } else {
        statusFormatted = t('phrases.last_seen_%day', {
            day: moment.unix(last_seen || 0).format('dddd')
        });
    }

    return (
        <>
            {last_seen ? (
                <p className={status ? '' : 'animation--none'}>{statusFormatted}</p>
            ) : (
                <p className={status ? '' : 'animation--none'}>{status || statusFormatted}</p>
            )}
        </>
    );
};

const useInterTenantPerson = (user_uuid: string | undefined) => {
    const tenants = useTypedSelector(selectInterUserTenants);

    const itm = useTypedSelector(state => selectAnyInterTenantUserId(state, user_uuid));

    const lst = Object.values(tenants);

    const tnt = lst.find(t => !t.users);

    const dispatch = useTypedDispatch();

    const handleCallClient = () => {
        if (itm) {
            dispatch(
                startCall(
                    `${itm.prefix}${itm.client.extension}`,
                    itm.client.user_display_name,
                    undefined,
                    itm.tenant
                )
            );
        }
    };

    useGetInterTenantClientUsersQuery(
        {
            tenant_uuid: tnt?.uuid ?? ''
        },
        {
            skip: !tnt || !user_uuid || !!itm,
            refetchOnMountOrArgChange: true
        }
    );

    return {
        client: itm?.client,
        handleCallClient
    };
};

export const ChatHeader: React.FC<IChatHeaderProps> = () => {
    const {
        chat,
        parentMessageChatId,
        chatTitle,
        sideBarVisible,
        noPriorChatHistory,
        useChatAction
    } = useChatContext();
    const conferenceAllowed = useTypedSelector(selectAppAllowConference);

    const { t } = usePolyglot();

    const chatDictionary = useTypedSelector(selectChatEntities);
    const chateeUser = useTypedSelector(state => selectAuthUserByUuid(state, chat.uuid));

    const { client, handleCallClient } = useInterTenantPerson(
        chat.type === 'user' && !chateeUser ? chat.uuid : undefined
    );

    const dispatch = useTypedDispatch();

    const handleSidebarToggle = () => {
        useChatAction({
            type: 'set_sidebar_visible',
            payload: !sideBarVisible
        });
    };

    const userStatus = useTypedSelector(state =>
        selectUserStatus(state, client?.user_sip_name || chateeUser?.name)
    );

    const currentRecipientChatStatus = userStatus?.chat || { connected: false };

    const { addNameForReq } = useUserStatusContext();

    useEffect(() => {
        const returnFunc = () => {
            if (client) {
                addNameForReq(client.user_sip_name, false);
            }
            if (chateeUser) {
                addNameForReq(chateeUser.name, false);
            }
        };

        if (!client && !chateeUser) return returnFunc;

        if (client) {
            addNameForReq(client.user_sip_name, true);
        }
        if (chateeUser) {
            addNameForReq(chateeUser.name, true);
        }
        return returnFunc;
    }, [client, chateeUser]);

    return (
        <div className='chat__header'>
            <div className='chat__header-details'>
                {chat && (
                    <ChatIcon
                        chat={(parentMessageChatId && chatDictionary[parentMessageChatId]) || chat}
                    />
                )}
                <div className='chat__header-name'>
                    <div>
                        <h1 className='chat_title'>
                            {chatTitle}
                            {isIntegration(chat) && chat.integration?.number ? (
                                <span>&nbsp;&nbsp;{chat.integration.number}</span>
                            ) : null}
                        </h1>
                        {!noPriorChatHistory && chat.type === 'user' && (
                            <div>
                                {currentRecipientChatStatus &&
                                    currentRecipientChatStatus.connected && (
                                        <ChatStatus chatStatus={currentRecipientChatStatus} />
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='chat__header-options'>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    context='danger'
                    onClick={() => dispatch(setActiveChat(''))}
                    title={t('actions.close_chat')}
                >
                    <Icon name='cross' />
                </StyledButton>
                {chat?.type === 'user' && chateeUser ? (
                    <ContactProvider value={{ contact: chateeUser }}>
                        <ChatHeaderCallBtn />
                    </ContactProvider>
                ) : null}
                {client ? (
                    <CallIconButton
                        handleCall={() => handleCallClient()}
                        user={{
                            uuid: client.user_uuid,
                            name: client.user_sip_name
                        }}
                    />
                ) : null}
                {chat?.type === 'channel' && conferenceAllowed ? <ChatHeaderGroupCall /> : null}
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    onClick={() => handleSidebarToggle()}
                    data-is-sidebar-toggle='true'
                    title={t('phrases.extra_options')}
                >
                    <Icon name='threeDots' />
                </StyledButton>
            </div>
        </div>
    );
};
