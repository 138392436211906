import {TypeOf, z} from "zod";
import {IToast} from "../components/toastCenter";
import {SettingPageType} from "./settings";
import {PageTypes} from "./navigation";
import {IUser} from "./user";
import {IHuntGroup, IPhonebookContact} from "./auth";
import {ICall} from "./call";

export type PhonePageType = 'history' | 'missed' | 'parked' | 'voicemail';

interface dragAndDropProps {
    id: string;
    extraData?: Record<string, any>
}

export interface IApp {
    active_page: PageTypes;
    active_phone_tab: PhonePageType;
    drag_and_drop: dragAndDropProps | null;
    grid_view: boolean;
    toasts: IToast[];
    settings_page: SettingPageType;
    provisioning_open: boolean;
    status_subscriptions: string[];
    focus_on_department?: string;
    global_settings: GlobalSettings;
    global_settings_updated?: boolean;
    viewContact?: ViewContactType;
    editPhonebookContact?: IPhonebookContact;
    smartDiallerError?: SmartDiallerError;
    apiOnlyPhonebookUuids: string[];
    has_devices: boolean;
    smart_dial: SmartDialProps
}

export interface SmartDialProps {
    apps: SmartDiallerApp[];
    registrations: SmartDiallerRegistration[];
    location_ids: string[];
}

// export interface SmartDiallerError extends Pick<ICall, 'id' | 'smartDiallerData' | 'displayName' | 'callee'> {
export interface SmartDiallerError extends ICall {
    reasonPhrase?: string;
    statusCode?: number;
}

export interface GlobalSettings {
    do_not_disturb: boolean;
    selected_phonebooks: Record<string, boolean>;
    favourite_contact: Record<string, boolean>;
    pinned_chats: Record<string, boolean>;
    muted_chats: Record<string, boolean>;
    mark_chat_unread: Record<string, boolean>;
    sync_dnd_to_phones: boolean;
    attended_transfer: boolean;
}

export type GlobalSettingsDictionaryName =
    'selected_phonebooks'
    | 'favourite_contact'
    | 'pinned_chats'
    | 'muted_chats'
    | 'mark_chat_unread';

export type ViewContactType = {
    type: 'user';
    data: IUser;
} | {
    type: 'hunt_group';
    data: IHuntGroup;
} | {
    type: 'phonebook_contact';
    data: IPhonebookContact;
} | {
    type: 'tenant';
    data: string;
}

export interface SmartDiallerRegistration {
    call_id: string;
    location_id: string;
    username: string;
    cc: string;
    registered_at: Date;
    expires: Date;
    received: string;
    contact: string;
    user_agent: string;
    // Set by match to hardware
    nickName?: string;
}

export interface SmartDiallerApp {
    uuid: string;
    device_platform: number;
    app_version: number;
    device_name: string;
    device_model: string;
}

export const AuxCodeValidator = z.object({
    code: z.string(),
    set_by: z.string(),
    time: z.string(),
})

export const SetAuxCodeResponse =  z.object({
    code: z.string(),
    set_by: z.string(),
    time: z.string(),
    user_uuid: z.string(),
})

export type AuxCode = TypeOf<typeof AuxCodeValidator>
