/* eslint-disable react/jsx-props-no-spreading */
import {AriaTextFieldProps, useTextField} from "react-aria";
import {forwardRef, ReactNode} from "react";
import {FieldError} from "react-hook-form";
import Icon from "../Icon";
import formStyles from "../AriaForm/styles.module.scss";
import styles from './styles.module.scss';
import {StyledButton} from "../StyledComponents";
import {focusNext} from "../../helpers";

interface Props extends AriaTextFieldProps {
    hideLabel?: boolean;
    invalid?: boolean;
    isTouched?: boolean;
    isDirty?: boolean;
    error?: FieldError;
    onEnter?: () => void;
    type?: "search" | "text" | "email" | "password" | "tel" | "url" | string | undefined;
    isSearch?: boolean;
    onSearchClick?: () => void;
    widgets?: ReactNode;
    extraKeydown?: (e: React.KeyboardEvent) => void;
    afterInputElem?: ReactNode;
    labelExtra?: ReactNode;
    noInput?: boolean
}

const AriaTextField = forwardRef((props: Props, ref: any) => {
    const {label, hideLabel} = props;
    const {labelProps, inputProps, descriptionProps, errorMessageProps} =
        useTextField({...props, type: props.type || 'text'}, ref);

    return (
        <div
            className={formStyles.form_text_field}
            data-comp='text-input'
        >
            {!hideLabel ? (
                <label {...labelProps}>{label}{props.labelExtra}</label>
            ) : null}
            {props.isRequired ? (
                <mark>*</mark>
            ) : null}
            {props.noInput ? (
                <div
                    className={formStyles.input_container}
                    data-no-input='true'
                >
                    <p>-</p>
                </div>
            ) : (
                <div className={formStyles.input_wrapper}>
                <div
                    className={formStyles.input_container}
                    data-invalid={props.invalid || null}
                    data-is-touched={props.isTouched || null}
                    data-is-dirty={props.isDirty || null}
                    data-error={!!props.error || null}
                    data-is-search={props.isSearch || null}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            focusNext({
                                container: e.currentTarget,
                                querySelector: 'input'
                            })
                        }
                    }}
                >
                    {(props.isSearch && !props.onSearchClick) ? (
                        <Icon name='search'/>
                    ) : null}
                    {(props.isSearch && props.onSearchClick) ? (
                        <StyledButton
                            buttonStyle='text'
                            iconOnly
                            size='small'
                            onClick={() => props.onSearchClick?.()}
                        >
                            <Icon name='search'/>
                        </StyledButton>
                    ) : null}
                    <div
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                focusNext({
                                    container: e.currentTarget,
                                    querySelector: 'input'
                                })
                            }
                        }}
                    >
                        {props.widgets ? props.widgets : null}
                        <input
                            {...inputProps}
                            ref={ref}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if (props.onEnter) props.onEnter()
                                }
                                props.extraKeydown?.(e)
                            }}
                        />
                    </div>
                </div>
                    {props.afterInputElem}
                </div>
            )}
            {props.description && (
                <div
                    className={styles.description}
                    {...descriptionProps}
                >
                    {props.description}
                </div>
            )}
            {props.errorMessage && typeof props.errorMessage !== 'function' &&(
                <div
                    className={styles.errorMessage}
                    {...errorMessageProps}
                >
                    {props.errorMessage}
                </div>
            )}
        </div>
    );
});

export default AriaTextField;
