import React from 'react';
import { logout } from '../../redux/slices';
import {usePolyglot} from "../../context/Polyglot";
import {useTypedDispatch} from "../../redux/hooks";

export const Logout: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    return (
        <i
            className='icon-logout--hover'
            title={t("actions.logout")}
            onClick={() => {
                dispatch(logout());
            }}
        />
    );
};

export default Logout;
