import React, {lazy, useState, Suspense} from "react";
import {CountryCode, parsePhoneNumberFromString} from "libphonenumber-js";
import {
    blindTransferCall,
    handleFindingATransfer, selectAttendedTransferOpt,
    selectUserCountryCode,
    setBeingTransferred,
    startCall
} from "../../../../../redux/slices";
import Icon from "../../../../Icon";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import {useDebounce} from "../../../../../helpers";
import styles from "../../../Keypad.module.scss";
import {usePolyglot} from "../../../../../context/Polyglot";
import {useCallContext} from "../../../../../context/CallContext/context";
import AriaButton from "../../../../AriaComponents/AriaButton";
import KeypadPageTitle from "./atoms/KeypadPageTitle";

const KeypadContacts = lazy(() => import('../../../KeypadContacts/KeypadContacts'))

export const ContactListKeypad = () => {
    const {call, currentTab} = useCallContext()

    const countryCode = useTypedSelector(selectUserCountryCode) as CountryCode;
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);
    const alwaysAttendedTransfer = useTypedSelector(selectAttendedTransferOpt);

    const [searchTerm, setSearchTerm] = useState('');

    const searchQueryDebounced = useDebounce(searchTerm, 500);

    const parsedNumber = parsePhoneNumberFromString(searchTerm, countryCode);

    const dispatch = useTypedDispatch();
    const {t} = usePolyglot();

    function handleTransferCancel() {
        if (aTransferIsActive) {
            dispatch(setBeingTransferred(call.id, false));
        }
    }

    const handleCallTransfer = () => {
        if (searchTerm.length === 0 || !call) return;

        if (alwaysAttendedTransfer) {
            dispatch(startCall(searchTerm, undefined, call.id));
            // setKeypadActive(false);
        } else if (call.callee) {
            dispatch(blindTransferCall(call.callee, searchTerm, call.id));
        }
    };

    return (
        <div className={styles.add_call}>
            <>
                <KeypadPageTitle
                    title={currentTab === 'transfer' ? t("actions.transfer") : t("phrases.new_call")}
                    onClick={() => handleTransferCancel()}
                />
                <input
                    type="text" placeholder={t("phrases.enter_name_or_number")} value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            if (currentTab === 'transfer') {
                                handleCallTransfer();
                            } else {
                                dispatch(startCall(searchTerm));
                            }
                        }
                    }}
                />
                {parsedNumber ? (
                    <AriaButton
                        title='Start Call'
                        tabIndex={-1}
                        onClick={() => {
                            if (currentTab === 'transfer') {
                                handleCallTransfer()
                            } else {
                                dispatch(startCall(parsedNumber.number))
                            }
                        }}
                        className={styles.add_call_button}
                    >
                        <div
                            className='contact-card__phone-icon'
                            title={t("phrases.new_call")}
                        >
                            {currentTab === 'transfer' ? (
                                <i className='icon-transfer--hover'/>
                            ) : (
                                <Icon name='addCall' width={28} height={28}/>
                            )}
                        </div>
                    </AriaButton>
                ) : null}
                <div>
                    <Suspense>
                        <KeypadContacts
                            searchTerm={searchQueryDebounced}
                            transfer={currentTab === 'transfer'}
                            addCall={currentTab === 'add_call'}
                        />
                    </Suspense>
                </div>
            </>
        </div>
    )
}

export default ContactListKeypad
