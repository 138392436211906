/* eslint-disable react/jsx-props-no-spreading */
import React, {useRef} from 'react';

interface SliderProps extends React.HTMLProps<HTMLInputElement> {
    name: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    locked?: string;
}

export const Slider: React.FC<SliderProps> = props => {
    const {name, label, locked} = props;

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div
            className='slider-input'
            data-locked={!!locked || null}
            onClick={() => {
                inputRef.current?.click()
            }}
            title={locked || undefined}
        >
            {label && <p>{label}</p>}
            <label htmlFor={name} className='switch'>
                <input
                    id={name}
                    type='checkbox'
                    ref={inputRef}
                    onKeyDown={event => event.key === 'Enter' && event.currentTarget.click()}
                    {...props}
                />
                <span className='slider round'/>
            </label>
        </div>
    );
};
