/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react';
import {IYeOldeCall} from '../../../../types';
import CallHistoryRowDisplay from "./CallHistoryRowDisplay";
import {useCallHistoryBase} from "./hooks";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    selectUserExtensionsToNickDict,
    setActiveChat,
    startCall
} from "../../../../redux/slices";
import {usePolyglot} from "../../../../context/Polyglot";
import Icon from "../../../../components/Icon";
import AriaButton from "../../../../components/AriaComponents/AriaButton";
import CallIconButton from "../../../../components/buttons/CallIconButton";
import {useUserStatusContext} from "../../../home/children/UserStatusContext";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const InternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({call}) => {
    const {
        duration,
        callee,
        callIcon,
        date,
    } = useCallHistoryBase(call);

    const extToNameDict = useTypedSelector(selectUserExtensionsToNickDict);

    const {t} = usePolyglot();

    const item = extToNameDict[callee]

    const dispatch = useTypedDispatch();

    const {addNameForReq} = useUserStatusContext();

    useEffect(() => {
        const returnFunc = () => {
            if (item) {
                addNameForReq(item.sip_name, false)
            }
        }

        if (!item) return returnFunc;

        addNameForReq(item.sip_name, true)
        return returnFunc;
    }, [item]);

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={callIcon}
            rowName={item?.name || callee}
            secondaryString={item?.name && callee}
            subHeader={t("adjective.call_internal")}
            date={date}
            duration={duration}
            options={(<>
                {item ? (
                    <CallIconButton
                        handleCall={() => {
                            dispatch(startCall(callee, item?.name));
                        }}
                        user={{uuid: item.uuid, name: item.sip_name}}
                    />
                ) : (
                    <AriaButton
                        onClick={() => {
                            dispatch(startCall(callee, item?.name));
                        }}
                    >
                        <Icon name='phoneCall' width={22} height={22}/>
                    </AriaButton>
                )}
                {item ? (
                    <AriaButton
                        onClick={() => {
                            dispatch(setActiveChat(item.uuid))
                        }}
                        title={t("actions.send_message")}
                    >
                        <Icon name='messageFilled' width={22} height={22}/>
                    </AriaButton>
                ) : null}
            </>)}
        />
    );
};

export default InternalCallHistoryRow;