import React, {FunctionComponent, useEffect, useRef} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {TypeOf, z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useOnClickOutside} from "../../../../helpers";
import {StyledButton} from "../../../StyledComponents";
import FormTextField from "../../../AriaForm/FormTextField";
import formStyles from "../../../../sass/globalModules/forms.module.scss";
import styles from '../styles.module.scss';
import {joinMeetingId} from "../../../../pages/video/helpers";


interface Props {
    closeEditor: () => void;
}


const JoinViaModal: FunctionComponent<Props> = ({
                                                    closeEditor,
                                                }) => {
    const editorRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(editorRef, closeEditor)

    const joinViaIdSchema = z
        .object({
            meeting_id: z.string().min(1, 'Meeting Id / url required'),
        })

    type JoinViaIdInput = TypeOf<typeof joinViaIdSchema>;

    const formMethods = useForm<JoinViaIdInput>({
        resolver: zodResolver(joinViaIdSchema),
        defaultValues: {
            meeting_id: '',
        }
    });

    const handleJoin = (meeting_id: string) => {
        joinMeetingId(meeting_id);
        closeEditor();
    }

    useEffect(() => {
        formMethods.setFocus('meeting_id')
    }, []);

    const saveForm = (formData: JoinViaIdInput) => {
        if (formData.meeting_id.includes("/")) {
            const urlRegex = /meet.(.*?).app\/(.*?)/gm;

            const urlParser = urlRegex.exec(formData.meeting_id)
            let found: string | undefined;

            if (urlParser) {
                found = formData.meeting_id.substring(urlParser.index + urlParser[0].length).trim().replaceAll("/", "");

            }

            if (found && found.length > 0) {
                handleJoin(found)
            } else {
                formMethods.setError("meeting_id", {
                    message: 'Invalid Input'
                })
            }

        } else {
            handleJoin(formData.meeting_id)
        }

    }

    return (
        <div
            className={styles.id_join_pop}
            ref={editorRef}
        >
            <div className={formStyles.formContainer}>
                <section>
                    <h2>
                        Join via ID
                    </h2>
                </section>
                <FormProvider
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...formMethods}
                >
                    <form onSubmit={formMethods.handleSubmit(saveForm)}>
                        <FormTextField
                            name='meeting_id'
                            label='Meeting ID'
                            placeholder='Enter the meeting url or ID'
                            onEnter={() => saveForm(formMethods.getValues())}
                            required
                        />
                        <div className={formStyles.formControls}>
                            <StyledButton
                                buttonStyle='primary'
                                size='large'
                                type="submit"
                            >
                                <p>
                                    Join
                                </p>
                            </StyledButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
};

export default JoinViaModal;
