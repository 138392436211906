import React from 'react';
import {LoadingSpinner} from '../loading/LoadingSpinner';

interface ButtonProps {
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    type?:
        | 'info'
        | 'success'
        | 'warning'
        | 'danger'
        | 'white'
        | 'shadow'
        | 'underline'
        | 'disabled';
    size?: 'medium' | 'small';
    showPopup?: boolean;
    popupType?: 'info' | 'warning';
    popupTitle?: string;
    popupText?: string;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
    error?: string;
    children?: React.ReactNode;
    buttonType?: "button" | "submit" | "reset";
}

export const Button: React.FC<ButtonProps> = ({
                                                  text,
                                                  onClick,
                                                  type,
                                                  size,
                                                  showPopup,
                                                  popupType,
                                                  popupTitle,
                                                  popupText,
                                                  disabled,
                                                  loading,
                                                  active,
                                                  error,
                                                  buttonType = 'button',
                                                  children
                                              }) => (
    <button
        type={buttonType}
        className={[
            'button',
            type,
            size || 'medium',
            loading ? 'loading' : '',
            active ? 'active' : ''
        ]
            .join(' ')
            .trim()}
        onClick={onClick}
        disabled={disabled}
    >
        {showPopup && (
            <dialog
                className={`button__popup ${popupType || 'warning'}${
                    popupTitle ? '' : ' no-title'
                }`}
                open={showPopup}
            >
                <h6>{popupTitle}</h6>
                <p>{popupText}</p>
            </dialog>
        )}
        {loading ? (
            <div className='loading-spinner-container'>
                <LoadingSpinner color='mamba'/>
            </div>
        ) : children || text}
        {error && (
            <div className='button__error'>
                <p>{error}</p>
            </div>
        )}
    </button>
);
