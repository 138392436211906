import {api} from ".";
import {MeetingRoom, MeetingRoomApi} from "../../types/video";
import {setActiveRoomId, setVideoPage} from "../slices";

export const videoApi = api.injectEndpoints({
    endpoints: builder => ({
        /**
         * GET Requests
         */
        getOneMeeting: builder.query<{ result: MeetingRoomApi }, string>({
            query: (uuid) => ({
                method: 'GET',
                url: `/api/get-meeting-room/${uuid}`,
            }),
        }),
        getAllMeetings: builder.query<{ result: MeetingRoomApi[] }, null>({
            query: () => ({
                method: 'GET',
                url: '/api/get-meeting-rooms',
            }),
            providesTags: ['Meetings'],
        }),
        getMeetingRoomCheck: builder.query<any, string | undefined>({
            query: (uuid) => ({
                method: 'GET',
                url: '/api/check-meeting-room',
                params: {room_uuid: uuid},
            }),
        }),

        /**
         * POST Requests
         */
        postMeeting: builder.mutation< {result: MeetingRoomApi }, MeetingRoom>({
            query: (data) => ({
                method: 'POST',
                url: '/api/create-meeting-room',
                data
            }),
            invalidatesTags: ['Meetings'],
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;
                    if (!result) return;

                    dispatch(setVideoPage('meeting-preview'))
                    dispatch(setActiveRoomId(result.uuid))
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            },
        }),

        /**
         * PUT Requests
         */
        putMeeting: builder.mutation< { result: MeetingRoomApi }, {
            uuid: string, data: MeetingRoom
        }>({
            query: ({uuid, data}) => ({
                method: 'PUT',
                url: `/api/update-meeting-room`,
                params: {room_uuid: uuid},
                data
            }),
            invalidatesTags: ['Meetings'],
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;
                    if (!result) return;

                    dispatch(setVideoPage('meeting-preview'))
                    dispatch(setActiveRoomId(result.uuid))
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            },
        }),

        /**
         * DELE Requests
         */
        deleteMeeting: builder.mutation<null, string>({
            query: (uuid) => ({
                method: 'DELETE',
                url: `/api/delete-meeting-room`,
                params: {room_uuid: uuid},
            }),
            invalidatesTags: ['Meetings'],
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    if (!data) return;

                    dispatch(setVideoPage('meeting-home'))
                    dispatch(setActiveRoomId(null))
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            },
        }),
    })
})

export const {
    useGetOneMeetingQuery,
    useGetAllMeetingsQuery,
    usePostMeetingMutation,
    usePutMeetingMutation,
    useDeleteMeetingMutation,
    useGetMeetingRoomCheckQuery
} = videoApi;