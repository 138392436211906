import {createSelector, EntityId, EntityState} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {MessageSliceChat, IChatMessage, ChunkType} from "../../../types";
import {messagesAdapter} from "./slice";


export const messagesEntitySelectors = messagesAdapter.getSelectors();

export const selectMessageChats = (state: RootState) => state.message.chat;


export const selectMessageChatById =
    (state: RootState, chat_id: string | undefined): MessageSliceChat | undefined =>
        chat_id ? state.message.chat[chat_id] : undefined;

export const selectMessageIds = createSelector([
        selectMessageChatById,
        (_: any, chatUuid: string, chunk: ChunkType) => chunk,
    ],
    (chat: MessageSliceChat | undefined, chunk: ChunkType): EntityId[] | undefined =>
        chunk === 'initial' ? chat?.initialChunk?.ids : chat?.searchChunk?.ids
);

export const selectChatChunk = createSelector([
        selectMessageChatById,
        (_: any, chatUuid: string, chunk: ChunkType) => chunk,
    ],
    (chat: MessageSliceChat | undefined, chunk: ChunkType): EntityState<IChatMessage> | undefined =>
        chunk === 'initial' ? chat?.initialChunk : chat?.searchChunk
);

export const selectMessageInChunk = createSelector([
        selectMessageChatById,
        (_: any, chatId: string, chunk: ChunkType, messageUuid?: string | EntityId) => ({
            chunk, messageUuid
        }),
    ],
    (chat: MessageSliceChat | undefined, {
        chunk,
        messageUuid
    }: { chunk: ChunkType, messageUuid?: string | EntityId }): IChatMessage | undefined => {
        if (!messageUuid) return undefined;
        const queryChunk = chunk === 'initial' ? chat?.initialChunk : chat?.searchChunk
        if (!queryChunk) return undefined;
        return messagesEntitySelectors.selectById(queryChunk, messageUuid)
    }
);

export const selectMessageInChat = createSelector([
        selectMessageChatById,
        (_: any, chatId: string, messageUuid?: string | EntityId) => messageUuid,
    ],
    (chat: MessageSliceChat | undefined, messageUuid): IChatMessage | undefined => {
        if (!chat || !messageUuid) return undefined;

        return messagesEntitySelectors.selectById(chat.initialChunk, messageUuid)
            || messagesEntitySelectors.selectById(chat.searchChunk, messageUuid)
    }
);

export const selectMessageChunk = createSelector([
        selectMessageChatById,
        (_: any, chatId: string, messageUuid?: string | EntityId) => messageUuid,
    ],
    (chat: MessageSliceChat | undefined, messageUuid?: string | EntityId): ChunkType | undefined => {
        if (!messageUuid || !chat) return undefined;

        const foundInInitial = messagesEntitySelectors.selectById(chat.initialChunk, messageUuid)

        if (foundInInitial) {
            return 'initial'
        }

        if (!chat.searchChunk) return undefined;

        const foundInSearch = messagesEntitySelectors.selectById(chat.searchChunk, messageUuid)

        return foundInSearch ? 'search' : undefined;
    }
);

export const selectAllMessagesInChunk = createSelector([
        selectMessageChatById,
        (_: any, chatId: string, chunk: ChunkType) => chunk,
    ],
    (chat: MessageSliceChat | undefined, chunk: ChunkType): IChatMessage[] | undefined => {
        const queryChunk = chunk === 'initial' ? chat?.initialChunk : chat?.searchChunk
        if (!queryChunk) return undefined;
        return messagesEntitySelectors.selectAll(queryChunk)
    }
);

export const selectGlobalMessageById = createSelector([
        selectMessageChats,
        (_: RootState, message_uuid: string | undefined) => message_uuid
    ],
    (chats: Record<string, MessageSliceChat>, message_uuid: string): IChatMessage | undefined => {
        if (!chats) return undefined;

        let message: IChatMessage | undefined;

        Object.values(chats).forEach((messageChat: MessageSliceChat) => {
            const found =
                messageChat.initialChunk.entities[message_uuid]
                || messageChat.searchChunk?.entities[message_uuid]
                || undefined

            if (found) {
                message = found
                // return
            }
        })

        return message;
    });

export const selectGlobalMessageLocation = createSelector([
        selectMessageChats,
        (_: RootState, message_uuid: string | undefined) => message_uuid
    ],
    (chats: Record<string, MessageSliceChat>, message_uuid: string): {
        chat_uuid: string;
        chunk: ChunkType;
        message: IChatMessage
    } | undefined => {
        if (!chats) return undefined;

        let location: {
            chat_uuid: string;
            chunk: ChunkType;
            message: IChatMessage;
        } | undefined;

        Object.keys(chats).every((key: string) => {
            const initialFound = chats[key].initialChunk.entities[message_uuid]

            if (initialFound) {
                location = {
                    chat_uuid: key,
                    chunk: 'initial',
                    message: initialFound
                }
                return false
            }

            const searchFound = chats[key].searchChunk.entities[message_uuid]

            if (searchFound) {
                location = {
                    chat_uuid: key,
                    chunk: 'search',
                    message: searchFound
                }
                return false
            }
            return true
        })

        return location;
    });

export const selectLastMessageInChunk = createSelector([
        selectMessageChatById,
        (_: any, chatUuid: string, chunk: ChunkType) => chunk,
    ],
    (chat: MessageSliceChat | undefined, chunk: ChunkType): IChatMessage | undefined => {
        const queryChunk = chunk === 'initial' ? chat?.initialChunk : chat?.searchChunk
        if (!queryChunk) return undefined;

        return messagesEntitySelectors.selectById(queryChunk, queryChunk.ids[queryChunk?.ids.length - 1])
    }
);

export const selectFirstMessageInChunk = createSelector([
        selectMessageChatById,
        (_: any, chatUuid: string, chunk: ChunkType) => chunk,
    ],
    (chat: MessageSliceChat | undefined, chunk: ChunkType): IChatMessage | undefined => {
        const queryChunk = chunk === 'initial' ? chat?.initialChunk : chat?.searchChunk
        if (!queryChunk) return undefined;

        return messagesEntitySelectors.selectById(queryChunk, queryChunk.ids[0])
    }
);

export const selectChatMessages = createSelector([selectMessageChatById],
    (chat: MessageSliceChat | undefined): IChatMessage[] | undefined =>
        chat ? messagesEntitySelectors.selectAll(chat.initialChunk) : undefined
);

export const selectAllMessageIdsInChat = createSelector([selectMessageChatById],
    (chat: MessageSliceChat | undefined): EntityId[] => {
        if (!chat) return []

        return messagesEntitySelectors.selectIds(chat.initialChunk)
            .concat(messagesEntitySelectors.selectIds(chat.searchChunk))

    }
);