import { isCallSwitch, isPulseHd } from '../../helpers';


export const getVideoBaseUrl = (min?: boolean) => {
    let url: string;

    switch (true) {
        case isPulseHd:
            url = 'meet.pulsehd.app';
            break;

        case isCallSwitch:
            url = 'meet.callswitchone.app';
            break;

        default:
            url = 'meet.yay.app';
    }

    return min ? url : `https://${url}`;
};

export const joinMeetingId = (meetingId: string) => {
    const baseUrl = getVideoBaseUrl();
    window.open(`${baseUrl}/${meetingId}`, '_blank')?.focus();
};

// Helper function to format MAC addresses
export const formatMacAddresses = (registrations: any) =>
    registrations?.map(device => {
        // Extract MAC address, remove colons, and convert to lowercase
        const macAddressWithoutColons = device.user_agent.split(' ').pop()?.toLowerCase().replace(/:/g, '');
        // Return the modified device object
        return {
            ...device,
            mac_address: macAddressWithoutColons
        };
    });

