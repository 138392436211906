import {api} from ".";
import {addOneToast, updateAvatarKey} from "../slices";
import { t } from "../../lib/polyglot.lib";

export const userApi = api.injectEndpoints({
    endpoints: builder => ({

        /**
         * GET Requests
         */


        /**
         * POST Requests
         */
        postUserAvatar: builder.mutation<any, {
            userUuid: string,
            base64Img: string
        }>({
            query: ({
                        userUuid,
                        base64Img
                    }) => ({
                method: 'POST',
                url: `/api/post-user-avatar?voip_user_uuid=${userUuid}`,
                data: {
                    data: base64Img
                }
            }),
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;

                    if (result === undefined) return;

                    dispatch(updateAvatarKey(result.file_key))

                    dispatch(addOneToast({
                        type: 'success',
                        title: t("phrases.icon_updated"),
                        content: t("phrases.your_users_icon_has_been_updated"),
                    }))
                } catch {
                    // do nothing
                }
            }
        }),

        /**
         * PUT Requests
         */

        /**
         * DELETE Requests
         */
        deleteUserAvatar: builder.mutation<any, { userUuid: string }>({
            query: ({
                        userUuid,
                    }) => ({
                method: 'DELETE',
                url: `/api/post-user-avatar?voip_user_uuid=${userUuid}`
            }),
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(updateAvatarKey(''))

                    dispatch(addOneToast({
                        type: 'success',
                        title: t("phrases.icon_updated"),
                        content: t("phrases.your_users_icon_has_been_updated"),
                    }))
                } catch {
                    // do nothing
                }
            }
        }),
    })
})

export const {
    usePostUserAvatarMutation,
    useDeleteUserAvatarMutation,
} = userApi;