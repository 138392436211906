/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {FunctionComponent} from 'react';
import {InterTenantClient} from "../../../../types";
import Icon from "../../../Icon";
import {useTypedDispatch} from "../../../../redux/hooks";
import {setViewContactItem} from "../../../../redux/slices";

interface Props {
    client: InterTenantClient;
    dividers: string[] | undefined
    tileView?: boolean;
}

const TenantRow: FunctionComponent<Props> = ({client, dividers, tileView}) => {
    const dispatch = useTypedDispatch();

    const handleTenantClick = () => {

        dispatch(setViewContactItem({
            type: "tenant",
            data: client.uuid
        }))
    }

    let className = 'contact-card';

    if (tileView) {
        className += ' tile-view'
    }

    return (<>
        {dividers?.map(d => (
            <div key={`${d}_${client.uuid}`}>
                <div className='list-divider'>
                    <p>{d}</p>
                </div>
            </div>
        ))}
        <div
            className={className}
            title={client.name}
            onClick={handleTenantClick}
            // onContextMenu={(e) => handleContextMenu(e)}
            // ref={contactRef}
            tabIndex={0}
            // data-is-prov-item={provisionItemUuid === contact.uuid || null}
        >
            <div>
                <div>
                    <Icon name='switchCalls'/>
                    <p>
                        {client.name}
                    </p>
                </div>
            </div>
        </div>
    </>);
};

export default TenantRow;
