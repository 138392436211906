import React, {useEffect} from "react";
import {InterTenantUser} from "../../../../types";
import EntityIcon from "../../../icons/EntityIcon";
import styles from './styles.module.scss';
import CallIconButton from "../../../buttons/CallIconButton";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    selectActiveChat, selectCanAddFriend,
    selectChatEnabled,
    setTenantChat,
    setViewContact,
    startCall,
    updateActivePage
} from "../../../../redux/slices";
import {useUserStatusContext} from "../../../../pages/home/children/UserStatusContext";
import {StyledButton} from "../../../StyledComponents";
import Icon from "../../../Icon";

interface Props {
    data: InterTenantUser,
    prefix: number,
    tenant: string,
}

const InterTenantUserDisplay: React.FC<Props> = ({data, prefix, tenant}) => {
    const {
        user_uuid, extension, user_display_name, user_sip_name, user_avatar_key, can_chat
        // , number, country_code
    } = data;

    const chatEnabled = useTypedSelector(selectChatEnabled);
    const activeChat = useTypedSelector(selectActiveChat);
    const canAddFriend = useTypedSelector(selectCanAddFriend);

    const dispatch = useTypedDispatch();

    const {addNameForReq} = useUserStatusContext();
    useEffect(() => {
        const returnFunc = () => {
            addNameForReq(user_sip_name, false)
        }

        addNameForReq(user_sip_name, true)

        return returnFunc;
    }, []);

    const handleCallClient = () => {
        dispatch(startCall(`${prefix}${extension}`, user_display_name, undefined, tenant))

        dispatch(setViewContact())
    }
    const openChat = () => {
        if (activeChat === user_uuid) {
            dispatch(updateActivePage("chat"));
        } else {
            dispatch(setTenantChat(data));
        }
        dispatch(setViewContact())
    };

    return (
        <div className={styles.inter_tenant_user}>
            <EntityIcon
                type="user"
                details={{
                    sip_name: user_sip_name,
                    display_name: user_display_name,
                    avatar_key: user_avatar_key,
                    blf: true,
                    uuid: user_uuid,
                }}
                size={28}
            />
            <p>
                {user_display_name}
                <span>
                    ({extension})
                </span>
            </p>
            <div className={styles.options}>
                {(chatEnabled && canAddFriend && can_chat) ? (
                    <StyledButton
                        buttonStyle='text'
                        onClick={() => openChat()}
                        title="Chat"
                        iconOnly
                    >
                        <Icon name='messageFilled' ariaHidden width={22} height={22}/>
                    </StyledButton>
                ) : null}
                <CallIconButton
                    handleCall={() => handleCallClient()}
                    user={{
                        uuid: user_uuid,
                        name: user_sip_name,
                    }}
                />
            </div>
        </div>
    )
}

export default InterTenantUserDisplay;