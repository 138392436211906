import React, {useRef, useState} from 'react';
import {
    selectChatSettingByKey,
    selectCurrentMessageId,
    selectMessageIds,
    updateChatSettings
} from '../../../redux/slices';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {useOnClickOutside} from '../../../helpers';
import {usePutMessageIdMutation} from "../../../redux/services/sipApi";
import styles from '../SettingsPage.module.scss'
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import SettingsDropdown from "./SettingsComponents/SettingsDropdown";
import {StyledDropdownOption} from "../../../components/StyledComponents";
import SettingsSlider from "./SettingsComponents/SettingsSlider";

interface ChatProps {
}

export const Chat: React.FC<ChatProps> = () => {
    const user = useTypedSelector(state => state.user);

    const disableEnter = useTypedSelector(state => selectChatSettingByKey(state, 'disableEnter'));
    const messageIds = useTypedSelector(selectMessageIds);
    const selectedMessageId = useTypedSelector(selectCurrentMessageId);

    const [dropdownActive, setDropdownActive] = useState<'chat-view' | 'chat-style' | 'message-id'>();

    const chatViewRef = useRef<HTMLDivElement>(null);
    const chatStyleRef = useRef<HTMLDivElement>(null);
    const chatMessageIdRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch();

    const [putMessageId, {isLoading: messageIDLoading}] = usePutMessageIdMutation();

    useOnClickOutside(chatViewRef, () => setDropdownActive(dropdownActive === 'chat-view' ? undefined : dropdownActive));
    useOnClickOutside(chatStyleRef, () => setDropdownActive(dropdownActive === 'chat-style' ? undefined : dropdownActive));
    useOnClickOutside(chatMessageIdRef, () => setDropdownActive(dropdownActive === 'message-id' ? undefined : dropdownActive));

    const {t} = usePolyglot();

    const smsOutNumOpts: StyledDropdownOption[] = messageIds.map(mid => ({
        label: `${mid.cli_name} (${mid.cli_display})`,
        value: mid.uuid
    }))

    smsOutNumOpts.unshift({
        label: t("phrases.deselect_message_id"),
        value: ''
    })

    return (
        <div className={styles.container}>
            <MenuHeader title={t("terms.chat_settings")} description={t("phrases.customise_your_chat_experience")}/>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.sms_id")}</h2>
                    <p>{t("phrases.decide_your_outbound_number")}</p>
                </div>
                <div>
                    <SettingsDropdown
                        title={t("terms.sms_outbound_number")}
                        options={smsOutNumOpts}
                        onSelect={(val) => putMessageId({
                            voip_user_uuid: user.uuid,
                            caller_uuid: val || undefined
                        })}
                        selected={selectedMessageId?.uuid}
                        placeHolder={t("phrases.select_message_id")}
                        loading={messageIDLoading}
                    />
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.accessibility")}</h2>
                    <p>{t("phrases.customise_the_chat_interface")}</p>
                </div>
                <div>
                    <SettingsSlider
                        title={t("phrases.press_enter_to_send_message")}
                        checked={!disableEnter}
                        onChange={() =>
                            dispatch(
                                updateChatSettings({
                                    setting: 'disableEnter',
                                    value: !disableEnter
                                })
                            )
                        }
                    />
                </div>
            </div>

        </div>
    );
};
