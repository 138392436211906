import React, {useEffect, useRef, useState} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {selectActiveRoomId, selectVideoPage, setActiveRoomId, setVideoPage} from "../../redux/slices";
import MeetingPreview from "./children/MeetingPreview";
import VideoPageHome from "./VideoPageHome";
import styles from './styles.module.scss'
import MeetingConfig from "./MeetingConfig";

interface Props {
}

const Video: React.FC<Props> = () => {
    const dispatch = useTypedDispatch();
    const page = useTypedSelector(selectVideoPage);
    const activeMeetingId = useTypedSelector(selectActiveRoomId);

    const [isSmall, setIsSmall] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const isSmallRef = useRef(false);

    const observer = useRef(
        new ResizeObserver(entries => {
            if (entries[0].contentRect.width < 735 && !isSmallRef.current) {
                isSmallRef.current = true
                setIsSmall(true)
            }

            if (entries[0].contentRect.width >= 735 && isSmallRef.current) {
                isSmallRef.current = false
                setIsSmall(false)
            }
        })
    )
    useEffect(() => {
        if (containerRef?.current) {
            observer.current.observe(containerRef.current)
        }

        return () => {
            if (containerRef?.current) {
                observer.current.unobserve(containerRef.current)
            }
        }
    }, [containerRef, observer])

    useEffect(() => () => {
        dispatch(setActiveRoomId(null))
        dispatch(setVideoPage('meeting-home'))
    }, [])

    let template: JSX.Element;

    switch (page) {
        case 'meeting-preview':
            template = <MeetingPreview/>
            break;
        case 'meeting-create':
            template = <MeetingConfig key="new"/>
            break;
        case 'meeting-edit':
            template = <MeetingConfig key={activeMeetingId}/>
            break;
        case 'meeting-join':
            template = <VideoPageHome/>
            break;
        case 'meeting-home':
        default:
            template = <VideoPageHome/>
    }

    return (
        <div
            className={styles.container}
            ref={containerRef}
            data-is-small={isSmall || null}
        >
            {template}
        </div>
    )
};

export default Video;
