import React, {useRef, useState} from "react";
import {motion} from "framer-motion/dist/framer-motion";
import {
    resetUsersForChannelSelection,
    selectCurrentUserId,
    setChannelCreation,
    setSingleUserForChannelSelection
} from "../../../redux/slices";
import Icon from "../../../components/Icon";
import ContactsSearch from "./ContactsSearch/ContactsSearch";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {usePutChannelUsersMutation} from "../../../redux/services/chatApi";
import {usePolyglot} from "../../../context/Polyglot";
import {useChatContext} from "../../../context/ChatContext/context";
import {ChatSettingsSidebarMenus} from "./index";
import styles from './styles.module.scss';
import {StyledButton} from "../../../components/StyledComponents";

interface ChatSettingsAddUsersProps {
    setSideBarTab: (val: ChatSettingsSidebarMenus) => void;
}

export const ChatSettingsAddUsers: React.FC<ChatSettingsAddUsersProps> = ({setSideBarTab}) => {
    const {chat} = useChatContext();

    const [searchTerm, setSearchTerm] = useState<string>('');

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useTypedDispatch()
    const {t} = usePolyglot();

    const usersForGroupSelection = useTypedSelector(state => state.chat.usersForGroupSelection);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const [putChannelUsers] = usePutChannelUsersMutation();

    const membersBeingAdded = chat?.channel
        ? usersForGroupSelection.filter(
            selectedUser =>
                !chat?.channel?.members.map(member => member.member).includes(selectedUser.uuid)
        )
        : [];


    const handleAddMembers = () => {
        if (membersBeingAdded.length > 0) {
            putChannelUsers({
                voip_user_uuid: userUuid,
                channel_uuid: chat.uuid,
                changes: {
                    members_to_add: membersBeingAdded.map(member => member.uuid)
                }
            })
            dispatch(setChannelCreation(false));
            dispatch(resetUsersForChannelSelection())
            setSideBarTab('menu')
        }
    }

    const handleGroupSelection = (usr) => {
        const groupSelectParams = {
            uuid: usr.uuid,
            image: usr.avatar_key,
            display_name: usr.nickname
        }
        inputRef.current?.focus()
        dispatch(setSingleUserForChannelSelection(groupSelectParams));
        setSearchTerm('')
    }

    return (
        <motion.div
            className={styles.chat_settings_page}
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
        >
            <div className={styles.header}>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    size='medium'
                    onClick={() => {
                        setSideBarTab('menu')
                        dispatch(resetUsersForChannelSelection())
                    }}
                    title='Return to settings menu'
                >
                    <Icon name='arrowLeft' ariaHidden/>
                </StyledButton>
                <h4>{t("actions.add_user", 2)}</h4>
                {usersForGroupSelection.length > 0 &&
                    <StyledButton
                        buttonStyle='primary'
                        onClick={handleAddMembers}
                        className='overview__save-button'
                    >
                        <p>{t("actions.save")}</p>
                    </StyledButton>
                }
            </div>
            {usersForGroupSelection.length > 0 &&
                <ul className={styles.selected_users}>
                    {usersForGroupSelection?.map((usr, index) => (
                        <li key={index}>
                            <p>{usr.display_name}</p>
                            <StyledButton
                                buttonStyle='text'
                                iconOnly
                                onClick={() => handleGroupSelection(usr)}
                                size='small'
                            >
                                <Icon name='cross' width={13} height={13}/>
                            </StyledButton>
                        </li>
                    ))}
                </ul>
            }
            <div
                className={styles.input_container}
                onClick={() => {
                    inputRef.current?.focus()
                }}
            >
                <Icon name='search' ariaHidden/>
                <input
                    name='contacts Search'
                    placeholder={t("actions.search_contacts")}
                    value={searchTerm}
                    ref={inputRef}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <ContactsSearch
                searchTerm={searchTerm}
                handleSelect={handleGroupSelection}
                excluded={chat?.channel?.members.map((member) => member.member)}
            />
        </motion.div>
    )
}
