import React, {ReactNode} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import styles from '../styles.module.scss';
import {MeetingRoomMember} from "../../../types/video";
import {useTypedSelector} from "../../../redux/hooks";
import {selectAuthUserByUuid, selectCurrentUserId} from "../../../redux/slices";
import EntityIcon from "../../../components/icons/EntityIcon";
import StyledDropdown from "../../../components/StyledComponents/StyledDropdown";
import AriaUserSearchSelect from "../../../components/AriaComponents/AriaUserSearchSelect";

interface Props {
}

export const MeetingParticipant: React.FC<{ uuid: string, admin: boolean, options?: ReactNode }> = ({
                                                                                                        uuid,
                                                                                                        admin,
                                                                                                        options
                                                                                                    }) => {
    const user = useTypedSelector(state => selectAuthUserByUuid(state, uuid));

    if (!user) return null;

    return (
        <div className={styles.meeting_participant}>
            <EntityIcon
                type='user'
                details={{
                    display_name: user.nickname,
                    avatar_key: user?.avatar_key,
                    uuid
                }}
                size={24}
            />
            <p>
                {user.nickname}
                {admin ? (
                    <>
                        &nbsp;(Admin)
                    </>
                ) : null}
            </p>
            {options}
        </div>
    )
}

const MeetingConfigParticipants: React.FC<Props> = () => {
    const {control} = useFormContext();
    const currentUserUuid = useTypedSelector(selectCurrentUserId);

    return (
        <div
            className={styles.config_participants}
        >
            <Controller
                name='members'
                control={control}
                render={({
                             field: {onChange, value},
                         }) => (
                    <>
                        <AriaUserSearchSelect
                            selectedUsers={value.map(v => v.user_id)}
                            label='Participants'
                            onUserClick={(user) => {
                                const isSelected = value.some(v => v.user_id === user.uuid);

                                if (isSelected) {
                                    onChange(value.filter(v => v.user_id !== user.uuid))
                                } else {
                                    onChange([...value, {
                                        user_id: user.uuid,
                                        user_name: user.nickname,
                                        admin: false
                                    }])
                                }
                            }}
                            placeholder='Search'
                        />
                        {value && Array.isArray(value) && value.length > 0 ? (
                            <ul>
                                {[...value]
                                    .sort((a: MeetingRoomMember, b: MeetingRoomMember) => {
                                        if (a.user_id === currentUserUuid) {
                                            return -1;
                                        }
                                        if (b.user_id === currentUserUuid) {
                                            return 1;
                                        }

                                        if (a.admin === b.admin) {
                                            return a.user_name.toLowerCase().localeCompare(b.user_name.toLowerCase());
                                        }

                                        return a.admin ? -1 : 1;
                                    })
                                    .map((val: MeetingRoomMember) => (
                                        <MeetingParticipant
                                            key={val.user_id}
                                            uuid={val.user_id}
                                            admin={val.admin || false}
                                            options={val.user_id !== currentUserUuid ? (
                                                <StyledDropdown
                                                    options={[{
                                                        label: 'Remove',
                                                        onSelect: () => {
                                                            onChange(value.filter(v => v.user_id !== val.user_id))
                                                        }
                                                    }, {
                                                        label: val.admin ? 'Remove Admin' : 'Make Admin',
                                                        onSelect: () => {
                                                            onChange(value.map(v => {
                                                                if (v.user_id === val.user_id) {
                                                                    return {
                                                                        ...v,
                                                                        admin: !v.admin
                                                                    }
                                                                }
                                                                return v;
                                                            }))
                                                        }
                                                    }]}
                                                    iconOnly
                                                />
                                            ) : undefined}
                                        />
                                    ))}
                            </ul>
                        ) : null}
                    </>
                )
                }
                rules={{required: true}}
            />
        </div>
    );
};

export default MeetingConfigParticipants;
