import React from "react";
import {useContactContext} from "../../../context/ContactContext/context";
import {IUser} from "../../../types";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    selectActiveChat, selectCallingShortcodesOnUser,
    selectChatEnabled, selectCurrentUser, selectDepartmentByUserUuid,
    selectUserStatus,
    setActiveChat, setViewContact, startCall,
    updateActivePage
} from '../../../redux/slices';
import {usePolyglot} from "../../../context/Polyglot";
import {useCallContact} from "../../../components/sidebars/Contact/hooks";
import StyledDropdown, {StyledDropdownOption} from "../../../components/StyledComponents/StyledDropdown";
import styles from "../syles.module.scss";
import {StyledButton} from "../../../components/StyledComponents";
import Icon from "../../../components/Icon";
import {isPreview} from "../../../helpers";
import DepartmentTag from "../../../components/buttons/DepartmentTag";
import AuxCodeController from "../../../pages/home/children/AuxCodeController/AuxCodeController";
import CallIconButton from "../../../components/buttons/CallIconButton";

interface OptionProps {
    inTag?: boolean
}

export const UserOptions: React.FC<OptionProps> = ({inTag}) => {
    const contact = useContactContext().contact as IUser;

    const chatEnabled = useTypedSelector(selectChatEnabled);
    const activeChat = useTypedSelector(selectActiveChat);
    const userStatus = useTypedSelector(state => selectUserStatus(state, contact.name));
    const {
        pickupShortcode,
        listenShortcode,
        whisperShortcode,
        bargeShortcode
    } = useTypedSelector(state => selectCallingShortcodesOnUser(state, contact.uuid));

    const {t} = usePolyglot();
    const {callContact} = useCallContact()

    const dispatch = useTypedDispatch();

    const openChat = () => {
        if (activeChat === contact.uuid) {
            dispatch(updateActivePage("chat"));
        }
        dispatch(setActiveChat(contact.uuid));
        dispatch(setViewContact())
    };

    type ExtraOptionsValue = 'barge' | 'listen' | 'whisper';

    const extraOptions: StyledDropdownOption[] = [];

    if (listenShortcode) {
        extraOptions.push({
            label: t("actions.listen"),
            value: 'listen'
        })
    }

    if (whisperShortcode) {
        extraOptions.push({
            label: t("actions.whisper"),
            value: 'whisper',
        })
    }

    if (bargeShortcode) {
        extraOptions.push({
            label: t("actions.join"),
            value: 'barge'
        })
    }

    const handleExtraOption = (option: ExtraOptionsValue) => {
        switch (option) {
            case "barge":
                dispatch(
                    startCall(
                        `${bargeShortcode?.short_code}${contact.extension}`,
                        t("phrases.joining_to_%n", {n: contact.nickname})
                    )
                )
                break;
            case "listen":
                dispatch(
                    startCall(
                        `${listenShortcode?.short_code}${contact.extension}`,
                        t("phrases.listening_to_%n", {n: contact.nickname})
                    )
                )
                break;
            case "whisper":
                dispatch(
                    startCall(
                        `${whisperShortcode?.short_code}${contact.extension}`,
                        t("phrases.whispering_to_%n", {n: contact.nickname})
                    )
                )
                break;
        }
        dispatch(setViewContact())
    }

    return (

        <>
            <div className={styles.mainOptions}>
                <CallIconButton
                    user={contact}
                    handleCall={() => callContact()}
                />
                {chatEnabled ? (
                    <StyledButton
                        buttonStyle='text'
                        onClick={() => openChat()}
                        title={t("actions.chat")}
                        iconOnly
                    >
                        <Icon name='messageFilled' ariaHidden width={22} height={22}/>
                    </StyledButton>
                ) : null}
                {isPreview ? (
                    <StyledButton
                        buttonStyle='text'
                        title={t("terms.video", 1)}
                        iconOnly
                    >
                        <Icon name='videoFilled' ariaHidden width={22} height={22}/>
                    </StyledButton>
                ) : null}
                {!inTag && userStatus && userStatus.status === 'early' && pickupShortcode && (
                    <StyledButton
                        buttonStyle='text'
                        onClick={() =>
                            dispatch(
                                startCall(
                                    `${pickupShortcode?.short_code}${contact.extension}`,
                                    `${t("actions.pick_up")} ${contact.nickname}`
                                )
                            )
                        }
                        title={t("actions.pick_up")}
                        iconOnly
                    >
                        <Icon name='callOutgoing' ariaHidden width={22} height={22}/>
                    </StyledButton>
                )}
            </div>
            {!inTag && extraOptions.length > 0 ? (
                <StyledDropdown
                    onSelect={(val: string) => handleExtraOption(val as ExtraOptionsValue)}
                    options={extraOptions}
                    display={(<p>More</p>)}
                    buttonStyle='text'
                    noDownArrow
                    className={styles.extra_opts}
                />
            ) : null}
        </>
    )
}

export const UserMain: React.FC = () => {
    const contact = useContactContext().contact as IUser;
    const user = useTypedSelector(selectCurrentUser);

    const departments = useTypedSelector(state => selectDepartmentByUserUuid(state, contact.uuid)) || []

    const {t} = usePolyglot();

    return (
        <>
            {(user.allow_custom_aux_codes || isPreview) ? (
                <div className={[styles.item, styles.dropdown].join(' ')}>
                    <label
                        htmlFor='aux_code'
                    >
                        Call Queue Status
                    </label>
                    <AuxCodeController
                        user_data={contact.uuid === user.uuid ? undefined : {
                            user_name: contact.name,
                            user_uuid: contact.uuid,
                        }}
                    />
                </div>
            ) : null}
            <div className={styles.item}>
                <label>
                    {t("terms.extension", 1)}
                </label>
                <div>
                    <p>
                        {contact.extension}
                    </p>
                </div>
            </div>
            <div className={styles.item}>
                <label>
                    {t("terms.department", 2)}
                </label>
                <div className={styles.departments}>
                    {departments.map(dept => (
                        <DepartmentTag
                            key={dept.uuid}
                            department={dept}
                        />
                    ))}
                    {departments.length < 1 ? (
                        <p>
                            User is not in any departments
                        </p>
                    ) : null}
                </div>
            </div>
        </>
    )
}