import {EntityState} from "@reduxjs/toolkit";
import {INotifyMessageStatus} from "./bifrost";

export type ChatType = 'user' | 'channel' | 'thread' | 'integration';

export type IChatIntegrationType = 'sms' | 'whatsapp';

export type ChatMessageState = 'sent' | 'failed' | 'sending'

export type IMessageReactionDictionary = Record<string, IReaction[]>;

export const isUserChat = (chat: IChat): boolean => chat.type === 'user';

export const getIntegrationType = (channel: number): IChatIntegrationType | undefined => {
    let channelType: IChatIntegrationType | undefined;

    switch (channel) {
        case 1:
            channelType = 'sms'
            break

        case 4:
            channelType = 'whatsapp'
            break
    }

    return channelType;
}

export type MessageStatusTypes =
    | 'delivered'
    | 'read'
    | 'read_all'
    | 'start_typing'
    | 'still_typing'
    | 'stop_typing'
    | 'deleted';

export const isIntegration = (chat: IChat): boolean =>
    ['integration'].includes(chat.type);

export const isChat = (chat: IChat): boolean =>
    ['user', 'channel'].includes(chat.type);

export const isThread = (chat: IChat): boolean =>
    ['thread'].includes(chat.type);

export const isChannelType = (chatType: ChatType): boolean => chatType === 'channel';

export interface IChatMessageReceipt {
    user: string;
    time: string
}

export interface IChatParticipant {
    display_name: string;
    avatar?: string;
    internal?: boolean;
    uuid: string;
}

export interface IMessageReaction {
    content: string;
    time: string;
    user: string;
    uuid: string;
}

export interface IThreadSource {
    source_uuid: string;
    source_type: ChatType
}

export interface IChatMessage {
    from: string;
    time: string;
    updated_at: string;
    uuid: string;
    files?: IFile[];
    content?: string;
    from_type?: ChatType;
    reactions?: IReaction[];
    read?: IChatMessageReceipt[];
    delivered?: IChatMessageReceipt[];
    to?: string;
    thread?: {
        count: number;
        members: string[];
    };
    to_type?: ChatType;
    reply_to?: string;
    state?: ChatMessageState;
    deleted_at?: string;
    deleted?: boolean;
}

export interface IChatIntegration {
    channel: IChatIntegrationType,
    display_name: string,
    number: string,
    uuid: string,
}

export interface IChatBase {
    display_name: string;
    unread_count: number;
    new_messages_count: number;
    user_deleted: boolean;
    uuid: string;
    type: ChatType;
    files?: IFile[];
    channel?: IChatChannel;
    thread_message?: IChatMessage;
    integration?: IChatIntegration;
    source?: IThreadSource | undefined;
    needs_friend_req?: boolean;
}

// Used for API response types
export interface IChatFromApi extends IChatBase {
    messages: IChatMessage[];
}

export interface ILatestChat extends IChatBase {
    message: IChatMessage;
    chat_updated_at?: string;
    last_message: string;
    last_message_at: string;
    last_message_from: string;
    last_message_uuid: string;
}

// Used for Redux State
export interface IChat extends IChatBase {
    active_chunk: ChunkType
    latestMessage: {
        id: string;
        time: string;
    }
}

export type ChunkType = 'initial' | 'search';

export interface IThread extends IChat {
    thread_message?: IChatMessage;
}

export interface IIntegration extends IChat {
    integration: IChatIntegration;
}

export interface IGroupChat extends IChat {
    channel?: IChatChannel;
}

export interface IChannelMember {
    member: string;
    joined_at: string;
    invited_by: string;
    external: boolean;
    display_name: string;
    avatar?: string;
}

export interface IUserForChannel {
    uuid: string;
    image: string;
    display_name: string;
}

export interface IChatState {
    userUuid: string;
    chats: EntityState<IChat>;
    typingStatuses: Record<string, INotifyMessageStatus[]>
    failedMessagesQueue: Record<string, IChatMessage[]>
    messageReactions: Record<string, IMessageReaction>;
    messageReplies: Record<string, { message: IChatMessage; sender: string } | undefined>;
    externalReplies: IChatMessage[];
    groupCreationActive: boolean;
    usersForGroupSelection: IUserForChannel[];
    activeChat?: string;
    inProgressTextInputs?: Record<string, string>
    searchResults?: Record<string, IChatMessage[] | undefined>;
    scrollToMessageId?: string;
    scrollToThreadMessageId?: string;
    focusAttachment: FocusMessageAttachment | undefined;
    lastUpdatedChatId: string;
}

export interface FocusMessageAttachment {
    src: string,
    file_name?: string,
    file_size?: number,
    file_type: string,
    shared_by?: string,
    shared_at?: Date,
    uuid?: string
}

export interface ILatestChatMessage {
    chat_updated_at: string;
    display_name?: string;
    last_message: string;
    last_message_at: string;
    last_message_from: string;
    last_message_uuid: string;
    message?: IChatMessage;
    type: ChatType;
    unread_count: number;
    user_deleted: boolean;
    uuid: string;
    source?: { content: string; source_uuid: string; source_type: ChatType };
    thread_message?: IChatMessage;
    channel?: IChatChannel;
}

export interface IFile {
    content: string;
    content_type: string;
    index: number;
    metadata?: string;
    name: string;
    pending?: boolean;
    shared_at: string;
    shared_by: string;
    size: number;
    uuid: string;
}

export interface IReaction {
    content: string;
    time: string;
    user: string;
    uuid: string;
}

/**
 * @member {string} member uuid;
 * @member {string} joined_at date;
 * @member {string} invited_by uuid;
 * @member {string} external boolean;
 * @member {string} display_name probs their name;
 * @member {string} avatar probably a dog pic;
 */


export interface IChatMember {
    member: string;
    joined_at: string;
    invited_by: string;
    external: boolean;
    display_name: string;
    avatar?: string;
}

export interface IChatChannel {
    uuid: string;
    name: string;
    private: boolean;
    muted: boolean;
    muted_desktop: boolean;
    created_by: string;
    created_at: string;
    updated_at: string;
    avatar_set: boolean;
    members: IChatMember[];
}


export interface IUserDictionary {
    [key: string]: IChatMember;

    [key: number]: IChatMember;
}

export interface IDummyFileMessage {
    ContentString: string;
    MetaData: null;
    ThumbnailString: string;
    content: string;
    content_type: string;
    id: string;
    name: string;
}

export interface MessageSliceState {
    chat: Record<string, MessageSliceChat>
}

export interface MessageSliceChat {
    initialChunk: EntityState<IChatMessage>;
    searchChunk: EntityState<IChatMessage>;
    searchMessageId?: string;
}

export interface FriendRequest {
    to: string,
    from: string,
    from_name: string,
    message: string,
    display_name: string,
}