import React, { ReactNode } from 'react';
import styles from './CallHistoryRow.module.scss';
import Icon, { IconName } from '../../../../components/Icon';

interface Props {
    answered: boolean;
    callIcon: IconName;
    rowName: string;
    secondaryString: string | undefined;
    subHeader: ReactNode;
    date: string;
    duration: string;
    options: ReactNode;
    elsewhere?: boolean;
}

const CallHistoryRowDisplay: React.FC<Props> = ({
    answered,
    callIcon,
    rowName,
    secondaryString,
    subHeader,
    date,
    duration,
    options,
    elsewhere
}) => (
    <li
        className={styles.container}
        data-answered={answered}
        data-has-options={!!options || null}
        // onClick={() => (options && displayNumber) && dispatch(startCall(displayNumber))}
        // title={(options && displayNumber) ? t("actions.start_call") : ''}
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
        data-elsewhere={elsewhere || null}
    >
        <div className={styles.callStatus}>
            <Icon name={callIcon} />
        </div>
        <div className={`${styles.caller} ${styles.dataRows}`}>
            <div className={styles.callerName}>
                <p>{rowName}</p>
                {secondaryString ? <p>{secondaryString}</p> : null}
            </div>
            <p>{subHeader}</p>
        </div>
        <div className={`${styles.timeData} ${styles.dataRows}`}>
            <p>{date}</p>
            <p>{duration}</p>
        </div>
        {options ? <div className={styles.hoverMenu}>{options}</div> : null}
    </li>
);

export default CallHistoryRowDisplay;
