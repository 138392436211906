import {Text} from 'slate';

const fromBlock = ({children, element}) => {
    switch (element.type) {
        case 'paragraph':
            return `${children} ${`\n`}`
        case 'headingOne':
            return `# ${children}`;
        case 'headingTwo':
            return `## ${children}`;
        case 'headingThree':
            return `### ${children}`;
        case `code`:
            return `\`\`\`${children}\`\`\``
        case 'list-item':
            return `- ${children} ${`\n`}`
        case 'numbered-list':
            return children.split("\n").map((c, i) => c.replace("- ", `${i + 1}. `)).join("\n");
        default:
            return children;
    }
}

const fromMarked = (baseChildren, leaf) => {
    let children = baseChildren;

    if (leaf.italic) {
        children = `_${children}_`
    }
    if (leaf.bold) {
        children = `**${children}**`
    }

    if (leaf.code) {
        children = `\`\`\`${children}\`\`\``
    }

    if (leaf.strikethrough) {
        children = `~~${children}~~`
    }

    if (leaf.tagType === 'user') {
        children = `<UserTag uuid="${leaf.data.uuid}" />`
    }

    if (leaf.tagType === 'department') {
        children = `<DepartmentTag uuid="${leaf.data.uuid}" />`
    }

    if (leaf.tagType === 'contact') {
        // cutting empty values from the final message
        try {
            const contactAsString = JSON.stringify(leaf.data)
            children = `<ContactTag contact=${contactAsString}/>`
        } catch (e) {
            children = '@Unknown'
        }
    }

    if (leaf.underline) {
        children = `__${children}__`
    }

    return children;
}

const objToMarkdown = node => {
    if (Text.isText(node)) {
        return (fromMarked(node.text, node))
    }

    const children = node.children.map(n => objToMarkdown(n)).join('');

    return fromBlock({children, element: node});
}

// eslint-disable-next-line consistent-return
export const slateToMarkdown = (slateValue) => {
    if (slateValue?.length > 0) {
        return slateValue.map(n => objToMarkdown(n)).join('')
    }
}

export default slateToMarkdown;
