import {MutableRefObject, RefObject, useEffect, useRef} from "react";
import {GetAndSet} from "../pages/chat/ChatMessages/hooks";

export interface PlaceAboveProps {
    relativeElement: RefObject<HTMLElement>,
    fixedElement: RefObject<HTMLElement>,
    xPos?: 'left' | 'right',
    yPos?: 'bottom',
    offset?: {
        x?: number,
        y?: number,
    },
    flipped?: boolean,
}

interface MainProps extends PlaceAboveProps {
    minWidth: MutableRefObject<GetAndSet<number>>
}

const placeAbove = ({
                        relativeElement,
                        fixedElement,
                        xPos,
                        yPos,
                        offset,
                        flipped,
                        minWidth
                    }: MainProps): void => {
    const relative = relativeElement.current;
    const fixed = fixedElement.current;

    if (!relative || !fixed) {
        return;
    }

    let outTop: number | undefined;
    let outRight: number | undefined;
    let outBottom: number | undefined;
    let outLeft: number | undefined;

    const relativeElementPosition = relative.getBoundingClientRect();

    const screen = {width: window.innerWidth, height: window.innerHeight};

    let leftMost: number;

    switch (xPos) {
        case 'left':
            leftMost = relativeElementPosition.left;
            if (flipped) {
                leftMost += fixed.offsetWidth
            }
            break;
        case 'right':
            leftMost = relativeElementPosition.left + relativeElementPosition.width;
            if (flipped) {
                leftMost -= fixed.offsetWidth
            }
            break;
        default:
            leftMost = relativeElementPosition.left
                + (relativeElementPosition.width / 2)
                - (fixed.offsetWidth / 2);
    }

    let topMost = relativeElementPosition.top - fixed.offsetHeight;

    if (yPos === 'bottom') {
        topMost = relativeElementPosition.top + relativeElementPosition.height
    }

    leftMost += (offset?.x || 0);
    topMost += (offset?.y || 0);

    const overlap = {
        right: leftMost + fixed.offsetWidth > screen.width,
        left: leftMost < 0,
        top: topMost < 0,
        bottom: topMost + fixed.offsetHeight > screen.height,
    }

    if (overlap.right) {
        outLeft = screen.width - fixed.offsetWidth - 8;
    } else if (overlap.left) {
        outLeft = 8;
    } else {
        outLeft = leftMost;
    }

    if (overlap.top) {
        outTop = 8;
    } else if (overlap.bottom) {
        outBottom = 8;
    } else {
        outTop = topMost;
    }

    if (!minWidth.current.get()) {
        minWidth.current.set(fixed.offsetWidth)
    }

    fixed.style.top = outTop ? `${outTop}px` : 'revert'
    fixed.style.right = outRight ? `${outRight}px` : 'revert'
    fixed.style.bottom = outBottom ? `${outBottom}px` : 'revert'
    fixed.style.left = outLeft ? `${outLeft}px` : 'revert'
    fixed.style.minWidth = `${minWidth.current.get()}px`;
}

interface UsePlaceOverProps extends PlaceAboveProps {
    display: boolean;
    onScrollExtraCallback?: () => void;
}

const usePlaceOver = ({
                          relativeElement,
                          fixedElement,
                          xPos,
                          yPos,
                          offset,
                          display,
                          flipped,
                          onScrollExtraCallback
                      }: UsePlaceOverProps) => {

    const minWidth = useRef(new GetAndSet<number>());

    const onScroll = () => {
        setPosition()
        onScrollExtraCallback?.()
    }

    const setPosition = () => {
        placeAbove({
            relativeElement,
            fixedElement,
            xPos,
            yPos,
            offset,
            flipped,
            minWidth
        });
    }

    useEffect(() => {

        if (display) {
            setPosition()
        }

        window.addEventListener("scroll", onScroll, true);
        return () => window.removeEventListener("scroll", onScroll, true);

    }, [display]);

}

export default usePlaceOver;