import {useRef, useState} from "react";
import {IToast} from "../index";
import styles from '../styles.module.scss';
import Icon from "../../Icon";
import {useTypedDispatch} from "../../../redux/hooks";
import {removeOneToast} from "../../../redux/slices";
import {useTimeout} from "../../../helpers";
import AriaButton from "../../AriaComponents/AriaButton";

interface Props {
    toast: IToast;
    stick?: boolean;
}

export const Toast: React.FC<Props> = ({toast, stick}) => {
    const [hold, setHold] = useState<boolean>(false);

    const toastTime = toast.type === 'info' ? 10000 : 8000;

    const delay = useRef<number>(toastTime);

    const dispatch = useTypedDispatch()

    useTimeout((() => {
        removeToast()
    }), delay.current, hold)

    const removeToast = () => {
        if (stick) return;
        dispatch(
            removeOneToast(toast.id)
        )
    }

    const getColour = (): string => {
        switch (toast.type) {
            case "error":
                return 'var(--color-danger-rgb)';
            case "warning":
                return 'var(--color-warning-rgb)';
            case "success":
                return 'var(--color-success-rgb)';
            default:
                return 'var(--notification-color-rgb)';
        }
    }

    return (
        <li
            className={styles.toast_item}
            onMouseEnter={() => setHold(true)}
            onMouseLeave={() => {
                setHold(false)
                delay.current = 2500
            }}
            style={{
                "--_toast-colour": getColour()
            } as any}
        >
            <div className={styles.toast_heading}>
                <h3>
                    {toast.title}
                </h3>
                <AriaButton
                    onClick={() => removeToast()}
                >
                    <Icon name='cross' width={16} height={16}/>
                </AriaButton>
            </div>
            <div className={styles.toast_content}>
            {typeof toast.content === 'string' ? (
                <p>
                    {toast.content}
                </p>
            ) : (
                toast.content
            )}
            </div>
        </li>
    )
}