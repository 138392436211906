import React, {lazy, useState, Suspense, ReactNode} from 'react';
import {useDebounce} from '../../helpers';
import {TextInput} from "../../components";
import {PhonePageType} from "../../types";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    selectFiltersByPage, selectNewVoicemailCount,
    selectParkedCallsCount,
    setPageFilter,
    updateActivePhoneTab
} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";
import {Filter, Filters} from "../../components/menus/Filters";
import MenuHeader from "../../components/menus/MenuHeader";
import CallHistory from "./call-history/CallHistory";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ContactNamesProvider from "./GetContactNamesContext";
import StyledPageTabs, {ITab} from "../../components/StyledComponents/StyledPageTabs";
import VoicemailPage from "./mailbox-messages/VoicemailPage";

const CallParking = lazy(() => import('./call-parking/CallParking'))

interface PhoneProps {
}

export const Phone: React.FC<PhoneProps> = () => {
    const page = useTypedSelector(state => state.app.active_phone_tab)
    const filter = useTypedSelector(state => selectFiltersByPage(state, 'callHistory'))
    const mailboxUuid = useTypedSelector(state => state.user.personal_mailbox);
    const parkedCount = useTypedSelector(selectParkedCallsCount);
    const newVoicemailCount = useTypedSelector(selectNewVoicemailCount);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const searchQueryDebounced = useDebounce(searchTerm, 500);

    const {t} = usePolyglot();
    const dispatch = useTypedDispatch()

    const setPage = (tab: PhonePageType) => dispatch(updateActivePhoneTab(tab));

    let pageComponent: React.ReactElement;
    let fallback: ReactNode | undefined;

    const filters: Filter[] = [
        {
            title: 'misc',
            value: '',
        },
        {
            title: t("adjective.call_internal"),
            value: 'Internal',
        },
        {
            title: t("adjective.call_inbound"),
            value: 'Inbound',
        },
        {
            title: t("adjective.call_outbound"),
            value: 'Outbound'
        },
    ]

    switch (page) {
        case 'parked':
            pageComponent = <CallParking/>;
            break;

        case 'voicemail':
            pageComponent = <VoicemailPage/>;
            break;

        case 'history':
        case 'missed':
        default:
            pageComponent = (
                <CallHistory
                    search={searchQueryDebounced}
                    filter={filter}
                    missedCallsOnly={page === 'missed'}
                />
            );
            break;
    }

    const tabs: ITab[] = [
        {
            value: 'history',
            label: t("terms.history"),
        },
        {
            value: 'missed',
            label: t("phrases.missed_call", 2),
        },
        {
            value: 'parked',
            label: t("adjective.call_parked"),
            notification_count: parkedCount,
        },
        {
            value: 'voicemail',
            label: "Voicemail",
            disabled: !mailboxUuid ? 'No mailbox assigned to account' : undefined,
            notification_count: newVoicemailCount
        },
    ]

    return (
        <div className='recent-calls'>
            <ContactNamesProvider>
                <MenuHeader
                    title={
                        <StyledPageTabs
                            options={tabs}
                            selected={page}
                            onChange={(val) => setPage(val as PhonePageType)}
                        />
                    }
                    input={
                        ['history', 'missed'].includes(page) ? (
                            <TextInput
                                name='Calls Search'
                                placeholder={t("phrases.search_recent_calls")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                isSearch
                                isSearching={searchTerm !== searchQueryDebounced}
                            />
                        ) : undefined
                    }
                    filter={
                        <Filters
                            filter={filter}
                            filters={[filters]}
                            onChange={(f) => {
                                dispatch(
                                    setPageFilter({
                                        page: 'callHistory',
                                        newFilters: filter.includes(f) ? [] : [f],
                                    })
                                )
                            }}
                            label='Recent Calls Filter'
                        />
                    }
                    selectedFilters={filter}
                />
                <Suspense fallback={fallback || <LoadingSpinner color='mamba'/>}>
                    {
                        pageComponent
                    }
                </Suspense>
            </ContactNamesProvider>
        </div>
    );
};
