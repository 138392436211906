/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { IYeOldeCall } from '../../../../types';
import CallHistoryRowDisplay from './CallHistoryRowDisplay';
import { useCallHistoryBase } from './hooks';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import {
    addOneToast,
    findContactOnBook,
    isValidContactEntity,
    selectAuthUserByUuid,
    selectCurrentUserId,
    selectPhonebookEntityInArr,
    selectUserCountryCode,
    setEditPhonebookContact,
    setViewContact,
    startCall,
    updateCallHistoryItem
} from '../../../../redux/slices';
import LoadingSpinner from '../../../../components/loading/LoadingSpinner';
import { useGetCallResolutionQuery } from '../../../../redux/services/sipApi';
import AriaButton from '../../../../components/AriaComponents/AriaButton';
import { usePolyglot } from '../../../../context/Polyglot';
import Icon from '../../../../components/Icon';
import { defaultContact, getNumbersArr } from './ExternalCallHistoryRow';
import EntityIcon from '../../../../components/icons/EntityIcon';

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const ElsewhereCallHistoryRow: React.FC<CallHistoryRowProps> = ({ call }) => {
    const { callee, callIcon, date, displayNumber } = useCallHistoryBase(call);

    const [contactLoading, setContactLoading] = useState(false);

    const { t } = usePolyglot();
    const dispatch = useTypedDispatch();

    const countryCode = useTypedSelector(selectUserCountryCode);
    const contactEntity = useTypedSelector(state =>
        selectPhonebookEntityInArr(state, getNumbersArr(callee, countryCode))
    );
    const userUuid = useTypedSelector(selectCurrentUserId);

    const { status, data } = useGetCallResolutionQuery(
        { call_uuid: call.getCdr || '' },
        { skip: !call.getCdr }
    );

    const user = useTypedSelector(state =>
        selectAuthUserByUuid(
            state,
            data?.result?.answered_type === 'sipuser' ? data.result.answered_uuid : undefined
        )
    );

    useEffect(() => {
        if (!data?.result?.answered_uuid) {
            return;
        }

        if (data.result.answered_uuid === userUuid) {
            dispatch(
                updateCallHistoryItem({
                    uuid: call.uuid,
                    answered: true,
                    getCdr: undefined,
                    call_duration: -1
                })
            );
        }
    }, [data?.result?.answered_uuid]);

    const getAnswerBy = () => {
        if (user) {
            return (
                <>
                    Answered by
                    <EntityIcon
                        type='user'
                        details={{
                            sip_name: user.name,
                            display_name: user.nickname,
                            avatar_key: user.avatar_key,
                            uuid: user.uuid
                        }}
                        size={16}
                    />
                    <span>{user.nickname}</span>
                </>
            );
        }

        return `Answered by ${data?.result?.answered_type || 'Unknown'}`;
    };

    const openContact = () => {
        if (!contactEntity) {
            setContactLoading(false);
            return;
        }

        const phonebookUuid =
            contactEntity.phonebookUuid || contactEntity.details[0]?.phonebook_uuid;
        const contactUuid = contactEntity.uuid || contactEntity.details[0]?.uuid;

        if (!phonebookUuid || !contactUuid) return;

        dispatch(
            findContactOnBook({
                phonebookUuid,
                contactUuid
            })
        )
            .then(r => {
                if (!r) {
                    dispatch(
                        addOneToast({
                            content: `Failed to find ${
                                contactEntity.name || 'contact'
                            } in contacts`,
                            title: 'Failed to find contact',
                            type: 'error'
                        })
                    );
                }
                dispatch(setViewContact(r));
            })
            .finally(() => {
                setContactLoading(false);
            });
    };

    return (
        <CallHistoryRowDisplay
            answered
            callIcon={callIcon}
            rowName={contactEntity?.name || callee}
            secondaryString={contactEntity?.name ? callee : undefined}
            subHeader={status !== 'fulfilled' ? <LoadingSpinner color='mamba' /> : getAnswerBy()}
            date={date}
            duration='Answered'
            elsewhere
            options={
                <>
                    <AriaButton
                        onClick={() => {
                            if (isValidContactEntity(contactEntity)) {
                                setContactLoading(true);
                                openContact();
                                return;
                            }
                            dispatch(
                                setEditPhonebookContact(
                                    defaultContact(t('phrases.label_main'), callee)
                                )
                            );
                        }}
                        title={
                            isValidContactEntity(contactEntity)
                                ? t('actions.view_contact')
                                : t('actions.add_contact', 1)
                        }
                        disabled={contactLoading}
                    >
                        {contactLoading ? <LoadingSpinner /> : null}
                        {!contactLoading && isValidContactEntity(contactEntity) ? (
                            <Icon name='accountFilled' width={22} height={22} />
                        ) : null}
                        {!contactLoading && !isValidContactEntity(contactEntity) ? (
                            <Icon name='addContact' width={22} height={22} />
                        ) : null}
                    </AriaButton>
                    <AriaButton
                        onClick={() => {
                            dispatch(startCall(displayNumber || callee, contactEntity?.name));
                        }}
                    >
                        <Icon name='phoneCall' width={22} height={22} />
                    </AriaButton>
                </>
            }
        />
    );
};

export default ElsewhereCallHistoryRow;
