import React, {useState} from 'react';
import {GropDisplayUser} from "../index";
import {getDisplayColor} from "../../../../helpers";
import styles from '../styles.module.scss';
import { useAppContext } from '../../../../context/AppContext/AppContext';

interface Props {
    display_users: GropDisplayUser[]
}

const Segment: React.FC<{ item: GropDisplayUser }> = ({item}) => {
    const [imageError, setImageError] = useState<boolean>(false)

    const {darkMode} = useAppContext();

    const initials: string = item.nickname.match(/\b\w/g)
        ?.join('')
        .substring(0, 2) || "";

    return (
        <div className={getDisplayColor(item.uuid, true, darkMode)}>
            {item.avatar_key && !imageError ? (
                <img
                    src={`https://storage.googleapis.com/v-profiles/${item.avatar_key}`}
                    alt={item.nickname}
                    onError={() => setImageError(true)}
                />
            ) : (
                <span>{initials}</span>
            )}
        </div>
    )
}

const MultiImageIcon: React.FC<Props> = ({display_users}) => {
    const users_to_display: GropDisplayUser[] = [];

    if (display_users.length === 1) {
        users_to_display.push(display_users[0])
        users_to_display.push(display_users[0])
    } else if (display_users.length > 1) {
        users_to_display.push(display_users[0])
        users_to_display.push(display_users[1])
    } else {
        const blank: GropDisplayUser = {
            nickname: "",
            uuid: "",
        }
        users_to_display.push(blank)
        users_to_display.push(blank)
    }

    return (
        <div
            className={styles.group_icon}
        >
            {users_to_display.map((u, i) => (
                <Segment
                    key={i}
                    item={u}
                />
            ))}
        </div>
    );
};

export default MultiImageIcon;
