import {
    ChatType, ChunkType,
    IChat,
    IChatMessage, IChatMessageReceipt,
    MessageSliceChat,
} from "../../../types";
import {AppDispatch, AppThunk} from "../../store";
import {selectActivePage} from "../appSlice";
import {selectCurrentUserId} from "../userSlice";
import {
    chatMessageAdded,
    chatSlice,
    selectActiveChatUuid,
    selectChatById, setActiveChunk,
    startNewIntegrationChat,
    startNewThread, startNewUserChat, updateOneChat,
} from "../chatSlice";
import {messageSlice} from "./slice";
import {
    selectAllMessagesInChunk,
    selectGlobalMessageById, selectGlobalMessageLocation,
} from "./selectors";

export const addOneMessage =
    (message: IChatMessage): AppThunk =>
        (dispatch: AppDispatch, getState) => {
            if (!message.to) return;

            const state = getState();

            const CURRENT_USER_UUID = selectCurrentUserId(state);
            const ACTIVE_CHAT = selectActiveChatUuid(state);
            const ACTIVE_PAGE = selectActivePage(state);

            let chatToUpdate: IChat | undefined;
            let location: {chat_uuid: string, chunk: ChunkType, message: IChatMessage} | undefined

            switch (message.to_type) {
                case 'thread':
                    chatToUpdate = selectChatById(state, message.to);
                    location = selectGlobalMessageLocation(state, message.to)

                    if (location) {
                        const newMembers: string[] = [...(location.message.thread?.members || [])]

                        if (!newMembers.includes(message.from)) newMembers.push(message.from);

                        dispatch(updateOneMessage({
                            message_uuid: location.message.uuid,
                            chat_uuid: location.chat_uuid,
                            changes: {
                                thread: {
                                    count: (location.message.thread?.count || 0) + 1,
                                    members: newMembers
                                }
                            }
                        }))
                    }

                    // if thread exists update the thread
                    if (chatToUpdate) {
                        // Step 1 - add the new message
                        dispatch(messageSlice.actions.addOneMessage({
                            chat_uuid: chatToUpdate.uuid,
                            message
                        }))

                        dispatch(chatMessageAdded({
                            chat_uuid: chatToUpdate.uuid,
                            message_uuid: message.uuid,
                            message_time: message.time
                        }))
                        if (message.from === CURRENT_USER_UUID) break;

                        // Step 2 - if chat is not active and message NOT from current user -> update unread count
                        if (ACTIVE_CHAT !== message.to || ACTIVE_PAGE !== 'chat') {
                            dispatch(chatSlice.actions.updateOneChat({
                                chat_uuid: chatToUpdate.uuid,
                                changes: {
                                    unread_count: chatToUpdate.unread_count < 0 ? 1 : chatToUpdate.unread_count + 1,
                                    new_messages_count: chatToUpdate.new_messages_count + 1,
                                }
                            }))

                        } else if (chatToUpdate.new_messages_count > 0) {
                            dispatch(chatSlice.actions.updateOneChat({
                                chat_uuid: chatToUpdate.uuid,
                                changes: {
                                    new_messages_count: chatToUpdate.new_messages_count + 1,
                                }
                            }))
                        }

                    } else {
                        // otherwise start new thread - write when this runs
                        const threadMessage = selectGlobalMessageById(state, message.to)

                        dispatch(startNewThread({
                            display_name: message.content,
                            uuid: message.to,
                            messages: [message],
                            thread_message: threadMessage,
                            unread_count: message.from !== CURRENT_USER_UUID ? 1 : 0,
                        }))
                    }

                    // always update parent chat - regardless whether this is a new thread or not
                    // dispatch(updateParentOfThread({message_uuid: message.to}))

                    break

                case 'channel':
                    chatToUpdate = selectChatById(state, message.to);

                    if (!chatToUpdate) return

                    dispatch(messageSlice.actions.addOneMessage({
                        chat_uuid: chatToUpdate.uuid,
                        message
                    }))

                    dispatch(chatMessageAdded({
                        chat_uuid: chatToUpdate.uuid,
                        message_uuid: message.uuid,
                        message_time: message.time
                    }))
                    if (message.from === CURRENT_USER_UUID) break;

                    if (ACTIVE_CHAT !== message.to || ACTIVE_PAGE !== 'chat') {
                        dispatch(updateOneChat({
                            chat_uuid: chatToUpdate.uuid,
                            changes: {
                                unread_count: chatToUpdate.unread_count < 0 ? 1 : chatToUpdate.unread_count + 1,
                                new_messages_count: chatToUpdate.unread_count + 1,
                            }
                        }))

                    } else if (chatToUpdate.new_messages_count > 0) {
                        dispatch(chatSlice.actions.updateOneChat({
                            chat_uuid: chatToUpdate.uuid,
                            changes: {
                                new_messages_count: chatToUpdate.new_messages_count + 1,
                            }
                        }))
                    }

                    break

                case 'integration':
                case 'user':
                    // depending on what chat this is coming form execute different logic
                    if (message.from_type === 'integration') {
                        chatToUpdate = selectChatById(state, message.from);
                    } else {
                        chatToUpdate = selectChatById(state, message.to === CURRENT_USER_UUID ? message.from : message.to);
                    }

                    // if chat already exists -> add one message and update unread message count
                    if (chatToUpdate) {
                        dispatch(messageSlice.actions.addOneMessage({
                            chat_uuid: chatToUpdate.uuid,
                            message
                        }))

                        dispatch(chatMessageAdded({
                            chat_uuid: chatToUpdate.uuid,
                            message_uuid: message.uuid,
                            message_time: message.time
                        }))
                        if (message.from === CURRENT_USER_UUID) break;

                        if (ACTIVE_CHAT !== message.from || ACTIVE_PAGE !== 'chat' || !document.hasFocus()) {
                            dispatch(updateOneChat({
                                chat_uuid: chatToUpdate.uuid,
                                changes: {
                                    unread_count: chatToUpdate.unread_count < 0 ? 1 : chatToUpdate.unread_count + 1,
                                    new_messages_count: chatToUpdate.unread_count + 1,
                                }
                            }))

                        } else if (chatToUpdate.new_messages_count > 0) {
                            dispatch(chatSlice.actions.updateOneChat({
                                chat_uuid: chatToUpdate.uuid,
                                changes: {
                                    new_messages_count: chatToUpdate.new_messages_count + 1,
                                }
                            }))
                        }

                        // if chat is not existing and message came from integration created new integration chat
                        // else create new user chat
                    } else if (message.from_type === 'integration') {
                        dispatch(startNewIntegrationChat({message}))
                    } else {
                        dispatch(startNewUserChat({message}))
                    }

                    break
            }

            if (!chatToUpdate) return;

            if (message.from === CURRENT_USER_UUID && chatToUpdate?.new_messages_count > 0) {
                dispatch(chatSlice.actions.updateOneChat({
                    chat_uuid: chatToUpdate.uuid,
                    changes: {
                        new_messages_count: 0,
                        unread_count: 0,
                    }
                }))
            }
            // else if (message.from !== CURRENT_USER_UUID) {
            //     dispatch(addPopupNotification(message));
            // }
        }

        /*

export const addFailedMessageToQueue =
    ({chat_uuid, message}: { chat_uuid: string | undefined, message: IChatMessage }): AppThunk =>
        async (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(chatSlice.actions.addFailedMessageToQueue({chat_uuid, message}))
        }

export const clearFailedMessageFromQueue =
    ({chat_uuid, message_uuid}: { chat_uuid: string | undefined, message_uuid: string }): AppThunk =>
        async (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(chatSlice.actions.clearFailedMessageFromQueue({chat_uuid, message_uuid}));
        }

export const setSearchResult =
    (payload: { messages: IChatMessage[], chat_key: string }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setSearchResult(payload));


export const addExternalReply =
    (data: IChatMessage): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.addExternalReply(data));

         */

export const addManyChatToMessage =
    (data: Record<string, MessageSliceChat>): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(messageSlice.actions.addManyChatToMessage(data));

export const addOneChatToMessage =
    (data: { chat_uuid: string, value: MessageSliceChat}): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(messageSlice.actions.addOneChatToMessage(data));

export const addOneMessageToChunk =
    (data: { chat_uuid: string; message: IChatMessage, chunk: ChunkType }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(messageSlice.actions.addOneMessageToChunk(data));

export const addManyMessages =
    (data: { chat_uuid: string; messages: IChatMessage[], chunk: ChunkType }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(messageSlice.actions.addManyMessage(data));


export const updateOneMessage =
    ({
         message_uuid,
         chat_uuid,
         changes
     }: { message_uuid: string, chat_uuid: string | undefined, changes: Partial<IChatMessage> }): AppThunk =>
        async (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(messageSlice.actions.updateOneMessage({
                message_uuid,
                chat_uuid,
                changes
            }))
        }

export const deleteOneMessage =
    ({
         message_uuid,
         chat_uuid,
         chat_type
     }: { message_uuid: string, chat_uuid: string | undefined, chat_type: ChatType }): AppThunk =>
        (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(messageSlice.actions.deleteOneMessage({
                chat_uuid,
                message_uuid
            }));

            // update parent message unread count
            if (chat_uuid && chat_type === 'thread') {
                // dispatch(updateParentOfThread({
                //     message_uuid: chat_uuid,
                //     is_delete: true
                // }))
            }
        }


export const mergeChunks =
    ({chat_uuid}: { chat_uuid: string }): AppThunk =>
        (dispatch: AppDispatch, getState) => {
            const searchChunkMessages = selectAllMessagesInChunk(getState(), chat_uuid, 'search') || [];
            dispatch(setActiveChunk({chat_uuid, active_chunk: 'initial'}))

            dispatch(messageSlice.actions.mergeChunks({
                chat_uuid,
                messages: searchChunkMessages
            }))
        }

export const setAllMessagesAsRead =
    ({chat_uuid, receipt}: { chat_uuid: string, receipt: IChatMessageReceipt }): AppThunk =>
        (dispatch: AppDispatch, getState) => {
            const initialChunkMessages = selectAllMessagesInChunk(getState(), chat_uuid, 'initial')
            const searchChunkMessages = selectAllMessagesInChunk(getState(), chat_uuid, 'search')

            if (!initialChunkMessages) return;

            const updateChunk = (originalArr: IChatMessage[], chunk: ChunkType) => {
                const receipts: { id: string; changes: { read: IChatMessageReceipt[]; }; }[] = []

                // if receipt is not there add read status
                for (let i = 0; i < originalArr.length; i += 1) {
                    if (!originalArr[i].read?.find(status => status.user === receipt.user)) {
                        receipts.push({
                            id: originalArr[i].uuid,
                            changes: {
                                read: [...(originalArr[i].read || []), receipt]
                            }
                        })
                    }
                }

                dispatch(messageSlice.actions.updateManyMessages({
                    chat_uuid,
                    chunk,
                    changes: receipts
                }))
            }

            updateChunk(initialChunkMessages, 'initial')

            if (searchChunkMessages) {
                updateChunk(searchChunkMessages, "search")
            }
        }