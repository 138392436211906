import React, {ReactNode, useEffect, useRef} from "react";
import Draggable, {DraggableData} from "react-draggable";
import {SessionState} from "sip.js";
import {useFocusWithin} from "react-aria";
import styles from "./Keypad.module.scss";
import Icon from "../Icon";
import {usePolyglot} from "../../context/Polyglot";
import {useTypedSelector} from "../../redux/hooks";
import {selectAllCalls, selectDragAndDropData} from "../../redux/slices";
import {useKeypadContext} from "../../context/KeypadContext/context";

interface Props {
    children: ReactNode;
    containerRef: HTMLDivElement | null;
    handleKeypadToggle: (val: boolean) => void;
}

type PulseType = 'outbound' | 'initial' | 'active' | undefined;

export const KeypadDraggableRoot: React.FC<Props> = ({children, containerRef, handleKeypadToggle}) => {
    const {keypadState, useKeypadAction} = useKeypadContext()

    const calls = useTypedSelector(selectAllCalls);
    const dragAndDropProps = useTypedSelector(selectDragAndDropData);

    const { focusWithinProps } = useFocusWithin({
        onFocusWithin: () => {
            if (mouseDown.current) return;
            handleKeypadToggle(true)
        },
    });

    const mouseDown = useRef<boolean>(false);

    const {t} = usePolyglot();

    const dragTimer = useRef<any>(null);
    const keypad_containerRef = useRef<HTMLDivElement>(null);
    const keypadPos = useRef<'left' | 'right'>('left');
    const positionRef = useRef<{ x: number, y: number }>({x: 0, y: 0,});
    const startPos = useRef<{ x: number, y: number }>({x: 0, y: 0});
    const nodeRef = useRef(null);

    let pulseType: PulseType;

    const handlePosition = (data: DraggableData) => {
        // small timer so the keypad doesn't judder when moved quickly
        positionRef.current = {x: data.x, y: data.y}
        if (!dragTimer.current) {
            dragTimer.current = setTimeout(() => {
                changePosition()
                dragTimer.current = null;
            }, 300)
        }
    }

    // checks to see if keypad was dragged, so we can set it not to open when dragged
    const checkIfWasDragged = (moment: 'start' | 'stop', data: DraggableData) => {
        if (moment === 'start') {
            useKeypadAction({
                type: 'SetWasDragged',
                payload: false,
            })
            startPos.current = {
                x: data.x,
                y: data.y,
            }
        } else if (moment === 'stop') {

            if (data.x !== startPos.current.x || data.y !== startPos.current.y) {
                useKeypadAction({
                    type: 'SetWasDragged',
                    payload: true,
                })
            }
        }
    }

    useEffect(() => {
        changePosition();
    }, [calls.length]);

    useEffect(() => {
        if (keypadState.keypadActiveStatus) {
            changePosition();
            mouseDown.current = false
        }
    }, [keypadState.keypadActiveStatus]);

    const changePosition = () => {
        if (containerRef && keypad_containerRef.current) {
            const keypadTotalHeight = (calls.length > 1 ? 475 : 430);

            keypad_containerRef.current.style.height = `${keypadTotalHeight}px`;

            const overlapAmount: number = (-positionRef.current.y - (containerRef.scrollHeight - keypadTotalHeight))

            /**
             * if the keypad is positioned high enough for the keypad to overlap the top, bring down into view
             */
            if (-positionRef.current.y > containerRef.scrollHeight - keypadTotalHeight) {
                keypad_containerRef.current.style.top = `${overlapAmount}px`;
                keypad_containerRef.current.style.transform = `translateY(${overlapAmount}) scale(${keypadState.keypadActiveStatus ? 1 : 0})`
            } else {
                keypad_containerRef.current.style.top = '0px';
            }

            if (positionRef.current.x > (containerRef.scrollWidth / 2)) {
                keypadPos.current = 'right'
            } else {
                keypadPos.current = 'left'
            }

            keypad_containerRef.current.style.transformOrigin = `${keypadPos.current} ${Math.min((keypadTotalHeight - overlapAmount), keypadTotalHeight)}px`;
        }
    };


    switch (true) {
        case calls.some(c => c.state === SessionState.Initial && c.isOutbound):
            pulseType = 'outbound'
            break;
        case calls.some(c => c.state === SessionState.Initial):
            pulseType = 'initial'
            break;
        case calls[calls.length - 1]?.state === SessionState.Established:
            pulseType = 'active'
            break;
    }

    return (
        <Draggable
            onDrag={(_, data) => handlePosition(data)}
            bounds='parent'
            onStart={(_, data) => checkIfWasDragged('start', data)}
            onStop={(_, data) => checkIfWasDragged('stop', data)}
            handle=".keypad-draggable-handle"
            nodeRef={nodeRef}
        >
            <div className={styles.draggableComponent} ref={nodeRef}>
                <div
                    className={styles.keypad_section}
                    data-widget-active={(keypadState.widgetActive && !dragAndDropProps) || null}
                    data-multiple-calls={calls.length > 1 || null}
                    data-open={keypadState.keypadActiveStatus || null}
                    data-pos={keypadPos.current}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...focusWithinProps}
                >
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            handleKeypadToggle(!keypadState.keypadActiveStatus)
                            mouseDown.current = false
                        }}
                        className={[styles.keypad_open_button, 'keypad-draggable-handle'].join(' ')}
                        data-pulse-type={pulseType}
                        title={keypadState.keypadActiveStatus ? t("phrases.close_keypad") : t("phrases.open_keypad")}
                        onMouseDown={() => {
                            mouseDown.current = true
                        }}
                    >
                        <p style={{opacity: 0, fontSize: 10, position: "absolute"}}>Keypad Root</p>
                        <div>
                            <Icon name='keypad'/>
                        </div>
                    </button>
                    <div
                        className={styles.move_handler}
                        ref={keypad_containerRef}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </Draggable>
    )
}

export default KeypadDraggableRoot
