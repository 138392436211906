import React, {useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {changeCallerId, selectCurrentCallerId} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";
import CallerIDDisplay from "../callerIDDisplay/CallerIDDisplay";
import StyledDropdown from "../StyledComponents/StyledDropdown";
import {ITelecommunicationsIdentificationDisplay} from "../../types";

interface Props {
}

export const CallerIDs: React.FC<Props> = () => {
    const [isLoadingCID, setIsLoadingCID] = useState<boolean>(false);

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch()

    const callerIds: ITelecommunicationsIdentificationDisplay[] = useTypedSelector(state => state.sip.callerIds);
    const userUuid = useTypedSelector(state => state.user.uuid);

    const userCallerID = useTypedSelector(selectCurrentCallerId) || t("adjective.caller_id_anonymous");

    const callerIDOptions = callerIds.map(cid => ({
        label: (<>
            <p>
                {cid.cli_name || cid.cli_display}
            </p>
            {(cid.cli_name && cid.cli_name !== cid.cli_display) ? (
                <p>
                    {cid.cli_display}
                </p>
            ) : null}
        </>),
        value: cid.uuid,
        large: !!(cid.cli_name && cid.cli_name !== cid.cli_display),
    }))

    callerIDOptions.unshift({
        label: t("phrases.caller_id_hidden_anonymous"),
        value: '',
        large: false,
    })

    return (
        <StyledDropdown
            options={callerIDOptions}
            onSelect={(val) => {
                setIsLoadingCID(true)
                dispatch(
                    changeCallerId({
                        userUuid,
                        callerIdUuid: val || undefined
                    })
                ).then(() => setIsLoadingCID(false))
            }}
            selected={userCallerID}
            display={<CallerIDDisplay/>}
            loading={isLoadingCID}
        />
    )
}

export default CallerIDs;
