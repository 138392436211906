
export interface AuxCodeContextState {
    code_to_change_to?: string;
}

export const initialState: AuxCodeContextState = {
}

export type AuxCodeReducerAction =
    {
        type: 'set_change_after_call_code',
        payload?: string
    }


export const AuxCodeReducer = (state: AuxCodeContextState, action: AuxCodeReducerAction): AuxCodeContextState => {

    switch (action.type) {
        case "set_change_after_call_code":
            return {
                ...state,
                code_to_change_to: action.payload
            }
        default:
            return state
    }
}
