import styles from './skeleton-style.module.scss';

interface Props {
    name?: string;
    style?: React.CSSProperties;
    className?: string;
}

const StyledSkeleton: React.FC<Props> = ({ style, name, className }) => (
    <div className={[styles.skeleton, className].join(' ')} style={style} data-comp-name={name} />
);

export default StyledSkeleton;
