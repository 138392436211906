import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AppFilterPage,
    IAppSettings,
    IChatSettings,
    IAppMetaSettings,
    ILoggedInUser,
    IPhoneSettings, SmartDiallerSelected, SmartDiallerNumber
} from "../../../types";
import {authApi} from "../../services/authApi";
import {
    changeCallerId,
    getUserState,
    putCallHandling,
} from "./thunks";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const initialState: ILoggedInUser = {
    settings: {
        chat: {
            settings: {
                disableChatNotifications: false,
                chatNotificationVolume: 100,
                isLargeFont: true,
                defaultChatView: 'none',
                lastOpenChatId: '',
                disableEnter: false,
                style: 'default'
            }
        },
        phone: {
            settings: {
                allowInternalCalls: false,
                autoAnswerClickToDial: false,
                bringAppToFrontOff: false,
                callVolumeMute: false,
                callVolumeValue: 100,
                callWaitingBeepOff: false,
                contactsSortByLastName: false,
                defaultToGridView: false,
                disableHoldToParkCall: false,
                disableShortCodesLongPress: false,
                dnd: false,
                inputDeviceId: 'default',
                missedCallNotifications: false,
                outputDeviceIdCall: 'default',
                outputDeviceIdRingtone: 'default',
                ringtoneVolumeMute: false,
                ringtoneVolumeValue: 100,
                showCallRouteName: false,
                showPhonebookContactCompanyName: false,
                videoDeviceId: 'default',
                showCallHandling: false,
                popParkedCallsTab: true,
                popCrm: 'never',
                disableHuntGroupDisplay: false,
                phonebooksInContacts: false,
                crmIntegrationUuids: [],
                keepActiveCall: false,
                popWhenAnswered: false,
                useGlobalDnd: true,
                popOnOutbound: false,
            }
        },
        appMeta: {
            filters: {
                chatList: [],
                callHistory: [],
                contactList: [],
            },
            settings: {
                asideWidth: 420
            },
            smartDiallerNumbers: [],
        }
    }
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateAvatarKey: (state, {payload}: PayloadAction<string>) => ({
            ...state,
            avatar_key: payload
        }),
        updateDevices: (state, {payload}: PayloadAction<any[]>) => ({
            ...state,
            devices: payload
        }),
        updateAppSettings: (state, {payload}: PayloadAction<IAppSettings>) => {
            localStorage[state.uuid] = payload;
        },
        setMessageCallerId: (state, {payload}: PayloadAction<string | undefined>) => ({
            ...state,
            message_caller_id: payload
        }),
        updatePhoneSettings: (
            state,
            {
                payload
            }: PayloadAction<{
                setting: keyof IPhoneSettings;
                value: string | number | boolean | string[];
            }>
        ) => {
            const phone = {
                ...state.settings.phone,
                settings: {
                    ...(state.settings.phone.settings as IPhoneSettings),
                    [payload.setting]: payload.value
                }
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                phone
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    phone
                }
            };
        },
        setPageFilter: (
            state,
            {payload}: PayloadAction<{ page: AppFilterPage, newFilters: string[] }>
        ) => {
            const appMeta = {
                ...state.settings.appMeta,
                filters: {
                    ...state.settings.appMeta.filters,
                    [payload.page]: payload.newFilters
                }
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                appMeta
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    appMeta
                }
            };
        },
        setSmartDiallerItem: (
            state,
            {payload}: PayloadAction<SmartDiallerSelected | undefined>
        ) => {
            const appMeta = {
                ...state.settings.appMeta,
                smartDiallerSelection: payload,
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                appMeta
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    appMeta
                }
            };
        },
        updateAppMetaSettings: (
            state,
            {
                payload
            }: PayloadAction<{
                setting: string;
                value: string | number | boolean;
            }>
        ) => {
            const appMeta = {
                ...state.settings.appMeta,
                settings: {
                    ...(state.settings.appMeta.settings as IAppMetaSettings),
                    [payload.setting]: payload.value
                }
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                appMeta
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    appMeta
                }
            };
        },
        updateChatSettings: (
            state,
            {
                payload
            }: PayloadAction<{
                setting: string;
                value: string | number | boolean;
            }>
        ) => {
            const chat = {
                ...state.settings.chat,
                settings: {
                    ...(state.settings.chat.settings as IChatSettings),
                    [payload.setting]: payload.value
                }
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                chat
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    chat
                }
            };
        },
        addSmartDiallerNumber: (
            state,
            {payload}: PayloadAction<SmartDiallerNumber>
        ) => {
            const appMeta = {
                ...state.settings.appMeta,
                smartDiallerNumbers: [...state.settings.appMeta.smartDiallerNumbers || [], payload]
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                appMeta
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    appMeta
                }
            };
        },
        removeSmartDiallerNumber: (
            state,
            {payload}: PayloadAction<string>
        ) => {
            const appMeta = {
                ...state.settings.appMeta,
                smartDiallerNumbers: (state.settings.appMeta.smartDiallerNumbers || []).filter(num => num.uuid !== payload)
            };

            localStorage[state.uuid] = JSON.stringify({
                ...state.settings,
                appMeta
            } as IAppSettings);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    appMeta
                }
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(changeCallerId.fulfilled, (state, {payload}) => ({
            ...state,
            caller_id: payload.callerIdUuid
        }));
        builder.addCase(putCallHandling.fulfilled, (state, {payload}) => ({
            ...state,
            ...payload
        }));
        builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (_, {payload}) =>
            getUserState(payload.user, payload.devices)
        );
    }
});

export const user = userSlice.reducer;
