import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import { usePolyglot } from '../../context/Polyglot';
import styles from '../keypad/Keypad.module.scss';
import AriaButton from '../AriaComponents/AriaButton';
import { useTypedSelector } from '../../redux/hooks';
import { selectHasDevices, selectSmartDiallerSelected } from '../../redux/slices';
import LoadingSpinner from '../loading/LoadingSpinner';
import KeypadIcon from '../Icon/KeypadIcons';

interface AnswerProps {
    onClick: () => void;
    holdAndAnswer?: boolean;
    showText?: boolean;
    title?: string;
    loading?: boolean;
}

export const Answer: React.FC<AnswerProps> = ({
    onClick,
    holdAndAnswer,
    showText,
    title,
    loading
}) => {
    const [clicked, setClicked] = useState<boolean>(false);

    const smartDiallerSelected = useTypedSelector(selectSmartDiallerSelected);
    const hasDevices = useTypedSelector(selectHasDevices);

    const disableFDevice = !hasDevices && !smartDiallerSelected;

    const { t } = usePolyglot();

    const clickHandler = () => {
        onClick();

        if (!clicked) {
            setClicked(true);
        }
    };

    useEffect(() => {
        let timeout;
        if (clicked) {
            timeout = setTimeout(() => {
                setClicked(false);
            }, 500);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [clicked]);

    return (
        <AriaButton
            className={styles.answerButton}
            onClick={() => clickHandler()}
            disabled={clicked || disableFDevice || loading}
            title={
                disableFDevice
                    ? 'Select A Smart Dialler Device or allow audio devices to allow Calls'
                    : title
            }
            dataTags={{
                'data-disable-f-d': disableFDevice ? 'true' : null
            }}
        >
            {loading ? <LoadingSpinner color='mamba' /> : null}
            {!loading && holdAndAnswer ? (
                <>
                    <KeypadIcon name='callHAA' def />
                    <p>{t('actions.hold_and_accept')}</p>
                </>
            ) : null}
            {!loading && !holdAndAnswer ? (
                <>
                    <Icon name='phoneAnswer' width={45} height={45} def />
                    {showText ? <p>{t('actions.accept')}</p> : null}
                </>
            ) : null}
        </AriaButton>
    );
};
