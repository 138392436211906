import moment from 'moment';
import { api } from '.';
import {
    addInterTenantClient,
    addManyPhonebookEntity,
    addManyVoicemail,
    addUsersToTenant,
    addVoicemailBoxes,
    refreshHardwareList,
    removeOneVoicemail,
    selectCurrentUserId,
    selectCurrentUserSipName,
    selectHardwareRegistrations,
    selectUserStatus,
    setFeedbackTags,
    setMessageCallerId,
    setVoicemailGotten,
    setVoicemailSeen,
    updateCallRecording,
    updateUserStatus
} from '../slices';
import {
    AuxCode,
    CallFeedbackTag,
    HardwarePhone,
    IMailboxMessage,
    InterTenantUser,
    InterTenantUserValidator,
    InterTenantValidator,
    IPhonebookContact,
    IUser,
    MailboxDisplay,
    PhonebookContactEntity,
    SetAuxCodeResponse,
    SmartDiallerApp,
    SmartDiallerRegistration
} from '../../types';
import { isPreview } from '../../helpers';

export interface RoomInvite {
    auth_token: string;
    call_id: string;
    from_user: string;
    room: string;
    id: string;
}

export const ConfServerOptions = ['one', 'two', 'talk'] as const;

const appContactRegex = /(transport=ws)/i;

export type ConfigServerType = (typeof ConfServerOptions)[number];

export const getConferenceServerType = (): ConfigServerType => {
    const confSvr: string | null = localStorage.getItem('conf_svr');

    if (confSvr) {
        if (!isPreview) {
            localStorage.removeItem('conf_svr');
            return 'one';
        }

        if (ConfServerOptions.includes(confSvr as any)) return confSvr as any;

        localStorage.removeItem('conf_svr');
        return 'one';
    }
    return 'one';
};

export const getConferenceServer = (): string => `wss://${getConferenceServerType()}.yay.com`;

export const sipApi = api.injectEndpoints({
    endpoints: builder => ({
        /**
         * Query Functions
         */

        /**
         * GET Requests
         */
        getAvailableTags: builder.query<{ result: CallFeedbackTag[] }, null>({
            query: () => ({
                method: 'GET',
                url: '/api/get-available-call-tags'
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result: availableTags }
                    } = await queryFulfilled;

                    dispatch(setFeedbackTags(availableTags));
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getVoicemailList: builder.query<{ result: IMailboxMessage[] }, string>({
            query: mailbox_uuid => ({
                method: 'GET',
                url: '/api/get-mailbox-messages',
                params: {
                    mailbox_uuid
                }
            }),
            async onQueryStarted(mailbox_uuid, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result }
                    } = await queryFulfilled;
                    if (!result) return;

                    dispatch(addManyVoicemail(mailbox_uuid, result));
                    dispatch(setVoicemailGotten(mailbox_uuid));
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getVoicemailUrl: builder.query<{ result: any }, string>({
            query: message_uuid => ({
                method: 'GET',
                url: '/api/get-mailbox-message',
                params: {
                    message_uuid
                }
            })
        }),
        getContactNameByNumber: builder.query<
            PhonebookContactEntity[],
            {
                numbers: string[];
                phonebooks: string[];
            }
        >({
            query: ({ numbers, phonebooks }) => ({
                method: 'POST',
                url: '/api/get-contact-entities',
                data: {
                    phonebooks,
                    numbers
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (!data) return;

                    dispatch(addManyPhonebookEntity(data));
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getSmartDiallerDevices: builder.query<
            {
                registrations: SmartDiallerRegistration[];
                apps: SmartDiallerApp[];
            },
            string
        >({
            query: userUuid => ({
                method: 'GET',
                url: '/api/get-smart-dial-locations',
                params: {
                    voip_user_uuid: userUuid
                }
            }),
            transformResponse: (r: any) => r.result,
            async onQueryStarted(userUuid, { dispatch, getState, queryFulfilled }) {
                try {
                    const {
                        data: { registrations }
                    } = await queryFulfilled;

                    const state = getState();

                    const currentHardwareReg = selectHardwareRegistrations(state);

                    if (
                        registrations.some(r => {
                            if (!appContactRegex.test(r.contact)) return false;
                            return currentHardwareReg.some(c => (c.location_id === r.location_id));
                        })
                    ) {
                        dispatch(refreshHardwareList(userUuid));
                    }
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getUserHardware: builder.query<HardwarePhone[], string>({
            query: userUuid => ({
                method: 'GET',
                url: '/api/get-hardware-phones',
                params: {
                    voip_user_uuid: userUuid
                }
            }),
            transformResponse: response => (response as any)?.result || []
        }),
        getInterTenants: builder.query<
            {
                result: any[];
            },
            string
        >({
            query: (voip_user_id: string) => ({
                method: 'GET',
                url: '/api/get-bridged-accounts',
                params: { voip_user_id }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result: res }
                    } = await queryFulfilled;

                    res.forEach(client => {
                        try {
                            const valid = InterTenantValidator.parse(client);
                            dispatch(addInterTenantClient(valid));
                        } catch (e) {
                            //
                        }
                    });
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getInterTenantClientUsers: builder.query<
            {
                result: any[];
            },
            {
                tenant_uuid: string;
            }
        >({
            query: ({ tenant_uuid }) => ({
                method: 'GET',
                url: '/api/get-inter-tenant-users',
                params: {
                    tenant_uuid
                }
            }),
            async onQueryStarted({ tenant_uuid }, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result: res }
                    } = await queryFulfilled;

                    const users: InterTenantUser[] = [];

                    res.forEach(u => {
                        try {
                            const data = InterTenantUserValidator.parse(u);

                            users.push(data);
                        } catch (e) {
                            //     jkrsf
                        }
                    });

                    dispatch(addUsersToTenant(tenant_uuid, users));
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getVoicemailDetails: builder.query<
            {
                result: MailboxDisplay[];
            },
            {
                voip_user_uuid: string;
            }
        >({
            query: ({ voip_user_uuid }) => ({
                method: 'GET',
                url: '/api/get-mailbox-details',
                params: {
                    voip_user_uuid
                }
            }),
            async onQueryStarted(mailbox_uuid, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result }
                    } = await queryFulfilled;
                    if (!result) return;

                    dispatch(addVoicemailBoxes(result));
                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        // getCallDetails: builder.query<any,
        //     {
        //         call_uuid: string;
        //     }
        // >({
        //     query: ({ call_uuid }) => ({
        //         method: 'GET',
        //         url: '/api/get-call-single',
        //         params: {
        //             call_uuid
        //         }
        //     }),
        //     async onQueryStarted(cal_uuid, { dispatch, queryFulfilled }) {
        //         try {
        //
        //
        //             const {
        //                 // data: { result }
        //                 data
        //             } = await queryFulfilled;
        //             // if (!result) return;
        //
        //             console.log('dta', data);
        //
        //             // dispatch(addVoicemailBoxes(result));
        //         } catch {
        //             // do nothing handle catch errors - do I need to try and catch here or just check for results
        //         }
        //     }
        // }),
        getCallResolution: builder.query<
            any,
            {
                call_uuid: string;
            }
        >({
            query: ({ call_uuid }) => ({
                method: 'GET',
                url: '/api/get-call-resolution',
                params: {
                    call_uuid
                }
            })
        }),

        /**
         * POST Requests
         */
        postCallTags: builder.mutation<
            null,
            {
                call_uuid: string;
                data: string[];
            }
        >({
            query: ({ call_uuid, data }) => ({
                method: 'POST',
                url: '/api/add-call-tags',
                params: { call_uuid },
                data
            })
        }),
        searchContacts: builder.query<
            {
                result?: {
                    next_key: string;
                    count: number;
                    page: number;
                    contacts: IPhonebookContact[];
                };
            },
            { search_term: string; sort_by_last_name: boolean; phone_books: string[] }
        >({
            query: ({ search_term, sort_by_last_name, phone_books }) => ({
                method: 'POST',
                url: '/api/search-api-contacts',
                data: {
                    limit: 100,
                    pattern: search_term,
                    order_by_surname: sort_by_last_name,
                    phone_books
                }
            })
        }),

        /**
         * PUT Requests
         */
        putAvailableInCallQueues: builder.mutation<
            { result: { available: boolean } },
            {
                voip_user_uuid: string;
                available: boolean;
            }
        >({
            query: ({ voip_user_uuid, available }) => ({
                method: 'PUT',
                url: '/api/update-availability',
                params: { user_uuid: voip_user_uuid },
                data: { available }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                const {
                    data: { result }
                } = await queryFulfilled;

                if (result === undefined) {
                    return;
                }

                try {
                    const state = getState();

                    const currentUserSipName = selectCurrentUserSipName(state);
                    const status = selectUserStatus(state, currentUserSipName);

                    if (!status || !currentUserSipName) return;

                    dispatch(
                        updateUserStatus({
                            ...status,
                            queue_available: result.available
                        })
                    );
                } catch (e) {
                    //     Validation Failed
                }
            }
        }),
        putAuxCode: builder.mutation<
            any,
            { voip_user_uuid: string; auxCode: Pick<AuxCode, 'code' | 'set_by'> }
        >({
            query: ({ voip_user_uuid, auxCode }) => ({
                method: 'PUT',
                url: '/api/update-aux-code',
                params: { user_uuid: voip_user_uuid },
                data: {
                    ...auxCode,
                    time: moment().format('YYYY-MM-DDThh:mm:ss')
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                const {
                    data: { result }
                } = await queryFulfilled;

                if (result === undefined) {
                    return;
                }

                try {
                    const newAux = SetAuxCodeResponse.parse(result);
                    const state = getState();
                    const currentUserUuid = selectCurrentUserId(state);

                    if (newAux.user_uuid !== currentUserUuid) {
                        return;
                    }

                    const currentUserSipName = selectCurrentUserSipName(state);
                    const status = selectUserStatus(state, currentUserSipName);

                    if (newAux.code === status?.aux_code?.code) return;

                    if (!status || !currentUserSipName) return;

                    dispatch(
                        updateUserStatus({
                            ...status,
                            aux_code: {
                                code: newAux.code,
                                time: newAux.time
                            }
                        })
                    );
                } catch (e) {
                    //     Validation Failed
                }
            }
        }),
        putMessageId: builder.mutation<
            null,
            { voip_user_uuid: string; caller_uuid: string | undefined }
        >({
            query: ({ voip_user_uuid, caller_uuid }) => ({
                method: 'PUT',
                url: '/api/change-message-id',
                params: { user_uuid: voip_user_uuid },
                data: { message_id: { uuid: caller_uuid } }
            }),
            async onQueryStarted({ caller_uuid }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(setMessageCallerId(caller_uuid));
                } catch {
                    // do nothing
                }
            }
        }),
        putCallFeedback: builder.mutation<
            null,
            {
                call_uuid: string;
                data: { note?: string; rating_mood?: number };
            }
        >({
            query: ({ call_uuid, data }) => ({
                method: 'PUT',
                url: '/api/update-call-feedback',
                params: { call_uuid },
                data
            })
        }),
        putCallRecording: builder.mutation<
            { result: IUser },
            {
                user_uuid: string;
                recording: boolean;
            }
        >({
            query: ({ user_uuid, recording }) => ({
                method: 'PUT',
                url: '/api/change-recording',
                params: { user_uuid },
                data: {
                    call_recording: recording
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const {
                        data: { result }
                    } = await queryFulfilled;

                    dispatch(updateCallRecording(result.call_recording));
                } catch {
                    // do nothing
                }
            }
        }),
        postVoicemailSeen: builder.mutation<
            { result: any },
            {
                voicemail_uuid: string;
                mailbox_uuid: string;
            }
        >({
            query: ({ voicemail_uuid, mailbox_uuid }) => ({
                method: 'POST',
                url: '/api/post-voicemail-seen',
                params: {
                    messageUuid: voicemail_uuid,
                    mailboxUuid: mailbox_uuid
                }
            }),
            async onQueryStarted({ voicemail_uuid, mailbox_uuid }, { dispatch }) {
                try {
                    dispatch(setVoicemailSeen(mailbox_uuid, voicemail_uuid));
                } catch {
                    // do nothing
                }
            }
        }),

        /**
         * DELETE Requests
         */
        deleteSingleVoicemail: builder.mutation<
            { result: any },
            {
                voicemail_uuid: string;
                mailbox_uuid: string;
            }
        >({
            query: ({ voicemail_uuid, mailbox_uuid }) => ({
                method: 'DELETE',
                url: '/api/delete-single-voicemail',
                params: {
                    messageUuid: voicemail_uuid,
                    mailboxUuid: mailbox_uuid
                }
            }),
            async onQueryStarted({ voicemail_uuid, mailbox_uuid }, { dispatch }) {
                try {
                    dispatch(removeOneVoicemail(mailbox_uuid, voicemail_uuid));
                } catch {
                    // do nothing
                }
            }
        })
    })
});

export const {
    useGetVoicemailDetailsQuery,
    useGetVoicemailUrlQuery,
    useGetContactNameByNumberQuery,
    useGetVoicemailListQuery,
    usePutAuxCodeMutation,
    useSearchContactsQuery,
    usePutAvailableInCallQueuesMutation,
    usePutMessageIdMutation,
    usePutCallFeedbackMutation,
    usePostCallTagsMutation,
    useGetAvailableTagsQuery,
    useGetSmartDiallerDevicesQuery,
    usePutCallRecordingMutation,
    useDeleteSingleVoicemailMutation,
    useGetInterTenantClientUsersQuery,
    useGetInterTenantsQuery,
    usePostVoicemailSeenMutation,
    useGetCallResolutionQuery
} = sipApi;
