import React from 'react';
import headphones from '../../img/misc/headphones.svg';
import Icon from "../Icon";
import AriaButton from "../AriaComponents/AriaButton";

interface IGenericNotificationProps {
    title?: string;
    message: string | JSX.Element;
    icon?: 'cogs' | 'headphones';
    button?: JSX.Element;
    crossClick?: (e?) => void;
    className?: string;
    clickFunction?: () => void;
    mouseEnterFunction?: () => void;
    mouseLeaveFunction?: () => void;
    type?: string;
    children?: any;
}

const Cogs: React.FC = () => (
    <svg width='50' height='69' viewBox='0 0 50 69' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_2_298)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M38.8385 34.1482C39.8261 35.2201 40.6695 36.42 41.3466 37.7166H45.3492L46.8395 44.2521L43.2319 46.0167C43.1841 47.4831 42.9444 48.9365 42.519 50.3388L45.3621 53.2014L41.8447 58.9003L38.0654 57.5627C37.0114 58.5667 35.8282 59.4212 34.5481 60.1029V64.1679L28.106 65.6755L26.3882 62.0156C24.9424 61.9706 23.5093 61.7273 22.1278 61.2923L19.3105 64.1767L13.693 60.6126L15.0115 56.7785C14.0239 55.7066 13.1805 54.5067 12.5034 53.2101H8.50076L7.0105 46.6746L10.618 44.91C10.6663 43.4436 10.906 41.9902 11.331 40.5879L8.49217 37.7253L12.0052 32.0263L15.7846 33.3639C16.8405 32.3623 18.0233 31.5081 19.3019 30.8238V26.7587L25.7439 25.2512L27.4618 28.9111C28.9074 28.9579 30.3403 29.2011 31.7222 29.6343L34.5395 26.75L40.1612 30.314L38.8385 34.1482ZM30.9362 39.5117C29.7442 38.7558 28.3581 38.3755 26.953 38.4189C25.548 38.4622 24.1872 38.9273 23.0426 39.7552C21.8981 40.5831 21.0212 41.7367 20.5229 43.0702C20.0246 44.4037 19.9272 45.8571 20.2431 47.2467C20.559 48.6363 21.2739 49.8997 22.2975 50.8771C23.3211 51.8546 24.6073 52.5021 25.9937 52.7379C27.38 52.9738 28.8041 52.7873 30.086 52.202C31.3678 51.6168 32.4499 50.6591 33.1952 49.45C33.6908 48.6472 34.0256 47.7531 34.1802 46.8189C34.3349 45.8847 34.3066 44.9287 34.0967 44.0057C33.8869 43.0826 33.4998 42.2106 32.9575 41.4395C32.4152 40.6683 31.7283 40.0133 30.9362 39.5117Z'
            />
        </g>
        <g filter='url(#filter1_d_2_298)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M42.7419 6.59889C43.2875 7.19388 43.7539 7.85891 44.1291 8.57697H46.3451L47.1697 12.202L45.1684 13.1692C45.1431 13.9824 45.0102 14.7884 44.7733 15.5656L46.3451 17.1472L44.3996 20.3017L42.3081 19.561C41.7227 20.1159 41.067 20.5891 40.3583 20.9683V23.2208L36.7851 24.053L35.8231 22.027C35.023 22.0014 34.23 21.8666 33.4653 21.6262L31.9021 23.2252L28.7927 21.2515L29.5228 19.1296C28.9759 18.5357 28.5094 17.8705 28.1356 17.1515H25.9196L25.095 13.5265L27.1049 12.568C27.1305 11.7563 27.2634 10.9518 27.5 10.176L25.9281 8.59439L27.8693 5.43557L29.9652 6.18061C30.5482 5.62479 31.2027 5.15141 31.9106 4.7733V2.52073L35.4752 1.67548L36.4415 3.70584C37.2413 3.73212 38.034 3.86544 38.7993 4.10233L40.3626 2.50766L43.472 4.48139L42.7419 6.60324V6.59889ZM38.3656 9.57036C37.7059 9.1517 36.9386 8.94091 36.1608 8.96467C35.383 8.98842 34.6297 9.24566 33.996 9.70384C33.3623 10.162 32.8768 10.8005 32.6009 11.5387C32.325 12.2768 32.271 13.0814 32.4459 13.8506C32.6207 14.6199 33.0165 15.3192 33.5832 15.8602C34.1498 16.4013 34.8619 16.7596 35.6294 16.8901C36.3968 17.0205 37.1851 16.917 37.8946 16.5929C38.6041 16.2687 39.203 15.7384 39.6153 15.0689C40.1681 14.1715 40.3469 13.0882 40.1126 12.0571C39.8782 11.026 39.2498 10.1316 38.3656 9.57036Z'
            />
        </g>
        <path
            d='M15.183 10.8993C15.6275 11.3783 16.006 11.9162 16.3082 12.4983H18.1034L18.7734 15.4393L17.1543 16.2322C17.1305 16.8878 17.0193 17.5371 16.8236 18.1624L18.112 19.4434L16.5359 22.0009L14.8437 21.3996C14.3693 21.8498 13.8378 22.2337 13.2633 22.5412V24.3624L10.3816 25.0421L9.60423 23.3995C8.95432 23.3769 8.31024 23.267 7.68879 23.0727L6.42615 24.3798L3.90517 22.7808L4.49783 21.038C4.05335 20.559 3.67485 20.0211 3.37263 19.439H1.57744L0.907471 16.498L2.52657 15.705C2.54924 15.0472 2.65754 14.3952 2.84867 13.7662L1.56026 12.4852L3.13642 9.9538L4.83282 10.5551C5.30502 10.1041 5.83522 9.72014 6.40897 9.41353V7.58359L9.2993 6.9039L10.0809 8.54649C10.7294 8.56913 11.3721 8.67901 11.9921 8.87326L13.2547 7.56616L15.7757 9.16519L15.183 10.908V10.8993ZM11.6399 13.3043C11.1054 12.9658 10.484 12.7955 9.85415 12.8152C9.22432 12.8348 8.61438 13.0435 8.10141 13.4148C7.58845 13.786 7.19549 14.3033 6.97222 14.9011C6.74895 15.4988 6.70538 16.1504 6.84703 16.7733C6.98867 17.3962 7.30917 17.9625 7.76801 18.4007C8.22685 18.8388 8.80344 19.1291 9.42488 19.2349C10.0463 19.3407 10.6847 19.2572 11.2594 18.9949C11.8341 18.7327 12.3192 18.3035 12.6535 17.7615C12.8762 17.4016 13.0266 17.0006 13.0962 16.5815C13.1658 16.1624 13.1531 15.7335 13.059 15.3194C12.9648 14.9053 12.791 14.5142 12.5475 14.1684C12.304 13.8226 11.9955 13.5289 11.6399 13.3043Z'
        />
        <defs>
            <filter
                id='filter0_d_2_298'
                x='5.0105'
                y='24.2512'
                width='43.8291'
                height='44.4243'
                filterUnits='userSpaceOnUse'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix'/>
                <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset dy='1'/>
                <feGaussianBlur stdDeviation='1'/>
                <feComposite in2='hardAlpha' operator='out'/>
                <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/>
                <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2_298'/>
                <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_2_298'
                    result='shape'
                />
            </filter>
            <filter
                id='filter1_d_2_298'
                x='23.095'
                y='0.675476'
                width='26.0747'
                height='26.3776'
                filterUnits='userSpaceOnUse'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix'/>
                <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset dy='1'/>
                <feGaussianBlur stdDeviation='1'/>
                <feComposite in2='hardAlpha' operator='out'/>
                <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/>
                <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2_298'/>
                <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_2_298'
                    result='shape'
                />
            </filter>
        </defs>
    </svg>
);

export const GenericNotification: React.FC<IGenericNotificationProps> = ({
                                                                             title,
                                                                             message,
                                                                             icon,
                                                                             button,
                                                                             crossClick,
                                                                             className,
                                                                             clickFunction,
                                                                             type = '',
                                                                             children,
                                                                             mouseEnterFunction,
                                                                             mouseLeaveFunction,
                                                                         }) => (
    <div className={`notification ${className || ''} ${clickFunction ? 'canHover' : ''}`}
         data-type={type}
         onClick={() => clickFunction && clickFunction()}
         onMouseEnter={() => mouseEnterFunction && mouseEnterFunction()}
         onMouseLeave={() => mouseLeaveFunction && mouseLeaveFunction()}
         data-icon={icon}
    >
        <div className={`${crossClick ? 'cross' : ''}`}>
            {icon === 'cogs' && <Cogs/>}
            {icon === 'headphones' && <img src={headphones} alt='headphones'/>}
            <div>
                {title && <p className='notification-title'>{title}</p>}
                <p>{message}</p>
            </div>
        </div>
        {button}
        {
            // className !== 'danger' &&
            crossClick ? (
                <AriaButton
                    className='notification_close'
                    onClick={() => {
                        crossClick();
                    }}
                >
                    <Icon name='cross' width={20} height={20}/>
                </AriaButton>
            ) : null}
        {children}
    </div>
);

export default GenericNotification;
