import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {selectAuthUserByUuid, selectUserStatus, setViewContact} from "../../../redux/slices";
import ContactProvider, {useContactContext} from "../../../context/ContactContext/context";
import {usePolyglot} from "../../../context/Polyglot";
import {IPhonebookContact} from "../../../types";
import styles from './styles.module.scss';
import usePlaceOver from "../../../helpers/placeAbove";
import {UserOptions} from "../../../modals/ContactViewer/children";
import {ChatStatus} from "../ChatHeader/ChatHeader";
import PhonebookIcon from "../../../components/icons/EntityIcon/children/PhonebookIcon";
import {useUserStatusContext} from "../../home/children/UserStatusContext";
import EntityIcon from "../../../components/icons/EntityIcon";

interface TagProps {
    tag: any;
    plain?: boolean;
}

interface GenericTagProps {
    title: string;
    children: ReactNode
}

const GenericTag: React.FC<GenericTagProps> = ({title, children}) => {
    const {contact} = useContactContext()
    const [showCard, setShowCard] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch();

    usePlaceOver({
        relativeElement: containerRef,
        fixedElement: itemRef,
        display: showCard,
        offset: {
            y: -12,
        }
    })

    return (
        <span
            className={styles.defaultTag}
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget) && showCard) {
                    setShowCard(false)
                }
            }}
            onFocus={() => setShowCard(true)}
            onMouseEnter={() => setShowCard(true)}
            onMouseLeave={() => setShowCard(false)}
            ref={containerRef}
            data-comp='chat_tag'
            onClick={() => dispatch(setViewContact(contact))}
        >
            <mark
                /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                tabIndex={0}
            >
                {title}
            </mark>
            <div
                ref={itemRef}
                className={styles.generic_tag}
            >
                {children}
            </div>
        </span>
    )
}

export const UserTag = ({tag, plain}: TagProps) => {
    const user = useTypedSelector(state => selectAuthUserByUuid(state, tag?.uuid))

    const userStatus = useTypedSelector(state => selectUserStatus(state, user?.name));

    const {addNameForReq} = useUserStatusContext();

    useEffect(() => {
        const returnFunc = () => {
            if (user) {
                addNameForReq(user.name, false)
            }
        }

        if (!user) return returnFunc;

        addNameForReq(user.name, true)
        return returnFunc;
    }, []);

    const {t} = usePolyglot();

    if (!user) {
        return (
            <mark>
                {`@${t('adjective.chat_unknown')} `}
            </mark>
        )
    }

    if (plain) {
        return (
            <mark>
                @{`${user.nickname} `}
            </mark>
        )
    }

    return (
        <ContactProvider value={{contact: user}}>
            <GenericTag title={`@${user.nickname}`}>
                <div className={styles.user_tag}>
                    <EntityIcon
                        type='user'
                        details={{
                            sip_name: user.name,
                            display_name: user.nickname,
                            avatar_key: user.avatar_key,
                            blf: true,
                            uuid: user.uuid,
                        }}
                    />
                    <div className={styles.details}>
                        <p>
                            {user.nickname}
                        </p>
                        <span>
                            <ChatStatus chatStatus={userStatus?.chat}/>
                        </span>
                    </div>
                    <UserOptions inTag/>
                </div>
            </GenericTag>
        </ContactProvider>
    )
}

export const ContactTag = ({tag, plain}: TagProps) => {
    const {t} = usePolyglot();

    let parsedContact: IPhonebookContact;

    try {
        parsedContact = JSON.parse(`{${tag.contact}}`);
    } catch {
        return (
            <mark>
                {`#${t('adjective.chat_unknown')}`}
            </mark>
        );
    }

    if (plain) {
        return <mark>
            {`#${[parsedContact.first_name, parsedContact.last_name].join(" ")} `}
        </mark>
    }

    return (
        <ContactProvider value={{contact: parsedContact}}>
            <GenericTag
                title={`#${[parsedContact.first_name, parsedContact.last_name].join(" ")} `}
            >
                <div className={styles.user_tag}>
                    <PhonebookIcon/>
                    <div className={styles.details}>
                        <p>
                            {[parsedContact.first_name, parsedContact.last_name].join(" ") || ''}
                        </p>
                        {parsedContact.company_name ? (
                            <span>
                                <p>
                            {parsedContact.company_name}
                                </p>
                        </span>
                        ) : null}
                    </div>
                </div>
            </GenericTag>
        </ContactProvider>
    )
}