import React, {FunctionComponent, useRef, useState} from 'react';
import formStyles from "../../sass/globalModules/forms.module.scss";
import styles from "./styled.module.scss";
import AriaTextField from "../../components/AriaComponents/AriaTextField";
import {StyledButton} from "../../components/StyledComponents";
import {setSmartDiallerError, startCall} from "../../redux/slices";
import {SmartDiallerError} from "../../types";
import {usePolyglot} from "../../context/Polyglot";
import {useTypedDispatch} from "../../redux/hooks";
import AriaFormGenericField from "../../components/AriaComponents/AriaFormGenericField";
import {useSmartDiallerItems} from "../../components/keypad/displays/NotInCallKeypad/hooks";
import StyledDropdown from "../../components/StyledComponents/StyledDropdown";

interface Props {
    smartDiallerError: SmartDiallerError
}

const SmartDiallerErrorDisplay: FunctionComponent<Props> = ({smartDiallerError}) => {
    const [callingNumber, setCallingNumber] = useState(smartDiallerError.callee);

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    const {
        smartDiallerOpts,
        getSelectedLabel,
    } = useSmartDiallerItems({});

    const callingNumberRef = useRef(null);

    return (
        <div
            className={[formStyles.formContainer, styles.container].join(' ')}
        >
            <section className={formStyles.formHeader}>
                <h2>Smart Dialler Error</h2>
            </section>
            <div className={styles.error_Section}>
                <p>
                    Failed to move call
                </p>
                <p>
                    {smartDiallerError.smartDiallerData?.title || "unknown Device"}
                </p>
                <p>
                    Possible Solutions:<br/>
                </p>
                <ul>
                    <li>Check device Do Not Disturb setting</li>
                    <li>Check connection to device</li>
                    <li>Restart Device</li>
                </ul>
            </div>
            <AriaTextField
                label='Calling Number'
                value={callingNumber}
                onChange={(e) => {
                    setCallingNumber(e)
                }}
                placeholder='Number to Redial'
                ref={callingNumberRef}
            />
            <AriaFormGenericField
                labelProps={{
                    label: 'Smart Dialler Device',
                }}
                className={styles.device_selector}
            >
                <StyledDropdown
                    options={smartDiallerOpts}
                    placeHolder={getSelectedLabel()}
                    className={styles.device}
                />
            </AriaFormGenericField>
            <div className={formStyles.formControls}>
                <StyledButton
                    buttonStyle='text'
                    context='danger'
                    type="button"
                    onClick={() => dispatch(setSmartDiallerError())}
                >
                    <p>
                        {t("actions.cancel")}
                    </p>
                </StyledButton>
                <StyledButton
                    buttonStyle='primary'
                    size='large'
                    type="submit"
                    disabled={!callingNumber}
                    onClick={() => {
                        if (callingNumber) {
                            dispatch(startCall(callingNumber));
                            dispatch(setSmartDiallerError());
                        }
                    }}
                >
                    <p>
                        Redial
                    </p>
                </StyledButton>
            </div>
        </div>
    );
};

export default SmartDiallerErrorDisplay;
