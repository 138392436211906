import React, { lazy, Suspense, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { isPreview, useGetUser } from './helpers';
import NavigationProvider from './context/Navigation';
import { useTypedSelector } from './redux/hooks';
import { PolyglotProvider } from './context/Polyglot';
import { FallbackContainer } from './modals/FallbackContainer';
import { AppLoader } from './modals/AppLoader';
import { ErrorBoundary } from './components';
import AppCrash from './components/AppCrash';
import AppForceUpdater from './modals/AppForceUpdater';
import { useAppContext } from './context/AppContext/AppContext';

const Home = lazy(() => import('./pages/home/Home'));
const Auth = lazy(() => import('./pages/auth/Auth'));

interface AppProps {}

const App: React.FC<AppProps> = () => {
    const {appName, appFavicon} = useAppContext()

    const userIsLoggedIn = useTypedSelector(state => state.user.name && state.user.password);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const { userIsLoading } = useGetUser();

    if (window.location.pathname.includes('/x')) {
        window.location.replace('/');
        return <span>Redirecting...</span>;
    }

    return (
        <PolyglotProvider>
            <HelmetProvider>
                <NavigationProvider>
                    <ErrorBoundary errorComponent={<AppCrash />}>
                        <FallbackContainer>
                            <Helmet defer={false}>
                                <title>
                                    {appName}
                                    {isPreview ? ' - Preview' : ''}
                                </title>
                                {!userIsLoggedIn ? <link rel='icon' href={appFavicon} /> : null}
                            </Helmet>
                            <Suspense>
                                {userIsLoggedIn ? <Home setIsLoaded={setIsLoaded} /> : null}
                                {!userIsLoggedIn && !userIsLoading ? (
                                    <Auth setIsLoaded={setIsLoaded} />
                                ) : null}
                            </Suspense>
                            <AppLoader userIsLoading={userIsLoading} isLoaded={isLoaded} />
                            <AppForceUpdater />
                        </FallbackContainer>
                    </ErrorBoundary>
                </NavigationProvider>
            </HelmetProvider>
        </PolyglotProvider>
    );
};

export default App;
