/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {CircularProgress} from "@mui/material";
import { api } from '../../api';
import { checkUserVerification } from '../../redux/slices';
import { useTypedDispatch} from "../../redux/hooks";
import {isPulseHd} from '../../helpers';
import Logout from '../../components/buttons/Logout';
import { IAuthUser } from '../../types';
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';

interface IAccountVerificationProps {
    loggedInUser: IAuthUser;
}

export const AccountVerification: React.FC<IAccountVerificationProps> = ({ loggedInUser }) => {
    const [loading, setLoading] = useState(false);
    const [resendError, setResendError] = useState(false);
    const [code, setCode] = useState('');
    const [invitationSent, setInvitationSent] = useState(0);
    const email = loggedInUser.verifications?.filter(
        verification => verification.verification_type.toLowerCase() === 'email'
    )[0];
    const mobile = loggedInUser.verifications?.filter(
        verification => verification.verification_type.toLowerCase() === 'mobile'
    )[0];

    let getVerificationTimeOut: NodeJS.Timeout;

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const { appLogoWhite, appLogoAlt } = useAppContext();

    const getVerification = () => {
        dispatch(checkUserVerification(loggedInUser.reseller_user_uuid));
        getVerificationTimeOut = setTimeout(() => getVerification(), 1000);
    };

    const isEmail = !email?.is_verified;

    const handleInvite = async () => {
        setInvitationSent(invitationSent + 1);
        await api.resendVerification({
            userUuid: loggedInUser.reseller_user_uuid,
            isResend: false,
            isEmail
        });
    };

    useEffect(() => {
        if (email && mobile) {
            clearTimeout(getVerificationTimeOut);
            if (!mobile.is_verified) {
                getVerification();

                if (invitationSent <= 1) {
                    if (email.is_verified) {
                        setInvitationSent(1);
                    }

                    handleInvite();
                }
            }
        }

        return () => {
            clearTimeout(getVerificationTimeOut);
        };
        // eslint-disable-next-line
    }, [email?.is_verified, mobile?.is_verified]);

    useEffect(() => {
        setCode('');
        // eslint-disable-next-line
    }, [email?.is_verified]);

    const handleInvitationResend = async () => {
        const response = await api.resendVerification({
            userUuid: loggedInUser.reseller_user_uuid,
            isResend: true,
            isEmail
        });

        if (response !== undefined && response.data !== undefined && response.data.html) {
            setResendError(true);
        }
    };

    const sendVerificationCode = async () => {
        setLoading(true);

        await api.setUpVerification({
            userUuid: loggedInUser.reseller_user_uuid,
            data: {
                verification_code: code,
                verification_type: !email?.is_verified ? 'EMAIL' : 'MOBILE'
            }
        });

        setLoading(false);
    };

    return (
        <section id='login_verification'>
            <div className='container verify'>
                {loading && (
                    <CircularProgress
                        size={40}
                        style={{
                            color: '#ffffff'
                        }}
                    />
                )}
                <div className='row'>
                    <header className='logo'>
                        <img src={appLogoWhite} alt={appLogoAlt} />
                    </header>
                    <div className='exit'>
                        <Logout />
                    </div>
                </div>
                <div className='verification-message'>
                    {resendError ? (
                        <p>
                            {t("phrases.error_verification")}
                            <br />
                            {!isPulseHd && (
                                <>
                                    {t(`phrases.please_contact_support_at_%number_or_%email`, {
                                        number: loggedInUser.is_y ? '0330 122 6000' : '(888) 929-8747',
                                        email: 'help@yay.com'
                                    })}
                                </>
                            )}
                        </p>
                    ) : !email?.is_verified && email?.is_required ? (
                        <p>
                            {t("phrases.sent_verification_code_to_your_email_%email", {
                                email: loggedInUser.email_address
                            })}
                        </p>
                    ) : (
                        <p>
                            {t(`phrases.we_have_sent_a_verification_code_to_your_${loggedInUser.is_y ? 'mobile' : 'cell'}_number_%number`, {
                                number: loggedInUser.reseller_phone
                            })}
                        </p>
                    )}
                </div>
                <div>
                    {!resendError && (
                        <>
                            <div className='input-box'>
                                <p>
                                    <label>{t("phrases.enter_the_verification_code")}</label>
                                    <span>*</span>
                                </p>
                                <input
                                    id='verification_code'
                                    type='number'
                                    onChange={e => setCode(e.target.value)}
                                    value={code}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            sendVerificationCode();
                                        }
                                    }}
                                />
                            </div>
                            <div className='login-verification-button'>
                                <button
                                    id='confirm_code'
                                    type='button'
                                    onClick={sendVerificationCode}
                                >
                                    {t("actions.submit")}
                                </button>
                            </div>
                            <div className='resend-verification'>
                                <p
                                    onClick={handleInvitationResend}
                                    onKeyDown={handleInvitationResend}
                                >
                                    {t("phrases.resend_verification_code")}
                                </p>
                                <p
                                    onClick={() => setResendError(true)}
                                    onKeyDown={() => setResendError(true)}
                                >
                                    {t(`phrases.error_incorrect_${email?.is_verified ? 'email' : 'number'}`)}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AccountVerification;