import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { selectCurrentUserSipName, setStatusSubscription } from '../../../redux/slices';

interface Props {
    addNameForReq: (name: string, val: boolean) => void;
}

export const UserStatusContext = createContext<Props | null>(null);

export const useUserStatusContext = () => {
    const context = useContext(UserStatusContext);

    if (!context?.addNameForReq) {
        throw new Error('Component requiring addNumberForReq rendered outside UserStatusProvider');
    }

    return {
        addNameForReq: context.addNameForReq
    };
};

export const UserStatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const userSipName = useTypedSelector(selectCurrentUserSipName);

    const namesArr = useRef<Map<string, boolean>>(new Map<string, boolean>());
    const timer = useRef<NodeJS.Timeout>();
    const dispatch = useTypedDispatch();

    const addNameForReq = (name: string, val: boolean) => {
        if (namesArr.current.get(name) === val || name === userSipName) return;

        namesArr.current.set(name, val);

        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            const resArr: string[] = [];
            namesArr.current.forEach((v, key) => {
                if (v) {
                    resArr.push(key);
                }
            });
            if (resArr.length > 0) {
                dispatch(setStatusSubscription(resArr));
            }
        }, 375);
    };

    useEffect(() => () => clearTimeout(timer.current), []);

    return (
        <UserStatusContext.Provider value={{ addNameForReq }}>
            {children}
        </UserStatusContext.Provider>
    );
};

export default UserStatusProvider;
