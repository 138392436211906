import React, {useState} from 'react';
import GroupIconEditor from "./children/GroupIconEditor";
import FormTextField from "../../../AriaForm/FormTextField";
import {StyledButton} from "../../../StyledComponents";
import styles from '../styles.module.scss';
import {usePolyglot} from "../../../../context/Polyglot";
import {useDeleteChannelMutation} from "../../../../redux/services/chatApi";
import {selectCurrentUserId, setActiveChat} from "../../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {useChatContext} from "../../../../context/ChatContext/context";

interface Props {
}

const GroupGeneralEdit: React.FC<Props> = () => {
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const [deleteWarning, setDeleteWarning] = useState<boolean>(false);

    const {t} = usePolyglot()

    const dispatch = useTypedDispatch();

    const [deleteChannel] = useDeleteChannelMutation();

    const handleChatDelete = () => {

        dispatch(setActiveChat(''));
        deleteChannel({
            voip_user_uuid: userUuid,
            channel_uuid: chat.uuid
        });
    }

    return (<>
        <GroupIconEditor/>
        <FormTextField
            label={t('actions.rename_group')}
            name='group_name'
            isRequired
        />
        <div
            className={styles.group_deletion}
            data-warning-active={deleteWarning || null}
        >
            <p>
                {deleteWarning ? t('phrases.are_you_sure') : t('actions.delete_group')}
                <span>
                      {t('phrases.this_action_will_delete_the_group_permanently')}
                    </span>
            </p>
            <div>
                <StyledButton
                    buttonStyle='tertiary'
                    context='danger'
                    onClick={() => {
                        if (deleteWarning) {
                            handleChatDelete()
                        } else {
                            setDeleteWarning(true)
                        }
                    }}
                >
                    <p>
                        {deleteWarning ? t('actions.delete_group') : t('actions.delete')}
                    </p>
                </StyledButton>
                {deleteWarning ? (
                    <StyledButton
                        buttonStyle='tertiary'
                        onClick={() => setDeleteWarning(false)}
                    >
                        <p>
                            {t('actions.cancel')}
                        </p>
                    </StyledButton>
                ) : null}
            </div>
        </div>
    </>);
};

export default GroupGeneralEdit;
