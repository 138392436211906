import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

type Props<T> = {
    options: OptionLabVal<T>[];
    selectedTab: T | undefined;
    setSelectedTab: (val: T) => void;
    gliderStyle?: 'primary';
};

export type OptionLabVal<T> = {
    label: string;
    node?: ReactNode;
    value: T;
};

const StyledTabs = <T,>({ options, selectedTab, setSelectedTab, gliderStyle }: Props<T>) => {
    const [activeIndex, setActiveIndex] = useState<number>(
        Math.max(
            options.findIndex(option => option.value === selectedTab),
            0
        )
    );

    useEffect(() => {
        setActiveIndex(
            Math.max(
                options.findIndex(option => option.value === selectedTab),
                0
            )
        );
    }, [selectedTab]);

    const container = useRef<HTMLDivElement>(null);
    const glider = useRef<HTMLDivElement>(null);

    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const dragLen = useRef(0);

    const positionSlider = () => {
        if (container.current && glider.current) {
            const btns = Array.from(container.current.querySelectorAll('button'));

            let w = 0;
            let fw = 0;

            for (let i = 0; i <= Math.min(activeIndex, btns.length - 1); i += 1) {
                const br = btns[i];

                const bcr = br.getBoundingClientRect();

                if (i === activeIndex) {
                    fw = bcr.width;
                } else {
                    w += bcr.width;
                }
            }

            w += activeIndex * 4;

            glider.current.style.width = `${fw}px`;
            glider.current.style.marginLeft = `${w}px`;
        }
    };

    useEffect(() => {
        positionSlider();

        const inter = setInterval(() => {
            positionSlider();
        }, 250);

        return () => {
            clearInterval(inter);
        };
    });

    return (
        <div
            className={styles.tab_selector}
            data-glder-style={gliderStyle || null}
            ref={container}
            onMouseDown={e => {
                isDown.current = true;
                startX.current = e.pageX - e.currentTarget.offsetLeft;
                scrollLeft.current = e.currentTarget.scrollLeft;
            }}
            onMouseLeave={() => {
                isDown.current = false;
            }}
            onMouseUp={() => {
                isDown.current = false;
            }}
            onMouseMove={e => {
                if (!isDown.current || !e.currentTarget) return;
                const diff = (e.pageX - e.currentTarget.offsetLeft - startX.current) * 1.25;
                e.currentTarget.scrollLeft = scrollLeft.current - diff;
                dragLen.current += diff;
            }}
        >
            <div className={styles.tab_glider} ref={glider} />
            {options.map((option, idx) => (
                <button
                    key={`${idx}_${option.value}`}
                    onClick={() => {
                        if (dragLen.current <= 28 && dragLen.current >= -28) {
                            setActiveIndex(idx);
                            setSelectedTab(option.value);
                        }
                        dragLen.current = 0;
                    }}
                    title={option.label}
                    data-is-active={selectedTab === option.value || null}
                >
                    {option.node ? option.node : <p>{option.label}</p>}
                </button>
            ))}
        </div>
    );
};

export default StyledTabs;
