import {createContext, Dispatch, ReactNode, useContext, useMemo, useReducer} from "react";
import {IChat, IThread} from "../../types";
import {ChatInputContextState, chatReducer, ChatInputReducerAction, initialState} from "./reducer";

interface Props {
    reducer: Dispatch<ChatInputReducerAction>;
    state: ChatInputContextState;
    chat: IChat | IThread | undefined;
    shouldReset?: boolean;
}

const ChatInputContext = createContext<Props | null>(null)

export const useChatInputContext = () => {
    const context = useContext(ChatInputContext)

    if (!context?.chat) {
        throw new Error(
            'Component requiring chat context rendered without a chat'
        )
    }


    const chatState: ChatInputContextState = context.state
    const useChatInputAction: (val: ChatInputReducerAction) => void = context.reducer

    const noPriorChatHistory = useMemo(() => !!(context?.chat?.uuid && !context.chat), [context.chat, context.chat.uuid]);

    return {
        noPriorChatHistory,
        expandMessage: chatState.expandMessage,
        chat: context.chat,
        shouldReset: context.shouldReset,
        files: chatState.filesToUpload,
        chatState,
        useChatInputAction
    }
}

interface ProviderProps {
    children: ReactNode;
    chat: IChat | undefined;
    shouldReset?: boolean
}

const ChatInputProvider: React.FC<ProviderProps> = ({ children, chat, shouldReset }) => {
    const [state, dispatch] = useReducer(chatReducer, {...initialState})

    return (
        <ChatInputContext.Provider value={{reducer: dispatch, state, chat, shouldReset}}>
            {children}
        </ChatInputContext.Provider>
    )
}

export default ChatInputProvider;
