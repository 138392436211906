import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {initialState, KeypadAction, KeypadState, reducer} from "./reducer";

interface Props {
    state: KeypadState;
    reducer: Dispatch<KeypadAction>;
}

export const KeypadContext = createContext<Props | null>(null)

export const useKeypadContext = () => {
    const context = useContext(KeypadContext)

    if (!context) {
        throw new Error(
            'Component requiring keypad context rendered outside keypad'
        )
    }

    const keypadState: KeypadState = context.state
    const useKeypadAction: (val: KeypadAction) => void = context.reducer

    return {
        keypadState,
        useKeypadAction,
    }
}

export const KeypadProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const keypadReducer = useReducer(reducer, initialState)

    return (
        <KeypadContext.Provider value={{state: keypadReducer[0], reducer: keypadReducer[1]}}>
            {children}
        </KeypadContext.Provider>
    )
}

export default KeypadProvider