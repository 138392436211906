import moment from "moment/moment";
import {v4 as uuidv4} from "uuid";
import {useCallback, useState} from "react";
import debounce from "lodash.debounce";
import {ReactEditor} from "slate-react";
import {Element} from "slate";
import {FriendRequest, IChatMessage, IFile, isIntegration} from "../../../types";
import {clearActiveMessageReply, selectCurrentUserId, setActiveChunk} from "../../../redux/slices";
import {
    useGetMostRecentChatMessagesQuery, useInitiateFriendMutation,
    useSendChatMessageMutation,
    useSendChatMessageWithImageMutation,
    useSendTypingStatusMutation
} from "../../../redux/services/chatApi";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {useChatInputContext} from "../../../context/ChatInputContext/context";
import {useChatContext} from "../../../context/ChatContext/context";
import {slateToMarkdown} from './utils/slateToMarkdown';

interface UseSendMessageProps {
    editor: ReactEditor;
    value: Element[];
    setValue: (val: Element[]) => void;
    characterByteCount: number;
    sendUserStillTyping: any;
    sendUserStopTyping: any;
}

export const useSendMessage = ({
                                   editor,
                                   value,
                                   setValue,
                                   characterByteCount,
                                   sendUserStillTyping,
                                   sendUserStopTyping
                               }: UseSendMessageProps) => {
    const {chat, shouldReset} = useChatInputContext()
    const {files, useChatAction} = useChatContext();

    const messageReply = useTypedSelector(state => state.chat.messageReplies[chat.uuid]);
    const user = useTypedSelector(state => state.user);

    const [messageIsSending, setMessageIsSending] = useState<boolean>(false);
    const [recentMessagesQuery, setRecentMessagesQuery] = useState<any>({
        voip_user_uuid: user.uuid,
    })

    const dispatch = useTypedDispatch();

    const {refetch, isUninitialized, isFetching} = useGetMostRecentChatMessagesQuery(recentMessagesQuery,
        {skip: !(recentMessagesQuery.type || recentMessagesQuery.chat_uuid)})

    const [sendChatMessage] = useSendChatMessageMutation();
    const [sendTypingStatus] = useSendTypingStatusMutation();
    const [sendChatMessageWithImage] = useSendChatMessageWithImageMutation();
    const [sendInitiateFriend] = useInitiateFriendMutation();

    const uuid = uuidv4();
    const initialValue = [{
        type: 'paragraph',
        children: [{text: ''}],
    }];

    return async (event?: any) => {
        const isSendDisabled = isIntegration(chat) && (characterByteCount > 750 || characterByteCount < 3);

        if (isSendDisabled) {
            return;
        }

        switch (true) {
            case slateToMarkdown(value).trim().length === 0 && files?.length === 0:
            case chat === undefined:
                return;
        }

        if (chat.needs_friend_req) {
            const friendRequest: FriendRequest = {
                display_name: chat.display_name,
                from: user.uuid,
                from_name: user.nickname,
                message: slateToMarkdown(value).trim(),
                to: chat.uuid,
            }

            await sendInitiateFriend(friendRequest);
        }

        if (!messageIsSending) {
            setMessageIsSending(true);

            const point = {path: [0, 0], offset: 0}
            // eslint-disable-next-line no-param-reassign
            editor.selection = {anchor: point, focus: point};
            // eslint-disable-next-line no-param-reassign
            editor.children = initialValue;

            if (event) event.preventDefault();

            sendUserStillTyping.cancel();
            sendUserStopTyping.cancel();

            sendTypingStatus({
                user: user.uuid,
                to: chat.uuid,
                to_type: chat.type,
                status: 'stop_typing'
            })

            const replyTo = messageReply?.message?.uuid;

            const message: IChatMessage = {
                uuid,
                to: chat.uuid,
                to_type: chat.type,
                content: slateToMarkdown(value).trim(),
                from: user.uuid,
                from_type: 'user',
                time: moment().format(),
                updated_at: moment().format()
            };

            dispatch(setActiveChunk({
                chat_uuid: chat.uuid,
                active_chunk: 'initial',
            }))

            if (replyTo) {
                message.reply_to = replyTo;

                dispatch(clearActiveMessageReply(chat.uuid));
            }

            if (files && files.length > 0) {
                const readFile = async (file: File, index: number): Promise<IFile> =>
                    // eslint-disable-next-line no-return-await
                    await new Promise((resolve, reject) => {
                        const fileReader = new FileReader();

                        fileReader.onload = readerEvent => {
                            resolve({
                                content:
                                    typeof readerEvent.target?.result === 'string'
                                        ? unescape(btoa(readerEvent.target?.result))
                                        : '',
                                content_type: file.type,
                                index,
                                shared_at: moment().format(),
                                shared_by: user.uuid,
                                size: file.size,
                                uuid: uuidv4(),
                                name: file.name,
                                pending: true
                            });
                        };

                        fileReader.onerror = reject;

                        fileReader.readAsBinaryString(file);
                    });

                const readFiles = await Promise.all(
                    files.map((file, index) => readFile(file, index))
                );

                await sendChatMessageWithImage({
                    ...message,
                    files: readFiles
                })

            } else {
                await sendChatMessage(message);
            }
            useChatAction({type: "reset_files"})
            setMessageIsSending(false);

            if (shouldReset) {
                // dispatch(deleteAllMessages({chat_uuid: chat.uuid, message}));
                if (recentMessagesQuery.chat_uuid === chat.uuid) {
                    if (!isUninitialized && !isFetching) {
                        refetch()
                    }
                } else {
                    setRecentMessagesQuery({
                        ...recentMessagesQuery,
                        type: chat.type,
                        chat_uuid: chat.uuid
                    })
                }
            }
        }

        setValue(initialValue);

        setTimeout(() => {
            setMessageIsSending(false);
        }, 5000)
    };
}

export const useUserTyping = () => {
    const {chat} = useChatContext()

    const userUuid = useTypedSelector(selectCurrentUserId)

    const [userStartedTyping, setUserStartedTyping] = useState(false);

    const [sendTypingStatus] = useSendTypingStatusMutation();

    // eslint-disable-next-line
    const sendUserStillTyping = useCallback(
        debounce(() => {

            sendTypingStatus({
                user: userUuid,
                to: chat.uuid,
                to_type: chat.type,
                status: 'still_typing'
            })

        }, 1000),
        []
    );
    // eslint-disable-next-line
    const sendUserStopTyping = useCallback(
        debounce(() => {
            sendTypingStatus({
                user: userUuid,
                to: chat.uuid,
                to_type: chat.type,
                status: 'stop_typing'
            })
            setUserStartedTyping(false);
        }, 3000),
        []
    );

    const handleTypingStatus = (charLength: number) => {
        if (charLength > 0 && !userStartedTyping) {
            setUserStartedTyping(true);

            sendTypingStatus({
                user: userUuid,
                to: chat.uuid,
                to_type: chat.type,
                status: 'start_typing'
            })

        } else {

            sendUserStopTyping();
        }
    };

    return {
        handleTypingStatus,
        sendUserStillTyping,
        sendUserStopTyping,
    };
}
