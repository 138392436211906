import {ReactElement} from "react";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import {Toast} from "./children/Toast";
import styles from './styles.module.scss';
import {useTypedSelector} from "../../redux/hooks";
import {selectToasts} from "../../redux/slices";

interface Props {
}

export interface IToast {
    id: string;
    type: 'info' | 'success' | 'warning' | 'error';
    active: boolean;
    title: string;
    content: string | ReactElement;
}


export const ToastCenter: React.FC<Props> = () => {
    const toasts = useTypedSelector(selectToasts);

    return (
        <ul
            className={styles.toast_container}
            data-schema={document.body.getAttribute('data-schema')}
        >
            <AnimatePresence>
                {toasts.map((t) => (
                    <motion.div
                        key={`TA_${t.id}`}
                        initial={{
                            y: -250,
                            opacity: 0,
                        }}
                        animate={{
                            y: 0,
                            opacity: 1,
                        }}
                        exit={{
                            y: -250,
                            opacity: 0,
                        }}
                        transition={{
                            duration: 1.5,
                            ease: 'easeInOut'
                        }}
                    >
                        <Toast toast={t}/>
                    </motion.div>
                ))}
            </AnimatePresence>
        </ul>
    )
}