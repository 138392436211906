import React from 'react';
import styles from './styles.module.scss';

interface Props {
    context: 'beta';
    size?: 'small';
    text: string;
    title?: string;
}

const StyledTag: React.FC<Props> = ({context, text, size, title}) => (
    <code
        className={styles.styled_tag}
        title={title || undefined}
        data-context={context}
        data-size={size || null}
    >
        {text}
    </code>

);

export default StyledTag;
