import React from "react";
import styles from "./Previews.module.scss";
import { useAppContext } from '../../../../../../context/AppContext/AppContext';

export const LightPreview: React.FC = () => {
    const {isYay, isCallSwitch} = useAppContext();

    let schema;
    switch (true) {
        case isYay:
            schema = 'yddab88d'
            break;
        case isCallSwitch:
            schema = 'cef527d1'
            break;
        default:
            schema = 'pe12cd8c'
    }

    return (
        <div data-schema={schema} className={styles.container}>
            <svg
                width={156}
                height={101}
                viewBox="0 0 156 101"
                fill="none"
                role='figure'
            >
                <title id="title">Light Theme Icon</title>
                <g clipPath="url(#clip0_1142_9018)" data-theme="light">
                    <rect width="156" height="101" rx="4" fill="#F2F2F2" className='test' data-fill='secondary-background' opacity="0.3"/>
                    <path d="M14.2146 23.4811C14.2146 19.6151 17.3486 16.4811 21.2146 16.4811H49.9702V100.999H14.2146V23.4811Z" fill="#D9D9D9" data-fill="border"/>
                    <path
                        d="M14.2146 23.4811C14.2146 19.6151 17.3486 16.4811 21.2146 16.4811H49.9702V100.999H14.2146V23.4811Z"
                        fill="#87001C" data-fill="nav-flat"/>
                    <rect x="49.9702" y="16.4811" width="105.829" height="84.5183" fill="white" data-fill="primary-background"/>
                    <path
                        d="M61.0023 34.6495C61.0023 31.2188 63.7835 28.4376 67.2142 28.4376H156V40.8614H67.2142C63.7835 40.8614 61.0023 38.0802 61.0023 34.6495V34.6495Z"
                        fill="#B6B7BF" data-fill="secondary-font" opacity="0.6"/>
                    <path opacity="0.4"
                          d="M78 62.3856C78 60.3724 79.6321 58.7403 81.6453 58.7403H171.503V66.0309H81.6453C79.6321 66.0309 78 64.3989 78 62.3856V62.3856Z"
                          fill="#B6B7BF" data-fill="secondary-font"/>
                    <path opacity="0.4"
                          d="M78 85.423C78 83.4097 79.6321 81.7776 81.6453 81.7776H171.503V89.0683H81.6453C79.6321 89.0683 78 87.4362 78 85.423V85.423Z"
                          fill="#B6B7BF" data-fill="secondary-font"/>
                    <path
                        d="M20.5924 27.1495C20.5924 24.9403 22.3833 23.1495 24.5924 23.1495H39.5924C41.8016 23.1495 43.5924 24.9403 43.5924 27.1495V42.1495C43.5924 44.3586 41.8016 46.1495 39.5924 46.1495H24.5924C22.3833 46.1495 20.5924 44.3586 20.5924 42.1495V27.1495Z"
                        fill="white" data-fill="primary-background"/>
                </g>
                <defs>
                    <clipPath id="clip0_1142_9018">
                        <rect width="156" height="101" rx="4" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
