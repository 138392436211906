import React from 'react';
import StyledSkeleton from "./StyledSkeleton";

interface Props {
    text: string;
}

const DividerSkeleton: React.FC<Props> = ({text}) => (
    <div className='list-divider'>
        <StyledSkeleton style={{height: 17.5, width: `${text.length}ch`}}/>
    </div>
);

export default DividerSkeleton;
