import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import styles from './DragAndDrop.module.scss'
import {ContactIcon} from "../sidebars/Contact/children";

interface Props {
}

export const DragAndDrop: React.FC<Props> = () => {
    const dadRef = useRef<HTMLDivElement>(null);

    const dadArea = {x: 50, y: 50}

    useEffect(() => {
        window.addEventListener('dragover', (event) => {
            event.preventDefault();
            handleMouseMove(event)
        }, false);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, []);

    const handleMouseMove = (event) => {
        if (dadRef.current) {
            dadRef.current.style.left = `${event.clientX - dadArea.x / 2}px`
            dadRef.current.style.bottom = `${window.innerHeight - event.clientY - dadArea.y / 2}px`
        }
    }

    return ReactDOM.createPortal(
        <div
            ref={dadRef}
            className={styles.drag_and_drop}
        >
            <ContactIcon
                noBLF
                size={50}
            />
        </div>
    , document.body)
}

export default DragAndDrop;
