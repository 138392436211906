import React, {useRef, useState} from "react";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import {selectCurrentUserId, setActiveChat, startCall} from "../../../../../redux/slices";
import {getDisplayColor, useOnClickOutside} from "../../../../../helpers";
import {usePutChannelUsersMutation} from "../../../../../redux/services/chatApi";
import {usePolyglot} from "../../../../../context/Polyglot";
import styles from '../styles.module.scss';
import StyledDropdown from "../../../../../components/StyledComponents/StyledDropdown";
import {StyledButton} from "../../../../../components/StyledComponents";

interface IGroupMember {
    member: any;
    channelCreator?: boolean;
    admin?: boolean;
    adminUuids?: string[];
}

export type MemberOption =
    {
        label: string,
        value: 'chat' | 'call' | 'remove',
        onClick: () => void
    };

export const GroupMember: React.FC<IGroupMember> = ({member, channelCreator, admin, adminUuids}) => {
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const [kickWarning, setKickWarning] = useState<boolean>(false);

    const settingsRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(settingsRef, () => setKickWarning(false))

    const dispatch = useTypedDispatch()

    const [putChannelUsers] = usePutChannelUsersMutation();

    const {t} = usePolyglot();

    const users = useTypedSelector(state => state.auth.users || []);
    const user = users?.find(allUser => allUser.uuid === member?.member);

    const handleKick = () => {
        if (kickWarning) {
            kickMember(member.member)
        } else {
            setKickWarning(true)
        }
    }

    const kickMember = (kickID: string) => {
        putChannelUsers({
            voip_user_uuid: userUuid,
            channel_uuid: chat.uuid,
            changes: {
                members_to_remove: [kickID]
            }
        })
    }

    const options: MemberOption[] = [
        {
            label: t("actions.chat", 1),
            value: 'chat',
            onClick: () => dispatch(setActiveChat(member.member))
        },
        {
            label: t("actions.call", 1),
            value: 'call',
            onClick: () => user && dispatch(startCall(user.extension.toString(), user.nickname)),
        }
    ]

    if (adminUuids?.includes(userUuid) && member.member !== userUuid) {
        options.push(
            {
                label: kickWarning ? t("phrases.are_you_sure") : t("actions.remove_from_group"),
                value: 'remove',
                onClick: () => handleKick(),
            })
    }

    return (
        <div
            className={styles.group_member}
            onBlur={(e) => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    setKickWarning(false)
                }
            }}
        >
            <div className={styles.group_member_icon}>
                {member.avatar
                    ? <img
                        alt={member.display_name}
                        src={`https://storage.googleapis.com/v-profiles/${member.avatar}`}
                    />
                    :
                    <span className={getDisplayColor(member?.member, true)}>
                        {member.display_name
                            ?.match(/\b\w/g)
                            ?.join('')
                            .substring(0, 2)
                        }
                    </span>
                }
            </div>
            <p>
                {member.display_name}
                {(channelCreator && ` (${t("terms.creator", 1)})`) || (admin && ` (${t("terms.admin", 1)})`)}
            </p>
            <div
                className={styles.group_member_option}
                ref={settingsRef}
            >
                <StyledDropdown
                    options={options}
                    component={(props) =>
                        <StyledButton
                            key={`GCMO_${props.value}`}
                            buttonStyle='text'
                            onClick={() => props.onClick()}
                            context={props.value === 'remove' ? 'danger' : undefined}
                        >
                            <p>
                                {props.label}
                            </p>
                        </StyledButton>
                    }
                    iconOnly
                    direction='right'
                />
            </div>
        </div>
    )
}