/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react';
import {IYeOldeCall} from '../../../../types';
import CallHistoryRowDisplay from "./CallHistoryRowDisplay";
import {useCallHistoryBase} from "./hooks";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    selectActiveChat, selectCanAddFriend,
    selectChatEnabled, selectInterTenantById,
    setTenantChat, setViewContact,
    startCall, updateActivePage
} from "../../../../redux/slices";
import {usePolyglot} from "../../../../context/Polyglot";
import CallIconButton from "../../../../components/buttons/CallIconButton";
import {useUserStatusContext} from "../../../home/children/UserStatusContext";
import {useGetInterTenantClientUsersQuery} from "../../../../redux/services/sipApi";
import Icon from "../../../../components/Icon";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const InternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({call}) => {
    const {
        duration,
        callee,
        callIcon,
        date,
        calleeUuid,
    } = useCallHistoryBase(call);

    const tenant = useTypedSelector(state => selectInterTenantById(state, calleeUuid));
    const chatEnabled = useTypedSelector(selectChatEnabled);
    const activeChat = useTypedSelector(selectActiveChat);
    const canAddFriend = useTypedSelector(selectCanAddFriend);

    const {t} = usePolyglot();
    const dispatch = useTypedDispatch();

    const {addNameForReq} = useUserStatusContext();

    let ext = 0;

    for (let i = 0; i < callee.length; i += 1) {
        const char = callee.charAt(i);

        if (!char) break;

        const charInt = parseInt(char);

        if (!Number.isNaN(charInt)) {
            ext = (ext * 10) + charInt;
        }
    }

    const client = tenant?.users?.find(c => c.extension === ext);

    useGetInterTenantClientUsersQuery({tenant_uuid: tenant?.uuid || ''}, {
        skip: !tenant || tenant.users !== undefined || !!client,
        refetchOnMountOrArgChange: true
    })


    useEffect(() => {
        const returnFunc = () => {
            if (client) {
                addNameForReq(client.user_sip_name, false)
            }
        }

        if (!client) return returnFunc;

        addNameForReq(client.user_sip_name, true)
        return returnFunc;
    }, [client]);

    const openChat = () => {
        if (!client) return;

        if (activeChat === client.user_uuid) {
            dispatch(updateActivePage("chat"));
        } else {
            dispatch(setTenantChat(client));
        }
        dispatch(setViewContact())
    };

    if (!tenant) {
        return (
            <CallHistoryRowDisplay
                answered={call.answered}
                callIcon={callIcon}
                rowName={callee}
                date={date}
                duration={duration}
                options={[]}
                secondaryString=''
                subHeader=''
            />
        )
    }

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={callIcon}
            rowName={client?.user_display_name || String(ext)}
            secondaryString={client?.user_display_name && `${tenant.prefix}${client.extension}`}
            subHeader={tenant.name}
            date={date}
            duration={duration}
            options={(<>
                {client ? (
                    <CallIconButton
                        handleCall={() => {
                            dispatch(startCall(`${tenant.prefix}${client.extension}`, client.user_display_name, undefined, tenant.uuid));
                        }}
                        user={{uuid: client.user_uuid, name: client.user_sip_name}}
                    />
                ) : null}
                {(chatEnabled && canAddFriend && client?.can_chat) ? (
                    <AriaButton
                        onClick={() => {
                            openChat()
                        }}
                        title={t("actions.send_message")}
                    >
                        <Icon name='messageFilled' width={22} height={22}/>
                    </AriaButton>
                ) : null}
            </>)}
        />
    );
};

export default InternalCallHistoryRow;