import React, {RefObject, useEffect, useState} from 'react';
import {Editor} from "slate";
import {useSlate} from 'slate-react'
import styles from './Toolbar.module.scss';
import {AnimatedDiv} from "../../../../animation";
import {useChatInputContext} from "../../../../context/ChatInputContext/context";
import {isIntegration} from "../../../../types";
import Icon, {IconName} from "../../../../components/Icon";
import {InputFormat, ListType, useSlateMarks} from "./hooks";
import AriaButton from "../../../../components/AriaComponents/AriaButton";
import ChatInputMessageSend from "./children/ChatInputMessageSend";
import ChatInputAudioRecord from "./children/ChatInputAudioRecord";
import ChatInputEmoji from "./children/ChatInputEmoji";

interface Props {
    uploadInput: RefObject<HTMLInputElement>,
    onSubmit: () => void;
    characterCount: number;
}

const Toolbar: React.FC<Props> = ({
                                      uploadInput,
                                      onSubmit,
                                      characterCount
                                  }) => {
    const {chat} = useChatInputContext()

    const [textTransformButtons, setTextTransformButtons] = useState(false);
    const editor = useSlate();

    const {
        isMarkActive,
        toggleMark,
        toggleBlock,
        isBlockActive
    } = useSlateMarks()

    const formatButtons: {
        format: InputFormat,
        block?: ListType,
        icon: IconName,
    }[] = [
        {
            format: 'bold',
            icon: 'bold'
        },
        {
            format: 'italic',
            icon: 'italic'
        },
        {
            format: 'underline',
            icon: 'underline'
        },
        {
            format: 'strikethrough',
            icon: 'strikethrough'
        },
        {
            format: 'unorderedList',
            block: 'bulleted-list',
            icon: 'listDash'
        },
        {
            format: 'orderedList',
            block: 'numbered-list',
            icon: 'listNumber'
        },
    ]

    useEffect(() => {
        if (
            (
                Editor.marks(editor)
                && Object.keys(Editor.marks(editor) || {}).length !== 0
            )
            || isBlockActive(editor, "orderedList")
            || isBlockActive(editor, "unorderedList")) {
            setTextTransformButtons(true);
        }
    }, [Editor.marks(editor)])

    return (
        <div className={styles.toolbar} onPointerDown={e => e.preventDefault()}>
            {isIntegration(chat) ? (
                <ChatInputEmoji/>
            ) : (
                <>
                    <AriaButton
                        className={styles.toolbar_button}
                        // title={t("verb.add_%name", { name: t("noun.attachment", 1)})}
                        onClick={() => uploadInput.current?.click()}
                    >
                        <Icon name='paperclip'/>
                    </AriaButton>
                    <ChatInputEmoji/>
                    <ChatInputAudioRecord/>
                    <AriaButton
                        dataTags={{
                            'data-is-active': textTransformButtons,
                        }}
                        className={styles.toolbar_button}
                        onClick={() => {
                            setTextTransformButtons(!textTransformButtons)
                        }}
                    >
                        <Icon name='text'/>
                    </AriaButton>
                    <div className={styles.toolbar_extras}>
                        <AnimatedDiv visible={textTransformButtons} inAnimation={['fade', 'slideFromLeft']}>
                            <hr/>
                            {formatButtons.map(formatButton => (
                                <AriaButton
                                    dataTags={{
                                        'data-is-active': formatButton.block ? isBlockActive(formatButton.block) : isMarkActive(formatButton.format),
                                    }}
                                    className={styles.toolbar_button}
                                    onClick={() => {
                                        if (formatButton.block) {
                                            toggleBlock(formatButton.block)
                                        } else {
                                            toggleMark(formatButton.format);
                                        }
                                    }}
                                    key={`format_${formatButton.format}`}
                                >
                                    <Icon name={formatButton.icon}/>
                                </AriaButton>
                            ))}
                        </AnimatedDiv>
                    </div>
                </>
            )}
            <ChatInputMessageSend
                onSubmit={onSubmit}
                characterCount={characterCount}
            />
        </div>
    )
}

export default Toolbar;
