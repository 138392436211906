
export const MoodEmojiNeutral: React.FC<any> = ({
                                                    ariaHidden,
                                                    title,
                                                }) => (
    <svg
        width={45}
        height={45}
        viewBox="0 0 45 45"
        fill="none"
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">About</title>}
        <desc id="desc">Neutral</desc>
        <path d="M22.2385 38.8133C31.4521 38.8133 38.9212 31.3442 38.9212 22.1307C38.9212 12.9171 31.4521 5.448 22.2385 5.448C13.0249 5.448 5.55584 12.9171 5.55584 22.1307C5.55584 31.3442 13.0249 38.8133 22.2385 38.8133Z" fill="url(#paint0_radial_4_335)"/>
        <path opacity="0.5" d="M22.2385 38.8133C31.4521 38.8133 38.9212 31.3442 38.9212 22.1307C38.9212 12.9171 31.4521 5.448 22.2385 5.448C13.0249 5.448 5.55584 12.9171 5.55584 22.1307C5.55584 31.3442 13.0249 38.8133 22.2385 38.8133Z" fill="url(#paint1_radial_4_335)"/>
        <path d="M16.7956 22.8395C17.6824 22.8395 18.4013 21.7518 18.4013 20.4101C18.4013 19.0684 17.6824 17.9807 16.7956 17.9807C15.9087 17.9807 15.1898 19.0684 15.1898 20.4101C15.1898 21.7518 15.9087 22.8395 16.7956 22.8395Z" fill="url(#paint2_radial_4_335)"/>
        <path d="M16.7956 18.8044C17.4837 18.8044 18.0885 19.4092 18.4013 20.2954C18.3804 18.9816 17.6714 17.9286 16.7956 17.9286C15.9197 17.9286 15.2107 18.9816 15.1898 20.2954C15.5027 19.4092 16.097 18.8044 16.7956 18.8044Z" fill="url(#paint3_linear_4_335)"/>
        <path d="M27.6813 22.8395C28.5681 22.8395 29.287 21.7518 29.287 20.4101C29.287 19.0684 28.5681 17.9807 27.6813 17.9807C26.7945 17.9807 26.0756 19.0684 26.0756 20.4101C26.0756 21.7518 26.7945 22.8395 27.6813 22.8395Z" fill="url(#paint4_radial_4_335)"/>
        <path d="M27.6813 18.8672C26.9932 18.8672 26.3884 19.4719 26.0756 20.3582C26.0965 19.0445 26.8055 17.9913 27.6813 17.9913C28.5572 17.9913 29.2662 19.0445 29.287 20.3582C28.9742 19.4719 28.3799 18.8672 27.6813 18.8672Z" fill="url(#paint5_linear_4_335)"/>
        <path d="M29.2663 29.4085H15.2112C14.7837 29.4085 14.4292 29.054 14.4292 28.6265C14.4292 28.199 14.7837 27.8445 15.2112 27.8445H29.2663C29.6938 27.8445 30.0483 28.199 30.0483 28.6265C30.0483 29.054 29.6938 29.4085 29.2663 29.4085Z" fill="url(#paint6_radial_4_335)"/>
        <path d="M15.1382 28.2615H29.3497C29.6521 28.2615 29.9232 28.4388 30.0483 28.689C30.0483 28.6682 30.0483 28.6473 30.0483 28.6265C30.0483 28.199 29.6938 27.8445 29.2663 27.8445H15.2112C14.7837 27.8445 14.4292 28.199 14.4292 28.6265C14.4292 28.6473 14.4292 28.6682 14.4292 28.689C14.5647 28.4284 14.8254 28.2615 15.1382 28.2615Z" fill="url(#paint7_linear_4_335)"/>
        <path d="M19.2876 15.1344C18.6829 15.1657 18.1303 15.1552 16.7956 14.759C14.825 14.1856 14.3454 14.6235 13.0525 15.8017C12.9586 15.8851 12.8544 15.979 12.7501 16.0728C12.5416 16.2605 12.5103 16.6045 12.698 16.8757C12.8127 17.0425 13.2714 17.3136 13.6781 16.9695C14.9397 15.9164 15.8677 16.0311 16.9834 16.3022C17.8696 16.5107 18.7871 16.7401 19.7673 16.2605C20.1635 16.0728 20.2678 15.7913 20.1739 15.4889C20.0592 15.1135 19.7151 15.1031 19.2876 15.1344Z" fill="url(#paint8_linear_4_335)"/>
        <path d="M25.2622 13.5704C25.8669 13.5182 26.3779 13.3618 27.6812 12.8301C29.2973 12.1732 30.4025 12.5068 31.5287 13.3097C31.6329 13.3827 31.7476 13.4557 31.8727 13.5391C32.1126 13.6955 32.1855 14.0396 32.0395 14.3211C31.9561 14.4983 31.5287 14.832 31.0803 14.5609C29.6727 13.7059 28.776 13.9561 27.7125 14.3837C26.8679 14.7278 25.9921 15.0822 24.9494 14.7486C24.5323 14.613 24.3864 14.3628 24.4281 14.05C24.4906 13.6747 24.8347 13.6121 25.2622 13.5704Z" fill="url(#paint9_linear_4_335)"/>
        <defs>
            <radialGradient id="paint0_radial_4_335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.9562 15.3697) scale(20.1255 20.1255)">
                <stop stopColor="#FFE030"/>
                <stop offset="1" stopColor="#FFB92E"/>
            </radialGradient>
            <radialGradient id="paint1_radial_4_335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.9562 15.3697) scale(15.8336 15.8336)">
                <stop stopColor="#FFEA5F"/>
                <stop offset="1" stopColor="#FFBC47" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint2_radial_4_335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.3043 20.4741) rotate(73.8539) scale(2.35714 1.52503)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <linearGradient id="paint3_linear_4_335" x1="16.7916" y1="17.9713" x2="16.7916" y2="20.2354" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <radialGradient id="paint4_radial_4_335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.1984 20.4745) rotate(73.8539) scale(2.35714 1.52503)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <linearGradient id="paint5_linear_4_335" x1="27.6853" y1="18.0311" x2="27.6853" y2="20.2952" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <radialGradient id="paint6_radial_4_335" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.2387 28.6221) scale(5.54981 0.998413)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <linearGradient id="paint7_linear_4_335" x1="14.4291" y1="28.261" x2="30.0483" y2="28.261" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <linearGradient id="paint8_linear_4_335" x1="16.4283" y1="17.7716" x2="16.3588" y2="14.7131" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#7A4400"/>
            </linearGradient>
            <linearGradient id="paint9_linear_4_335" x1="28.2652" y1="15.727" x2="28.2652" y2="12.8042" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#7A4400"/>
            </linearGradient>
        </defs>
    </svg>
)

