export const phrasesEn = {
    "phrases": {
        "add_phone_number": "Add Phone Number",
        "add_email_address": "Add Email Address",
        "reset_filters": "Reset Filters",
        "search_recent_calls": "Search Recent Calls",
        "click_to_dial": "Click to Dial",
        "add_meeting_details": "Add meeting details",
        "copy_invitation": "Copy Invitation",
        "hide_meeting_invitation": "Hide Meeting Invitation",
        "show_meeting_invitation": "Show Meeting Invitation",
        "start_typing_user": "Start typing user...",
        "start_typing_to_search": "Start typing to search...",
        "invite_participants": "Invite participants",
        "remove_participant": "Remove participant",
        "set_as_admin": "Set as admin",
        "unset_as_admin": "Unset as admin",
        "resend_verification_code": "Re-send Verification Code",
        "enter_the_verification_code": "Enter the Verification Code",
        "download_desktop_app": "Download Desktop App",
        "login_with_company_code": "Login with Company Code",
        "login_with_microsoft": "Login with Microsoft",
        "login_with_google": "Login with Google",
        "choose_account_to_login": "Choose account to login",
        "choose_user_to_login": "Choose user to login",
        "login_with_qr_code": "Login with QR code",
        "scan_this_with_%name_mobile_app_to_login_instantly": "Scan this with %{name} mobile app to login instantly",
        "scan_desktop_qr_code": "Scan Desktop QR Code",
        "enter_company_code": "Enter Company Code",
        "enter_your_name_and_email": "Enter your name and email.",
        "leaving": "Leaving",
        "parking_slots_are_not_set": "Parking slots are not set",
        "parked_calls": "Parked Calls",
        "missed_call": "Missed call |||| Missed Calls",
        "phonebook_not_selected": "Phonebook is not selected",
        "no_message": "No Message |||| No Messages",
        "loading_audio": "Loading Audio...",
        "loading_phonebooks": "Loading Phonebooks",
        "new_tab": "New Tab",
        "same_tab": "Same Tab",
        "no_sound": "No Sound",
        "waiting_beep": "Waiting Beep",
        "sms_id_not_set": "SMS ID is not set",
        "whispering_to_%n": "Whispering do %{n} ",
        "joining_to_%n": "Joining to %{n}",
        "listening_to_%n": "Listening to %{n}",
        "contact_details_favourite": "Contact details favourite",
        "contact_account_admin_to_use_parking_features": "Contact account admin to use parking features.",
        "your_admin_has_not_enabled_chat_for_this_user": "Your admin has not enabled a chat feature for this user.",
        "scroll_up_for_more_messages": "Scroll up for more messages",
        "this_is_the_start_of_new_thread": "This is the start of new thread",
        "this_is_the_start_of_the_%name_group messages": "This is the start of the %{name} group messages",
        "this_is_the_start_of_the_conversation_with_%name": "This is the start of the conversation with %{name}.",
        "your_meetings": "Your meetings",
        "you_have_no_meetings": "You have no meetings",
        "no_open_meetings": "No open meetings",
        "open_meetings": "Open Meetings",
        "message_details": "Message details",
        "this_message_was_deleted": "This message was deleted",
        "are_you_sure": "Are you sure?",
        "chat_pinned": "Chat Pinned",
        "all_people": "All people",
        "change_will_take_effect_on_next_reload": "Change will take an effect on next reload",
        "new_chat": "New Chat",
        "new_call": "New Call",
        "having_a_think": "Having a think...",
        "type_in_the_box_above_to_filter_through_the_messages_in_this_chat":  "Type in the box above to filter through the messages in this chat",
        "no_messages_containing_%name_found": "No messages containing \"%{name}\" found.", // TODO
        "in_group": "In group",
        "no_chats": "No Chats",
        "no_results_match_your_search": "No results match your search",
        "parking_slot_is_available": "Parking slot is available",
        "force_waiting_room": "Force waiting room",
        "new_message_%c": "%{smart_count} New Message |||| %{smart_count} New Messages",
        "loading_your_chats":  "Loading your chats...",
        "error_group_subject_required": "Group subject is required",
        "group_subject": "Group Subject",
        "new_group": "New Group",
        "back_to_group_user_selection": "Back to the group user selection",
        "back_to_single_chat_selection": "Back to the single chat selection",
        "back_to_chats": "Back to chats",
        "getting_messages": "Getting Messages",
        "meeting_details": "Meeting Details",
        "new_meeting": "New Meeting",
        "go_to_%name_meeting": "Go to '%{name}' meeting",
        "allow_admins_to_mute_others": "Allow admins to mute others",
        "mute_participants_on_entry": "Mute participants on entry",
        "is_room_open": "Is room open",
        "hide_advanced_settings": "Hide advanced settings",
        "show_advanced_settings": "Show advanced settings",
        "meeting_description": "Meeting description",
        "meeting_password": "Meeting password",
        "meeting_name": "Meeting name",
        "%name_is_inviting_you_to_a_meeting_room": "%{name} is inviting you to a meeting room",
        "join_%name_video": "Join %{name} Video",
        "no_invitations": "No invitations",
        "invited_users": "Invited Users",
        "invitation_copied": "Invitation Copied",
        "meeting_admin": "Meeting Admin",
        "we_have_sent_a_verification_code_to_your_cell_number_%number": "We have sent a verification code to your cell number %{number}",
        "we_have_sent_a_verification_code_to_your_mobile_number_%number": "We have sent a verification code to your mobile number %{number}",
        "sent_verification_code_to_your_email_%email": "We have sent a verification code to your email %{email}",
        "please_contact_support_at_%number_or_%email": "Please contact support at %{number} or %{email}",
        "for_best_experience_please_use": "For the best experience please use:",
        "password_is_now_reset": "Password is now reset",
        "mobile_currently_not_supported": "Mobile is currently not supported",
        "please_download_app_from_store": "Please download app from the store",
        "loading": "Loading",
        "logging_in": "Logging in",
        "no_users": "No users",
        "you_can_change_user_in_settings": "You can change users in settings.",
        "you_can_change_account_in_settings": "You can change account in settings.",
        "dont_have_login": "Don't have a login?",
        "forgotten_password": "Forgotten password?",
        "warning": "WARNING!",
        "please_enter_login_details": "Please enter login details.",
        "please_select_way_to_login": "Please select way to login.",
        "member_login": "Member login",
        "incorrect_email_or_password_try_again": "Incorrect email or password! Please try again.",
        "pending_admin_approval": "Pending admin approval",
        "account_pending": "Account pending",
        "check_later_or_contact_account_holder": "Check later or contact the account holder",
        "so_we_can_assign_you_a_user": "So that we can assign you a user.", // TODO
        "invalid_company_code": "Invalid Company Code",
        "where_can_find_this": "Where can I find this?",
        "have_you_been_given_company_code": "Have you been given the Company Code?",
        "dont_have_company_code": "Don't have a Company Code?",
        "lets_go": "Let's go",
        "resend_code_in_%count_seconds": "Resend code in %{smart_count} second |||| Resend code in %{smart_count} seconds",
        "did_not_receive_code": "Did not receive code?",
        "please_enter_6_digit_code_we_sent_to_%number": "Please enter 6-digit code we sent to %{number}",
        "enter_mobile_number": "Enter mobile number",
        "to_active_%name_app_enter_mobile_number": "To activate your %{name} app, please enter mobile number",
        "have_a_dashboard_login": "Have a dashboard login?",
        "%count_unread": "%{smart_count} Unread",
        "dnd_enabled": "DND Enabled",
        "login_as_member": "Login as member",
        "to_make_call_enable_microphone_settings": "To make call, enable microphone settings.",
        "%name_is_now_available": "%{name} is now available",
        "audio_device_changed": "Audio device changed!",
        "go_to_mailbox": "Go to Mailbox",
        "forward_calls_to": "Forward calls to",
        "go_to_settings_to_change_audio_preferences": "Go to settings to change audio preferences.",
        "what_was_the_mood_of_this_call": "What was the mood of this call?",
        "call_ended": "Call Ended",
        "on_hold": "On hold",
        "return_to_phone": "Return to phone",
        "send_to_mailbox": "Send to Mailbox",
        "assign_call_route": "Assign Call Route",
        "change_active_call_route_on_number": "Change active call route on a number",
        "modify_outgoing_caller_id_to_unknown": "Modify outgoing caller ID to Unknown",
        "modify_outgoing_caller_id_to_%name": "Modify outgoing caller ID to %{name}",
        "play_sound": "Play Sound",
        "hang_up": "Hang Up",
        "park_or_retrieve_call_in_parking_slot": "Park or retrieve call in parking slot %{slot}",
        "join_live_call_taking_place_on_an_extension": "Join live call taking place on an extension",
        "global_pickup": "Global pickup",
        "pick_up_any_incoming_call": "Pick up any incoming call",
        "listen_to_an_extension": "Listen to an extension",
        "park_retrieve": "Park retrieve",
        "park_or_resume_a_call": "Park or resume a call",
        "pick_up_an_incoming_call_to_an_extension": "Pick up an incoming call to an extension",
        "play_an_audio_file_to_the_caller": "Play an audio file to the caller",
        "record_new_audio_file": "Record new audio file",
        "set_caller_id": "Set Caller ID",
        "modify_outgoing_caller_id": "Modify outgoing caller ID",
        "toggle_queue_group_availability": "Toggle queue group availability",
        "toggle_queue_group_availability_for_extension": "Toggle queue group availability for extension",
        "toggle_recording": "Toggle Recording",
        "pause_and_resume_call_recording": "Pause and resume call recording",
        "whisper_to_the_user_on_extension": "Whisper to the user on extension",
        "clear_queue_timeout": "Clear queue timeout",
        "clears_your_queue_timeout_to_allow_answering": "Clears your queue timeout to allow answering.",
        "change_active_call_route_%name_to_call_route": "Change active call route %{name} to call route",
        "change_active_call_route_%name_to_time_diary": "Change active call route %{name} to time diary",
        "close_keypad": "Close Keypad",
        "open_keypad": "Open Keypad",
        "call_%count": "(%{smart_count}) Call |||| (%{smart_count}) Calls",
        "hide_tab": "Hide Tab",
        "ringing": "Ringing",
        "single_call": "Single Call",
        "making_group_call": "Making Group Call",
        "no_call_id": "No call ID",
        "invite_to_group": "Invite to Group",
        "create_group_call": "Create Group Call",
        "make_group": "Make Group", // TODO
        "initialising_conference_call": "Initialising conference call",
        "call_customer_support": "Call Customer Support",
        "my_devices": "My devices",
        "this_device": "This device",
        "show_devices": "Show Devices",
        "hide_devices": "Hide Devices",
        "minutes_remaining": "Minutes remaining",
        "available_in_call_queues": "Available in Call Queues",
        "allow_internal_calls": "Allow Internal Calls",
        "show_my_caller_id": "Show my caller ID",
        "record_call": "Record Call |||| Record Calls",
        "caller_id_hidden_anonymous": "Caller ID hidden (Anonymous)",
        "drag_and_drop_to_upload_image": "Drag and drop to upload image",
        "call_waiting_beep": "Call waiting beep",
        "auto_connect_click_to_dial": "Auto connect Click to Dial",
        "bring_app_to_front": "Bring app to Front",
        "app_to_front": "App to Front",
        "show_call_handling": "Show call handling",
        "show_advanced_call_handling": "Show advanced call handling",
        "use_advanced_call_handling": "Use advanced call handling",
        "activate_call_handling_routes": "Active call handling routes",
        "call_transfer_and_parking": "Call Transfer and Parking",
        "display_phonebooks_when_transferring": "Display phonebooks when transferring",
        "phonebooks_in_transfers": "Phonebooks in Transfers",
        "transfer_preference": "Transfer Preference",
        "blind_transfer_description_p1": "The transfer can be made as a blind transfer or an assisted transfer:",
        "blind_transfer_description_p2": "Instantly transfer a call to another person without notification.",
        "blind_transfer_description_p3": "Talk to the other person before the call transfer.",
        "long_press_hold_to_park": "Long press 'Hold' to park", // TODO
        "hold_to_park": "Hold to Park",
        "show_parking_slot_after_parking_a_call": "Show parking slot after parking a call",
        "sms_sending_number": "SMS sending number",
        "select_message_id": "Select Message ID",
        "deselect_message_id": "Deselect Message ID",
        "nothing_to_select_from": "Nothing to select from",
        "press_enter_to_send_message": "Press Enter to send message",
        "chat_notifications": "Chat Notifications",
        "notification_volume": "Notification Volume",
        "select_phonebook": "Select phonebook",
        "dont_use_phonebook": "Don't use phonebook",
        "selected_phonebooks": "Selected Phonebooks",
        "sort_by_last_name": "Sort by last name",
        "show_contact_company_name": "Show contact Company Name",
        "contact_view": "Contact View",
        "pop_crm_on_call": "Pop CRM on Call",
        "show_hunt_groups_in_contacts": "Show hunt groups in contacts",
        "ringtone_and_loudspeaker_audio": "Ringtone & Loudspeaker",
        "no_devices": "No Devices",
        "no_contacts": "No Contacts",
        "in_call_audio": "In Call Audio",
        "app_language": "App Language",
        "advanced_call_handling": "Advanced Call Handling",
        "in_case_of_blind_transfer": "In case of blind transfer",
        "follow_call_handling": "Follow call handling", // TODO
        "return_to_sender": "Return to the sender",
        "when_busy": "When busy",
        "when_busy_description": "If we cannot get through to you because you are on another call or meeting.", // TODO
        "unanswered_call": "Unanswered Call",
        "enter_name_or_number": "Enter Name or Number",
        "add_people_to_create_group": "Add people to create a group",
        "customer_support": "Customer Support",
        "system_default": "System Default",
        "%count_digits_left": "%{smart_count} digit left |||| %{smart_count} digits left",
        "call_assurance": "Call Assurance",
        "call_assurance_description": "Full control over acceptance of forwarded calls.",
        "music_on_hold": "Music on hold",
        "music_on_hold_description": "Make sure your business sounds the best with our hosted VoIP music on-hold features.",
        "call_encryption": "Call Encryption",
        "call_encryption_description": "Increased security for voice calls on your phone line.",
        "call_history": "Call History",
        "call_history_description": "See full histories of calls made and received, via your online dashboard.",
        "voicemail": "Voicemail",
        "voicemail_description": "Create voicemail mailboxes for individual users or teams.",
        "hd_quality_calls": "HD Quality Calls",
        "hd_quality_calls_description": "Unrivalled high definition audio calls via our office phone system.",
        "download_app_latest_version": "Download app latest version",
        "perfect_way_to_stay_in_contact": "The Perfect way to stay in contact with your team", // TODO spans
        "about_%name": "About %{name}!",
        "terms_and_conditions": "Terms and Conditions",
        "privacy_policy": "Privacy Policy",
        "no_call_history": "No call history",
        "when_i_dont_answer": "When I don't answer",
        "when_i_dont_answer_description": "If we ring you and you don’t pick up the phone.",
        "when_i_am_unreachable": "When I am unreachable",
        "when_unreachable_description": "If we cannot reach you (i.e. your internet goes down) or connect to your number.",
        "want_to_add_more_detail": "Want to add more details?",
        "edit_call_routes_in_dashboard": "Edit call routes in the dashboard!",
        "presence_available": "Available",
        "presence_ringing": "Ringing",
        "presence_busy": "Busy",
        "presence_do_not_disturb": "Do Not Disturb",
        "presence_online": "Online",
        "presence_offline": "Offline",
        "last_seen_today_at_%time": "Last Seen Today at %{time}",
        "last_seen_yesterday_at_%time": "Last Seen Yesterday at %{time}",
        "last_seen_%day_at_%time": "Last Seen %{day} at %{time}",
        "last_seen_%day": "Last Seen %{day}",
        "label_main": "Main",
        "label_home": "Home",
        "label_work": "Work",
        "label_other": "Other",
        "label_mobile": "Mobile",
        "label_office": "Office",
        "error_meeting_id_taken": "This meeting ID is not available",
        "error_no_invite_found": "No invite found! Please contact the account admin",
        "error_verification": "Oh no! It looks like there was a problem with the verification!",
        "error_unable_to_make_conference_call": "Unable to make conference for this call",
        "error_input_required": "This input is required!",
        "error_passwords_must_match": "Passwords must match",
        "error_generic_try_again": "Something went wrong! Please try again.",
        "error_password_must_contain": "Password must contain at least 8 characters, 1 number and special character $@!%*#?&", // TODO
        "error_failed_to_login": "Failed to login. Please contact technical support.",
        "error_incorrect_email_or_password": "Incorrect Email or Password",
        "error_invalid_company_code": "Invalid Company Code",
        "error_invalid_email": "Invalid Email",
        "error_offline_reconnect": "You are offline, please reconnect.",
        "error_file_image_format": "File must be an image",
        "error_failed_image_upload": "Failed to upload an image",
        "error_invalid_number": "Invalid mobile number",
        "error_incorrect_email": "Incorrect Email",
        "error_incorrect_number": "Incorrect Number",
        "error_incorrect_code": "Incorrect Code",
        "error_invalid_verification_code": "Invalid Verification Code",
        "error_first_name_required": "First name is required",
        "error_last_name_required": "Last name is required",
        "error_phonebook_required": "Phonebook is required",
        "error_number_required": "Phone number is required",
        "error_number_and_label_required": "Phone number and label are required",
        "error_generic_required": "Required",
        "error_meeting_url_length": "Meeting ID must be at least 3 Characters long",
        "error_loading_message": "There was an error loading this message",
        "error_chat_error_generic_1": "Oops! Looks like something went wrong!",
        "error_chat_error_generic_2": "Don't worry, we're on the case! Try reloading the app to get started with chat again",
        "error_unable_to_save_feedback_please_try_again": "Unable to save feedback, please try again later",
        "error_unable_to_save_contact_please_ty_again_later": "Unable to save contact, please try again later",
        "error_unable_to_delete_contact_please_ty_again_later": "Unable to delete contact, please try again later",
        "no_result": "No Result",
        "message_delivered": "Message Delivered",
        "message_read": "Message Read",
        "message_sent": "Message Sent",
        "message_deleted": "Message Deleted",
        "no_valid_numbers_contact": "No valid numbers on this contact",
        "add_%name_to_group_call": "Add %{name} to group call",
        "new_call_with_%name": "New call with: %{name}",
        "click_to_clear": "Click to clear",
        "pick_your_emoji": "Pick your emoji...",
        "update_available_title": "New Updates available!",
        "update_available_download_desc": "To enjoy the latest features, please download the update.",
        "update_available_refresh_desc": "To enjoy the latest features, please refresh the page.",
        "pick_up_call": "Pick Up Call",
        "pick_up_%name": "Pick Up %{name}",
        "chat_in_progress": "Chat in progress",
        "sent_message": "Sent a message",
        "%user_is_typing": "%{user} is typing...",
        "%user1_and_%user2_are_typing": "%{user1} and %{user2} are typing...",
        "%users_and_%count_others_are_typing": "%{users} and %{smart_count} others are typing...",
        "drop_files_here": "Drop Files here!",
        "speaker_not_found": "Speaker not found",
        "speaker_system_default": "System default speaker device",
        "mic_not_found": "Microphone not found",
        "mic_blocked": "Microphone blocked",
        "camera_not_found": "Camera not found",
        "camera_blocked": "Camera blocked",
        "this_is_start_chat": "This is the start of a new chat",
        "this_is_start_chat_%name": "This is the start of a new chat with %{name}",
        "this_is_start_thread": "This is the start of a new thread",
        "added_to_chat_%name": "You've just been added to %{name}",
        "added_to_group_chat": "You've just been added to the Group Chat",
        "integration_chat": "Integration Chat",
        "sent_file": "Sent a file",
        "new_message": "New Message",
        "sending": "Sending",
        "create_your_%name_account": "Create Your %{name} Account",
        "advanced_call_routes_configured_p1": "You have advanced call routes configured for this. To edit these, go to",
        "advanced_call_routes_configured_p2": "call routes",
        "advanced_call_routes_configured_p3": "Alternatively, if you want a simple call handling route, you can",
        "advanced_call_routes_configured_p4": "reset this route",
        "global_recording_set_by_admin": "Global Call recording Has been Set By Your Admin",
        "message_transcription": "Message Transcription",
        "stop_voicemail_message": "Stop Voicemail Message",
        "play_voicemail_message": "Play Voicemail Message",
        "download_file": "Download File",
        "cancel_message_reply": "Cancel message reply",
        "preview_audio": "Preview Audio",
        "close_audio_preview": "Close Audio Preview",
        "file_icon": "File Icon",
        "file_audio_icon": "File Audio Icon",
        "no_receipts": "No Receipts",
        "message_info": "Message Info",
        "avatar_preview": "Avatar Preview",
        "message_was_deleted": "This message was deleted",
        "other_os": "Other OS",
        "other_browser": "Other Browser",
        "voip_service_provider": "VoIP Service Provider",
        "making_business_easy": "Making Business Easy",
        "google_playstore_link": "google play store link",
        "apple_playstore_link": "apple store link",
        "contact_yay": "Contact Yay.com",
        "contact_us": "Contact us",
        "voip_domain_questions": "VoIP & Domain Questions",
        "frequent_questions": "Frequent Questions",
        "review_testimonials": "Reviews and Testimonials",
        "policy_accreditations": "Policy & Accreditations",
        "about_us": "About us",
        "about_yay": "About Yay.com",
        "help_and_about": "Help & About",
        "domain_name_api_docs": "Domain Name API Docs",
        "voip_api_docs": "VoIP API Docs",
        "phone_system_integration": "Phone System Integrations",
        "domain_name_reseller": "Domain Name Reseller",
        "domain_name_registration": "Domain Name Registration",
        "bulk_domain_search": "Bulk Domain Name Search",
        "domain_registration_pricing": "Domain Registration Pricing",
        "domain_backorders": "Domain Backorders",
        "domain_transfer": "Domain Transfer",
        "domain_name_search": "Domain Name Search",
        "domain_names": "Domain Names",
        "business_phone_system": "Business Phone Systems",
        "voip_free_trial": "VoIP Free 14 Day Trial",
        "hosted_voice_reseller": "Hosted Voice Reseller",
        "download_yay_apps": "Download Yay.com Apps",
        "voice_features": "Voice Features",
        "call_costs": "Call Costs",
        "compare_voip_plans": "Compare Voice Plans",
        "trigger_mobile_menu": "Trigger Mobile Menu",
        "international_phone_numbers": "International Phone Numbers",
        "buy_international_phone_numbers": "Buy International Phone Numbers",
        "transfer_phone_number": "Transfer your Phone Number",
        "transfer_number_to_voip": "Transfer Your Phone Number To VoIP",
        "all_phone_numbers": "All Phone Numbers",
        "%area_phone_numbers": "%{area} Numbers",
        "buy_%area_phone_numbers": "Buy %{area} Phone Numbers",
        "add_people_to_invite": "Add People to Invite",
        "call_with_%name_queued": "Call with %{name} Queued",
        "manage_your_account_and_general_settings": "Manage your account settings and general preferences.",
        "view_your_account_details": "View your account details, active devices list, minutes remaining, and availability.",
        "choose_your_theme": "Choose your preferred theme for your interface appearance.",
        "choose_your_language_and_region": "Select your preferred language to use throughout the application.",
        "adjust_your_call_settings": "Adjust your settings for outbound/inbound calls, and call transfer & parking.",
        "manage_your_outbound_call_settings": "Manage your outbound calls preferences. Set your caller ID and control call recording.",
        "manage_your_inbound_call_settings": "Manage your settings for inbound calls.",
        "manage_your_advanced_call_settings": "Manage your advanced call handling preferences.",
        "manage_your_call_transfer_parking_preferences": "Manage your call transfer and calling parking preferences.",
        "customise_your_chat_experience": "Customise your chat experience and manage your outbound SMS ID.",
        "decide_your_outbound_number": "Decide what number you present when sending SMS messages from Chat.",
        "customise_the_chat_interface": "Customise the user interface for chat messages.",
        "make_changes_to_notifications": "Make changes to how notifications are presented within the App.",
        "set_your_user_status": "Set your status as Do Not Disturb to stop notifications appearing in the App.",
        "set_whether_you_want_notifications": "Set whether you want to receive chat notifications, and set notification volume.",
        "configure_how_contacts_are_presented": "Configure how your contacts are presented within the App.",
        "customise_how_contacts_are_arranged": "Customise how contacts are arranged within your phonebook.",
        "decide_which_phonebook_to_present": "Decide which phonebook to present, and customise your view.",
        "manage_crm_integration": "Manage settings for your CRM integration.",
        "manage_audio_settings": "Manage the audio output settings and input devices for the App.",
        "choose_notification_audio_output": "Choose which audio output will play notifications.",
        "choose_call_audio_output": "Choose which audio output to use during calls.",
        "choose_call_audio_input": "Choose which input device to use during calls.",
        "for_more_control_edit_call_routes": "For more control, edit call routes in the Dashboard.",
        "to_you": "To You",
        "choose_your_preferred_chat_style": "Choose your preferred chat style.",
        "line_key_configuration": "Line Key Configuration",
        "edit_line_key_%key": "Edit Line Key %{key}",
        "edit_save_profile_picture": "Edit / Save Profile Picture",
        "pop_linked_crm_on_call": "Pop Linked CRM on Call",
        "crm_for_contact_pop": "CRM for Contact Pop",
        "selected_crms": "Selected CRMs",
        "no_linked_crms_to_select": "No Linked CRMs to select",
        "no_linked_crms_support_call_popping": "No Linked CRMS support call popping",
        "icon_updated": "Icon Updated",
        "group_icon_changed": "Group Icon Changed",
        "your_users_icon_has_been_updated": "Your User's Icon has been updated",
        "refresh_for_new_icon": "Refresh for new icon",
        "transfer_to": "Transfer to:",
        "scroll_to_bottom": "Scroll to bottom",
        "extra_options": "Extra Options",
        "%count_participants": "(%{count}) Participants",
        "close_chat_details": "Close Chat Details",
        "close_line_key_configuration": "Close line key configuration",
        "line_key_%n": "Line Key %{n}",
        "search_members": "Search Members",
        "this_action_will_delete_the_group_permanently": "This action will delete the group permanently",
        "upload_a_new_image": "Upload a New Image",
        "file_types_png_and_jpeg_up_to_4mb" : 'File types: .png and .jpeg up to 4MB',
        "change_group_icon": "Change Group Icon",
        "add_members_to_%name": "Add Members to %{name}",
        "none_set": "None Set",
        "pop_on_answered": "Pop on Answered",
        "prioritise_active_call": "Prioritise Active Call",
        "connected_headset": "Connected Headset",
        "headset_detected": "Headset Detected",
        "previously_selected_device_detected": "Previously selected device detected:",
        "search_to_start_an_individual_or_a_group_chat": "Search to start an individual or a group chat",
        "my_external_numbers": "My External Numbers",
        "assign_external_numbers": "Assign numbers to your account that can be used to handle outbound calls (via internal transfer).",
        "register_number": "Register Number",
        "refreshing_the_app_may_fix_this": "Refreshing the app may fix this.",
        "problem_persists_contact": "If the problem persists, please contact us.",
        "contact_us_ref": "contact us"
    },
    "terms": {
        "keypad": "Keypad",
        "conference_call": "Conference Call |||| Conference Call",
        "sip_user": "SIP User |||| SIP Users",
        "email_address": "Email Address",
        "video": "Video",
        "contact": "Contact |||| Contacts",
        "contact_%count": "%{smart_count} contact |||| %{smart_count} contacts", // TODO
        "user": "User |||| Users",
        "phonebook": "Phonebook |||| Phonebooks",
        "call": "Call |||| Calls",
        "chat": "Chat |||| Chats",
        "call_history": "Call History",
        "history": "History",
        "mail_box": "Mail Box |||| Mail Boxes",
        "tab": "Tab |||| Tabs",
        "parking_slot": "Parking Slot |||| Parking Slots",
        "message": "Message |||| Messages",
        "conference": "Conference |||| Conferences",
        "reaction": "Reactions |||| Reactions",
        "attachment": "Attachment |||| Attachments",
        "audio": "Audio",
        "preview": "Preview",
        "thread": "Thread |||| Threads",
        "creator": "Creator |||| Creators",
        "admin": "Admin |||| Admins",
        "group": "Group |||| Groups",
        "desktop": "Desktop",
        "outbound_call": "Outbound Call |||| Outbound Calls",
        "inbound_call": "Inbound Call |||| Inbound Calls",
        "caller_id": "Caller ID",
        "speaker": "Speaker |||| Speakers",
        "microphone": "Microphone |||| Microphones",
        "camera": "Camera |||| Cameras",
        "input_device": "Input Device |||| Input Devices",
        "filter": "Filter |||| Filters",
        "internal_user": "Internal User |||| Internal Users",
        "hunt_group": "Hunt Group |||| Hunt Groups",
        "phonebook_contact": "Phonebook Contact |||| Phonebook Contacts",
        "phone_number": "Phone number |||| Phone numbers",
        "phone_number_%count": "%{smart_count} Phone number |||| $%{smart_count} Phone numbers |||| $%{smart_count} Phone numbers",
        "parking": "Parking",
        "company": "Company |||| Companies",
        "label": "Label |||| Labels",
        "value": "Value |||| Values",
        "meeting_id": "Meeting ID",
        "password": "Password",
        "dark_mode": "Dark Mode",
        "light_mode": "Light Mode",
        "theme": "Theme |||| Themes",
        "email": "Email",
        "verification": "Verification",
        "call_note": "Call Note |||| Call Notes",
        "call_route": "Call Route |||| Call Routes",
        "time_diary": "Time Diary |||| Time Diaries",
        "setting": "Setting |||| Settings",
        "account": "Account |||| Accounts",
        "notification": "Notification |||| Notifications",
        "short_code": "Short Code |||| Short Codes",
        "type": "Type |||| Types",
        "group_call": "Group Call |||| Group Calls",
        "full_name": "Full Name",
        "last_name": "Last Name",
        "first_name": "First Name",
        "group_chat": "Group Chat |||| Group Chats",
        "transcription": "Transcription",
        "receipt": "Receipt |||| Receipts",
        "voice": "Voice",
        "integration": "Integration |||| Integrations",
        "review": "Review |||| Reviews",
        "language_and_region": "Language & Region",
        "account_and_general": "Account & General",
        "call_settings": "Call Settings",
        "chat_settings": "Chat Settings",
        "sms_id": "SMS ID",
        "accessibility": "Accessibility",
        "compact_view": "Compact View",
        "sms_outbound_number": "SMS Outbound Number",
        "notifications_settings": "Notifications Settings",
        "user_status": "User Status",
        "system_notification": "System Notification |||| System Notifications",
        "contacts_settings": "Contacts Settings",
        "visibility_and_sorting": "Visibility & Sorting",
        "crm_integration": "CRM Integration",
        "audio_settings": "Audio Settings",
        "standard": "Standard",
        "compact": "Compact",
        "chat_view": "Chat View",
        "custom": "Custom",
        "page_%cur_of_%total": "Page: %{cur} of %{total}",
        "call_feedback": "Call Feedback",
        "line_key": "Line Key |||| Line Keys",
        "volume": "Volume",
        "blf": "BLF",
        "speed_dial": "Speed Dial",
        "none": "None",
        "favourite": "Favourite |||| Favourites",
        "department": "Department |||| Departments",
        "applied_filter" : "Applied Filter |||| Applied Filters",
        "extension": "Extension |||| Extensions",
        "number": "Number |||| Numbers"
    },
    "adjective": {
        "selection_empty": "Empty",
        "transfer_blind": "Blind",
        "transfer_attended": "Attended",
        "minutes_unlimited": "Unlimited",
        "contact_primary": "Primary",
        "chats_individual": "Individual",
        "message_copied": "Copied",
        "caller_id_anonymous": "Anonymous",
        "call_unanswered": "Unanswered",
        "call_parked": "Parked",
        "call_internal": "Internal",
        "call_inbound": "Inbound",
        "call_outbound": "Outbound",
        "chat_unknown": "Unknown",
        "callee_unknown": "Unknown",
        "shortcode_unknown": "Unknown",
        "user_unknown": "Inactive User",
        "chats_misc": "Misc",
        "chats_unread": "Unread",
        "chats_read": "Read",
        "chats_pinned": "Pinned",
        "chats_muted": "Muted",
        "chats_sms": "SMS",
    },
    "actions": {
        "go_next": "Next",
        "call_when_available": "Call when available",
        "cancel_arranged_call": "Cancel arranged call",
        "add_attachment": "Add attachment |||| Add attachments",
        "mark_as_read": "Mark as Read",
        "mark_as_unread": "Mark as Unread",
        "mute_notifications": "Mute Notifications",
        "unmute_notifications": "Unmute Notifications",
        "cancel_preview": "Cancel Preview",
        "start_call": "Start Call",
        "send_message": "Send Message",
        "record_audio": "Record Audio",
        "stop_recording_audio": "Stop Recording Audio",
        "click_to_park": "Click to Park",
        "switch_user": "Switch User",
        "change_profile_photo": "Change profile image",
        "add_contact": "Add Contact |||| Add Contacts",
        "switch_to_grid_view": "Switch to Grid View",
        "switch_to_list_view": "Switch to List View", // TODO
        "search_contacts": "Search Contacts",
        "remove_from_group": "Remove from the group",
        "view_user": "View User",
        "view_hunt_group": "View Hunt Group",
        "view_contact": "View Contact",
        "edit_contact": "Edit Contact",
        "send_sms": "Send SMS",
        "add_to_favourites": "Add to favourites",
        "remove_from_favourites": "Remove from favourites",
        "join_call": "Join call",
        "add_to_call": "Add to call",
        "type_a_message": "Type a Message",
        "add_reaction": "Add Reaction",
        "transfer_call": "Transfer call",
        "add_for_group_call": "Add for a group call",
        "return_to_chat": "Return to Chat",
        "search_for_meeting": "Search for a meeting",
        "copy_message": "Copy message",
        "reply_in_thread": "Reply in Thread",
        "reply_privately": "Reply Privately",
        "confirm_group_call": "Confirm group call",
        "start_group_call": "Start group call",
        "delete_chat": "Delete Chat",
        "mute_chat": "Mute Chat",
        "add_user": "Add User |||| Add Users",
        "show_less": "Show less",
        "show_all": "Show all",
        "search_chat": "Search Chat",
        "search_thread": "Search Thread",
        "pin_chat": "Pin Chat",
        "unpin_chat": "Unpin Chat",
        "pin_thread": "Pin Thread",
        "unpin_thread": "Unpin Thread",
        "mark_all_as_read": "Mark all as read",
        "go_to_chats": "Go to chats",
        "go_to_threads": "Go to threads",
        "remove_image":  "Remove Image",
        "add_group_icon": "Add group icon",
        "create_group": "Create Group",
        "select_user": "Select User |||| Select Users",
        "view_meeting": "View Meeting",
        "edit_meeting": "Edit Meeting",
        "join_meeting": "Join Meeting",
        "create_meeting": "Creat Meeting",
        "delete_meeting": "Delete Meeting",
        "view_meeting_details": "View Meeting Details ",
        "enter_details": "Enter Details",
        "save_changes": "Save Changes",
        "exit_page": "Exit page",
        "show_to_all": "Show to all",
        "confirm_password": "Confirm Password",
        "create_password": "Create Password",
        "start_again": "Start Again",
        "select_account": "Select Account",
        "select_contact": "Select Contact",
        "create_account": "Create Account",
        "go_to_settings": "Go to Settings",
        "add_note": "Add note |||| Add notes",
        "add_tag": "Add Tag |||| Add Tags",
        "forward": "Forward",
        "mute": "Mute",
        "hold": "Hold",
        "kick": "Kick",
        "cancel": "Cancel",
        "edit": "Edit",
        "disconnect": "Disconnect",
        "connect": "Connect",
        "save": "Save",
        "clear": "Clear",
        "filter": "Filter",
        "search": "Search",
        "add": "Add",
        "call": "Call",
        "chat": "Chat",
        "message": "Message",
        "listen": "Listen",
        "whisper": "Whisper",
        "transfer": "Transfer",
        "join": "Join",
        "delete": "Delete",
        "react": "React",
        "reply": "Reply",
        "confirm": "Confirm",
        "create": "Create",
        "submit": "Submit",
        "logout": "Log out",
        "login": "Log In",
        "reset": "Reset",
        "skip": "Skip",
        "resend": "Resend",
        "verify": "Verify",
        "barge": "Barge",
        "pick_up": "Pick up",
        "record": "Record",
        "switch": "Switch",
        "accept": "Accept",
        "hold_and_accept": "Hold & Accept",
        "decline": "Decline",
        "refresh_page": "Refresh Page",
        "call_back": "Call Back",
        "download": "Download",
        "leave_group": "Leave Group",
        "call_now": "Call Now",
        "invite": "Invite",
        "play": "Play",
        "change_image": "Change Image",
        "double_click_to_like": "Double Click To Like",
        "update": "Update",
        "open_dashboard": "Open Dashboard",
        "remove": "Remove",
        "revert_changes": "Revert Changes",
        "save_configuration": "Save Configuration",
        "clear_image": "Clear Image",
        "upload": "Upload",
        "select_linked_crm": "Select Linked CRM",
        "do_not_use_a_crm": "Do not use a CRM",
        "add_tags": "Add Tag(s)",
        "close_chat": "Close Chat",
        "next_page": "Next Page",
        "previous_page": "Previous Page",
        "start_chat": "Start Chat",
        "delete_group": "Delete Group",
        "rename_group": "Rename Group",
        "add_members": "Add Members",
        "message_%name": "Message %{name}",
        "forget": "Forget",
        "refresh": "Refresh"
    },
    "placeholders": {
        "voice_note": "Voice_Note",
        "full_name": "Jane Smith",
        "phone_number": "e.g. 077123456789",
        "email": "your@email.com",
        "password": "e.g. Password1!",
        "video_meeting_url": "e.g. support-meeting",
        "video_meeting_name": "e.g. Support Meeting"
    },
    "adverbs": {
        "never": "Never",
        "today": "Today",
        "yesterday": "Yesterday",
        "then": "Then"
    },
    "abbreviations": {
        "eg": "eg.",
        "ext": "ext.",
        "sms": "SMS",
        "dnd": "DND",
        "time_sec": "sec |||| secs",
        "time_min": "min |||| mins",
        "time_hr": "hr |||| hrs",
        "pdf": "PDF"
    },
    "conjunctions": {
        "and": "and",
        "or": "or"
    },
    "pronouns": {
        "you": "You",
        "someone": "someone",
        "me": "Me"
    },
    "prepositions": {
        "via_%name": "via %{name}",
        "on_%name": "on %{name}"
    }
}



