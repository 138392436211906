import React, {useState} from "react";
import {StyledButton} from "../../../components/StyledComponents";
import styles from "../styles.module.scss";
import Icon from "../../../components/Icon";

type CopyBtnProps = {
    text: string;
    getText?: never;
} | {
    text?: never;
    getText: () => string;
}

const CopyBtn: React.FC<CopyBtnProps> = ({text, getText}) => {
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState(false);

    const getCopyText = () => {
        if (text) {
            return text
        }

        if (getText) {
            return getText();
        }

        return "Unable To Get Data"
    }

    return (
        <StyledButton
            buttonStyle='text'
            className={styles.copy_btn}
            onClick={() => {
                navigator.clipboard.writeText(getCopyText())
                    .then(() => {
                        setCopied(true)
                    })
                    .catch(() => {
                        setError(true)
                    });

                setTimeout(() => {
                    setCopied(false)
                    setError(false)
                }, 2500);
            }}
            size='small'
            disabled={error}
            context={error ? 'danger' : undefined}
        >
            {error ? (
                <p>
                    Error
                </p>
            ) : (<>
                {copied ? (
                    <>
                        <Icon name='tick'/>
                        <p>
                            Copied
                        </p>
                    </>
                ) : (
                    <>
                        <Icon name='copy'/>
                        <p>
                            Copy
                        </p>
                    </>
                )}
            </>)}
        </StyledButton>
    )
}

export default CopyBtn;