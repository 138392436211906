import React, {useState} from "react";
import styles from "../../ThemeSelector/Themes/Previews.module.scss"
import { useAppContext } from '../../../../../../context/AppContext/AppContext';

export const Standard: React.FC = () => {
    const {isYay, isCallSwitch, currentTheme} = useAppContext();

    const [theme, setTheme] = useState(currentTheme)

    let schema;
    switch (true) {
        case isYay:
            schema = 'yddab88d'
            break;
        case isCallSwitch:
            schema = 'cef527d1'
            break;
        default:
            schema = 'pe12cd8c'
    }

    const element = document.body;

    const observer = new MutationObserver((mutations: any) => {
        mutations.forEach((mutation) =>  {
            if (mutation.type === "attributes") {
                setTheme(document.body.getAttribute("data-theme") as any);
            }
        });
    });

    observer.observe(element, {
        attributes: true
    });

    return (
        <div data-schema={schema} className={styles.container}>
            <svg
                width={156}
                height={101}
                viewBox="0 0 156 101"
                fill="none"
                role='figure'
            >
                <title id="title">Standard Chat View Icon</title>
                <g clipPath="url(#clip0_1436_12096)" data-theme={theme}>
                    <rect x="0.840942" y="0.663574" width="156" height="101" rx="4" fill="white" data-fill="primary-background"/>
                    <rect x="10.8409" y="10.6636" width="84.2798" height="31.3178" rx="4" fill="#D9D9D9" data-fill="secondary-background"/>
                    <rect x="10.8409" y="45.5574" width="84.2798" height="11.2318" rx="4" fill="#D9D9D9" data-fill="secondary-background"/>
                    <rect x="16.8726" y="16.715" width="72.2167" height="4.18339" rx="2.09169" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="16.8726" y="24.4746" width="72.2167" height="4.18339" rx="2.09169" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="16.8726" y="32.2343" width="42.8482" height="4.18339" rx="2.09169" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="16.8726" y="49.0816" width="42.8482" height="4.18339" rx="2.09169" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="64.8224" y="68.4301" width="79.0186" height="23.2335" rx="4" fill="#027FFE" data-fill="chat-background"/>
                    <rect x="70.4774" y="74.4815" width="67.7085" height="4.18339" rx="2.09169" fill="white" data-fill="chat-color" opacity="0.4"/>
                    <rect x="70.4774" y="82.2409" width="40.1734" height="4.18339" rx="2.09169" fill="white" data-fill="chat-color" opacity="0.4"/>
                </g>
            </svg>
        </div>
    )
}
