import React, {ReactNode} from 'react';
import Icon from "../../../../components/Icon";
import styles from './styles.module.scss';
import {StyledButton} from "../../../../components/StyledComponents";

interface Props {
    title: string;
    options: {
        label: string,
        value: string,
    }[];
    onDelete: (val: string) => void;
    title_btn?: ReactNode;
}

const SettingsSelectedList: React.FC<Props> = ({title, options, onDelete, title_btn}) => (
    <div className={styles.selection_list}>
        <label>{title}{title_btn}</label>
        <ul>
            {options.map(opt => (
                <li key={opt.value}>
                    <label>
                        {opt.label}
                    </label>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        title={`Remove ${opt.label}`}
                        onClick={() => onDelete(opt.value)}
                        context='danger'
                        size='small'
                    >
                        <Icon
                            name="cross"
                            width={18}
                            height={18}
                            ariaHidden
                        />
                    </StyledButton>
                </li>
            ))}
        </ul>
    </div>
);

export default SettingsSelectedList;
