import React from 'react';
import styles from "./styles.module.scss";
import StyledSkeleton from "../../Skeletons/StyledSkeleton";

interface Props {
}

const VideoSideBarSkeleton: React.FC<Props> = () => (
    <div
        className={styles.meeting_row}
        data-skeleton='true'
    >
        <StyledSkeleton style={{height: 17.5, width: (Math.random() * 40) + 60, top: 8}}/>
        <StyledSkeleton style={{height: 15, width: (Math.random() * 200) + 100, top: 32}}/>
    </div>
);

export default VideoSideBarSkeleton;
