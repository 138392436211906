import {TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import React, {RefObject, useEffect, useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {selectCurrentUserId, setActiveChat, updateActivePage} from "../../../redux/slices";
import {
    usePostChannelMutation,
} from "../../../redux/services/chatApi";
import {focusNext} from "../../../helpers";


export const useChatGroupEditor = ({closeEditor}: { closeEditor: () => void; }) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const activeChat = useTypedSelector(state => state.chat.activeChat);
    const [makingGroup, setMakingGroup] = useState(false);

    const dispatch = useTypedDispatch();

    const NewChatSchema = z
        .object({
            group_name: z.string().optional(),
            group_members: z.array(z.string()).min(1, 'Select a user to chat with'),
        })
        .superRefine((val, ctx) => {
            if (val.group_members.length > 1 && (!val.group_name || val.group_name.length < 1)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Must have a group name when making a group',
                    path: ['group_name']
                })
            }
        })

    const [postChannel, {error: saveError, isLoading, isSuccess}] = usePostChannelMutation();

    type NewChatFormInput = TypeOf<typeof NewChatSchema>;

    const formMethods = useForm<NewChatFormInput>({
        resolver: zodResolver(NewChatSchema),
        defaultValues: {
            group_members: [],
        }
    });

    useEffect(() => {
        if (isSuccess) {
            closeEditor();
        }
    }, [isSuccess]);


    const saveForm = (formData: NewChatFormInput) => {

        if (formData.group_members.length > 1 && formData.group_name) {
            const arrayOfUuids: { uuid: string }[] = formData.group_members.map(user => ({
                uuid: user
            }));

            postChannel({
                voip_user_uuid: userUuid,
                participants: arrayOfUuids,
                name: formData.group_name.trim(),
                avatar: ''
            })
        }

        if (formData.group_members.length === 1) {
            if (activeChat === formData.group_members[0]) {
                dispatch(updateActivePage('chat'))
            }
            dispatch(setActiveChat(formData.group_members[0]));
            closeEditor();
        }
    }

    useEffect(() => {
        const subscription = formMethods.watch((value, {name}) => {
            switch (name) {
                case "group_members":
                    setMakingGroup(value.group_members ? value.group_members.length > 1 : false)
                    break;
                default:
                    break;
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    return {
        formMethods,
        saveForm,
        saveError,
        makingGroup,
        saveLoading: isLoading,
    }
}

export const handleMembersKeydown = ({
                                         showDropdown,
                                         setShowDropdown,
                                         inputRef,
                                     }: {
    showDropdown: boolean;
    setShowDropdown: (val: boolean) => void;
    inputRef: RefObject<HTMLInputElement>;
}) => {
    const handleArrowKeys = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {

        if (!showDropdown) {
            setShowDropdown(true)
        }

        focusNext({
            container: e.currentTarget,
            before,
        })
    }

    const ACTIONS = {
        ArrowRight: (e) => handleArrowKeys(e),
        ArrowLeft: (e) => handleArrowKeys(e, true),
        ArrowDown: (e) => handleArrowKeys(e),
        ArrowUp: (e) => handleArrowKeys(e, true),
        Escape: () => {
            if (showDropdown && inputRef.current) {
                setShowDropdown(false)
                inputRef.current.focus();
            }
        },
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
    }

    return (e) => {
        if (
            e.target.nodeName === 'BUTTON'
            && e.key.length === 1
            && /[a-zA-Z$&+,:;=?@#|'<>.-^*()%!]/.test(e.key)
            && inputRef.current
        ) {
            inputRef.current.focus();
        }

        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}