import React, {useMemo} from "react";
import {useContactContext} from "../../../context/ContactContext/context";
import {IPhonebookContact, IPhonebookContactDetail} from "../../../types";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    selectChatEnabled, selectCurrentMessageId, selectCurrentUserId, selectPhonebookDictionary, selectUserCountryCode,
    startCall,
} from "../../../redux/slices";
import {usePolyglot} from "../../../context/Polyglot";
import styles from "../syles.module.scss";
import {StyledButton} from "../../../components/StyledComponents";
import Icon from "../../../components/Icon";
import {getValidNumber} from "../../../components/sidebars/Contact/Contact";
import {usePostIntegrationMutation} from "../../../redux/services/chatApi";

export const PhonebookContactMain: React.FC = () => {
    const contact = useContactContext().contact as IPhonebookContact;

    const phonebookDictionary = useTypedSelector(selectPhonebookDictionary);
    const countryCode = useTypedSelector(selectUserCountryCode);
    const chatEnabled = useTypedSelector(selectChatEnabled);
    const selectedMessageId = useTypedSelector(selectCurrentMessageId);
    const userId = useTypedSelector(selectCurrentUserId);

    const [postIntegration] = usePostIntegrationMutation();

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    const onSmsClick = async (detail, validNumber, displayName) => {

        if (!detail?.value || !validNumber) return;

        postIntegration({
            voip_user_uuid: userId,
            destination: validNumber,
            channel: 1,
            name: displayName
        })
    }

    const onPhonebookContactCall = (detail, validNumber, displayName) => {
        if (!detail?.value || !validNumber) return;

        dispatch(startCall(validNumber, displayName))
    }

    interface DataItem {
        label: string;
        primary?: boolean;
        data: IPhonebookContactDetail[]
    }

    const detailsMapped: DataItem[] = useMemo(() => {
        const arr: DataItem[] = [];
        const map: Record<string, IPhonebookContactDetail[]> = {};

        // eslint-disable-next-line no-underscore-dangle
        (contact.details || (contact as any)._details).forEach(detail => {
            if (!detail.label) {
                return
            }
            if (detail.primary) {
                arr.push({
                    label: detail.label,
                    primary: true,
                    data: [detail]
                })
            } else {
                if (!map[detail.label]) {
                    map[detail.label] = []
                }
                map[detail.label].push(detail)
            }
        })

        Object.entries(map).forEach(([key, value]) => {
            arr.push({
                label: key,
                data: value
            })
        })

        return arr;
    }, []);

    return (
        <>
            {contact.phone_book_uuid ? (
                <div className={styles.item}>
                    <label>
                        {t("terms.phonebook", 1)}
                    </label>
                    <div>
                        <p>
                            {phonebookDictionary[contact.phone_book_uuid] || 'Unknown'}
                        </p>
                    </div>
                </div>
            ) : null}
            {detailsMapped.map(detailSection => (
                <div
                    className={styles.item}
                    key={detailSection.label}
                >
                    <label>{detailSection.label}{detailSection.primary ? ` (${t("adjective.contact_primary")})` : ''}</label>
                    <div className={styles.phonebook_item}>
                        {detailSection.data.map((detail, idx) => {
                            let controls = <></>;

                            if (detail.type === 1) {
                                const validNumber = getValidNumber(detail?.value, countryCode);

                                if (validNumber) {
                                    let displayName: string;

                                    if (contact.first_name && contact.last_name) {
                                        displayName = `${contact.first_name} ${contact.last_name} (${detail.label})`;
                                    } else {
                                        displayName = `${contact.first_name || contact.last_name || contact.company_name} (${detail.label})`
                                    }
                                    controls = (
                                        <>
                                            {chatEnabled ? (
                                                <StyledButton
                                                    onClick={() => selectedMessageId && onSmsClick(detail, validNumber, displayName)}
                                                    disabled={!selectedMessageId}
                                                    title={selectedMessageId ? t("actions.send_sms") : t("phrases.sms_id_not_set")}
                                                    buttonStyle='text' iconOnly
                                                >

                                                    <Icon name='SMS' ariaHidden width={28} height={28}/>
                                                </StyledButton>
                                            ) : null}
                                            <StyledButton
                                                buttonStyle='text'
                                                title={t("actions.start_call")}
                                                onClick={() => onPhonebookContactCall(detail, validNumber, displayName)}
                                                iconOnly
                                            >
                                                <Icon name='phoneCall' ariaHidden width={28} height={28}/>
                                            </StyledButton>
                                        </>
                                    )
                                }
                            }

                            return (
                                <div
                                    key={`${detail.value}_${idx}`}
                                >
                                    <p>{detail.value}</p>
                                    {controls}
                                </div>
                            )
                        })}
                    </div>
                </div>
            ))}
        </>
    )
}