import React, { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { detect } from 'detect-browser';
import { isCallSwitch, isPulseHd, isYay } from '../../helpers';
import yayLogo from '../../img/logos/logo-yay-white.svg';
import yayLogoColor from '../../img/logos/logo-yay-red.svg';
import yayFavicon from '../../img/favicons/favicon.ico';
import YayLoader from '../../animation/Yay_Loader_Part1.json';
import YayLoader2 from '../../animation/Yay_Loader_Part2.json';
import yayWinClose from '../../img/force-close/yay/win.png';
import yayMacClose from '../../img/force-close/yay/mac.png';
import callswitchLogo from '../../img/logos/logo-callswitch-white.svg';
import callswitchLogoColor from '../../img/logos/logo-callswitch-color.svg';
import callswitchFavicon from '../../img/callswitch/favicon-callswitch.svg';
import CSOneLoader from '../../animation/CS1_Loader_V4_Part1.json';
import CSOneLoader2 from '../../animation/CS1_Loader_V4_Part2.json';
import csoWinClose from '../../img/force-close/cso/win.png';
import csoMacClose from '../../img/force-close/cso/mac.png';
import pulseHdLogo from '../../img/logos/logo-pulsehd.svg';
import pulseHdLogoColor from '../../img/logos/logo-pulsehd-violet.svg';
import pulseHdFavicon from '../../img/logos/logo-pulsehd-64.png';
import phdWinClose from '../../img/force-close/phd/win.png';
import phdMacClose from '../../img/force-close/phd/mac.png';
import { isElectron } from '../../pages/home';
import pjson from '../../../package.json';

type AppTheme = 'light' | 'dark' | 'system_default';

interface Props {
    state: {
        appDownloadURL: string;
        appFavicon: string;
        appLogoAlt: string;
        appLogoColor: string;
        appLogoWhite: string;
        appName: string;
        brandedInitialLoader: Record<string, any> | undefined;
        brandedLoader: Record<string, any> | undefined;
        forceCloseAssets: {
            win: string;
            mac: string;
        };
        packageVersion: string | undefined;
        siteVersion: string;
        appId: number;
    };
    darkMode: boolean;
    currentTheme: AppTheme;
    setTheme: (theme: AppTheme) => void;
}

const AppContext = createContext<Props | null>(null);

export const useAppContext = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Component requiring context rendered without state');
    }

    return {
        ...context.state,
        darkMode: context.darkMode,
        currentTheme: context.currentTheme,
        setTheme: context.setTheme,
        isYay,
        isPulseHd,
        isCallSwitch
    };
};


interface ProviderProps {
    children: ReactNode;
}

export const AppProvider: React.FC<ProviderProps> = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState<AppTheme>(localStorage.theme_mode || 'light');
    const darkMode =
        (currentTheme === 'system_default' &&
            window.matchMedia('(prefers-color-scheme: dark)').matches) ||
        currentTheme === 'dark';

    type Schemas = 'yddab88d' | 'pe12cd8c' | 'cef527d1';

    /**
     * Schemas:
     * Yay: yddab88d
     * PulseHd: pe12cd8c
     * CallSwitch One: cef527d1
     */
    useLayoutEffect(() => {
        const docBody = document.body;

        let schema: Schemas;

        switch (true) {
            case isYay:
                schema = 'yddab88d';
                break;
            case isCallSwitch:
                schema = 'cef527d1';
                break;
            default:
                schema = 'pe12cd8c';
        }

        docBody.setAttribute('data-schema', schema);
        docBody.setAttribute('data-theme', darkMode ? 'dark' : 'light');
        if (!isElectron) {
            docBody.setAttribute('data-is-browser', 'true');
        }
    }, []);

    const state = useMemo(() => {
        let appName: string;
        let appLogoWhite: string;
        let appLogoColor: string;
        let appLogoAlt: string;
        let appDownloadURL: string;
        let appFavicon: string;
        let brandedLoader: Record<string, any> | undefined;
        let brandedInitialLoader: Record<string, any> | undefined;
        let packageVersion: string | undefined;
        let forceCloseAssets: {
            win: string;
            mac: string;
        };
        let appId = 2;

        const siteVersion = pjson.version;
        const browser = detect();

        switch (browser?.os) {
            case 'Mac OS':
                switch (true) {
                    case isPulseHd:
                        appDownloadURL = 'https://storage.googleapis.com/pulsehd/PulseHD.dmg';
                        break;
                    case isCallSwitch:
                        appDownloadURL =
                            'https://storage.googleapis.com/callswitch-apps/CallSwitchOne-5.1.0-universal.dmg';
                        break;
                    default:
                        appDownloadURL = 'https://storage.googleapis.com/yay-apps/Yay.com.dmg';
                }
                break;

            case 'linux':
            case 'Linux':
                switch (true) {
                    case isPulseHd:
                        appDownloadURL = 'https://storage.googleapis.com/pulsehd/PulseHD.AppImage';
                        break;
                    case isCallSwitch:
                        appDownloadURL =
                            'https://storage.googleapis.com/callswitch-apps/CallSwitchOne-5.1.0.AppImage';
                        break;
                    default:
                        appDownloadURL = 'https://storage.googleapis.com/yay-apps/Yay.com.AppImage';
                }
                break;

            default:
                switch (true) {
                    case isPulseHd:
                        appDownloadURL =
                            'https://storage.googleapis.com/pulsehd/PulseHD%20Setup.exe';
                        break;
                    case isCallSwitch:
                        appDownloadURL = ''; // TODO -> CALLSWITCH
                        break;
                    default:
                        appDownloadURL =
                            'https://storage.googleapis.com/yay-apps/Yay.com%20Setup.exe';
                }
        }

        switch (true) {
            case isYay:
                appName = 'Yay.com';
                appLogoWhite = yayLogo;
                appLogoColor = yayLogoColor;
                appLogoAlt = 'Yay.com Logo';
                appFavicon = yayFavicon;
                brandedLoader = YayLoader;
                brandedInitialLoader = YayLoader2;
                packageVersion = /yay\.com\/(.*?) /gm.exec(navigator.userAgent)?.[1];
                forceCloseAssets = {
                    win: yayWinClose,
                    mac: yayMacClose
                };
                appId = 1;
                break;

            case isCallSwitch:
                appName = 'CallSwitch One';
                appLogoWhite = callswitchLogo;
                appLogoColor = callswitchLogoColor;
                appLogoAlt = 'CallSwitch One Logo';
                appFavicon = callswitchFavicon;
                brandedLoader = CSOneLoader;
                brandedInitialLoader = CSOneLoader2;
                packageVersion = /callswitchone\/(.*?) /gm.exec(navigator.userAgent)?.[1];
                forceCloseAssets = {
                    win: csoWinClose,
                    mac: csoMacClose
                };
                appId = 4
                break;

            default:
                appName = 'PulseHD';
                appLogoWhite = pulseHdLogo;
                appLogoColor = pulseHdLogoColor;
                appLogoAlt = 'PulseHD Logo';
                appFavicon = pulseHdFavicon;
                brandedLoader = undefined;
                brandedInitialLoader = undefined;
                packageVersion = /pulsehd\/(.*?) /gm.exec(navigator.userAgent)?.[1];
                forceCloseAssets = {
                    win: phdWinClose,
                    mac: phdMacClose
                };
        }

        return {
            appDownloadURL,
            appFavicon,
            appLogoAlt,
            appLogoColor,
            appLogoWhite,
            appName,
            brandedInitialLoader,
            brandedLoader,
            forceCloseAssets,
            packageVersion,
            siteVersion,
            appId
        };
    }, []);

    const setTheme = useCallback((theme: AppTheme) => {
        setCurrentTheme(theme);
        localStorage.setItem('theme_mode', theme);

        const docBody = document.body;

        const systemTheme = (darkModeSet: boolean) => {
            docBody.setAttribute('data-theme', darkModeSet ? 'dark' : 'light');
        };

        if (theme === 'dark') {
            docBody.setAttribute('data-theme', 'dark');

            window.matchMedia('(prefers-color-scheme: dark)')?.removeEventListener(
                'change',
                event => {
                    systemTheme(event.matches);
                },
                false
            );
        } else if (theme === 'light') {
            docBody.setAttribute('data-theme', 'light');

            window.matchMedia('(prefers-color-scheme: dark)')?.removeEventListener(
                'change',
                event => {
                    systemTheme(event.matches);
                },
                false
            );
        } else {
            systemTheme(
                window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            );

            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
                systemTheme(event.matches);
            });
        }
    }, []);

    return (
        <AppContext.Provider value={{ state, darkMode, setTheme, currentTheme }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
