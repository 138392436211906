import {createContext, Dispatch, ReactNode, useCallback, useContext, useReducer} from "react";
import {GroupCallVolAction, GroupCallVolContextState, initialState, reducer} from "./reducer";

export type IGroupCallSpeaking = Record<string, boolean>

interface dispatcherProps {
    reducer: Dispatch<GroupCallVolAction>;
}

interface valueProps {
    state: GroupCallVolContextState;
}


const GroupCallVolDispatchContext = createContext<dispatcherProps | null>(null)
const GroupCallVolValueContext = createContext<valueProps | null>(null)

export const useGroupCallVolDispatch = () => {
    const context = useContext(GroupCallVolDispatchContext)

    if (!context) {
        throw new Error(
            'Component requiring groupCallVol context rendered without volumes'
        )
    }

    const useGroupCallVolAction = useCallback((val: GroupCallVolAction) => {
        context.reducer(val);
    }, []);

    return {
        useGroupCallVolAction,
    }
}
export const useGroupCallVolValue = () => {
    const context = useContext(GroupCallVolValueContext)

    if (!context) {
        throw new Error(
            'Component requiring groupCallVol context rendered without volumes'
        )
    }


    return {
        ...context.state
    }
}

interface ProviderProps {
    children: ReactNode;
}

export const GroupCallVolProvider: React.FC<ProviderProps> = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...initialState})

    return (
        <GroupCallVolValueContext.Provider value={{state}}>
            <GroupCallVolDispatchContext.Provider value={{reducer: dispatch}}>
                {children}
            </GroupCallVolDispatchContext.Provider>
        </GroupCallVolValueContext.Provider>
    )
}

export default GroupCallVolProvider;