export interface HeadsetInputContextState {
    headset?: HIDDevice;
    reportValues: number[];
}

export const initialState: HeadsetInputContextState = {
    reportValues: []
}

export type HeadsetInputReducerAction =
    {
        type: 'setHeadset',
        payload?: HIDDevice
    } | {
    type: 'newReport',
    payload: number,
}

export const headsetReducer = (state: HeadsetInputContextState, action: HeadsetInputReducerAction): HeadsetInputContextState => {

    switch (action.type) {
        case "setHeadset":
            return {
                ...state,
                headset: action.payload
            }
        case "newReport":
            return {
                ...state,
                reportValues: [...state.reportValues, action.payload]
            }
        default:
            return state
    }
}
