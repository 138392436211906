import React, {FunctionComponent, Suspense} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    selectSmartDiallerError,
    setSmartDiallerError
} from "../../redux/slices";
import ModalFormContainer from "../../components/forms/modalFormContainer/ModalFormContainer";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import SmartDiallerErrorDisplay from "./SmartDiallerErrorDisplay";

interface Props {
}

const SmartDaillerErrorModal: FunctionComponent<Props> = () => {
    const smartDiallerError = useTypedSelector(selectSmartDiallerError);

    const dispatch = useTypedDispatch();

    return (
        <ModalFormContainer
            showModal={!!smartDiallerError}
            setShowModal={() => dispatch(setSmartDiallerError())}
        >
            <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                {smartDiallerError ? (
                    <SmartDiallerErrorDisplay
                        smartDiallerError={smartDiallerError}
                    />
                ) : null}
            </Suspense>
        </ModalFormContainer>
    );
};

export default SmartDaillerErrorModal;
