import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {IHuntGroup, IPhonebookContact, IUser} from "../../types";
import contactReducer, {ContactAction, ContactState, initialState} from "./reducer";

interface Props {
    contact: IUser | IPhonebookContact | IHuntGroup
    setSearchTerm?: (val: string) => void;
    state: ContactState;
    reducer: Dispatch<ContactAction>;
}

interface ProviderProps {
    value: {
        contact: IUser | IPhonebookContact | IHuntGroup
        setSearchTerm?: (val: string) => void;
    };
    children: ReactNode;
}

const ContactContext = createContext<Props | null>(null)

export const useContactContext = () => {
    const context = useContext(ContactContext)

    if (!context?.contact) {
        throw new Error(
            'Component requiring contact context rendered without contact'
        )
    }

    const useContactAction: (val: ContactAction) => void = context.reducer

    return {
        contact: context.contact,
        setSearchTerm: context.setSearchTerm,
        ...context.state,
        useContactAction
    }
}

export const ContactProvider: React.FC<ProviderProps> = ({value, children}) => {
    const [state, reducer] = useReducer(contactReducer, initialState)

    return (
        <ContactContext.Provider
            value={{
                contact: value.contact,
                setSearchTerm: value.setSearchTerm,
                state,
                reducer,
            }}
        >
            {children}
        </ContactContext.Provider>
    )
}

export default ContactProvider;