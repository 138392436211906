import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import MenuHeader from '../../menus/MenuHeader';
import { StyledButton } from '../../StyledComponents';
import Icon from '../../Icon';
import {
    selectActivePhonebooks,
    selectAllDepartments,
    selectApiOnlyPhonebooks,
    selectFiltersByPage,
    selectFocusOnDepartment,
    selectInterTenantList,
    selectPhoneSettingByKey,
    selectProvisioningOpen,
    setEditPhonebookContact,
    setFocusOnDepartment,
    setPageFilter,
    startCall,
    updatePhoneSettings
} from '../../../redux/slices';
import { TextInput } from '../../forms';
import { Filter, Filters } from '../../menus/Filters';
import CallerIDs from '../../menus/CallerIDs';
import ContactList from './ContactList';
import { Department, IPhonebook } from '../../../types';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { usePolyglot } from '../../../context/Polyglot';
import { useDebounce } from '../../../helpers';
import { useContacts, UseContactsProps } from './ContactListHooks';
import ShortcodesList from './children/ShortcodesList';
import { AriaCheckbox } from '../../AriaComponents';
import DepartmentContacts from './DepartmentContacts';
import DepartmentTag from '../../buttons/DepartmentTag';
import StyledDropdown from '../../StyledComponents/StyledDropdown';
import SettingsInfoIcon from '../../../pages/settings/SettingsPages/SettingsComponents/SettingsInfoIcon';

interface Props {}

type ContactPageType = 'contacts' | 'short_codes';

const possibleFilters = ['Internal Users', 'Phonebook Contacts', 'Hunt Groups', 'Tenants'];

const ContactSidebar: FunctionComponent<Props> = () => {
    const gridView = useTypedSelector(state => selectPhoneSettingByKey(state, 'defaultToGridView'));
    const filter = useTypedSelector(state => selectFiltersByPage(state, 'contactList'));
    const showProvisioning = useTypedSelector(selectProvisioningOpen);
    const departments = useTypedSelector(selectAllDepartments);
    const disableHuntGroupDisplay = useTypedSelector(
        state => state.user.settings.phone.settings?.disableHuntGroupDisplay
    );
    const focusOnDepartment = useTypedSelector(selectFocusOnDepartment);
    const activePhonebookUuids = useTypedSelector(selectActivePhonebooks);
    const apiOnlyPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);
    const allPhonebooks: IPhonebook[] = useTypedSelector(state => state.auth.phonebooks) || [];
    const tenantsCount = useTypedSelector(selectInterTenantList).length;

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<ContactPageType>('contacts');
    const [selectedDepartmentUuids, setSelectedDepartmentUuids] = useState<string[]>(
        filter.filter(f => !possibleFilters.includes(f))
    );

    const { t } = usePolyglot();

    const dispatch = useTypedDispatch();

    const searchQueryDebounced = useDebounce(searchTerm, 500);

    const selectedDepartments: Department[] = [];

    selectedDepartmentUuids.forEach(uuid => {
        const found = departments?.find(dept => dept.uuid === uuid);

        if (found) {
            selectedDepartments.push(found);
        }
    });

    const listParams: UseContactsProps = useMemo(() => {
        if (!(departments && selectedDepartmentUuids.length > 0)) {
            return {
                searchQuery: searchQueryDebounced,
                filter
            };
        }

        const uuidArr: Set<string> = new Set();

        selectedDepartments.forEach(dept => {
            dept.users.forEach(u => uuidArr.add(u));
        });

        return {
            searchQuery: searchQueryDebounced,
            departmentUserIds: [...uuidArr]
        };
    }, [filter.length, selectedDepartments.length, searchQueryDebounced]);

    const { favourites, mainDisplay, outerDisplay, initialLoad, apiContactsLoading, getStrForCDI } =
        useContacts(listParams);

    const hasSelectedDepartments: boolean = selectedDepartmentUuids.length > 0;

    let filters: Filter[] = [];

    if (departments && departments.length > 0) {
        filters = [
            {
                title: 'misc',
                value: ''
            }
        ];
    }

    if (!disableHuntGroupDisplay || activePhonebookUuids.length > 0) {
        filters = [
            {
                title: 'misc',
                value: ''
            },
            {
                title: t('terms.internal_user', 2),
                value: 'Internal Users',
                disabled: hasSelectedDepartments
            }
        ];
    }
    if (activePhonebookUuids.length > 0) {
        filters.push({
            title: t('terms.phonebook_contact', 2),
            value: 'Phonebook Contacts',
            disabled: hasSelectedDepartments
        });
    }
    if (!disableHuntGroupDisplay) {
        filters.push({
            title: t('terms.hunt_group', 2),
            value: 'Hunt Groups',
            disabled: hasSelectedDepartments
        });
    }

    if (tenantsCount > 0) {
        filters.push({
            title: 'Bridged Accounts',
            value: 'Tenants',
            disabled: hasSelectedDepartments
        });
    }

    let pageComponent: React.ReactElement;
    let pageTitle: string;

    useEffect(() => {
        if (activePhonebookUuids.length < 1 && filter.includes('Phonebook Contacts')) {
            handleFilterChange('Phonebook Contacts');
        }
    }, [activePhonebookUuids.length]);

    switch (page) {
        case 'contacts':
            if (departments && selectedDepartmentUuids.length > 0) {
                pageComponent = (
                    <DepartmentContacts
                        departments={selectedDepartments}
                        searchVal={searchQueryDebounced}
                        favourites={favourites}
                        outerDisplay={outerDisplay}
                        apiContactsLoading={apiContactsLoading}
                        getStrForCDI={getStrForCDI}
                    />
                );
            } else {
                pageComponent = (
                    <ContactList
                        key='base_contact_list'
                        searchQuery={searchQueryDebounced}
                        favourites={favourites}
                        mainDisplay={mainDisplay}
                        outerDisplay={outerDisplay}
                        initialLoad={initialLoad}
                        apiContactsLoading={apiContactsLoading}
                        getStrForCDI={getStrForCDI}
                    />
                );
            }
            pageTitle = t('terms.contact', 2);
            break;
        default:
            pageComponent = <ShortcodesList searchTerm={searchTerm} />;
            pageTitle = t('terms.short_code', 2);
            break;
    }

    type ITab = {
        value: ContactPageType;
        label: string;
    };

    const tabs: ITab[] = [
        {
            value: 'contacts',
            label: t('terms.contact', 2)
        },
        {
            value: 'short_codes',
            label: t('terms.short_code', 2)
        }
    ];

    function handleFilterChange(selectedFilter: string) {
        let newFilters: string[];

        if (filter.includes(selectedFilter)) {
            newFilters = filter.filter(f => f !== selectedFilter);
        } else {
            newFilters = filter.concat(selectedFilter);
        }

        switch (true) {
            case !possibleFilters.includes(selectedFilter) &&
                selectedDepartmentUuids.includes(selectedFilter):
                setSelectedDepartmentUuids(
                    selectedDepartmentUuids.filter(uuid => uuid !== selectedFilter)
                );
                break;
            case !possibleFilters.includes(selectedFilter) &&
                !selectedDepartmentUuids.includes(selectedFilter):
                setSelectedDepartmentUuids([...selectedDepartmentUuids, selectedFilter]);
        }

        dispatch(
            setPageFilter({
                page: 'contactList',
                newFilters
            })
        );
    }

    function handleEnterKeyDown(key) {
        if (key === 'Enter' && !/[^\d\s+()\-$#*]/.test(searchTerm)) {
            dispatch(startCall(searchTerm));
        }
    }

    useEffect(() => {
        setSearchTerm('');
    }, [page]);

    useEffect(() => {
        if (departments && selectedDepartmentUuids.length > 0) {
            setSelectedDepartmentUuids(
                filter.filter(uuid => departments.some(dept => dept.uuid === uuid))
            );
        }
    }, [departments]);

    useEffect(() => {
        if (focusOnDepartment) {
            setSelectedDepartmentUuids([focusOnDepartment]);
            dispatch(
                setPageFilter({
                    page: 'contactList',
                    newFilters: filter
                        .filter(item => possibleFilters.includes(item))
                        .concat(focusOnDepartment)
                })
            );
            dispatch(setFocusOnDepartment());
        }
    }, [focusOnDepartment]);

    return (
        <div>
            <MenuHeader
                title={
                    !showProvisioning ? (
                        <div
                            className='menu-header__title'
                            data-comp='menu-header__title'
                            data-phone-header='true'
                        >
                            <h2>{pageTitle}</h2>
                            {apiOnlyPhonebooks.length > 0 ? (
                                <SettingsInfoIcon
                                    customMark={<i />}
                                    customDisplay={
                                        <>
                                            <p>
                                                Due to high volume of records not all phonebook
                                                contacts will be displayed,
                                                <br />
                                                but all contacts are still searchable.
                                            </p>
                                            <p>
                                                <br />
                                                Phonebooks not being displayed:
                                            </p>
                                            <ul>
                                                {apiOnlyPhonebooks.map(uuid => {
                                                    const found = allPhonebooks.find(
                                                        pb => pb.uuid === uuid
                                                    );

                                                    if (found) {
                                                        return (
                                                            <li key={found.uuid}>{found.name}</li>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </ul>
                                        </>
                                    }
                                    className='restricted-phonebooks'
                                />
                            ) : null}
                            <section className='menu-header__icon-container'>
                                <StyledButton
                                    buttonStyle='tertiary'
                                    fontWeight='medium'
                                    onClick={() => {
                                        dispatch(
                                            setEditPhonebookContact({
                                                company_name: '',
                                                created_on: '',
                                                details: [
                                                    {
                                                        type: 1,
                                                        label: t('phrases.label_main'),
                                                        value: '',
                                                        primary: true,
                                                        type_id: 1
                                                    }
                                                ],
                                                first_name: '',
                                                integrations: [],
                                                last_name: '',
                                                uuid: '',
                                                phone_book_uuid: ''
                                            })
                                        );
                                    }}
                                    title={t('actions.add_contact', 1)}
                                >
                                    <Icon name='plus' width={24} height={24} />
                                    <p>{t('actions.add_contact', 1)}</p>
                                </StyledButton>
                                <StyledButton
                                    buttonStyle='tertiary'
                                    iconOnly
                                    title={
                                        gridView
                                            ? t('actions.switch_to_list_view')
                                            : t('actions.switch_to_grid_view')
                                    }
                                    onClick={() =>
                                        dispatch(
                                            updatePhoneSettings({
                                                setting: 'defaultToGridView',
                                                value: !gridView
                                            })
                                        )
                                    }
                                >
                                    <Icon
                                        name={!gridView ? 'gridView' : 'rowView'}
                                        width={24}
                                        height={24}
                                    />
                                </StyledButton>
                            </section>
                        </div>
                    ) : (
                        <div
                            className='menu-header__title'
                            data-comp='menu-header__title'
                            data-phone-header='true'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <StyledDropdown
                                options={tabs}
                                display={<h2>{pageTitle}</h2>}
                                selected={page}
                                onSelect={val => {
                                    setPage(val as ContactPageType);
                                }}
                                className='menu-header__tab-dropdown prov'
                            />
                            {page === 'contacts' ? (
                                <StyledButton
                                    buttonStyle='tertiary'
                                    iconOnly
                                    title={
                                        gridView
                                            ? t('actions.switch_to_list_view')
                                            : t('actions.switch_to_grid_view')
                                    }
                                    onClick={() =>
                                        dispatch(
                                            updatePhoneSettings({
                                                setting: 'defaultToGridView',
                                                value: !gridView
                                            })
                                        )
                                    }
                                >
                                    <Icon
                                        name={!gridView ? 'gridView' : 'rowView'}
                                        width={24}
                                        height={24}
                                    />
                                </StyledButton>
                            ) : null}
                        </div>
                    )
                }
                input={
                    <TextInput
                        name={t('actions.search_contacts')}
                        placeholder={t('actions.search_contacts')}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={e => handleEnterKeyDown(e.code)}
                        isSearch
                        isSearching={searchTerm !== searchQueryDebounced}
                    />
                }
                filter={
                    filters?.length > 0 && page === 'contacts' ? (
                        <Filters
                            filter={
                                hasSelectedDepartments
                                    ? filter.filter(f => !possibleFilters.includes(f))
                                    : filter
                            }
                            filters={[filters]}
                            onChange={f => handleFilterChange(f)}
                            label='Contacts Filter'
                        >
                            <>
                                {departments && departments.length > 0 ? (
                                    <>
                                        <p>Departments</p>
                                        {departments.map(department => (
                                            <AriaCheckbox
                                                key={department.uuid}
                                                value={department.uuid}
                                            >
                                                <DepartmentTag department={department} isSpan />
                                            </AriaCheckbox>
                                        ))}
                                    </>
                                ) : null}
                            </>
                        </Filters>
                    ) : undefined
                }
                callerIds={!showProvisioning ? <CallerIDs /> : undefined}
                selectedFilters={
                    selectedDepartments.length > 0
                        ? selectedDepartments.map(dept => dept.name)
                        : filter
                }
                filterDisplay={
                    selectedDepartments.length > 0 ? (
                        <p>
                            {t('terms.applied_filter', 1)}:&nbsp;
                            {selectedDepartments.map(dept => (
                                <DepartmentTag
                                    key={dept.uuid}
                                    department={dept}
                                    onClick={() => handleFilterChange(dept.uuid)}
                                    isSpan
                                />
                            ))}
                        </p>
                    ) : undefined
                }
            />
            {pageComponent}
        </div>
    );
};

export default ContactSidebar;
