import React, {FunctionComponent, ReactNode} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {selectDepartmentDictionary, setFocusOnDepartment} from "../../redux/slices";
import {StyledButton} from "../StyledComponents";
import styles from './styles.module.scss';
import {Department} from "../../types";
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';

interface Props {
    department?: Department;
    disabled?: boolean;
    onClick?: () => void;
    isSpan?: boolean;
    text?: string;
    allowClick?: boolean;
    isEditorLeaf?: {
        children: ReactNode;
        title: string;
        uuid: string;
    }
}

const DepartmentTag: FunctionComponent<Props> = ({
                                                     department,
                                                     disabled,
                                                     onClick,
                                                     isSpan,
                                                     text,
                                                     allowClick,
                                                     isEditorLeaf
                                                 }) => {

    const {darkMode} = useAppContext();

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    if (isEditorLeaf) {
        return (
            <span
                title={isEditorLeaf.title}
                className={styles.department}
                data-is-dark={darkMode}
                data-is-leaf='true'
                data-allow-click='true'
                onClick={() => dispatch(setFocusOnDepartment(isEditorLeaf?.uuid))}
            >
                {isEditorLeaf.children}
            </span>
        )
    }

    if (isSpan || !department) {
        return (
            <span
                className={styles.department_span}
            >
            <span
                className={styles.department}
                data-is-dark={darkMode}
                data-allow-click={allowClick || false}
                onClick={() => {
                    if (allowClick) {
                        if (onClick) {
                            onClick()
                        } else if (department) {
                            dispatch(setFocusOnDepartment(department.uuid))
                        }
                    }
                }}
            >
                {department?.name || text || t('adjective.chat_unknown')}
            </span>
            </span>
        )
    }

    return (
        <StyledButton
            className={styles.department}
            dataTags={{
                "data-is-dark": darkMode
            }}
            buttonStyle='text'
            onClick={() => {
                if (onClick) {
                    onClick()
                } else if (department) {
                    dispatch(setFocusOnDepartment(department.uuid))
                }
            }}
            disabled={disabled || !department}
            title={department?.name || t('adjective.chat_unknown')}
        >
            <p>
                {department?.name || t('adjective.chat_unknown')}
            </p>
        </StyledButton>
    );
};

export const DepartmentTagByUuid: React.FC<{ uuid: string }> = ({uuid}) => {
    const departmentDictionary = useTypedSelector(selectDepartmentDictionary);

    const department = departmentDictionary[uuid];

    if (!department) return (
        <DepartmentTag text='Unknown'/>
    )

    return (
        <DepartmentTag department={department} isSpan allowClick/>
    )
}

export default DepartmentTag;
