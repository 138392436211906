/* eslint-disable no-nested-ternary */
import React, {useRef} from 'react';
import {FormProvider} from "react-hook-form";
import Icon from "../../Icon";
import {usePolyglot} from "../../../context/Polyglot";
import FormTextField from "../../AriaForm/FormTextField";
import {usePhonebookContactEditor} from "./hooks";
import {useOnClickOutside} from "../../../helpers";
import formStyles from '../../../sass/globalModules/forms.module.scss'
import {StyledButton} from "../../StyledComponents";

interface Props {
    closeEditor: () => void;
}

export const SmartDiallerNumberRegister: React.FC<Props> = ({closeEditor}) => {
    const {t} = usePolyglot();

    const editorRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(editorRef, closeEditor)

    const {
        formMethods,
        saveNumber
    } = usePhonebookContactEditor({
        closeEditor
    })

    return (
        <div
            className={['phonebook-contact-editor', formStyles.formContainer].join(' ')}
            ref={editorRef}
        >
            <section className={formStyles.formHeader}>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    onClick={() => closeEditor()}
                >
                    <Icon name='arrowLeft'/>
                </StyledButton>
                <h2>
                    {t('phrases.register_number')}
                </h2>
            </section>
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form onSubmit={formMethods.handleSubmit(saveNumber)}>
                    <FormTextField
                        name='name'
                        label={t('terms.label', 1)}
                    />
                    <FormTextField
                        name='value'
                        label={t('terms.number', 1)}
                        required
                    />
                    <div className={formStyles.formControls}>
                        <StyledButton
                            buttonStyle='text'
                            context='danger'
                            type="button"
                            onClick={() => closeEditor()}
                        >
                            <p>
                                {t("actions.cancel")}
                            </p>
                        </StyledButton>
                        <StyledButton
                            buttonStyle='primary'
                            size='large'
                            type="submit"
                        >
                            <p>
                                {t("actions.save")}
                            </p>
                        </StyledButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
};

export default SmartDiallerNumberRegister;