import React, {useMemo, useState} from 'react';
import styles from './styles.module.scss';

interface Props {
    strs: string[]
}

const FadingText: React.FC<Props> = ({strs}) => {
    const [leftIdx, setLeftIdx] = useState(0);
    const [rightIdx, setRightIdx] = useState(1);

    const longest: string = useMemo(() => {

        if (strs.length < 1) {
            return ""
        }

        const lenSorted = strs.sort((a, b) => b.length - a.length)

        return lenSorted[0];
    }, [strs]);

    return (
        <div
            className={styles.fading_text}
            data-comp='fading-text'
        >
            <p
                onAnimationIteration={() => {
                    setLeftIdx(leftIdx + 2)
                }}
            >
                {strs[leftIdx % strs.length]}
            </p>
            <p
                onAnimationIteration={() => {
                    setRightIdx(rightIdx + 2)
                }}
            >
                {strs[rightIdx % strs.length]}
            </p>
            <p
                aria-hidden
            >
                {longest}
            </p>
        </div>
    );
};

export default FadingText;
