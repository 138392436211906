import React from 'react';
import styles from "../styles.module.scss";
import StyledSkeleton from "../../../../components/Skeletons/StyledSkeleton";

interface Props {
}

const VoicemailItemSkeleton: React.FC<Props> = () => (
    <div className={styles.voicemail_item}>
        <div className={styles.call_data}>
            <div>
                <StyledSkeleton style={{height: 20, width: (Math.random() * 40) + 100}}/>
                <StyledSkeleton style={{width: (Math.random() * 100) + 80}}/>
            </div>
            <StyledSkeleton style={{height: 20, width: 40}}/>
        </div>
        <div className={styles.player}>
            <StyledSkeleton style={{width: 24, height: 24, borderRadius: '50%'}}/>
            <StyledSkeleton style={{width: 32}}/>
            <StyledSkeleton style={{flex: 1, height: 8}}/>
            <StyledSkeleton style={{width: 32}}/>
        </div>
        <div className={styles.transcription}>
            <StyledSkeleton style={{width: 64}}/>
            <StyledSkeleton style={{marginTop: 2, height: Math.ceil(Math.random() * 2) * 16}}/>
        </div>
    </div>
);

const VoicemailSkeleton: React.FC<Props> = () => (
    <div className={styles.messages} data-skeleton='true'>
        <StyledSkeleton className={['list-divider', styles.divider].join(' ')}/>
        {Array(3).fill(1).map((_, i) => (
            <VoicemailItemSkeleton key={`a_${i}`}/>
        ))}
        <StyledSkeleton className={['list-divider', styles.divider].join(' ')}/>
        {Array(1).fill(1).map((_, i) => (
            <VoicemailItemSkeleton key={`b_${i}`}/>
        ))}
        <StyledSkeleton className={['list-divider', styles.divider].join(' ')}/>
        {Array(2).fill(1).map((_, i) => (
            <VoicemailItemSkeleton key={`c_${i}`}/>
        ))}
    </div>
);

export default VoicemailSkeleton;
