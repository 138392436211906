import {createContext, ReactNode, useContext} from "react";
import {IChatMessage} from "../../types";

interface Props {
    message: IChatMessage;
}

const MessageContext = createContext<Props | null>(null)

export const useMessageContext = () => {
    const context = useContext(MessageContext)

    if (!context?.message) {
        throw new Error(
            'Component requiring message context rendered without a message'
        )
    }

    return {
        message: context.message
    }
}

interface ProviderProps {
    children: ReactNode;
    message: IChatMessage;
}

export const MessageProvider: React.FC<ProviderProps> = ({ children, message }) => (
    <MessageContext.Provider value={{message}} >
        {children}
    </MessageContext.Provider>
)

export default MessageProvider