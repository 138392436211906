import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../styles.module.scss';
import { StyledButton } from '../../../StyledComponents';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import { selectUsersDictionary, setActiveChat, updateActivePage } from '../../../../redux/slices';
import { usePolyglot } from '../../../../context/Polyglot';
import Icon from '../../../Icon';
import EntityIcon from '../../../icons/EntityIcon';
import AriaUserSearchSelect from '../../../AriaComponents/AriaUserSearchSelect';
import { IUser } from '../../../../types';

interface Props {
    name?: string;
    label?: string;
    placeHolder?: string;
    closeEditor?: () => void;
}

const NewChatMembersController: React.FC<Props> = ({
    name = 'group_members',
    label = 'Search Contacts',
    placeHolder,
    closeEditor
}) => {
    const { control } = useFormContext();

    const canCreateGroup = useTypedSelector(state => state.user.chat_config?.can_create_group);
    const userDictionary = useTypedSelector(selectUsersDictionary);

    const { t } = usePolyglot();

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useTypedDispatch();

    const getPlaceholder = (count: number) => {
        if (count > 0) {
            return '';
        }

        if (placeHolder) return placeHolder;

        return canCreateGroup
            ? t('phrases.search_to_start_an_individual_or_a_group_chat')
            : 'Search';
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <AriaUserSearchSelect
                    selectedUsers={value}
                    label={label}
                    onUserClick={(user: IUser) => {
                        const isSelected = value.includes(user.uuid);

                        if (isSelected) {
                            onChange(value.filter(val => val !== user.uuid));
                        } else {
                            onChange([...value, user.uuid]);
                        }
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                        if (!canCreateGroup && closeEditor) {
                            dispatch(setActiveChat(user.uuid));
                            dispatch(updateActivePage('chat'));
                            closeEditor();
                        }
                    }}
                    widgets={
                        <>
                            {value.map((contactId: string) => {
                                const contact = userDictionary[contactId];

                                return (
                                    <div
                                        key={`SGM_${contactId}`}
                                        className={styles.user_chip}
                                        title={contact?.nickname || t('adjective.user_unknown')}
                                    >
                                        <EntityIcon
                                            type='user'
                                            details={{
                                                display_name: contact.nickname,
                                                avatar_key: contact.avatar_key,
                                                uuid: contact.uuid
                                            }}
                                            size={18}
                                        />
                                        <p>{contact?.nickname || t('adjective.user_unknown')}</p>
                                        <StyledButton
                                            buttonStyle='text'
                                            size='small'
                                            onClick={() => {
                                                onChange(value.filter(val => val !== contactId));
                                            }}
                                            iconOnly
                                            title={t('actions.remove')}
                                        >
                                            <Icon name='cross' />
                                        </StyledButton>
                                    </div>
                                );
                            })}
                        </>
                    }
                    onDelete={() => {
                        onChange(value.slice(0, -1));
                    }}
                    placeholder={getPlaceholder(Array.isArray(value) ? value.length : 0)}
                />
            )}
            rules={{ required: true }}
        />
    );
};

export default NewChatMembersController;
