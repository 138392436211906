import React from "react";
import {isHuntGroup, isUser} from "../../../types";
import {
    addCallWhenAvailable,
    deleteCallWhenAvailable,
    handleFindingATransfer,
    selectCallWhenAvailableList,
    selectUserStatus,
    startCall
} from "../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {useContactContext} from "../../../context/ContactContext/context";
import {focusNext} from "../../../helpers";

type UseContactKeyDownProps = {
    gridView: boolean;
    gridColumnCount: number
} | {
    gridView: never;
    gridColumnCount: never;
}

export const useCallContact = () => {
    const {contact, setSearchTerm} = useContactContext();

    const callWhenAvailableList = useTypedSelector(selectCallWhenAvailableList);
    const isInCallWhenAvailableList = isUser(contact) && callWhenAvailableList.find(c => c.uuid === contact.uuid);


    const userStatus = useTypedSelector(state => isUser(contact) ? selectUserStatus(state, contact.name) : undefined);
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const dispatch = useTypedDispatch()

    const isPhoneNumber = 'type' in contact && contact.type === 'phoneNumber' && 'number' in contact;

    const callContact = (
        event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        event?.stopPropagation();

        if (isUser(contact)) {
            if (userStatus?.status === 'open' || aTransferIsActive) {
                dispatch(startCall(contact.extension.toString(), contact.nickname));
            } else if (isInCallWhenAvailableList) {
                dispatch(deleteCallWhenAvailable(contact.uuid));
            } else {
                dispatch(addCallWhenAvailable({
                    user_uuid: contact.uuid,
                    sip_name: contact.name,
                    display_name: contact.nickname,
                    extension: `${contact.extension}`
                }));
            }
        }

        if (isHuntGroup(contact)) {
            dispatch(startCall(contact.extension_number.toString(), contact.name));
        }

        if (isPhoneNumber) {
            dispatch(startCall(contact.number as string))
        }

        if (setSearchTerm) {
            setSearchTerm('')
        }
    };

    return {
        callContact
    }
}

export const useContactKeyDown = ({gridView, gridColumnCount}: UseContactKeyDownProps) => {
    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement;

        if (
            (target?.parentNode as HTMLElement).className === 'recent-row__options'
            && target.nextSibling?.firstChild?.nodeName === 'UL'
        ) {
            focusNext({
                container: target.parentNode as HTMLElement,
                before
            })
            return;
        }

        if (target.parentNode?.nodeName === 'UL') {
            focusNext({
                container: target.parentNode?.parentNode?.parentNode as HTMLElement,
                before
            })
            return;
        }

        if (gridView) {
            let gridElement: any = e.currentTarget;

            for (let i = 0; i < gridColumnCount; i += 1) {
                if (before && gridElement.previousSibling?.firstChild) {
                    gridElement = gridElement.previousSibling
                } else if (!before && gridElement.nextSibling.firstChild) {
                    gridElement = gridElement.nextSibling
                } else {
                    break;
                }
            }

            if (gridElement) {
                focusNext({container: gridElement, ref: e.currentTarget})
            }
            return;
        }

        let navElement: any = before ? e.currentTarget.previousSibling : e.currentTarget.nextSibling;

        if (!navElement) return;

        if ((navElement?.firstChild?.firstChild as HTMLElement).className === 'list-divider') {
            navElement = before ? navElement?.previousSibling : navElement?.nextSibling;
        }

        if (!navElement) return;

        if (navElement) {
            focusNext({
                container: navElement,
            })
        }
    }

    const handleArrowX = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        if (gridView) {
            const focusElement = before ?
                e.currentTarget?.previousSibling
                : e.currentTarget?.nextSibling


            if (focusElement) {
                focusNext({container: focusElement as HTMLElement})
            }

        } else {
            focusNext({
                container: e.currentTarget,
                before,
            })
        }
    }

    const parentActions = {
        ArrowRight: (e) => handleArrowX(e),
        ArrowLeft: (e) => handleArrowX(e, true),
        ArrowDown: (e) => handleArrowY(e),
        ArrowUp: (e) => handleArrowY(e, true),
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
    }

    return (e) => {
        const handler = parentActions[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}