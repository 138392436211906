import React, { lazy, useState, Suspense } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { TextInput } from '../../../../components';
import { usePolyglot } from '../../../../context/Polyglot';
import { useChatContext } from '../../../../context/ChatContext/context';
import { useChannelMembers } from '../../ChatHeader/hooks';
import ChatSideBarHeader from './children/ChatSideBarHeader';
import { useDebounce } from '../../../../helpers';
import { useMessageSearch } from '../../../../components/sidebars/chat/hooks';
import LoadingSpinner from '../../../../components/loading/LoadingSpinner';
import ChannelParticipants from './children/ChannelParticipants';
import ChatSettingsMenuOptions from './children/ChatSettingsMenuOptions';
import settingsStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { ChatSettingsSidebarMenus } from '../index';

const ChatHeaderMessagesSearch = lazy(() => import('./children/ChatInnerSearch'));

interface ChatSettingsMenuProps {
    setSideBarTab: (val: ChatSettingsSidebarMenus) => void;
}

export const ChatSettingsMenu: React.FC<ChatSettingsMenuProps> = ({ setSideBarTab }) => {
    const { chat } = useChatContext();

    const { t } = usePolyglot();
    const { adminUuids } = useChannelMembers();

    const [searchTerm, setSearchTerm] = useState<string>('');

    const searchQueryDebounced = useDebounce(searchTerm, 500);
    const { isSearching } = useMessageSearch({
        searchQuery: searchQueryDebounced,
        chatId: chat.uuid,
        chat_type: chat.type
    });

    return (
        <motion.div
            className={settingsStyles.chat_settings_page}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >
            <ChatSideBarHeader adminUuids={adminUuids} />
            <div className={styles.chat_side_input}>
                <TextInput
                    name='Chat Search'
                    placeholder={t('actions.search_chat')}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    autoFocus
                    isSearch
                />
            </div>
            {searchTerm ? (
                <Suspense fallback={<LoadingSpinner color='mamba' />}>
                    <ChatHeaderMessagesSearch
                        searchValue={searchQueryDebounced}
                        isSearching={isSearching}
                    />
                </Suspense>
            ) : null}
            {!searchTerm ? (
                <>
                    <ChatSettingsMenuOptions setSideBarTab={setSideBarTab} />
                    {chat.type === 'channel' && <ChannelParticipants />}
                </>
            ) : null}
        </motion.div>
    );
};
