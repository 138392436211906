import React from 'react';
import {usePolyglot} from "../../context/Polyglot";

export const ChatMessageErrorBoundary: React.FC = () => {
    const { t } = usePolyglot();

    return (
        <div className='chat-message--left-aligned'>
            <div className='chat-message__content status-read'>
                <div className='chat-message__message'>
                    <p>
                        <strong>{t("phrases.error_loading_message")}</strong>
                    </p>
                </div>
            </div>
            <div className='chat-message__reactions' />
        </div>
    )
};
