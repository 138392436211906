import {ReactNode, useEffect, useRef} from "react";
import {AnimatePresence} from "framer-motion/dist/framer-motion";
import {useOnClickOutside} from "../../../../helpers";
import {AnimatedDiv} from "../../../../animation";
import styles from "./TagPopup.module.scss";
import SelectedPhonebookContact from "./SelectedPhonebookContact";
import {BaseTagType, ChatTagType, useInsertTag, useTagSelector} from "./hooks";
import {StyledButton} from "../../../../components/StyledComponents";
import {useTypedSelector} from "../../../../redux/hooks";
import {
    selectAuthUserByUuid,
    selectDepartmentByUuid,
} from "../../../../redux/slices";
import {useChatTagContext} from "./ChatTagsContext/context";
import StyledList from "../../../../components/StyledComponents/StyledList";
import DepartmentTag from "../../../../components/buttons/DepartmentTag";
import EntityIcon from "../../../../components/icons/EntityIcon";

interface Props {
}

const UserTagItem: React.FC<{ tagData: BaseTagType }> = ({tagData}) => {
    const user = useTypedSelector(state => selectAuthUserByUuid(state, tagData.data.uuid))

    if (!user) return null;

    return (
        <>
            <EntityIcon
                type='user'
                details={{
                    uuid: user.uuid,
                    avatar_key: user.avatar_key,
                    display_name: user.nickname
                }}
                size={24}
            />
            <p>{user.nickname}</p>
        </>
    )
}

/*
const PhonebookTagItem: React.FC<{ tagData: PhonebookContactTagType }> = ({tagData}) => {
    const phonebooks = useTypedSelector(selectUsablePhonebooks);
    const {useChatTagAction} = useChatTagContext();

    const contactName = [tagData.data.first_name, tagData.data.last_name].join(" ");
    const phonebook = phonebooks.find(book => book.uuid === tagData.data.phone_book_uuid);

    return (
        <StyledButton
            buttonStyle='text'
            onClick={() => useChatTagAction({
                type: 'setSelectedContact',
                payload: tagData.data
            })}
            className={styles.contact_tag}
            dataTags={{
                'data-large': !!phonebook || null
            }}
        >
            <UserIcon/>
            <div className={styles.details_container}>
                <p>{contactName}</p>
                {phonebook ? (
                    <p>{phonebook.name}</p>
                ) : null}
            </div>
        </StyledButton>
    )
}
 */

const DepartmentTagItem: React.FC<{ tagData: BaseTagType }> = ({tagData}) => {
    const department = useTypedSelector(state => selectDepartmentByUuid(state, tagData.data.uuid));

    if (!department) return null;

    return (
        <DepartmentTag
            department={department}
            disabled
        />
    )
}

const RenderTag: React.FC<{ tag: ChatTagType, isSelected: boolean }> = ({tag, isSelected}) => {

    let tagItem: ReactNode = null;
    const insertTag = useInsertTag();

    const itemRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (!isSelected || !itemRef.current) return;

        itemRef.current.scrollIntoView({block: "center", behavior: 'smooth'});
    }, [isSelected]);

    switch (tag.tagType) {
        case "user":
            tagItem = (
                <UserTagItem
                    tagData={tag}
                />
            );
            break;
        case "contact":
            // tagItem =  (
            //     <PhonebookTagItem
            //         tagData={tag}
            //     />
            // )
            // break;
            return null;
        case "department":
            tagItem = (
                <DepartmentTagItem
                    tagData={tag}
                />
            )
            break;
        default:
            return null;
    }

    if (!tagItem) return null;

    return (
        <li
            ref={itemRef}
        >
            <StyledButton
                buttonStyle='text'
                onClick={() => insertTag(tag)}
                active={isSelected}
            >
                {
                    tagItem
                }
            </StyledButton>
        </li>
    )
}

export const TagPopup: React.FC<Props> = () => {
    const {tagSearchVal, selectedContact, selected, useChatTagAction} = useChatTagContext()

    const popupRef = useRef<any>(null);

    useOnClickOutside(popupRef, () => useChatTagAction({type: 'clearState'}));

    const {
        availableTags,
    } = useTagSelector();

    const selectedUuid = availableTags[selected]?.data?.uuid;

    useEffect(() => {
        const el: any = popupRef.current;

        if (tagSearchVal !== null && el) {
            const domSelection = window.getSelection()
            const domRange = domSelection?.getRangeAt(0)
            const rect: any = domRange?.getBoundingClientRect()
            el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
            el.style.left = `${
                rect.left +
                window.pageXOffset -
                el.offsetWidth / 2 +
                rect.width / 2}px`
        }
    }, [tagSearchVal]);

    return (
        <AnimatedDiv
            ref={popupRef}
            className={styles.tag_popup_container}
            visible={tagSearchVal !== undefined || !!selectedContact}
            onMouseDown={e => e.preventDefault()}
        >
            <AnimatePresence>
                {tagSearchVal !== undefined ? (
                    <StyledList
                        options={availableTags}
                        component={(props: ChatTagType) => (
                            <RenderTag
                                key={props.data.uuid}
                                tag={props}
                                isSelected={selectedUuid === props.data.uuid}
                            />
                        )}
                        className={styles.option_container}
                    />
                ) : null}
            </AnimatePresence>
            <AnimatedDiv visible={!!selectedContact} className={styles.tag_popup}>
                {selectedContact ? (
                    <SelectedPhonebookContact
                        contact={selectedContact}
                    />
                ) : null}
            </AnimatedDiv>
        </AnimatedDiv>
    )
}

export default TagPopup;
