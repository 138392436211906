import React from "react";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    CallWhenAvailableUser,
    deleteCallWhenAvailable,
    selectUserStatus,
    startCall
} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";
import {StyledButton} from "../StyledComponents";
import AppNotification from "./AppNotification";

interface Props {
    whenAvailableUser: CallWhenAvailableUser;
    setUnseenList: (val: (prev: CallWhenAvailableUser[]) => CallWhenAvailableUser[]) => void;
}

export const CallQueuedNotification: React.FC<Props> = ({whenAvailableUser, setUnseenList}) => {
    const status = useTypedSelector((state) => selectUserStatus(state, whenAvailableUser.sip_name));

    const dispatch = useTypedDispatch()
    const {t} = usePolyglot();

    if (!status || (status.status === 'open' && !status.global_dnd)) return null;

    return (
        <AppNotification
            title={`Call queued - ${whenAvailableUser.display_name}`}
            message="You will be notified when the user is available."
            onClose={() => setUnseenList(prev => prev.filter(u => u.user_uuid !== whenAvailableUser.user_uuid))}
            holdDelay={7500}
            controls={(<>
                <StyledButton
                    buttonStyle='primary'
                    context="success"
                    size='large'
                    onClick={() => {
                        dispatch(startCall(whenAvailableUser.extension, whenAvailableUser.display_name));
                        dispatch(deleteCallWhenAvailable(whenAvailableUser.user_uuid))
                    }}
                >
                    <p>
                        {t("actions.call_now")}
                    </p>
                </StyledButton>
                <StyledButton
                    buttonStyle='text'
                    context="danger"
                    size='medium'
                    onClick={() => {
                        dispatch(deleteCallWhenAvailable(whenAvailableUser.user_uuid))
                    }}
                >
                    <p>
                        {t("actions.cancel")}
                    </p>
                </StyledButton>
            </>)}
        />
    )
}

export default CallQueuedNotification;