import Icon from "../../../../../components/Icon";
import styles from '../Toolbar.module.scss';
import {useChatContext} from "../../../../../context/ChatContext/context";
import {useChatInputContext} from "../../../../../context/ChatInputContext/context";
import AriaButton from "../../../../../components/AriaComponents/AriaButton";

interface Props {
    onSubmit: () => void;
    characterCount: number;
}

export const ChatInputMessageSend: React.FC<Props> = ({onSubmit, characterCount}) => {
    const {chat} = useChatInputContext()
    const {files} = useChatContext();

    // const cost = ((Math.ceil(characterCount / 153)) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "1,")

    const displayCharacters = `${characterCount % 153} / 153${characterCount >= 153 ? ` + ${Math.floor(characterCount / 153)}` : ''}`

    return (
        <div className={styles.send_message}>
            {chat.integration?.channel.toLowerCase() === 'sms' ? (
                <div className={styles.message_meta_data}>
                    <p>{displayCharacters}</p>
                    {/*
                    <p>£{cost}</p>
                    <div className={styles.costData}>
                        <p>{`Characters: ${displayCharacters}`}</p>
                        <p>{`Messages: ${Math.ceil(characterCount / 153)} SMS`}</p>
                        <p>Cost Per Message: £0.05</p>
                        <p className={styles.cost_total}>{`Total Cost: £${cost}`}</p>
                    </div>
                    */}
                </div>
            ) : null}
            <AriaButton
                onClick={() => onSubmit()}
                disabled={characterCount < 1 && (!files || files.length < 1)}
                className={`${styles.toolbar_button} ${styles.send_button}`}
            >
                <Icon name='paperplane'/>
            </AriaButton>
        </div>
    )
}

export default ChatInputMessageSend;
