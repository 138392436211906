/* eslint-disable react/state-in-constructor */
import {Component, ErrorInfo, ReactNode} from 'react';
import ErrorProvider from "../../context/ErrorContext/context";

interface Props {
    children: ReactNode;
    errorComponent: ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public errorState?: {
        error: Error;
        errorInfo: ErrorInfo
    };

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        this.errorState = {
            error,
            errorInfo
        }
    }

    public render() {
        if (this.state.hasError && this.errorState) {
            return (
                <ErrorProvider error={this.errorState.error} errorInfo={this.errorState.errorInfo}>
                    {this.props.errorComponent}
                </ErrorProvider>
            );
        }

        return this.props.children;
    }
}

    export default ErrorBoundary;
