/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import {SessionState} from "sip.js";
import {
    selectUsersForGroupCall,
    sendDTMF,
    startCall,
    selectShortCodes,
    updateCallDirect
} from '../../../../redux/slices';
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import NumberKeypad from "./tabs/NumberKeypad";
import ContactListKeypad from "./tabs/ContactListKeypad";
import {OptionsMenu, selectOpenParkingSlotShortcode} from "./tabs/OptionsMenu";
import styles from "../../Keypad.module.scss";
import InCallGroup from "./tabs/InCallGroup";
import {AnimatedDiv} from "../../../../animation";
import {usePolyglot} from "../../../../context/Polyglot";
import {useCallContext} from "../../../../context/CallContext/context";
import {useKeypadContext} from "../../../../context/KeypadContext/context";
import {useConferenceMethods} from "../../ConferenceHooks";

interface Props {
}

export type CallTabOptions =
    'options_menu'
    | 'number_keypad'
    | 'shortcodes'
    | 'transfer'
    | 'add_call'
    | 'add_to_call'
    ;

export const CallEstablished: React.FC<Props> = () => {
    const {call, currentTab, useCallAction} = useCallContext();
    const {keypadState} = useKeypadContext();

    const openParkingSlotShortcode = useTypedSelector(state => selectOpenParkingSlotShortcode(state));
    const shortcodes = useTypedSelector(selectShortCodes);
    const invitationList = useTypedSelector(selectUsersForGroupCall);

    const {t} = usePolyglot();
    const {inCallSubscribe} = useConferenceMethods()

    const [inputValue, setInputValue] = useState<string>('');
    const [groupError, setGroupError] = useState<boolean>(false);
    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (call.subscribed === true || !call.socketId) return;

        inCallSubscribe()

        dispatch(
            updateCallDirect({
                ...call,
                subscribed: true,
            })
        )
    }, []);

    useEffect(() => {
        const found = shortcodes?.find(cde => inputValue.substring(inputValue.length - 3).includes(cde.short_code))
        if (found) {
            dispatch(sendDTMF(call.id, found.short_code === '*11' ? openParkingSlotShortcode : found.short_code));
            if (found.feature.feature.toLowerCase() === 'parkretrieve') {
                setInputValue('')
            }
        }
    }, [inputValue]);

    useEffect(() => {
        if (keypadState.keypadActiveStatus) {
            window.addEventListener('keypress', handleKeyPad);
            window.addEventListener('keydown', handleSpecCharacter);
        } else {
            window.removeEventListener('keypress', handleKeyPad);
            window.removeEventListener('keydown', handleSpecCharacter);
        }

        return () => {
            window.removeEventListener('keypress', handleKeyPad);
            window.removeEventListener('keydown', handleSpecCharacter);
        };
    }, [keypadState.keypadActiveStatus]);

    useEffect(() => {
        if (invitationList.length > 0) {
            useCallAction({
                type: 'set_current_tab',
                payload: 'add_to_call'
            })
        }
    }, [invitationList.length]);

    const handleKeyPad = useCallback(
        (event: KeyboardEvent) => {
            if (
                (event.target as HTMLInputElement).nodeName === 'INPUT' ||
                (event.target as HTMLTextAreaElement).nodeName === 'TEXTAREA'
            )
                return;
            if (parseInt(event.key) || event.key === '0' || event.key === '*') {
                dispatch(sendDTMF(call.id, event.key));
                setInputValue(prevVal => prevVal + event.key);
            }
        },
        [call.id, dispatch]
    );

    const handleSpecCharacter = (event: KeyboardEvent) => {
        if (
            (event.target as HTMLInputElement).nodeName === 'INPUT' ||
            (event.target as HTMLTextAreaElement).nodeName === 'TEXTAREA'
        )
            return;
        if (event.key === 'Backspace') {
            setInputValue(prevVal => prevVal.slice(0, -1));
        }

        if (event.key === 'Enter') {
            dispatch(startCall(inputValue));
        }
    };

    const SearchVal = () => (!['transfer', 'add_to_call', 'add_call'].includes(currentTab)) ? (
        <button onClick={() => setInputValue("")}
                className={`${currentTab === 'transfer' ? styles.hidden : styles.dtmf_number}`}>
            <p title={t("phrases.click_to_clear")}>{inputValue}</p>
        </button>
    ) : null

    let currentPage;
    switch (currentTab) {
        case 'number_keypad':
        case 'shortcodes':
            currentPage = (
                <NumberKeypad
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            );
            break;
        case 'transfer':
        case 'add_call':
            currentPage = (
                <ContactListKeypad/>
            );
            break;
        case 'add_to_call':
            currentPage = (
                <InCallGroup
                    setGroupError={setGroupError}
                />
            );
            break;
        case 'options_menu':
        default:
            currentPage = (
                <OptionsMenu
                    groupError={groupError}
                />
            );
    }

    return (
        <AnimatedDiv
            inAnimation={['fade', 'slideFromBottom']}
            visible={(call.state === SessionState.Established) && keypadState.keypadActiveStatus}
            transition={{duration: .3, delay: .4}}
        >
            <SearchVal/>
            {currentPage}
        </AnimatedDiv>
    );
}

export default CallEstablished