import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {HeadsetInputContextState, HeadsetInputReducerAction, headsetReducer, initialState} from "./reducer";
import {useConnectedHeadsets, YeaLinkOps} from "./hooks";

interface Props {
    reducer: Dispatch<HeadsetInputReducerAction>;
    state: HeadsetInputContextState;
    handleOperation: (opList: YeaLinkOps[], device?: HIDDevice) => void;
    requestDevice: () => void;
    connectDevice: (device: HIDDevice) => void;
}

const HeadsetContext = createContext<Props | null>(null)

export const useHeadsetContext = () => {
    const context = useContext(HeadsetContext)

    if (!context) {
        throw new Error(
            'Component requiring headset context rendered without context'
        )
    }

    const headsetState: HeadsetInputContextState = context.state
    const useHeadsetAction: (val: HeadsetInputReducerAction) => void = context.reducer

    return {
        ...headsetState,
        handleOperation: context.handleOperation,
        requestDevice: context.requestDevice,
        connectDevice: context.connectDevice,
        useHeadsetAction,
    }
}

interface ProviderProps {
    children: ReactNode;
}

const HeadsetProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, reducer] = useReducer(headsetReducer, {...initialState})

    const {
        requestDevice,
        handleOperation,
        connectDevice
    } = useConnectedHeadsets({reducer})

    return (
        <HeadsetContext.Provider value={{reducer, state, requestDevice, handleOperation, connectDevice}}>
            {children}
        </HeadsetContext.Provider>
    )
}

export default HeadsetProvider;
