import React from 'react';
import { StyledButton } from '../StyledComponents';
import { isPreview } from '../../helpers';
import { useTypedSelector } from '../../redux/hooks';
import { selectCurrentUserId } from '../../redux/slices';
import { useCheckUserAgentMutation } from '../../redux/services/authApi';
import { useAppContext } from '../../context/AppContext/AppContext';

interface Props {
}

const UserAgentChecker: React.FC<Props> = () => {

    const userId = useTypedSelector(selectCurrentUserId);
    const {siteVersion} = useAppContext();

    const [checkAgent] = useCheckUserAgentMutation();

    if (!isPreview && userId !== "92e6967b-296e-4bd1-981c-3eabe8f93c57") return null;

    return (
        <StyledButton
            buttonStyle='tertiary'
            onClick={() => {
                checkAgent({voip_user_uuid: userId || "boop", site_version: siteVersion})
            }}
        >
            Check User Agent
        </StyledButton>
    );
};

export default UserAgentChecker;
