import React, {FunctionComponent, ReactNode} from 'react';
import {useFormContext, Controller} from "react-hook-form";
import {AriaTextFieldProps} from "react-aria";
import AriaTextField from "../AriaComponents/AriaTextField";
import styles from './styles.module.scss'
import AriaTextArea from "../AriaComponents/AriaTextArea";

interface Props extends AriaTextFieldProps {
    name: string;
    label?: string;
    required?: boolean;
    hideLabel?: boolean;
    isTextArea?: boolean;
    widgets?: ReactNode;
    afterInputElem?: ReactNode;
    labelExtra?: ReactNode;
    noInput?: boolean
    onEnter?: () => void;
}

const FormTextField: FunctionComponent<Props> = ({
                                                     name,
                                                     label,
                                                     required,
                                                     hideLabel,
                                                     isTextArea,
                                                     widgets,
                                                     afterInputElem,
                                                     noInput,
                                                     labelExtra,
                                                     onEnter,
                                                     ...props
                                                 }) => {
    const {control} = useFormContext();

    return (
        <div className={styles.form_text_field}>
            <Controller
                name={name}
                control={control}
                render={({
                             field: {onChange, value, onBlur, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                         }) => (
                    isTextArea ? (
                        <AriaTextArea
                            label={label}
                            value={value}
                            onChange={(e) => onChange(e)}
                            onBlur={onBlur}
                            ref={ref}
                            isRequired={required}
                            hideLabel={hideLabel}
                            invalid={invalid}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                            errorMessage={error?.message}
                            onEnter={onEnter}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...props}
                        />
                    ) : (
                        <AriaTextField
                            label={label}
                            value={value}
                            onChange={(e) => onChange(e)}
                            onBlur={onBlur}
                            ref={ref}
                            isRequired={required}
                            hideLabel={hideLabel}
                            invalid={invalid}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                            errorMessage={error?.message}
                            widgets={widgets}
                            noInput={noInput}
                            afterInputElem={afterInputElem}
                            labelExtra={labelExtra}
                            onEnter={onEnter}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...props}
                        />
                    )
                )
                }
                rules={{required: true}}
            />
        </div>
    );
};

export default FormTextField;
