import {useEffect, useRef, useState} from "react";
import moment from "moment";
import Icon from "../../../../../components/Icon";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {AnimatedDiv} from "../../../../../animation";
import AudioRecorder from "../../AudioRecorder";
import styles from '../Toolbar.module.scss';
import AriaButton from "../../../../../components/AriaComponents/AriaButton";
import usePlaceOver from "../../../../../helpers/placeAbove";

interface ChatInputAudioRecordingProps {
}

export const handleRecordAudio = async ({
                                            recording,
                                            setRecording
                                        }) => {
    try {
        const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
        const recorder = recording.recording.mediaRecorder;
        if (recorder && recorder?.state === 'recording') {
            recorder.stop();
        } else {
            setRecording({
                ...recording,
                recording: {
                    mediaStream: audioStream,
                    loading: true
                },
            });
        }
    } catch (err) {
        console.log(err);
    }
}

export const ChatInputAudioRecord: React.FC<ChatInputAudioRecordingProps> = () => {
    const {useChatAction} = useChatContext();

    const initialState = {
        recording: {
            mediaStream: undefined,
            mediaRecorder: null,
            loading: false
        },
        audio: {
            source: null
        }
    };

    const [popupVisible, setPopupVisible] = useState(false);
    const [recording, setRecording] = useState<any>(initialState);

    const containerRef = useRef<HTMLDivElement>(null);
    const recordingContainerRef = useRef<HTMLDivElement>(null);

    usePlaceOver({
        relativeElement: containerRef,
        fixedElement: recordingContainerRef,
        display: popupVisible
    })

    useEffect(() => {
        if (recording.recording.mediaStream) {
            setRecording({
                ...recording,
                recording: {
                    ...recording.recording,
                    mediaRecorder: new MediaRecorder(recording.recording.mediaStream)
                }
            })
        }
    }, [recording.recording.mediaStream]);

    useEffect(() => {
        const recorder = recording.recording.mediaRecorder;
        let chunks: any[] = [];

        if (recorder && recorder?.state === 'inactive') {
            recorder.start();

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, {type: 'audio/ogg; codecs=opus'});
                chunks = [];

                const stream = recording?.recording.mediaStream?.getTracks();
                if (stream) {
                    stream.forEach(track => track.stop());
                }

                // const file = new File([blob], `${t("noun.voice_note_%~_~")}`, {type: 'audio/ogg'})
                const file = new File([blob], `voice_note_${moment(new Date()).format("MM_DD_HH_mm")}`, {type: 'audio/ogg'})
                useChatAction({
                    type: 'add_file_to_upload',
                    payload: [file],
                })
                cancelRecording();
            }
        }
        return () => {
            if (recorder) recorder?.mediaStream?.getAudioTracks()?.forEach((track) => track.stop());
        }
    }, [recording.recording.mediaRecorder]);

    useEffect(() => {
        if (recording.recording.mediaRecorder?.state) {
            setRecording({
                ...recording,
                recording: {
                    ...recording.recording,
                    loading: false
                }
            })
        }
    }, [recording?.recording?.mediaRecorder?.state]);

    useEffect(() => {
        if (recording.recording.mediaStream?.active) {
            setPopupVisible(true)
        }
    }, [recording.recording]);

    // const {t} = usePolyglot();

    const cancelRecording = () => {
        setRecording(initialState)
        setPopupVisible(false);
    }

    useEffect(() => {
        if (popupVisible && recordingContainerRef.current || recording.recording.mediaRecorder !== null) {
            handleRecordAudio({recording, setRecording})
        }
    }, [popupVisible])

    return (
        <div
            className={styles.overlay_container}
             ref={containerRef}
        >
            <AriaButton
                className={styles.toolbar_button}
                // title={(recording.recording.mediaStream?.active)
                //     ? t("actions.stop_recording_%name", {name: t("terms.audio")})
                //     : t("actions.record_%name", {name: t("terms.audio")})
                // }
                dataTags={{
                    'data-is-recording': recording.recording.mediaStream?.active
                }}

                onClick={() => setPopupVisible(!popupVisible)}
            >
                <Icon name={recording.recording.mediaStream?.active ? 'tick' : 'record'}/>
            </AriaButton>
            <AnimatedDiv visible={popupVisible} ref={recordingContainerRef}>
                <AudioRecorder recording={recording} stopRecording={cancelRecording}/>
            </AnimatedDiv>
        </div>
    )
}

export default ChatInputAudioRecord;
