import React, {FunctionComponent, useState} from 'react';
import Icon from "../../../../../components/Icon";
import {isThread} from "../../../../../types";
import {Slider} from "../../../../../components";
import {usePolyglot} from "../../../../../context/Polyglot";
import {
    selectCurrentUserId, selectFavouriteChats,
    selectMutedChats,
    setActiveChat,
    updateFavouriteChat,
    updateMutedChat
} from "../../../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import {useLeaveChannelMutation} from "../../../../../redux/services/chatApi";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {useChannelMembers} from "../../../ChatHeader/hooks";
import styles from '../styles.module.scss';
import {StyledButton} from "../../../../../components/StyledComponents";
import {ChatSettingsSidebarMenus} from "../../index";

interface Props {
    setSideBarTab: (val: ChatSettingsSidebarMenus) => void;
}

const ChatSettingsMenuOptions: FunctionComponent<Props> = ({setSideBarTab}) => {
    const {chat} = useChatContext();
    const {t} = usePolyglot();

    const favouriteChats = useTypedSelector(selectFavouriteChats);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const mutedChats = useTypedSelector(selectMutedChats);

    const [leaveGroupWarning, setLeaveGroupWarning] = useState<boolean>(false);

    const {adminUuids} = useChannelMembers();

    const dispatch = useTypedDispatch()

    const [leaveChannel] = useLeaveChannelMutation()

    const handleMuteToggle = async () => {
        dispatch(
            updateMutedChat({
                userUuid,
                isChannel: chat.type === 'channel',
                uuidtoMute: chat.uuid,
                mute: !mutedChats[chat.uuid]
            })
        );
    }

    const handleChatLeave = async () => {
        dispatch(setActiveChat(''));
        await leaveChannel({
            voip_user_uuid: userUuid,
            channel_uuid: chat.uuid
        })
    }
    return (
        <div className={styles.menu_actions}>
            {adminUuids?.includes(userUuid) &&
                <StyledButton
                    buttonStyle='text'
                    onClick={() => setSideBarTab('addMembers')}
                >
                    <Icon name='addContact' ariaHidden/>
                    <p>{t("actions.add_user", 2)}</p>
                </StyledButton>
            }
            <StyledButton
                onClick={() => dispatch(updateFavouriteChat(chat.uuid, !favouriteChats[chat.uuid]))}
                buttonStyle='text'
            >
                <Icon name='pin' ariaHidden/>
                <p>{t(`actions.${favouriteChats[chat.uuid] ? 'unpin' : 'pin'}_${isThread(chat) ? 'thread' : 'chat'}`)}</p>
                <Slider
                    name={t("phrases.chat_pinned")}
                    checked={favouriteChats[chat.uuid]}
                    readOnly
                    tabIndex={-1}
                />
            </StyledButton>
            {!isThread(chat) ? (
                <StyledButton
                    onClick={() => handleMuteToggle()}
                    buttonStyle='text'
                >
                    <Icon name='muteUnfilled' ariaHidden/>
                    <p>{t("actions.mute_chat")}</p>
                    <Slider
                        name={t("phrases.presence_do_not_disturb")}
                        checked={mutedChats[chat.uuid]}
                        readOnly
                        tabIndex={-1}
                    />
                </StyledButton>
            ) : null}
            {chat?.type === 'channel' ? (
                <div className={styles.danger_select}>
                    <StyledButton
                        buttonStyle='text'
                        context='danger'
                        onClick={() => setLeaveGroupWarning(true)}
                        disabled={leaveGroupWarning}
                    >
                        <Icon name='logout' ariaHidden/>
                        <p>
                            {leaveGroupWarning ?
                                t("phrases.are_you_sure")
                                : t("actions.leave_group")
                            }
                        </p>
                    </StyledButton>
                    {leaveGroupWarning ? (<>
                        <StyledButton
                            buttonStyle='tertiary'
                            context='danger'
                            onClick={handleChatLeave}
                        >
                            <p>{t("actions.confirm")}</p>
                        </StyledButton>
                        <StyledButton
                            buttonStyle='tertiary'
                            onClick={() => setLeaveGroupWarning(false)}
                        >
                            <p>{t("actions.cancel")}</p>
                        </StyledButton>
                    </>) : null}
                </div>
            ) : null}
        </div>
    );
};

export default ChatSettingsMenuOptions;
