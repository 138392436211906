import React from "react";
import {focusNext} from "../../../helpers";


export const useKeypadContactsKeydown = () => {

    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement;

        let listNode;

        if (
            (target.parentNode as HTMLElement).className === 'recent-row__options'
            && target.nextSibling?.nodeName === 'UL'
        ) {
            listNode = target.parentNode
        }

        if (target.parentNode?.parentNode?.parentNode?.nodeName === 'UL') {
            listNode = target.parentNode?.parentNode?.parentNode?.parentNode
        }

        if (listNode) {
            focusNext({
                container: listNode as HTMLElement,
                before,
            })
            return
        }

        const keypadContactArray = e.currentTarget.parentNode && Array.from(e.currentTarget.parentNode?.children)

        if (!keypadContactArray) return;

        const currentIndex = keypadContactArray.findIndex(item => item.contains(target))

        const KeypadContactTarget = keypadContactArray[currentIndex + (before ? -1 : 1)]

        if (KeypadContactTarget) {
            focusNext({
                container: KeypadContactTarget as HTMLElement,
                before
            })
        }
    }

    const ACTIONS = {
        ArrowDown: (e) => handleArrowY(e),
        ArrowUp: (e) => handleArrowY(e, true),
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
    }

    return (e) => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}