import {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import Visualizer from "./Visualizer";
import styles from "./AudioRecorder.module.scss";
import Icon from "../../../../components/Icon";
import {AnimatedDiv} from "../../../../animation";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

interface AudioRecorderProps {
    recording: any;
    stopRecording: () => void;
}

export const AudioDuration = ({duration}) => {
    const formattedDuration = moment.utc(duration * 1000).format('mm:ss');

    return (
        <span className={styles.audio_duration}>{formattedDuration}</span>
    )
}

export const volumeMeter = async ({audioStream, setVolumeLevel, volumeInterval, setDuration}) => {
    try {
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(audioStream.current);
        const analyser = audioContext.createAnalyser();

        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;

        audioSource.connect(analyser);

        const volumes = new Uint8Array(analyser.frequencyBinCount);

        // eslint-disable-next-line no-param-reassign
        volumeInterval.current = setInterval(() => {
            analyser.getByteFrequencyData(volumes);

            const averageVolume =
                volumes.reduce((accumulator, curr) => accumulator + curr)

            // The max is actually 255, but it seems the mic is capped around 180
            const computedVolume = Math.floor(averageVolume / 170);

            setVolumeLevel((prev) => {
                const newVolume = [...prev, computedVolume]
                newVolume.shift();
                return [...newVolume]
            })

            setDuration(Math.floor(analyser.context.currentTime))

        }, 200);
    } catch (e) {
        console.error('Failed to initialize volume visualizer', e);
    }
};

const AudioRecorder = ({recording, stopRecording}: AudioRecorderProps) => {
    const audioStream = useRef<MediaStream>(recording.mediaStream);
    const volumeInterval = useRef<NodeJS.Timeout>();
    const [volumeLevel, setVolumeLevel] = useState<number[]>([]);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (recording.recording.mediaStream?.active && volumeLevel.length === 0) {
            for (let i = 0; i < 20; i += 1) {
                setVolumeLevel(prev => [...prev, 0])
            }
            audioStream.current = recording.recording.mediaStream;
            volumeMeter({
                audioStream,
                setVolumeLevel,
                volumeInterval,
                setDuration
            });
        }
    }, [recording.recording]);

    return (
        <div className={styles.audio_recorder_container}>
            <div className={styles.audio_recorder}>
                <AnimatedDiv visible={!recording.recording.mediaStream?.active} className={styles.loader}>
                    <LoadingSpinner />
                </AnimatedDiv>
                <AnimatedDiv visible={recording.recording.mediaStream?.active}>
                    <Visualizer volume={volumeLevel}/>
                </AnimatedDiv>
                <AudioDuration duration={duration}/>
                <AriaButton
                    className={styles.button_close}
                    onClick={() => stopRecording()}
                >
                    <Icon name="cross"/>
                </AriaButton>
            </div>
        </div>
    )
}

export default AudioRecorder;
