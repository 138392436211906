import React from 'react';
import MuiSlider from "@mui/material/Slider";
import styles from './styles.module.scss';

interface Props {
    max: number;
    duration: number;
    disabled?: boolean;
    value: number;
    onScrub: (val: number) => void;
}

const StyledTrack: React.FC<Props> = ({
                                          max,
                                          duration,
                                          disabled,
                                          value,
                                          onScrub
                                      }) => (
    <MuiSlider
        size="small"
        min={0}
        max={max}
        value={value}
        step={Math.max(0.025, Math.min(0.1, duration / 1000))}
        style={{
            color: 'var(--primary-color)',
        }}
        onChange={(e) => {
            let newVal = parseFloat((e.target as any).value || '');

            if (Number.isNaN(newVal)) {
                newVal = 0
            }

            if (newVal !== undefined) {
                onScrub(newVal)
            }
        }}
        disabled={disabled}
        classes={{
            root: styles.audio_slider,
            active: styles.active,
            thumb: styles.thumb,
            rail: styles.rail,
        }}
        data-comp='audio-attachment__player'
    />
);

export default StyledTrack;
