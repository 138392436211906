import React, { useEffect, useMemo, useRef, useState } from 'react';
import ModalFormContainer from '../components/forms/modalFormContainer/ModalFormContainer';
import formStyles from '../sass/globalModules/forms.module.scss';
import { versionCompare } from '../helpers';
import StyledTabs from '../components/StyledComponents/StyledTabs';
import { StyledButton } from '../components/StyledComponents';
import Icon from '../components/Icon';
import { useAppContext } from '../context/AppContext/AppContext';

interface Props {}

const AppForceUpdater: React.FC<Props> = () => {
    const updateTries = useRef(0);
    const [open, setOpen] = useState(false);

    const { packageVersion, forceCloseAssets } = useAppContext();

    const [isMac, setIsMac] = useState(navigator.userAgent.includes('Macintosh'));

    const options = useMemo(() => {
        const base = [
            {
                label: 'Windows',
                value: false
            },
            {
                label: 'Mac',
                value: true
            }
        ];

        if (isMac) {
            base.reverse();
        }

        return base;
    }, []);

    useEffect(() => {
        if (!packageVersion) {
            return;
        }

        if (versionCompare(packageVersion, '1.1.1') > -1) {
            return;
        }

        try {
            updateTries.current = parseInt(localStorage.getItem('update_tries') || '0');
        } catch (e) {
            updateTries.current = 0;
        }

        setOpen(updateTries.current > -1);
    }, []);

    const onClose = () => {
        localStorage.setItem('update_tries', String(updateTries.current + 1));
        setOpen(false);
    };

    return (
        <ModalFormContainer
            showModal={open}
            setShowModal={() => {
                onClose();
            }}
        >
            <div className={formStyles.formContainer} style={{ width: 500 }}>
                <section className={formStyles.formHeader}>
                    <h2>App Updated</h2>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        dataTags={{
                            'data-pos': 'right'
                        }}
                        onClick={() => onClose()}
                    >
                        <Icon name='cross' />
                    </StyledButton>
                </section>
                <p>Please quit and and restart to complete.</p>
                <StyledTabs
                    options={options}
                    selectedTab={isMac}
                    setSelectedTab={a => setIsMac(a)}
                />
                <img
                    style={{
                        maxWidth: 240,
                        maxHeight: 240
                    }}
                    src={isMac ? forceCloseAssets.mac : forceCloseAssets.win}
                    alt='windows tray close'
                />
                <ul>
                    {isMac ? (
                        <li style={{ listStyleType: 'circle' }}>
                            Please right click the app icon in the dock and select &quot;Quit&quot;.
                        </li>
                    ) : (
                        <li style={{ listStyleType: 'circle' }}>
                            Please open the app tray, right click the app icon, and select
                            &quot;Exit&quot;.
                        </li>
                    )}
                    <li style={{ listStyleType: 'circle' }}>
                        Then, reopen the application to complete the update.
                    </li>
                </ul>
            </div>
        </ModalFormContainer>
    );
};

export default AppForceUpdater;
