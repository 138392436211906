import React from 'react';
import moment from 'moment/moment';
import { focusNext } from '../../../../helpers';
import { IYeOldeCall } from '../../../../types';
import { usePolyglot } from '../../../../context/Polyglot';
import { useTypedSelector } from '../../../../redux/hooks';
import { selectCurrentUserId, selectCurrentUserSipName } from '../../../../redux/slices';

export const useCallHistoryKeyDown = () => {
    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        let navElement: any = before
            ? e.currentTarget.previousSibling
            : e.currentTarget.nextSibling;

        if (!navElement) return;

        if ((navElement?.firstChild as HTMLElement).className === 'list-divider') {
            navElement = before ? navElement?.previousSibling : navElement?.nextSibling;
        }

        if (!navElement) return;

        if (navElement) {
            focusNext({
                container: navElement
            });
        }
    };

    const handleArrowX = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        focusNext({
            container: e.currentTarget,
            before
        });
    };

    const ACTIONS = {
        ArrowUp: e => handleArrowY(e, true),
        ArrowDown: e => handleArrowY(e),
        ArrowLeft: e => handleArrowX(e, true),
        ArrowRight: e => handleArrowX(e),
        Enter: e => e.target.click(),
        Space: e => e.target.click()
    };

    return e => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
};

export const useCallHistoryBase = (call: IYeOldeCall) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const userSipName = useTypedSelector(selectCurrentUserSipName);

    const { t } = usePolyglot();

    const formatCallDuration = (): string => {
        if (call.call_duration === -1) {
            return '';
        }

        if (!call.answered) {
            return !call.from || call.from?.uuid === userUuid || callType === 'Outbound'
                ? t(`adjective.call_unanswered`)
                : t('phrases.missed_call', 1);
        }

        const duration = call.call_duration;

        const hr = Math.floor(duration / 3600);
        const min = Math.floor((duration % 3600) / 60);
        const sec = Math.floor((duration % 3600) % 60);

        const hDisplay = hr > 0 ? [hr, t('abbreviations.time_hr', hr)] : [];
        const mDisplay = min > 0 ? [min, t('abbreviations.time_min', min)] : [];
        const sDisplay = [sec, t('abbreviations.time_sec', sec)];

        return [...hDisplay, ...mDisplay, ...sDisplay].join(' ');
    };

    const callType: 'Inbound' | 'Outbound' = ['outbound', 'outgoing'].includes(call.call_type)
        ? 'Outbound'
        : 'Inbound';

    const displayNumber =
        !call.from || call.from.name === userSipName ? call.to?.number : call.from.number;

    const displayName =
        !call.from || call.from.name === userSipName
            ? call.to?.nickname || call.to?.number
            : call.from.nickname || call.from.number;

    const callee =
        !call.from || call.from.name === userSipName
            ? call.to?.number || ''
            : call.from.number || '';

    const calleeUuid =
        !call.from || call.from.name === userSipName ? call.to?.uuid : call.from?.uuid;

    let callIcon: 'callIncoming' | 'callOutgoing' | 'group';

    if (call.call_type === 'room') {
        callIcon = 'group';
    } else {
        callIcon = callType === 'Outbound' ? 'callOutgoing' : 'callIncoming';
    }

    return {
        duration: formatCallDuration(),
        displayName,
        displayNumber,
        callee,
        callType,
        callIcon,
        calleeUuid,
        date: moment(call.call_start_time).format('HH:mm')
    };
};
