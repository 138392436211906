import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IAuthState,
    IPhonebookContact,
    PhonebookContactEntity
} from "../../../types";
import {authApi, ILoginResponse} from "../../services/authApi";
import {
    callGetPhonebookContacts,
    checkUserVerification,
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithToken
} from "./thunks";

export const phonebookEntityAdapter = createEntityAdapter<PhonebookContactEntity>({
    selectId: (ent) => ent.number
})

export function isValidContactEntity(entity?: PhonebookContactEntity) {
    if (!entity) return false;

    return entity.details.length > 0;
}

const initialState: IAuthState = {
    loading: 'idle',
    tokenLoading: 'idle',
    phonebookContacts: {},
    phonebookContactsLoading: {},
    phonebookContactSavingLoading: 'idle',
    integrations: [],
    phonebookEntities: phonebookEntityAdapter.getInitialState(),
};

const stateFromLogin = (state: IAuthState, payload: ILoginResponse, more: Partial<IAuthState> = {loginStep: undefined}): IAuthState => ({
    ...state,
    loggedInUser: payload.auth,
    users: payload?.users
        ? [...payload.users].sort((a, b) => a.nickname.localeCompare(b.nickname))
        : [],
    selectableUsers: payload.selectable_users,
    huntGroups: payload.hunt_groups || [],
    phonebooks: payload.phone_books || [],
    shortcodes: payload.short_codes || [],
    phoneNumbers: [...(payload.numbers || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
    ),
    callRoutes: payload.call_routes,
    timeDiaries: payload.time_diaries,
    integrations: payload.integrations,
    sounds: [...(payload.sounds || [])].sort((a, b) => a.name.localeCompare(b.name)),
    mailboxMenus: [...(payload.mailboxes || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
    ),
    departments: [...(payload.departments || [])].sort((a, b) => a.name.localeCompare(b.name)),
    isAus: payload.is_aus ?? (state.isAus || false),
    ...more
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoginStep: (state, {payload}) => ({
            ...state,
            loginStep: payload
        }),
        resetAuthLoading: state => ({
            ...state,
            loading: 'idle',
            tokenLoading: 'idle'
        }),
        deleteSelectedPhonebookContacts: (state, {payload: phonebookUuid}: { payload: string }) => ({
            ...state,
            phonebookContacts: {
                ...state.phonebookContacts,
                [phonebookUuid]: undefined
            },
            phonebookContactsLoading: {
                ...state.phonebookContactsLoading,
                [phonebookUuid]: undefined
            },
        }),
        resetPhonebookContacts: state => ({
            ...state,
            phonebookContacts: {},
            phonebookContactsLoading: {}
        }),
        resetPhonebookContactCreationLoading: state => ({
            ...state,
            phonebookContactSavingLoading: 'idle'
        }),
        fromPostPhonebookContact: (state, {payload}: {
            payload: { phonebookUuid: string; newContact: IPhonebookContact }
        }) => ({
            ...state,
            phonebookContacts: {
                ...state.phonebookContacts,
                [payload.phonebookUuid]: [...(state.phonebookContacts[payload.phonebookUuid] || []), payload.newContact]
            },
        }),
        fromPutPhonebookContact: (state, {payload}: {
            payload: { phonebookUuid: string; contactUuid: string; newContact: IPhonebookContact }
        }) => ({
            ...state,
            phonebookContacts: {
                ...state.phonebookContacts,
                [payload.phonebookUuid]: state.phonebookContacts[payload.phonebookUuid]
                    ? state.phonebookContacts[payload.phonebookUuid]?.map(contact =>
                        contact.uuid === payload.contactUuid ? payload.newContact : contact
                    )
                    : [payload.newContact],
            },
            phonebookContactSavingLoading: 'succeeded'
        }),
        fromDeletePhonebookContact: (state, {payload}: {
            payload: { phonebookUuid: string; contactUuid: string }
        }) => ({
            ...state,
            phonebookContacts: {
                ...state.phonebookContacts,
                [payload.phonebookUuid]: state.phonebookContacts[payload.phonebookUuid]?.filter(contact => (
                    contact.uuid !== payload.contactUuid
                ))
            },
            phonebookContactSavingLoading: 'succeeded'
        }),


        // Phonebook Entities
        addOnePhonebookEntity: (state, {payload}: PayloadAction<PhonebookContactEntity>) => {
            phonebookEntityAdapter.setOne(state.phonebookEntities, payload)
        },
        addManyPhonebookEntity: (state, {payload}: PayloadAction<PhonebookContactEntity[]>) => {
            phonebookEntityAdapter.setMany(state.phonebookEntities, payload)
        },
    },
    extraReducers: builder => {
        builder.addCase(loginWithEmailAndPassword.pending, state => ({
            ...state,
            loading: 'pending'
        }));
        builder.addCase(loginWithEmailAndPassword.rejected, state => ({
            ...state,
            loading: 'failed'
        }));
        builder.addCase(loginWithEmailAndPassword.fulfilled, (state, {payload}) =>
            stateFromLogin(state, payload, {
                loading: 'succeeded'
            })
        );
        builder.addCase(loginWithToken.pending, state => ({
            ...state,
            tokenLoading: 'pending'
        }));
        builder.addCase(loginWithToken.rejected, state => ({
            ...state,
            tokenLoading: 'failed'
        }));
        builder.addCase(loginWithToken.fulfilled, (state, {payload}) =>
            stateFromLogin(state, payload, {
                tokenLoading: 'succeeded'
            })
        );
        builder.addCase(loginWithGoogle.pending, state => ({
            ...state,
            loading: 'pending'
        }));
        builder.addCase(loginWithGoogle.rejected, state => ({
            ...state,
            loading: 'google_failed'
        }));
        builder.addCase(loginWithGoogle.fulfilled, (state, {payload}) =>
            stateFromLogin(state, payload, {
                loading: 'succeeded'
            })
        );
        builder.addCase(checkUserVerification.rejected, state => state);
        builder.addCase(checkUserVerification.fulfilled, (state, {payload}) => {
            if (state.loggedInUser) {
                return {
                    ...state,
                    loggedInUser: {
                        ...state.loggedInUser,
                        verifications: payload
                    }
                };
            }

            return state;
        });
        builder.addCase(callGetPhonebookContacts.pending, (state, {meta}) => ({
            ...state,
            phonebookContactsLoading: {
                ...state.phonebookContactsLoading,
                [meta.arg.phonebookUuid]: 'pending'
            }
        }));
        builder.addCase(callGetPhonebookContacts.rejected, (state, {meta}) => ({
            ...state,
            phonebookContactsLoading: {
                ...state.phonebookContactsLoading,
                [meta.arg.phonebookUuid]: 'failed'
            }
        }));
        builder.addCase(callGetPhonebookContacts.fulfilled, (state, {payload, meta}: { payload: any, meta: any }) => {

            if (Array.isArray(payload.result.contacts)) {
                return {
                    ...state,
                    phonebookContactsLoading: {
                        ...state.phonebookContactsLoading,
                        [meta.arg.phonebookUuid]: 'succeeded'
                    },
                    phonebookContacts: {
                        ...state.phonebookContacts,
                        [meta.arg.phonebookUuid]: payload.result.contacts
                    }
                };
            }

            return state;
        });
        builder.addMatcher(
            authApi.endpoints.getDeviceLinkCode.matchFulfilled,
            (state, {payload}) => {
                if (payload?.login_result) {
                    return stateFromLogin(state, payload.login_result);
                }

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.postConfirmationCode.matchFulfilled,
            (state, {payload}) => {
                if ('login_result' in payload) {
                    return stateFromLogin(state, payload.login_result);
                }

                if ('selectable_accounts' in payload) {
                    return {
                        ...state,
                        loginStep: 'accounts',
                        selectableAccounts: payload.selectable_accounts
                    };
                }

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.postRequestDeployment.matchFulfilled,
            (state, {payload}) => {
                if (payload?.auth?.user_details) {
                    return stateFromLogin(state, payload);
                }

                return {...state, loginStep: 'pending-approval'};
            }
        );
        builder.addMatcher(
            authApi.endpoints.getDeploymentStatus.matchFulfilled,
            (state, {payload}) => {
                if (payload?.auth?.user_details) {
                    return stateFromLogin(state, payload);
                }

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.getAccountUser.matchFulfilled,
            (state, {payload}) => stateFromLogin(state, payload)
        );
        builder.addMatcher(
            authApi.endpoints.postLoginWithEmailAndPassword.matchFulfilled,
            (state, {payload}) => stateFromLogin(state, payload, {
                loginStep: payload.matched_accounts?.length > 0 ? 'accounts' : 'users'
            })
        );
        builder.addMatcher(
            authApi.endpoints.postLoginWithSso.matchFulfilled,
            (state, {payload}) => stateFromLogin(state, payload, {
                loginStep: 'users'
            })
        );
        builder.addMatcher(
            authApi.endpoints.getLoginWithToken.matchFulfilled,
            (state, {payload}) => stateFromLogin(state, payload, {
                loginStep: 'users'
            })
        );
    }
});

export const auth = authSlice.reducer;