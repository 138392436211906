import React, {lazy, Suspense, useState} from 'react';
import styles from "./styles.module.scss";
import StyledTabs from "../../../components/StyledComponents/StyledTabs";
import {useTypedSelector} from "../../../redux/hooks";
import {
    selectMailboxes,
    selectNewVoicemailCountOnBox
} from "../../../redux/slices";
import VoicemailSkeleton from "./skeletons";
import {MailboxDisplay} from "../../../types";

const MailboxMessages = lazy(() => import('./MailboxMessages'))

interface Props {
}

const BoxLabel: React.FC<{ box: MailboxDisplay }> = ({box}) => {
    const unseenCount = useTypedSelector(state => selectNewVoicemailCountOnBox(state, box.uuid));
    return (
        <p className={styles.tab_node}>
            {box.name}
            {box.is_primary ? <>&nbsp;(Primary)</> : null}
            {unseenCount ? (
                <i className={styles.noti}>
                    {unseenCount > 99 ? "+99" : unseenCount}
                </i>
            ) : null}
        </p>
    )
}

const VoicemailPage: React.FC<Props> = () => {
    const allMailboxes = useTypedSelector(selectMailboxes);

    const [selectedMailbox, setSelectedMailbox] = useState<string | undefined>(allMailboxes?.[0]?.uuid);

    return (
        <div className={styles.voicemail_section}>
            {(allMailboxes.length > 1) ? (
                <StyledTabs
                    options={allMailboxes.map(m => ({
                        value: m.uuid,
                        label: `${m.name}${m.is_primary ? ' (Primary)' : ''}`,
                        node: <BoxLabel box={m}/>
                    }))}
                    selectedTab={selectedMailbox}
                    setSelectedTab={setSelectedMailbox}
                />
            ) : null}
            <Suspense fallback={<VoicemailSkeleton/>}>
                {selectedMailbox ? (
                    <MailboxMessages
                        mailbox={selectedMailbox}
                        key={selectedMailbox}
                    />
                ) : null}
            </Suspense>
        </div>
    );
};

export default VoicemailPage;
