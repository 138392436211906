import React, {forwardRef, ReactNode} from "react";
import {useTypedSelector} from "../../redux/hooks";
import {selectActivePage, selectAppSettingByKey} from "../../redux/slices";
import Resizer from "./resizer";

interface Props {
    activeSidebar: ReactNode;
}

const Aside = forwardRef<HTMLElement, Props>(
    ({activeSidebar}, ref) => {
        const width = useTypedSelector(state => selectAppSettingByKey(state, 'asideWidth'));
        const page = useTypedSelector(selectActivePage);

        return (
            <aside
                ref={ref}
                style={{width: (page === 'settings' ? 360 : width)}}
            >
                {activeSidebar}
                <Resizer
                    sidebarRef={ref}
                />
            </aside>
        )
    })

export default Aside;
