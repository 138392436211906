
export const MoodEmojiHappyV: React.FC<any> = ({
                                                    ariaHidden,
                                                    title,
                                                }) => (
    <svg
        width={45}
        height={45}
        viewBox="0 0 44 45"
        fill="none"
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">About</title>}
        <desc id="desc">Very Happy</desc>
            <path d="M22.156 39.4178C31.3692 39.4178 38.838 31.949 38.838 22.7358C38.838 13.5225 31.3692 6.05377 22.156 6.05377C12.9428 6.05377 5.474 13.5225 5.474 22.7358C5.474 31.949 12.9428 39.4178 22.156 39.4178Z" fill="url(#paint0_radial_61_333)"/>
            <path opacity="0.5" d="M22.156 39.4178C31.3692 39.4178 38.838 31.949 38.838 22.7358C38.838 13.5225 31.3692 6.05377 22.156 6.05377C12.9428 6.05377 5.474 13.5225 5.474 22.7358C5.474 31.949 12.9428 39.4178 22.156 39.4178Z" fill="url(#paint1_radial_61_333)"/>
            <path d="M18.2842 11.8342C18.1291 11.1824 17.3299 10.9419 16.1195 11.229C15.0875 11.4773 12.9693 12.5791 11.8908 14.9456C11.689 15.3801 12.1779 15.481 12.4028 15.1706C13.1632 14.1309 15.4755 12.4782 17.5316 12.2222C18.3618 12.1213 18.2842 11.8342 18.2842 11.8342Z" fill="url(#paint2_linear_61_333)"/>
            <path d="M16.7091 22.6737C17.7076 22.6737 18.517 21.0514 18.517 19.0502C18.517 17.049 17.7076 15.4267 16.7091 15.4267C15.7107 15.4267 14.9012 17.049 14.9012 19.0502C14.9012 21.0514 15.7107 22.6737 16.7091 22.6737Z" fill="url(#paint3_radial_61_333)"/>
            <path d="M16.7092 16.6527C17.4851 16.6527 18.1601 17.5528 18.5093 18.8718C18.486 16.9088 17.6868 15.3337 16.7092 15.3337C15.7238 15.3337 14.9323 16.9088 14.9091 18.8718C15.2582 17.5528 15.9332 16.6527 16.7092 16.6527Z" fill="url(#paint4_linear_61_333)"/>
            <path d="M33.4222 25.5601C33.2981 25.5135 33.1584 25.5058 33.0342 25.5446C29.5117 26.5532 25.8571 27.0576 22.1638 27.0576C18.4705 27.0576 14.8082 26.5455 11.2933 25.5446C11.1614 25.5058 11.0295 25.5135 10.9054 25.5601C10.5252 25.7152 9.91999 26.2894 10.7191 28.4852V28.493C11.8985 31.3018 15.0254 36.2365 22.156 36.2365C29.2711 36.2365 32.398 31.294 33.6084 28.493C34.3998 26.2894 33.8024 25.7152 33.4222 25.5601Z" fill="url(#paint5_radial_61_333)"/>
            <path d="M22.1561 35.453C25.1124 35.453 27.3314 34.5452 28.9919 33.3037C27.1685 32.4813 24.9184 31.9459 22.1561 31.9459C19.3939 31.9459 17.1438 32.489 15.3204 33.3192C16.9809 34.5452 19.2 35.453 22.1561 35.453Z" fill="url(#paint6_radial_61_333)"/>
            <path d="M33.1971 25.5135C33.1738 25.5135 33.1505 25.5135 33.1195 25.5213C29.5658 26.5455 25.8803 27.0654 22.1482 27.0654C18.4161 27.0654 14.7305 26.5455 11.1769 25.5213C11.1535 25.5135 11.1303 25.5135 11.0992 25.5135C10.8587 25.5369 9.71037 25.7774 10.6957 28.493V28.5008C11.1769 29.6337 11.976 31.1156 13.2252 32.4735C12.0536 31.0846 13.4968 30.3164 16.065 30.6268C18.6333 30.9372 22.1482 30.9449 22.1482 30.9449C22.1482 30.9449 25.6552 30.9372 28.2313 30.6268C30.7996 30.3164 32.2427 31.0846 31.0711 32.4735C32.3203 31.1156 33.1195 29.6337 33.6005 28.5008V28.493C34.586 25.7696 33.4376 25.5291 33.1971 25.5135Z" fill="url(#paint7_linear_61_333)"/>
            <path d="M31.2186 29.331C32.1963 29.1215 32.9334 28.3068 33.0265 27.3058L33.1196 26.3282C29.5659 27.3292 25.8804 27.8413 22.156 27.8413C18.4317 27.8413 14.7461 27.3292 11.1924 26.3282L11.2856 27.3058C11.3787 28.2991 12.1158 29.1137 13.0935 29.331C16.0574 29.975 19.099 30.2931 22.156 30.2931C25.2131 30.2931 28.2547 29.9673 31.2186 29.331Z" fill="url(#paint8_radial_61_333)"/>
            <path d="M26.0277 11.8342C26.1829 11.1824 26.9821 10.9419 28.1925 11.229C29.2244 11.4773 31.3427 12.5791 32.4212 14.9456C32.6229 15.3801 32.1341 15.481 31.9091 15.1706C31.1487 14.1309 28.8365 12.4782 26.7804 12.2222C25.9501 12.1213 26.0277 11.8342 26.0277 11.8342Z" fill="url(#paint9_linear_61_333)"/>
            <path d="M27.6029 22.6737C28.6013 22.6737 29.4108 21.0514 29.4108 19.0502C29.4108 17.049 28.6013 15.4267 27.6029 15.4267C26.6044 15.4267 25.795 17.049 25.795 19.0502C25.795 21.0514 26.6044 22.6737 27.6029 22.6737Z" fill="url(#paint10_radial_61_333)"/>
            <path d="M27.6029 16.6527C26.827 16.6527 26.1519 17.5528 25.8028 18.8718C25.8261 16.9088 26.6253 15.3337 27.6029 15.3337C28.5883 15.3337 29.3797 16.9088 29.403 18.8718C29.0538 17.5528 28.3788 16.6527 27.6029 16.6527Z" fill="url(#paint11_linear_61_333)"/>
            <defs>
                <radialGradient id="paint0_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.8738 15.9751) scale(20.1247)">
                    <stop stopColor="#FFDF30"/>
                    <stop offset="1" stopColor="#FFB82E"/>
                </radialGradient>
                <radialGradient id="paint1_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.8738 15.9751) scale(15.833)">
                    <stop stopColor="#FFE95F"/>
                    <stop offset="1" stopColor="#FFBB47" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint2_linear_61_333" x1="15.1221" y1="13.7334" x2="14.7152" y2="12.0233" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint3_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.163 19.142) rotate(77.7198) scale(3.45851 1.74095)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint4_linear_61_333" x1="16.7096" y1="15.407" x2="16.7096" y2="18.7864" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
                <radialGradient id="paint5_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.156 30.8779) scale(9.1898 9.1898)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <radialGradient id="paint6_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.2937 34.1108) scale(7.61636 2.30242)">
                    <stop offset="0.2479" stopColor="#FF0000"/>
                    <stop offset="1" stopColor="#C10000"/>
                </radialGradient>
                <linearGradient id="paint7_linear_61_333" x1="33.8653" y1="28.9889" x2="10.5889" y2="28.9889" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="0.5" stopColor="#512D00"/>
                    <stop offset="1" stopColor="#3C2200"/>
                </linearGradient>
                <radialGradient id="paint8_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.156 28.3143) scale(20.1535)">
                    <stop offset="0.00132565" stopColor="white"/>
                    <stop offset="1" stopColor="#A8BBBD"/>
                </radialGradient>
                <linearGradient id="paint9_linear_61_333" x1="29.1868" y1="13.7281" x2="29.5938" y2="12.018" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint10_radial_61_333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.1503 19.1409) rotate(102.28) scale(3.45851 1.74095)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint11_linear_61_333" x1="27.6025" y1="15.4071" x2="27.6025" y2="18.7864" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
            </defs>
    </svg>
)

export default MoodEmojiHappyV

