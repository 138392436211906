/* eslint-disable no-await-in-loop,no-restricted-syntax */
import React, {useEffect, useMemo, useState} from "react";
import {putCallHandling, selectCallHandlingSimple, selectCurrentUserId, selectDashboardUrl} from "../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {ThenTargetTypes, ThenTypes} from "../../types";
import styles from './SettingsPage.module.scss';
import {Radio, SelectInput} from "../../components";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {usePolyglot} from "../../context/Polyglot";
import SettingsDropdown from "./SettingsPages/SettingsComponents/SettingsDropdown";
import {StyledButton} from "../../components/StyledComponents";

interface AdvancedCallHandlingProps {
    callHandlingActive: boolean;
    returnToSender: boolean;
    setReturnToSender: (val: boolean) => void;
}

interface ICallHandlingModuleProps {
    title: string;
    sounds: { id: string; name: string }[];
    sound: string;
    onChangeSound: (value: string) => void;
    then: ThenTypes;
    onChangeThen: (value: ThenTypes) => void;
    finalOptions: { id: string; name: string }[];
    final: string;
    onChangeFinal: (value: string) => void;
    advanced: boolean;
    setAdvanced: (value: boolean) => void;
}

export const CallHandlingModule: React.FC<ICallHandlingModuleProps> = ({
                                                                           title,
                                                                           sounds,
                                                                           sound,
                                                                           onChangeSound,
                                                                           then,
                                                                           onChangeThen,
                                                                           finalOptions,
                                                                           final,
                                                                           onChangeFinal,
                                                                           advanced,
                                                                           setAdvanced
                                                                       }) => {
    const [textValue, setTextValue] = useState<string>('');
    const dashboardURL = useTypedSelector(selectDashboardUrl)

    const {t} = usePolyglot();

    const thenOptions: { id: ThenTypes; name: string }[] = [
        {
            id: 'busy',
            name: t("phrases.hang_up")
        },
        {
            id: 'voicemail',
            name: t("phrases.send_to_mailbox")
        },
        {
            id: 'forward',
            name: t("actions.forward")
        }
    ];

    useEffect(() => {
        setTextValue(finalOptions.find(option => option.id === final)?.name || final);
    }, [then]);

    return (
        <div className={styles.call_handling_module}>
            <h3>{title}...</h3>
            {advanced ? (
                <>
                    <p>
                        {t("phrases.advanced_call_routes_configured_p1")}
                        <a
                            href={`https://www.${dashboardURL}/account/voice/routing`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t("phrases.advanced_call_routes_configured_p2")}
                        </a>
                    </p>
                    <p>
                        {t("phrases.advanced_call_routes_configured_p3")}
                        <a onClick={() => setAdvanced(false)}>
                            {t("phrases.advanced_call_routes_configured_p4")}
                        </a>.
                    </p>
                </>
            ) : (
                <>
                    <SettingsDropdown
                        title={t("actions.play")}
                        options={sounds.map(s => ({
                            label: s.name,
                            value: s.id,
                        }))}
                        onSelect={onChangeSound}
                        selected={sound}
                    />
                    <SettingsDropdown
                        title={t("adverbs.then")}
                        options={thenOptions.map(tO => ({
                            label: tO.name,
                            value: tO.id,
                        }))}
                        onSelect={value => onChangeThen(value as ThenTypes)}
                        selected={then}
                    />
                    {['forward', 'voicemail'].includes(then) ? (
                        <SelectInput
                            name={then}
                            label={then === 'forward' ? t("phrases.forward_calls_to") : t("phrases.go_to_mailbox")}
                            value={final}
                            onClick={value => {
                                onChangeFinal(value);

                                if (then === 'forward') {
                                    setTextValue(
                                        finalOptions.find(option => option.id === value)?.name || ''
                                    );
                                }
                            }}
                            options={finalOptions}
                            textValue={textValue}
                            setTextValue={setTextValue}
                            onChange={({target}) => {
                                onChangeFinal(target.value);
                                setTextValue(target.value);
                            }}
                            placeholder={t("phrases.start_typing_to_search")}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
};

export const AdvancedCallHandling: React.FC<AdvancedCallHandlingProps> = ({
                                                                              callHandlingActive,
                                                                              returnToSender,
                                                                              setReturnToSender
                                                                          }) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const dashboardURL = useTypedSelector(selectDashboardUrl);
    const callHandlingSimple = useTypedSelector(selectCallHandlingSimple);
    const sounds = useTypedSelector(state =>
        (state.auth.sounds || []).map(({uuid, name}) => ({id: uuid, name}))
    );
    const mailboxMenus = useTypedSelector(state =>
        (state.auth.mailboxMenus || []).map(({uuid, name}) => ({id: uuid, name}))
    );

    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    const phoneNumbersOptions: {
        id: string;
        name: string;
        type: ThenTargetTypes;
    }[] = useTypedSelector(state =>
        (state.auth.phoneNumbers || []).map(({number, country_code, name}) => ({
            id: `+${country_code}${number}`,
            name,
            type: 'forward'
        }))
    );
    const sipUserOptions: {
        id: string;
        name: string;
        type: ThenTargetTypes;
    }[] = useTypedSelector(state =>
        (state.auth.users || []).map(({uuid, nickname}) => ({
            id: uuid,
            name: nickname,
            type: 'sipuser'
        }))
    );
    const huntGroupOptions: {
        id: string;
        name: string;
        type: ThenTargetTypes;
    }[] = useTypedSelector(state =>
        (state.auth.huntGroups || []).map(({uuid, name}) => ({
            id: uuid,
            name,
            type: 'huntgroup'
        }))
    );
    const [busyModule, setBusyModule] = useState<{
        sound: string;
        then: ThenTypes;
        voicemail: string;
        forward: string;
        advanced: boolean;
    }>({
        sound: callHandlingSimple?.WhenBusyCallHandling.sound_uuid || '',
        then: callHandlingSimple?.WhenBusyCallHandling.then || 'busy',
        voicemail: callHandlingSimple?.WhenBusyCallHandling.then_target || mailboxMenus[0]?.id,
        forward: callHandlingSimple?.WhenBusyCallHandling.then_target || phoneNumbersOptions[0]?.id,
        advanced: !!callHandlingSimple?.WhenBusyCallHandling.is_advanced
    });
    const [noAnswerModule, setNoAnswerModule] = useState<{
        sound: string;
        then: ThenTypes;
        voicemail: string;
        forward: string;
        advanced: boolean;
    }>({
        sound: callHandlingSimple?.WhenNoAnswerCallHandling.sound_uuid || '',
        then: callHandlingSimple?.WhenNoAnswerCallHandling.then || 'busy',
        voicemail: callHandlingSimple?.WhenNoAnswerCallHandling.then_target || mailboxMenus[0]?.id,
        forward:
            callHandlingSimple?.WhenNoAnswerCallHandling.then_target || phoneNumbersOptions[0]?.id,
        advanced: !!callHandlingSimple?.WhenNoAnswerCallHandling.is_advanced
    });
    const [unreachableModule, setUnreachableModule] = useState<{
        sound: string;
        then: ThenTypes;
        voicemail: string;
        forward: string;
        advanced: boolean;
    }>({
        sound: callHandlingSimple?.WhenUnreachableCallHandling.sound_uuid || '',
        then: callHandlingSimple?.WhenUnreachableCallHandling.then || 'busy',
        voicemail:
            callHandlingSimple?.WhenUnreachableCallHandling.then_target || mailboxMenus[0]?.id,
        forward:
            callHandlingSimple?.WhenUnreachableCallHandling.then_target ||
            phoneNumbersOptions[0]?.id,
        advanced: !!callHandlingSimple?.WhenUnreachableCallHandling.is_advanced
    });
    const forwardDropdownOptions: {
        id: string;
        name: string;
        type: ThenTargetTypes;
        divider?: boolean;
    }[] = [
        {
            id: 'forward',
            name: t("terms.phone_number", 2),
            type: 'forward',
            divider: true
        },
        ...phoneNumbersOptions,
        {
            id: 'sipuser',
            name: t("terms.sip_user", 2),
            type: 'sipuser',
            divider: true
        },
        ...sipUserOptions,
        {
            id: 'huntgroup',
            name: t("terms.hunt_group", 2),
            type: 'huntgroup',
            divider: true
        },
        ...huntGroupOptions
    ];

    const mailboxDropdownOptions: {
        id: string;
        name: string;
        type: ThenTargetTypes;
        divider?: boolean;
    }[] = [
        {
            id: 'mailbox',
            name: t("terms.mail_box", 2),
            type: 'forward',
            divider: true
        },
        ...mailboxMenus
    ];

    const [defaultState, setDefaultState] = useState({
        blind_transfer_return_to_sender: returnToSender,
        busyModule,
        noAnswerModule,
        unreachableModule,
    })

    useEffect(() => {
        setDefaultState({
            blind_transfer_return_to_sender: returnToSender,
            busyModule,
            noAnswerModule,
            unreachableModule,
        })
    }, []);


    const formData = useMemo(() => ({
            blind_transfer_return_to_sender: returnToSender,
            busyModule,
            noAnswerModule,
            unreachableModule,
        }
    ), [busyModule, noAnswerModule, unreachableModule, returnToSender]);

    const isFormDirty = useMemo(() => {
        let dirty = false;

        for (const [key, value] of Object.entries(formData)) {
            if (value !== defaultState[key]) {
                dirty = true;
            }
        }

        return dirty;
    }, [formData, defaultState]);

    const onSaveCallHandling = () => {
        setSaveLoading(true)
        dispatch(
            putCallHandling({
                data: {
                    // call_forwarding: {
                    //     forward_internal_calls: false,
                    //     internal_forward_destination: '',
                    //     forward_external_calls: false,
                    //     external_forward_destination: ''
                    // },
                    call_handling: {
                        blind_transfer_return_to_sender: returnToSender,
                        is_active: callHandlingActive,
                        new_when_busy: !busyModule.advanced
                            ? {
                                sound_uuid: busyModule.sound,
                                then: busyModule.then,
                                then_target:
                                    busyModule.then === 'forward'
                                        ? busyModule.forward
                                        : busyModule.voicemail,
                                then_target_type:
                                    forwardDropdownOptions.find(
                                        option => option.id === busyModule.forward
                                    )?.type || 'forward'
                            }
                            : undefined,
                        new_no_answer: !noAnswerModule.advanced
                            ? {
                                sound_uuid: noAnswerModule.sound,
                                then: noAnswerModule.then,
                                then_target:
                                    noAnswerModule.then === 'forward'
                                        ? noAnswerModule.forward
                                        : noAnswerModule.voicemail,
                                then_target_type:
                                    forwardDropdownOptions.find(
                                        option => option.id === noAnswerModule.forward
                                    )?.type || 'forward'
                            }
                            : undefined,
                        new_when_unreachable: !unreachableModule.advanced
                            ? {
                                sound_uuid: unreachableModule.sound,
                                then: unreachableModule.then,
                                then_target:
                                    unreachableModule.then === 'forward'
                                        ? unreachableModule.forward
                                        : unreachableModule.voicemail,
                                then_target_type:
                                    forwardDropdownOptions.find(
                                        option => option.id === unreachableModule.forward
                                    )?.type || 'forward'
                            }
                            : undefined
                    }
                },
                userUuid
            })
        )
            .then(() => {
                setSaveLoading(false)
            });
        setDefaultState({
            blind_transfer_return_to_sender: returnToSender,
            busyModule,
            noAnswerModule,
            unreachableModule,
        })
    };

    return (
        <div>
            <div className={styles.callHandling}>
                <div className='radio-select'>
                    <div className={styles.heading}>
                        <h3>{t("phrases.in_case_of_blind_transfer")}...</h3>
                        <div className={styles.saveArea}>
                            {saveLoading ? (
                                <LoadingSpinner color='mamba'/>
                            ) : null}
                            <StyledButton
                                buttonStyle='primary'
                                onClick={onSaveCallHandling}
                                disabled={!isFormDirty}
                                size='large'
                            >
                                <p>
                                    {t("actions.update")}
                                </p>
                            </StyledButton>
                            {/*
                            <Button
                                text={t("actions.update")}
                                type='success'
                                onClick={onSaveCallHandling}
                                disabled={!isFormDirty}
                            />
                            */}
                        </div>
                    </div>
                    <Radio
                        name='follow_call_handling'
                        label={t("phrases.follow_call_handling")}
                        checked={!returnToSender}
                        onChange={() => setReturnToSender(false)}
                    />
                    <Radio
                        name='return_to_sender'
                        label={t("phrases.return_to_sender")}
                        checked={returnToSender}
                        onChange={() => setReturnToSender(true)}
                    />
                </div>
                <CallHandlingModule
                    title={t("phrases.when_busy")}
                    sounds={[{id: '', name: t("phrases.no_sound")}, ...sounds]}
                    sound={busyModule.sound}
                    onChangeSound={value => setBusyModule({...busyModule, sound: value})}
                    then={busyModule.then}
                    onChangeThen={value => setBusyModule({...busyModule, then: value})}
                    finalOptions={
                        busyModule.then === 'forward'
                            ? forwardDropdownOptions
                            : mailboxDropdownOptions
                    }
                    final={
                        busyModule.then === 'forward'
                            ? busyModule.forward
                            : busyModule.voicemail
                    }
                    onChangeFinal={value =>
                        setBusyModule({
                            ...busyModule,
                            [busyModule.then === 'forward' ? 'forward' : 'voicemail']:
                            value
                        })
                    }
                    advanced={busyModule.advanced}
                    setAdvanced={value =>
                        setBusyModule({...busyModule, advanced: value})
                    }
                />
                <CallHandlingModule
                    title={t("phrases.when_i_dont_answer")}
                    sounds={[{id: '', name: t("phrases.no_sound")}, ...sounds]}
                    sound={noAnswerModule.sound}
                    onChangeSound={value =>
                        setNoAnswerModule({...noAnswerModule, sound: value})
                    }
                    then={noAnswerModule.then}
                    onChangeThen={value =>
                        setNoAnswerModule({...noAnswerModule, then: value})
                    }
                    finalOptions={
                        noAnswerModule.then === 'forward'
                            ? forwardDropdownOptions
                            : mailboxDropdownOptions
                    }
                    final={
                        noAnswerModule.then === 'forward'
                            ? noAnswerModule.forward
                            : noAnswerModule.voicemail
                    }
                    onChangeFinal={value =>
                        setNoAnswerModule({
                            ...noAnswerModule,
                            [noAnswerModule.then === 'forward'
                                ? 'forward'
                                : 'voicemail']: value
                        })
                    }
                    advanced={noAnswerModule.advanced}
                    setAdvanced={value =>
                        setNoAnswerModule({...noAnswerModule, advanced: value})
                    }
                />
                <CallHandlingModule
                    title={t("phrases.when_i_am_unreachable")}
                    sounds={[{id: '', name: t("phrases.no_sound")}, ...sounds]}
                    sound={unreachableModule.sound}
                    onChangeSound={value =>
                        setUnreachableModule({...unreachableModule, sound: value})
                    }
                    then={unreachableModule.then}
                    onChangeThen={value =>
                        setUnreachableModule({...unreachableModule, then: value})
                    }
                    finalOptions={
                        unreachableModule.then === 'forward'
                            ? forwardDropdownOptions
                            : mailboxDropdownOptions
                    }
                    final={
                        unreachableModule.then === 'forward'
                            ? unreachableModule.forward
                            : unreachableModule.voicemail
                    }
                    onChangeFinal={value =>
                        setUnreachableModule({
                            ...unreachableModule,
                            [unreachableModule.then === 'forward'
                                ? 'forward'
                                : 'voicemail']: value
                        })
                    }
                    advanced={unreachableModule.advanced}
                    setAdvanced={value =>
                        setUnreachableModule({...unreachableModule, advanced: value})
                    }
                />
                <div className={styles.description}>
                    <p>
                        {t("phrases.for_more_control_edit_call_routes")}
                    </p>
                    <p>
                        <a
                            href={`https://${dashboardURL}/account/voice/routing`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t("actions.open_dashboard")}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AdvancedCallHandling
