import React, {useState} from "react";
import styles from "../../ThemeSelector/Themes/Previews.module.scss"
import { useAppContext } from '../../../../../../context/AppContext/AppContext';

export const Compact: React.FC = () => {
    const {currentTheme, isYay, isCallSwitch} = useAppContext();

    const [theme, setTheme] = useState(currentTheme)

    let schema;
    switch (true) {
        case isYay:
            schema = 'yddab88d'
            break;
        case isCallSwitch:
            schema = 'cef527d1'
            break;
        default:
            schema = 'pe12cd8c'
    }

    const element = document.body;

    const observer = new MutationObserver((mutations: any) => {
        mutations.forEach((mutation) =>  {
            if (mutation.type === "attributes") {
                setTheme(document.body.getAttribute("data-theme") as any);
            }
        });
    });

    observer.observe(element, {
        attributes: true
    });

    return (
        <div data-schema={schema} className={styles.container}>
            <svg
                width={156}
                height={101}
                viewBox="0 0 156 101"
                fill="none"
                role='figure'
            >
                <title id="title">Compact Chat View Icon</title>
                <g clipPath="url(#clip0_1436_12095)" data-theme={theme}>
                    <rect x="0.840942" y="0.327118" width="156" height="101" rx="4" fill="white" data-fill="primary-background"/>
                    <rect x="14.8409" y="16.3271" width="24" height="24" rx="4" fill="#D9D9D9" data-fill="tertiary-background"/>
                    <path
                        d="M32.3748 26.1615C32.3748 29.2177 29.8972 31.6953 26.841 31.6953C23.7847 31.6953 21.3071 29.2177 21.3071 26.1615C21.3071 23.1052 23.7847 20.6276 26.841 20.6276C29.8972 20.6276 32.3748 23.1052 32.3748 26.1615Z"
                        fill="#626262" data-fill="primary-font" opacity="0.4"/>
                    <path
                        d="M19.0188 39.5174C19.0188 35.1974 22.5209 31.6953 26.8409 31.6953C31.161 31.6953 34.6631 35.1974 34.6631 39.5174V40.3271H19.0188V39.5174Z"
                        fill="#626262" data-fill="primary-font" opacity="0.4"/>
                    <rect x="14.8409" y="64.5204" width="24" height="24" rx="4" fill="#D9D9D9" data-fill="tertiary-background"/>
                    <path
                        d="M32.3748 74.3548C32.3748 77.411 29.8972 79.8886 26.841 79.8886C23.7847 79.8886 21.3071 77.411 21.3071 74.3548C21.3071 71.2985 23.7847 68.821 26.841 68.821C29.8972 68.821 32.3748 71.2985 32.3748 74.3548Z"
                        fill="#626262" data-fill="primary-font" opacity="0.4"/>
                    <path
                        d="M19.0188 87.7108C19.0188 83.3907 22.5209 79.8886 26.8409 79.8886C31.161 79.8886 34.6631 83.3907 34.6631 87.7108V88.5204H19.0188V87.7108Z"
                        fill="#626262" data-fill="primary-font" opacity="0.4"/>
                    <rect x="46.67" y="16.826" width="39.4619" height="7.6033" rx="3.80165" fill="#868895" data-fill="primary-font" opacity="0.4"/>
                    <rect x="46.67" y="64.5204" width="39.4619" height="7.6033" rx="3.80165" fill="#868895" data-fill="primary-font" opacity="0.4"/>
                    <rect x="46.67" y="32.7238" width="120.475" height="7.6033" rx="3.80165" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="46.67" y="44.6044" width="82.2175" height="7.6033" rx="3.80165" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                    <rect x="46.67" y="80.4183" width="120.475" height="7.6033" rx="3.80165" fill="#B6B7BF" data-fill="secondary-font" opacity="0.4"/>
                </g>
            </svg>
        </div>
    )
}
