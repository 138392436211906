import React, {FunctionComponent, useMemo, useState} from "react";
import {InterTenantClient, InterTenantUser} from "../../../../types";
import {useDebounce} from "../../../../helpers";
import {useGetInterTenantClientUsersQuery} from "../../../../redux/services/sipApi";
import {TextInput} from "../../../../components";
import styles from '../../syles.module.scss'
import InterTenantUserDisplay from "../../../../components/sidebars/Contact/Tenant/TenantContact";
import {ContactsSkeleton} from "../../../../components/Skeletons/ContactsSkeleton";

interface Props {
    client: InterTenantClient
}

const TenantItems: FunctionComponent<Props> = ({client}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const searchTermDebounce = useDebounce(searchTerm.toLowerCase(), 440);

    const {isLoading: usersLoading} = useGetInterTenantClientUsersQuery({tenant_uuid: client.uuid}, {
        skip: client.users !== undefined,
        refetchOnMountOrArgChange: true
    })

    const sortedUsers = useMemo((): InterTenantUser[] => {
        if (!client || !client.users) return [];

        return [...client.users].sort((a, b) => a.user_display_name.localeCompare(b.user_display_name));
    }, [client?.users]);

    const displayUsers = useMemo(() => {
        if (!searchTermDebounce) {
            return sortedUsers;
        }

        return sortedUsers.filter(u => {
            if (u.user_display_name.toLowerCase().includes(searchTermDebounce)) {
                return true;
            }
            return !!u.extension?.toString().toLowerCase().includes(searchTermDebounce);

        })
    }, [sortedUsers, searchTermDebounce]);

    return (<>
        {/*
            {ddiMatch ? (
                <div className={styles.ddi_match}>
                    <InterTenantUserDisplay data={ddiMatch}/>
                </div>
            ) : null}
            */}
        <div className={styles.item}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
                Prefix
            </label>
            <div>
                <p>
                    {client.prefix}
                </p>
            </div>
        </div>
        <div
            className={styles.item}
            data-input="true"
        >
            <TextInput
                name="search_inter_tenant_users"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                isSearch
                isSearching={searchTerm !== searchTermDebounce}
            />
        </div>
        <div
            className={styles.item}
        >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
                Users
            </label>
            <div
                className={styles.contacts}
                data-loading-tc="true"
            >
                {usersLoading ? (
                    <>
                        <ContactsSkeleton skeletonType='singleRowPhoneBooks' text=' '/>
                        <ContactsSkeleton skeletonType='singleRowPhoneBooks' text=' '/>
                        <ContactsSkeleton skeletonType='singleRowPhoneBooks' text=' '/>
                    </>
                ) : displayUsers.map(u => (
                    <InterTenantUserDisplay
                        key={u.user_uuid}
                        data={u}
                        prefix={client.prefix}
                        tenant={client.uuid}
                    />
                ))}
            </div>
        </div>
    </>);
};

export default TenantItems;