/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Icon from "../Icon";
import LoadingSpinner from "../loading/LoadingSpinner";

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
    name: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    required?: boolean;
    validation?: RegExp;
    error?: string | JSX.Element;
    isSearch?: boolean;
    border?: 'all' | 'none';
    disabled?: boolean;
    isSearching?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
    name,
    onChange,
    label,
    type,
    required,
    validation,
    error,
    isSearch,
    className,
    border,
    disabled,
    isSearching,
    ...props
}) => {
    const [isValid, setIsValid] = useState<boolean | undefined>();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    let inputType = type || 'text';
    let borderClassName: string | undefined;

    if (showPassword && type === 'password') {
        inputType = 'text';
    }

    switch (border) {
        case 'none':
            borderClassName = 'no-border';
            break;

        case 'all':
            borderClassName = 'all-border';
            break;
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (validation) {
            if (validation.test(event.target.value)) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }

        onChange(event);
    };

    return (
        <div
            className={[
                'text-input',
                borderClassName || undefined,
                isSearch ? 'search-Icon' : undefined,
                className
            ]
                .join(' ')
                .trim()}
        >
            {(isSearch && !isSearching) ? (<Icon name='search' />) : null}
            {(isSearch && isSearching) ? (<LoadingSpinner color='mamba'/>) : null}
            {label && <label htmlFor={name}>{label}</label>}
            {required && <div className='required'>*</div>}
            <input id={name} type={inputType} onChange={onInputChange} {...props} disabled={disabled}/>
            {(isValid !== undefined || type === 'password') && (
                <div className='validation-container'>
                    {type === 'password' && (
                        <button
                            type='button'
                            onMouseDown={() => setShowPassword(true)}
                            onMouseUp={() => setShowPassword(false)}
                            onMouseLeave={() => setShowPassword(false)}
                        >
                            {showPassword ? (
                                <i className='icon-eye' />
                            ) : (
                                <i className='icon-eye-cross' />
                            )}
                        </button>
                    )}
                    {isValid !== undefined &&
                        (isValid ? <i className='icon-check' /> : <i className='icon-cross-2' />)}
                </div>
            )}
            {error && (
                <div className='error-message'>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
};
