import * as Sentry from '@sentry/react';
import {RootState} from "../store";

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state: RootState) => {
        if (state?.auth?.loggedInUser) {
            scope.setUser({
                id: state.auth.loggedInUser.reseller_user_uuid,
                email: state.auth.loggedInUser.email_address,
                username: state.user?.nickname || 'Not logged in'
            });
        }
        scope.setSpan(scope.getSpan());
    },
    stateTransformer: () => null,
    actionTransformer: action => {
        if (action.type === 'status/updateUserStatus') {
            return null;
        }

        return action;
    }
});