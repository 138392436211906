/* eslint-disable react/jsx-props-no-spreading */
import {ReactNode} from "react";
import {FieldError} from "react-hook-form";
import {LabelAriaProps} from 'react-aria';
import formStyles from "../AriaForm/styles.module.scss";
import {focusNext} from "../../helpers";

interface Props
    // extends AriaTextFieldProps
{
    hideLabel?: boolean;
    invalid?: boolean;
    isTouched?: boolean;
    isDirty?: boolean;
    error?: FieldError;
    onEnter?: () => void;
    type?: "search" | "text" | "email" | "password" | "tel" | "url" | string | undefined;
    isSearch?: boolean;
    onSearchClick?: () => void;
    widgets?: ReactNode;
    extraKeydown?: (e: React.KeyboardEvent) => void;
    children: ReactNode;
    labelProps: LabelAriaProps
    isRequired?: boolean;
    className?: string;
}

const AriaFormGenericField: React.FC<Props> = ({
                                                   hideLabel,
                                                   labelProps,
                                                   isRequired,
                                                   children,
                                                   invalid,
                                                   isTouched,
                                                   isSearch,
                                                   isDirty,
                                                   error,
                                                   className
                                               }) => (
    <div
        className={[formStyles.form_text_field, className].join(' ')}
    >
        {!hideLabel ? (
            <label {...labelProps}>{labelProps.label}</label>
        ) : null}
        {isRequired ? (
            <mark>*</mark>
        ) : null}
        <div
            className={formStyles.input_container}
            data-invalid={invalid || null}
            data-is-touched={isTouched || null}
            data-is-dirty={isDirty || null}
            data-error={!!error || null}
            data-is-search={isSearch || null}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    focusNext({
                        container: e.currentTarget,
                        querySelector: 'input'
                    })
                }
            }}
        >
            {
                children
            }
        </div>
        {/*
            {props.description && (
                <div
                    className={styles.description}
                    {...descriptionProps}
                >
                    {props.description}
                </div>
            )}
            {errorMessage && (
                <div
                    className={styles.errorMessage}
                >
                    {errorMessage}
                </div>
            )}
            */}
    </div>
);

export default AriaFormGenericField;
