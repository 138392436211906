import React, {FunctionComponent, useRef} from 'react';
import {mergeProps, useButton, useLongPress} from "react-aria";
import {AriaLongPressButtonProps} from "./types";

const AriaLongPressButton: FunctionComponent<AriaLongPressButtonProps> = ({
                                                                              onClick,
                                                                              children,
                                                                              disabled,
                                                                              className,
                                                                              dataTags,
                                                                              longPress,
                                                                              longPressDescription,
                                                                              tabIndex,
                                                                              type = 'button'
                                                                          }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const {buttonProps} = useButton({
        isDisabled: disabled,
        onPress: (e) => onClick ? onClick(e as any) : null,
        type
    }, buttonRef);

    const {longPressProps} = useLongPress({
        accessibilityDescription: longPressDescription || undefined,
        onLongPress: (e) => {
            longPress(e)
        }
    })

    const mergedProps = mergeProps(buttonProps, longPressProps)

    return (
        <button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...mergedProps} {...dataTags}
            className={className}
            tabIndex={tabIndex}
            aria-hidden={tabIndex === -1}
        >
            {children}
        </button>
    );
};

export default AriaLongPressButton;
