import React from "react";
import styles from "../../../../Keypad.module.scss"
import {useCallContext} from "../../../../../../context/CallContext/context";

interface Props {
}

export const SmartDiallerActiveDisplay: React.FC<Props> = () => {
    const {call} = useCallContext();

    const {smartDiallerData} = call;

    if (!smartDiallerData) return null

    return (
        <div className={styles.transferring_display}>
            <p>
                Call active on:
            </p>
            <p>
                {smartDiallerData.title}
            </p>
        </div>
    )
}

export default SmartDiallerActiveDisplay;
