import { Store, Dispatch, PayloadAction } from '@reduxjs/toolkit';

export const electron =
    (store: Store) => (next: Dispatch) => async (action: PayloadAction<any>) => {
        if ((window as any).electron) {
            switch (action.type) {
                case 'sip/connect':
                    (window as any).electron?.receive('new-call', (url: string) => {
                        const number = decodeURI(url).split(':').pop();

                        store.dispatch({
                            type: 'sip/call',
                            payload: {
                                callee: number,
                                displayName: number
                            }
                        });
                    });

                    (window as any).electron?.receive('answer-call', (id: string) => {
                        if (id) {
                            store.dispatch({
                                type: 'sip/answer',
                                payload: id
                            });
                        }
                    });

                    (window as any).electron?.receive('end-call', (id: string) => {
                        if (id) {
                            store.dispatch({
                                type: 'sip/end-call',
                                payload: id
                            });
                        }
                    });

                    (window as any).electron?.receive(
                        'toggle-hold-call',
                        ({ callId, onHold }: { callId: string; onHold: boolean }) => {
                            if (callId) {
                                store.dispatch({
                                    type: 'sip/toggle-hold',
                                    payload: {
                                        callId,
                                        onHold
                                    }
                                });
                            }
                        }
                    );

                    (window as any).electron?.receive(
                        'toggle-mute-call',
                        ({ callId, onHold }: { callId: string; onHold: boolean }) => {
                            if (callId) {
                                store.dispatch({
                                    type: 'sip/toggle-mute',
                                    payload: {
                                        callId,
                                        onHold
                                    }
                                });
                            }
                        }
                    );
                    break;

                default:
                    break;
            }
        }

        return next(action);
    };
