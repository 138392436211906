import React, {useRef} from "react";
import {selectPhoneSettingByKey, sendDTMF} from "../../../../../redux/slices";
import {playTone} from "../../../../../helpers";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";
import styles from "../../../Keypad.module.scss";
import ShortcodesKeypad from "./ShortcodesKeypad";
import {useCallContext} from "../../../../../context/CallContext/context";
import AriaButton from "../../../../AriaComponents/AriaButton";
import {usePolyglot} from "../../../../../context/Polyglot";
import KeypadPageTitle from "./atoms/KeypadPageTitle";

let starTimeout: NodeJS.Timeout;
let plusTimeout: NodeJS.Timeout;

interface Props {
    inputValue: string;
    setInputValue: (val: string) => void;
}

export const NumberKeypad: React.FC<Props> = ({
                                                  inputValue,
                                                  setInputValue
                                              }) => {
    const {call, currentTab, useCallAction} = useCallContext();

    const {t} = usePolyglot();

    const disableShortCodesLongPress = useTypedSelector(state => selectPhoneSettingByKey(state, 'disableShortCodesLongPress'));

    const keypadHoldTimerRef = useRef<any>(null);

    const dispatch = useTypedDispatch();

    const dialpadButtons: {
        dtmf: string;
        text: number | string;
        subText?: string;
    }[] =
        [
            {
                dtmf: '1',
                text: 1
            },
            {
                dtmf: '2',
                text: 2,
                subText: 'abc'
            },
            {
                dtmf: '3',
                text: 3,
                subText: 'def'
            },
            {
                dtmf: '4',
                text: 4,
                subText: 'ghi'
            },
            {
                dtmf: '5',
                text: 5,
                subText: 'jkl'
            },
            {
                dtmf: '6',
                text: 6,
                subText: 'mno'
            },
            {
                dtmf: '7',
                text: 7,
                subText: 'pqrs'
            },
            {
                dtmf: '8',
                text: 8,
                subText: 'tuv'
            },
            {
                dtmf: '9',
                text: 9,
                subText: 'wxyz'
            },
            {
                dtmf: '*',
                text: '*'
            },
            {
                dtmf: '0',
                text: 0,
                subText: '+'
            },
            {
                dtmf: '#',
                text: '#'
            }
        ];

    const setButtonPressed = button => {
        if (button === 'backspace') {
            setInputValue(inputValue.slice(0, -1));
        } else {
            setInputValue(inputValue + button);
        }
    }

    const onMouseDownStar = (event:
                                 | React.MouseEvent<HTMLButtonElement, MouseEvent>
                                 | React.TouchEvent<HTMLButtonElement>
    ) => {
        starTimeout = setTimeout(() => {
            if (!disableShortCodesLongPress) {
                useCallAction({
                    type: 'set_current_tab',
                    payload: 'shortcodes'
                })
            }
        }, 1000);

        event.target.addEventListener('mouseup', () => {
            clearTimeout(starTimeout);
        });
    };

    const onMouseDownPlus = (event:
                                 | React.MouseEvent<HTMLButtonElement, MouseEvent>
                                 | React.TouchEvent<HTMLButtonElement>
    ) => {
        plusTimeout = setTimeout(() => {
            if (!disableShortCodesLongPress) {
                // setButtonPressed?.('+');
            }
        }, 1000);

        event.target.addEventListener('mouseup', () => {
            clearTimeout(plusTimeout);
        });
    };

    const keypadHold = (val: string) => {
        if (val === '+') {
            keypadHoldTimerRef.current = setTimeout(() => {
                setButtonPressed?.(val);
                keypadHoldTimerRef.current = null;
            }, 1000);
        } else if (keypadHoldTimerRef.current) {
            setButtonPressed?.(val);
            clearTimeout(keypadHoldTimerRef.current);
        }
        return () => {
            clearTimeout(keypadHoldTimerRef.current);
        }
    }

    if (currentTab === 'shortcodes') {
        return (
            <ShortcodesKeypad
                setButtonPressed={setButtonPressed}
                inputValue={inputValue}
            />
        )
    }

    return (
        <div className={styles.number_keypad}>
            <KeypadPageTitle title={
                <AriaButton
                    onClick={() => useCallAction({type: 'set_current_tab', payload: 'shortcodes'})}
                    className={styles.shortcodes_btn}
                >
                    {t("terms.short_code", 2)}
                </AriaButton>
            }
            />
            <div className={styles.dial}>
                {
                    dialpadButtons.map(({dtmf, text, subText}, index) => (
                        <button
                            onClick={() => {
                                dispatch(sendDTMF(call?.id ?? "", dtmf));
                            }}
                            onMouseDown={event => {
                                switch (dtmf) {
                                    case '*':
                                        onMouseDownStar(event);
                                        break;

                                    case '+':
                                        onMouseDownPlus(event);
                                        break;

                                    case '0':
                                        keypadHold('+');
                                        break;

                                    default:
                                        break;
                                }
                            }}
                            onMouseUp={() => {
                                playTone({key: dtmf});
                                if (dtmf !== '0') {
                                    setButtonPressed?.(dtmf);
                                } else {
                                    keypadHold('0');
                                }
                            }}
                            onTouchStart={event => {
                                switch (dtmf) {
                                    case '*':
                                        onMouseDownStar(event);
                                        break;

                                    case '+':
                                        onMouseDownPlus(event);
                                        break;
                                    case '0':
                                        keypadHold('+');
                                        break;

                                    default:
                                        break;
                                }
                            }}
                            onTouchEnd={() => {
                                playTone({key: dtmf});
                                if (dtmf !== '0') {
                                    setButtonPressed?.(dtmf);
                                } else {
                                    keypadHold('0');
                                }
                            }}
                            key={index}
                            data-center={['*', '#'].includes(text as string) || null}
                        >
                            <p>{text}</p>
                            {subText ? (
                                <span>{subText}</span>
                            ) : null}
                        </button>
                    ))
                }
            </div>
            <div/>
        </div>
    )
}

export default NumberKeypad;
