import React, {ReactNode, useRef, useState} from "react";
import {StyledButton} from "../StyledComponents";
import Icon from "../Icon";
import styles from './styles.module.scss';
import {useTimeout} from "../../helpers";

interface Props {
    title: string,
    message: string | undefined,
    onClose: (() => void) | undefined,
    controls: ReactNode,
    holdDelay?: number;
}

const AppNotification: React.FC<Props> = ({
                                              title,
                                              message,
                                              onClose,
                                              controls,
                                              holdDelay
                                          }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [hold, setHold] = useState(false)


    useTimeout(
        () => {
            if (holdDelay && onClose) {
                onClose()
            }
        },
        holdDelay,
        hold)

    return (
        <div
            className={styles.app_notification}
            ref={containerRef}
            onMouseEnter={() => setHold(true)}
            onMouseLeave={() => setHold(false)}
        >
            <div className={styles.heading}>
                <h3>
                    {title}
                </h3>
                {onClose ? (
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        size='small'
                        onClick={() => onClose()}
                    >
                        <Icon name='cross'/>
                    </StyledButton>
                ) : null}
            </div>
            {message ? (
                <p>
                    {message}
                </p>
            ) : null}
            <div className={styles.controls}>
                {controls}
            </div>
        </div>
    )
}

export default AppNotification;