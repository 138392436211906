import React, {useMemo} from 'react';
import {
    addOneApiOnlyPhonebook,
    deleteSelectedPhonebookContacts,
    getPhonebookContacts, removeOneApiOnlyPhonebook,
    resetPhonebookContacts, selectActivePhonebooks, selectApiOnlyPhonebooks,
    selectAppDisableHuntGroups,
    selectPhoneSettingByKey, selectSelectedPhonebooks, selectUsablePhonebooks, setAllSelectedPhonebook,
    updatePhoneSettings, updateSelectedPhonebook
} from '../../../redux/slices';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import styles from '../SettingsPage.module.scss'
import {usePolyglot} from "../../../context/Polyglot";
import {ICRMIntegration} from "../../../types";
import MenuHeader from "../../../components/menus/MenuHeader";
import SettingsDropdown from "./SettingsComponents/SettingsDropdown";
import {StyledDropdownOption} from "../../../components/StyledComponents";
import SettingsSlider from "./SettingsComponents/SettingsSlider";
import SettingsSelectedList from "./SettingsComponents/SettingsSelectedList";

interface ContactsProps {
}


const CRMIntegrationSelector = () => {
    const {t} = usePolyglot();

    const integrations: ICRMIntegration[] = useTypedSelector(state => state.auth.integrations);
    const selectedIntegrationUuids: string[] = useTypedSelector(state => state.user.settings.phone.settings?.crmIntegrationUuids);

    const dispatch = useTypedDispatch();

    const onUnselectAll = () => dispatch(updatePhoneSettings({
        setting: 'crmIntegrationUuids',
        value: []
    }));

    const onSelectionToggle = (integrationUuid: string) => {
        if (!selectedIntegrationUuids) return;

        switch (true) {
            case selectedIntegrationUuids.includes(integrationUuid):
                dispatch(updatePhoneSettings({
                    setting: 'crmIntegrationUuids',
                    value: selectedIntegrationUuids?.filter(uuid => uuid !== integrationUuid)
                }));
                break;

            default:
                dispatch(updatePhoneSettings({
                    setting: 'crmIntegrationUuids',
                    value: [integrationUuid, ...selectedIntegrationUuids]
                }));
        }
    }

    const getIntegrationName = (uuid: string): string => {
        const found = integrations?.find(integration => integration.uuid === uuid);

        return found?.display_name || found?.name || ''
    }

    const crmPopIntegrations = integrations?.filter(integration => integration.supports_screen_pop) || [];

    const crmOptions: StyledDropdownOption[] =
        crmPopIntegrations.length < 1 ? [
                {
                    label: integrations?.length < 1 ? t("phrases.no_linked_crms_to_select") : t("phrases.no_linked_crms_support_call_popping"),
                    divider: true,
                }
            ]
            : [
                {
                    label: t("actions.do_not_use_a_crm"),
                    onSelect: onUnselectAll,
                },
                ...crmPopIntegrations?.map(integration => ({
                    label: integration.display_name || integration.name,
                    value: integration.uuid
                }))
            ]

    return (
        <>
            <SettingsDropdown
                title={t("phrases.crm_for_contact_pop")}
                options={crmOptions}
                onSelect={(value: string) => {
                    onSelectionToggle(value)
                }}
                selected={selectedIntegrationUuids || []}
                placeHolder={t("actions.select_linked_crm")}
            />
            {selectedIntegrationUuids && selectedIntegrationUuids.length > 0 ? (
                <SettingsSelectedList
                    title={t("phrases.selected_crms")}
                    options={selectedIntegrationUuids.map(uuid => ({
                        label: getIntegrationName(uuid),
                        value: uuid
                    }))}
                    onDelete={(val) => onSelectionToggle(val)}
                />
            ) : null}
        </>
    )
}

const CRMIntegrationSettings = () => {
    const popWhenAnswered = useTypedSelector(state => selectPhoneSettingByKey(state, 'popWhenAnswered'));
    const popOnOutbound = useTypedSelector(state => selectPhoneSettingByKey(state, 'popOnOutbound'));
    const popCrm = useTypedSelector(state => selectPhoneSettingByKey(state, 'popCrm'));

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    const crmPopOptions: StyledDropdownOption[] = [
        {
            label: t("adverbs.never"),
            value: 'never',
        },
        {
            label: t("phrases.new_tab"),
            value: 'new'
        },
        {
            label: t("phrases.same_tab"),
            value: 'same'
        }
    ]

    return (
        <div className={styles.section_group}>
            <div className={styles.heading}>
                <h2>{t("terms.crm_integration")}</h2>
                <p>{t("phrases.manage_crm_integration")}</p>
            </div>
            <div>
                <SettingsDropdown
                    title={t("phrases.pop_linked_crm_on_call")}
                    options={crmPopOptions}
                    onSelect={(value: string) => {
                        dispatch(updatePhoneSettings({setting: 'popCrm', value}))
                    }}
                    selected={popCrm}
                />
                <CRMIntegrationSelector/>
                <SettingsSlider
                    title={t('phrases.pop_on_answered')}
                    checked={popWhenAnswered}
                    onChange={() =>
                        dispatch(
                            updatePhoneSettings({
                                setting: 'popWhenAnswered',
                                value: !popWhenAnswered
                            })
                        )
                    }
                />
                <SettingsSlider
                    title='Pop on Outbound'
                    checked={popOnOutbound}
                    onChange={() =>
                        dispatch(
                            updatePhoneSettings({
                                setting: 'popOnOutbound',
                                value: !popOnOutbound
                            })
                        )
                    }
                />
            </div>
        </div>
    )

}

export const Contacts: React.FC<ContactsProps> = () => {
    const appDisableHuntGroups = useTypedSelector(selectAppDisableHuntGroups);
    const selectablePhonebooks = useTypedSelector(selectUsablePhonebooks);
    const selectedPhonebooks = useTypedSelector(selectSelectedPhonebooks);
    const activePhonebookUuids = useTypedSelector(selectActivePhonebooks);
    const apiOnlyPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);

    const contactsSortByLastName = useTypedSelector(state => selectPhoneSettingByKey(state, 'contactsSortByLastName'));
    const showPhonebookContactCompanyName = useTypedSelector(state => selectPhoneSettingByKey(state, 'showPhonebookContactCompanyName'));
    const disableHuntGroupDisplay = useTypedSelector(state => selectPhoneSettingByKey(state, 'disableHuntGroupDisplay'));

    const phonebookDictionary = useMemo(() =>
            Object.fromEntries(selectablePhonebooks?.map(phonebook => [phonebook.uuid, phonebook]))
        , [selectablePhonebooks])

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    const onPhonebookToggle = (phonebookUuid: string) => {
        if (selectedPhonebooks[phonebookUuid]) {
            dispatch(
                updateSelectedPhonebook(
                    phonebookUuid,
                    false
                )
            );

            if (apiOnlyPhonebooks.includes(phonebookUuid)) {
                dispatch(removeOneApiOnlyPhonebook(phonebookUuid))
            } else {
                dispatch(deleteSelectedPhonebookContacts(phonebookUuid))
            }
        } else {
            dispatch(
                updateSelectedPhonebook(
                    phonebookUuid,
                    true
                )
            );
            const pb = selectablePhonebooks.find(p => p.uuid === phonebookUuid)
            if (!pb) return;
            if (pb.total_contacts > 2500) {
                dispatch(addOneApiOnlyPhonebook(phonebookUuid))
            } else {
                dispatch(getPhonebookContacts({phonebookUuid}));
            }
        }
    }

    const onUnselectAllPhonebooks = () => {
        dispatch(resetPhonebookContacts());
        dispatch(setAllSelectedPhonebook({}))
    }

    // TODO => remove selected phonebooks that dont exist on homepage before fetching contacts

    // TODO => on phone books select clear current and fetch new

    const getPhonebookDisplayName = (phonebookUuid: string): string => {
        const phonebook = phonebookDictionary[phonebookUuid];
        if (!phonebook) return ''
        return `${phonebook.name} (${t("terms.contact_%count", {smart_count: phonebook.total_contacts || 0})})`
    }

    const phonebookOptions: StyledDropdownOption[] = !selectablePhonebooks || selectablePhonebooks?.length === 0 ?
        [{label: t("phrases.nothing_to_select_from"), value: ''}]
        : [
            {
                label: t("phrases.dont_use_phonebook"),
                onSelect: onUnselectAllPhonebooks,
            },
            ...selectablePhonebooks.map(pb => ({
                label: getPhonebookDisplayName(pb.uuid),
                value: pb.uuid,
            }))
        ]

    return (
        <div className={styles.container}>
            <MenuHeader
                title={t("terms.contacts_settings")}
                description={t("phrases.configure_how_contacts_are_presented")}
            />
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.visibility_and_sorting")}</h2>
                    <p>{t("phrases.customise_how_contacts_are_arranged")}</p>
                </div>
                <div>
                    <SettingsSlider
                        title={t("phrases.sort_by_last_name")}
                        checked={contactsSortByLastName}
                        onChange={() =>
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'contactsSortByLastName',
                                    value: !contactsSortByLastName
                                })
                            )
                        }
                    />
                    {!appDisableHuntGroups ? (
                        <SettingsSlider
                            title={t("phrases.show_hunt_groups_in_contacts")}
                            checked={!disableHuntGroupDisplay}
                            onChange={() =>
                                dispatch(
                                    updatePhoneSettings({
                                        setting: 'disableHuntGroupDisplay',
                                        value: !disableHuntGroupDisplay
                                    })
                                )
                            }
                        />
                    ) : null}
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.phonebook", 2)}</h2>
                    <p>{t("phrases.decide_which_phonebook_to_present")}</p>
                </div>
                <div>
                    <SettingsDropdown
                        options={phonebookOptions}
                        onSelect={(val: string) => {
                            onPhonebookToggle(val)
                        }}
                        title={t("terms.phonebook", 1)}
                        placeHolder={t("phrases.select_phonebook")}
                        selected={activePhonebookUuids}
                    />
                    {activePhonebookUuids.length > 0 ? (
                        <SettingsSelectedList
                            title={t("phrases.selected_phonebooks")}
                            options={activePhonebookUuids.map(phonebookUuid => ({
                                label: getPhonebookDisplayName(phonebookUuid),
                                value: phonebookUuid
                            }))}
                            onDelete={(val) => onPhonebookToggle(val)}
                        />
                    ) : null}
                    <SettingsSlider
                        title={t("phrases.show_contact_company_name")}
                        checked={showPhonebookContactCompanyName}
                        onChange={() =>
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'showPhonebookContactCompanyName',
                                    value: !showPhonebookContactCompanyName
                                })
                            )
                        }
                    />
                </div>
            </div>
            <CRMIntegrationSettings/>
        </div>

    );
};
