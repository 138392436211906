import React, {useRef} from "react";
import {AutoSizer, CellMeasurer, CellMeasurerCache, Grid, List} from 'react-virtualized';
import {Contact} from "./Contact";
import {useTypedSelector} from "../../../redux/hooks";
import {
    selectAppSettingByKey, selectFavouriteContacts,
    selectIsFetchingPhonebooks,
    selectPhoneSettingByKey,
} from "../../../redux/slices";
import {useHeightObserver} from "../../../helpers";
import {usePolyglot} from "../../../context/Polyglot";
import {ContactsSkeleton} from "../../Skeletons/ContactsSkeleton";
import {ContactIcon, ContactOptions} from "./children";
import ContactProvider from "../../../context/ContactContext/context";
import {useContactKeyDown} from "./hooks";
import AriaListFocusContainer from "../../AriaComponents/AriaListFocusContainer";
import {ContactDisplayItem} from "./ContactListHooks";
import {IHuntGroup, IPhonebookContact, IUser} from "../../../types";
import TenantRow from "./Tenant/TenantRow";

interface ContactListProps {
    searchQuery: string | undefined,
    favourites: ContactDisplayItem[],
    mainDisplay: ContactDisplayItem[],
    outerDisplay: ContactDisplayItem[],
    getStrForCDI: (val: ContactDisplayItem) => string,
    initialLoad: boolean,
    apiContactsLoading: boolean,
}

type ContactEntity = {
    contact: IUser | IPhonebookContact | IHuntGroup | undefined;
    menuFloored: boolean;
} & ({
    type: 1;
    menuWalled?: never;
    dividers: string[];
} | {
    type: 2;
    menuWalled: boolean;
    dividers?: never;
})

const RenderContact: React.FC<ContactEntity> = ({
                                                    type,
                                                    contact,
                                                    menuFloored,
                                                    menuWalled,
                                                    dividers
                                                }) => {

    if (!contact) {
        return null;
    }

    return (
        <ContactProvider value={{contact}}>
            {dividers?.map(d => (
                <div key={`${d}_${contact.uuid}`}>
                    <div className='list-divider'>
                        <p>{d}</p>
                    </div>
                </div>
            ))}
            <Contact
                key={contact.uuid}
                icon={
                    <ContactIcon
                        size={type === 2 ? 40 : undefined}
                    />
                }
                tileView={type === 2}
                options={
                    <ContactOptions
                        menuWalled={type === 2 && menuWalled}
                        menuFloored={menuFloored}
                    />
                }
                menuWalled={type === 2 && menuWalled}
            />
        </ContactProvider>
    );
}

export const ContactList: React.FC<ContactListProps> = ({
                                                            searchQuery,
                                                            favourites,
                                                            mainDisplay,
                                                            outerDisplay,
                                                            getStrForCDI,
                                                            initialLoad,
                                                            apiContactsLoading
                                                        }) => {
    const gridView = useTypedSelector(state => selectPhoneSettingByKey(state, 'defaultToGridView'));
    const width = useTypedSelector(state => selectAppSettingByKey(state, 'asideWidth'));
    const isFetchingPhonebooks = useTypedSelector(selectIsFetchingPhonebooks);
    const favouriteContacts = useTypedSelector(selectFavouriteContacts);

    const totalLen = favourites.length + mainDisplay.length + outerDisplay.length;
    const {t} = usePolyglot();

    const tileSize = {
        width: 96,
        height: 88
    };

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 55,
    });

    const gridCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: tileSize.height,
        defaultWidth: tileSize.width,
    })

    const gridColumnCount = Math.floor(width / tileSize.width);

    const handleKeyDown = useContactKeyDown({gridView, gridColumnCount})

    const heightRef = useRef<HTMLDivElement>(null);

    const {listHeight} = useHeightObserver(heightRef);

    const getContact = (idx: number): ContactDisplayItem | undefined => {
        if (idx < favourites.length) {
            return favourites[idx];
        }

        if (idx - favourites.length < mainDisplay.length) {
            return mainDisplay[idx - favourites.length];
        }

        return outerDisplay[idx - favourites.length - mainDisplay.length];
    }

    const cellRenderer = ({
                              key,
                              rowIndex,
                              columnIndex,
                              style,
                              parent,
                          }) => {
        let contactIdx = (rowIndex * gridColumnCount) + columnIndex
        const isOuter = contactIdx > favourites.length + mainDisplay.length - 1;

        if (isOuter && favourites.length + mainDisplay.length > 0) {
            const bump = gridColumnCount - (favourites.length + mainDisplay.length) % gridColumnCount;
            if (
                contactIdx - bump < favourites.length + mainDisplay.length
            ) {
                return null;
            }
            contactIdx -= bump;
        }

        const contact = getContact(contactIdx);

        const divider = contact && contactIdx === favourites.length + mainDisplay.length ?
            "Other Results" : undefined;

        return (
            <CellMeasurer
                key={divider ? `${key}_divider` : key}
                cache={cache}
                parent={parent}
                columnIndex={columnIndex}
                rowIndex={rowIndex}
            >
                {divider ? (
                    <div
                        style={{
                            ...style,
                            width: '100%',
                            height: 24,
                        }}
                    >
                        <div className='list-divider'>
                            <p>{divider}</p>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            ...style,
                            paddingTop: 6,
                            marginTop: isOuter ? 12 : 0,
                        }}
                        onKeyDown={(e) => handleKeyDown(e)}
                    >
                        {contact?.type !== 4 ? (
                            <RenderContact
                                contact={contact?.data}
                                menuWalled={gridColumnCount - columnIndex < 2}
                                menuFloored={(rowIndex > 8 && (rowIndex - (Math.ceil(totalLen / gridColumnCount) - 4) >= 0))}
                                type={2}
                            />
                        ) : (
                            <TenantRow
                                client={contact.data}
                                dividers={undefined}
                                tileView
                            />
                        )}
                    </div>
                )}
            </CellMeasurer>
        )
    }

    const rowRenderer = ({
                             key,
                             index,
                             style,
                             parent
                         }) => {

        const contact = getContact(index);
        const isFav = index < favourites.length;
        if (!contact) {
            return null;
        }

        const dividers: string[] = [];
        const prev = index > 0 ? getContact(index - 1) : undefined;

        switch (true) {
            case !!contact && index === favourites.length + mainDisplay.length:
                dividers.push('Other Results')
                dividers.push(getStrForCDI(contact).substring(0, 1))
                break;
            case index === 0:
                dividers.push(
                    isFav ?
                        t('terms.favourite', 2) :
                        getStrForCDI(contact).substring(0, 1)
                );
                break;
            case index < totalLen:
                if (isFav || !prev) {
                    break;
                }

                if (favouriteContacts[prev.data.uuid]) {
                    dividers.push(getStrForCDI(contact).substring(0, 1));
                    break;
                }

                if (
                    getStrForCDI(contact)
                        .toLowerCase()
                        .substring(0, 1) ===
                    getStrForCDI(prev)
                        .toLowerCase()
                        .substring(0, 1)
                ) break;

                dividers.push(getStrForCDI(contact).substring(0, 1));
                break;
        }

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div
                    style={style}
                    onKeyDown={(e) => handleKeyDown(e)}
                >
                    {(index === 0 && isFetchingPhonebooks && !apiContactsLoading) ? (
                        <ContactsSkeleton skeletonType='singleRowPhoneBooks'/>
                    ) : null}
                    {index === 0 && apiContactsLoading ? (
                        <ContactsSkeleton skeletonType='singleRowPhoneBooks' text='Getting Contacts'/>
                    ) : null}
                    {contact?.type !== 4 ? (
                        <RenderContact
                            contact={contact?.data}
                            menuFloored={(index > 8 && (index - (totalLen - 6) >= 0))}
                            type={1}
                            dividers={dividers}
                        />
                    ) : (
                        <TenantRow
                            client={contact.data}
                            dividers={dividers}
                        />
                    )}
                </div>
            </CellMeasurer>
        )
    }

    const getGridRowCount = (): number => {
        const base = Math.ceil(totalLen / gridColumnCount);

        if (outerDisplay.length > 0) {
            return base + 1;
        }

        return base
    }

    return (
        <AriaListFocusContainer
            className='list-container contacts-list'
            ref={heightRef}
            title='Contacts List'
        >
            {(!initialLoad && totalLen < 1) ? (
                <div className='list-placeholder'>
                    <p>{searchQuery ? t("phrases.no_results_match_your_search") : t("phrases.no_contacts")}</p>
                </div>
            ) : null}
            {initialLoad ? (
                <ContactsSkeleton skeletonType={gridView ? 'manyGrid' : 'manyRow'}/>
            ) : (
                <AutoSizer>
                    {() => (
                        gridView ? (
                            <Grid
                                cellRenderer={cellRenderer}
                                width={width}
                                height={listHeight}
                                rowCount={getGridRowCount()}
                                columnWidth={tileSize.width}
                                columnCount={gridColumnCount}
                                overscanRowCount={10}
                                rowHeight={gridCache.rowHeight}
                                deferredMeasurementCache={cache}
                                className='windowed-contact-list-container-center'
                                tabIndex={-1}
                                scrollToRow={0}
                            />
                        ) : (
                            <List
                                width={width}
                                height={listHeight}
                                rowCount={totalLen}
                                rowRenderer={rowRenderer}
                                overscanRowCount={10}
                                rowHeight={cache.rowHeight}
                                deferredMeasurementCache={cache}
                                className='windowed-contact-list-container'
                                tabIndex={-1}
                                scrollToIndex={0}
                            />
                        )
                    )}
                </AutoSizer>
            )}
        </AriaListFocusContainer>
    )
}

export default ContactList;
