import React, {FunctionComponent} from 'react';
import styles from "../ChatInput.module.scss";
import TypingLoader from "../../../../components/loading/TypingLoader/TypingLoader";
import {useChatContext} from "../../../../context/ChatContext/context";
import {useTypedSelector} from "../../../../redux/hooks";
import {usePolyglot} from "../../../../context/Polyglot";
import {INotifyMessageStatus} from "../../../../types";

interface Props {
}


const TypingUsers: FunctionComponent<Props> = () => {
    const {chat, isScrolled, memberDictionary} = useChatContext();
    const typingStatuses:  INotifyMessageStatus[] = useTypedSelector(state => state.chat.typingStatuses[chat.uuid] || []);

    const {t} = usePolyglot();

    let usersTypingNames: string[];

    if (
        typingStatuses?.some(typingStatus =>
            ['start_typing', 'still_typing'].includes(typingStatus.status)
        )
    ) {
        usersTypingNames = typingStatuses
            .filter(typingStatus => ['start_typing', 'still_typing'].includes(typingStatus.status))
            .map(typingUsers => (memberDictionary[typingUsers.user]?.display_name || t("pronouns.someone")))

    } else {
        usersTypingNames = [];
    }

    if (!usersTypingNames || usersTypingNames.length < 1) return null

    let typingString: string | undefined;

    switch (true) {
        case usersTypingNames.length === 1:
            typingString = `${usersTypingNames[0]} is typing...`
            break;
        case usersTypingNames.length === 2:
            typingString = `${usersTypingNames[0]} & ${usersTypingNames[1]} are typing...`
            break;
        case usersTypingNames.length === 3:
            typingString = `${usersTypingNames[0]}, ${usersTypingNames[1]} & ${usersTypingNames[2]} are typing...`
            break;
        case usersTypingNames.length > 3:
            typingString = `${usersTypingNames[0]}, ${usersTypingNames[1]} & ${usersTypingNames.length - 2} others are typing...`
            break;
    }

    return (
        <div
            className={styles.user_typing}
             data-has-border={isScrolled || null}
            title={`User(s) Typing: ${usersTypingNames.join(', ')}`}
        >
            <TypingLoader mini/>
            <p>
                {typingString}
            </p>
        </div>
    );
};

export default TypingUsers;
