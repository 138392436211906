import {TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    deleteAllFeedbackForms, deleteSingleFeedbackForm, selectActiveFeedbackForms,
    setShowFeedbackForm,
} from "../../../redux/slices";
import {
    useGetAvailableTagsQuery,
    usePostCallTagsMutation,
    usePutCallFeedbackMutation
} from "../../../redux/services/sipApi";


const CallFeedbackFormSchema = z
    .object({
        tag: z.array(z.string()),
        note: z.string(),
        mood: z.string(),
    })
    .partial()
    .refine(
        ({note, mood}) => !!note || !!mood,
        {
            message: 'Either Note or mood should be filled in',
            path: ['note']
        }
    )
    .refine(
        ({note, mood}) => !!note || !!mood,
        {
            message: 'Either Note or mood should be filled in',
            path: ['mood']
        }
    );

export type CallFeedbackFormInput = TypeOf<typeof CallFeedbackFormSchema>;


export const useCallFeedbackForm = (call_uuid: string, invite_uuid: string) => {
    const allFeedbackForms = useTypedSelector(selectActiveFeedbackForms);

    const dispatch = useTypedDispatch()

    useGetAvailableTagsQuery(null, {refetchOnMountOrArgChange: true})

    const [putCallFeedback, {
        isLoading: putFeedbackLoading,
        error: putFeedbackError
    }] = usePutCallFeedbackMutation();
    const [postCallTags, {
        isLoading: postTagsLoading,
        error: postTagError
    }] = usePostCallTagsMutation();

    const formMethods = useForm<CallFeedbackFormInput>({
        resolver: zodResolver(CallFeedbackFormSchema),
        defaultValues: {
            tag: []
        }
    });

    const onSubmit = (formData: CallFeedbackFormInput) => {
        const {mood, note, tag} = formData

        if (tag && tag.length > 0) {
            postCallTags({
                call_uuid,
                data: tag || [],
            })
        }

        let parsedMood;

        if (mood) {
            parsedMood = parseInt(mood);
        }

        if (note || parsedMood) {
            putCallFeedback({
                call_uuid,
                data: {
                    ...(note ? {note} : {}),
                    ...(parsedMood ? {rating_mood: parsedMood} : {})
                }
            })
                .then(() => {
                    onClose()
                })
        }
    }

    const onClose = () => {
        if (allFeedbackForms.length < 2) {
            dispatch(setShowFeedbackForm(false))
            dispatch(deleteAllFeedbackForms());
        } else {
            dispatch(
                deleteSingleFeedbackForm(invite_uuid)
            )
        }
    }

    return {
        formMethods,
        onSubmit,
        onClose,
        submitLoading: putFeedbackLoading || postTagsLoading,
        submitError: putFeedbackError || postTagError,
    }
}