/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import {useOnClickOutside} from '../../helpers';
import Icon from "../Icon";
import {usePolyglot} from "../../context/Polyglot";

interface SelectInputProps {
    name: string;
    value: string;
    onClick: (value: string) => void;
    options: {
        type?: string;
        id: string;
        name: string;
        divider?: boolean
    }[];
    label?: string;
    required?: boolean;
    placeholder?: string;
    textValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setTextValue?: (val: string) => void;
    secondary?: boolean;
}

export const SelectInput: React.FC<SelectInputProps> = ({
                                                            value,
                                                            onClick,
                                                            options,
                                                            label,
                                                            name,
                                                            required,
                                                            placeholder,
                                                            textValue,
                                                            onChange,
                                                            setTextValue,
                                                            secondary
                                                        }) => {
    const [dropdownActive, setDropdownActive] = useState<boolean>(false);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(dropdownRef, () => setDropdownActive(false));
    const {t} = usePolyglot();

    let dropdownOptions = options;

    if (textValue !== undefined && textValue.length > 1) {
        dropdownOptions = options.filter(option =>
            option.name
                .replace(/ /g, '')
                .toLowerCase()
                .includes(textValue.replace(/ /g, '').toLowerCase())
            || option.divider
        );
    }

    return (
        <div className='select-input' data-is-secondary={secondary}>
            {label && <label htmlFor={name}>{label}</label>}
            {required && <sup className='required'>*</sup>}
            <div
                ref={dropdownRef}
                onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        setDropdownActive(false)
                    }
                }}
            >
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setDropdownActive(!dropdownActive)
                    }}
                    data-has-input={!!textValue || !!setTextValue || null}
                >
                    {(textValue !== undefined && dropdownActive) ? (
                        <input
                            ref={inputRef}
                            type='text'
                            value={textValue}
                            onChange={onChange}
                            placeholder={placeholder}
                            onClick={e => {
                                e.stopPropagation()
                                if (!dropdownActive) {
                                    setDropdownActive(true)
                                }
                            }}
                        />
                    ) : (
                        <p title={options.find(option => option.id === value)?.name || placeholder}>
                            {options.find(option => option.id === value)?.name ||
                                (placeholder && <span>{placeholder}</span>)}
                        </p>
                    )}
                    <Icon name='arrowDown' width={24} height={24}/>
                </button>
                <AnimatePresence>
                    {dropdownActive ? (
                        <motion.ul
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            id={name}
                        >
                            {(setTextValue && textValue && textValue.length > 0) ? (
                                <button
                                    onClick={() => {
                                        if (setTextValue) {
                                            setTextValue('')
                                        }
                                        if (inputRef.current) {
                                            inputRef.current.focus()
                                        }
                                    }}
                                    data-warning='true'
                                >
                                    {t("actions.clear")}
                                </button>
                            ) : null}
                            {dropdownOptions.map(option =>
                                option.divider ? (
                                    <button
                                        key={option.id}
                                        className='select-input__divider'
                                        onClick={event => event.stopPropagation()}
                                        disabled
                                        tabIndex={-1}
                                    >
                                        {option.name}
                                    </button>
                                ) : (
                                    <button
                                        key={option.id}
                                        className={option.id === value ? 'select-input__selected' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            onClick(option.id)
                                            setDropdownActive(false)
                                        }}
                                        title={option.name}
                                        data-is-large={(option.type === 'forward' && option.name !== option.id) || null}
                                    >
                                        {option.name}
                                        {(option.type === 'forward' && option.name !== option.id) ? (
                                            <>
                                                <br/>
                                                <span>{option.id}</span>
                                            </>
                                        ) : null}
                                    </button>
                                )
                            )}
                        </motion.ul>
                    ) : null}
                </AnimatePresence>
            </div>
        </div>
    );
};
