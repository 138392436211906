import React from 'react';
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';

const NoChat: React.FC = () => {
    const { appLogoWhite, appLogoColor, appLogoAlt, isCallSwitch, isYay } = useAppContext();
    const { t } = usePolyglot();

    return (
        <div className='chat no-chat'>
            <div>
                <img src={isCallSwitch ? appLogoColor : appLogoWhite} alt={appLogoAlt} style={!isYay ? {height: '80px'} : undefined}/>
                <h1 style={isCallSwitch ? {color: '#0D112B'} : undefined}>{t("phrases.your_admin_has_not_enabled_chat_for_this_user")}</h1>
            </div>
        </div>
    )
};

export default NoChat;
