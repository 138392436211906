import {useMemo, useState} from "react";
import {useDeleteMeetingMutation, useGetAllMeetingsQuery} from "../../../../redux/services/videoApi";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {addOneToast, selectActiveRoomId} from "../../../../redux/slices";


export const useMeetingPreviewData = () => {

    const {data: meetings} = useGetAllMeetingsQuery(null);

    const activeMeetingId = useTypedSelector(selectActiveRoomId);
    const meeting = meetings?.result?.find(m => m.uuid === activeMeetingId);

    const [deleteMeeting, {
        isLoading: deleteMeetingLoading
    }] = useDeleteMeetingMutation();

    const [deletionConfirm, setDeletionConfirm] = useState<boolean>(false);

    const dispatch = useTypedDispatch();

    const sortedParticipants = useMemo(() => [...(meeting?.members || [])].sort((a, b) => {
        if (a.admin === b.admin) {
            return a.user_name.toLowerCase().localeCompare(b.user_name.toLowerCase());
        }

        return a.admin ? -1 : 1;
    }), [meeting?.uuid, meeting?.members]);


    const handleDeleteClick = () => {
        if (!meeting) return;

        if (!deletionConfirm) {
            setDeletionConfirm(true);
            return;
        }

        deleteMeeting(meeting.uuid)
            .catch(() => {
                dispatch(addOneToast({
                    content: `Failed to delete meeting ${meeting.name}`,
                    title: "Failed to delete",
                    type: 'error'
                }))
            })
    }

    return {
        meeting,
        deleteMeetingLoading,
        deletionConfirm,
        sortedParticipants,
        handleDeleteClick,
    }
}