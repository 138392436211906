import React, {useState} from "react";
import {useDevices} from "./hooks";
import ThemeSelector from "./ThemeSelector";
import {usePolyglot} from "../../../../context/Polyglot";
import {isPreview, useDnd, versionCompare} from "../../../../helpers";
import MenuHeader from "../../../../components/menus/MenuHeader";
import {
    usePutAuxCodeMutation,
    usePutAvailableInCallQueuesMutation
} from "../../../../redux/services/sipApi";
import {
    selectCurrentUserId, selectCurrentUserQueueAvailability,
    selectPhoneSettingByKey,
    updatePhoneSettings
} from "../../../../redux/slices";
import {AnimatedDiv} from "../../../../animation";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import AccountDevices from "./children/AccountDevices";
import styles from "./Account.module.scss";
import Icon from "../../../../components/Icon";
import ChatViewSelector from "./ChatViewSelector";
import ProfilePicture from "./children/ProfilePicture";
import SettingsDropdown from "../SettingsComponents/SettingsDropdown";
import SettingsSlider from "../SettingsComponents/SettingsSlider";
import {useHeadsetContext} from "../../../../context/HeadsetContext/context";
import StyledTag from "../../../../components/StyledComponents/StyledTag";
import {StyledButton, StyledDropdownOption} from "../../../../components/StyledComponents";
import {isElectron} from "../../../home";
import AuxCodeController from "../../../home/children/AuxCodeController/AuxCodeController";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

const electron = navigator.userAgent
    .split(' ')
    .find(i => i.toLowerCase().includes('electron/'));

export const isOldElectron = () => electron ? versionCompare(electron.replace('Electron/', ''), "27.0.2") === -1 : false;

interface AccountProps {
}

export const Index: React.FC<AccountProps> = () => {
    const queueAvailability = useTypedSelector(selectCurrentUserQueueAvailability);
    const user = useTypedSelector(state => state.user);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const allowInternal = useTypedSelector(state => selectPhoneSettingByKey(state, 'allowInternalCalls'));

    const {
        localDnd,
        globalDnd,
        globalDndLoading,
        dndDisabled,
        updateDnd
    } = useDnd();

    const {t, currentLocale, onLocalChange} = usePolyglot();
    const {headset, requestDevice, useHeadsetAction} = useHeadsetContext();

    const [showDevices, setShowDevices] = useState<boolean>(false);

    const languageOpts: StyledDropdownOption[] = [
        {label: 'English', value: 'en-gb'},
        {label: 'Polski', value: 'pl'}
    ]

    const {displayDevices} = useDevices();

    const dispatch = useTypedDispatch();

    const [putAvailableInCallQueues, {isLoading: availableInCallQueuesLoading}] = usePutAvailableInCallQueuesMutation();
    const [putAuxCode] = usePutAuxCodeMutation();

    // TODO - Waiting for backend to fix changing status
    // const userStatus = useTypedSelector(state => selectUserStatus(state, user?.name))?.chat;
    // const [statusValue, setStatusValue] = useState(userStatus?.status || '');
    // const [putChatStatus, {isLoading: putStatusLoading}] = usePutChatStatusMutation();
    // const updateStatus = () => {
    //     let status = statusValue?.trim() || '';
    //
    //     if (!status) {
    //         putChatStatus({
    //             voip_user_uuid: user?.uuid,
    //             status
    //         })
    //
    //     } else {
    //         status = status.charAt(0).toUpperCase() + status.slice(1);
    //
    //         putChatStatus({
    //             voip_user_uuid: user?.uuid,
    //             status
    //         })
    //     }
    // };x

    const styledTagTitle = `Beta Feature
                        
This is currently still in development
and currently only supports a small
selection of headsets.`

    const old_electron = isOldElectron();

    return (
        <div className={styles.container}>
            <MenuHeader
                title={t("terms.account_and_general")}
                description={t("phrases.manage_your_account_and_general_settings")}
            />
            <div className={styles.content}>
                <ProfilePicture/>
                {/*
                TODO - Fix changing chat status
                <div className={styles.section}>
                    <p>Chat Status</p>
                    <input
                        type='text'
                        value={statusValue}
                        placeholder='None Set'
                        onChange={event => setStatusValue(event.target.value)}
                        onKeyDown={event => event.key === 'Enter' && !putStatusLoading && updateStatus()}
                    />
                    {putStatusLoading ? (
                        <LoadingSpinner color='mamba'/>
                    ) : (
                        <button onClick={updateStatus}>
                            <p>
                                Save
                            </p>
                        </button>
                    )}
                </div>
                */}
                <div className={styles.section_group}>
                    <div className={styles.heading}>
                        <h2>{t("terms.account", 1)}</h2>
                        <p>{t("phrases.view_your_account_details")}</p>
                    </div>
                    <div>
                        {user.devices.length > 0 ? (
                            <>
                                <div
                                    className={styles.section}
                                    data-open={showDevices ? 'true' : 'false'}
                                    data-section-type='icon-btn'
                                >
                                    <p>{t("phrases.my_devices")}</p>
                                    <AriaButton
                                        onClick={() => setShowDevices(!showDevices)}
                                        title={showDevices
                                            ? t("phrases.hide_devices")
                                            : t("phrases.show_devices")
                                        }
                                    >
                                        <Icon name={showDevices ? 'arrowDown' : 'arrowRight'}/>
                                    </AriaButton>
                                </div>
                                <AnimatedDiv className={styles.devices} visible={showDevices}>
                                    {displayDevices.map(device => device && (
                                        <AccountDevices device={device} key={device.uuid}/>
                                    ))}
                                </AnimatedDiv>
                            </>
                        ) : null}
                        {((user.allow_aux_codes || user.app_allow_aux) || isPreview) ? (
                            <SettingsDropdown
                                title='Call Queue Status'
                                dropdown={(<AuxCodeController/>)}
                            />
                        ) : null}
                        {(!(user.allow_aux_codes || user.app_allow_aux) || isPreview) ? (
                            <SettingsSlider
                                title={t("phrases.available_in_call_queues")}
                                disabled={availableInCallQueuesLoading || !globalDnd}
                                checked={queueAvailability}
                                onChange={() => {
                                    putAvailableInCallQueues({
                                        voip_user_uuid: userUuid,
                                        available: !queueAvailability
                                    })
                                    if (!queueAvailability) {
                                        putAuxCode({
                                            voip_user_uuid: user.uuid,
                                            auxCode: {
                                                code: 'Available',
                                                set_by: user.uuid,
                                            }
                                        })
                                    }
                                }}
                                loading={availableInCallQueuesLoading}
                            />
                        ) : null}
                        {!dndDisabled ? (
                            <>
                                <SettingsSlider
                                    title="Global Do Not Disturb"
                                    checked={globalDnd}
                                    onChange={() => updateDnd(!globalDnd, true)}
                                    loading={globalDndLoading}
                                    infoText="Applies Do Not Disturb settings to your account.
                            This will prevent calls with all of your apps and devices (Yealink handsets only)
                            and be displayed to other users on your presence."
                                />
                                <SettingsSlider
                                    title="App Do Not Disturb"
                                    checked={localDnd}
                                    onChange={() => updateDnd(!localDnd, false)}
                                    loading={globalDndLoading}
                                    infoText="Applies Do Not Disturb settings to this application only.
                            Other apps and devices on your account will continue to receive
                            calls and your presence will not be changed."
                                />
                                {localDnd ? (
                                    <SettingsSlider
                                        title={t("phrases.allow_internal_calls")}
                                        checked={allowInternal}
                                        onChange={() =>
                                            dispatch(
                                                updatePhoneSettings({
                                                    setting: 'allowInternalCalls',
                                                    value: !allowInternal
                                                })
                                            )
                                        }
                                    />
                                ) : null}
                            </>
                        ) : null}
                        {/**
                         There is no native display for selecting a HID device
                         in electron so hiding until we make a display
                         */}
                        {!isElectron || !old_electron ? (
                            <div className={styles.section}>
                                <p>
                                    {t('phrases.connected_headset')}&nbsp;&nbsp;
                                    <StyledTag
                                        context='beta'
                                        text='Beta'
                                        size='small'
                                        title={styledTagTitle}
                                    />
                                </p>
                                {headset ? (
                                    <div
                                        style={{display: 'flex', alignItems: 'center', gap: 8}}
                                    >
                                        <p>{headset.productName}</p>
                                        <StyledButton
                                            buttonStyle='tertiary'
                                            context='danger'
                                            onClick={() => {
                                                useHeadsetAction({
                                                    type: 'setHeadset',
                                                    payload: undefined
                                                })
                                                headset.forget()
                                                    .catch(() => {
                                                        // ignore
                                                    })
                                            }}
                                        >
                                            <p>
                                                {t('actions.disconnect')}
                                            </p>
                                        </StyledButton>
                                    </div>
                                ) : (
                                    <StyledButton
                                        buttonStyle='tertiary'
                                        onClick={requestDevice}
                                    >
                                        <p>
                                            {t('actions.connect')}
                                        </p>
                                    </StyledButton>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={styles.section_group}>
                    <div className={styles.heading}>
                        <h2>{t("terms.theme", 1)}</h2>
                        <p>{t("phrases.choose_your_theme")}</p>
                    </div>
                    <ThemeSelector/>
                </div>
                <div className={styles.section_group}>
                    <div className={styles.heading}>
                        <h2>{t("terms.chat_view")}</h2>
                        <p>{t("phrases.choose_your_preferred_chat_style")}</p>
                    </div>
                    <ChatViewSelector/>
                </div>
                {(isPreview) ? (
                    <div className={styles.section_group}>
                        <div className={styles.heading}>
                            <h2>{t("terms.language_and_region", 2)}</h2>
                            <p>{t("phrases.choose_your_language_and_region")}</p>
                        </div>
                        <div>
                            <SettingsDropdown
                                title={t("phrases.app_language")}
                                options={languageOpts}
                                onSelect={onLocalChange}
                                selected={currentLocale}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
