import React from "react";
import styles from "../../../Keypad.module.scss";
import {usePolyglot} from "../../../../../context/Polyglot";
import {useCallContext} from "../../../../../context/CallContext/context";
import KeypadPageTitle from "./atoms/KeypadPageTitle";
import AriaButton from "../../../../AriaComponents/AriaButton";
import {Shortcode} from "../../../../../pages/settings";
import {useShortcodeList} from "../../../../../helpers";

export const ShortcodesKeypad = ({setButtonPressed, inputValue}) => {
    const {displayShortcodes} = useShortcodeList(inputValue)
    const {currentTab} = useCallContext();
    const {t} = usePolyglot();

    return (
        <div className={`${styles.shortcodes} ${currentTab === 'shortcodes' && styles.active_tab}`}>
            {currentTab === 'shortcodes' &&
                <>
                    <KeypadPageTitle
                        title={t("terms.short_code", 2)}
                        destination='number_keypad'
                    />
                    <div>
                        {displayShortcodes.map(shortcode => (
                            <AriaButton
                                key={shortcode.uuid}
                                onClick={() => setButtonPressed && setButtonPressed(shortcode.short_code)}
                            >
                                <Shortcode shortcode={shortcode}/>
                            </AriaButton>
                        ))}

                    </div>
                </>
            }
        </div>
    )
}

export default ShortcodesKeypad;
