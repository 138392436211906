/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface RadioProps extends React.HTMLProps<HTMLInputElement> {
    name: string;
    label: string;
}

export const Radio: React.FC<RadioProps> = ({name, label, ...props}) => (
        <div className='radio-input'>
            <label htmlFor={name}>
                <div className='radio-input__mask'>
                    <div className='radio-input__dot-container' data-is-checked={props.checked}>
                        <input id={name} type='checkbox' {...props}/>
                        <span className='radio-input__dot'/>
                    </div>
                </div>
                {label}
            </label>
        </div>
    );

export default Radio;
