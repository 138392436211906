/* eslint-disable prefer-destructuring */
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTypedSelector} from "../../redux/hooks";
import newMessageFavicon from '../../img/favicons/favicon-blue-dot.ico';
import {selectAllChatUnreadMessages} from "../../redux/slices";
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';
import { isPreview } from '../../helpers';

export const TabNotifications: React.FC = () => {
    const unreadChatCount = useTypedSelector(selectAllChatUnreadMessages)

    const { appFavicon, appName, isPulseHd, isCallSwitch } = useAppContext();
    const { t } = usePolyglot();

    let unreadIcon = appFavicon;

    switch (true) {
        case isCallSwitch:
        case isPulseHd:
            break;
        default:
            unreadIcon = newMessageFavicon;
    }

    useEffect(() => {
        const electron = (window as any).electron;

        if (electron) {
            if (unreadChatCount > 0) {
                electron.send('set-badge-count', unreadChatCount);
            } else {
                electron.send('set-badge-count', 0);
            }
        }
    }, [unreadChatCount]);

    if (unreadChatCount > 0) {
        return (
            <Helmet defer={false}>
                <title>{`${appName}${isPreview ? ' - Preview' : ''} - ${t("phrases.%count_unread", unreadChatCount)}`}</title>
                <link rel='icon' href={unreadIcon} />
            </Helmet>
        );
    }

    return (
        <Helmet defer={false}>
            <title>{appName}{isPreview ? ' - Preview' : ''}</title>
            <link rel='icon' href={appFavicon} />
        </Helmet>
    );
};
