import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {AuxCodeContextState, AuxCodeReducer, AuxCodeReducerAction, initialState} from "./reducer";

interface Props {
    reducer: Dispatch<AuxCodeReducerAction>;
    state: AuxCodeContextState;
}

export const AuxCodeContext = createContext<Props | null>(null)

export const useAuxCodeContext = () => {
    const context = useContext(AuxCodeContext)

    if (!context) {
        throw new Error(
            'Component requiring addNumberForReq rendered outside AuxCodeProvider'
        )
    }

    const useAuxAction: (val: AuxCodeReducerAction) => void = context.reducer;

    return {
        ...context.state,
        useAuxAction
    };
}

export const AuxCodeProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [state, dispatch] = useReducer(AuxCodeReducer, initialState);

    return (
        <AuxCodeContext.Provider value={{reducer: dispatch, state}}>
            {children}
        </AuxCodeContext.Provider>
    )
}

export default AuxCodeProvider