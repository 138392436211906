import React from 'react';
import formStyles from "../../../../../sass/globalModules/forms.module.scss";
import styles from "../../../NewChat/styles.module.scss";
import {StyledButton} from "../../../../StyledComponents";
import Icon from "../../../../Icon";
import NewChatMembersController from "../../../NewChat/children/NewChatMembersController";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {usePolyglot} from "../../../../../context/Polyglot";

interface Props {
    closeEditor: () => void;
    onAddMembers: () => void;
}

const AddUsersDisplay: React.FC<Props> = ({closeEditor, onAddMembers}) => {
    const {chat} = useChatContext();
    const {t} = usePolyglot();

    return (
        <div
            className={[formStyles.formContainer, styles.container].join(' ')}
            onBlur={(e) => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    closeEditor()
                }
            }}
        >
            <section className={formStyles.formHeader}>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    onClick={closeEditor}
                >
                    <Icon name='arrowLeft'/>
                </StyledButton>
                <h2>
                    {t('phrases.add_members_to_%name', {name: chat.display_name})}
                </h2>
            </section>
            <NewChatMembersController
                name='users_config.adding'
            />
            <div className={[formStyles.formControls, styles.formControls].join(' ')}>
                <StyledButton
                    buttonStyle='primary'
                    size='large'
                    onClick={() => onAddMembers()}
                >
                    <p>
                        {t('actions.add_members')}
                    </p>
                </StyledButton>
            </div>
        </div>
    )
};

export default AddUsersDisplay;
