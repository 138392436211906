import React, {ReactNode} from 'react';
import HeadsetProvider from "../../../context/HeadsetContext/context";
import HotKeyProvider, {HotKeyNavValue} from "../../../context/HotKeysContext/context";
import UserStatusProvider from "./UserStatusContext";
import AuxCodeProvider from "./AuxCodeController/context";

interface Props {
    children: ReactNode;
    hotKeyNavigate: (val: HotKeyNavValue) => void
}

const HomeContexProviders: React.FC<Props> = ({children, hotKeyNavigate}) => (
    <HotKeyProvider value={{navigate: hotKeyNavigate}}>
        <HeadsetProvider>
            <UserStatusProvider>
                <AuxCodeProvider>
                    {children}
                </AuxCodeProvider>
            </UserStatusProvider>
        </HeadsetProvider>
    </HotKeyProvider>
);

export default HomeContexProviders;
