import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    AppFilterPage, SmartDiallerNumber,
    SmartDiallerSelected,
    IAppSettings,
    ICallHandlingPutData,
    ILoggedInUser,
    IPhoneSettings,
    IUser
} from "../../../types";
import {checkAudioDevice} from "../../../helpers";
import {AppDispatch, AppThunk} from "../../store";
import {api} from "../../../api";
import {userSlice} from "./slice";

export const formatSmartDiallerNumberLabel = (num: SmartDiallerNumber): string => {
    if (!num.name) return num.value

    return `${num.name} (${num.value})`
}

export const oldParseSettings = {
    selected_phonebooks: {},
    favourite_contact: {},
    pinned_chats: {},
    muted_chats: {}
}

export const getUserState = (
    data: IUser,
    devices: { deviceId: string; label: string }[]
): ILoggedInUser | undefined => {
    if (!data) return undefined;

    const defaultUser: ILoggedInUser = {
        ...data,
        settings: {
            chat: {
                settings: {
                    disableChatNotifications: false,
                    chatNotificationVolume: 100,
                    isLargeFont: true,
                    defaultChatView: 'none',
                    lastOpenChatId: '',
                    disableEnter: false,
                    style: 'default'
                }
            },
            phone: {
                settings: {
                    allowInternalCalls: false,
                    autoAnswerClickToDial: false,
                    bringAppToFrontOff: false,
                    callVolumeMute: false,
                    callVolumeValue: 100,
                    callWaitingBeepOff: false,
                    contactsSortByLastName: false,
                    defaultToGridView: false,
                    disableHoldToParkCall: false,
                    disableShortCodesLongPress: false,
                    dnd: false,
                    inputDeviceId: 'default',
                    missedCallNotifications: false,
                    outputDeviceIdCall: 'default',
                    outputDeviceIdRingtone: 'default',
                    crmIntegrationUuids: [],
                    ringtoneVolumeMute: false,
                    ringtoneVolumeValue: 100,
                    showCallRouteName: false,
                    showPhonebookContactCompanyName: false,
                    videoDeviceId: 'default',
                    showCallHandling: false,
                    popParkedCallsTab: true,
                    popCrm: 'never',
                    disableHuntGroupDisplay: false,
                    phonebooksInContacts: false,
                    currentAppLocale: "en",
                    keepActiveCall: false,
                    popWhenAnswered: false,
                    useGlobalDnd: true,
                    popOnOutbound: false,
                }
            },
            appMeta: {
                filters: {
                    chatList: [],
                    callHistory: [],
                    contactList: [],
                },
                settings: {
                    asideWidth: 420,
                },
            }
        }
    };

    const localAppSettings = localStorage['yay.app.settings'];

    const localUserSettings = localStorage[data.uuid]; // calls and chat config

    if (localAppSettings && localUserSettings) {
        /** BELOW IS PARSING THE LOCAL STORAGE DATA */
        const parsedUserSettings = {
            ...JSON.parse(localUserSettings, (key, value: string) => {
                switch (true) {
                    case (key === 'popCrm' && [true, false].includes(value as any)):
                        return 'never';
                    case value === 'false':
                        return false;
                    case value === 'true':
                        return true;
                    case ['callVolumeValue', 'ringtoneVolumeValue'].includes(key):
                        return parseInt(value);
                    case key === 'phonebookUuids':
                        (value as any).forEach((val: string) => {
                            oldParseSettings.selected_phonebooks[val] = true;
                        })
                        return undefined;
                    case key === 'favouriteChats':
                        Object.entries(value).forEach(([uuid, val]) => {
                            oldParseSettings.pinned_chats[uuid] = val;
                        });
                        return undefined;
                    case key === 'mutedChats':
                        Object.entries(value).forEach(([uuid, val]) => {
                            oldParseSettings.muted_chats[uuid] = val;
                        })
                        return undefined;
                    case key === 'favouriteContacts':
                        Object.entries(value).forEach(([uuid, val]) => {
                            oldParseSettings.favourite_contact[uuid] = val;
                        });
                        return undefined;
                    default:
                        return value;
                }
            })
        };

        if (!parsedUserSettings.appMeta) {
            parsedUserSettings.appMeta = {}
        }

        if (!parsedUserSettings.appMeta?.filters) {
            parsedUserSettings.appMeta.filters = {
                chatList: [],
                callHistory: [],
                contactList: [],
            }
        }

        if (!parsedUserSettings.appMeta?.smartDiallerNumbers) {
            parsedUserSettings.appMeta.smartDiallerNumbers = [];
        }

        /** clear non-existing settings (e.g. if the setting state has changed) */
        /** handle changes to phonebook uuid => phonebook uuids */
        Object.keys(parsedUserSettings.phone.settings).forEach(key => {
            if (defaultUser.settings.phone.settings?.[key] === undefined) {
                delete parsedUserSettings.phone.settings[key];
            }
        })

        Object.keys(parsedUserSettings.chat.settings).forEach(key => {
            if (defaultUser.settings.chat.settings?.[key] === undefined) {
                delete parsedUserSettings.chat.settings[key];
            }
        })

        Object.keys(parsedUserSettings.appMeta?.settings || {}).forEach(key => {
            if (defaultUser.settings.appMeta.settings?.[key] === undefined) {
                delete parsedUserSettings.appMeta.settings[key];
            }
        })

        parsedUserSettings.phone.settings.outputDeviceIdRingtone = checkAudioDevice({
            devices,
            deviceId: parsedUserSettings.phone.settings.outputDeviceIdRingtone,
            deviceName: parsedUserSettings.phone.settings.outputDeviceNameRingtone
        });

        parsedUserSettings.phone.settings.outputDeviceIdCall = checkAudioDevice({
            devices,
            deviceId: parsedUserSettings.phone.settings.outputDeviceIdCall,
            deviceName: parsedUserSettings.phone.settings.outputDeviceNameCall
        });

        /** resave local storage */
        localStorage[data.uuid] = JSON.stringify(parsedUserSettings);

        localStorage['yay.app.settings'] = JSON.stringify({
            ...JSON.parse(localAppSettings),
            defaultSipUserUuid: data.uuid
        });

        return {
            ...defaultUser,
            hardware_phones: [],
            settings: {
                chat: {
                    ...defaultUser.settings.chat,
                    ...parsedUserSettings.chat,
                    settings: {
                        ...defaultUser.settings.chat.settings,
                        ...parsedUserSettings.chat.settings
                    }
                },
                phone: {
                    ...defaultUser.settings.phone,
                    ...parsedUserSettings.phone,
                    settings: {
                        ...defaultUser.settings.phone.settings,
                        ...parsedUserSettings.phone.settings
                    }
                },
                appMeta: {
                    ...defaultUser.settings.appMeta,
                    ...parsedUserSettings.appMeta,
                    settings: {
                        ...defaultUser.settings.appMeta.settings,
                        ...parsedUserSettings.appMeta?.settings
                    }
                },
            }
        };
    }

    localStorage['yay.app.settings'] = JSON.stringify({
        defaultSipUserUuid: data.uuid
    });

    return defaultUser;
};

export const setMessageCallerId =
    (uuid: string | undefined): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.setMessageCallerId(uuid));

export const updateAvatarKey =
    (key: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updateAvatarKey(key));

export const updateDevices =
    (devices: any[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updateDevices(devices));

export const updateAppSettings =
    (appSettings: IAppSettings): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updateAppSettings(appSettings));

export const updatePhoneSettings =
    (phoneSetting: { setting: keyof IPhoneSettings; value: string | number | boolean | string[] }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updatePhoneSettings(phoneSetting));

export const updateChatSettings =
    (chatSetting: { setting: string; value: string | number | boolean }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updateChatSettings(chatSetting));

export const updateAppMetaSettings =
    (chatSetting: { setting: string; value: string | number | boolean }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.updateAppMetaSettings(chatSetting));

export const changeCallerId = createAsyncThunk(
    '/change-caller-id',
    async ({userUuid, callerIdUuid}: { userUuid: string; callerIdUuid: string | undefined }) => {
        await api.changeCallerId(userUuid, callerIdUuid);
        return {callerIdUuid};
    }
);

export const updateMutedChat = createAsyncThunk(
    '/post-mute-chat',
    async ({
               userUuid,
               isChannel,
               uuidtoMute,
               mute
           }: {
        userUuid: string;
        isChannel: boolean;
        uuidtoMute: string;
        mute: boolean;
    }) => {
        if (mute) {
            if (isChannel) {
                await api.muteChannel(userUuid, uuidtoMute);
            } else {
                await api.muteUser(userUuid, uuidtoMute);
            }
        } else if (isChannel) {
            await api.unmuteChannel(userUuid, uuidtoMute);
        } else {
            await api.unmuteUser(userUuid, uuidtoMute);
        }

        return {key: uuidtoMute, value: mute};
    }
);

export const putCallHandling = createAsyncThunk(
    '/put-call-handling',
    async ({data, userUuid}: { data: ICallHandlingPutData; userUuid: string }) => {
        const response = await api.putCallHandling(userUuid, data);

        return response.data.result;
    }
);

export const setPageFilter =
    ({page, newFilters}: { page: AppFilterPage, newFilters: string[] }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.setPageFilter({page, newFilters}))

export const setSmartDiallerSelection =
    (smartDiallerSelection?: SmartDiallerSelected): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.setSmartDiallerItem(smartDiallerSelection))

export const addSmartDiallerNumber =
    (smartDiallerNumber: SmartDiallerNumber): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.addSmartDiallerNumber(smartDiallerNumber))

export const removeSmartDiallerNumber =
    (smartDiallerNumberUuid: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(userSlice.actions.removeSmartDiallerNumber(smartDiallerNumberUuid))