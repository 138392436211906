import React, {memo, useRef} from 'react';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';
import {useTypedSelector} from "../../../../redux/hooks";
import {
    selectAppSettingByKey,
} from "../../../../redux/slices";
import {focusNext, useHeightObserver} from "../../../../helpers";
import AriaListFocusContainer from "../../../AriaComponents/AriaListFocusContainer";
import MeetingRow from "./MeetingRow";
import {VideoSidebarItem} from "../index";
import VideoSideBarSkeleton from "../VideoSideBarSkeleton";
import DividerSkeleton from "../../../Skeletons/DividerSkeleton";

interface Props {
    dataSet: VideoSidebarItem[];
    loading?: boolean;
}

const MeetingsList = memo<Props>(({
                                      dataSet,
                                      loading
                                  }) => {
    const width = useTypedSelector(state => selectAppSettingByKey(state, 'asideWidth'));

    const heightRef = useRef<HTMLDivElement>(null);

    const {listHeight} = useHeightObserver(heightRef);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 76,
    });

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (!heightRef.current) {
            return;
        }

        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                focusNext({
                    container: heightRef.current,
                    ref: e.currentTarget.lastChild as HTMLElement,
                    before: true,
                })
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                focusNext({
                    container: heightRef.current,
                    ref: e.currentTarget.lastChild as HTMLElement
                })
                break;
            default:
                break
        }
    }

    const rowRenderer = ({
                             key,
                             index,
                             style,
                             parent,
                         }) => {
        const currentItem = dataSet[index];

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div
                    style={style}
                    onKeyDown={(e) => handleKeydown(e)}
                >
                    {currentItem.type === 'divider' ? (
                        <div>
                            <div className='list-divider'>
                                <p>{currentItem.data}</p>
                            </div>
                        </div>
                    ) : (
                        <MeetingRow
                            meeting={currentItem.data}
                        />
                    )}
                </div>
            </CellMeasurer>
        )

    }

    return (
        <AriaListFocusContainer
            className='meeting-list list-container'
            ref={heightRef}
            title='Meeting List'
        >
            {loading ? (
                <>
                    <DividerSkeleton text="Your Meetings"/>
                    {Array(10).fill(1).map((_, i) => (
                        <VideoSideBarSkeleton key={i}/>
                    ))}
                </>
            ) : (
                <AutoSizer>
                    {() => (
                        <List
                            width={width}
                            height={listHeight}
                            rowCount={dataSet.length}
                            rowRenderer={rowRenderer}
                            overscanRowCount={3}
                            rowHeight={cache.rowHeight}
                            deferredMeasurementCache={cache}
                            className='windowed-chat-list-container'
                            tabIndex={-1}
                            scrollToIndex={0}
                        />
                    )}
                </AutoSizer>
            )}
        </AriaListFocusContainer>
    );
}, (prevProps, nextProps) => {
    if (prevProps.loading !== nextProps.loading) {
        return false;
    }
    return prevProps.dataSet.length === nextProps.dataSet.length;

});

export default MeetingsList;
