import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {ICall} from "../../types";
import {CallAction, CallContextState, initialState, reducer} from "./reducer";

interface Props {
    reducer: Dispatch<CallAction>;
    state: CallContextState;
    call: ICall
}

const CallContext = createContext<Props | null>(null)

export const useCallContext = () => {
    const context = useContext(CallContext)

    if (!context?.call) {
        throw new Error(
            'Component requiring call context rendered without a call'
        )
    }

    const useCallAction: (val: CallAction) => void = context.reducer;

    return {
        speakerActive: context.state.speakerActive,
        currentTab: context.state.currentTab,
        membersTabOpen: context.state.membersTabOpen,
        call: context.call,
        useCallAction,
    }
}

interface ProviderProps {
    children: ReactNode;
    call: ICall;
}

export const CallProvider: React.FC<ProviderProps> = ({ children, call }) => {
    const [state, dispatch] = useReducer(reducer, {...initialState})

    return (
        <CallContext.Provider value={{reducer: dispatch, state, call}}>
            {children}
        </CallContext.Provider>
    )
}

export default CallProvider;