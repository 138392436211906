/* eslint-disable no-nested-ternary */
import React, {useRef} from 'react';
import {FormProvider} from "react-hook-form";
import Icon from "../../Icon";
import {usePolyglot} from "../../../context/Polyglot";
import FormTextField from "../../AriaForm/FormTextField";
import FormSelect from "../../AriaForm/FormSelect";
import {usePhonebookContactEditor} from "./hooks";
import PhonebookContactEditorMultipleInput from "./children/PhonebookContactEditorMultipleInput";
import LoadingSpinner from "../../loading/LoadingSpinner";
import {useOnClickOutside} from "../../../helpers";
import formStyles from '../../../sass/globalModules/forms.module.scss'
import {StyledButton} from "../../StyledComponents";
import {useTypedDispatch} from "../../../redux/hooks";
import {setEditPhonebookContact} from "../../../redux/slices";

interface Props {
}

export const PhonebookContactEditor: React.FC<Props> = () => {
    const {t} = usePolyglot();

    const editorRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch()

    const closeEditor = () => dispatch(setEditPhonebookContact());

    useOnClickOutside(editorRef, closeEditor)

    const {
        saveContact,
        deleteContact,
        actionLoading,
        saveError,
        deleteError,
        formMethods,
        editorMode,
        labelOptions,
        phonebookOptions
    } = usePhonebookContactEditor({
        closeEditor
    })

    return (
        <div
            className={['phonebook-contact-editor', formStyles.formContainer].join(' ')}
            ref={editorRef}
        >
            <section className={formStyles.formHeader}>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    onClick={() => closeEditor()}
                >
                    <Icon name='arrowLeft'/>
                </StyledButton>
                <h2>
                    {
                        editorMode === 'edit' ? (
                            t('actions.edit_contact')
                        ) : (
                            t('actions.add_contact', 1)
                        )
                    }
                </h2>
                {saveError ? (
                    <p>
                        {t("phrases.error_unable_to_save_contact_please_ty_again_later")}
                    </p>
                ) : null}
                {deleteError ? (
                    <p>
                        {t("phrases.error_unable_to_delete_contact_please_ty_again_later")}
                    </p>
                ) : null}
            </section>
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form onSubmit={formMethods.handleSubmit(saveContact)}>
                    <FormTextField
                        name='first_name'
                        label={t("terms.first_name")}
                        required
                    />
                    <FormTextField
                        name='last_name'
                        label={t("terms.last_name")}
                        required
                    />
                    <FormTextField
                        name='company'
                        label={t("terms.company", 1)}
                    />
                    <FormSelect
                        name='phonebook'
                        options={phonebookOptions}
                        readOnly={editorMode === 'edit' || undefined}
                        label={t('terms.phonebook', 1)}
                        required
                    />
                    <PhonebookContactEditorMultipleInput
                        base='phone_numbers'
                        label={t("terms.phone_number", 2)}
                        inputName='phoneNumber'
                        innerLabel={t("terms.phone_number", 1)}
                        labelOptions={labelOptions}
                        addMessage={t('phrases.add_phone_number')}
                        isPhonenumbers
                    />
                    <PhonebookContactEditorMultipleInput
                        base='emails'
                        label={t('terms.email_address')}
                        inputName='emailAddress'
                        innerLabel='Email'
                        labelOptions={labelOptions}
                        addMessage={t('phrases.add_email_address')}
                    />
                    <div className={formStyles.formControls}>
                        {actionLoading ? <LoadingSpinner color='mamba'/> : (
                            <>
                                {editorMode === 'edit' ? (
                                    <StyledButton
                                        buttonStyle='text'
                                        context='danger'
                                        type="button"
                                        onClick={() => deleteContact()}
                                    >
                                        <p>
                                            {t("actions.delete")}
                                        </p>
                                    </StyledButton>
                                ) : null}
                                <StyledButton
                                    buttonStyle='primary'
                                    size='large'
                                    type="submit"
                                >
                                    <p>
                                        {
                                            editorMode === 'edit' ? (
                                                t("actions.save")
                                            ) : (
                                                t('actions.add_contact', 1)
                                            )
                                        }
                                    </p>
                                </StyledButton>
                            </>
                        )}
                    </div>
                </form>
            </FormProvider>
        </div>
    )
};

export default PhonebookContactEditor;