import React, {ReactNode} from 'react';
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import StyledDropdown, {StyledDropdownOption} from "../../../../components/StyledComponents/StyledDropdown";
import styles from './styles.module.scss';

type Props = ({
    dropdown?: never
    options: StyledDropdownOption[];
    onSelect: (val: string) => void;
    loading?: boolean;
    selected?: string | string[]
} | {
    dropdown: ReactNode
    options?: never;
    onSelect?: never;
    loading?: never;
    selected?: never
}) & {
    title: string;
    placeHolder?: string;
    description?: ReactNode;
}

const SettingsDropdown: React.FC<Props> = ({
                                               options,
                                               onSelect,
                                               title,
                                               loading,
                                               placeHolder,
                                               selected,
                                               description,
                                               dropdown
                                           }) => {

    const identifier = `${title.split(' ').join('_')}_dropdown`

    let display: ReactNode;

    if (dropdown) {
        display = dropdown;
    } else if (loading) {
        display = <LoadingSpinner color='mamba'/>
    } else if (options) {
        display = <StyledDropdown
            onSelect={onSelect}
            options={options}
            placeHolder={placeHolder}
            selected={selected}
        />
    } else {
        display = null
    }

    return (
        <div
            className={styles.setting_dropdown}
        >
            <label
                htmlFor={identifier}
            >
                {title}
            </label>
            {
                display
            }
            {description ? (
                <div className={styles.description}>
                    {description}
                </div>
            ) : null}
        </div>
    );
};

export default SettingsDropdown;
