import {IGroupCallSpeaking} from "./context";

export interface GroupCallVolContextState {
    speakingMap: IGroupCallSpeaking
}

export const initialState: GroupCallVolContextState = {
    speakingMap: {}
}


export type GroupCallVolAction =
    {
        type: 'set_single_user_volume',
        payload: {
            uuid: string;
            volume: number;
        }
    } |
    {
        type: 'clear_volumes',
    }

export const reducer = (state: GroupCallVolContextState, action: GroupCallVolAction): GroupCallVolContextState => {

    switch (action.type) {
        case "set_single_user_volume":

            return {
                ...state,
                speakingMap: {
                    ...state.speakingMap,
                    [action.payload.uuid]: (action.payload.volume || 0) > 70
                }
            }
        case "clear_volumes":
            return initialState;
        default:
            return state
    }
}