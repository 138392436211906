import React, {ReactNode} from 'react';
import {useAuxCodeContext} from "./context";
import {StyledDropdownOption} from "../../../../components/StyledComponents";
import {isPreview} from "../../../../helpers";
import StyledDropdown from "../../../../components/StyledComponents/StyledDropdown";
import styles from "../../../../components/navigation/styles.module.scss";
import {
    addOneToast,
    selectAllCalls,
    selectCurrentUserId, selectCurrentUserQueueAvailability, selectCurrentUserSipName,
    selectQueueAvailability,
    selectUserAuxCode, selectUserGlobalDnd
} from "../../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {usePutAuxCodeMutation, usePutAvailableInCallQueuesMutation} from "../../../../redux/services/sipApi";

interface Props {
    user_data?: ForOtherProps
}

export const auxCodes = [
    'Available',
    'Unavailable',
    'Do Not Disturb',
    'After Call Work',
    'Lunch',
    'Break',
    'Scheduled Time Off',
    'Unscheduled Time Off',
    'Callout',
    'Supervisor Meeting',
    'Tech Issue',
    'System Startup'
]

interface DropdownProps {
    display: string | ReactNode | undefined,
    handleAuxSelect: (val: string) => void;
    loading: boolean;
    selected: string | undefined;
    disabled?: boolean
}

interface ForOtherProps {
    user_uuid: string;
    user_name: string;
}

const ControllerDropdown: React.FC<DropdownProps> = ({
                                                         display,
                                                         handleAuxSelect,
                                                         loading,
                                                         selected = "Available",
                                                         disabled
                                                     }) => {

    const opts: StyledDropdownOption[] = auxCodes.map(ac => ({
        label: ac,
        value: ac,
    }))

    if (isPreview) {
        opts.push({
            label: 'Testing',
            value: 'Testing',
        })
    }

    return (
        <StyledDropdown
            display={display}
            options={opts}
            onSelect={handleAuxSelect}
            loading={loading}
            selected={selected}
            className={styles.dropdown}
            disabled={disabled}
        />
    )
}

const ControllerForSelf: React.FC = () => {
    const {code_to_change_to, useAuxAction} = useAuxCodeContext();

    const queueAvailability = useTypedSelector(selectCurrentUserQueueAvailability);
    const currentUserSipName = useTypedSelector(selectCurrentUserSipName);
    const currentUserUuid = useTypedSelector(selectCurrentUserId);
    const callsLength = useTypedSelector(selectAllCalls).length;

    const aux_code = useTypedSelector((state) => selectUserAuxCode(state, currentUserSipName))
    const global_dnd = useTypedSelector(state => selectUserGlobalDnd(state, currentUserSipName))

    const [putAvailableInCallQueues, {isLoading: baseLoading}] = usePutAvailableInCallQueuesMutation();
    const [putAuxCode, {isLoading: auxLoading}] = usePutAuxCodeMutation();

    const handleAuxSelect = (newAux: string) => {
        if (callsLength > 0 && newAux !== 'Available') {
            useAuxAction({
                type: 'set_change_after_call_code',
                payload: newAux,
            })
            return;
        }

        putAuxCode({
            voip_user_uuid: currentUserUuid,
            auxCode: {
                code: newAux,
                set_by: currentUserUuid,
            }
        })
            // .catch(e => {
            //     console.log('e', e)
            // })


        if (newAux === 'Available' && !queueAvailability) {
            putAvailableInCallQueues({
                voip_user_uuid: currentUserUuid,
                available: true
            })
        }

        if (newAux !== 'Available' && queueAvailability) {
            putAvailableInCallQueues({
                voip_user_uuid: currentUserUuid,
                available: false
            })
        }
    }

    return (
        <ControllerDropdown
            display={code_to_change_to ? (<p>Change After Call</p>) : undefined}
            handleAuxSelect={handleAuxSelect}
            loading={baseLoading || auxLoading || false}
            selected={aux_code}
            disabled={global_dnd}
        />
    )
}

const ControllerForOther: React.FC<ForOtherProps> = ({user_uuid, user_name}) => {
    const aux_code = useTypedSelector((state) => selectUserAuxCode(state, user_name))
    const queue_availability = useTypedSelector((state) => selectQueueAvailability(state, user_name))

    const currentUserUuid = useTypedSelector(selectCurrentUserId);

    const [putAvailableInCallQueues, {isLoading: baseLoading}] = usePutAvailableInCallQueuesMutation();
    const [putAuxCode, {isLoading}] = usePutAuxCodeMutation();

    const dispatch = useTypedDispatch();


    const handleAuxSelect = (newAux: string) => {
        putAuxCode({
            voip_user_uuid: user_uuid,
            auxCode: {
                code: newAux,
                set_by: currentUserUuid,
            }
        })
            .then((r) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (r.error) {
                    dispatch(addOneToast({
                        title: `Queue Status Error`,
                        content: `Failed to set queue status: ${newAux}`,
                        type: "error"
                    }))
                }
            })

        if (newAux === 'Available' && !queue_availability) {
            putAvailableInCallQueues({
                voip_user_uuid: user_uuid,
                available: true
            })
        }

        if (newAux !== 'Available' && queue_availability) {
            putAvailableInCallQueues({
                voip_user_uuid: user_uuid,
                available: false
            })
        }
    }

    return (
        <ControllerDropdown
            display={undefined}
            handleAuxSelect={handleAuxSelect}
            loading={isLoading || baseLoading || false}
            selected={aux_code}
        />
    )
}

const AuxCodeController: React.FC<Props> = ({user_data}) => {

    if (user_data) {
        return (
            <ControllerForOther user_uuid={user_data.user_uuid} user_name={user_data.user_name}/>
        )
    }

    return (
        <ControllerForSelf/>
    );
};

export default AuxCodeController;
