import {formatBytes} from "../index";
import Icon from "../../../../components/Icon";
import styles from "../Attachment.module.scss";

export interface GenericAttachmentProps {
    file: any;
}

interface AttachmentNameDisplayProps {
    file: {
        name: string;
        size?: number;
    };
    noSizeDisplay?: boolean;
}

export const AttachmentNameDisplay: React.FC<AttachmentNameDisplayProps> = ({file, noSizeDisplay}) => (
    <div
        className={styles.file_info}
        title={file.name}>
        <p>
            <span>
                {file.name.slice(0, -6)}
            </span>
            <span>
                {file.name.slice(-6)}
            </span>
        </p>
        {file.size && !noSizeDisplay ? (
            <span>{formatBytes(file.size)}</span>
        ) : null}
    </div>
)

export const GenericAttachment: React.FC<GenericAttachmentProps> = ({
                                                                        file,
                                                                    }) => {

    const fileEnding = file.name.split('.').slice(-1)[0].toLowerCase();

    let fileType: JSX.Element;

    switch (file.type || file.content_type || fileEnding) {
        case 'application/zip':
        case 'zip':
            fileType =
                <div className={styles.file_type}>
                <span>
                    ZIP
                </span>
                </div>;
            break;
        case 'application/x-msdownload':
        case 'exe':
            fileType = <div className={styles.file_type}>
                <span>
                    EXE
                </span>
            </div>;
            break;
        case 'application/vnd.ms-excel':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'msword':
            fileType = <div className={styles.file_type}>
                <span>
                    DOC
                </span>
            </div>;
            break;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'ppt':
            fileType = <div className={styles.file_type}>
                <span>
                    PPT
                </span>
            </div>;
            break;
        case 'text/plain':
        case 'txt':
            fileType = <div className={styles.file_type}>
                <span>
                    TXT
                </span>
            </div>;
            break;
        case 'application/json':
        case 'json':
            fileType = <div className={styles.file_type}>
                <span>
                    JSON
                </span>
            </div>;
            break;
        case 'text/csv':
        case 'csv':
            fileType = <div className={styles.file_type}>
                <span>
                    CSV
                </span>
            </div>;
            break;
        case 'application/x-iwork-numbers-sffnumbers':
        case 'numbers':
            fileType = <div className={styles.file_type}>
                <span>
                    NUM
                </span>
            </div>;
            break;
        case 'text/html':
        case 'html':
            fileType = <div className={styles.file_type}>
                <span>
                    HTML
                </span>
            </div>;
            break;
        default:
            fileType = <div
                className={styles.file_type}
                data-generic='true'
            >
                <Icon name='attachmentFile'/>
            </div>;
    }

    return (
        <>
            <div className={styles.preview_button_container}>
                {fileType}
            </div>
            <div className={styles.file_content_container}>
                <AttachmentNameDisplay file={file}/>
            </div>
        </>
    )
}