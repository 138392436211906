import {useMemo} from "react";
import moment from "moment";
import {useTypedSelector} from "../../../redux/hooks";
import {
    selectAllPhonebookEntities,
    selectCurrentUserId,
    selectCurrentUserSipName,
    selectUserCountryCode, selectUserExtensionsToNickDict
} from "../../../redux/slices";
import {usePrevious} from "../../../helpers";
import {usePolyglot} from "../../../context/Polyglot";
import {IYeOldeCall, PhonebookContactEntity} from "../../../types";
import {getNumbersArr} from "./CallHistoryRow/ExternalCallHistoryRow";

export const useAllCallHistory = ({searchQuery, filter, missedCallsOnly}: {
                                      searchQuery: string;
                                      filter: string[];
                                      missedCallsOnly: boolean | undefined;
                                  }
) => {
    const callHistory: IYeOldeCall[] = useTypedSelector(state => state.sip.callHistory) || [];
    const currentUserId = useTypedSelector(selectCurrentUserId);
    const userSipName = useTypedSelector(selectCurrentUserSipName);
    const allPhonebookEntities = useTypedSelector(selectAllPhonebookEntities);
    const countryCode = useTypedSelector(selectUserCountryCode);
    const userByExt = useTypedSelector(selectUserExtensionsToNickDict);

    const {t} = usePolyglot();

    const previousFilter = usePrevious(filter)

    const filterHist = (history: any[]) => {
        let filtered: any[] = history || [];

        filter?.forEach(f => {
            filtered = filtered.filter(c => {
                if (f === 'Internal' && c.call_type === 'internal') {
                    return true
                }
                if (f === 'Inbound' && c.call_type === 'inbound') {
                    return true
                }
                return f === 'Outbound' && c.call_type === 'outbound';

            })
        })

        return filtered
    }

    const searchResults = useMemo(() => callHistory.filter(call => {

        const displayName =
            !call.from || call.from.name === userSipName
                ? call.to?.nickname || call.to?.number
                : call.from.nickname || call.from.number;

        if (displayName?.toLowerCase().includes(searchQuery.toLowerCase())) {
            return true;
        }

        const callee =
            !call.from || call.from.name === userSipName
                ? call.to?.number || ''
                : call.from.number || ''

        if (callee?.toLowerCase().includes(searchQuery.toLowerCase())) {
            return true;
        }

        const foundUser = userByExt[callee];

        if (foundUser && foundUser.name.toLowerCase().includes(searchQuery.toLowerCase())) {
            return true
        }

        const numArr = getNumbersArr(callee, countryCode);

        for (let i = 0; i < numArr.length; i += 1) {
            const item: PhonebookContactEntity | undefined = allPhonebookEntities[numArr[i]]

            if (item && item.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                return true
            }
        }

        return false
    }), [searchQuery]);

    return useMemo(() => {
        let dividerDate = '';
        const dateFormat = 'dddd D MMMM';
        const data: any[] = [];

        let init = searchQuery ? searchResults : callHistory;


        init = init.filter(call => {
            switch (true) {
                case missedCallsOnly && (call.call_type === 'internal' && call.from?.uuid !== currentUserId && !call.answered):
                    return true
                case missedCallsOnly && (call.call_type === 'inbound' && !call.answered):
                    return true
                case !missedCallsOnly:
                    return true
                default:
                    return false
            }
        })

        if (previousFilter !== filter) {
            init = filterHist(init)
        }

        init.forEach((c, index) => {

            if (
                !dividerDate ||
                moment(c.call_start_time).format(dateFormat) !== dividerDate ||
                index === 0
            ) {
                let customDividerDate: string | null = null;

                dividerDate = moment(c.call_start_time).format(dateFormat);

                if (dividerDate === moment().format(dateFormat)) {
                    customDividerDate = t("adverbs.today");
                }

                if (
                    dividerDate === moment().subtract(1, 'day').format(dateFormat)
                ) {
                    customDividerDate = t("adverbs.yesterday");
                }

                data.push({
                    type: 'divider',
                    data: customDividerDate || dividerDate || null,
                })
            }
            data.push(c)
        })

        return data
    }, [searchResults, filter, missedCallsOnly, callHistory]);
}
