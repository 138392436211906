import React, {useState} from 'react';
import AriaText from "../../../../../components/AriaComponents/AriaText";
import Icon from "../../../../../components/Icon";
import {usePolyglot} from "../../../../../context/Polyglot";
import {useChannelMembers} from "../../../ChatHeader/hooks";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {GroupMember} from "./GroupMember";
import styles from '../styles.module.scss';
import {StyledButton} from "../../../../../components/StyledComponents";

interface Props {
}

const ChannelParticipants: React.FC<Props> = () => {
    const {displayMembers, adminUuids} = useChannelMembers();
    const {chat} = useChatContext()
    const {t} = usePolyglot();

    const [groupMembersList, setGroupMembersList] = useState<boolean>(false);
    const [showGroupMembers, setShowGroupMembers] = useState<number>(10);

    return (
        <div className={styles.channel_members}>
            <StyledButton
                buttonStyle='text'
                onClick={() => setGroupMembersList(!groupMembersList)}
                size='medium'
            >
                <AriaText text={groupMembersList ? t("actions.show_less") : t("actions.show_all")}/>
                <p>
                    {t("phrases.%count_participants", {count: chat?.channel?.members.length || 0})}
                </p>
                <Icon name={groupMembersList ? 'arrowDown' : 'arrowRight'} width={20} height={20}/>
            </StyledButton>
            {groupMembersList && <>
                {displayMembers.slice(0, showGroupMembers).map(member => (
                    <GroupMember
                        member={member}
                        key={member.member}
                        adminUuids={adminUuids}
                        admin={adminUuids?.includes(member.member) || undefined}
                        channelCreator={member.member === chat.channel?.created_by}
                    />
                ))}
                {chat.channel && (chat.channel?.members?.length > showGroupMembers) &&
                    <StyledButton
                        buttonStyle='text'
                        size='medium'
                        onClick={() => setShowGroupMembers(chat.channel?.members?.length || showGroupMembers + 10)}
                    >
                        <p>
                            {t("actions.show_all")}
                        </p>
                        <Icon name='arrowDown' ariaHidden width={22} height={22}/>
                    </StyledButton>
                }
                {chat.channel && showGroupMembers > 10 &&
                    <StyledButton
                        buttonStyle='text'
                        onClick={() => setShowGroupMembers(10)}
                        size='medium'
                    >
                        <p>
                            {t("actions.show_less")}
                        </p>
                        <Icon name='arrowUp' ariaHidden width={22} height={22}/>
                    </StyledButton>
                }
            </>
            }
        </div>
    );
};

export default ChannelParticipants;
