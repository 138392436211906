import React, {ReactElement, ReactNode, useMemo} from "react";
import moment from "moment";
import {SessionState} from "sip.js";
import {Counter} from "../counter";
import styles from "./Keypad.module.scss";
import {AnimatedDiv} from "../../animation";
import {usePolyglot} from "../../context/Polyglot";
import {useCallContext} from "../../context/CallContext/context";
import {useKeypadContext} from "../../context/KeypadContext/context";
import {useCallerDisplayNameGeneric} from "../../helpers";
import {useGetContactNameByNumberQuery} from "../../redux/services/sipApi";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    addOneToast,
    findContactOnBook,
    parseNumber,
    selectApiOnlyPhonebooks,
    selectCallerIdById,
    selectCurrentCallerId,
    setViewContact
} from "../../redux/slices";
import {PhonebookContactEntity} from "../../types";
import AriaButton from "../AriaComponents/AriaButton";

interface Props {
}

export const CallInfo: React.FC<Props> = () => {
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);
    const curCallerId = useTypedSelector(selectCurrentCallerId);

    const callerIdData = useTypedSelector(state => selectCallerIdById(state, curCallerId));

    const {keypadState} = useKeypadContext();
    const {call} = useCallContext();

    const dispatch = useTypedDispatch();

    const {
        type,
        name,
        data
    } = useCallerDisplayNameGeneric(call.callee)

    const {
        type: type_other,
        name: name_other,
        data: data_other,
    } = useCallerDisplayNameGeneric(call.assertedIdentity?.number)

    const onContactClick = (entity: PhonebookContactEntity) => {
        if (type !== 'contact') {
            return
        }

        const phonebookUuid = entity.phonebookUuid || entity.details[0]?.phonebook_uuid;
        const contactUuid = entity.uuid || entity.details[0]?.uuid;

        if (!phonebookUuid || !contactUuid) return;

        dispatch(findContactOnBook({
            phonebookUuid,
            contactUuid,
        }))
            .then(r => {
                if (!r) {
                    dispatch(addOneToast({
                        content: `Failed to find ${entity.name || 'contact'} in contacts`,
                        title: "Failed to find contact",
                        type: "error"
                    }))
                }

                dispatch(setViewContact(r))
            })
    }

    useGetContactNameByNumberQuery(
        {
            numbers: call.callee ? [parseNumber(call.callee)] : [],
            phonebooks: apiPhonebooks
        },
        {
            skip: apiPhonebooks.length < 1 || type !== undefined || !call.callee
        }
    )
    const {t} = usePolyglot();

    let subInfo: ReactNode | string;

    switch (call.state) {
        case SessionState.Initial:
            subInfo = call.isOutbound ? t("phrases.presence_ringing") : t("adjective.call_inbound");
            break;
        case SessionState.Terminated:
            subInfo = t("phrases.call_ended");
            break;
        default:
            if (call.onHold) {
                subInfo = t("actions.hold");
                break;
            }
            if (call.isParking) {
                subInfo = t("terms.parking");
                break;
            }
            subInfo = <Counter
                initialTime={moment().unix() - Math.min(moment().unix(), call.answeredTime ? call.answeredTime / 1000 : moment().unix())}/>
    }

    if ((call.state === SessionState.Initial && call.isAdmin)) {
        return (
            <div className={styles.call_info}>
                <h3
                    onMouseDown={e => e.stopPropagation()}
                    style={{textAlign: 'center'}}
                >
                    {call.state && [SessionState.Terminating, SessionState.Terminated].includes(call.state) && t("phrases.leaving")}
                    {t("terms.conference_call", 1)}
                </h3>
            </div>
        )
    }

    const displayStrs: {
        first_line: ReactNode,
        second_line: ReactElement | null,
    } = useMemo(() => {

        let displayName: ReactNode = call.displayName || name || call.callee || null;
        let numberStr: ReactElement | null = displayName === call.callee ? null : <p>{call.callee}</p>;

        if (call.assertedIdentity) {
            numberStr = <p>From {name}</p>;
            if (["contact", "user"].includes(type_other || "") && call.assertedIdentity.number) {
                displayName = (
                    <AriaButton
                        onClick={() => {
                            if (type_other === 'contact') {
                                onContactClick(data_other)
                            }
                        }}
                    >
                        <h3>
                            {`${name_other} (${call.assertedIdentity.number})`}
                        </h3>
                    </AriaButton>)
            } else {
                displayName = call.assertedIdentity.number || call.assertedIdentity.name || "Transfer";
            }

            return {
                first_line: displayName,
                second_line: numberStr
            }
        }

        if (type === 'user') {
            return {
                first_line: name,
                second_line: <p>{call.callee}</p>
            }
        }

        if (call.displayName === 'DDI') {
            displayName = callerIdData?.cli_name || "DDI"

            if (type === 'contact') {
                numberStr = (
                    <p>
                        <AriaButton onClick={() => onContactClick(data)}><span>{name}</span></AriaButton>
                        ({call.callee})
                    </p>
                )
            }

            return {
                first_line: displayName,
                second_line: numberStr
            }
        }

        if (type === 'contact' && call.displayName === name) {
            return {
                first_line: <AriaButton onClick={() => onContactClick(data)}><h3>{name}</h3></AriaButton>,
                second_line: <p>{call.callee}</p>
            }
        }

        if (type === 'contact') {
            return {
                first_line: displayName,
                second_line: <p>
                    <AriaButton onClick={() => onContactClick(data)}><span>{name}</span></AriaButton>
                    ({call.callee})
                </p>
            }
        }

        return {
            first_line: displayName,
            second_line: numberStr
        }
    }, [call, type, name, data, type_other, name_other, data_other, callerIdData]);

    return (
        <AnimatedDiv
            visible={!(call.roomId) && (keypadState.keypadActiveStatus)}
            className={styles.call_info}
            inAnimation={['fade']}
            transition={{duration: .4, delay: .1}}
        >
            <div className={styles.firstLine}>
                <h3 onMouseDown={e => e.stopPropagation()}>
                    {displayStrs.first_line}
                </h3>
            </div>
            <div className={styles.secondLine}>
                {displayStrs.second_line}
                <p className='counter'>
                    {subInfo}
                </p>
            </div>
        </AnimatedDiv>
    )
}

export default CallInfo;
