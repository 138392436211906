import React, {useEffect, useState} from "react";
import {useTypedSelector} from "../../../redux/hooks";
import {selectActiveFeedbackForms} from "../../../redux/slices";
import CallFeedbackForm from "./CallFeedbackForm";
import styles from './styles.module.scss'


interface CallFeedbackContainerProps {
}

export const CallFeedbackContainer: React.FC<CallFeedbackContainerProps> = () => {
    const allFeedbackForms = useTypedSelector(selectActiveFeedbackForms);

    const [feedbackPage, setFeedbackPage] = useState<number>(0);

    useEffect(() => {
        if (feedbackPage > allFeedbackForms.length - 1) {
            setFeedbackPage(allFeedbackForms.length - 1)
        }
    }, [allFeedbackForms.length]);

    const changePage = (move: 'next' | 'back') => {
        setFeedbackPage(feedbackPage + (move === 'next' ? 1 : -1))
    }

    return (
        <div
            className={styles.callFeedbackContainer}
            data-multiple-forms={allFeedbackForms?.length > 1}
        >
            {allFeedbackForms?.map((form, index) => (
                <CallFeedbackForm
                    key={`CFF_${form.invite_uuid}_${form.call_uuid}`}
                    activeForm={form}
                    active={index === feedbackPage}
                    formBefore={index > 0}
                    formAfter={index < allFeedbackForms.length - 1}
                    changePage={changePage}
                />
            ))}
        </div>
    )
}

export default CallFeedbackContainer