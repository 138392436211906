import React from 'react';
import styles from './ThemeSelector.module.scss';
import { StyledButton } from '../../../../../../components/StyledComponents';

export const SchemaChange: React.FC = () => {
    const schema = localStorage.previewTheme;

    const availableThemes = [
        {
            value: 'yay',
            title: 'Yay'
        },
        {
            value: 'callswitch',
            title: 'CallSwitch One'
        },
        {
            value: 'pulsehd',
            title: 'PulseHD'
        }
    ];

    return (
        <div className={styles.selector_container}>
            {availableThemes.map(theme => {
                const { value, title } = theme;

                return (
                    <label className={styles.label} data-is-active={value === schema} key={value}>
                        <StyledButton
                            buttonStyle='tertiary'
                            onClick={() => {
                                localStorage.setItem('previewTheme', value);
                                (window as any).location.reload();
                            }}
                        >
                            <p>{title}</p>
                        </StyledButton>
                    </label>
                );
            })}
        </div>
    );
};

export default SchemaChange;
