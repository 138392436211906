import React, {useRef, useState, Suspense, forwardRef} from "react";
import styles from "./Keypad.module.scss";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import DragAndDropKeypad from "./displays/DragAndDropKeypad/DragAndDropKeypad";
import {CallProvider} from "../../context/CallContext/context";
import NotInCallKeypad from "./displays/NotInCallKeypad/NotInCallKeypad";
import CallsWidget from "./CallsWidget";
import KeypadDraggableRoot from "./KeypaddraggbaleRoot";
import {focusNext, useOnClickOutside} from "../../helpers";
import {useKeypadContext} from "../../context/KeypadContext/context";
import {useDragContact, useHasAudioDevice, useKeypad, useKeypadAux} from "./hooks";
import ContactProvider from "../../context/ContactContext/context";
import {IPhonebookContact} from "../../types";
import CallInstance from "./CallInstance";
import CallAudio from "./CallAudio";
import TransferringKeypad from "./displays/TransferringKeypad/TransferringKeypad";
import GroupCallVolProvider from "../../context/CallContext/GroupCallVolumesContext/context";
import {selectAllCalls} from "../../redux/slices";
import {useTypedSelector} from "../../redux/hooks";
import {useConferenceWatcher} from "./ConferenceHooks";

interface Props {
}

export interface ContinuingDAD {
    context: 'new' | 'group' | 'transfer';
    contact: IPhonebookContact
}


const Keypad = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {keypadState, useKeypadAction} = useKeypadContext();

    const calls = useTypedSelector(selectAllCalls);

    const [continueWith, setContinueWith] = useState<ContinuingDAD | undefined>(undefined);

    const containerRef = useRef<HTMLDivElement>(null);
    const keypadExitRef = useRef<HTMLDivElement>(null);
    const keypadEnterRef = useRef<HTMLDivElement>(null);

    const {handleKeypadToggle} = useKeypad();

    const {dragContact} = useDragContact({handleKeypadToggle})

    useHasAudioDevice();
    useConferenceWatcher()
    useKeypadAux()

    useOnClickOutside(containerRef, () => {
        if (keypadState.keypadActiveStatus) {
            handleKeypadToggle(false)
        }
    });

    return (
        <div
            aria-label='keypad'
            className={styles.draggable_container}
            ref={ref}
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget) {
                    handleKeypadToggle(false)
                }
            }}
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    handleKeypadToggle(false)
                }
            }}
        >
            {dragContact ? (
                <ContactProvider value={{contact: dragContact}}>
                    <DragAndDrop/>
                </ContactProvider>
            ) : null}
            <div
                className={styles.draggable_area}
                ref={containerRef}
            >
                <KeypadDraggableRoot
                    containerRef={containerRef.current}
                    handleKeypadToggle={handleKeypadToggle}
                >
                    <div>
                        {(continueWith?.contact || dragContact) ? (
                            <ContactProvider value={{contact: continueWith ? continueWith.contact : dragContact}}>
                                <DragAndDropKeypad
                                    continueWith={continueWith}
                                    setContinueWith={setContinueWith}
                                />
                            </ContactProvider>
                        ) : null}
                        <TransferringKeypad/>
                        <div
                            className={styles.keypad_container}
                            data-is-in-call={calls.length > 0 || null}
                        >
                            <div
                                onClick={() => useKeypadAction({
                                    type: 'SetWidgetActive',
                                    payload: false
                                })}
                            >
                                <div>
                                    {calls.length > 0 ? (
                                        calls.map((c, index) => (
                                            <CallProvider
                                                call={c}
                                                key={`CI_${index}_${c.id}`}
                                            >
                                                <GroupCallVolProvider>
                                                    <Suspense>
                                                        <CallInstance/>
                                                        <CallAudio/>
                                                    </Suspense>
                                                </GroupCallVolProvider>
                                            </CallProvider>
                                        ))
                                    ) : (
                                        <NotInCallKeypad
                                            handleKeypadToggle={handleKeypadToggle}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {calls.length > 1 &&
                            <CallsWidget/>
                        }
                    </div>
                </KeypadDraggableRoot>
            </div>
            <div
                ref={keypadExitRef}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                style={{
                    width: 0,
                    height: 0,
                    position: 'fixed',
                    // visibility: 'hidden'
                }}
                onFocus={() => keypadEnterRef.current && focusNext({ref: keypadEnterRef.current})}
            />
            <div
                ref={keypadEnterRef}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                style={{
                    width: 0,
                    height: 0,
                    position: 'fixed',
                    // visibility: 'hidden'
                }}
                onFocus={() => containerRef.current && focusNext({container: containerRef.current})}
            />
        </div>
    )
})

export default Keypad