import * as Sentry from "@sentry/react";


export const errorHandler = ({requestConfig, err}) => {

    const sendSentryMessage = ({message, info}) => {
        const req = {...requestConfig}

        if (req.data?.password) {
            req.data.password = 'hidden'
        }

        Sentry.captureMessage(message, {
            extra: {
                request: req,
                error: err.response?.data
            },
            tags: {
                info: `${info}, ${err.response.statusText}`
            }
        });
    }

    switch (requestConfig.url) {
        case '/generate-device-link':
            sendSentryMessage({
                message: 'Failed generate-device-link',
                info: 'failed to generate a qr code link for login',
            })
            break;

        case '/login':
            if (err.response?.status === 404) break;
            sendSentryMessage({
                message: 'Failed login',
                info: 'login server failed',
            })
            break;

        case '/check-confirmation-code':
            if (err.response?.status === 400 && err.response.statusText === 'Bad Request') break;
            sendSentryMessage({
                message: 'Failed check-confirmation-code',
                info: 'Failed check-confirmation-code',
            })
            break;

        case '/api/get-specific-sip-user':
            if (err.response?.status === 404) break;
            sendSentryMessage({
                message: 'Failed /api/get-specific-sip-user',
                info: 'failed to login as a specific sip user',
            })
            break;

        case '/api/get-latest-chats':
            sendSentryMessage({
                message: 'Failed /api/get-latest-chats',
                info: 'failed to get chats for a user',
            })
            break;

        case '/api/get-phonebook-contacts':
            sendSentryMessage({
                message: 'Failed /api/get-phonebook-contacts',
                info: 'failed to get phonebook contacts for a user',
            })
            break;

        case '/api/get-mailbox-messages':
            sendSentryMessage({
                message: 'Failed /api/get-mailbox-messages',
                info: 'failed to get mailbox messages for a user',
            })
            break;

        case '/api/get-call-history':
            sendSentryMessage({
                message: 'Failed /api/get-call-history',
                info: 'failed to get call history for a user',
            })
            break;

        case '/api/get-caller-ids':
            sendSentryMessage({
                message: 'Failed /api/get-caller-ids',
                info: 'failed to get caller IDs for a user',
            })
            break;

        case '/api/get-parking-slots':
            sendSentryMessage({
                message: 'Failed /api/get-parking-slots',
                info: 'failed to get parking slots for a user',
            })
            break;

        case '/api/get-chat-messages':
            sendSentryMessage({
                message: 'Failed /api/get-chat-messages',
                info: 'failed to get chat messages',
            })
            break;
    }
}