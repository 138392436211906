import React, {RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import moment from "moment";
import throttle from 'lodash.throttle';
import {EntityId} from "@reduxjs/toolkit";
import {clearScrollToMessageUuid, deleteOneChat, selectCurrentUserId} from "../../../redux/slices";
import {
    useGetChatMessagesAfterQuery,
    useGetChatMessagesBeforeQuery,
    usePostAllMessagesReadMutation
} from "../../../redux/services/chatApi";
import {focusNext, usePrevious} from "../../../helpers";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {useChatContext} from "../../../context/ChatContext/context";
import {
    selectFirstMessageInChunk,
    selectLastMessageInChunk,
    selectMessageIds, selectMessageInChunk,
} from "../../../redux/slices/messageSlice";
import {mergeChunks} from "../../../redux/slices/messageSlice/thunks";

interface UseChatMessagesProps {
    chatMessageContainer: RefObject<HTMLDivElement>;
    scrollToBottom: (value?: "auto" | "smooth" | undefined) => void;
    setChatMessageContainer: ({param, value}: { param: string, value: any }) => void
}


export class GetAndSet<T> {
    private val: T | undefined

    constructor(initialValue?: T) {
        this.val = initialValue;
    }

    get() {
        return this.val
    }

    set(newVal: T) {
        this.val = newVal
    }
}

export type ScrollType = undefined | 'before' | 'after' | 'diffing' | 'first_message';

const getDateDivider = (container: HTMLElement): string | undefined => {
    if (!container) return undefined;

    const res = container
        .querySelectorAll('.chat__date-divider')

    const dividerArr = Array.from(res).reverse();

    if (!dividerArr || dividerArr.length < 1) {
        return undefined;
    }

    const dividerElem = dividerArr.find(
        (elem) => (elem as HTMLElement).getBoundingClientRect().y < 0
    );

    if (dividerElem) {
        return dividerElem.innerHTML.replaceAll('<hr>', '')
    }

    if (container.querySelector(".skeletons_chat_body")) {
        return dividerArr[dividerArr.length - 1].innerHTML.replaceAll('<hr>', '');
    }

    return undefined;
}

export const useChatMessages = ({
                                    chatMessageContainer,
                                    scrollToBottom,
                                    setChatMessageContainer
                                }: UseChatMessagesProps) => {
    const {chat, isScrolled, useChatAction} = useChatContext();
    const [showDownZoom, setShowDownZoom] = useState(false);

    const [dividerDate, setDividerDate] = useState<string | undefined>(
        chatMessageContainer.current ? getDateDivider(chatMessageContainer.current) : undefined
    );

    const latestMessageFrom = useTypedSelector(state => selectMessageInChunk(state, chat.uuid, 'initial', chat.latestMessage.id))?.from
    const messageIds = useTypedSelector(state => selectMessageIds(state, chat.uuid, chat.active_chunk)) || []
    const scrollToMessageId = useTypedSelector(state => state.chat.scrollToMessageId);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const [messagesToDisplayCount, setMessagesToDisplayCount] = useState<number>(20);

    const scrollType = useRef(new GetAndSet<ScrollType>());

    const prevScrollPos = usePrevious((chatMessageContainer.current?.scrollHeight || 0) - (chatMessageContainer.current?.scrollTop || 0)) ?? 0;
    const prevScrollHeight = usePrevious(chatMessageContainer.current?.scrollHeight || 0) ?? 0;
    const previousLatestMessageId = usePrevious(chat.latestMessage.id)

    const scrollBottomRef = useRef<number>(0);

    const reversedMessageIds = useMemo<EntityId[]>(() => (
        messageIds
            .slice(0, Math.min(messagesToDisplayCount, messageIds.length))
            .reverse()
    ), [
        messagesToDisplayCount,
        messageIds.length
    ])

    const dispatch = useTypedDispatch()

    const {
        newMessagesBeforeIsFetching,
        getMessagesBefore,
        getMessagesBeforeDisabled,
    } = useChatMessagesBefore({
        getMessagesCount: 20,
        scrollType: scrollType.current,
    })
    const {
        newMessagesAfterIsFetching,
        getMessagesAfter,
        getMessagesAfterDisabled,
    } = useChatMessagesAfter({
        getMessagesCount: 20,
        scrollType: scrollType.current,
    })

    useLayoutEffect(() => {
        if (previousLatestMessageId !== chat.latestMessage.id && chat.active_chunk === 'initial') {
            scrollType.current.set('first_message')
            setMessagesToDisplayCount(messagesToDisplayCount + 1)
        }
    }, [chat.latestMessage.id]);

    useLayoutEffect(() => {
        if (!scrollToMessageId) return;
        const indexOfMessage = messageIds.indexOf(scrollToMessageId)

        if (indexOfMessage > Math.max(messagesToDisplayCount, 0)) {
            setMessagesToDisplayCount(indexOfMessage)
        }
    }, [scrollToMessageId]);


    let lastScrollTop: number | undefined;

    if (!lastScrollTop) {
        lastScrollTop = chatMessageContainer.current?.scrollTop;
    }

    useLayoutEffect(() => {
        if (!reversedMessageIds?.length || !chatMessageContainer.current) return;

        if (scrollToMessageId && !reversedMessageIds.includes(scrollToMessageId)) {
            dispatch(
                clearScrollToMessageUuid()
            ).then(() => {
                scrollToBottom("auto")
            });

        }

        if (chat.type === 'thread' && reversedMessageIds.length < 1) {
            dispatch(deleteOneChat(chat.uuid))
        }

        if (scrollToMessageId) {
            scrollType.current.set(undefined)
            return;
        }
        const scrollTypeVal = scrollType.current.get()


        let distanceFromTop: number;
        let distanceFromBottom: number;
        switch (scrollTypeVal) {
            case 'before':
                setChatMessageContainer({
                    param: 'scrollTop',
                    value: chatMessageContainer.current.scrollHeight - prevScrollPos
                })
                break
            case 'after':
                setChatMessageContainer({
                    param: 'scrollTop',
                    value: (prevScrollHeight || 0) - prevScrollPos
                })
                break
            case 'first_message':
                distanceFromTop = chatMessageContainer.current.scrollTop;
                distanceFromBottom = chatMessageContainer.current.scrollHeight - (distanceFromTop + chatMessageContainer.current.offsetHeight);

                switch (true) {
                    case distanceFromBottom <= 1048:
                    case distanceFromBottom <= 2096 && latestMessageFrom === userUuid:
                        scrollToBottom('smooth');
                        break;
                    case latestMessageFrom === userUuid:
                        scrollToBottom('auto');
                        break;
                }
                break
            default:
        }

        scrollType.current.set(undefined)
    }, [reversedMessageIds?.length]);

    const handleThrottledChatViewScroll = throttle((target: HTMLDivElement) => {
        if (!messageIds) return;

        const scrollingUp = !!lastScrollTop && (lastScrollTop > target.scrollTop);

        const scrollingDown = !!lastScrollTop && (lastScrollTop < target.scrollTop);

        const scrollLimit = 2000;
        const distanceFromTop = target.scrollTop;
        const distanceFromBottom = target.scrollHeight - (distanceFromTop + target.offsetHeight);

        if (
            scrollingUp
            && distanceFromTop < scrollLimit
            && !newMessagesBeforeIsFetching
        ) {
            if (messagesToDisplayCount >= messageIds.length) {
                getMessagesBefore();
                setMessagesToDisplayCount(messagesToDisplayCount + 20);
            }
            if (messagesToDisplayCount <= messageIds.length + 20) {
                setMessagesToDisplayCount(messagesToDisplayCount + 20);
            }
        }

        if (
            scrollingDown
            && distanceFromBottom < scrollLimit
            && chat.active_chunk === 'search'
            && !newMessagesAfterIsFetching
        ) {
            if (messagesToDisplayCount >= messageIds.length) {
                getMessagesAfter();
                setMessagesToDisplayCount(messagesToDisplayCount + 20);
            }

            if (messagesToDisplayCount <= messageIds.length + 20) {
                setMessagesToDisplayCount(messagesToDisplayCount + 20);
            }
        }

        if (distanceFromBottom > 2250 && !showDownZoom) {
            setShowDownZoom(true)
        }

        if (distanceFromBottom <= 2250 && showDownZoom) {
            setShowDownZoom(false)
        }


        if (distanceFromBottom > 4 && !isScrolled) {
            useChatAction({
                type: 'set_is_scrolled',
                payload: true,
            })
        }

        if (distanceFromBottom <= 4 && isScrolled) {
            useChatAction({
                type: 'set_is_scrolled',
                payload: false,
            })
        }

        if (chatMessageContainer.current) {
            const newDividerDate = getDateDivider(chatMessageContainer.current)

            if (newDividerDate !== dividerDate) {
                setDividerDate(newDividerDate)
            }
        }

        lastScrollTop = target.scrollTop;
        scrollBottomRef.current = target.scrollHeight - target.scrollTop - target.clientHeight;
    }, 300);

    return {
        newMessagesBeforeIsFetching,
        newMessagesAfterIsFetching,
        reversedMessageIds,
        handleThrottledChatViewScroll,
        getMessagesAfterDisabled,
        getMessagesBeforeDisabled,
        showDownZoom,
        dividerDate
    }
}


export const useChatMessagesBefore = ({
                                          getMessagesCount,
                                          scrollType
                                      }: { getMessagesCount: number; scrollType: GetAndSet<ScrollType> }) => {
    const {chat} = useChatContext();

    const topOfMessageList = useTypedSelector(state => selectLastMessageInChunk(state, chat.uuid, chat.active_chunk))
    const messageIds = useTypedSelector(state => selectMessageIds(state, chat.uuid, chat.active_chunk)) || []
    const searchMessageIds = useTypedSelector(state => selectMessageIds(state, chat.uuid, 'search')) || []
    const userUuid = useTypedSelector(selectCurrentUserId);

    const isDisabled = useRef(messageIds.length > 20);

    const [getMessagesBeforeDisabled, setGetMessagesBeforeDisabled] = useState<{
        initial: boolean;
        search: boolean;
    }>({
        initial: false,
        search: false,
    });

    const dispatch = useTypedDispatch();

    const [getMessagesBeforeQuery, setGetMessagesBeforeQuery] = useState<any>({
        voip_user_uuid: userUuid,
        type: chat.type,
        chat_uuid: chat.uuid,
        time: moment(topOfMessageList?.time).utc().toISOString(),
        offset: 0,
        limit: 20,
    });

    useLayoutEffect(() => {
        isDisabled.current = messageIds.length > 20;
    }, [chat.active_chunk]);

    const {
        data: newMessagesBeforeData,
        refetch: refetchBefore,
        isFetching: newMessagesBeforeIsFetching,
    } = useGetChatMessagesBeforeQuery(getMessagesBeforeQuery, {
        skip: !getMessagesBeforeQuery.time
            || !topOfMessageList
            || getMessagesBeforeDisabled.initial
            || (chat.active_chunk === 'search' && getMessagesBeforeDisabled.search)
            || isDisabled.current
    });

    useEffect(() => {
        if (!newMessagesBeforeData?.result.messages) return;

        scrollType.set('before');

        if (
            chat.active_chunk === 'initial' &&
            newMessagesBeforeData.result.messages.some(newMessage => searchMessageIds.includes(newMessage.uuid))
        ) {
            dispatch(mergeChunks({chat_uuid: chat.uuid}))
        }
        if (newMessagesBeforeData.result.messages.length < getMessagesCount) {
            setGetMessagesBeforeDisabled({
                initial: chat.active_chunk === 'initial' || getMessagesBeforeDisabled.initial,
                search: chat.active_chunk === 'search' || getMessagesBeforeDisabled.search,
            })
        }
    }, [newMessagesBeforeData]);

    const getMessagesBefore = (override?: boolean) => {
        if ((
            getMessagesBeforeDisabled.initial
            || (chat.active_chunk === "search" && getMessagesBeforeDisabled.search)
            || newMessagesBeforeIsFetching) && !override
        ) return;

        isDisabled.current = false

        if ((getMessagesBeforeQuery?.time === topOfMessageList?.time)) {
            refetchBefore();
        } else {
            const newQuery = {
                voip_user_uuid: userUuid,
                type: chat.type,
                chat_uuid: chat.uuid,
                offset: 0,
                limit: getMessagesCount,
                time: moment(topOfMessageList?.time).utc().toISOString()
            }
            if (getMessagesBeforeQuery !== newQuery) {
                setGetMessagesBeforeQuery(newQuery);
            }
        }
    }

    return {
        newMessagesBeforeIsFetching,
        getMessagesBefore,
        getMessagesBeforeDisabled: getMessagesBeforeDisabled.initial
            || (chat.active_chunk === "search" && getMessagesBeforeDisabled.search),
        newMessagesBeforeData
    }
}

export const useChatMessagesAfter = ({
                                         getMessagesCount,
                                         scrollType
                                     }: { getMessagesCount: number; scrollType: GetAndSet<ScrollType> }) => {
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId);
    const floorOfList = useTypedSelector(state => selectFirstMessageInChunk(state, chat.uuid, chat.active_chunk))

    const dispatch = useTypedDispatch();

    const initialMessageIds = useTypedSelector(state => selectMessageIds(state, chat.uuid, 'initial')) || []

    const getMessagesAfterDisabled = chat.active_chunk === 'initial'

    const [getMessagesAfterQuery, setGetMessagesAfterQuery] = useState<any>(
        chat.active_chunk === 'search' ? {
            voip_user_uuid: userUuid,
            type: chat.type,
            chat_uuid: chat.uuid,
            time: moment(floorOfList?.time).utc().toISOString(),
            offset: 0,
            limit: getMessagesCount,
        } : undefined
    );

    const {
        data: newMessagesAfterData,
        refetch: refetchAfter,
        isFetching: newMessagesAfterIsFetching,
    } = useGetChatMessagesAfterQuery(getMessagesAfterQuery, {
        skip: !getMessagesAfterQuery?.time || getMessagesAfterDisabled
    });

    useEffect(() => {
        if (!newMessagesAfterData?.result.messages) return;

        scrollType.set('after')

        if (
            newMessagesAfterData.result.messages.some(newMessage => initialMessageIds.includes(newMessage.uuid))
            || newMessagesAfterData.result.messages.length < getMessagesCount
        ) {
            dispatch(mergeChunks({chat_uuid: chat.uuid}))
        }
    }, [newMessagesAfterData]);


    const getMessagesAfter = (override?: boolean) => {
        if ((getMessagesAfterDisabled || newMessagesAfterIsFetching) && !override) return;

        if ((getMessagesAfterQuery?.time === floorOfList?.time)) {
            refetchAfter();
        } else {
            setGetMessagesAfterQuery({
                voip_user_uuid: userUuid,
                type: chat.type,
                chat_uuid: chat.uuid,
                offset: 0,
                limit: getMessagesCount,
                time: moment(floorOfList?.time).utc().toISOString(),
            })
        }
    }

    return {
        newMessagesAfterIsFetching,
        getMessagesAfter,
        getMessagesAfterDisabled,
        newMessagesAfterData
    }
}

export const useChatFocus = () => {
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId)

    const [postAllMessagesRead] = usePostAllMessagesReadMutation();

    const handleFocus = () => {
        if (chat.unread_count > 0) {
            postAllMessagesRead({
                voip_user_uuid: userUuid,
                type: chat.type,
                chat_uuid: chat.uuid
            })
        }
    }

    useEffect(() => {
        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener('focus', handleFocus);
        }
    }, [chat.unread_count]);
}

/**
 * Body :
 *  Tab (+shift) - move off list when on list, regular if inside
 *  Escape - focus on list / close menu
 *  Enter, arrow down, arrow up - focus on first element of list
 */
export const useChatMessageListKeydown = ({
                                              chatMessageContainer,
                                              containerEndRef
                                          }: { chatMessageContainer: RefObject<HTMLDivElement>, containerEndRef: RefObject<HTMLDivElement> }) => {

    const focusOnFirst = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.currentTarget !== e.target) return;

        if (!chatMessageContainer.current) return;


        const initialTarget = Array
            .from(chatMessageContainer.current.children).at(-1)

        if (initialTarget) {
            (initialTarget as HTMLElement).focus()
        }
    }

    const handleTab = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.target === e.currentTarget && !e.shiftKey) {
            e.preventDefault()
            if (containerEndRef.current) focusNext({ref: containerEndRef.current})
        }
    }

    const ACTIONS = {
        ArrowDown: (e) => focusOnFirst(e),
        ArrowUp: (e) => focusOnFirst(e),
        Enter: (e) => focusOnFirst(e),
        Tab: (e) => handleTab(e),
        Escape: (e) => e.currentTarget.focus(),
    }

    return (e) => {
        const handler = ACTIONS[e.key];
        if (handler) {
            if (e.key !== 'Tab') {
                e.preventDefault();
            }
            handler(e);
        }
    };
}

/**
 * Messages:
 *  arrow up, arrow down -
 *      if on message - focus on next message
 *      if inside message - focus on message
 *      if inside options - focus inside options
 *  arrow left, arrow right - focus on next element inside of message
 *  Enter, space - click on element
 */
export const useChatMessageKeydown = () => {

    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement;

        let listNode;

        if (target.nextSibling?.firstChild?.nodeName === 'UL') {
            listNode = target.parentNode
        }

        if (target.parentNode?.nodeName === 'UL') {
            listNode = target.parentNode?.parentNode?.parentNode
        }

        if (listNode) {
            focusNext({
                container: listNode as HTMLElement,
                before,
            })
            return
        }

        let navTarget = before ? e.currentTarget.previousSibling : e.currentTarget.nextSibling;

        if (!navTarget) return;

        if ((navTarget as HTMLElement).className === 'chat__date-divider') {
            navTarget = before ? navTarget.previousSibling : navTarget.nextSibling;
        }

        if (!navTarget) return;

        if ((navTarget as HTMLElement).className === 'chat__unread-count') {
            focusNext({
                container: navTarget as HTMLElement,
            })
        } else {
            (navTarget as HTMLElement).focus();
        }
    }

    const handleArrowX = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        focusNext({
            container: e.currentTarget,
            before,
            includeContainer: true,
        })
    }

    const ACTIONS = {
        ArrowRight: (e) => handleArrowX(e),
        ArrowLeft: (e) => handleArrowX(e, true),
        ArrowDown: (e) => handleArrowY(e),
        ArrowUp: (e) => handleArrowY(e, true),
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
    }

    return (e) => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}


export const useReadDividerKeydown = () => {

    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {

        let navTarget = before ? e.currentTarget.previousSibling : e.currentTarget.nextSibling;

        if (!navTarget) return;

        if ((navTarget as HTMLElement).className === 'chat__date-divider') {
            navTarget = before ? navTarget.previousSibling : navTarget.nextSibling;
        }

        if (!navTarget) return;

        (navTarget as HTMLElement).focus();
    }

    const ACTIONS = {
        ArrowDown: (e) => handleArrowY(e),
        ArrowUp: (e) => handleArrowY(e, true),
    }

    return (e) => {
        const handler = ACTIONS[e.key];
        if (handler) {
            if (e.key !== 'Tab') {
                e.preventDefault();
            }
            handler(e);
        }
    };
}
