import React from 'react';
import styles from "../styles.module.scss";

type Props = {
    avatar_key?: string;
    avatar_str?: string;
    alt: string,
    setImageError: (val: boolean) => void;
    imgOnly?: boolean;
}


const ImageOnlyIcon: React.FC<Props> = ({avatar_str, avatar_key, alt, setImageError, imgOnly}) => {

    const imagePart = (
        <img
            src={avatar_str || `https://storage.googleapis.com/v-profiles/${avatar_key}`}
            alt={alt}
            onError={() => setImageError(true)}
        />
    )

    if (imgOnly) return imagePart;

    return (
        <div
            className={styles.user_icon}
            data-format={3}
        >
            {imagePart}
        </div>
    );
};

export default ImageOnlyIcon;
