import React, {FunctionComponent, useState} from 'react';
import {FormProvider} from "react-hook-form";
import {useMeetingEditor} from "./hooks";
import {StyledButton} from "../../../components/StyledComponents";
import FormTextField from "../../../components/AriaForm/FormTextField";
import styles from '../styles.module.scss';
import MenuHeader from "../../../components/menus/MenuHeader";
import MeetingConfigParticipants from "./MeetingConfigParticipants";
import MeetingConfigAdvanced from "./MeetingConfigAdvanced";
import {selectActiveRoomId, setVideoPage} from "../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import CopyBtn from "../children/CopyBtn";
import Icon from "../../../components/Icon";
import {api} from "../../../api";
import {getVideoBaseUrl} from "../helpers";

interface Props {
}

const UrlRandomizer = ({setUrl}: { setUrl: (val: string) => void }) => {

    const [generatingUrl, setGeneratingUrl] = useState(false);

    const onUrlGenerate = async () => {
        const limit = 10;
        let counter = 0;

        do {
            const getRandomCharacters = (length: number) => Math.random().toString(36).slice(length * -1);
            const url = `${getRandomCharacters(3)}-${getRandomCharacters(3)}-${getRandomCharacters(3)}`

            try {
                // eslint-disable-next-line no-await-in-loop
                await api.checkMeetingId({
                    meetingId: url
                });
                setGeneratingUrl(false);
                setUrl(url)
                break;

            } catch (error) {

                if (counter === 10) {
                    setGeneratingUrl(false);
                }

                counter += 1;
            }
        } while (counter <= limit);
    }

    return (
        <StyledButton
            buttonStyle='text'
            iconOnly
            size='small'
            onClick={() => {
                setGeneratingUrl(true)
                onUrlGenerate();
            }}
            loading={generatingUrl}
        >
            <Icon name='loop'/>
        </StyledButton>
    )
}

const MeetingConfig: FunctionComponent<Props> = () => {
    const activeRoomId = useTypedSelector(selectActiveRoomId);

    const dispatch = useTypedDispatch();

    const {
        formMethods,
        saveForm,
        usePassword,
        loading
    } = useMeetingEditor();

    return (
        <div
            className={styles.config_form}
        >
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form onSubmit={formMethods.handleSubmit(saveForm)}>
                    <MenuHeader
                        title={activeRoomId ? 'Edit Meeting' : 'New Meeting'}
                        titleOptions={
                            <div className='menu-header__icon-container'>
                                {!loading ? (
                                    <StyledButton
                                        buttonStyle='text'
                                        fontWeight='medium'
                                        context='danger'
                                        onClick={() => {
                                            if (activeRoomId) {
                                                dispatch(setVideoPage('meeting-preview'))
                                            } else {
                                                dispatch(setVideoPage('meeting-home'))
                                            }
                                        }}
                                    >
                                        <p>Cancel</p>
                                    </StyledButton>
                                ) : null}
                                <StyledButton
                                    buttonStyle='primary'
                                    fontWeight='medium'
                                    type='submit'
                                    loading={loading}
                                >
                                    {activeRoomId ? (
                                        <p>
                                            Save Changes
                                        </p>
                                    ) : (
                                        <p>Create Meeting</p>
                                    )}
                                </StyledButton>
                            </div>
                        }
                    />
                    <div className={styles.form_data}>
                        <div>
                            <FormTextField
                                name='name'
                                label='Meeting Name'
                                required
                            />
                            <FormTextField
                                name='custom_url'
                                label='Meeting ID'
                                widgets={(
                                    <div className={styles.id_prefix}>
                                        <p>
                                            {getVideoBaseUrl(true)}/
                                        </p>
                                    </div>
                                )}
                                required
                                afterInputElem={(<>
                                    <CopyBtn
                                        getText={() => `${getVideoBaseUrl()}/${formMethods.getValues("custom_url")}`}
                                    />
                                </>)}
                                labelExtra={(
                                    <UrlRandomizer
                                        setUrl={(val: string) => {
                                            formMethods.setValue("custom_url", val)
                                        }}
                                    />
                                )}
                            />
                            <FormTextField
                                name='password'
                                label='Password'
                                noInput={!usePassword}
                            />
                            <FormTextField
                                name='description'
                                label='Description'
                                isTextArea
                            />
                        </div>
                        <div>
                            <MeetingConfigParticipants/>
                            <MeetingConfigAdvanced/>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default MeetingConfig;
