import {v4 as uuidv4} from "uuid";
import moment from "moment";
import z from "zod";
import {
    ChatType, ChunkType, FocusMessageAttachment,
    getIntegrationType,
    IChat,
    IChatChannel,
    IChatIntegration,
    IChatMessage, InterTenantUser, IPhonebookContact, isThread,
    IThread,
    IThreadSource,
    IUser,
    IUserForChannel
} from "../../../types";
import {isCallSwitch, isYay} from "../../../helpers";
import yayLogo from "../../../img/logos/logo-yay-white.svg";
import callswitchLogo from "../../../img/logos/logo-callswitch-white.svg";
import pulseHdLogo from "../../../img/logos/logo-pulsehd.svg";
import {AppDispatch, AppThunk, RootState} from "../../store";
import {api, api as services} from "../../../api";
import audioURL from "../../../sounds/notification.mp3";
import {selectMutedChats, updateActivePage} from "../appSlice";
import {selectChatSettingByKey, selectCurrentUserId, userSlice} from "../userSlice";
import {chatSlice} from "./slice";
import {
    selectChatById,
    selectLastUpdatedChatId,
} from "./selectors";
import {t} from "../../../lib/polyglot.lib";
import {
    messagesAdapter,
    messageSlice, selectAllMessageIdsInChat,
    selectFirstMessageInChunk, selectGlobalMessageById, selectGlobalMessageLocation,
    selectMessageChunk,
} from "../messageSlice";
import {addOneChatToMessage} from "../messageSlice/thunks";
import {selectAuthUserByUuid, selectDepartmentDictionary, selectUsersDictionary} from "../authSlice";
import {
    contactTagRegex,
    departmentTagRegex,
    genericTagRegex,
    userTagRegex
} from '../../../pages/chat/MarkdownComponents';


const friendValidator = z.object({
    uuid: z.string(),
    user_uuid: z.string(),
    display_name: z.string(),
})

// type Friend = TypeOf<typeof friendValidator>;

const buildNotificationObject = ({message, chatName}, {getState}) => {
    const sender = selectAuthUserByUuid(getState(), message.from);
    const userDictionary = selectUsersDictionary(getState);
    const departmentDictionary = selectDepartmentDictionary(getState);

    const notificationData = {
        display_name: '',
        body: '',
        icon: ''
    }

    const tagParse = (stringToParse: string): string => {

        const userTagParser = userTagRegex.exec(stringToParse)

        let difStr = stringToParse;

        if (userTagParser) {
            const userDisplayName = userDictionary[userTagParser[1]]?.nickname || t("adjective.user_unknown")

            difStr = stringToParse.replaceAll(userTagParser[0], `@${userDisplayName}`)
        }

        const contactTagParser = contactTagRegex.exec(difStr)

        if (contactTagParser) {
            const parsedContact: IPhonebookContact = JSON.parse(contactTagParser[1]);
            const contactDisplayName = [parsedContact.first_name, parsedContact.last_name].join(" ") || t("adjective.user_unknown")

            difStr = difStr.replaceAll(contactTagParser[0], `#${contactDisplayName}`)
        }

        const DepartmentTagParser = departmentTagRegex.exec(difStr)

        if (DepartmentTagParser) {
            const parsedDepartmentName: string = departmentDictionary[DepartmentTagParser[1]]?.name || "Unknown";

            difStr = difStr.replaceAll(DepartmentTagParser[0], `@${parsedDepartmentName}`)
        }

        const GenericTagParser = genericTagRegex.exec(difStr)

        if (GenericTagParser) {
            difStr = difStr.replaceAll(GenericTagParser[0], `#${GenericTagParser[1]}`)
        }

        if (!userTagParser && !contactTagParser && !DepartmentTagParser && !GenericTagParser) return stringToParse;

        return tagParse(difStr)
    }

    const content = tagParse(message.content);

    switch (true) {
        case !!sender?.avatar_key:
            if (sender?.avatar_key) {
                notificationData.icon = `https://storage.googleapis.com/v-profiles/${sender.avatar_key}`
            }
            break;
        case isYay:
            notificationData.icon = yayLogo;
            break;
        case isCallSwitch:
            notificationData.icon = callswitchLogo;
            break;
        default:
            notificationData.icon = pulseHdLogo;
    }

    switch (message.to_type) {
        case 'channel':
            notificationData.display_name = chatName || t("terms.group_chat", 1);
            notificationData.body = `${sender?.nickname || t("phrases.new_message")}: ${content || (message.files ? t("phrases.sent_file") : '')}`
            break

        case 'user':
            if (message.from_type === 'integration') {
                // To get the actual name call API - atm. show generic name
                notificationData.display_name = t("phrases.new_message");
            } else {
                notificationData.display_name = sender?.nickname || t("phrases.new_message");
            }

            notificationData.body = content || (message.files ? t("phrases.sent_file") : '');

            break

        case 'thread':
            notificationData.display_name = `${t("terms.thread", 1)}: ${sender?.nickname || t("phrases.new_message")}`;
            notificationData.body = content || (message.files ? t("phrases.sent_file") : '');
            break
    }

    return notificationData;
}

const showNotification = ({
                              notificationObject,
                              chat,
                              messagesLength
                          }: { notificationObject: any; chat: IChat; messagesLength: number | undefined }): AppThunk =>
    (dispatch: AppDispatch, getState) => {
        const state: RootState = getState();

        const currentUserId = selectCurrentUserId(state);

        // shows notification
        const notification = new Notification(notificationObject.display_name, {
            icon: notificationObject.icon,
            body: notificationObject.body
        });

        const chatClick = () => {
            if (messagesLength && messagesLength <= 30) {
                services.getChatMessagesById({
                    uuid: currentUserId,
                    type: chat.type,
                    chatId: chat.uuid,
                    offset: messagesLength
                }).then().catch();
            }

            services.postAllMessagesRead({
                voip_user_uuid: currentUserId,
                type: chat.type,
                chat_uuid: chat.uuid
            }).then().catch()

            dispatch(chatSlice.actions.setActiveChat(chat.uuid));

        }

        const threadClick = () => {

            const getParentId = () => {
                if (!chat) return undefined

                return chat.thread_message?.to === currentUserId ?
                    chat.thread_message?.from :
                    chat.thread_message?.to;
            }

            const parentChat = selectChatById(state, getParentId());


            const threadMessage = chat.thread_message;

            if (parentChat && threadMessage) {
                dispatch(setActiveChat(parentChat.uuid));

                if (threadMessage.uuid) {
                    dispatch(focusOnMessage({
                        chat_uuid: parentChat.uuid,
                        message: threadMessage,
                    }));
                }
            }
        }

        notification.onclick = () => {
            dispatch(
                updateActivePage('chat')
            )

            if (isThread(chat)) {
                threadClick()
            } else {
                chatClick()
            }

            if ((window as any).electron) {
                (window as any).electron.send('bring-window-to-front');
            }

            window.focus();
            notification.close();
        };

        notification.onshow = () => {
            // if is electron return
            if (navigator.userAgent.toLowerCase().includes(' electron/')) return;

            const setNotificationVolume = selectChatSettingByKey(state, 'chatNotificationVolume');

            const audio = new Audio(audioURL);
            const notificationVolume = (setNotificationVolume ?? 100) / 100;

            audio.autoplay = true;
            audio.volume = notificationVolume;
            audio.play().catch((error: Error) => {
                console.warn(error.message); // TODO: Fire checkAudioPermissions
            });
        };
    }

export const sendMessageNotification =
    (message: IChatMessage): AppThunk =>
        (dispatch: AppDispatch, getState) => {
            // This browser does not support desktop notification
            if (!("Notification" in window)) return;

            // if app is open ignore
            if (document.hasFocus() || !document.hidden) return;

            // permission notifications has been denied
            if (Notification.permission === "denied") return;

            const state: RootState = getState();

            // if notifications are disabled ignore
            if (selectChatSettingByKey(state, 'disableChatNotifications')) return;

            const currentUserId = selectCurrentUserId(state);

            // if message was send by current user ignore
            if (message.from === currentUserId) return;

            const chat = selectChatById(state, message.to_type === 'user' ? message.from : message.to);
            const messagesLength = selectAllMessageIdsInChat(state, message.to_type === 'user' ? message.from : message.to)?.length || undefined;

            // if there is no required data ignore
            if (!chat) return;

            let threadParentChat: string | undefined;

            if (message.to_type === 'thread' && message.to) {
                threadParentChat = selectGlobalMessageLocation(state, message.to)?.chat_uuid;
            }

            const mutedChats = selectMutedChats(state) || {};

            // if it should not ignore muted AND chat is muted OR is thread and thread parent is muted ignore
            if (
                mutedChats[chat.uuid]
                || (threadParentChat && mutedChats[threadParentChat])
                || (message.to_type === 'thread' && !threadParentChat)
            ) return;

            // BUILD NOTIFICATION OBJECT
            const notificationObject = buildNotificationObject({message, chatName: chat.display_name}, {getState})

            // if permission is granted show notification
            if (Notification.permission === "granted") {
                dispatch(showNotification({notificationObject, chat, messagesLength}))

            } else {
                // else request permission and based on the res show notification or ignore
                Notification.requestPermission()
                    .then((permission) => {
                        if (permission === "granted") {
                            dispatch(showNotification({notificationObject, chat, messagesLength}))
                        }
                    });
            }
        };

export const startNewIntegrationChat =
    ({message, integrationFromApi}: { message?: IChatMessage, integrationFromApi?: IChatIntegration }): AppThunk =>
        async (dispatch: AppDispatch) => {

            let newIntegration;

            if (message) {
                try {
                    const {data: {result}} = await services.getIntegrationDetails(message.from);

                    if (!result?.channel) return;

                    newIntegration = {
                        uuid: message?.from,
                        display_name: result.name || result.number || t("phrases.integration_chat"),
                        number: result.number || '',
                        channel: getIntegrationType(result.channel)
                    }

                } catch (error) {
                    // do nothing
                }
            }

            // if integration was passed as params it means that you are creating the chat
            // else use the new integration that was constructed from the message data and fetched data
            const integration = integrationFromApi || newIntegration;

            // if for some reason there is no integration return
            if (!integration) return;

            const unreadCount = message ? 1 : 0;

            const chatObject: IChat = {
                display_name: integration.display_name,
                type: 'integration' as ChatType,
                uuid: integration.uuid,
                user_deleted: false,
                unread_count: unreadCount,
                new_messages_count: unreadCount,
                integration,
                active_chunk: 'initial',
                latestMessage: {
                    id: integration.uuid,
                    time: moment().format()
                }
            };

            const initialMessage = messagesAdapter.setOne(messagesAdapter.getInitialState(), message || {
                uuid: integration.uuid,
                from: 'hide',
                from_type: 'user',
                to: integration.uuid,
                to_type: 'integration',
                content: t("phrases.this_is_start_chat"),
                time: moment().format(),
                updated_at: moment().format()
            })

            dispatch(addOneChatToMessage({
                chat_uuid: integration.uuid,
                value: {
                    initialChunk: initialMessage,
                    searchChunk: messagesAdapter.getInitialState(),
                }
            }))

            dispatch(chatSlice.actions.addOneChat(chatObject));
        }

export const startNewUserChat =
    ({message, newChatUser}: { message?: IChatMessage, newChatUser?: IUser }): AppThunk =>
        async (dispatch: AppDispatch, getState) => {

            const state: RootState = getState();
            const currentUser = selectCurrentUserId(state);
            const sender = message ? selectAuthUserByUuid(state, message.from) : newChatUser

            const unreadCount = (message && message.from !== currentUser) ? 1 : 0;

            let newChat: IChat | undefined;

            if (sender) {
                newChat = {
                    display_name: sender.nickname,
                    type: 'user' as ChatType,
                    uuid: sender.uuid,
                    user_deleted: false,
                    unread_count: unreadCount,
                    new_messages_count: unreadCount,
                    active_chunk: 'initial',
                    latestMessage: {
                        id: message?.uuid || '',
                        time: moment().format(),
                    }
                };
            } else {
                if (!message) {
                    return;
                }

                try {

                    const friendRes = await api.getFriend({
                        voip_user_uuid: currentUser,
                        friend_uuid: message.from
                    });

                    const {result} = friendRes.data;

                    if (!result) return;

                    const friend = friendValidator.parse(result)

                    newChat = {
                        display_name: friend.display_name,
                        type: 'user' as ChatType,
                        uuid: friend.user_uuid,
                        user_deleted: false,
                        unread_count: unreadCount,
                        new_messages_count: unreadCount,
                        active_chunk: 'initial',
                        latestMessage: {
                            id: message?.uuid || '',
                            time: moment().format(),
                        }
                    };


                } catch (e) {
                    console.error("e", e)
                }

            }

            if (!newChat) return;

            const initialMessage = messagesAdapter.setOne(messagesAdapter.getInitialState(), message || {
                from: 'hide',
                content: t("phrases.this_is_start_chat_%name", {
                    name: newChat.display_name
                }),
                time: moment().format(),
                updated_at: moment().format(),
                uuid: ''
            })

            dispatch(addOneChatToMessage({
                chat_uuid: newChat.uuid,
                value: {
                    initialChunk: initialMessage,
                    searchChunk: messagesAdapter.getInitialState(),
                }
            }))

            dispatch(chatSlice.actions.addOneChat(newChat));
        }

export const startNewChannel =
    (channel: IChatChannel): AppThunk =>
        (dispatch: AppDispatch, getState) => {

            const state: RootState = getState();
            const currentUser = selectCurrentUserId(state);

            const unreadCount = (channel.created_by && channel.created_by !== currentUser) ? 1 : 0;

            const uuid = `${uuidv4()}-noMenu`;

            const chatObject: IChat = {
                display_name: channel.name || t("terms.group_chat", 1),
                type: 'channel' as ChatType,
                unread_count: unreadCount,
                new_messages_count: unreadCount,
                user_deleted: false,
                uuid: channel.uuid,
                channel,
                active_chunk: 'initial',
                latestMessage: {
                    id: uuid,
                    time: moment().format()
                }
            }

            const initialMessage = messagesAdapter.setOne(messagesAdapter.getInitialState(), {
                content: channel.name
                    ? t("phrases.added_to_chat_%name", {name: channel.name})
                    : t("phrases.added_to_group_chat"),
                time: moment().format(),
                updated_at: moment().format(),
                from: '',
                uuid
            })

            dispatch(addOneChatToMessage({
                chat_uuid: channel.uuid,
                value: {
                    initialChunk: initialMessage,
                    searchChunk: messagesAdapter.getInitialState(),
                }
            }))

            return dispatch(chatSlice.actions.addOneChat(chatObject));
        };

export const startNewThread =
    (data: {
        display_name: string | undefined,
        uuid: string,
        thread_message: IChatMessage | undefined,
        unread_count?: number,
        messages?: IChatMessage[]
    }): AppThunk => (dispatch: AppDispatch) => {

        const getThreadSource = (threadMessage: IChatMessage | undefined): IThreadSource | undefined =>
            (threadMessage?.to && threadMessage.from && threadMessage.to_type) ? {
                source_uuid: threadMessage.to_type === 'user' ? threadMessage.from : threadMessage.to,
                source_type: threadMessage.to_type
            } : undefined

        const latestMessage = data.messages?.reduce((prev, current) => (prev.time < current.time) ? prev : current)

        const chatObject: IThread = {
            type: 'thread',
            user_deleted: false,
            display_name: data.display_name || t("adjective.chat_unknown"),
            unread_count: data.unread_count || 0,
            new_messages_count: data.unread_count || 0,
            thread_message: data.thread_message,
            source: getThreadSource(data.thread_message),
            uuid: data.uuid,
            active_chunk: 'initial',
            latestMessage: {
                id: latestMessage?.uuid || '',
                time: latestMessage?.time || moment().format()
            }
        };

        const initialMessage = (data.messages && data.messages?.length > 0) ?
            messagesAdapter.setMany(messagesAdapter.getInitialState(), data.messages) :
            messagesAdapter.setOne(messagesAdapter.getInitialState(), {
                uuid: '',
                from: 'hide',
                from_type: 'user',
                to: '',
                to_type: 'thread',
                content: t("phrases.this_is_start_thread"),
                time: moment().format(),
                updated_at: moment().format()
            })

        dispatch(addOneChatToMessage({
            chat_uuid: data.uuid,
            value: {
                initialChunk: initialMessage,
                searchChunk: messagesAdapter.getInitialState(),
            }
        }))

        dispatch(chatSlice.actions.addOneChat(chatObject));
    };

// export const updateParentOfThread =
//     ({message_uuid, is_delete}: { message_uuid: string, is_delete?: boolean }): AppThunk =>
//         (dispatch: AppDispatch, getState) => {
//             const state = getState();
//
//             const CURRENT_USER = selectCurrentUserId(state);
//             const parentChat = selectChatByMessageId(state, message_uuid);
//             const message = selectMessageById(state, message_uuid)
//
//             // if (!parentChat || !message) return;
//             //
//             // dispatch(chatSlice.actions.updateOneMessage({
//             //     chat_uuid: parentChat.uuid,
//             //     message_uuid,
//             //     changes: {
//             //         thread: message.thread ?
//             //             {...message.thread, count: message.thread.count + (is_delete ? -1 : 1)} :
//             //             {count: 1, members: [CURRENT_USER]}
//             //     }
//             // }))
//         }

export const addOneChat =
    (data: IChat): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.addOneChat(data))

export const updateOneChat =
    (data: { chat_uuid: string, changes: Partial<IChat> }): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.updateOneChat(data))

export const deleteOneChat =
    (uuid: string): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.deleteOneChat(uuid))

export const addManyChats =
    (data: IChat[]): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.addManyChats(data))

export const focusOnMessage =
    ({chat_uuid, message}: { chat_uuid: string; message: IChatMessage }): AppThunk =>
        async (dispatch: AppDispatch, getState) => {

            const messageChunk = selectMessageChunk(getState(), chat_uuid, message.uuid);
            const chat = selectChatById(getState(), chat_uuid);
            const userUuid = selectCurrentUserId(getState());

            if (messageChunk) {
                dispatch(setActiveChat(chat_uuid))
                dispatch(setActiveChunk({chat_uuid, active_chunk: messageChunk}))
                dispatch(setScrollToMessageUuid(message?.uuid))
            } else {
                dispatch(messageSlice.actions.addOneMessageToChunk({chat_uuid, message, chunk: 'search'}))
                dispatch(setActiveChunk({chat_uuid, active_chunk: 'search'}))
                setTimeout(() => {
                    dispatch(setScrollToMessageUuid(message?.uuid))
                }, 250)
            }

            if (chat?.unread_count) {
                services.postAllMessagesRead({
                    voip_user_uuid: userUuid,
                    type: chat.type,
                    chat_uuid: chat.uuid
                }).then().catch()
            }
        }

export const focusOnThreadMessage =
    ({chat, parentChat, message}
         : { chat: IChat, parentChat: IChat; message: IChatMessage }
    ): AppThunk =>
        async (dispatch: AppDispatch, getState) => {
            const userUuid = selectCurrentUserId(getState());
            let queryMessage: IChatMessage | undefined = selectGlobalMessageById(getState(), chat.uuid);

            if (!queryMessage) {
                const res = await api.getChatMessageByIdAPI({
                    voip_user_uuid: selectCurrentUserId(getState()),
                    message_uuid: chat.uuid
                })

                if (res) {
                    queryMessage = res.data.result
                }
            }

            if (!queryMessage) {
                console.error('getting message failed')
                return;
            }

            const queryMessageChunk = selectMessageChunk(getState(), parentChat.uuid, queryMessage.uuid);

            dispatch(setActiveChunk({chat_uuid: parentChat.uuid, active_chunk: queryMessageChunk || 'search'}))
            dispatch(setScrollToMessageUuid(queryMessage.uuid))

            if (!queryMessageChunk) {
                dispatch(messageSlice.actions.addOneMessageToChunk({
                    chat_uuid: parentChat.uuid,
                    message: queryMessage,
                    chunk: 'search'
                }))
            }

            const messageChunk = selectMessageChunk(getState(), queryMessage.uuid, message.uuid);

            dispatch(setActiveChunk({chat_uuid: queryMessage.uuid, active_chunk: messageChunk || 'search'}))
            dispatch(setScrollToThreadMessageId(message?.uuid))

            if (!messageChunk) {
                dispatch(messageSlice.actions.addOneMessageToChunk({
                    chat_uuid: queryMessage.uuid,
                    message,
                    chunk: 'search'
                }))
            }

            if (parentChat?.unread_count) {
                services.postAllMessagesRead({
                    voip_user_uuid: userUuid,
                    type: parentChat.type,
                    chat_uuid: parentChat.uuid
                }).then().catch()
            }

            if (chat?.unread_count) {
                services.postAllMessagesRead({
                    voip_user_uuid: userUuid,
                    type: chat.type,
                    chat_uuid: chat.uuid
                }).then().catch()
            }
        }

export const removeChannelUser =
    ({chat_uuid, member_uuid}: { chat_uuid: string, member_uuid: string }): AppThunk =>
        (dispatch: AppDispatch, getState) => {
            const chat = selectChatById(getState(), chat_uuid);

            if (!chat?.channel) return;

            dispatch(chatSlice.actions.updateOneChat({
                chat_uuid,
                changes: {
                    channel: {
                        ...(chat.channel || []),
                        members: chat.channel.members?.filter(member => member.member !== member_uuid) || []
                    }
                }
            }))
        }

export const addFailedMessageToQueue =
    ({chat_uuid, message}: { chat_uuid: string | undefined, message: IChatMessage }): AppThunk =>
        async (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(chatSlice.actions.addFailedMessageToQueue({chat_uuid, message}))
        }

export const clearFailedMessageFromQueue =
    ({chat_uuid, message_uuid}: { chat_uuid: string | undefined, message_uuid: string }): AppThunk =>
        async (dispatch: AppDispatch) => {
            if (!chat_uuid) return;

            dispatch(chatSlice.actions.clearFailedMessageFromQueue({chat_uuid, message_uuid}));
        }

export const setActiveChat =
    (data: string): AppThunk =>
        async (dispatch: AppDispatch) => {
            dispatch(chatSlice.actions.setActiveChat(data));

            dispatch(userSlice.actions.updateChatSettings({
                setting: 'lastOpenChatId',
                value: data
            }));

            dispatch(updateActivePage('chat'))
        };

export const setTenantChat =
    (tenantUser: InterTenantUser): AppThunk =>
        async (dispatch: AppDispatch, getState: () => RootState) => {
            const state = getState();

            const existingChat = selectChatById(state, tenantUser.user_uuid);
            if (existingChat) {
                dispatch(chatSlice.actions.setActiveChat(existingChat.uuid));

                dispatch(userSlice.actions.updateChatSettings({
                    setting: 'lastOpenChatId',
                    value: existingChat.uuid
                }));

                dispatch(updateActivePage('chat'));
                return;
            }

            const chatObject: IChat = {
                display_name: tenantUser.user_display_name,
                type: 'user' as ChatType,
                uuid: tenantUser.user_uuid,
                user_deleted: false,
                unread_count: 0,
                new_messages_count: 0,
                active_chunk: 'initial',
                latestMessage: {
                    id: '',
                    time: moment().format(),
                },
                needs_friend_req: true
            };

            const initialMessage = messagesAdapter.setOne(messagesAdapter.getInitialState(), {
                from: 'hide',
                content: t("phrases.this_is_start_chat_%name", {
                    name: tenantUser.user_display_name
                }),
                time: moment().format(),
                updated_at: moment().format(),
                uuid: ''
            })


            dispatch(addOneChatToMessage({
                chat_uuid: tenantUser.user_uuid,
                value: {
                    initialChunk: initialMessage,
                    searchChunk: messagesAdapter.getInitialState(),
                }
            }))

            dispatch(chatSlice.actions.addOneChat(chatObject));

            dispatch(chatSlice.actions.setActiveChat(tenantUser.user_uuid));

            dispatch(userSlice.actions.updateChatSettings({
                setting: 'lastOpenChatId',
                value: tenantUser.user_uuid
            }));

            dispatch(updateActivePage('chat'));
        };


export const setActiveChunk =
    ({chat_uuid, active_chunk}: { chat_uuid: string, active_chunk: ChunkType }): AppThunk =>
        async (dispatch: AppDispatch) => {
            dispatch(chatSlice.actions.updateOneChat({
                chat_uuid,
                changes: {
                    active_chunk
                }
            }))
        };

export const clearActiveChat =
    (): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.clearActiveChat());


export const setSearchResult =
    (payload: { messages: IChatMessage[], chat_key: string }): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setSearchResult(payload));

// TODO -> WEBSOCKET RESPONSE
export const updateChatTypingStatus =
    (data): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.updateChatTypingStatus(data));

export const clearMessageTypingStatus =
    (uuid: string): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.clearMessageTypingStatus(uuid));

export const addExternalReply =
    (data: IChatMessage): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.addExternalReply(data));

export const setChannelCreation =
    (data: boolean): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setChannelCreation(data));

export const resetUsersForChannelSelection =
    (): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.resetUsersForChannelSelection());

export const setManyUsersForChannelSelection =
    (data: IUserForChannel[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setManyUsersForChannelSelection(data));

export const setSingleUserForChannelSelection =
    (data: IUserForChannel): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setSingleUserForChannelSelection(data));

export const setScrollToMessageUuid =
    (uuid: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setScrollToMessageUuid(uuid));

export const setScrollToThreadMessageId =
    (uuid: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setScrollToThreadMessageId(uuid));

export const clearScrollToMessageUuid =
    (): AppThunk => (dispatch: AppDispatch) =>
        dispatch(chatSlice.actions.clearScrollToMessageUuid());

export const clearScrollToThreadMessageId =
    (): AppThunk => (dispatch: AppDispatch) =>
        dispatch(chatSlice.actions.clearScrollToThreadMessageId());

export const addUserUuidToChatState =
    (uuid: string): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.addUserUuid(uuid));

export const updateActiveChatInputText =
    (data: { chat_uuid: string; text: string }): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.updateActiveChatInputText(data));

export const clearActiveChatInputText =
    (data: { chat_uuid: string; }): AppThunk =>
        async (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.clearActiveChatInputText(data));

export const resetChatMessagesSearch =
    (chatKey: string): AppThunk => (dispatch: AppDispatch) =>
        dispatch(chatSlice.actions.resetChatMessagesSearch(chatKey));

export const setFilePreview =
    (focusAttachment?: FocusMessageAttachment): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(chatSlice.actions.setFilePreview(focusAttachment))

export const setActiveMessageReply =
    (data: { chat_uuid: string, data: { message: IChatMessage, sender: string } }): AppThunk =>
        async (dispatch: AppDispatch) => {
            dispatch(chatSlice.actions.setActiveMessageReply(data))
        };

export const clearActiveMessageReply =
    (data: string): AppThunk =>
        async (dispatch: AppDispatch) => {
            dispatch(chatSlice.actions.clearActiveMessageReply(data))
        };


export const setLastUpdatedChatId =
    (chatId: string): AppThunk =>
        async (dispatch: AppDispatch, getState) => {

            const current = selectLastUpdatedChatId(getState())

            dispatch(chatSlice.actions.setLastUpdatedChatId(chatId === current ? '' : chatId))
        };

export const setLatestMessageById =
    (chatId: string): AppThunk =>
        async (dispatch: AppDispatch, getState) => {
            const newFirstMessageInitial = selectFirstMessageInChunk(getState(), chatId, 'initial');

            if (newFirstMessageInitial) {
                dispatch(chatMessageAdded({
                    chat_uuid: chatId,
                    message_uuid: newFirstMessageInitial.uuid,
                    message_time: newFirstMessageInitial.time
                }))
                return
            }

            const newFirstMessageSearch = selectFirstMessageInChunk(getState(), chatId, 'search');

            if (newFirstMessageSearch) {
                dispatch(chatMessageAdded({
                    chat_uuid: chatId,
                    message_uuid: newFirstMessageSearch.uuid,
                    message_time: newFirstMessageSearch.time
                }))
                return
            }

            dispatch(chatMessageAdded({
                chat_uuid: chatId,
            }))

        };

export const chatMessageAdded =
    ({
         chat_uuid,
         message_uuid,
         message_time
     }: { chat_uuid: string; message_uuid?: string; message_time?: string }): AppThunk =>
        async (dispatch: AppDispatch) => {

            dispatch(chatSlice.actions.updateOneChat({
                chat_uuid, changes: {
                    latestMessage: message_uuid && message_time ? {
                        id: message_uuid,
                        time: message_time
                    } : {
                        id: '',
                        time: '0'
                    }
                }
            }))

            dispatch(setLastUpdatedChatId(chat_uuid))
        };