import React, {useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import {ChatSettingsMenu} from "./ChatSettingsMenu";
import {ChatSettingsAddUsers} from "./ChatSettingsAddUsers";
import {useChatContext} from "../../../context/ChatContext/context";
import {useOnClickOutside} from "../../../helpers";
import styles from './styles.module.scss';
import {isElectron} from "../../home";

export type ChatSettingsSidebarMenus = 'menu' | 'addMembers' | 'mediaAndFiles';

interface Props {
}

export const ChatSettings: React.FC<Props> = () => {
    const {sideBarVisible, useChatAction} = useChatContext();

    const containerRef = useRef<HTMLDivElement>(null);

    const [sideBarTab, setSideBarTab] = useState<ChatSettingsSidebarMenus>('menu');

    useOnClickOutside(containerRef, (e) => {
        if (!(e.target as HTMLElement).parentNode?.parentElement?.getAttribute("data-is-sidebar-toggle")?.includes("true")) {
            e.stopPropagation();
            useChatAction({
                type: 'set_sidebar_visible',
                payload: false,
            })
        }
    })

    return (
        <AnimatePresence>
            {sideBarVisible ? (
                <motion.div
                    key='menu_container'
                    className={styles.chat_settings_container}
                    initial={isElectron ? null : {
                        x: 400,
                    }}
                    animate={isElectron ? {
                        opacity: 1,
                    } : {
                        x: 0,
                        opacity: 1
                    }}
                    exit={isElectron ? {
                        opacity: 0,
                    } : {
                        x: 400,
                        opacity: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'ease-out',
                    }}
                >
                    {sideBarTab === 'menu' ? (
                        <ChatSettingsMenu
                            key='mein_menu'
                            setSideBarTab={setSideBarTab}
                        />
                    ) : null}
                    {sideBarTab === 'addMembers' ? (
                        <ChatSettingsAddUsers
                            key='add_members'
                            setSideBarTab={setSideBarTab}
                        />
                    ) : null}
                </motion.div>
            ) : null}
        </AnimatePresence>
    )
}

export default ChatSettings;
