import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import React, {forwardRef} from "react";

type AnimationType = 'fade' | 'slideFromRight' | 'slideFromTop' | 'slideFromLeft' | 'slideFromBottom'

interface AnimatedComponentProps extends React.HTMLProps<HTMLDivElement> {
    inAnimation?: AnimationType[] | false,
    outAnimation?: AnimationType[] | false,
    transition?: {
        duration?: number,
        delay?: number,
        ease?: string,
        type?: 'default' | 'tween' | 'spring' | undefined,
        staggerChildren?: number,
        staggerDirection?: 1 | -1,
    },
    visible: boolean,
    children: any,
}

export const AnimatedDiv = forwardRef(({
                                           inAnimation = ['fade'],
                                           outAnimation,
                                           transition = {
                                               duration: 0.15,
                                               delay: 0,
                                               ease: 'ease-in-out',
                                               type: undefined,
                                               staggerChildren: 0,
                                               staggerDirection: 1
                                           },
                                           visible,
                                           ...props
                                       }: AnimatedComponentProps, ref) => {
    const transitionProperties = {
        duration: transition.duration,
        delay: transition.delay,
        type: transition.type,
        ease: transition.ease,
        staggerChildren: transition.staggerChildren,
        staggerDirection: transition.staggerDirection
    }

    const animation: any = {
        initial: {
            pointerEvents: 'none',
            zIndex: '1'
        },
        animate: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            y: 0,
            x: 0,
            opacity: 1,
            pointerEvents: 'all'
        },
        exit: {
            pointerEvents: 'none'
        }
    }

    if (inAnimation) {
        if (inAnimation.includes('fade')) {
            animation.initial.opacity = 0;
        }
        if (inAnimation.includes('slideFromRight')) {
            animation.initial.marginRight = '-100%';
            animation.initial.x = 300;
        }
        if (inAnimation.includes('slideFromLeft')) {
            animation.initial.marginLeft = '-100%';
            animation.initial.x = -300;
        }
        if (inAnimation.includes('slideFromBottom')) {
            animation.initial.marginBottom = '-100%';
            animation.initial.y = 100;
        }
        if (inAnimation.includes('slideFromTop')) {
            animation.initial.marginTop = '-100%';
            animation.initial.y = -300;
        }
    }

    if (outAnimation === undefined) {
        animation.exit = animation.initial
    } else if (outAnimation) {
        if (outAnimation.includes('fade')) {
            animation.initial.opacity = 0;
        }
        if (outAnimation.includes('slideFromRight')) {
            animation.initial.marginRight = '-100%';
            animation.initial.x = 300;
        }
        if (outAnimation.includes('slideFromLeft')) {
            animation.initial.marginLeft = '-100%';
            animation.initial.x = -300;
        }
        if (outAnimation.includes('slideFromBottom')) {
            animation.initial.marginBottom = '-100%';
            animation.initial.y = 100;
        }
        if (outAnimation.includes('slideFromTop')) {
            animation.initial.marginTop = '-100%';
            animation.initial.y = -300;
        }
    }

    return (
        <AnimatePresence>
            {visible ? (
                <motion.div
                    ref={ref}
                    data-motion-div='true'
                    style={{
                        isolation: 'isolate',
                        zIndex: 1,
                    }}
                    transition={transitionProperties}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...props} {...animation}
                >
                    {props.children}
                </motion.div>
            ) : null}
        </AnimatePresence>
    )
})
