import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import moment from "moment";
import {ChunkType, IChatMessage, MessageSliceChat, MessageSliceState} from "../../../types";

export const messagesAdapter = createEntityAdapter<IChatMessage>({
    selectId: (message) => message.uuid,
    sortComparer: (a, b) => moment(b.time).unix() - moment(a.time).unix()
})

const initialState: MessageSliceState = {
    chat: {}
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        addOneChatToMessage: (state, {payload}: PayloadAction<{ chat_uuid: string, value: MessageSliceChat}>) => ({
            ...state,
            chat: {
                ...state.chat,
                [payload.chat_uuid]: payload.value
            }
        }),
        addManyChatToMessage: (state, {payload}: PayloadAction<Record<string, MessageSliceChat>>) => ({
            ...state,
            chat: {
                ...state.chat,
                ...payload
            }
        }),

        addManyMessage: (state, {payload}: PayloadAction<{
            chat_uuid: string,
            messages: IChatMessage[],
            chunk: ChunkType
        }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            const activeChunk = payload.chunk === 'search' ? activeChat.searchChunk : activeChat.initialChunk

            messagesAdapter.setMany(activeChunk, payload.messages)
        },
        addOneMessage: (state, {payload}: PayloadAction<{ chat_uuid: string; message: IChatMessage }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            messagesAdapter.setOne(activeChat.initialChunk, payload.message)
        },
        mergeChunks: (state, {payload}: PayloadAction<{ chat_uuid: string; messages: IChatMessage[] }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            messagesAdapter.upsertMany(activeChat.initialChunk, payload.messages)
            messagesAdapter.removeAll(activeChat.searchChunk)
        },
        addOneMessageToChunk: (state, {payload}: PayloadAction<{ chat_uuid: string; message: IChatMessage; chunk: ChunkType }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            const activeChunk = payload.chunk === 'search' ? activeChat.searchChunk : activeChat.initialChunk

            if (!activeChunk) return;

            messagesAdapter.setOne(activeChunk, payload.message)
        },
        updateOneMessage: (state, {payload}: PayloadAction<{
            chat_uuid: string,
            message_uuid: string,
            changes: Partial<IChatMessage>
        }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            const activeChunk = activeChat.initialChunk.ids.includes(payload.message_uuid) ? activeChat.initialChunk : activeChat.searchChunk;

            if (!activeChunk) return;

            messagesAdapter.updateOne(activeChunk, {
                id: payload.message_uuid,
                changes: {...payload.changes}
            })
        },
        updateManyMessages: (state, {payload}: PayloadAction<{
            chat_uuid: string,
            chunk: ChunkType,
            changes: {
                id: string,
                changes: Partial<IChatMessage>
            }[]
        }>) => {

            const activeChat = state.chat[payload.chat_uuid];
            const activeChunk = payload.chunk === 'initial' ? activeChat.initialChunk : activeChat.searchChunk;

            if (!activeChunk) return;

            messagesAdapter.updateMany(activeChunk, payload.changes)
        },
        deleteOneMessage: (state, {payload}: PayloadAction<{ message_uuid: string, chat_uuid: string }>) => {
            const activeChat = state.chat[payload.chat_uuid];

            if (!activeChat) return;

            const activeChunk = activeChat.initialChunk.ids.includes(payload.message_uuid) ? activeChat.initialChunk : activeChat.searchChunk;

            if (!activeChunk) return;

            messagesAdapter.removeOne(activeChunk, payload.message_uuid)
        },
    }
})

export const message = messageSlice.reducer;
