import styles from "./Attachments.module.scss";
import {AnimatedDiv} from "../../../../animation";
import Attachment from "../../Attachment";
import {useChatContext} from "../../../../context/ChatContext/context";

interface Props {
}

const Attachments: React.FC<Props> = () => {
    const {files, useChatAction} = useChatContext()

    const handleFileDelete = (fileIndex: number) => {
        useChatAction({
            type: "set_files",
            payload: files?.filter((_, index) => fileIndex !== index)
        })
    };

    return (
        <div className={styles.attachments_grand_container}>
            <AnimatedDiv visible={files.length > 0} className={styles.attachments_container}
                         inAnimation={['fade', 'slideFromLeft', 'slideFromBottom']} transition={{duration: 1}}>
                {files.map((file, i) =>
                    <Attachment file={file} handleFileDelete={() => handleFileDelete(i)} mini key={i}/>
                )}
            </AnimatedDiv>
        </div>

    )
}

export default Attachments;
