import React, {FunctionComponent} from 'react';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import LoadingSpinner from "../../loading/LoadingSpinner";
import {Slider} from "../../forms";
import {
    logout,
    resetUser,
    selectCurrentUser,
    selectCurrentUserId,
    selectCurrentUserQueueAvailability,
    selectDepartmentByUserUuid,
    selectPhoneSettingByKey,
    selectSelectableUsers,
    updatePhoneSettings
} from "../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {usePolyglot} from "../../../context/Polyglot";
import {usePutAuxCodeMutation, usePutAvailableInCallQueuesMutation} from "../../../redux/services/sipApi";
import styles from '../styles.module.scss';
import DepartmentTag from "../../buttons/DepartmentTag";
import {StyledButton} from "../../StyledComponents";
import {isPreview, useDnd} from "../../../helpers";
import AuxCodeController from "../../../pages/home/children/AuxCodeController/AuxCodeController";
import SettingsInfoIcon from "../../../pages/settings/SettingsPages/SettingsComponents/SettingsInfoIcon";

interface Props {
}

const NavigationQuickMenu: FunctionComponent<Props> = () => {
    const queueAvailability = useTypedSelector(selectCurrentUserQueueAvailability);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const user = useTypedSelector(selectCurrentUser);

    const departments = useTypedSelector(state => selectDepartmentByUserUuid(state, userUuid));
    const canSwitchUser = useTypedSelector(selectSelectableUsers)?.length > 1;

    const allowInternal = useTypedSelector(state => selectPhoneSettingByKey(state, 'allowInternalCalls'));

    const {
        localDnd,
        globalDnd,
        dndDisabled,
        globalDndLoading,
        updateDnd
    } = useDnd();

    const dispatch = useTypedDispatch();

    const [putAvailableInCallQueues, {isLoading: availableInCallQueuesLoading}] = usePutAvailableInCallQueuesMutation();
    const [putAuxCode] = usePutAuxCodeMutation();

    const {t} = usePolyglot();

    return (
        <div className={styles.quick_settings}>
            <div className={styles.user_details}>
                <p>{user.nickname}&nbsp;{`(${user.extension})`}</p>
                {departments ? (
                    <div className={styles.departments}>
                        {departments.map(dept => (
                            <DepartmentTag
                                key={dept.uuid}
                                department={dept}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
            {((user.allow_aux_codes || user.app_allow_aux) || isPreview) ? (
                <div className={styles.item}>
                    <label
                        htmlFor='aux_code'
                    >
                        Call Queue Status
                    </label>
                    <AuxCodeController/>
                </div>
            ) : null}
            {(!(user.allow_aux_codes || user.app_allow_aux) || isPreview) ? (
                <div className={styles.item}>
                    <label
                        htmlFor='CallQueueAvailability'
                    >
                        {t("phrases.available_in_call_queues")}
                    </label>
                    <div className={styles.loading}>
                        {availableInCallQueuesLoading ? (
                            <LoadingSpinner color="mamba"/>
                        ) : null}
                        <Slider
                            name='CallQueueAvailability'
                            disabled={availableInCallQueuesLoading || globalDnd}
                            checked={queueAvailability}
                            onChange={() => {
                                putAvailableInCallQueues({
                                    voip_user_uuid: userUuid,
                                    available: !queueAvailability
                                })
                                if (!queueAvailability) {
                                    putAuxCode({
                                        voip_user_uuid: user.uuid,
                                        auxCode: {
                                            code: 'Available',
                                            set_by: user.uuid,
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            ) : null}
            {!dndDisabled ? (
                <>
                    <div className={styles.item}>
                        <label
                            htmlFor='DoNotDisturbGlobal'
                        >
                            Global Do Not Disturb
                            <SettingsInfoIcon
                                infoText="Applies Do Not Disturb settings to your account.
                                This will prevent calls with all of your apps and devices
                                (Yealink handsets only) and be displayed to other users on your presence. "
                            />
                        </label>
                        <div className={styles.loading}>
                            {globalDndLoading ? (
                                <LoadingSpinner color="mamba"/>
                            ) : null}
                            <Slider
                                name='DoNotDisturbGlobal'
                                checked={globalDnd}
                                onChange={() => updateDnd(!globalDnd, true)}
                                disabled={globalDndLoading}
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label
                            htmlFor='DoNotDisturb'
                        >
                            App Do Not Disturb
                            <SettingsInfoIcon
                                infoText="Applies Do Not Disturb settings to this application only.
                                Other apps and devices on your account will continue
                                to receive calls and your presence will not be changed."
                            />
                        </label>
                        <div className={styles.loading}>
                            {globalDndLoading ? (
                                <LoadingSpinner color="mamba"/>
                            ) : null}
                            <Slider
                                name='DoNotDisturb'
                                checked={localDnd}
                                onChange={() => updateDnd(!localDnd, false)}
                                disabled={globalDndLoading}
                            />
                        </div>
                    </div>
                    <AnimatePresence>
                        {localDnd ? (
                            <motion.div
                                className={styles.item}
                                initial={{
                                    marginTop: -30,
                                    scaleY: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    marginTop: 0,
                                    scaleY: 1,
                                    opacity: 1,
                                }}
                                exit={{
                                    marginTop: -30,
                                    scaleY: 0,
                                    opacity: -0.5,
                                }}
                            >
                                <label
                                    htmlFor='AllowInternal'
                                >
                                    {t("phrases.allow_internal_calls")}
                                </label>
                                <div>
                                    <Slider
                                        name='AllowInternal'
                                        checked={allowInternal}
                                        onChange={() =>
                                            dispatch(
                                                updatePhoneSettings({
                                                    setting: 'allowInternalCalls',
                                                    value: !allowInternal
                                                })
                                            )
                                        }
                                    />
                                </div>
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </>
            ) : null}
            {canSwitchUser ? (
                <div className={styles.item}>
                    <p>
                        {t("actions.switch_user")}
                    </p>
                    <StyledButton
                        buttonStyle='text'
                        context='danger'
                        onClick={() => dispatch(resetUser())}
                    >
                        <p>
                            {t("actions.switch_user")}
                        </p>
                    </StyledButton>
                </div>
            ) : null}
            <div className={styles.item}>
                <p>
                    {t("actions.logout")}
                </p>
                <StyledButton
                    buttonStyle='text'
                    context='danger'
                    onClick={() => dispatch(logout())}
                >
                    <p>
                        {t("actions.logout")}
                    </p>
                </StyledButton>
            </div>
        </div>
    );
};

export default NavigationQuickMenu;
