import React, {useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import styles from '../styles.module.scss';
import {StyledButton} from "../../../components/StyledComponents";
import Icon from "../../../components/Icon";
import StyledCheckbox from "../../../components/StyledComponents/StyledCheckbox";

interface Props {
}

const CheckboxItem = ({name, label}: { name: string, label: string }) => {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                         field: {onChange, value},
                     }) => (
                <StyledCheckbox
                    inputProps={{
                        value,
                        checked: value,
                        onChange: () => onChange(!value)
                    }}
                    label={label}
                />

            )
            }
            rules={{required: true}}
        />
    )
}

const MeetingConfigAdvanced: React.FC<Props> = () => {
    const [showAdvanced, setShowAdvanced] = useState(false)

    const advancedOptions: { label: string, value: string } [] = [
        {
            label: 'Is room open',
            value: 'is_open'
        }, {
            label: 'Show to all',
            value: 'show_to_all'
        }, {
            label: 'Force waiting room',
            value: 'force_waiting_room'
        }, {
            label: 'Allow admins to mute others',
            value: 'allow_muting'
        }, {
            label: 'Mute participants on entry',
            value: 'mute_on_entry'
        }
    ]

    return (
        <div className={styles.advanced_settings}>
            <StyledButton
                buttonStyle='text'
                size='medium'
                onClick={() => {
                    setShowAdvanced(!showAdvanced)
                }}
            >
                <p>Advanced Settings</p>
                <Icon name={showAdvanced ? 'arrowDown' : 'arrowRight'}/>
            </StyledButton>
            {showAdvanced ? (
                <ul>
                    {advancedOptions.map((opt) => (
                        <CheckboxItem
                            key={opt.value}
                            name={opt.value}
                            label={opt.label}
                        />
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export default MeetingConfigAdvanced;
