import React, {FunctionComponent, useRef} from 'react';
import {useButton} from "react-aria";
import {AriaButtonProps} from "./types";

const AriaButton: FunctionComponent<AriaButtonProps> = ({
                                                            onClick,
                                                            children,
                                                            disabled,
                                                            className,
                                                            dataTags,
                                                            title,
                                                            tabIndex,
                                                            type = 'button',
                                                            onKeyDown,
                                                        }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);


    const {buttonProps} = useButton({
        isDisabled: disabled,
        onPress: (e) => onClick ? onClick(e) : null,
        onKeyDown: (e) => onKeyDown ? onKeyDown(e) : null,
        type,
        excludeFromTabOrder: true,
    }, buttonRef);

    return (
        <button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps} {...dataTags}
            className={className}
            title={title}
            tabIndex={tabIndex}
            aria-hidden={tabIndex === -1}
        >
            {children}
        </button>
    );
};


export default AriaButton;
