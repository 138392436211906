import React, {DetailedHTMLProps, InputHTMLAttributes} from 'react';
import styles from './styles.module.scss';

interface Props {
    inputProps: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    label: string;
}

const StyledCheckbox: React.FC<Props> = ({inputProps, label}) => (
        <label className={styles.styled_checkbox}>
            <input
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...inputProps}
                type='checkbox'
            />
            <p>
                {label}
            </p>
        </label>
    );

export default StyledCheckbox;
