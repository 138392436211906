import React from 'react';
import styles from './styles.module.scss';
import {StyledButton} from "./StyledButton";
import Icon from "../Icon";
import {usePolyglot} from "../../context/Polyglot";

interface Props {
    page: number;
    setPage: (val: number) => void;
    totalPages: number;
}

const StyledPaginator: React.FC<Props> = ({page, setPage, totalPages}) => {
    const {t} = usePolyglot();

    return (
        <div className={styles.styled_paginator}>
            <StyledButton
                buttonStyle='tertiary'
                iconOnly
                onClick={() => setPage(page - 1)}
                disabled={page <= 0}
                title={t("actions.previous_page")}
            >
                <Icon name='arrowLeft'/>
            </StyledButton>
            <p>
                {t("terms.page_%cur_of_%total", {cur: page + 1, total: totalPages})}
            </p>
            <StyledButton
                buttonStyle='tertiary'
                iconOnly
                onClick={() => setPage(page + 1)}
                disabled={page >= totalPages - 1}
                title={t("actions.next_page")}
            >
                <Icon name='arrowRight'/>
            </StyledButton>
        </div>
    );
}

export default StyledPaginator;
