import React, {FunctionComponent, lazy, Suspense} from 'react';
import {useTypedDispatch, useTypedSelector} from "../redux/hooks";
import {selectEditPhonebookContact, setEditPhonebookContact} from "../redux/slices";
import ModalFormContainer from "../components/forms/modalFormContainer/ModalFormContainer";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import ContactProvider from "../context/ContactContext/context";

const PhonebookContactEditor = lazy(() => import('../components/forms/PhonebookContactEditor/PhonebookContactEditor'))

interface Props {
}

const PhonebookContactEditorModal: FunctionComponent<Props> = () => {

    const contactToEdit = useTypedSelector(selectEditPhonebookContact);
    const dispatch = useTypedDispatch();

    return (
        <ModalFormContainer
            showModal={!!contactToEdit}
            setShowModal={() => dispatch(setEditPhonebookContact())}
        >
            <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                {contactToEdit ? (
                    <ContactProvider value={{contact: contactToEdit}}>
                        <PhonebookContactEditor/>
                    </ContactProvider>
                ) : null}
            </Suspense>
        </ModalFormContainer>
    );
};

export default PhonebookContactEditorModal;
