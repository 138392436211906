import {IChatMessage} from "../../types";

export interface ChatInputContextState {
    filesToUpload: File[];
    expandMessage?: IChatMessage;
}

export const initialState: ChatInputContextState = {
    filesToUpload: [],
    expandMessage: undefined,
}

export type ChatInputReducerAction =
    {
        type: 'add_file_to_upload',
        payload?: FileList | File[]
    } |
    {
        type: 'reset_files'
    } |
    {
        type: 'set_message_details',
        payload?: IChatMessage
    } |
    {
        type: 'set_files',
        payload?: File[]
    }

const handleFileFiltering = (file: File) => file.size < 64 * 1000 * 1000;

export const chatReducer = (state: ChatInputContextState, action: ChatInputReducerAction): ChatInputContextState => {

    switch (action.type) {
        case "add_file_to_upload":

            if (action.payload) {
                const filteredFiles = Object.values(action.payload).filter(handleFileFiltering);

                return {
                    ...state,
                    filesToUpload: [...state.filesToUpload, ...filteredFiles],
                }
            }
            return state
        case "reset_files":
            return {
                ...state,
                filesToUpload: [],
            }
        case "set_message_details":
            return {
                ...state,
                expandMessage: action.payload
            }
        case "set_files":
            return {
                ...state,
                filesToUpload: action.payload || [],
            }
        default:
            return state
    }
}
