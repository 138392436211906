import {ChatTagType} from "../../TagPopup/hooks";
import DepartmentTag from "../../../../../components/buttons/DepartmentTag";

interface Props {
    children: any;
    element: ChatTagType;
}

const TagUser: React.FC<Props> = ({children, element}) => element.tagType === 'department' ? (
    <DepartmentTag
        isEditorLeaf={{
            children,
            title: element.text,
            uuid: element.data.uuid
        }}
    />
) : (
    <mark
        title={element.text}
    >
        {children}
    </mark>
);


export default TagUser;
