import Lottie from "react-lottie-player";
import React, {useState} from "react";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import styles from "../pages/auth/Auth.module.scss";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import {usePolyglot} from "../context/Polyglot";
import { useAppContext } from '../context/AppContext/AppContext';

interface AppLoaderProps {
    userIsLoading: boolean;
    isLoaded: boolean;
}

export const AppLoader: React.FC<AppLoaderProps> = ({userIsLoading, isLoaded}) => {

    const {isYay, brandedInitialLoader} = useAppContext();
    const {t} = usePolyglot();

    const [animationComplete, setAnimationComplete] = useState<boolean>(false);

    return (
        <AnimatePresence>
            {(!isLoaded || userIsLoading || (brandedInitialLoader && !animationComplete)) ? (
                <motion.div
                    key='auth_motion'
                    className={styles.authLoading}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.25
                    }}
                >
                    <div>
                        {brandedInitialLoader ? (
                            <Lottie
                                animationData={brandedInitialLoader}
                                play
                                style={{width: 360, height: 360}}
                                loop={false}
                                onComplete={() => setAnimationComplete(true)}
                            />
                        ) : (
                            <>
                                <p>
                                    {userIsLoading ? t("phrases.logging_in") : t("phrases.loading")}
                                </p>
                                <LoadingSpinner color={isYay ? 'iron' : 'mamba'}/>
                            </>
                        )}
                    </div>
                </motion.div>
            ) : null}
        </AnimatePresence>
    )
}