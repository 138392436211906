import React, {useMemo} from "react";
import {useChatContext} from "../../../context/ChatContext/context";
import {useTypedSelector} from "../../../redux/hooks";
import {focusNext} from "../../../helpers";
import {IChatMember} from "../../../types";
import {selectUsersDictionary} from "../../../redux/slices";


export const useChannelMembers = () => {
    const {chat} = useChatContext();

    const userDictionary = useTypedSelector(selectUsersDictionary);

    const adminUuids = chat.channel?.members.reduce((uuids: string[], member) => {
        if (
            !chat.channel?.private &&
            userDictionary[member.member]?.chat_config?.can_admin_all_public_groups
        ) {
            return [...uuids, member.member];
        }

        if (chat.channel?.created_by === member.member) {
            return [...uuids, member.member];
        }

        return uuids;
    }, []);

    const displayMembers: IChatMember[] = useMemo(() => {
        if (!chat.channel?.members || chat.channel.members.length === 0) return [];
        const queryMembers = chat.channel.members.map(m => m)

        const creator: any[] = []
        const admins: any[] = []
        const members: any[] = []

        queryMembers
            .sort((a, b) => a.display_name.localeCompare(b.display_name))
            .forEach(m => {
                switch (true) {
                    case m.member === chat.channel?.created_by:
                        creator.push(m)
                        break;
                    case adminUuids?.includes(m.member):
                        admins.push(m)
                        break;
                    default:
                        members.push(m)
                }
            })

        return creator.concat(admins).concat(members);
    }, [chat.channel?.members.length]);

    return {
        adminUuids,
        displayMembers,
    }
}

export const useChatMessagesSearchKeydown = () => {

    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement

        if (e.currentTarget === e.target) {
            if (before) return;
            focusNext({
                container: e.currentTarget,
                before
            })
            return;
        }

        const navElement = before ? target.parentElement?.previousSibling : target.parentElement?.nextSibling;

        if (navElement) {
            (navElement.firstChild as HTMLElement).focus();
        }
    }

    const ACTIONS = {
        ArrowDown: (e) => handleArrowY(e),
        ArrowUp: (e) => handleArrowY(e, true),
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
        Escape: (e) => e.currentTarget.focus(),
    }

    return (e) => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}