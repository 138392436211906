import React from 'react';
import styles from './styles.module.scss'

interface Props {
    text: string;
}

const AriaText: React.FC<Props> = ({
                                                text
                                            }) => (
    <span className={styles.aria_text}>
        {text}
    </span>

);

export default AriaText;
