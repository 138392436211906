/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import appleStore from '../../img/upsell/apple-store.svg';
import contactWhite from '../../img/upsell/contacts-white.svg';
import googlePlayBadge from '../../img/upsell/google-play-badge.svg';
import mobilePhoneTop from '../../img/upsell/mobile-phone-top.png';
import mobilePhoneBottom from '../../img/upsell/mobile-phone-bottom.png';
import mobilePhone480 from '../../img/upsell/mobile-phones-480.png';
import phone1 from '../../img/upsell/phone-1.png';
import phone2 from '../../img/upsell/phone-2.png';
import { api } from '../../api';

import logo from '../../img/logos/logo-yay-white.svg';
import {usePolyglot} from "../../context/Polyglot";

export const IsMobile: React.FC = () => {
    const [countryCode, setCountryCode] = useState('');

    const { t } = usePolyglot();

    useEffect(() => {
        api.getIpCountryCode().then(res => setCountryCode(res.toString()));

        document.body.classList.add('downloadBody');

        const navBar = document.getElementsByClassName('react-navbar')[0] as HTMLElement;
        const navBarTitles = document.getElementsByClassName('react-navbar__section-title');
        const topHeaderMobileNavBarButton = document.getElementsByClassName('small-nav-trigger');
        const innerLI = navBar.querySelectorAll('ul > li > ul > li');
        const body = document.getElementsByTagName('body')[0];

        for (let m = 0; m < innerLI.length; m++) {
            if (innerLI[m].querySelector('a')?.href === window.location.href) {
                innerLI[m].classList.add('current');
                (innerLI[m].parentNode as HTMLElement).style.display = 'block';
            }
        }

        // HANDLE NAVBAR SLIDING MOTION
        function clickOutsideNav(e) {
            if (e.target.closest('.react-navbar')) {
                e.stopPropagation();
            } else {
                (topHeaderMobileNavBarButton[0] as HTMLElement).click();
            }
        }

        function handleNavBarDisplay(e, closeNavVal) {
            e.stopPropagation();
            let initialTouchPosition = 0;
            let initialYTouchPosition = 0;
            let changeInX;
            let changeInY;
            let closeNav = closeNavVal || false;

            const touchStartFunction = function (event) {
                event.stopPropagation();
                body.style.overflowY = 'hidden';
                // html.style.overflowY = 'hidden';
                initialTouchPosition = event.touches[0].clientX;
                initialYTouchPosition = event.touches[0].clientY;
            };

            const touchMoveFunction = function (event) {
                event.stopPropagation();
                changeInX = event.touches[0].clientX - initialTouchPosition;
                changeInY = event.touches[0].clientY - initialYTouchPosition;

                if (Math.abs(changeInY) > 10) return;

                if (changeInX < 0) {
                    navBar.style.transform = `translateX(${changeInX}px)`;
                    closeNav = true;
                }
            };

            const transitionEnd = function () {
                navBar.style.transform = 'none';
                window.removeEventListener('click', clickOutsideNav);
            };

            const touchEndFunction = function () {
                if (changeInX <= -40 && closeNav) {
                    body.style.overflowY = 'unset';

                    navBar.addEventListener('transitionend', transitionEnd);
                    navBar.classList.remove('active');
                    closeNav = false;
                } else {
                    navBar.style.transform = 'none';
                    closeNav = false;
                }
            };

            if (navBar.classList.contains('active')) {
                window.removeEventListener('click', clickOutsideNav);
                navBar.removeEventListener('touchstart', touchStartFunction);
                navBar.removeEventListener('touchmove', touchMoveFunction);
                navBar.removeEventListener('touchend', touchEndFunction);
                body.style.overflowY = 'unset';
                // html.style.overflowY = 'unset';
                if (closeNav) {
                    navBar.classList.remove('active');
                }
            } else {
                navBar.removeEventListener('transitionend', transitionEnd);
                window.addEventListener('click', clickOutsideNav);
                navBar.addEventListener('touchstart', touchStartFunction, false);
                navBar.addEventListener('touchmove', touchMoveFunction, false);
                navBar.addEventListener('touchend', touchEndFunction, false);
                body.style.overflowY = 'hidden';
                // html.style.overflowY = 'hidden';
                navBar.classList.add('active');
            }
        }

        // Handle all divs with classes 'small-info-trigger' or 'small-nav-trigger'
        for (let l = 0; l < topHeaderMobileNavBarButton.length; l++) {
            topHeaderMobileNavBarButton[l].addEventListener('click', e => {
                handleNavBarDisplay(e, true);
            });
        }

        // Handle Sub menu display
        for (let k = 0; k < navBarTitles.length; k++) {
            navBarTitles[k].addEventListener('click', function () {
                // @ts-ignore
                const innerUL = this.parentNode.getElementsByTagName('UL')[0];
                const display = window.getComputedStyle(innerUL).getPropertyValue('display');
                if (display === 'none') {
                    innerUL.style.display = 'block';
                } else {
                    innerUL.style.display = 'none';
                }
            });
        }

        const sitemapMenuTitles = document.getElementsByClassName('menu-title');
        for (let i = 0; i < sitemapMenuTitles.length; i++) {
            sitemapMenuTitles[i].addEventListener('click', function () {
                // @ts-ignore
                const listMenu = this.parentNode.getElementsByTagName('section')[0];
                if (!listMenu.classList.contains('active')) {
                    listMenu.classList.add('active');
                    listMenu.style.height = 'auto';

                    const height = `${listMenu.clientHeight}px`;

                    listMenu.style.height = '0px';

                    setTimeout(() => {
                        listMenu.style.height = height;
                    }, 0);
                } else {
                    listMenu.style.height = '0px';
                    listMenu.addEventListener(
                        'transitionend',
                        () => {
                            listMenu.classList.remove('active');
                        },
                        {
                            once: true
                        }
                    );
                }
            });
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const bskt = urlParams.get('bskt');
        if (bskt === 'true') {
            window.onload = function () {
                if (window.innerWidth < 1024) {
                    if (navBar.classList.contains('active')) {
                        // @ts-ignore
                        topHeaderMobileNavBarButton[0].click();
                    }
                } else {
                    const cart = document.getElementById('floating-cart');
                    if (cart) {
                        cart.style.display = 'block';

                        setTimeout(() => {
                            cart?.style.removeProperty('display');
                        }, 3000);
                    }
                }
            };
        }
    }, []);

    const phoneMenuOptionsUK = [
        {
            href: 'https://www.yay.com/phone-number/all-numbers/',
            title: t("phrases.all_phone_numbers"),
            content: t("phrases.all_phone_numbers")
        },
        {
            href: 'https://www.yay.com/phone-number/keep-my-number/',
            title: t("phrases.transfer_number_to_voip"),
            content: t("phrases.transfer_phone_number")
        },
        {
            href: 'https://www.yay.com/phone-number/020-london/',
            title: t("phrases.buy_%area_phone_numbers", { area: "London 020" }),
            content: t("phrases.%area_phone_numbers", { area: "London 020" })
        },
        {
            href: 'https://www.yay.com/phone-number/0121-birmingham/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Birmingham 0121" }),
            content: t("phrases.%area_phone_numbers", { area: "Birmingham 0121" })
        },
        {
            href: 'https://www.yay.com/phone-number/0113-leeds/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Leeds 0113" }),
            content: t("phrases.%area_phone_numbers", { area: "Leeds 0113" })
        },
        {
            href: 'https://www.yay.com/phone-number/0141-glasgow/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Glasgow 0141" }),
            content: t("phrases.%area_phone_numbers", { area: "Glasgow 0141" })
        },
        {
            href: 'https://www.yay.com/phone-number/0131-edinburgh/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Edinburgh 0131" }),
            content: t("phrases.%area_phone_numbers", { area: "Edinburgh 0131" })
        },
        {
            href: 'https://www.yay.com/phone-number/0151-liverpool/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Liverpool 0151" }),
            content: t("phrases.%area_phone_numbers", { area: "Liverpool 0151" })
        },
        {
            href: 'https://www.yay.com/phone-number/0161-manchester/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Manchester 0161" }),
            content: t("phrases.%area_phone_numbers", { area: "Manchester 0161" })
        },
        {
            href: 'https://www.yay.com/phone-number/international/',
            title: t("phrases.buy_international_phone_numbers"),
            content: t("phrases.international_phone_numbers")
        }
    ];

    const phoneMenuOptionsUS = [
        {
            href: 'https://www.yay.com/phone-number/all-numbers/',
            title: t("phrases.all_phone_numbers"),
            content: t("phrases.all_phone_numbers")
        },
        {
            href: 'https://www.yay.com/phone-number/keep-my-number/',
            title: t("phrases.transfer_phone_number"),
            content: t("phrases.transfer_phone_number")
        },
        {
            href: 'https://www.yay.com/phone-number/917-new-york/',
            title: t("phrases.buy_%area_phone_numbers", { area: "New York 917" }),
            content: t("phrases.%area_phone_numbers", { area: "New York 917" })
        },
        {
            href: 'https://www.yay.com/phone-number/213-los-angeles/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Los Angeles 213" }),
            content: t("phrases.%area_phone_numbers", { area: "Los Angeles 213" })
        },
        {
            href: 'https://www.yay.com/phone-number/773-chicago/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Chicago 773" }),
            content: t("phrases.%area_phone_numbers", { area: "Chicago 773" })
        },
        {
            href: 'https://www.yay.com/phone-number/832-houston/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Houston 832" }),
            content: t("phrases.%area_phone_numbers", { area: "Houston 832" })
        },
        {
            href: 'https://www.yay.com/phone-number/628-san-francisco/',
            title: t("phrases.buy_%area_phone_numbers", { area: "San Francisco 628" }),
            content: t("phrases.%area_phone_numbers", { area: "San Francisco 628" })
        },
        {
            href: 'https://www.yay.com/phone-number/206-seattle/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Seattle 206" }),
            content: t("phrases.%area_phone_numbers", { area: "Seattle 206" })
        },
        {
            href: 'https://www.yay.com/phone-number/202-washington-dc/',
            title: t("phrases.buy_%area_phone_numbers", { area: "Washington DC 202" }),
            content: t("phrases.%area_phone_numbers", { area: "Washington DC 202" })
        },
        {
            href: 'https://www.yay.com/phone-number/international/',
            title: t("phrases.buy_international_phone_numbers"),
            content: t("phrases.international_phone_numbers")
        }
    ];

    const phoneMenuOptions = countryCode === 'US' ? phoneMenuOptionsUS : phoneMenuOptionsUK;

    return (
        <div id='download-app'>
            <header className='mobile-header-top yay'>
                <div className='trolly'>
                    <i
                        className='icon-burger small-nav-trigger'
                        title={t("phrases.trigger_mobile_menu")}
                    />
                </div>
                <a href='https://www.yay.com/' className='logo'>
                    <img
                        src={logo}
                        alt={`Yay.com - ${t("phrases.making_business_easy")}`}
                        title={`Yay.com - ${t("phrases.voip_service_provider")}`}
                    />
                </a>
                <img
                    className='profileIconImage small-info-trigger'
                    src={contactWhite}
                    alt='profile'
                    title={`Yay.com - ${t("phrases.voip_service_provider")}`}
                    onClick={() => window.location.assign('https://www.yay.com/account/')}
                />
                <div className='react-navbar'>
                    <i className='icon-cross small-nav-trigger' />
                    <ul>
                        <li>
                            <p className='react-navbar__section-title'>
                                {t("terms.voice")}
                            </p>
                            <ul>
                                <li>
                                    <a
                                        href='https://www.yay.com/voice/'
                                        title={t("phrases.compare_voip_plans")}
                                    >
                                        {t("phrases.compare_voip_plans")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/voice/calls/'
                                        title={t("phrases.call_costs")}
                                    >
                                        {t("phrases.call_costs")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/voice/phone-system/'
                                        title={t("phrases.voice_features")}
                                    >
                                        {t("phrases.voice_features")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/app/'
                                        title={t("phrases.download_yay_apps")}
                                    >
                                        {t("phrases.download_yay_apps")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/reseller/'
                                        title={t("phrases.hosted_voice_reseller")}
                                    >
                                        {t("phrases.hosted_voice_reseller")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        title={t("phrases.voip_free_trial")}
                                        href='https://www.yay.com/voip/try-free/'
                                    >
                                        {t("phrases.voip_free_trial")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        title={t("phrases.business_phone_system")}
                                        href='https://www.yay.com/business-phone-system/'
                                    >
                                        {t("phrases.business_phone_system")}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className='react-navbar__section-title'>
                                {t("terms.phone_number", 2)}
                            </p>
                            <ul>
                                {phoneMenuOptions.map(option => (
                                    <li>
                                        <a href={option.href} title={option.title}>
                                            {option.content}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li>
                            <p className='react-navbar__section-title'>
                                {t("phrases.domain_names")}
                            </p>
                            <ul>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/'
                                        title={t("phrases.domain_name_search")}
                                    >
                                        {t("phrases.domain_name_search")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/transfer-bulk/'
                                        title={t("phrases.domain_transfer")}
                                    >
                                        {t("phrases.domain_transfer")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/backorder/'
                                        title={t("phrases.domain_backorders")}
                                    >
                                        {t("phrases.domain_backorders")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/pricing/'
                                        title={t("phrases.domain_registration_pricing")}
                                    >
                                        {t("phrases.domain_registration_pricing")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/bulk-search/'
                                        title={t("phrases.bulk_domain_search")}
                                    >
                                        {t("phrases.bulk_domain_search")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/registration/'
                                        title={t("phrases.domain_name_registration")}
                                    >
                                        {t("phrases.domain_name_registration")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/reseller/domains/'
                                        title={t("phrases.domain_name_reseller")}
                                    >
                                        {t("phrases.domain_name_reseller")}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className='react-navbar__section-title'>
                                {t("terms.integration", 2)}
                            </p>
                            <ul>
                                <li>
                                    <a
                                        href='https://www.yay.com/voice/integrations/'
                                        title={t("phrases.phone_system_integration")}
                                    >
                                        {t("phrases.phone_system_integration")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/voip/api-docs/'
                                        title={t("phrases.voip_api_docs")}
                                    >
                                        {t("phrases.voip_api_docs")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/domain-names/api-docs/'
                                        title={t("phrases.domain_name_api_docs")}
                                    >
                                        {t("phrases.domain_name_api_docs")}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className='react-navbar__section-title'>
                                {t("phrases.help_and_about")}
                            </p>
                            <ul>
                                <li>
                                    <a href='https://www.yay.com/about/' title= {t("phrases.about_yay")}>
                                        {t("phrases.about_us")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/about/accreditations/'
                                        title={t("phrases.policy_accreditations")}
                                    >
                                        {t("phrases.policy_accreditations")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/about/reviews/'
                                        title={t("phrases.review_testimonials")}
                                    >
                                        {t("terms.review", 2)}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/faq/'
                                        title={t("phrases.voip_domain_questions")}
                                    >
                                        {t("phrases.frequent_questions")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.yay.com/about/contact-us/'
                                        title={t("phrases.contact_yay")}
                                    >
                                        {t("phrases.contact_us")}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </header>
            <div className='upsellPageContent'>
                <h1>
                    {t("phrases.perfect_way_to_stay_in_contact")}
                </h1>
                <p>{t("phrases.download_app_latest_version")}:</p>
                <div className='appBadges'>
                    <a
                        href='https://apps.apple.com/gb/app/yay-com/id1413192435'
                        className='secondImage'
                    >
                        <img alt={t("phrases.apple_playstore_link")} src={appleStore} />
                    </a>
                    <a
                        href='https://play.google.com/store/apps/details?id=com.yay.android_app'
                        className='firstImage'
                    >
                        <img alt={t("phrases.google_playstore_link")} src={googlePlayBadge} />
                    </a>
                </div>
                <img className='topHalfPhone' alt='' src={mobilePhoneTop} />
                <div className='infoDivs'>
                    <div className='infoDiv'>
                        <p>{t("phrases.hd_quality_calls")}</p>
                        <p>{t("phrases.hd_quality_calls_description")}</p>
                    </div>
                    <div className='infoDiv'>
                        <p>{t("phrases.call_assurance")}</p>
                        <p>{t("phrases.call_assurance_description")}</p>
                    </div>
                    <div className='infoDiv'>
                        <p>{t("phrases.voicemail")}</p>
                        <p>{t("phrases.voicemail_description")}</p>
                    </div>
                    <div className='infoDiv'>
                        <p>{t("phrases.music_on_hold")}</p>
                        <p>{t("phrases.music_on_hold_description")}</p>
                    </div>
                    <div className='infoDiv'>
                        <p>{t("phrases.call_history")}</p>
                        <p>{t("phrases.call_history_description")}</p>
                    </div>
                    <div className='infoDiv'>
                        <p>{t("phrases.call_encryption")}</p>
                        <p>{t("phrases.call_encryption_description")}</p>
                    </div>
                </div>
                <img className='bottomHalfPhone' alt='' src={mobilePhoneBottom} />
            </div>
            <div className='background-phones'>
                <img alt='' src={phone1} className='firstImage' />
                <img alt='' src={phone2} className='secondImage' />
                <img alt='' className='mobile-phones-480' src={mobilePhone480} />
            </div>
            <footer className='download-footer'>
                <div>
                    <ul className='download-info'>
                        <li>
                            <a href='https://www.yay.com/about/'>{t("phrases.about_%name", { name: "Yay"})}</a>
                        </li>

                        <li>
                            <a href='https://www.yay.com/terms'>{t("phrases.terms_and_conditions")}</a>
                        </li>

                        <li>
                            <a href='https://www.yay.com/privacy'>{t("phrases.privacy_policy")}</a>
                        </li>
                    </ul>
                    <ul className='download-contact'>
                        <li>
                            <i className='icon-status-busy' />
                            <p>{countryCode === 'US' ? '(888) YAY-8747' : '0330 122 6000'}</p>
                        </li>
                        <li>
                            <i className='icon-at' />
                            <p>help@yay.com</p>
                        </li>
                        <li>
                            <p>
                                &copy;{new Date().getFullYear()} <span>Yay.com</span>
                            </p>
                        </li>
                        <li>
                            <a href='https://www.yay.com/' className='logo'>
                                <img
                                    src={logo}
                                    alt={`Yay.com - ${t("phrases.making_business_easy")}`}
                                    title={`Yay.com - ${t("phrases.voip_service_provider")}`}
                                />
                            </a>
                        </li>
                    </ul>
                    {countryCode === 'US' ? (
                        <p>
                            26 Broadway, 8th Floor, New York,
                            <br />
                            NY, 10004
                        </p>
                    ) : (
                        <p>
                            Unit 4, Riverside Business Park, Walnut Tree Close, Guildford,
                            <br />
                            Surrey, GU1 4UG
                        </p>
                    )}
                </div>
            </footer>
        </div>
    );
};

export default IsMobile;