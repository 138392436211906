import React, {FunctionComponent, ReactNode} from 'react';
import {StyledButton} from "../../../../../StyledComponents";
import Icon from "../../../../../Icon";
import styles from '../../../../Keypad.module.scss'
import {useCallContext} from "../../../../../../context/CallContext/context";
import {CallTabOptions} from "../../CallEstablishedKeypad";

interface Props {
    title: string | ReactNode;
    onClick?: () => void;
    destination?: CallTabOptions;
}

const KeypadPageTitle: FunctionComponent<Props> = ({title, onClick, destination = 'options_menu' }) => {
    const {useCallAction} = useCallContext();

    return (
        <div className={styles.page_heading}>
            <StyledButton
                buttonStyle='text'
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                    useCallAction({
                        type: 'set_current_tab',
                        payload: destination
                    })
                }}
            >
                <Icon name='arrowLeft' width={20} height={20}/>
                <p>
                    Back
                </p>
            </StyledButton>
            <p>{title}</p>
            <div/>
        </div>
    );
};

export default KeypadPageTitle;
