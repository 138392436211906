import React, {ReactElement} from "react";
import {usePolyglot} from "../../context/Polyglot";

type Props = ({
    title: string;
    titleOptions?: ReactElement;
} | {
    title: ReactElement;
    titleOptions?: never;
}) & {
    description?: string;
    input?: ReactElement;
    filter?: ReactElement;
    callerIds?: ReactElement;
    selectedFilters?: string[];
    filterDisplay?: ReactElement;
}

export const MenuHeader: React.FC<Props> = ({
                                                title,
                                                description,
                                                titleOptions,
                                                input,
                                                filter,
                                                filterDisplay,
                                                callerIds,
                                                selectedFilters,
                                            }) => {


    const {t} = usePolyglot()

    let filterText = ``

    if (filter && selectedFilters && selectedFilters.length > 0) {
        filterText = `${t("terms.filter", selectedFilters.length)}: `

        selectedFilters.forEach((f, index) => {
            let text = ''

            switch (true) {
                case selectedFilters.length > 1 && index === (selectedFilters.length - 1):
                    text = ' & '
                    break
                case index > 0:
                    text = ', '
            }

            filterText = filterText.concat(text.concat(f))
        })
    }

    return (
        <div className='menu-header'>
            {typeof title === 'string' ? (
                    <div
                        className='menu-header__title'
                        data-comp='menu-header__title'
                    >
                        <h2>
                            {
                                title
                            }
                        </h2>
                        {description && <p>{description}</p>}
                        {
                            titleOptions
                        }
                    </div>
                ) :
                title
            }
            {input ? (
                <>
                    <div className='menu-header-input'>
                        <div data-filter={!!filter || null}>
                            {
                                input
                            }
                            {filter ? (
                                <>
                                    <div className='menu-header__filters-divider'/>
                                    {filter}
                                </>
                            ) : null}
                        </div>
                    </div>
                    {filter && selectedFilters && selectedFilters.length > 0 ? (
                        <div
                            className='menu-header__filters-display'
                            title={filterText}
                            data-filter={!!filter || null}
                        >
                            {filterDisplay || (
                                <p>
                                    {t('terms.applied_filter', selectedFilters.length)}:&nbsp;
                                    <span>{selectedFilters.join(', ')}</span>
                                </p>
                            )}
                        </div>
                    ) : null}
                </>
            ) : null}
            {callerIds ? (
                <div className='menu-header__caller-ID'>
                    <p>{t("terms.caller_id")}</p>
                    {callerIds}
                </div>
            ) : null}
        </div>
    )
}

export default MenuHeader;
