import React, { useState, useRef, RefObject, DragEvent } from 'react';
import {useTypedSelector} from "../redux/hooks";
import {usePolyglot} from "../context/Polyglot";
import {useChatContext} from "../context/ChatContext/context";

interface DragAndDropProps {
    uploadInputRef: RefObject<HTMLInputElement>;
    customClass?: string;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({
    uploadInputRef,
    customClass,
    children,
    disabled
}) => {
    const {useChatAction} = useChatContext()

    const [dropzoneActive, setDropzoneActive] = useState<boolean>(false);

    const { t } = usePolyglot();

    const activeChat = useTypedSelector(state => state.chat.activeChat);

    const dropRef = useRef<HTMLDivElement>(null);

    let dragCounter = 1;

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        if (!activeChat || disabled) return;

        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        if (!activeChat || disabled || event.dataTransfer.items.length < 1 || !event.dataTransfer.types.includes('Files')) return;

        event.preventDefault();
        event.stopPropagation();

        dragCounter += 1;

        setDropzoneActive(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        if (!activeChat || disabled) return;

        event.preventDefault();
        event.stopPropagation();

        // eslint-disable-next-line no-plusplus
        dragCounter--;

        if (dragCounter === 0 || !dropRef.current?.contains(event.target as Node)) {
            setDropzoneActive(false);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        if (!activeChat || disabled) return;

        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            uploadFiles(event.dataTransfer.files);

            dragCounter = 0;
        }

        setDropzoneActive(false);
    };

    const uploadFiles = (files) => {
        useChatAction({
            type: "add_file_to_upload",
            payload: files
        })
    }

    return (
        <div
            ref={dropRef}
            className={`drag-and-drop ${dropzoneActive && 'drag-hover'} ${
                customClass && customClass
            }`}
            style={{ position: 'relative' }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {dropzoneActive && (
                <div
                    style={{
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        top: '0',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        height: '100%',
                        width: '100%',
                        zIndex: 2
                    }}
                >
                    <div className='target'>
                        <div className='drop-modal'>
                            <p>{t("phrases.drop_files_here")}!</p>
                        </div>
                    </div>
                </div>
            )}
            <input
                ref={uploadInputRef}
                type='file'
                onChange={event => event.target.files && uploadFiles(event.target.files)}
                onClick={event => {
                    // eslint-disable-next-line no-param-reassign
                    (event.target as HTMLInputElement).value = '';
                }}
                multiple
                tabIndex={-1}
            />
            {children}
        </div>
    );
};
