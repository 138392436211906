import Polyglot from "node-polyglot";
import {phrasesEn} from "../context/Polyglot/phrases-en";
import getNavigatorLocale from "../helpers/getNavigatorLocale.helpers";
import {Phrases} from "../context/Polyglot";

const appLocaleSetting = localStorage.getItem("locale");
const navigatorLocale = getNavigatorLocale();

function getPhrasesByLocale(locale) {
    switch (locale) {
        // case 'es':
        //     return phrasesPl
        // case 'fr':
        //     return phrasesPl
        // case 'pl':
        //     return phrasesPl
        default:
            return phrasesEn
    }
}

const polyglot = new Polyglot({
    // allowMissing: true,
    phrases: getPhrasesByLocale(appLocaleSetting || navigatorLocale),
    locale: appLocaleSetting || navigatorLocale
});

const t = (p: Phrases, options?: number | Polyglot.InterpolationOptions): string => polyglot.t(p, options);

function setPolyglotLocale(locale) {
    if (locale) {
        polyglot.locale(locale);
        polyglot.replace(getPhrasesByLocale(locale))
    }
}

export { polyglot, setPolyglotLocale, t };
