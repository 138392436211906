import {useEffect, useState} from "react";
import {useTypedSelector} from "../../redux/hooks";
import {CallWhenAvailableUser, selectCallWhenAvailableList} from "../../redux/slices";
import {usePrevious} from "../../helpers";


export const useCallWhenAvailableList = () => {
    const whenAvailableList = useTypedSelector(selectCallWhenAvailableList);

    const previousLength = usePrevious(whenAvailableList.length) || 0;

    const [unseenList, setUnseenList] = useState<CallWhenAvailableUser[]>([]);
    const [seenList, setSeenList] = useState<CallWhenAvailableUser[]>([]);

    useEffect(() => {
        if (whenAvailableList.length > previousLength) {
            setUnseenList([...unseenList, whenAvailableList[whenAvailableList.length - 1]])
            setSeenList([...unseenList, whenAvailableList[whenAvailableList.length - 1]])
        }

        if (whenAvailableList.length < previousLength) {
            setUnseenList(unseenList.filter(u => whenAvailableList.some(l => l.user_uuid === u.user_uuid)))
            setSeenList(unseenList.filter(u => whenAvailableList.some(l => l.user_uuid === u.user_uuid)))
        }
    }, [whenAvailableList.length]);


    return {
        unseenList,
        setUnseenList,
        seenList,
    }
}