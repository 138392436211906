import React, {FunctionComponent, lazy, Suspense, useRef, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import styles from '../../styles.module.scss'
import {StyledButton} from "../../../../StyledComponents";
import {ChatIcon} from "../../../../icons/ChatIcon";
import LoadingSpinner from "../../../../loading/LoadingSpinner";
import {useTypedSelector} from "../../../../../redux/hooks";
import {selectCurrentUserId} from "../../../../../redux/slices";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {usePolyglot} from "../../../../../context/Polyglot";

const ImageEditor = lazy(() => import("../../../ImageEditor/ImageEditor"))

interface Props {
}

const GroupIconEditor: FunctionComponent<Props> = () => {
    const {t} = usePolyglot();

    const {control} = useFormContext();
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const [showIconEditor, setShowIconEditor] = useState(false);

    const hasImg = chat.channel?.avatar_set || false

    const urlCreator = useRef(window.URL || window.webkitURL);

    return (
        <Controller
            name='group_icon'
            control={control}
            render={({
                         field: {onChange, value},
                     }) => (
                <div
                    className={styles.group_icon_section}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className={styles.icon}>
                        {value && value !== '' ? (
                            <div>
                                <img
                                    src={urlCreator.current.createObjectURL(value)}
                                    alt='preview'
                                    width={80}
                                    height={80}
                                />
                            </div>
                        ) : null}
                        {!value ? (
                            <ChatIcon
                                chat={chat}
                                splitGroup={value === ''}
                                size={80}
                            />
                        ) : null}
                    </div>
                    <div className={styles.control}>
                        <div className={styles.button}>
                            <StyledButton
                                buttonStyle='tertiary'
                                onClick={() => setShowIconEditor(!showIconEditor)}
                            >
                                <p>
                                    {t('phrases.upload_a_new_image')}
                                </p>
                            </StyledButton>
                            {(hasImg && !value && value !== '') || value ? (
                                <StyledButton
                                    buttonStyle='text'
                                    context='danger'
                                    onClick={() => {
                                        onChange('')
                                    }}
                                >
                                    <p>
                                        {t('actions.remove')}
                                    </p>
                                </StyledButton>
                            ) : null}
                        </div>
                        <p className={styles.desc}>
                            {t('phrases.file_types_png_and_jpeg_up_to_4mb')}
                        </p>
                    </div>
                    <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                        {showIconEditor ? (
                            <div className={styles.icon_editor_container}>
                                <ImageEditor
                                    title={t('phrases.change_group_icon')}
                                    closeEditor={() => setShowIconEditor(false)}
                                    initialSrc={`/api/get-channel-avatar?voip_user_uuid=${userUuid}&channel_uuid=${chat.uuid}`}
                                    upload={(imgBlob) => {
                                        if (imgBlob) {
                                            onChange(imgBlob)
                                            setShowIconEditor(false)
                                        } else {
                                            onChange('')
                                            setShowIconEditor(false)
                                        }
                                    }}
                                />
                            </div>
                        ) : null}
                    </Suspense>
                </div>
            )}
            rules={{required: true}}
        />
    );
};

export default GroupIconEditor;
