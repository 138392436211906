import {TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import moment from "moment/moment";
import {
    useGetAllMeetingsQuery,
    usePostMeetingMutation,
    usePutMeetingMutation
} from "../../../redux/services/videoApi";
import {usePolyglot} from "../../../context/Polyglot";
import {useTypedSelector} from "../../../redux/hooks";
import {selectActiveRoomId, selectCurrentUser} from "../../../redux/slices";
import {useSendChatMessageMutation} from "../../../redux/services/chatApi";
import {api} from "../../../api";
import {MeetingRoom} from "../../../types/video";
import {IChatMessage} from "../../../types";
import {getVideoBaseUrl} from "../helpers";

export const useMeetingEditor = () => {
    const currentUser = useTypedSelector(selectCurrentUser);

    const {data: meetings} = useGetAllMeetingsQuery(null);
    const activeMeetingId = useTypedSelector(selectActiveRoomId);
    const meetingApiData = meetings?.result?.find(meeting => meeting.uuid === activeMeetingId);

    const [putMeeting, {
        isLoading: putMeetingLoading
    }] = usePutMeetingMutation();
    const [postMeeting, {
        isLoading: postMeetingLoading
    }] = usePostMeetingMutation();
    const [sendChatMessage] = useSendChatMessageMutation();

    const [usePassword, setUsePassword] = useState(!meetingApiData?.is_open);

    const matchUrl = useRef(true);
    const {t} = usePolyglot();

    const NewMeetingSchema = z
        .object({
            name: z.string()
                .min(1, "Meeting Name is Required")
                .max(255, "Name must contain less than 255 characters")
                .refine((s) => !s.includes("/"), "Invalid character '/'"),
            custom_url: z.string()
                .min(1, 'Url is required')
                .min(3, t("phrases.error_meeting_url_length"))
                .max(50, "Id must be shorter than 50 characters")
                .refine((s) => !s.includes("/"), "Invalid character '/'"),
            password: z.string()
                .max(255, "Password must contain less than 255 characters")
                .optional(),
            description: z.string()
                .max(255, "Description must contain less than 255 characters")
                .optional(),
            members: z.array(z.object({
                user_id: z.string(),
                user_name: z.string(),
                status: z.any().optional(),
                admin: z.boolean().optional()
            })),
            uuid: z.string().optional(),
            mute_on_entry: z.boolean(),
            force_waiting_room: z.boolean(),
            allow_muting: z.boolean(),
            is_open: z.boolean(),
            show_to_all: z.boolean(),
        })

    type NewMeetingFormInput = TypeOf<typeof NewMeetingSchema>;

    const formMethods = useForm<NewMeetingFormInput>({
        resolver: zodResolver(NewMeetingSchema),
        defaultValues: {
            name: meetingApiData?.name || '',
            password: meetingApiData?.password || '',
            description: meetingApiData?.description || '',
            custom_url: meetingApiData?.custom_url || '',
            members: meetingApiData?.members || [{
                user_id: currentUser.uuid,
                user_name: currentUser.nickname,
                admin: true,
            },],
            mute_on_entry: meetingApiData?.mute_on_entry || false,
            force_waiting_room: meetingApiData?.force_waiting_room || false,
            allow_muting: meetingApiData?.allow_muting ?? true,
            is_open: meetingApiData?.is_open || false,
            show_to_all: meetingApiData?.show_to_all || false,
            uuid: meetingApiData?.uuid || undefined,
        }
    });

    useEffect(() => {
        const subscription = formMethods.watch((value, {name}) => {
            switch (name) {
                case 'name' :
                    if (matchUrl.current) {
                        formMethods.setValue('custom_url', (value.name || '').toLowerCase().replaceAll(' ', '-'));
                    }
                    break;
                case 'custom_url':
                    formMethods.clearErrors("custom_url")
                    matchUrl.current = (value.name || '').toLowerCase().replaceAll(' ', '-') === value.custom_url;
                    break;
                case 'is_open':
                    setUsePassword(!value.is_open);
                    break;
                default:
                    break;
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    const sendInvites = (uuidsToInvite: string[], invitationMessage: string) => {

        for (let i = 0; i < uuidsToInvite.length; i += 1) {
            const cur = uuidsToInvite[i];


            const uuid = uuidv4();
            const message: IChatMessage = {
                uuid,
                to: cur,
                to_type: 'user',
                content: invitationMessage,
                from: currentUser.uuid,
                from_type: 'user',
                time: moment().format(),
                updated_at: moment().format()
            };

            sendChatMessage(message);
        }
    }

    const validateUrl = async (urlToValidate: string): Promise<boolean> => {

        try {
            await api.checkMeetingId({
                meetingId: urlToValidate
            })
        } catch (e: any) {
            let message = t("phrases.error_meeting_id_taken");
            const data = e.response?.data

            if (data && data.status_code !== 500) {
                message = data.result
            }

            formMethods.setError("custom_url", {message});
            return false
        }

        return true;
    }

    const saveForm = async (formData: NewMeetingFormInput) => {
        if (!formData.uuid) {
            const isValidUrl = await validateUrl(formData.custom_url);

            if (!isValidUrl) {
                return;
            }
        }

        const data: MeetingRoom = {
            ...formData
        }

        if (data.show_to_all && data.password) {
            data.password = undefined
        }

        const baseUrl = getVideoBaseUrl();

        const invitationMessage = (
            `${t("phrases.%name_is_inviting_you_to_a_meeting_room", {name: currentUser.nickname || ''})} \n` +
            `Join '${data.name || ''}' \n` +
            `${baseUrl}/${data.custom_url || ''} \n` +
            `${t("terms.meeting_id")}: ${data.custom_url || ''}` +
            `${data.password ? ` \n${t("terms.password")}: ${data.password}` : ''}`
        );

        if (data.uuid) {
            const uuidsToSend: string[] = []

            data.members.forEach(m => {
                if (
                    m.user_id !== currentUser.uuid
                    && meetingApiData
                    && !meetingApiData.members.some(me => me.user_id === m.user_id)
                ) {
                    uuidsToSend.push(m.user_id)
                }
            })

            putMeeting({
                uuid: data.uuid,
                data
            })
                .then(() => {

                    sendInvites(uuidsToSend, invitationMessage)
                })
        } else {
            postMeeting(data)
                .then(() => {
                    const uuidsToSend: string[] = []

                    data.members.forEach(m => {
                        if (m.user_id !== currentUser.uuid) {
                            uuidsToSend.push(m.user_id)
                        }
                    })

                    sendInvites(uuidsToSend, invitationMessage)

                })
        }
    }

    return {
        formMethods,
        saveForm,
        loading: putMeetingLoading || postMeetingLoading,
        usePassword
    }
}