import React, {memo, useRef, useState} from 'react';
import {IChatMember} from "../../../../../types";
import {getDisplayColor, useOnClickOutside} from "../../../../../helpers";
import StyledDropdown from "../../../../StyledComponents/StyledDropdown";
import {StyledButton} from "../../../../StyledComponents";
import styles from '../../styles.module.scss';
import {usePolyglot} from "../../../../../context/Polyglot";
import {MemberOption} from "../../../../../pages/chat/ChatSettings/ChatSettingsMenu/children/GroupMember";

interface Props {
    member: IChatMember;
    isAdmin: boolean;
    isCreator: boolean;
    canEdit: boolean;
    onRemove: () => void;
}

const GroupEditMember = memo<Props>(({member, isAdmin, isCreator, canEdit, onRemove}) => {
    const [kickWarning, setKickWarning] = useState<boolean>(false);

    const {t} = usePolyglot();

    const settingsRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(settingsRef, () => setKickWarning(false))

    const handleKick = () => {
        if (kickWarning) {
            onRemove()
        } else {
            setKickWarning(true)
        }
    }

    const options: MemberOption[] = [
        {
            label: kickWarning ? t("phrases.are_you_sure") : t("actions.remove_from_group"),
            value: 'remove',
            onClick: () => handleKick(),
        }
    ]

    return (
        <div
            className={styles.group_member}
            onBlur={(e) => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    setKickWarning(false)
                }
            }}
        >
            <div className={styles.group_member_icon}>
                {member.avatar
                    ? <img
                        alt={member.display_name}
                        src={`https://storage.googleapis.com/v-profiles/${member.avatar}`}
                        width={32}
                        height={32}
                    />
                    :
                    <span className={getDisplayColor(member?.member, true)}>
                        {member.display_name
                            ?.match(/\b\w/g)
                            ?.join('')
                            .substring(0, 2)
                        }
                    </span>
                }
            </div>
            <p>
                {member.display_name}
            </p>
            <div
                className={styles.group_member_option}
                ref={settingsRef}
            >
                {canEdit ? (
                    <StyledDropdown
                        options={options}
                        component={(props) =>
                            <StyledButton
                                key={`GCMO_${props.value}`}
                                buttonStyle='text'
                                onClick={() => props.onClick()}
                                context={props.value === 'remove' ? 'danger' : undefined}
                            >
                                <p>
                                    {props.label}
                                </p>
                            </StyledButton>
                        }
                        placeHolder={(isCreator && `${t("terms.creator", 1)}`) || (isAdmin && `${t("terms.admin", 1)}`) || 'Member'}
                        direction='right'
                    />
                ) : (
                    <p>
                        {(isCreator && `${t("terms.creator", 1)}`) || (isAdmin && `${t("terms.admin", 1)}`) || 'Member'}
                    </p>
                )}
            </div>
        </div>
    );
});

export default GroupEditMember;
