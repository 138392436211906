import {useEffect} from 'react';
import {useGlobalSettings, useInitialiseApp} from "../hooks";
import {useHeadsetContext} from "../../../context/HeadsetContext/context";
import {useTypedSelector} from "../../../redux/hooks";
import {
    selectAllCalls,
} from "../../../redux/slices";
import {useHotkeyContext} from "../../../context/HotKeysContext/context";
import {useDnd} from "../../../helpers";

interface Props {
    setIsLoaded: (val: boolean) => void;
    setChatsLoading: (val: boolean) => void;
}
const HomeReRenderOffLoad: React.FC<Props> = ({setIsLoaded, setChatsLoading}) => {
    const {reportValues} = useHeadsetContext();
    const {hotKeyNavigate} = useHotkeyContext();

    const calls = useTypedSelector(selectAllCalls);

    const {
        localDnd,
        globalDnd,
        dndDisabled,
        updateDnd
    } = useDnd();

    useEffect(() => {
        if (!dndDisabled) return;

        if (globalDnd) {
            updateDnd(false, true);
        }

        if (localDnd) {
            updateDnd(false, false);
        }
    }, [dndDisabled, globalDnd, localDnd]);

    useEffect(() => {
        if (globalDnd && localDnd) {
            updateDnd(false, false);
        }
    }, [globalDnd, localDnd]);

    useEffect(() => {
        if (calls.length > 0) return;

        hotKeyNavigate('keypad');
    }, [reportValues.length]);


    const {chatsLoading} = useInitialiseApp({setIsLoaded});
    useGlobalSettings()

    useEffect(() => {
        setChatsLoading(chatsLoading)
    }, [chatsLoading]);

    return null;
};

export default HomeReRenderOffLoad;
