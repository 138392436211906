import React, {useEffect, useRef, useState} from "react";
import debounce from "lodash.debounce";
import styles from "../../pages/home/Home.module.scss";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {selectActivePage, selectAppSettingByKey, updateAppMetaSettings} from "../../redux/slices";
import AriaText from "../AriaComponents/AriaText";

interface Props {
    sidebarRef: any
}

const Resizer: React.FC<Props> = ({sidebarRef}) => {
    const isElectron = navigator.userAgent.toLowerCase().includes(' electron/');
    const page = useTypedSelector(selectActivePage);

    const width = useTypedSelector(state => selectAppSettingByKey(state, 'asideWidth'));

    const minWidth = 350;

    const resizerRef = useRef<HTMLDivElement>(null);
    const [isResizing, setIsResizing] = useState(false);

    const [lock, setLock] = useState<boolean>(true);

    const posX = useRef<number>(420);
    const maxWidth = useRef(Math.max(minWidth, isElectron ? (window.innerWidth - 500) : Math.min(window.innerWidth - 500, 1000)));

    const dispatch = useTypedDispatch();

    const setAsideWidth = (value: number) => {
        dispatch(
            updateAppMetaSettings({setting: 'asideWidth', value})
        )
    }

    const inBounds = (val) => Math.min(maxWidth.current, Math.max(minWidth, val));

    const moveResizer = (resizerToPos: number) => {
        if (resizerRef.current) {
            const val = (inBounds(resizerToPos) - (resizerRef.current.getBoundingClientRect().width / 2)) + 4

            posX.current = val;
            resizerRef.current.style.marginLeft = `${val - 4}px`;
        }
    }

    const handleMouseMove = (e) => {
        if (resizerRef.current) {
            e.preventDefault();
            e.stopPropagation();
            moveResizer((e.clientX - sidebarRef.current.getBoundingClientRect().left))
        }
    }

    useEffect(() => {
        if (lock) {
            setLock(false)
        } else if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove)
        } else {
            handleResize()
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        };
    }, [isResizing]);


    useEffect(() => {
        const windowsResizer = debounce(() => {
            maxWidth.current = Math.max(minWidth, isElectron ? (window.innerWidth - 500) : Math.min(window.innerWidth - 500, 1000))
            moveResizer(posX.current)
            if (maxWidth.current >= 420 && width < 420) {
                setAsideWidth(420)
            } else {
                handleResize()
            }
        }, 500)

        window.addEventListener('resize', windowsResizer);
        return () => {
            window.removeEventListener('resize', windowsResizer);
        };
    }, [width]);


    useEffect(() => {
        moveResizer(width);
    }, [width, page])

    useEffect(() => {
        window.addEventListener("mouseup", () => setIsResizing(false));
        return () => {
            window.removeEventListener("mouseup", () => setIsResizing(false))
        };
    }, [isResizing]);

    const handleResize = () => {
        if ((posX.current) !== width) {
            setAsideWidth(posX.current)
        }
    }

    const arrowKeyResizer = debounce(() => {
        if ((posX.current) !== width) {
            setAsideWidth(posX.current)
        }
    }, 500)

    const handleArrowKeys = (e: any, left = false) => {
        if (resizerRef.current) {
            e.preventDefault();
            e.stopPropagation();
            moveResizer(e.target.offsetLeft + 4 + (left ? -5 : 5))
            arrowKeyResizer()
        }
    }

    if (page === 'settings') {
        return null;
    }

    return (
        <div
            ref={resizerRef}
            className={`${styles.resizer_container} ${isResizing ? styles.active : ''}`}
            onMouseDown={() => setIsResizing(true)}
            onMouseUp={() => setIsResizing(false)}
            onDoubleClick={() => {
                if (width !== 420) {
                    setAsideWidth(420)
                }
            }}
            onKeyDown={(e) => {
                switch (e.key) {
                    case 'ArrowRight':
                        handleArrowKeys(e)
                        break;
                    case 'ArrowLeft':
                        handleArrowKeys(e, true)
                        break;
                    case 'Enter' :
                        setAsideWidth(420)
                }
            }}
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
            title='Resize sidebar'
        >
            <AriaText text='Use arrow keys or drag to'/>
            <span className={styles.resizeHandle}/>
        </div>
    )
}

export default Resizer;
