import React from 'react';
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';
import { isCallSwitch, isPulseHd, isYay } from '../../helpers';


export const UnsupportedBrowser: React.FC<{ completePasswordUpdate?: string | null }> = ({
                                                                                             completePasswordUpdate
                                                                                         }) => {

    const {
        appLogoWhite,
        appLogoColor,
        appLogoAlt,
        appDownloadURL,
        darkMode
    } = useAppContext();

    const {t} = usePolyglot();

    return (
        <section id='login-unsupported'>
            <div className='container'>
                <img
                    src={(darkMode || isYay) ? appLogoWhite : appLogoColor}
                    alt={appLogoAlt} className='logo'
                    style={{
                        height: 50,
                        maxWidth: 200,
                    }}
                />
                <div className='unsupported-message'>
                    {(completePasswordUpdate && isPulseHd) ? (
                        <p>{t("phrases.password_is_now_reset")}</p>
                    ) : null}
                    <p>
                        {t("phrases.for_best_experience_please_use")}
                        <br/>
                        <a
                            href='https://www.google.com/chrome/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Google Chrome
                        </a>
                    </p>
                    <div>
                        <p>{t("conjunctions.or")}</p>
                        {isCallSwitch ? (
                            <a
                                href='https://www.callswitchone.com/download/'
                            >
                                {t("phrases.download_desktop_app")}
                            </a>
                        ) : (
                            <a href={appDownloadURL} download>
                                {t("phrases.download_desktop_app")}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};


export default UnsupportedBrowser