import {detect} from "detect-browser";
import React, {lazy, ReactElement, Suspense} from "react";
import {useTypedSelector} from "../redux/hooks";
import {isMobileDevice} from "../helpers";
import {selectLoggedInUser} from "../redux/slices";
import { useAppContext } from '../context/AppContext/AppContext';

const MobileAppDownload = lazy(() => import('../pages/auth/MobileAppDownload'))
const InviteCompletion = lazy(() => import('../pages/auth/InviteCompletion/InvitieCompletion'))
const AccountVerification = lazy(() => import('../pages/auth/AccountVerification'))
const IsMobile = lazy(() => import('../pages/auth/IsMobile'))
const UnsupportedBrowser = lazy(() => import('../pages/auth/UnsupportedBrowser'))

interface FallbackContainerProps {
    children: any
}

export const FallbackContainer: React.FC<FallbackContainerProps> = ({children}) => {

    const loggedInUser = useTypedSelector(selectLoggedInUser);

    const {isYay} = useAppContext();

    const urlParams = new URLSearchParams(window.location.search);

    const isMobile = isMobileDevice() && window.innerWidth < 1025;
    const browser = detect();
    const inviteToken = urlParams.get('invite_token');
    const email = urlParams.get('email');
    const completePasswordUpdate = urlParams.get('complete');

    let display: ReactElement | undefined;

    switch (true) {
        // First: If pulseHD and url has inviteToken and email
        case !isYay && !!inviteToken && !!email:
            display = (
                <InviteCompletion
                    inviteToken={inviteToken as string}
                    email={email as string}
                />
            )
            break;
        // Second: check not a mobile and touch device
        case isMobile:
            display = isYay ? <IsMobile/> : <MobileAppDownload/>;
            break;
        // Third: Reject if not chromium
        case (
            !browser ||
            browser.os === 'Android OS' ||
            !['chrome', 'edge-chromium'].includes(browser.name)
        ):
            display = (
                <UnsupportedBrowser completePasswordUpdate={completePasswordUpdate}/>
            )
            break;
        // Fourth: Check the user that's logging has verified email and mobile
        case (
            isYay &&
            loggedInUser?.verifications?.some(verification => verification.is_required && !verification.is_verified)
        ) :
            display = <AccountVerification loggedInUser={loggedInUser}/>
            break;
    }

    return (
        <Suspense>
            {display || children}
        </Suspense>
    )
}
