import React, {useRef, useState} from "react";
import {useTypedSelector} from "../../../../redux/hooks";
import {selectCurrentUserId, selectUsersDictionary} from "../../../../redux/slices";
import {useOnClickOutside} from "../../../../helpers";
import {usePolyglot} from "../../../../context/Polyglot";
import {useChatContext} from "../../../../context/ChatContext/context";
import CallIconButton from "../../../../components/buttons/CallIconButton";
import {useConference} from "../../../../components/keypad/ConferenceHooks";


interface ChatHeaderGroupCallProps {
}

export const ChatHeaderGroupCall: React.FC<ChatHeaderGroupCallProps> = () => {
    const {chat} = useChatContext();

    const userUuid = useTypedSelector(selectCurrentUserId);
    const usersDict = useTypedSelector(selectUsersDictionary);

    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const groupCallRef = useRef<HTMLDivElement>(null);

    const {commitToRoom} = useConference()
    const {t} = usePolyglot();

    useOnClickOutside(groupCallRef, () => setShowConfirm(false))

    const handleGroupCall = () => {
        const invitationNumbers: string[] = []
        chat.channel?.members.forEach((m) => {
            if (m.member !== userUuid) {
                const foundExt = usersDict[m.member]?.extension;
                if (foundExt) {
                    invitationNumbers.push(String(foundExt));
                }
            }
        })

        commitToRoom({invitationNumbers})
    }

    return (
        <div className='chat__header-group-call' ref={groupCallRef}>
            <CallIconButton
                handleCall={() => {
                    setShowConfirm(!showConfirm)
                }}
                ignore_transfer
            />
            {/*
            <AriaButton
                onClick={() => setShowConfirm(!showConfirm)}
                title={t("actions.start_group_call")}
                className='chat__header-group-call'
            >
                <Icon name='phoneCall'/>
            </AriaButton>
            */}
            {showConfirm ? (
                <div>
                    <p>
                        {t("actions.confirm_group_call")}
                    </p>
                    <CallIconButton
                        handleCall={() => {
                            handleGroupCall()
                        }}
                        ignore_transfer
                    />
                </div>
            ) : null}
        </div>
    )
}