import React, { FunctionComponent, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { usePolyglot } from '../../../context/Polyglot';
import formStyles from '../../../sass/globalModules/forms.module.scss';
import Icon from '../../Icon';
import FormTextField from '../../AriaForm/FormTextField';
import { useOnClickOutside } from '../../../helpers';
import { useChatGroupEditor } from './hooks';
import styles from './styles.module.scss';
import { StyledButton } from '../../StyledComponents';
import NewChatMembersController from './children/NewChatMembersController';
import { useTypedSelector } from '../../../redux/hooks';

interface Props {
    closeEditor: () => void;
}

const NewChat: FunctionComponent<Props> = ({ closeEditor }) => {
    const canCreateGroup = useTypedSelector(state => state.user.chat_config?.can_create_group);

    const { t } = usePolyglot();

    const editorRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(editorRef, closeEditor);

    const { formMethods, saveForm, saveLoading, makingGroup } = useChatGroupEditor({
        closeEditor
    });

    return (
        <div className={[formStyles.formContainer, styles.container].join(' ')} ref={editorRef}>
            <section className={formStyles.formHeader}>
                <StyledButton buttonStyle='text' onClick={() => closeEditor()} iconOnly>
                    <Icon name='arrowLeft' />
                </StyledButton>
                <h2>{t('phrases.new_chat')}</h2>
            </section>
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form onSubmit={formMethods.handleSubmit(saveForm)}>
                    <NewChatMembersController closeEditor={closeEditor} />
                    <AnimatePresence>
                        {makingGroup ? (
                            <motion.div
                                initial={{
                                    y: -24,
                                    opacity: 0
                                }}
                                animate={{
                                    y: 0,
                                    opacity: 1
                                }}
                                exit={{
                                    y: 24,
                                    opacity: 0
                                }}
                                transition={{
                                    duration: 0.4
                                }}
                            >
                                <FormTextField name='group_name' label='Group Name' required />
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                    {canCreateGroup ? (
                        <div className={[formStyles.formControls, styles.formControls].join(' ')}>
                            <StyledButton
                                buttonStyle='primary'
                                size='large'
                                type='submit'
                                loading={saveLoading}
                                disabled={saveLoading}
                            >
                                <p>
                                    {makingGroup
                                        ? t('actions.create_group')
                                        : t('actions.start_chat')}
                                </p>
                            </StyledButton>
                        </div>
                    ) : null}
                </form>
            </FormProvider>
        </div>
    );
};

export default NewChat;
