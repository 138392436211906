import {useEffect, useRef} from "react";
import {killStatusesSocket} from "../../redux/services/statusApi";
import {killChatSocket, useGetLatestChatsQuery, useSubscribeChatMessagesQuery} from "../../redux/services/chatApi";
import {
    useGetAvailableTagsQuery, useGetInterTenantsQuery, useGetSmartDiallerDevicesQuery, useGetVoicemailDetailsQuery,
} from "../../redux/services/sipApi";
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    addOneApiOnlyPhonebook,
    connectSip,
    deleteSelectedPhonebookContacts,
    getCallerIds,
    getCallHistory, getGlobalSettings,
    getParkingSlots,
    getPhonebookContacts,
    parseGlobalSettingsForApi, selectProvisionPhones,
    selectCurrentUserId,
    selectGlobalSettings,
    selectGlobalSettingUpdated,
    selectSelectedPhonebooks, selectSipCredentials, setGlobalSettingsUpdates,
    updateActivePage,
    updatePhoneSettings,
    updateSelectedPhonebook,
    updateSettingsPage
} from '../../redux/slices';
import {ICRMIntegration, IPhonebook} from "../../types";
import {useGetUserBlfConfigQuery} from "../../redux/services/provisionApi";
import {usePutUserSettingsMutation} from "../../redux/services/authApi";

export const useInitialiseApp = ({setIsLoaded}: { setIsLoaded: (val: boolean) => void }) => {
    const selectedIntegrationUuids: string[] = useTypedSelector(state => state.user.settings.phone.settings?.crmIntegrationUuids);
    const integrations: ICRMIntegration[] = useTypedSelector(state => state.auth.integrations) || [];
    const authUsers = useTypedSelector(state => state.auth.users); // comes from sip/connect
    const user = useTypedSelector(state => state.user);
    const hardwarePhonesLength = useTypedSelector(selectProvisionPhones).length
    const sipCredentials = useTypedSelector(selectSipCredentials)

    // Sip Selectors
    const parkingSlotsLoading = useTypedSelector(state => state.sip.parkingSlotsLoading);
    const phonebookContactsLoading = useTypedSelector(state => state.auth.phonebookContactsLoading);
    const callHistoryLoading = useTypedSelector(state => state.sip.callHistoryLoading);
    const callerIdsLoading = useTypedSelector(state => state.sip.callerIdsLoading);

    const dispatch = useTypedDispatch()

    useGetUserBlfConfigQuery(user.uuid, {skip: !user || hardwarePhonesLength < 1});
    useGetSmartDiallerDevicesQuery(user.uuid, {skip: !user})
    useSubscribeChatMessagesQuery(null, {skip: !authUsers || !user});

    useGetAvailableTagsQuery(null)

    const {isLoading: chatsLoading} = useGetLatestChatsQuery({
        voip_user_uuid: user.uuid,
        type: 'user,channel',
        limit: 1000
    }, {skip: !user})

    useGetLatestChatsQuery({
        voip_user_uuid: user.uuid,
        type: 'thread',
        limit: 1000
    }, {skip: !user})

    useGetInterTenantsQuery(user.uuid);
    useGetVoicemailDetailsQuery({voip_user_uuid: user.uuid});

    useEffect(() => {
        const handleOffline = () => {
            killStatusesSocket('offline');
            killChatSocket('offline');
        };

        window.addEventListener('offline', handleOffline);

        if ((window as any).electron) {
            (window as any).electron.receive('open-audio-settings', () => {
                dispatch(updateActivePage('settings'))
                dispatch(updateSettingsPage('audio'))
            });
        }

        if (window?.location.pathname.includes('auth-azure')) {
            window.history.pushState('', '', '/')
        }

        setIsLoaded(true)

        return () => {
            window.removeEventListener('offline', handleOffline);
        }
    }, [])

    useEffect(() => {
        if (!integrations || integrations.length < 1) return;
        const crmPopIntegrations = integrations?.filter(integration => integration.supports_screen_pop) || [];

        selectedIntegrationUuids.forEach((integrationUuid) => {
            if (!crmPopIntegrations.some(i => i.uuid === integrationUuid)) {
                dispatch(updatePhoneSettings({
                    setting: 'crmIntegrationUuids',
                    value: selectedIntegrationUuids?.filter(uuid => uuid !== integrationUuid)
                }));
            }
        })
    }, [integrations.length, selectedIntegrationUuids]);


    // SIP RELATED LOGIC
    useEffect(() => {
        if (sipCredentials) {
            dispatch(connectSip(sipCredentials));

        }
    }, [sipCredentials, sipCredentials?.username]);

    useEffect(() => {
        if (user.name && ['idle'].includes(callHistoryLoading)) {
            dispatch(getCallHistory({username: user.name, week: 1}));
        }

        if (user.uuid && ['idle'].includes(callerIdsLoading)) {
            dispatch(getCallerIds(user.uuid));
        }

        if (['idle'].includes(parkingSlotsLoading)) {
            dispatch(getParkingSlots());
        }
    }, [
        dispatch,
        user,
        phonebookContactsLoading,
        callHistoryLoading,
        callerIdsLoading,
        parkingSlotsLoading
    ]);

    return {
        chatsLoading,
    }
}

export const useGlobalSettings = () => {
    const userUuid = useTypedSelector(selectCurrentUserId)
    const allowedPhonebooks = useTypedSelector(state => state.user.allowed_phone_books) || [];
    const phonebooksRestricted = useTypedSelector(state => state.user.restrict_phone_books);
    const selectedPhonebooks = useTypedSelector(selectSelectedPhonebooks);
    const globalSettingUpdated = useTypedSelector(selectGlobalSettingUpdated);
    const globalSettings = useTypedSelector(selectGlobalSettings);
    const allPhonebooks: IPhonebook[] = useTypedSelector(state => state.auth.phonebooks);

    const dispatch = useTypedDispatch();

    const [putUserSettings] = usePutUserSettingsMutation()

    const phonebookLock = useRef(false);

    const commitPhonebooks = (uuids: string[]) => {
        const cur = uuids.pop();
        const pb = allPhonebooks?.find(p => p.uuid === cur);

        if (pb) {
            if (pb.total_contacts > 2500) {
                dispatch(addOneApiOnlyPhonebook(pb.uuid))
                commitPhonebooks(uuids)
            } else {
                dispatch(getPhonebookContacts({phonebookUuid: pb.uuid}))
                commitPhonebooks(uuids)
            }
        }
    }

    useEffect(() => {
        if (
            phonebookLock.current
            || !selectedPhonebooks
            || Object.keys(selectedPhonebooks).length < 1
            || !allPhonebooks
        ) return;

        const validatedPhonebooks: string[] = []

        Object.entries(selectedPhonebooks).forEach(([uuid, val]) => {
            switch (true) {
                case !val:
                    break;
                case !allPhonebooks.some(pb => pb.uuid === uuid):
                case phonebooksRestricted && !allowedPhonebooks.includes(uuid):
                    dispatch(deleteSelectedPhonebookContacts(uuid));
                    dispatch(updateSelectedPhonebook(uuid, false))
                    break;
                case !phonebooksRestricted || allowedPhonebooks.includes(uuid):
                    // dispatch(getPhonebookContacts({phonebookUuid: uuid}));
                    validatedPhonebooks.push(uuid)
                    break;

            }
        })

        commitPhonebooks([...validatedPhonebooks])

        phonebookLock.current = true;
    }, [selectedPhonebooks, allPhonebooks?.length]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        /**
         * If global setting are updated, wait 1 second before updating them in the API
         */

        if (globalSettingUpdated) {
            timeout = setTimeout(() => {
                    putUserSettings({uuid: userUuid, newAipGlobalSettings: parseGlobalSettingsForApi(globalSettings)})
                        .then(() => {
                            dispatch(setGlobalSettingsUpdates(false))
                        })
                },
                1000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [globalSettingUpdated]);

    const globalSettingsLock = useRef(false);

    useEffect(() => {
        if (!userUuid || globalSettingsLock.current) return;

        globalSettingsLock.current = true;
        dispatch(getGlobalSettings())
    }, [userUuid]);

}