/* eslint-disable react/jsx-props-no-spreading */
import {AriaTextFieldProps, useTextField} from "react-aria";
import {ChangeEventHandler, FocusEventHandler, forwardRef} from "react";
import {FieldError} from "react-hook-form";
import styles from './styles.module.scss';
import formStyles from "../AriaForm/styles.module.scss";

interface Props extends AriaTextFieldProps {
    hideLabel?: boolean;
    invalid?: boolean;
    isTouched?: boolean;
    isDirty?: boolean;
    error?: FieldError;
    onEnter?: () => void;
}

const AriaTextArea = forwardRef((props: Props, ref: any) => {
    const {label, hideLabel} = props;
    const {labelProps, inputProps, descriptionProps, errorMessageProps} =
        useTextField(props, ref);

    return (
        <div className={formStyles.form_text_field}>
            {!hideLabel ? (
                <label {...labelProps}>{label}</label>
            ) : null}
            {props.isRequired ? (
                <mark>*</mark>
            ) : null}
            <div className={formStyles.input_wrapper}>
                <div
                    className={formStyles.input_container}
                    data-invalid={props.invalid || null}
                    data-is-touched={props.isTouched || null}
                    data-is-dirty={props.isDirty || null}
                    data-error={!!props.error || null}
                >
            <textarea
                aria-labelledby={inputProps["aria-labelledby"]}
                disabled={inputProps.disabled}
                id={inputProps.id}
                onBlur={inputProps.onBlur as FocusEventHandler<HTMLTextAreaElement> | undefined}
                onFocus={inputProps.onFocus as FocusEventHandler<HTMLTextAreaElement> | undefined}
                onChange={inputProps.onChange as ChangeEventHandler<HTMLTextAreaElement> | undefined}
                readOnly={inputProps.readOnly}
                ref={ref}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        if (props.onEnter) props.onEnter()
                    }
                }}
                rows={5}
                cols={5}
            />
                </div>
            </div>
            {props.description && (
                <div
                    className={styles.description}
                    {...descriptionProps}
                >
                    {props.description}
                </div>
            )}
            {props.errorMessage && typeof props.errorMessage !== 'function' && (
                <div
                    className={styles.errorMessage}
                    {...errorMessageProps}
                >
                    {props.errorMessage}
                </div>
            )}
        </div>
    );
});

export default AriaTextArea;
