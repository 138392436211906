import React, {useMemo} from "react";
import ContactProvider, {useContactContext} from "../../../context/ContactContext/context";
import {IHuntGroup, IUser} from "../../../types";
import {useTypedSelector} from "../../../redux/hooks";
import {usePolyglot} from "../../../context/Polyglot";
import {useCallContact} from "../../../components/sidebars/Contact/hooks";
import styles from "../syles.module.scss";
import {ContactIcon, ContactOptions} from "../../../components/sidebars/Contact/children";
import Contact from "../../../components/sidebars/Contact/Contact";
import {selectUsersDictionary} from "../../../redux/slices";
import CallIconButton from "../../../components/buttons/CallIconButton";

export const HuntGroupOptions: React.FC = () => {
    const {callContact} = useCallContact()

    return (
        <div className={styles.mainOptions}>
            <CallIconButton
                handleCall={() => callContact()}
            />
        </div>
    )
}

export const HuntGroupMain: React.FC = () => {
    const contact = useContactContext().contact as IHuntGroup;
    const allUsers = useTypedSelector(selectUsersDictionary);

    const {t} = usePolyglot();

    const huntGroupMembers = useMemo(() => {
        const res: IUser[] = [];

        contact.members.forEach((mbr) => {
            const c = allUsers[mbr];

            if (c) {
                res.push(c);
            }
        })

        res.sort((a, b) => {
            const nameA = a.nickname.toUpperCase();
            const nameB = b.nickname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        return res;
    }, []);


    return (
        <>
            <div className={styles.item}>
                <label>
                    {t("terms.extension", 1)}
                </label>
                <div>
                    <p>
                        {contact.extension_number}
                    </p>
                </div>
            </div>
            <div className={styles.item}>
                <label>
                    {t("terms.contact", 2)}
                </label>
                <div className={styles.contacts}>
                    {huntGroupMembers.map(usr => (
                        <ContactProvider value={{contact: usr}}>
                            <Contact
                                key={usr.uuid}
                                icon={<ContactIcon/>}
                                options={<ContactOptions/>}
                            />
                        </ContactProvider>
                    ))}
                </div>
            </div>
        </>
    )
}