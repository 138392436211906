import {createContext, useContext, useState} from "react";
import {polyglot, setPolyglotLocale} from "../../lib/polyglot.lib";
import {setMomentLocale} from "../../lib/moment.lib";
import {phrasesEn} from "./phrases-en";

export type AppSupportedLocale = 'en-gb' | 'pl' | 'fr' | 'es'

interface IPolyglotProviderProps {
    children: JSX.Element
}

const PolyglotContext = createContext<any | null>(null);

const PolyglotProvider: React.FC<IPolyglotProviderProps> = ({ children }) => {
    const [currentLocale, setCurrentLocale] = useState<AppSupportedLocale>(polyglot.locale() as AppSupportedLocale);

    const onLocaleChange = (locale) => {
        setPolyglotLocale(locale);
        setCurrentLocale(locale);
        localStorage.setItem("locale", locale)
        setMomentLocale(locale)
    }

    return (
        <PolyglotContext.Provider value={{
            polyglot,
            currentLocale,
            onLocalChange: (locale) => onLocaleChange(locale)
        }}>
            {children}
        </PolyglotContext.Provider>
    )
}

export type Phrases =
    'phrases' | `phrases.${keyof typeof phrasesEn.phrases}`
    | 'terms' | `terms.${keyof typeof phrasesEn.terms}`
    | 'adjective' | `adjective.${keyof typeof phrasesEn.adjective}`
    | 'actions' | `actions.${keyof typeof phrasesEn.actions}`
    | 'placeholders' | `placeholders.${keyof typeof phrasesEn.placeholders}`
    | 'adverbs' | `adverbs.${keyof typeof phrasesEn.adverbs}`
    | 'abbreviations' | `abbreviations.${keyof typeof phrasesEn.abbreviations}`
    | 'conjunctions' | `conjunctions.${keyof typeof phrasesEn.conjunctions}`
    | 'pronouns' | `pronouns.${keyof typeof phrasesEn.pronouns}`
    | 'prepositions' | `prepositions.${keyof typeof phrasesEn.prepositions}`
    ;

const usePolyglot = () => {
    const context = useContext(PolyglotContext);

    return {
        t: (phrase: Phrases, args?) => context?.polyglot.t(phrase, args),
        currentLocale: context?.currentLocale,
        onLocalChange: context?.onLocalChange
    }
}
export { PolyglotProvider, usePolyglot };
