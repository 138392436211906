import StyledSkeleton from "../StyledSkeleton";
import { useTypedSelector } from "../../../redux/hooks";


export const ChatBodySkeleton = () => {
    const compactView = useTypedSelector(state => state.user.settings.chat.settings.style) === "minimal";

    const middle = {
        right: '0',
        transform: 'translateX(50%)',
    }

    if (compactView) {
        return (
            <div style={{ position: 'relative', minHeight: 840 }} className="skeletons_chat_body">
                <StyledSkeleton name="Rectangle 344" style={{ top: 4, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 8, left: 61.015625, width: 98.9765625, height: 12.15625 }} />
                <StyledSkeleton name="09:03" style={{ top: 10.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="This is a message with an emoji reaction" style={{ top: 32, left: 60.390625, width: 287.6875, height: 15.4140625 }} />
                <StyledSkeleton name="Thumbs Up" style={{ top: 54, left: 72, width: 18, height: 18 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 92, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 96, left: 60.328125, width: 99.8046875, height: 15.4140625 }} />
                <StyledSkeleton name="09:03" style={{ top: 98.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="A short message in the compact chat view should look like this." style={{ top: 120, left: 60.2265625, width: 449.2578125, height: 15.3359375 }} />
                <StyledSkeleton name="This is how a follow up message will look like" style={{ top: 149.8359375, left: 60.390625, width: 320.9140625, height: 15.5 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 184, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 188, left: 60.328125, width: 99.8046875, height: 15.4140625 }} />
                <StyledSkeleton name="09:03" style={{ top: 190.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="Nik Zahariev" style={{ top: 219.9921875, left: 81.015625, width: 89.4609375, height: 12.1640625 }} />
                <StyledSkeleton name="Hey! Coffee was great! See you on Thursday at 10pm! Hey! Coffee was great! See you on Thursday at 10pm!" style={{ top: 244.8359375, left: 80.390625, width: 624.03125, height: 37.578125 }} />
                <StyledSkeleton name="A thread message in the compact chat view should look like this" style={{ top: 299, left: 60.2265625, width: 453.8203125, height: 15.3359375 }} />
                <StyledSkeleton name="Thumbs Up" style={{ top: 325, left: 72, width: 18, height: 18 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 363, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 367, left: 61.015625, width: 98.9765625, height: 12.15625 }} />
                <StyledSkeleton name="09:03" style={{ top: 369.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="This is a message with an emoji reaction" style={{ top: 391, left: 60.390625, width: 287.6875, height: 15.4140625 }} />
                <StyledSkeleton name="Thumbs Up" style={{ top: 413, left: 72, width: 18, height: 18 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 451, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 455, left: 61.015625, width: 76.6875, height: 12.15625 }} />
                <StyledSkeleton name="09:03" style={{ top: 457.3515625, left: 147.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="Please check this attachment" style={{ top: 479, left: 61.3203125, width: 207.96875, height: 12.15625 }} />
                <StyledSkeleton name="document" style={{ top: 513, left: 76, width: 28, height: 28 }} />
                <StyledSkeleton name="PriceList_Jan23.paint" style={{ top: 514.46875, left: 119.3203125, width: 151.875, height: 14.78125 }} />
                <StyledSkeleton name="410MB" style={{ top: 533.3515625, left: 118.310546875, width: 37.212890625, height: 8.765625 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 573, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 577, left: 61.015625, width: 98.9765625, height: 12.15625 }} />
                <StyledSkeleton name="09:03" style={{ top: 579.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="This is a message with an emoji reaction" style={{ top: 601, left: 60.390625, width: 287.6875, height: 15.4140625 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 635, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 639, left: 60.328125, width: 99.8046875, height: 15.4140625 }} />
                <StyledSkeleton name="09:03" style={{ top: 641.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="Nik Zahariev" style={{ top: 676.9921875, left: 82.015625, width: 89.4609375, height: 12.1640625 }} />
                <StyledSkeleton name="Hey! Coffee was great! See you on Thursday at 10pm! Hey! Coffee was great! See you on Thursday at 10pm!" style={{ top: 701.8359375, left: 80.9375, width: 412.515625, height: 37.578125 }} />
                <StyledSkeleton name="This is how a reply looks" style={{ top: 762, left: 60.390625, width: 172.71875, height: 15.4140625 }} />
                <StyledSkeleton name="Rectangle 344" style={{ top: 796, left: 12, width: 40, height: 40 }} />
                <StyledSkeleton name="Johnny Jones" style={{ top: 800, left: 60.328125, width: 99.8046875, height: 15.4140625 }} />
                <StyledSkeleton name="09:03" style={{ top: 802.3515625, left: 169.88671875, width: 29.16796875, height: 8.765625 }} />
                <StyledSkeleton name="A short message in the compact chat view should look like this." style={{ top: 824, left: 60.2265625, width: 449.2578125, height: 15.3359375 }} />
            </div>
        )
    }

    return (
        <div style={{ position: 'relative', minHeight: 1080 }}>
            <StyledSkeleton
                name="Message Left"
                style={{ top: 33.8359375, left: 0, width: 376.6640625, height: 15.578125 }} />
            <StyledSkeleton
                name="Message Right"
                style={{ top: 123.8359375, right: 0, width: 464.125, height: 59.578125 }} />

            <StyledSkeleton
                name="Date Divider"
                style={{ top: 215.3515625, ...middle, width: 252.85546875, height: 8.765625 }} />

            <StyledSkeleton
                name="Message Left"
                style={{ top: 261.8359375, left: 0, width: 465.109375, height: 81.578125 }} />
            <StyledSkeleton
                name="Message Right"
                style={{ top: 417.8359375, right: 0, width: 464.125, height: 59.578125 }} />
            <StyledSkeleton
                name="Message Left"
                style={{ top: 538.8359375, left: 0, width: 376.6640625, height: 15.578125 }} />
            <StyledSkeleton
                name="Message Right"
                style={{ top: 628.8359375, right: 0, width: 464.125, height: 59.578125 }} />
            <StyledSkeleton
                name="Message Left"
                style={{ top: 762.8359375, left: 0, width: 461.140625, height: 59.578125 }} />
            <StyledSkeleton
                name="Message Right"
                style={{ top: 896.8359375, right: 0, width: 376.6640625, height: 15.578125 }} />
            <StyledSkeleton
                name="Message Left"
                style={{ top: 986.8359375, left: 0, width: 465.109375, height: 81.578125 }} />
        </div>
    )
}

export default ChatBodySkeleton;
