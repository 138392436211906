import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IUserStatus } from '../../../types';
import { CallDebounce, StatusStatus } from './slice';

export const selectUserStatuses = createSelector(
    [(state: RootState) => state.status.userStatuses],
    (statuses: Record<string, IUserStatus>) => statuses
);

export const selectCallWhenAvailableList = (state: RootState) => state.status.callWhenAvailable;

export const selectCallDebounce = (state: RootState): CallDebounce | undefined =>
    state.status.callDebounce;

export const selectPostCallFetcher = (state: RootState): number | undefined =>
    state.status.postCallFetcher;

export const selectUserStatus = createSelector(
    [selectUserStatuses, (_: RootState, userName: string | undefined) => userName],
    (userStatuses, userName: string | undefined): IUserStatus | undefined =>
        userName ? userStatuses[userName?.toLowerCase()] : undefined
);

export const selectUserAuxCode = createSelector(
    [selectUserStatus],
    (userStatus: IUserStatus | undefined): string | undefined => userStatus?.aux_code?.code
);

export const selectUserGlobalDnd = createSelector(
    [selectUserStatus],
    (userStatus: IUserStatus | undefined): boolean => userStatus?.global_dnd || false
);

export const selectQueueAvailability = createSelector(
    [selectUserStatus],
    (userStatus: IUserStatus | undefined): boolean => userStatus?.queue_available || false
);

export const selectStatusApi = (state: RootState): StatusStatus => state.status.userStatusAPI;

export const selectNewFetchUsers = createSelector(
    [(state: RootState): string[] => state.status.newFetchUsers],
    (fetchUsers?: string[]) => fetchUsers || []
);

export const selectIsUserNewFetch = createSelector(
    [selectNewFetchUsers, (_: any, user: string) => user],
    (fetchUsers: string[], user: string): boolean => fetchUsers.includes(user)
);
