import React from 'react';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    selectCurrentUserId, selectCurrentUserNickname,
    setActiveRoomId,
    setVideoPage
} from "../../../redux/slices";
import styles from '../styles.module.scss';
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import {StyledButton} from "../../../components/StyledComponents";
import Icon from "../../../components/Icon";
import {MeetingParticipant} from "../MeetingConfig/MeetingConfigParticipants";
import StyledCheckbox from "../../../components/StyledComponents/StyledCheckbox";
import CopyBtn from "./CopyBtn";
import {getVideoBaseUrl, joinMeetingId} from "../helpers";
import {useMeetingPreviewData} from "../../../components/sidebars/Video/children/hooks";

interface Props {
}

const MeetingPreview: React.FC<Props> = () => {
    const {
        meeting,
        handleDeleteClick,
        deleteMeetingLoading,
        deletionConfirm,
        sortedParticipants
    } = useMeetingPreviewData();

    const currentUserUuid = useTypedSelector(selectCurrentUserId);
    const currentUserNickname = useTypedSelector(selectCurrentUserNickname);

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    if (!meeting) {
        dispatch(setVideoPage('meeting-home'))
        dispatch(setActiveRoomId(null))
        return null;
    }

    const baseUrl = getVideoBaseUrl();

    return (
        <div className={styles.meeting_preview}>
            <MenuHeader
                title={meeting.name}
                titleOptions={(
                    <div className='menu-header__icon-container'>
                        <StyledButton
                            onClick={() => {
                                dispatch(setVideoPage('meeting-home'))
                                dispatch(setActiveRoomId(null))
                            }}
                            buttonStyle='text'
                            iconOnly
                            size='small'
                        >
                            <Icon name='cross'/>
                        </StyledButton>
                    </div>
                )}
            />
            <div className={styles.preview_data}>
                <div>
                    <section>
                        <p>Meeting Name</p>
                        <span>{meeting.name}</span>
                    </section>
                    <section>
                        <p>Meeting ID</p>
                        <div className={styles.meeting_url}>
                            <span>{`${baseUrl}/${meeting.custom_url || ''}`}</span>
                            <CopyBtn text={`${baseUrl}/${meeting.custom_url || ''}`}/>
                        </div>
                    </section>
                    {meeting.description ? (
                        <section>
                            <p>Description</p>
                            <span>{meeting.description}</span>
                        </section>
                    ) : null}
                    {meeting.password ? (
                        <section>
                            <p>Password</p>
                            <span>{meeting.password}</span>
                        </section>
                    ) : null}
                    <section className={styles.invite_preview}>
                        <div>
                            <p>Meeting Invitation</p>
                            <CopyBtn
                                text={(
                                    `${t("phrases.%name_is_inviting_you_to_a_meeting_room", {name: currentUserNickname || ''})} \n` +
                                    `Join '${meeting.name || ''}' \n` +
                                    `${baseUrl}/${meeting.custom_url || ''} \n` +
                                    `${t("terms.meeting_id")}: ${meeting.custom_url || ''}` +
                                    `${meeting.password ? ` \n${t("terms.password")}: ${meeting.password}` : ''}`
                                )}
                            />
                        </div>
                        <section>
                            <p>{t("phrases.%name_is_inviting_you_to_a_meeting_room", {name: currentUserNickname || ''})}</p>
                            <p>{`Join '${meeting.name || ''}'`}</p>
                            <p className={styles.url}>{`${baseUrl}/${meeting.custom_url || ''}`}</p>
                            <p>{`${t("terms.meeting_id")}: ${meeting.custom_url || ''}`}</p>
                            <p>{`${meeting.password ? `${t("terms.password")}: ${meeting.password}` : ''}`}</p>
                        </section>
                    </section>
                </div>
                <div>
                    <section>
                        <p>Participants</p>
                        <ul>
                            {sortedParticipants.map(mem => (
                                <MeetingParticipant
                                    key={mem.user_id}
                                    uuid={mem.user_id}
                                    admin={mem.admin || false}
                                />
                            ))}
                        </ul>
                    </section>
                    <section className={styles.advanced_settings}>
                        <p>Advanced Settings</p>
                        <ul>
                            <StyledCheckbox
                                inputProps={{
                                    value: meeting.is_open as any,
                                    checked: meeting.is_open,
                                    readOnly: true
                                }}
                                label='Is room open'
                            />
                            <StyledCheckbox
                                inputProps={{
                                    value: meeting.force_waiting_room as any,
                                    checked: meeting.force_waiting_room,
                                    readOnly: true
                                }}
                                label='Force waiting room'
                            />
                            <StyledCheckbox
                                inputProps={{
                                    value: meeting.show_to_all as any,
                                    checked: meeting.show_to_all,
                                    readOnly: true
                                }}
                                label='Show to all'
                            />
                            <StyledCheckbox
                                inputProps={{
                                    value: meeting.mute_on_entry as any,
                                    checked: meeting.mute_on_entry,
                                    readOnly: true
                                }}
                                label='Mute participants on entry'
                            />
                            <StyledCheckbox
                                inputProps={{
                                    value: meeting.allow_muting as any,
                                    checked: meeting.allow_muting,
                                    readOnly: true
                                }}
                                label='Allow admins to mute others'
                            />
                        </ul>
                    </section>
                </div>
                <div
                    className={styles.controls}
                    id='meeting_controls'
                >
                    {meeting.members.some(mem => mem.admin && mem.user_id === currentUserUuid) ? (<>
                        <StyledButton
                            buttonStyle='text'
                            context="danger"
                            onClick={() => handleDeleteClick()}
                            loading={deleteMeetingLoading}
                        >
                            <p>
                                {deletionConfirm ? 'Are You Sure?' : 'Delete'}
                            </p>
                        </StyledButton>
                        <StyledButton
                            buttonStyle='tertiary'
                            onClick={() => {
                                dispatch(setVideoPage('meeting-edit'))
                            }}
                            size='large'
                        >
                            <p>
                                Edit
                            </p>
                        </StyledButton>
                    </>) : null}
                    <StyledButton
                        buttonStyle='primary'
                        size='large'
                        onClick={() => {
                            joinMeetingId(meeting.custom_url)
                        }}
                    >
                        <p>
                            Join Meeting
                        </p>
                    </StyledButton>
                </div>
                <div/>
            </div>
        </div>
    );
};

export default MeetingPreview;
