import {IChatMessage} from "../../types";

export interface ChatContextState {
    filesToUpload: File[];
    expandMessage?: IChatMessage;
    sidebarVisible: boolean;
    messagesToForward: string[];
    isScrolled: boolean;
}

export const initialState: ChatContextState = {
    filesToUpload: [],
    expandMessage: undefined,
    sidebarVisible: false,
    messagesToForward: [],
    isScrolled: false,
}

export type ChatReducerAction =
    {
        type: 'add_file_to_upload',
        payload?: FileList | File[]
    } |
    {
        type: 'reset_files'
    } |
    {
        type: 'set_message_details',
        payload?: IChatMessage
    } |
    {
        type: 'set_sidebar_visible',
        payload: boolean
    } |
    {
        type: 'set_files',
        payload?: File[]
    } |
    {
        type: 'add_message_to_forward',
        payload: string;
    } |
    {
        type: 'delete_message_to_forward',
        payload: string
    } |
    {
        type: 'set_is_scrolled',
        payload: boolean
    }

const handleFileFiltering = (file: File) => file.size < 64 * 1000 * 1000;

export const chatReducer = (state: ChatContextState, action: ChatReducerAction): ChatContextState => {

    switch (action.type) {
        case "add_file_to_upload":

            if (action.payload) {
                const filteredFiles = Object.values(action.payload).filter(handleFileFiltering);

                return {
                    ...state,
                    filesToUpload: [...state.filesToUpload, ...filteredFiles],
                }
            }
            return state
        case "reset_files":
            return {
                ...state,
                filesToUpload: [],
            }
        case "set_message_details":
            return {
                ...state,
                expandMessage: action.payload
            }
        case "set_sidebar_visible":
            return {
                ...state,
                sidebarVisible: action.payload
            }
        case "set_files":
            return {
                ...state,
                filesToUpload: action.payload || [],
            }
        case "add_message_to_forward":
            return {
                ...state,
                messagesToForward: [...state.messagesToForward, action.payload]
            }
        case "delete_message_to_forward":
            return {
                ...state,
                messagesToForward: [...(state.messagesToForward).filter(message => message !== action.payload)]
            }
        case "set_is_scrolled":
            return {
                ...state,
                isScrolled: action.payload
            }
        default:
            return state
    }
}
