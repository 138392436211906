import moment from 'moment/moment';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ChunkType, IChat, IChatMessage, IChatParticipant, ILoggedInUser } from '../../../types';
import { chatsAdapter } from './slice';

export const chatEntitySelectors = chatsAdapter.getSelectors();

const selectCurrentUser = (state: RootState): ILoggedInUser => state.user;

export const selectFailedMessages = (state: RootState) => state.chat.failedMessagesQueue;

export const selectChatEntities = (state: RootState): EntityState<IChat> =>
    state.chat.chats.entities;

export const selectExternalReplies = (state: RootState): IChatMessage[] =>
    state.chat.externalReplies;

export const selectGroupCreationActive = (state: RootState): boolean =>
    state.chat.groupCreationActive;

export const selectActiveChatUuid = (state: RootState): string | undefined => state.chat.activeChat;

export const selectTypingStatusesById = (state: RootState, chatId): any[] | undefined =>
    state.chat.typingStatuses[chatId];

export const selectLastUpdatedChatId = (state: RootState): string | undefined =>
    state.chat.lastUpdatedChatId;

export const selectFocusAttachment = (state: RootState) => state.chat.focusAttachment;

//
// export const selectMessageSearchResult = createSelector([selectSearchResult, selectAuthUsers],
//     (messages: IChatMessage[], users: IUser[] | undefined): IChatMessage[] | undefined =>
//         // filter for messages only from existing users
//         messages?.filter(message => users?.find(user => user.uuid === message.from))
//             .sort((a, b) => moment(b.time).unix() - moment(a.time).unix())
// )

const selectSearchResult = createSelector(
    [(state: RootState) => state.chat.searchResults],
    (searchResults): Record<string, IChatMessage[] | undefined> => searchResults
);

const selectInProgressTextInputs = createSelector(
    [state => state.chat.inProgressTextInputs],
    (chats): Record<string, string> | undefined => chats
);

export const selectChaIsMessageProgress = createSelector(
    [selectActiveChatUuid, selectInProgressTextInputs, (_: RootState, chatId: string) => chatId],
    (activeChat, progressChats, chatId) => {
        if (activeChat === chatId) return false;
        return !!progressChats?.[chatId];
    }
);

export const selectMessageSearchResult = createSelector(
    [selectSearchResult, (_: RootState, chatId: string) => chatId],
    (allResults, chatId): IChatMessage[] | undefined => {
        if (!allResults) return undefined;

        const chatResults = allResults[chatId];

        if (!chatResults) return undefined;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return chatResults.toSorted((a, b) => moment(b.time).unix() - moment(a.time).unix());
    }
);

export const selectExternalReplyById = createSelector(
    [selectExternalReplies, (_: RootState, message_uuid: string | undefined) => message_uuid],
    (
        externalReplies: IChatMessage[] | undefined,
        message_uuid: string | undefined
    ): IChatMessage | undefined => {
        if (!externalReplies || !message_uuid) return undefined;

        const foundRely = externalReplies.find(
            externalMessage => externalMessage.uuid === message_uuid
        );

        return foundRely || undefined;
    }
);

export const selectAllChatEntries = (state: RootState): IChat[] =>
    chatEntitySelectors.selectAll(state.chat.chats);

export const selectChatById = (state: RootState, chat_id: string | undefined): IChat | undefined =>
    chat_id ? chatEntitySelectors.selectById(state.chat.chats, chat_id) : undefined;

// export const selectSourceMembersById = (
//     state: RootState,
//     chat_id: string | undefined
// ): IChatMember[] | undefined =>
//     chat_id
//         ? chatEntitySelectors.selectById(state.chat.chats, chat_id)?.channel?.members
//         : undefined;

export const selectChatParticipants = createSelector(
    [selectChatById, selectCurrentUser],
    (currentChat: IChat | undefined, currentUser: any): IChatParticipant[] => {
        let participants: IChatParticipant[] = [];

        switch (currentChat?.type) {
            case 'user':
            case 'integration':
                participants = [
                    {
                        uuid: currentChat.uuid,
                        display_name: currentChat.display_name || 'Unknown'
                    },
                    {
                        uuid: currentUser.uuid,
                        display_name: currentUser.nickname
                    }
                ];

                break;

            case 'channel':
                participants =
                    currentChat.channel?.members.map(member => ({
                        display_name: member.display_name,
                        uuid: member.member
                    })) || [];

                break;

            // no participants available for threads
            // case 'thread':
        }

        return participants.sort((a: IChatParticipant, b: IChatParticipant) =>
            (a.display_name || '').localeCompare(b.display_name || '')
        );
    }
);

export const selectChatActiveChunk = createSelector(
    [selectChatById],
    (chat: IChat | undefined): ChunkType | undefined => chat?.active_chunk
);

// export const selectChatMessages = createSelector([selectChatById, selectChatActiveChunk],
//     (chat: IChat | undefined, activeChunk: string | undefined): IChatMessage[] | undefined =>
//         chat ? messagesEntitySelectors.selectAll(chat.messages).filter(m => (m.chunk === activeChunk)) : undefined
// )

// export const selectChatActiveChunkOldestMessage = createSelector([selectChatMessages],
//     (chunkMessages: any | undefined): IChatMessage | undefined =>
//         chunkMessages?.[(chunkMessages?.length || 1) - 1]
// )

// export const selectChatActiveChunkNewestMessage = createSelector([selectChatMessages],
//     (chunkMessages: any | undefined): IChatMessage | undefined => {
//         if (!chunkMessages?.[0]?.chunk && chunkMessages?.[1]?.chunk) {
//             return chunkMessages[1]
//         }
//         return chunkMessages[0]
//
//     }
// )

export const selectChatsOnly = createSelector([selectAllChatEntries], (chats: IChat[]): IChat[] =>
    chats.filter(chat => ['user', 'channel', 'integration'].includes(chat.type))
);
