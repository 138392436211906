
export const MoodEmojiHappy: React.FC<any> = ({
                                                    ariaHidden,
                                                    title,
                                                }) => (
    <svg
        width={45}
        height={45}
        viewBox="0 0 45 45"
        fill="none"
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">About</title>}
        <desc id="desc">Happy</desc>
            <path d="M22.5045 38.8133C31.718 38.8133 39.1872 31.3442 39.1872 22.1307C39.1872 12.9171 31.718 5.448 22.5045 5.448C13.2909 5.448 5.82183 12.9171 5.82183 22.1307C5.82183 31.3442 13.2909 38.8133 22.5045 38.8133Z" fill="url(#paint0_radial_4_380)"/>
            <path opacity="0.5" d="M22.5045 38.8133C31.718 38.8133 39.1872 31.3442 39.1872 22.1307C39.1872 12.9171 31.718 5.448 22.5045 5.448C13.2909 5.448 5.82183 12.9171 5.82183 22.1307C5.82183 31.3442 13.2909 38.8133 22.5045 38.8133Z" fill="url(#paint1_radial_4_380)"/>
            <path d="M18.8343 13.4869C18.8343 12.8196 18.1148 12.4026 16.8636 12.4026C15.8106 12.4026 13.4854 12.9864 11.8901 15.0405C11.5982 15.4158 12.0465 15.6244 12.3385 15.3846C13.3186 14.5504 15.9565 13.4765 18.0106 13.7059C18.8343 13.7893 18.8343 13.4869 18.8343 13.4869Z" fill="url(#paint2_linear_4_380)"/>
            <path d="M17.0514 21.5885C17.9382 21.5885 18.6571 20.5008 18.6571 19.1591C18.6571 17.8174 17.9382 16.7297 17.0514 16.7297C16.1646 16.7297 15.4457 17.8174 15.4457 19.1591C15.4457 20.5008 16.1646 21.5885 17.0514 21.5885Z" fill="url(#paint3_radial_4_380)"/>
            <path d="M17.0514 17.5532C17.7396 17.5532 18.3443 18.158 18.6571 19.0442C18.6363 17.7305 17.9272 16.6774 17.0514 16.6774C16.1756 16.6774 15.4666 17.7305 15.4457 19.0442C15.7585 18.158 16.3633 17.5532 17.0514 17.5532Z" fill="url(#paint4_linear_4_380)"/>
            <path d="M26.1746 13.4869C26.1746 12.8196 26.8941 12.4026 28.1453 12.4026C29.1984 12.4026 31.5235 12.9864 33.1188 15.0405C33.4108 15.4158 32.9624 15.6244 32.6705 15.3846C31.6904 14.5504 29.0524 13.4765 26.9984 13.7059C26.1642 13.7893 26.1746 13.4869 26.1746 13.4869Z" fill="url(#paint5_linear_4_380)"/>
            <path d="M29.8761 27.0102C29.7406 26.9894 29.5946 27.0311 29.5007 27.1249C27.7074 28.8036 24.9442 29.7107 22.8589 29.7107H22.5044H22.1499C20.0646 29.7107 17.3015 28.8036 15.5081 27.1249C15.4039 27.0311 15.2683 26.9894 15.1328 27.0102C14.9868 27.0415 14.9138 27.1979 14.9868 27.323C16.5195 29.9714 19.3452 31.6084 22.421 31.6084H22.5149H22.6087C25.6845 31.6084 28.5102 29.9714 30.0429 27.323C30.0951 27.1979 30.022 27.0415 29.8761 27.0102Z" fill="#643800"/>
            <path d="M27.9472 21.5885C28.834 21.5885 29.5529 20.5008 29.5529 19.1591C29.5529 17.8174 28.834 16.7297 27.9472 16.7297C27.0603 16.7297 26.3414 17.8174 26.3414 19.1591C26.3414 20.5008 27.0603 21.5885 27.9472 21.5885Z" fill="url(#paint6_radial_4_380)"/>
            <path d="M27.9472 17.5532C27.259 17.5532 26.6543 18.158 26.3414 19.0443C26.3623 17.7305 27.0714 16.6774 27.9472 16.6774C28.823 16.6774 29.532 17.7305 29.5529 19.0443C29.2401 18.158 28.6353 17.5532 27.9472 17.5532Z" fill="url(#paint7_linear_4_380)"/>
            <path d="M29.8761 27.0102C29.7405 26.9894 29.5946 27.0311 29.5007 27.1249C27.7074 28.8036 24.9442 29.7107 22.8589 29.7107H22.5044H22.1499C20.0646 29.7107 17.3015 28.8036 15.5081 27.1249C15.4039 27.0311 15.2683 26.9894 15.1328 27.0102C14.9868 27.0415 14.9138 27.1979 14.9868 27.323C14.9972 27.3543 15.0181 27.3751 15.0389 27.396C15.1849 27.3126 15.3413 27.2292 15.4977 27.4064C17.1973 29.3145 19.7205 30.4614 22.421 30.4614H22.5148H22.6087C25.33 30.4614 27.8637 29.3041 29.5633 27.3647C29.7196 27.1875 29.8656 27.2709 30.0012 27.3647C30.0116 27.3439 30.022 27.3335 30.0325 27.3126C30.0951 27.1979 30.022 27.0415 29.8761 27.0102Z" fill="url(#paint8_linear_4_380)"/>
            <defs>
                <radialGradient id="paint0_radial_4_380" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.218 15.3697) scale(20.1255 20.1255)">
                    <stop stopColor="#FFE030"/>
                    <stop offset="1" stopColor="#FFB92E"/>
                </radialGradient>
                <radialGradient id="paint1_radial_4_380" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.218 15.3697) scale(15.8336 15.8336)">
                    <stop stopColor="#FFEA5F"/>
                    <stop offset="1" stopColor="#FFBC47" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint2_linear_4_380" x1="15.3123" y1="14.6051" x2="15.3123" y2="12.8472" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint3_radial_4_380" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5719 19.228) rotate(73.8539) scale(2.35714 1.52503)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint4_linear_4_380" x1="17.0538" y1="16.7201" x2="17.0538" y2="18.9842" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
                <linearGradient id="paint5_linear_4_380" x1="29.6884" y1="14.6051" x2="29.6884" y2="12.8472" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint6_radial_4_380" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.4657 19.2284) rotate(73.8539) scale(2.35714 1.52503)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint7_linear_4_380" x1="27.947" y1="16.7202" x2="27.947" y2="18.9843" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
                <linearGradient id="paint8_linear_4_380" x1="22.5003" y1="28.1894" x2="22.5003" y2="30.68" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
            </defs>
        </svg>



        )

export default MoodEmojiHappy

