import React from 'react';
import styles from './styles.module.scss'
import AriaButton from "../../components/AriaComponents/AriaButton";
import Icon from "../../components/Icon";
import {setActiveRoomId, setVideoPage} from "../../redux/slices";
import {useTypedDispatch} from "../../redux/hooks";
import {isPreview} from "../../helpers";

interface Props {
}

const VideoPageHome: React.FC<Props> = () => {
    const dispatch = useTypedDispatch();

    return (
        <div className={styles.home}>
            <h2>Secure video collaboration</h2>
            <p>
                Start a video call in a click with Instant Meetings or setup a dedicated space for your team with
                Meeting Rooms.
            </p>
            <div>
                {!isPreview ? (
                    <AriaButton
                        disabled
                        title="Currently not working, only on preview"
                    >
                        <Icon name='plus'/>
                        <div>
                            <p>
                                Instant Meeting
                            </p>
                            <span>
                          Start and instant video meeting
                      </span>
                        </div>
                    </AriaButton>
                ) : null}
                <AriaButton
                    onClick={() => {
                        dispatch(setVideoPage('meeting-create'))
                        dispatch(setActiveRoomId(null))
                    }}
                >
                    <Icon name='link'/>
                    <div>
                        <p>
                            Create Meeting Room
                        </p>
                        <span>
                          Setup a dedicated meeting room
                      </span>
                    </div>
                </AriaButton>
            </div>
        </div>
    );
};

export default VideoPageHome;
