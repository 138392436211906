import React, {ReactNode, useMemo, useState} from 'react';
import styles from './styles.module.scss'
import DragContainer from "../../DragAndDrop/DragContainer";
import PhonebookIcon from "./children/PhonebookIcon";
import UserIcon from "./UserEntityIcon/UserIcon";
import MultiImageIcon from "./children/MultiImageIcon";
import {useTypedSelector} from "../../../redux/hooks";
import {selectCurrentUserId} from "../../../redux/slices";
import ImageOnlyIcon from "./children/ImageOnlyIcon";

type Props = ({
    type: 'user',
    details: UserIconProps
} | {
    type: 'phonebook',
    details: {
        uuid: string
    };
} | {
    type: 'hunt_group',
    details: HuntGroupIconProps;
} | {
    type: 'chat_group',
    details: ChatGroupIconProps;
}) & {
    allowDrag?: boolean;
    size?: number;
    onClick?: () => void;
}

export interface UserIconProps {
    sip_name?: string;
    display_name: string;
    avatar_key?: string;
    avatar_str?: string;
    blf?: boolean;
    uuid: string;
}

export interface HuntGroupIconProps {
    uuid: string;
    display_users: GropDisplayUser[]
}

export interface GropDisplayUser {
    avatar_key?: string;
    nickname: string;
    uuid: string;
}

export interface ChatGroupIconProps {
    uuid: string;
    display_users: GropDisplayUser[];
    avatar_set: boolean;
}


const EntityIcon: React.FC<Props> = ({
                                         type,
                                         details,
                                         allowDrag = true,
                                         size = 35,
                                         onClick
                                     }) => {
    const loggedInUserId = useTypedSelector(selectCurrentUserId);
    const [imageError, setImageError] = useState(false);

    const item: ReactNode = useMemo(() => {
        switch (type) {
            case "user":
                return <UserIcon
                    uuid={details.uuid}
                    avatar_str={details.avatar_str}
                    avatar_key={details.avatar_key}
                    sip_name={details.sip_name}
                    display_name={details.display_name}
                    blf={size > 24 && (details.blf || false)}
                />;
            case "phonebook":
                return <PhonebookIcon/>
            case "hunt_group":
                return <MultiImageIcon display_users={details.display_users}/>
            case "chat_group":
                if (details.avatar_set && !imageError) {
                    return (
                        <ImageOnlyIcon
                            setImageError={setImageError}
                            alt=""
                            avatar_str={`/api/get-channel-avatar?voip_user_uuid=${loggedInUserId}&channel_uuid=${details.uuid}`}
                        />
                    )
                }
                return <MultiImageIcon display_users={details.display_users}/>
            default:
                return <>ERR</>
        }
    }, [details, imageError]);

    return (
        <div
            className={styles.container}
            style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                "--_size": `${size}px`,
                "--_border-radius": `${size > 24 ? 6 : 4}px`
            }}
            onClick={(e) => {
                if (onClick) {
                    e.preventDefault()
                    e.stopPropagation()
                    onClick()
                }
            }}
            data-comp='entity-icon'
            data-can-click={!!onClick || null}
        >
            {allowDrag ? (
                <DragContainer id={details.uuid}>
                    {item}
                </DragContainer>
            ) : (
                <figure>
                    {item}
                </figure>
            )}
        </div>
    );
};

export default EntityIcon;
