import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../redux/hooks";
import {
    selectAllCalls,
    selectFavouriteContacts, selectInterTenantClient,
    selectUserStatus,
    selectViewContact, setEditPhonebookContact,
    setViewContact, updateFavouriteContact,
} from "../../redux/slices";
import ModalFormContainer from "../../components/forms/modalFormContainer/ModalFormContainer";
import styles from './syles.module.scss';
import {StyledButton} from "../../components/StyledComponents";
import Icon from "../../components/Icon";
import {ChatStatus} from "../../pages/chat/ChatHeader/ChatHeader";
import ContactProvider, {useContactContext} from "../../context/ContactContext/context";
import {HuntGroupMain, HuntGroupOptions, PhonebookContactMain, UserMain, UserOptions} from "./children";
import {usePolyglot} from "../../context/Polyglot";
import {useOnClickOutside, useSPrevious} from "../../helpers";
import TenantItems from "./children/TenantItems";

interface Props {
}

const ContactViewer: React.FC<Props> = () => {
    const viewContact = useTypedSelector(selectViewContact);
    const userStatus = useTypedSelector(state => selectUserStatus(state, (viewContact?.data as any)?.name));
    const favouriteContacts = useTypedSelector(selectFavouriteContacts);

    const calls = useTypedSelector(selectAllCalls) || [];

    const prevCallsLength = useSPrevious<number>(calls.length || 0) || 0;

    const {contact} = useContactContext();

    const [imageError, setImageError] = useState<boolean>(false);

    const {t} = usePolyglot();

    const containerRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch();

    useOnClickOutside(containerRef, () => {
        dispatch(setViewContact())
    })

    let name = '';
    let description: ReactNode;
    let avatar: ReactNode;
    let options: ReactNode;
    let component: ReactNode;

    useEffect(() => {
        if (calls.length > prevCallsLength && calls[calls.length - 1]?.isOutbound) {
            dispatch(setViewContact())
        }
    }, [calls.length]);

    switch (viewContact?.type) {
        case 'user':
            name = viewContact.data.nickname;
            description = userStatus?.chat ? (<ChatStatus chatStatus={userStatus?.chat}/>) : null
            avatar = viewContact.data.avatar_key ? (
                <div
                    className={styles.pfp}
                >
                    <img
                        alt={name}
                        src={`https://storage.googleapis.com/v-profiles/${viewContact.data.avatar_key}`}
                        onError={() => setImageError(true)}
                    />
                </div>
            ) : null;
            options = <UserOptions/>
            component = <UserMain/>
            break;
        case 'hunt_group':
            name = viewContact.data.name;
            description = (<p>Hunt Group</p>);
            options = (<HuntGroupOptions/>)
            component = (<HuntGroupMain/>)
            break;
        case 'phonebook_contact':
            name = [viewContact.data.first_name, viewContact.data.last_name].join(" ") || '';
            description = (<p>{viewContact.data.company_name}</p>);
            component = (<PhonebookContactMain/>);
    }

    return (
        <div
            className={styles.container_wrapper}
            ref={containerRef}
        >
            {!imageError ? avatar : null}
            <div className={styles.contact_container}>
                <div className={styles.header}>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        onClick={() => {
                            dispatch(setViewContact());
                        }}
                    >
                        <Icon name='arrowLeft'/>
                    </StyledButton>
                    <div className={styles.details}>
                        <p>
                            {name}
                        </p>
                        <span>
                            {description}
                        </span>
                    </div>
                    <div className={styles.opts}>
                        {viewContact?.type === 'phonebook_contact' ? (
                            <StyledButton
                                buttonStyle='text'
                                onClick={() => {
                                    // eslint-disable-next-line no-underscore-dangle
                                    if ((viewContact?.data as any)._details) {
                                        dispatch(setEditPhonebookContact({
                                            company_name: viewContact?.data.company_name,
                                            created_on: '',
                                            // eslint-disable-next-line no-underscore-dangle
                                            details: (viewContact?.data as any)._details,
                                            first_name: viewContact?.data.first_name || '',
                                            integrations: [],
                                            last_name: viewContact?.data.last_name || '',
                                            uuid: '',
                                            phone_book_uuid: '',
                                        }))
                                    } else {
                                        dispatch(setEditPhonebookContact(viewContact?.data))
                                    }
                                    dispatch(setViewContact())
                                }}
                                iconOnly
                            >
                                <Icon name='squareAndPencil'/>
                            </StyledButton>
                        ) : null}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        {!(viewContact?.data as any)._details ? (
                            <StyledButton
                                buttonStyle='text'
                                iconOnly
                                onClick={() => {

                                    dispatch(
                                        updateFavouriteContact(contact.uuid, !favouriteContacts?.[contact.uuid])
                                    )
                                }}
                                title={!favouriteContacts?.[contact.uuid] ? t("actions.add_to_favourites") : t("actions.remove_from_favourites")}
                                dataTags={{
                                    "data-is-fav": favouriteContacts?.[contact.uuid] || 'false'
                                }}
                            >
                                <Icon name='star'/>
                            </StyledButton>
                        ) : null}
                    </div>
                </div>
                <div className={styles.options}>
                    {options}
                </div>
                {component}
            </div>
        </div>
    );
};

const TenantViewer: React.FC<{ clientUuid: string }> = ({clientUuid}) => {

    const client = useTypedSelector(state => selectInterTenantClient(state, clientUuid))

    const containerRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch();

    useOnClickOutside(containerRef, () => {
        dispatch(setViewContact())
    })

    if (!client) {
        return null;
    }

    return (
        <div
            className={styles.container_wrapper}
            ref={containerRef}
        >
            {/*
            {!imageError ? avatar : null}
            */}
            <div className={styles.contact_container}>
                <div className={styles.header}>
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        onClick={() => {
                            dispatch(setViewContact());
                        }}
                    >
                        <Icon name='arrowLeft'/>
                    </StyledButton>
                    <div className={styles.details}>
                        <p>
                            {client.name}
                        </p>
                        {/*
                        <span>
                            {description}
                        </span>
                        */}
                    </div>
                    {/*
                    <div className={styles.opts}>
                        {viewContact?.type === 'phonebook_contact' ? (
                            <StyledButton
                                buttonStyle='text'
                                onClick={() => {
                                    // eslint-disable-next-line no-underscore-dangle
                                    if ((viewContact?.data as any)._details) {
                                        dispatch(setEditPhonebookContact({
                                            company_name: viewContact?.data.company_name,
                                            created_on: '',
                                            // eslint-disable-next-line no-underscore-dangle
                                            details: (viewContact?.data as any)._details,
                                            first_name: viewContact?.data.first_name || '',
                                            integrations: [],
                                            last_name: viewContact?.data.last_name || '',
                                            uuid: '',
                                            phone_book_uuid: '',
                                        }))
                                    } else {
                                        dispatch(setEditPhonebookContact(viewContact?.data))
                                    }
                                    dispatch(setViewContact())
                                }}
                                iconOnly
                            >
                                <Icon name='squareAndPencil'/>
                            </StyledButton>
                        ) : null}
                        {!(viewContact?.data as any)._details ? (
                            <StyledButton
                                buttonStyle='text'
                                iconOnly
                                onClick={() => {

                                    dispatch(
                                        updateFavouriteContact(contact.uuid, !favouriteContacts?.[contact.uuid])
                                    )
                                }}
                                title={!favouriteContacts?.[contact.uuid] ? t("actions.add_to_favourites") : t("actions.remove_from_favourites")}
                                dataTags={{
                                    "data-is-fav": favouriteContacts?.[contact.uuid] || 'false'
                                }}
                            >
                                <Icon name='star'/>
                            </StyledButton>
                        ) : null}
                    </div>
                    */}
                </div>
                {/*
                <div className={styles.options}>
                    {options}
                </div>
                {component}
                */}
                <TenantItems client={client}/>
            </div>
        </div>
    )
}

const ContactViewerWrapper: React.FC = () => {
    const viewContact = useTypedSelector(selectViewContact);

    const dispatch = useTypedDispatch();

    return (
        <ModalFormContainer
            showModal={!!viewContact}
            setShowModal={() => {
                dispatch(setViewContact())
            }}
        >
            {viewContact && viewContact.type !== 'tenant' ? (
                <ContactProvider
                    key={viewContact.data.uuid}
                    value={{contact: viewContact.data}}
                >
                    <ContactViewer
                        key={viewContact.data.uuid}
                    />
                </ContactProvider>
            ) : null}
            {viewContact && viewContact.type === 'tenant' ? (
                <TenantViewer
                    clientUuid={viewContact.data}
                />
            ) : null}
        </ModalFormContainer>
    )
}

export default ContactViewerWrapper;
