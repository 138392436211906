import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUserStatus} from "../../../types";

interface IAuthStatus {
    name: string;
    token: string;
    user: string;
}

export type StatusStatus = 'fetching' | 'success' | 'error' | 'setup';

export type CallDebounceReason = "DND" | "Unavailable" | "Busy";

export type CallDebounce = {
    userUuid: string;
    reason: CallDebounceReason;
}

export interface IStatusesState {
    authStatus: IAuthStatus | null;
    userStatuses: Record<string, IUserStatus>;
    callWhenAvailable: CallWhenAvailableUser[];
    userStatusAPI: StatusStatus;
    newFetchUsers: string[];
    callDebounce?: CallDebounce;
    postCallFetcher?: number;
}

export interface CallWhenAvailableUser {
    sip_name: string,
    user_uuid: string,
    extension: string,
    display_name: string,
}

const initialState: IStatusesState = {
    authStatus: null,
    userStatuses: {},
    callWhenAvailable: [],
    userStatusAPI: 'setup',
    newFetchUsers: [],
    callDebounce: undefined,
};

export const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setAuthStatus: (state, {payload}: PayloadAction<IAuthStatus>): IStatusesState => ({
            ...state,
            authStatus: payload
        }),
        setUserStatuses: (state, {payload}: PayloadAction<IUserStatus[]>): IStatusesState => ({
            ...state,
            userStatuses: {
                ...state.userStatuses,
                ...Object.fromEntries(payload.map(user => [user.sipuser, user]))
            }
        }),
        updateUserStatus: (state, {payload}: PayloadAction<IUserStatus>): IStatusesState => ({
            ...state,
            userStatuses: {
                ...state.userStatuses,
                [payload.sipuser]: payload
            }
        }),
        addCallWhenAvailable: (
            state,
            {payload}: PayloadAction<{ user: CallWhenAvailableUser; }>): IStatusesState => ({
                ...state,
                callWhenAvailable: [
                    ...state.callWhenAvailable,
                    payload.user
                ]
            }
        ),
        deleteCallWhenAvailable: (state, {payload}: PayloadAction<{ userId: string }>): IStatusesState => ({
            ...state,
            callWhenAvailable: [
                ...state.callWhenAvailable.filter(c => c.user_uuid !== payload.userId)
            ]
        }),
        setStatusApi: (state, {payload}: PayloadAction<StatusStatus>): IStatusesState => ({
            ...state,
            userStatusAPI: payload
        }),
        setNewFetchUsers: (state, {payload}: PayloadAction<string[]>): IStatusesState => ({
            ...state,
            newFetchUsers: payload
        }),
        setCallDebounce: (state, {payload}: PayloadAction<CallDebounce | undefined>): IStatusesState => ({
            ...state,
            callDebounce: payload
        }),
        setPostCallFetcher: (state, {payload}: PayloadAction<number | undefined>): IStatusesState => ({
            ...state,
            postCallFetcher: payload
        }),
    },
});

export const status = statusSlice.reducer;