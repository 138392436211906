import {useRef, useState} from "react";
import {ReactEditor, useSlateWithV} from "slate-react";
import Icon from "../../../../../components/Icon";
import styles from '../Toolbar.module.scss';
import {EmojiMenu} from "../../../../../components";
import {AnimatedDiv} from "../../../../../animation";
import {useOnClickOutside} from "../../../../../helpers";
import AriaButton from "../../../../../components/AriaComponents/AriaButton";
import usePlaceOver from "../../../../../helpers/placeAbove";

interface Props {
}

const ChatInputEmoji: React.FC<Props> = () => {
    const [emojiMenu, setEmojiMenu] = useState(false);

    const emojiContainerRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const {editor} = useSlateWithV();

    const handleEmoji = (emoji) => {
        editor.insertText(emoji.native)

        setTimeout(() => {
            ReactEditor.focus(editor);
        }, 0)
    }

    useOnClickOutside(emojiContainerRef, () => setEmojiMenu(false))

    usePlaceOver({
        relativeElement: containerRef,
        fixedElement: emojiContainerRef,
        display: emojiMenu
    })

    return (
        <div
            className={styles.emojiSection}
            ref={containerRef}
        >
            <AriaButton
                className={styles.toolbar_button}
                onClick={() => setEmojiMenu(!emojiMenu)}
                dataTags={{
                    'data-is-active': emojiMenu
                }}
            >
                <Icon name='emoji'/>
            </AriaButton>
            <AnimatedDiv
                visible={emojiMenu}
                className={styles.emoji_menu_container}
                ref={emojiContainerRef}
            >
                <EmojiMenu
                    handleEmoji={handleEmoji}
                    closeMenu={() => setEmojiMenu(false)}
                />
            </AnimatedDiv>
        </div>
    )
}

export default ChatInputEmoji
