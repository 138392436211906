import React, {ReactNode, useRef} from "react";
import {cancelDragAndDrop, updateDragAndDrop} from "../../redux/slices";
import {useTypedDispatch} from "../../redux/hooks";
import styles from './DragContainer.module.scss';

interface DragContainerProps {
    children: ReactNode,
    id: string,
    disabled?: boolean,
    extraData?: Record<string, any>;
}

export const DragContainer: React.FC<DragContainerProps> = ({children, id, extraData, disabled = false}) => {
    const draggableRef = useRef<any>(null);

    const dispatch = useTypedDispatch();

    const handleDragStart = () => {
        if (draggableRef?.current) draggableRef.current.style.opacity = '0';
        setTimeout(() => {
            if (draggableRef?.current) draggableRef.current.style.opacity = 'revert';
        }, 1)
        dispatch(
            updateDragAndDrop(id, extraData)
        );
        return false;
    }

    const handleDragStop = () => {
        dispatch(cancelDragAndDrop());
    }

    if (disabled) {
        return (
            <figure className={styles.drag_container}>
                {children}
            </figure>
        )
    }

    return (
        <figure
            ref={draggableRef}
            onDragStart={() => handleDragStart()}
            onDragEnd={() => handleDragStop()}
            draggable
            className={styles.drag_container}

        >
            {children}
        </figure>
    )
}

export default DragContainer;
