import React from 'react';
import MuiSlider from "@mui/material/Slider";
import Icon from "../../../../components/Icon";
import styles from './styles.module.scss'
import {StyledButton} from "../../../../components/StyledComponents";

interface Props {
    title: string;
    volume: number;
    onAudioTest: () => void;
    onChange: (val: number) => void
}

const SettingsVolume: React.FC<Props> = ({title, volume, onAudioTest, onChange}) => {
    const identifier = title.split(' ').join('_');

    return (
        <div className={styles.settings_volume}>
            <label
                htmlFor={identifier}
                id={identifier}
            >
                {title}
            </label>
            <div className={styles.volumeInput}>
                <p>{volume}%</p>
                <div className={styles.volumeInputRange}>
                    <MuiSlider
                        aria-label={identifier}
                        value={volume}
                        min={0}
                        max={100}
                        step={1}
                        aria-labelledby="Zoom"
                        onChange={(e, newVolume) => onChange(Number(newVolume))}
                        classes={{
                            thumb: styles.thumb,
                            active: styles.active,
                        }}
                        // valueLabelDisplay='auto'
                        style={{
                            color: 'var(--primary-color)'
                        }}
                        size='small'
                        name={identifier}
                    />
                </div>
                <StyledButton
                    buttonStyle='text'
                    iconOnly
                    onClick={onAudioTest}
                    size='medium'
                >
                    <Icon name='speakerWave'/>
                </StyledButton>
            </div>
        </div>
    );
};

export default SettingsVolume;
