/* eslint-disable no-nested-ternary */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {CountryCode, parsePhoneNumberFromString} from "libphonenumber-js";
import {IPhonebookContact, IYeOldeCall} from '../../../../types';
import CallHistoryRowDisplay from "./CallHistoryRowDisplay";
import {useCallHistoryBase} from "./hooks";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {usePolyglot} from "../../../../context/Polyglot";
import {
    addOneToast,
    findContactOnBook, isValidContactEntity,
    parseNumber, selectApiOnlyPhonebooks, selectIsFetchingPhonebooks,
    selectPhonebookEntityInArr, selectPhoneSettingByKey, selectShortCodes,
    selectUserCountryCode,
    setEditPhonebookContact, setViewContact,
    startCall
} from '../../../../redux/slices';
import Icon from "../../../../components/Icon";
import AriaButton from "../../../../components/AriaComponents/AriaButton";
import {useContactNamesContext} from "../../GetContactNamesContext";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const regionNamesInEnglish = new Intl.DisplayNames(['en'], {type: 'region'});

export const getNumbersArr = (callee: string | undefined, countryCode: CountryCode): string[] => {

    if (!callee) {
        return [];
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(callee, countryCode);
    if (!parsedPhoneNumber) {
        return [];
    }

    const nationalNumber = parsedPhoneNumber.nationalNumber as string;
    const internationalNumber = parsedPhoneNumber.number as string;

    return [`0${nationalNumber}`, internationalNumber, nationalNumber];
}

export const defaultContact = (label: string, callee: string): IPhonebookContact => ({
    company_name: '',
    created_on: '',
    details: [
        {
            type: 1,
            label,
            value: callee,
            primary: true,
            type_id: 1,
        }
    ],
    first_name: '',
    integrations: [],
    last_name: '',
    uuid: '',
    phone_book_uuid: '',
})

const ExternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({call}) => {
    const {
        duration,
        callee,
        callIcon,
        date,
        callType,
        displayNumber,
    } = useCallHistoryBase(call);

    const [contactLoading, setContactLoading] = useState(false);

    const {addNumberForReq} = useContactNamesContext();

    const currentAppLocale = useTypedSelector(state => state.user?.settings.phone.settings?.currentAppLocale);
    const countryCode = useTypedSelector(selectUserCountryCode);
    const shortcodes = useTypedSelector(selectShortCodes);
    const contactsLoading = useTypedSelector(selectIsFetchingPhonebooks);
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);
    const showPhonebookContactCompanyName = useTypedSelector(state => selectPhoneSettingByKey(state, 'showPhonebookContactCompanyName'));

    const contactEntity = useTypedSelector(state => selectPhonebookEntityInArr(state, getNumbersArr(callee, countryCode)))

    const contactCompanyName: string | undefined = showPhonebookContactCompanyName && contactEntity?.company_name || contactEntity?.details?.[0]?.company_name;

    const isShortCode: boolean = useMemo(() => {
        if (call.to && shortcodes && ((call.to.name === call.to.nickname && call.to.name === call.to.number) || call.to.type === 'shortcode')) {
            const code = shortcodes.find(c => c.short_code === call.to?.number.substring(0, 3))

            return !!code;
        }
        return false;
    }, []);

    const {t} = usePolyglot();

    const regionNamesRegional = useRef(new Intl.DisplayNames([currentAppLocale], {type: 'region'}));

    const dispatch = useTypedDispatch();

    useEffect(() => {
        regionNamesRegional.current = new Intl.DisplayNames([currentAppLocale], {type: 'region'});
    }, [currentAppLocale]);

    useEffect(() => {
        if (!contactEntity && !contactsLoading && apiPhonebooks.length > 0) {
            addNumberForReq(parseNumber(callee))
        }
    }, [contactsLoading, apiPhonebooks.length]);

    let callSubHeader = callType === 'Inbound' ? t("adjective.call_inbound") : t("adjective.call_outbound");
    let callLocationEn: string | undefined = '';
    let callLocationRegional: string | undefined = '';

    switch (callType) {
        case "Inbound":
            if (contactCompanyName) {
                callSubHeader = contactCompanyName;
                break;
            }
            if (!call?.from?.area_name && !call?.from?.country) {
                break
            }
            callLocationEn = regionNamesInEnglish.of(call?.from?.country ?? '')
            callLocationRegional = regionNamesRegional.current.of(call?.from?.country ?? '')

            if (callLocationEn === call?.from?.area_name || !call?.from?.area_name) {
                callSubHeader = callLocationRegional || callSubHeader
            } else {
                callSubHeader = `${call?.from?.area_name ? `${call?.from?.area_name}, ` : ''}${callLocationRegional}`
            }
            break;
        case "Outbound":
            if (contactCompanyName) {
                callSubHeader = contactCompanyName;
                break;
            }
            if (!call?.to?.area_name && !call?.to?.country) {
                break
            }
            callLocationEn = regionNamesInEnglish.of(call?.to?.country ?? '')
            callLocationRegional = regionNamesRegional.current.of(call?.to?.country ?? '')

            if (callLocationEn === call?.to?.area_name) {
                callSubHeader = callLocationRegional || callSubHeader
            } else {
                callSubHeader = `${call?.to?.area_name ? `${call.to.area_name}, ` : ''}${callLocationRegional}`
            }
            break;
    }

    const openContact = () => {
        if (!contactEntity) {
            setContactLoading(false)
            return;
        }

        const phonebookUuid = contactEntity.phonebookUuid || contactEntity.details[0]?.phonebook_uuid;
        const contactUuid = contactEntity.uuid || contactEntity.details[0]?.uuid;

        if (!phonebookUuid || !contactUuid) return;

        dispatch(findContactOnBook({
            phonebookUuid,
            contactUuid,
        }))
            .then(r => {
                if (!r) {
                    dispatch(addOneToast({
                        content: `Failed to find ${contactEntity.name || 'contact'} in contacts`,
                        title: "Failed to find contact",
                        type: "error"
                    }))
                }
                dispatch(setViewContact(r))
            })
            .finally(() => {
                setContactLoading(false)
            })
    }

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={callIcon}
            rowName={contactEntity?.name || callee}
            secondaryString={contactEntity?.name ? callee : undefined}
            subHeader={callSubHeader}
            date={date}
            duration={duration}
            options={!isShortCode ? (<>
                    <AriaButton
                        onClick={() => {
                            if (isValidContactEntity(contactEntity)) {
                                setContactLoading(true);
                                openContact();
                                return;
                            }
                            dispatch(
                                setEditPhonebookContact(
                                    defaultContact(t('phrases.label_main'), callee)
                                )
                            );
                        }}
                        title={
                            isValidContactEntity(contactEntity) ? t('actions.view_contact') : t('actions.add_contact', 1)
                        }
                        disabled={contactLoading}
                    >
                        {contactLoading ? <LoadingSpinner /> : null}
                        {!contactLoading && isValidContactEntity(contactEntity) ? (
                            <Icon name='accountFilled' width={22} height={22} />
                        ) : null}
                        {!contactLoading && !isValidContactEntity(contactEntity) ? (
                            <Icon name='addContact' width={22} height={22} />
                        ) : null}
                    </AriaButton>
                    <AriaButton
                        onClick={() => {
                            dispatch(startCall(displayNumber || callee, contactEntity?.name));
                        }}
                    >
                        <Icon name='phoneCall' width={22} height={22} />
                    </AriaButton>
                </>) : undefined}
        />
    );
};

export default ExternalCallHistoryRow;