import React, { useRef } from 'react';
import moment from 'moment/moment';
import { SessionState } from 'sip.js';
import Icon from '../../Icon';
import { Counter } from '../../counter';
import styles from '../Keypad.module.scss';
import { AnimatedDiv } from '../../../animation';
import { useCallContext } from '../../../context/CallContext/context';
import { useKeypadContext } from '../../../context/KeypadContext/context';
import ConferenceMember from './children/ConferenceMember';
import AriaButton from '../../AriaComponents/AriaButton';
import { useOnClickOutside } from '../../../helpers';
import ConferenceRingingMember from './children/ConferenceRingingMember';
import TypingLoader from '../../loading/TypingLoader/TypingLoader';

interface Props {}

const ParticipantsList = () => {
    const { call, membersTabOpen, useCallAction } = useCallContext();

    const displayMembers = call.roomMembers?.filter(rm => rm.active && rm.id) || [];
    const displayRingers = call.roomRingingMembers?.filter(rm => rm.active) || [];

    const container = useRef<HTMLDivElement>(null);

    useOnClickOutside(container, () => {
        useCallAction({
            type: 'set_member_tab_open',
            payload: false
        });
    });

    return (
        <div
            className={styles.participants}
            onBlur={e => {
                if (
                    e.relatedTarget &&
                    !e.currentTarget.contains(e.relatedTarget) &&
                    membersTabOpen
                ) {
                    useCallAction({
                        type: 'set_member_tab_open',
                        payload: false
                    });
                }
            }}
            ref={container}
        >
            <AriaButton
                onClick={() =>
                    useCallAction({
                        type: 'set_member_tab_open',
                        payload: !membersTabOpen
                    })
                }
            >
                <p>Participants ({displayMembers.length}){displayRingers.length > 0 ? (<><TypingLoader mini /> {displayRingers.length}</>) : null}</p>
                <Icon name={membersTabOpen ? 'arrowUp' : 'arrowDown'} />
            </AriaButton>
            {membersTabOpen ? (
                <ul>
                    {displayMembers.map(rm => (
                        <ConferenceMember key={`RM_${rm.id}`} roomMember={rm} />
                    ))}
                    {displayRingers.map(rm => (
                        <ConferenceRingingMember key={rm.call_id} data={rm} />
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export const GroupCall: React.FC<Props> = () => {
    const { call, currentTab } = useCallContext();
    const { keypadState } = useKeypadContext();

    const callInit = Math.min(
        moment().unix(),
        call.answeredTime ? call.answeredTime / 1000 : moment().unix()
    );

    return (
        <AnimatedDiv
            visible={call.roomId ? keypadState.keypadActiveStatus : false}
            className={styles.group_display}
            inAnimation={['fade']}
            transition={{ duration: 0.4, delay: 0.1 }}
        >
            <div className={styles.group_display_header}>
                <div>
                    <p>Conference Call</p>
                </div>
                <Counter initialTime={moment().unix() - callInit} />
            </div>
            {currentTab === 'options_menu' && call.state !== SessionState.Initial ? (
                <div className={styles.participant_list}>
                    <ParticipantsList />
                </div>
            ) : null}
        </AnimatedDiv>
    );
};
