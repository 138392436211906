import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import React, {RefObject, useEffect} from "react";
import {useTypedSelector} from "../../../redux/hooks";
import {selectCurrentUserId} from "../../../redux/slices";
import {
    usePutChannelAvatarMutation,
    usePutChannelNameMutation,
    usePutChannelUsersMutation
} from "../../../redux/services/chatApi";
import {blobToBase64, focusNext} from "../../../helpers";
import {useChatContext} from "../../../context/ChatContext/context";

export type LoadingType = 'loading' | 'error' | 'success' | undefined;

export const useChatGroupEditor = ({closeEditor}: { closeEditor: () => void; }) => {
    const userUuid = useTypedSelector(selectCurrentUserId);

    const {chat} = useChatContext();

    const ChatGroupEditorSchema = object({
        group_name: z.string().min(1, 'Group Name is Required'),
        users_config: z.object({
            adding: z.array(z.string()),
            removing: z.array(z.string()),
        }),
        group_icon: z.any().optional(),
    });

    const [putChannelName, nameApiData] = usePutChannelNameMutation();

    const [putChannelUsers, usersApiData] = usePutChannelUsersMutation();

    const [putChannelAvatar, avatarApiData] = usePutChannelAvatarMutation();

    type ChatGroupFormInput = TypeOf<typeof ChatGroupEditorSchema>;

    const formMethods = useForm<ChatGroupFormInput>({
        resolver: zodResolver(ChatGroupEditorSchema),
        defaultValues: {
            group_name: chat?.display_name || '',
            users_config: {
                adding: [],
                removing: [],
            }
        }
    });

    useEffect(() => {
        if (
            nameApiData.isLoading || nameApiData.isError
            || usersApiData.isLoading || usersApiData.isError
            || avatarApiData.isLoading || avatarApiData.isError
        ) {
            return
        }

        if (!nameApiData.isSuccess && !usersApiData.isSuccess && !avatarApiData.isSuccess) return

        closeEditor();

    }, [nameApiData.isSuccess, usersApiData.isSuccess, avatarApiData.isSuccess]);

    const editGroup = async (formData: ChatGroupFormInput) => {
        if (formData.group_icon !== undefined) {
            if (formData.group_icon) {
                blobToBase64(formData.group_icon)
                    .then((base64Img) => {
                        putChannelAvatar({
                            userUuid,
                            chatUuid: chat.uuid,
                            base64Img
                        })
                    })
            } else {
                putChannelAvatar({
                    userUuid,
                    chatUuid: chat.uuid,
                    base64Img: ''
                })
            }
        }

        if (formData.users_config.removing.length > 0 || formData.users_config.adding.length > 0) {
            putChannelUsers({
                voip_user_uuid: userUuid,
                channel_uuid: chat.uuid,
                changes: {
                    members_to_add: formData.users_config.adding || undefined,
                    members_to_remove: formData.users_config.removing || undefined,
                },
            })
        }

        if (chat?.display_name !== formData.group_name) {
            putChannelName({
                voip_user_uuid: userUuid,
                channel_uuid: chat.uuid,
                new_name: formData.group_name.trim() || ''
            })
        }
    }

    return {
        nameApiData,
        usersApiData,
        avatarApiData,
        formMethods,
        editGroup,
    }
}

export const handleMembersKeydown = ({
                                         showDropdown,
                                         setShowDropdown,
                                         inputRef,
                                     }: {
    showDropdown: boolean;
    setShowDropdown: (val: boolean) => void;
    inputRef: RefObject<HTMLInputElement>;
}) => {
    const handleArrowKeys = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {

        if (!showDropdown) {
            setShowDropdown(true)
        }

        focusNext({
            container: e.currentTarget,
            before,
        })
    }

    const ACTIONS = {
        ArrowRight: (e) => handleArrowKeys(e),
        ArrowLeft: (e) => handleArrowKeys(e, true),
        ArrowDown: (e) => handleArrowKeys(e),
        ArrowUp: (e) => handleArrowKeys(e, true),
        Escape: () => {
            if (showDropdown && inputRef.current) {
                setShowDropdown(false)
                inputRef.current.focus();
            }
        },
        Enter: (e) => e.target.click(),
        Space: (e) => e.target.click(),
    }

    return (e) => {
        if (
            e.target.nodeName === 'BUTTON'
            && e.key.length === 1
            && /[a-zA-Z$&+,:;=?@#|'<>.-^*()%!]/.test(e.key)
            && inputRef.current
        ) {
            inputRef.current.focus();
        }

        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
}