import React, {FunctionComponent, useMemo, useRef} from 'react';
import formStyles from "../../../sass/globalModules/forms.module.scss";
import {useOnClickOutside} from "../../../helpers";
import {StyledButton} from "../../StyledComponents";
import ModalFormContainer from "../modalFormContainer/ModalFormContainer";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    addCallWhenAvailable,
    CallDebounce,
    handleFindingATransfer,
    selectCallDebounce,
    selectUserOrClient,
    selectUserStatus,
    setCallDebounce,
    startCall
} from '../../../redux/slices';
import styles from './styles.module.scss'


interface Props {
}

const Modal: React.FC<CallDebounce> = ({userUuid, reason}) => {

    const user = useTypedSelector(state => selectUserOrClient(state, userUuid));

    const aTransferIsActive = useTypedSelector(handleFindingATransfer);
    const status = useTypedSelector(state => selectUserStatus(state, user?.sip_name));

    const dispatch = useTypedDispatch()


    const editorRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(editorRef, () => {
        dispatch(setCallDebounce(undefined))
    })

    const {
        title,
        data
    } = useMemo(() => {
        const displayName = user?.display_name || "User";
        const onCallWith = status?.status === "confirmed" && status.details?.remote ? ` with ${status.details?.remote}` : "";

        switch (reason) {
            case "DND":
                return {
                    title: `${displayName} is busy`,
                    data: `${displayName} has turned on 'Do Not Disturb' and will not receive any inbound calls.`,
                }
            case "Busy":
                return {
                    title: `${displayName} is unavailable`,
                    data: `${displayName} is currently on a call${onCallWith}.`,
                }
            default:
                return {
                    title: `${displayName} is unavailable`,
                    data: `${displayName} is currently unavailable and will not receive any inbound calls.`,
                }

        }
    }, [user, status]);

    if (!user) {
        dispatch(setCallDebounce(undefined))
        return null;
    }

    const pushCall = () => {
        dispatch(setCallDebounce(undefined))
        dispatch(startCall(user.extension, user.display_name));
    }

    return (
        <div
            className={[formStyles.formContainer, styles.container].join(' ')}
            ref={editorRef}
        >
            <div className={styles.data}>
                <h2>
                    {title}
                </h2>
                <p>
                    {data}
                </p>
            </div>
            <div className={[formStyles.formControls].join(' ')}>
                <StyledButton
                    buttonStyle='tertiary'
                    size='large'
                    onClick={() => {
                        dispatch(setCallDebounce(undefined))
                    }}
                >
                    <p>
                        Cancel
                    </p>
                </StyledButton>
                {aTransferIsActive ? (
                    <StyledButton
                        buttonStyle='primary'
                        size='large'
                        onClick={() => {
                            pushCall()
                            dispatch(setCallDebounce(undefined))
                        }}
                    >
                        <p>
                            Transfer Anyway
                        </p>
                    </StyledButton>
                ) : (<>
                    {reason === "Busy" ? (
                        <StyledButton
                            buttonStyle='tertiary'
                            size='large'
                            onClick={() => {
                                pushCall()
                                dispatch(setCallDebounce(undefined))
                            }}
                        >
                            <p>
                                Call Anyway
                            </p>
                        </StyledButton>
                    ) : null}
                    <StyledButton
                        buttonStyle='primary'
                        size='large'
                        onClick={() => {
                            dispatch(addCallWhenAvailable(user));
                            dispatch(setCallDebounce(undefined))
                        }}
                    >
                        <p>
                            Call on Available
                        </p>
                    </StyledButton>
                </>)}
            </div>
        </div>
    )
}


const CalMidpoint: FunctionComponent<Props> = () => {
    const callDebounce = useTypedSelector(selectCallDebounce);

    const dispatch = useTypedDispatch();

    return (
        <ModalFormContainer
            showModal={!!callDebounce}
            setShowModal={() => {
                dispatch(setCallDebounce(undefined));
            }}
        >
            {callDebounce ? (
                <Modal
                    userUuid={callDebounce.userUuid}
                    reason={callDebounce.reason}
                />
            ) : null}
        </ModalFormContainer>
    )
};

export default CalMidpoint;
