import React from 'react';
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import styles from './styles.module.scss';
import {Slider} from "../../../../components";
import SettingsInfoIcon from "./SettingsInfoIcon";

interface Props {
    title: string;
    checked: boolean
    onChange: () => void;
    loading?: boolean;
    disabled?: boolean;
    infoText?: string;
}

const SettingsSlider: React.FC<Props> = ({
                                             title,
                                             checked,
                                             onChange,
                                             loading,
                                             disabled,
                                             infoText
                                         }) => {

    const identifier = `${title.split(' ').join('_')}_dropdown`

    return (
        <div
            className={styles.setting_slider}
        >
            <label
                htmlFor={identifier}
            >
                {title}
                {infoText ? (
                    <SettingsInfoIcon infoText={infoText}/>
                ) : null}
            </label>
            {loading ? (
                <LoadingSpinner color='mamba'/>
            ) : (
                <Slider
                    name={identifier}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
            )}
        </div>
    );
};

export default SettingsSlider;
