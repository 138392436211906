import React, {FunctionComponent} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import FormTextField from "./FormTextField";
import styles from './styles.module.scss';
import {selectDefaultStyles, CustomDropdownIndicator} from "./FormSelect";

type Props = {
    label?: string;
    selectName: string;
    inputName: string;
    required?: boolean;
    defaultValue?: string;
    options: {
        label: string;
        value: string;
    } []
}

const FormSelectCombo: FunctionComponent<Props> = ({
                                                       selectName,
                                                       inputName,
                                                       options,
                                                       required,
                                                       label,
                                                       defaultValue
                                                   }) => {
    const {control} = useFormContext();

    return (
        <div className={styles.form_select_combo}>
            <Controller
                name={selectName}
                control={control}
                render={({field: {onChange, value}}) => (
                    <Select
                        unstyled
                        styles={selectDefaultStyles}
                        options={options}
                        value={options.find((c) => c.value === value)}
                        onChange={(val) => val && onChange((val as any).value)}
                        components={{
                            DropdownIndicator: CustomDropdownIndicator,
                        }}
                    />
                )}
                defaultValue={defaultValue}
                rules={{required: true}}
            />
            <FormTextField
                label={label}
                name={inputName}
                required={required}
                hideLabel
            />
        </div>
    );
};

export default FormSelectCombo;
