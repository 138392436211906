
export const MoodEmojiUpsetV: React.FC<any> = ({
                                                    ariaHidden,
                                                    title,
                                                }) => (
    <svg
        width={45}
        height={45}
        viewBox="0 0 45 45"
        fill="none"
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">About</title>}
        <desc id="desc">Very Upset</desc>
        <path d="M22.7065 38.8133C31.92 38.8133 39.3891 31.3442 39.3891 22.1307C39.3891 12.9171 31.92 5.448 22.7065 5.448C13.4929 5.448 6.0238 12.9171 6.0238 22.1307C6.0238 31.3442 13.4929 38.8133 22.7065 38.8133Z" fill="url(#paint0_radial_4_359)"/>
        <path d="M22.3832 28.908C22.3832 28.908 25.8449 28.8975 27.0335 31.6502C27.1065 31.8274 27.1586 32.0151 27.1691 32.2028C27.2003 32.5886 26.9293 32.9848 26.2724 32.7241C25.2192 32.3071 23.405 32.0985 22.3832 32.0985C21.3614 32.0985 19.5576 32.3071 18.494 32.7241C17.8372 32.9848 17.5661 32.5886 17.5974 32.2028C17.6078 32.0151 17.6599 31.8274 17.7329 31.6502C18.932 28.8975 22.3832 28.908 22.3832 28.908Z" fill="url(#paint1_radial_4_359)"/>
        <path d="M17.2638 26.3637C18.1506 26.3637 18.8695 25.2761 18.8695 23.9343C18.8695 22.5927 18.1506 21.5049 17.2638 21.5049C16.377 21.5049 15.6581 22.5927 15.6581 23.9343C15.6581 25.2761 16.377 26.3637 17.2638 26.3637Z" fill="url(#paint2_radial_4_359)"/>
        <path d="M16.6278 23.0587C17.5662 23.0587 18.3482 24.0179 18.5776 25.3212C18.7653 24.925 18.8695 24.4454 18.8695 23.9241C18.8695 22.579 18.1501 21.4947 17.2638 21.4947C16.4922 21.4947 15.8458 22.308 15.6894 23.4028C15.9709 23.1838 16.2837 23.0587 16.6278 23.0587Z" fill="url(#paint3_linear_4_359)"/>
        <path d="M15.5433 18.0121C14.1044 18.0121 12.8741 19.6699 14.4798 19.9202C16.0855 20.1704 19.005 21.5572 20.2457 22.5164C20.3396 22.6311 20.7045 22.5894 20.6732 22.3079C20.6732 21.2235 17.295 18.0121 15.5433 18.0121Z" fill="url(#paint4_linear_4_359)"/>
        <path d="M22.3833 29.8151C22.3833 29.8151 25.1985 29.8046 27.1274 31.9734C27.1066 31.8587 27.0752 31.7545 27.0231 31.6502C25.8345 28.8975 22.3728 28.908 22.3728 28.908C22.3728 28.908 18.9112 28.8975 17.7225 31.6502C17.6808 31.7545 17.6496 31.8587 17.6183 31.9734C19.568 29.8046 22.3833 29.8151 22.3833 29.8151Z" fill="url(#paint5_linear_4_359)"/>
        <path d="M28.1492 26.3637C29.036 26.3637 29.7549 25.2761 29.7549 23.9343C29.7549 22.5927 29.036 21.5049 28.1492 21.5049C27.2624 21.5049 26.5435 22.5927 26.5435 23.9343C26.5435 25.2761 27.2624 26.3637 28.1492 26.3637Z" fill="url(#paint6_radial_4_359)"/>
        <path d="M28.7853 23.0587C27.8468 23.0587 27.0648 24.0179 26.8355 25.3212C26.6478 24.925 26.5435 24.4454 26.5435 23.9241C26.5435 22.579 27.2629 21.4947 28.1492 21.4947C28.9208 21.4947 29.5672 22.308 29.7237 23.4028C29.4421 23.1838 29.1293 23.0587 28.7853 23.0587Z" fill="url(#paint7_linear_4_359)"/>
        <path d="M29.8695 18.0121C31.3084 18.0121 32.5387 19.6699 30.933 19.9202C29.3273 20.1704 26.4079 21.5572 25.1671 22.5164C25.0733 22.6311 24.7083 22.5894 24.7396 22.3079C24.7396 21.2235 28.1179 18.0121 29.8695 18.0121Z" fill="url(#paint8_linear_4_359)"/>
        <defs>
            <radialGradient id="paint0_radial_4_359" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.9465 15.4894) rotate(-29.698) scale(16.8354 13.7535)">
                <stop stopColor="#FF9214"/>
                <stop offset="1" stopColor="#FF4E0D"/>
            </radialGradient>
            <radialGradient id="paint1_radial_4_359" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.3851 30.8596) scale(3.65279 1.94584)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <radialGradient id="paint2_radial_4_359" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.7724 23.9895) rotate(73.8539) scale(2.35714 1.52503)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <linearGradient id="paint3_linear_4_359" x1="17.2788" y1="22.2415" x2="17.2788" y2="24.4159" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <linearGradient id="paint4_linear_4_359" x1="17.1981" y1="23.7905" x2="17.1981" y2="18.7503" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#643800"/>
            </linearGradient>
            <linearGradient id="paint5_linear_4_359" x1="22.3851" y1="27.7228" x2="22.3851" y2="30.3492" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <radialGradient id="paint6_radial_4_359" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.6867 23.9503) rotate(106.146) scale(2.35714 1.52503)">
                <stop offset="0.00132565" stopColor="#7A4400"/>
                <stop offset="1" stopColor="#643800"/>
            </radialGradient>
            <linearGradient id="paint7_linear_4_359" x1="28.1342" y1="22.2415" x2="28.1342" y2="24.4159" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#512D00"/>
            </linearGradient>
            <linearGradient id="paint8_linear_4_359" x1="28.2148" y1="23.7905" x2="28.2148" y2="18.7503" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stopColor="#3C2200"/>
                <stop offset="1" stopColor="#643800"/>
            </linearGradient>
        </defs>
    </svg>


        )

export default MoodEmojiUpsetV

