import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { getDisplayColor } from '../../../helpers';
import { useAppContext } from '../../../context/AppContext/AppContext';

interface Props {
    text: string;
    colour_raw?: string;
    plain?: boolean;
}

const colourRegex = /^(?:[0-9a-fA-F]{3}){1,2}$/gm;

const ChatGenericTag: FunctionComponent<Props> = ({ text, colour_raw, plain }) => {
    const { darkMode } = useAppContext();

    let colour: string | undefined;

    if (colour_raw?.match(colourRegex)) {
        colour = colour_raw;
    }

    const need_ht = !text.startsWith('#');

    return (
        <span>
            <span
                className={[styles.chat_generic_tag, !colour && !plain ? getDisplayColor(text, false, darkMode) : ""].join(" ")}
                data-is-dark={darkMode}
                data-allow-click={false}
                style={{
                    color: !plain ? `#${colour}`: undefined
                }}
            >
                {need_ht ? '#' : null}{text}
            </span>
        </span>
    );
};

export default ChatGenericTag;
