import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { LineKey } from './slice';
import { HardwarePhone } from '../../../types';

export const selectLineKeys = (state: RootState) => state.provision.lineKeys;

export const selectChangedPositions = (state: RootState) => state.provision.changedPositions;

export const selectProvisionItem = (state: RootState) => state.provision.provisionItem;

export const selectProvisionItemUuid = (state: RootState): string | undefined =>
    state.provision.provisionItem?.uuid;

export const selectLineKeyByPosition = createSelector(
    [selectLineKeys, (_: any, lineKeyPosition: number) => lineKeyPosition],
    (lineKeys: LineKey[], lineKeyPosition): LineKey | undefined =>
        lineKeys.find(stateLineKey => stateLineKey.position === lineKeyPosition)
);

export const selectApiLineKeys = (state: RootState) => state.provision.apiLineKeys;

export const selectApiLineKeyByPosition = createSelector(
    [selectApiLineKeys, (_: any, lineKeyPosition: number) => lineKeyPosition],
    (lineKeys: LineKey[], lineKeyPosition): LineKey | undefined =>
        lineKeys.find(stateLineKey => stateLineKey.position === lineKeyPosition)
);

const selectAllHardwarePhones = createSelector(
    [(state: RootState) => state.provision.hardwarePhones],
    (phones: HardwarePhone[]) => phones || []
);

export const selectProvisionPhones = createSelector([selectAllHardwarePhones], allPhones =>
    allPhones.filter(p => p.line_keys)
);

export const selectSelectedHardwarePhoneAddress = (state: RootState) =>
    state.provision.selectedHardwarePhone;

export const selectCurrentProvisioningMeta = createSelector(
    [selectAllHardwarePhones, selectSelectedHardwarePhoneAddress],
    (hardwarePhones: HardwarePhone[], currentAddress: string): HardwarePhone | undefined =>
        hardwarePhones?.find(hp => hp.mac_address === currentAddress)
);
