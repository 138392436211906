import StyledSkeleton from "../StyledSkeleton";


export const RowsContactsSkeleton = () => {
    const icon = {
        width: 32,
        height: 32
    }
    const divider = {
        width: 16,
        height: 16,
    }

    return (
        <div style={{ position: 'relative' }}>
            <StyledSkeleton name="Divider" style={{ top: 10.828369140625, left: 12.0181884765625, ...divider }} />
            <StyledSkeleton name="Icon" style={{ top: 50, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 57.04638671875, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Icon" style={{ top: 102.697998046875, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 109.713134765625, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Icon" style={{ top: 155.36474609375, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 162.3798828125, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Icon" style={{ top: 208.03125, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 215.04638671875, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Divider" style={{ top: 257.828369140625, left: 13.650146484375, ...divider }} />
            <StyledSkeleton name="Icon" style={{ top: 297.03125, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 302.56640625, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Icon" style={{ top: 349.697998046875, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 355.233154296875, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Divider" style={{ top: 399.494873046875, left: 13.650146484375, ...divider }} />
            <StyledSkeleton name="Icon" style={{ top: 438.697998046875, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 444.233154296875, left: 84, width: 109.5703125, height: 13.777099609375 }} />
            <StyledSkeleton name="Icon" style={{ top: 491.36474609375, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 496.89990234375, left: 84, width: 109.5703125, height: 13.77734375 }} />
            <StyledSkeleton name="Icon" style={{ top: 546.03173828125, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 549.56689453125, left: 84, width: 109.5703125, height: 13.77734375 }} />
            <StyledSkeleton name="Divider" style={{ top: 593.828125, left: 13.650146484375, ...divider }} />
            <StyledSkeleton name="Icon" style={{ top: 633.03173828125, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 638.56689453125, left: 84, width: 109.5703125, height: 13.77734375 }} />
            <StyledSkeleton name="Icon" style={{ top: 685.6982421875, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 691.2333984375, left: 84, width: 109.5703125, height: 13.77734375 }} />
            <StyledSkeleton name="Icon" style={{ top: 738.36474609375, left: 39.6153564453125, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 743.89990234375, left: 84, width: 109.5703125, height: 13.77734375 }} />
        </div>
    )
}

export default RowsContactsSkeleton
