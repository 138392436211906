/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { Store, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ICall } from '../../types';
import { RootState} from "../store";
import {isCallSwitch, isYay} from '../../helpers';
import { callsSlice } from '../slices';
import yayLogo from '../../img/logos/logo-yay-64.png';
import pulseHdLogo from '../../img/logos/logo-pulsehd-64.png';
import callswitchLogo from '../../img/logos/logo-callswitch-64.png';
import { t } from "../../lib/polyglot.lib";

let hasAskedPermission = false;

const checkNotificationPermission = () => {
    if (window.Notification && Notification.permission !== 'denied' && !hasAskedPermission) {
        Notification.requestPermission();
        hasAskedPermission = true;
    }
};

export const notification = (store: Store) => (next: Dispatch) => (action: PayloadAction<any>) => {
    const state = store.getState() as RootState;


    switch (action.type) {
        case callsSlice.actions.newCall.type:
            if (document.hasFocus() || !document.hidden) break;

            const { callee } = action.payload as ICall;

            const userExtDictionary = Object.fromEntries(
                state.auth.users?.map(user => [user.extension, user]) || []
            );

            checkNotificationPermission();

            let notificationIcon;

            switch (true) {
                case callee && userExtDictionary[callee]?.avatar_key:
                    if (callee) {
                        notificationIcon = `https://storage.googleapis.com/v-profiles/${userExtDictionary[callee].avatar_key}`;
                    }
                    break;

                case isYay:
                    notificationIcon = yayLogo
                    break;

                case isCallSwitch:
                    notificationIcon = callswitchLogo;
                    break;

                default:
                    notificationIcon = pulseHdLogo;
            }

            const callNotification = new Notification(t("terms.inbound_call", 1), {
                body: action.payload.displayName,
                icon: notificationIcon
            });

            callNotification.onclick = () => {
                window.focus();
                callNotification.close();
            };
            break;

        // case 'notifications/new-reminder':
        //     sendNotification(store, action.payload, true);
        //     break;

        default:
            break;
    }

    return next(action);
};
