/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import {combineReducers} from '@reduxjs/toolkit';
import {RootState} from "./store";
import {api} from './services';
import {app, auth, chat, chatsAdapter, sip, status, user, video, provision} from './slices';
import {message} from "./slices/messageSlice";

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    app,
    auth,
    chat,
    message,
    provision,
    sip,
    status,
    user,
    video,
});

const rootReducer = (state: RootState, action: { type: string }) => {
    if (action.type === 'auth/switch-user') {
        state = {
            ...state,
            sip: {
                calls: [],
                callHistoryLoading: 'idle',
                callHistory: [],
                callerIdsLoading: 'idle',
                callerIds: [],
                parkingSlotsLoading: 'idle',
                callHistoryFillerLoading: 'idle',
                callFeedback: {
                    show: false,
                    forms: []
                },
                usersForGroupCall: [],
                conferenceInvitations: [],
            },
            status: {
                userStatuses: {},
                callWhenAvailable: [],
            },
            user: undefined,
            chat: {
                userUuid: '',
                chats: chatsAdapter.getInitialState(),
                typingStatuses: {},
                failedMessagesQueue: {},
                messageReplies: {},
                externalReplies: [],
                messageReactions: {},
                groupCreationActive: false,
                usersForGroupSelection: [],
            },
            message: {
                chat: {},
            },
            app: {
                ...state.app,
                follow_me_apps: undefined,
                follow_me_registration: undefined,
            }
        };

        localStorage['yay.app.settings'] = null;
    }

    if (action.type === 'auth/logout') {
        localStorage['yay.app.settings'] = null;
        sessionStorage.clear();
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        // @ts-ignore
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
