import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import {Zoom} from "@mui/material";
import {AudioNotification, GenericNotification} from '.';
import {useOnlineStatus} from '../../helpers';
import UserAvailableNotification from "./UserAvailableNotification";
import {usePolyglot} from "../../context/Polyglot";
import CallQueuedNotification from "./CallQueuedNotification";
import {useCallWhenAvailableList} from "./hooks";
import {useHeadsetContext} from "../../context/HeadsetContext/context";
import {StyledButton} from "../StyledComponents";
import {useTypedSelector} from "../../redux/hooks";
import {selectDnd} from "../../redux/slices";

interface NotificationCentreProps {
}

/*
const AppNotifications = () => {
    const users = useTypedSelector(state => state.auth.users);
    const userDictionary = users ? Object.fromEntries(users.map(u => [u.uuid, u])) : {};
    const chatDictionary = useTypedSelector(selectChatEntities);
    const popupNotifications = useTypedSelector(state => getPopupNotifications(state));

    const [visibleNotifications, setVisibleNotifications] = useState(popupNotifications.slice(-5).reverse())

    const dispatch = useTypedDispatch();

    useEffect(() => {
        setVisibleNotifications(popupNotifications.slice(-5).reverse());
    }, [popupNotifications])

    function handleScrollToMessage(notification) {
        let chatId;
        if (notification.to_type === 'user') {
            chatId = notification.from
        } else {
            chatId = notification.to
        }

        dispatch(updateActivePage('chat'))
        dispatch(setActiveChat(chatId))
        dispatch(setScrollToMessageUuid(notification.uuid));
        dispatch(removePopupNotification(notification.uuid));
    }

    function onClose(notification) {
        dispatch(removePopupNotification(notification.uuid));
    }

    return (
        visibleNotifications.reverse().slice(0, 5).map(notification => (
            <GenericNotification
                clickFunction={() => handleScrollToMessage(notification)}
                message={notification.content}
                title={`${userDictionary[notification.from].nickname} > ${chatDictionary[notification.to].display_name}`}
                className='warning'
                type='chat-message'
                crossClick={() => onClose(notification)}
            />
        ))
    )
}
 */

export const NotificationCentre: React.FC<NotificationCentreProps> = () => {
    const {
        dnd
    } = useTypedSelector(selectDnd);

    // const [showMicrophoneWarning, setShowMicrophoneWarning] = useState<boolean>(false);
    const [showDNDBanner, setShowDNDBanner] = useState<boolean>(false);
    const [hidDevices, setHidDevices] = useState<HIDDevice[]>([]);

    const {setUnseenList, seenList, unseenList} = useCallWhenAvailableList();
    const {headset, connectDevice} = useHeadsetContext();
    const userIsOnline = useOnlineStatus();
    const {t} = usePolyglot();

    useEffect(() => {
        setShowDNDBanner(dnd);
    }, [dnd]);


    useEffect(() => {
        navigator.hid.getDevices()
            .then(r => {
                if (r.length > 0) {
                    setHidDevices(r)
                }
            })
            .catch(() => {
                setHidDevices([])
            })
    }, []);

    return (
        <div className='notification-container'>
            {/* <AppNotifications/> */}
            {!userIsOnline && (
                <Zoom in={!userIsOnline}>
                    <div>
                        <GenericNotification
                            message={t("phrases.error_offline_reconnect")}
                            className='danger'
                        />
                    </div>
                </Zoom>
            )}
            {!headset && hidDevices.map((device, idx) => (
                <GenericNotification
                    key={`${device.productId}, ${idx}`}
                    title={t('phrases.headset_detected')}
                    message={(<>
                        {t('phrases.previously_selected_device_detected')}<br/>
                        {device.productName}
                    </>)}
                    crossClick={() => {
                        setHidDevices(hidDevices.filter((_, deviceIdx) => deviceIdx !== idx))
                    }}
                    className='info'
                    icon='headphones'
                >
                    <div style={{marginTop: 12, display: 'flex', gap: 8}}>
                        <StyledButton
                            buttonStyle='tertiary'
                            onClick={() => {
                                connectDevice(device)
                            }}
                        >
                            <p>
                                {t('actions.connect')}
                            </p>
                        </StyledButton>
                        <StyledButton
                            buttonStyle='tertiary'
                            onClick={() => {
                                device.forget()
                                setHidDevices(hidDevices.filter((_, deviceIdx) => deviceIdx !== idx))
                            }}
                            context='danger'
                        >
                            <p>
                                {t('actions.forget')}
                            </p>
                        </StyledButton>
                    </div>
                </GenericNotification>
            ))}
            {showDNDBanner && (
                <GenericNotification
                    message={t("phrases.dnd_enabled")}
                    className='danger'
                    crossClick={() => setShowDNDBanner(false)}
                />
            )}
            <AnimatePresence>
                {unseenList.map((whenAvailableUser) => (
                    <motion.div
                        key={`Queue_${whenAvailableUser.user_uuid}`}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={{
                            duration: 0.3,
                            ease: 'easeOut'
                        }}
                    >
                        <CallQueuedNotification
                            whenAvailableUser={whenAvailableUser}
                            setUnseenList={setUnseenList}
                        />
                    </motion.div>
                ))}
                {seenList.map((whenAvailableUser) => (
                    <motion.div
                        key={`Available_${whenAvailableUser.user_uuid}`}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={{
                            duration: 0.3,
                            ease: 'easeOut'
                        }}
                    >
                        <UserAvailableNotification
                            user={whenAvailableUser}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
            <AudioNotification/>
            {/*
            <UpdateNotification/>
            */}
        </div>
    );
};
