import React, {useRef, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useChatContext} from "../../../../context/ChatContext/context";
import {useChannelMembers} from "../../../../pages/chat/ChatHeader/hooks";
import styles from '../styles.module.scss';
import AriaLabel from "../../../AriaComponents/AriaLabel";
import {usePolyglot} from "../../../../context/Polyglot";
import GroupEditMember from "./children/GroupEditMember";
import {useTypedSelector} from "../../../../redux/hooks";
import {selectCurrentUserId} from "../../../../redux/slices";
import Icon from "../../../Icon";
import AriaButton from "../../../AriaComponents/AriaButton";
import AriaTextField from "../../../AriaComponents/AriaTextField";
import AddUsersDisplay from "./children/AddUsersDisplay";

interface Props {
}


const GroupMemberEdit: React.FC<Props> = () => {
    const {chat} = useChatContext();
    const [searchVal, setSearchVal] = useState('');
    const [showAddUsers, setShowAddUsers] = useState<boolean>(false);
    const {control} = useFormContext();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const {t} = usePolyglot();

    const {displayMembers, adminUuids} = useChannelMembers();

    const inputRef = useRef(null);


    return (<>
        <AriaTextField
            value={searchVal}
            onChange={(e) => {
                setSearchVal(e)
            }}
            placeholder={t("phrases.search_members")}
            isSearch
            aria-labelledby={t("phrases.search_members")}
            ref={inputRef}
        />
        <Controller
            name='users_config.removing'
            control={control}
            render={({
                         field: {onChange, value},
                     }) => (
                <div className={styles.channel_members}>
                    <AriaLabel label={t("terms.contact", 2)}/>
                    <ul>
                        <AriaButton
                            className={styles.group_member}
                            onClick={() => setShowAddUsers(!showAddUsers)}
                        >
                            <Icon name='addUser'/>
                            <p>
                                {t('actions.add_user', 2)}
                            </p>
                        </AriaButton>
                        {displayMembers.map(member => {
                            if (!member.display_name.includes(searchVal)) return null
                            if ((value || []).some(item => item === member.member)) return null
                            return (
                                <GroupEditMember
                                    key={`GEM_${member.member}`}
                                    member={member}
                                    isAdmin={adminUuids?.includes(member.member) || false}
                                    isCreator={chat.channel?.created_by === member.member}
                                    canEdit={member.member !== userUuid && adminUuids?.includes(userUuid) || false}
                                    onRemove={() => onChange([...value, member.member])}
                                />
                            )
                        })}
                    </ul>
                </div>
            )}
            rules={{required: true}}
        />
        {showAddUsers ? (
            <div className={styles.add_users_display}>
                <AddUsersDisplay
                    closeEditor={() => setShowAddUsers(false)}
                    onAddMembers={() => setShowAddUsers(false)}
                />
            </div>
        ) : null}
    </>);
};

export default GroupMemberEdit;
