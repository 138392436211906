import {Compact, Standard,} from "./Themes";
import {usePolyglot} from "../../../../../context/Polyglot";
import styles from "../ThemeSelector/ThemeSelector.module.scss";
import {selectChatSettingByKey, updateChatSettings} from "../../../../../redux/slices";
import {useTypedDispatch, useTypedSelector} from "../../../../../redux/hooks";

const ChatViewSelector = () => {
    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    let chatStyle = useTypedSelector(state => selectChatSettingByKey(state, 'style'));
    if (!chatStyle) {
        chatStyle = "default";
    }

    const availableThemes = [
        {
            value: "default",
            title: t("terms.standard"),
            preview: <Standard/>
        },
        {
            value: "minimal",
            title: t("terms.compact"),
            preview: <Compact/>
        },
    ];

    const setChatView = (val) => {
        dispatch(
            updateChatSettings({
                setting: 'style',
                value: val
            })
        )
    }

    return (
        <div className={styles.selector_container}>
            {availableThemes.map(theme => {
                const {value, title, preview} = theme;

                return (
                    <label
                        className={styles.label}
                        data-is-active={value === chatStyle}
                        key={value}
                    >
                        <button
                            className={styles.selector}
                            onClick={() => setChatView(value)}
                        >
                            {preview}
                        </button>
                        <p>
                            {title}
                        </p>
                    </label>
                )
            })}
        </div>
    )
}

export default ChatViewSelector;
