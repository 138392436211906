import React, {useCallback, useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import {selectPhoneSettingByKey, updatePhoneSettings} from '../../../redux/slices';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {isPreview, playRingtone, stopRingtone, useMediaDevices} from '../../../helpers';
import styles from '../SettingsPage.module.scss'
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import {IPhoneSettings} from "../../../types";
import {StyledDropdownOption} from "../../../components/StyledComponents/StyledDropdown";
import SettingsDropdown from "./SettingsComponents/SettingsDropdown";
import SettingsVolume from "./SettingsComponents/SettingsVolume";

interface AudioVideoProps {
}

export const AudioVideo: React.FC<AudioVideoProps> = () => {
    const outputDeviceIdRingtone = useTypedSelector(state => selectPhoneSettingByKey(state, 'outputDeviceIdRingtone'));
    const outputDeviceIdCall = useTypedSelector(state => selectPhoneSettingByKey(state, 'outputDeviceIdCall'));
    const inputDeviceId = useTypedSelector(state => selectPhoneSettingByKey(state, 'inputDeviceId'));
    const videoDeviceId = useTypedSelector(state => selectPhoneSettingByKey(state, 'videoDeviceId'));
    const ringtoneVolumeValue = useTypedSelector(state => selectPhoneSettingByKey(state, 'ringtoneVolumeValue'));
    const callVolumeValue = useTypedSelector(state => selectPhoneSettingByKey(state, 'callVolumeValue'));

    const {
        audioInputOptions,
        audioOutputOptions,
        videoInputOptions,
        handleMediaDevices,
    } = useMediaDevices();

    const [ringtoneVolume, setRingtoneVolume] = useState<number>(ringtoneVolumeValue ?? 100);
    const [callVolume, setCallVolume] = useState<number>(callVolumeValue ?? 100);
    const [playTestRingtone, setPlayTestRingtone] = useState<boolean>(false);
    const [playTestCallVolume, setPlayTestCallVolume] = useState<boolean>(false);

    const dispatch = useTypedDispatch();

    const {t} = usePolyglot();

    // eslint-disable-next-line
    const updatePhoneSettingsCallback = useCallback(
        debounce(
            (setting: keyof IPhoneSettings, value: number) =>
                dispatch(
                    updatePhoneSettings({
                        setting,
                        value
                    })
                ),
            10
        ),
        []
    );

    useEffect(() => {
        handleMediaDevices();

        if ('permissions' in navigator && 'mediaDevices' in navigator) {

            navigator.mediaDevices.ondevicechange = () => {
                handleMediaDevices();
            };
        }
    }, []);

    useEffect(() => {
        if (audioOutputOptions.length > 0 && !audioOutputOptions.some(o => o.value === outputDeviceIdRingtone)) {
            const def = audioOutputOptions.find(o => o.value === 'default')
            if (!def) return
            dispatch(
                updatePhoneSettings({
                    setting: 'outputDeviceIdRingtone',
                    value: def.value
                })
            );
            dispatch(
                updatePhoneSettings({
                    setting: 'outputDeviceNameRingtone',
                    value: def.name
                })
            );
        }
        if (audioOutputOptions.length > 0 && !audioOutputOptions.some(o => o.value === outputDeviceIdCall)) {
            const def = audioOutputOptions.find(o => o.value === 'default')
            if (!def) return
            dispatch(
                updatePhoneSettings({
                    setting: 'outputDeviceIdCall',
                    value: def.value
                })
            );
            dispatch(
                updatePhoneSettings({
                    setting: 'outputDeviceNameCall',
                    value: def.name
                })
            );
        }
        if (audioInputOptions.length > 0 && !audioInputOptions.some(o => o.value === inputDeviceId)) {
            const def = audioInputOptions.find(o => o.value === 'default')
            if (!def) return
            dispatch(
                updatePhoneSettings({
                    setting: 'inputDeviceId',
                    value: def.value
                })
            );
        }
        if (videoInputOptions.length > 0 && !videoInputOptions.some(o => o.value === videoDeviceId)) {
            dispatch(
                updatePhoneSettings({
                    setting: 'videoDeviceId',
                    value: videoInputOptions[0].value
                })
            )
        }
    }, [audioInputOptions.length, audioOutputOptions.length, videoInputOptions.length]);

    useEffect(() => {
        updatePhoneSettingsCallback('ringtoneVolumeValue', ringtoneVolume);
    }, [ringtoneVolume, updatePhoneSettingsCallback]);

    useEffect(() => {
        updatePhoneSettingsCallback('callVolumeValue', callVolume);
    }, [callVolume, updatePhoneSettingsCallback]);

    useEffect(() => {
        if (playTestRingtone) {
            playRingtone({
                callId: 'ringtone-test',
                outputDeviceId: outputDeviceIdRingtone || 'default',
                isOutbound: true,
                volume: ringtoneVolume
            });
        }

        if (!playTestRingtone) {
            stopRingtone('ringtone-test');
        }

        if (playTestCallVolume) {
            playRingtone({
                callId: 'call-volume-test',
                outputDeviceId: outputDeviceIdCall || 'default',
                isOutbound: false,
                volume: callVolume
            });
        }

        if (!playTestCallVolume) {
            stopRingtone('call-volume-test');
        }

        return () => {
            stopRingtone('ringtone-test');
            stopRingtone('call-volume-test');
        };
    }, [playTestRingtone, playTestCallVolume]);

    const setRingtoneDevice = (val: string) => {
        dispatch(
            updatePhoneSettings({
                setting: 'outputDeviceIdRingtone',
                value: val
            })
        );
        dispatch(
            updatePhoneSettings({
                setting: 'outputDeviceNameRingtone',
                value: val
            })
        );
    }

    const setCallAudioDevice = (val: string) => {
        dispatch(
            updatePhoneSettings({
                setting: 'outputDeviceIdCall',
                value: val
            })
        );
        dispatch(
            updatePhoneSettings({
                setting: 'outputDeviceNameCall',
                value: val
            })
        );
    }

    const speakerOpts: StyledDropdownOption[] = audioOutputOptions.map(audioOpt => ({
        label: audioOpt.name,
        value: audioOpt.value,
    }))

    const audioInOpts: StyledDropdownOption[] = audioInputOptions.map(audioOpt => ({
        label: audioOpt.name,
        value: audioOpt.value,
    }))

    const cameraOpts: StyledDropdownOption[] = videoInputOptions.map(cameraOpt => ({
        label: cameraOpt.name,
        value: cameraOpt.value,
    }))

    return (
        <div className={styles.container}>
            <MenuHeader title={isPreview ? `${t("terms.audio")} & ${t("terms.video", 1)}` : t("terms.audio")}
                        description={t("phrases.manage_audio_settings")}/>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("phrases.ringtone_and_loudspeaker_audio")}</h2>
                    <p>Choose an audio output for ringtones & as an in-call speaker</p>
                </div>
                <div>
                    <SettingsDropdown
                        title={t("terms.speaker", 1)}
                        options={speakerOpts}
                        onSelect={setRingtoneDevice}
                        selected={outputDeviceIdRingtone || 'default'}
                        placeHolder={t("phrases.no_devices")}
                    />
                    <SettingsVolume
                        title={t('terms.volume')}
                        volume={ringtoneVolume}
                        onAudioTest={() => setPlayTestRingtone(!playTestRingtone)}
                        onChange={setRingtoneVolume}
                    />
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("phrases.in_call_audio")}</h2>
                    <p>{t("phrases.choose_call_audio_output")}</p>
                </div>
                <div>
                    <SettingsDropdown
                        title={t("terms.speaker", 1)}
                        options={speakerOpts}
                        onSelect={setCallAudioDevice}
                        selected={outputDeviceIdCall || 'default'}
                        placeHolder={t("phrases.no_devices")}
                    />
                    <SettingsVolume
                        title={t('terms.volume')}
                        volume={callVolume}
                        onAudioTest={() => setPlayTestCallVolume(!playTestCallVolume)}
                        onChange={setCallVolume}
                    />
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.input_device", 2)}</h2>
                    <p>{t("phrases.choose_call_audio_input")}</p>
                </div>
                <div>
                    <SettingsDropdown
                        title={t("terms.microphone", 1)}
                        options={audioInOpts}
                        onSelect={(val) => {
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'inputDeviceId',
                                    value: val
                                })
                            )
                        }}
                        selected={inputDeviceId || 'default'}
                        placeHolder={t("phrases.no_devices")}
                    />
                    {isPreview ? (
                        <SettingsDropdown
                            title={t("terms.camera", 1)}
                            options={cameraOpts}
                            onSelect={(val) => {
                                dispatch(
                                    updatePhoneSettings({
                                        setting: 'videoDeviceId',
                                        value: val
                                    })
                                )
                            }}
                            selected={videoDeviceId || 'default'}
                            placeHolder={t("phrases.no_devices")}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
