/* eslint-disable react/jsx-props-no-spreading */
import {useRadioGroupState} from 'react-stately';
import {AriaRadioProps, useRadio, useRadioGroup} from 'react-aria';
import {createContext, ReactNode, useContext, useRef} from "react";
import {RadioGroupProps} from "@react-types/radio";

const RadioContext = createContext<any>(null);

interface AriaRadioGroupProps extends RadioGroupProps {
    children?: ReactNode
}

export const AriaRadioGroup: React.FC<AriaRadioGroupProps> = (props) => {
    const { children, label, description, errorMessage, validationState } = props;
    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
        useRadioGroup(props, state);


    return (
        <div {...radioGroupProps}>
            <span {...labelProps}>{label}</span>
            <RadioContext.Provider value={state}>
                {children}
            </RadioContext.Provider>
            {description && (
                <div {...descriptionProps} style={{ fontSize: 12 }}>{description}</div>
            )}
            {errorMessage && validationState === 'invalid' && typeof errorMessage !== 'function' &&
                (
                    <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
                        {errorMessage}
                    </div>
                )}
        </div>
    );
}

interface RadioProps extends AriaRadioProps {
    children?: ReactNode;
    inputHidden?: boolean;
}

export const AriaRadio: React.FC<RadioProps> = (props) => {
    const { children } = props;
    const state = useContext(RadioContext);
    const ref = useRef(null);
    const { inputProps } = useRadio(props, state, ref);

    return (
        <label style={{ display: 'block' }}>
            <input
                {...inputProps}
                ref={ref}
            />
            {children}
        </label>
    );
}