import StyledSkeleton from "../StyledSkeleton";


export const ChatListChatsSkeleton = () => {

    const icon = {
        width: 32,
        height: 32
    }

    return (
        <div style={{ position: 'relative' }}>
            <StyledSkeleton name="Icon" style={{ top: 16, left: 24, ...icon }} />
            <StyledSkeleton name="Name" style={{ top: 16, left: 64, width: 110, height: 12 }} />
            <StyledSkeleton name="Message" style={{ top: 38.5, left: 64, width: 320, height: 32 }} />

            <StyledSkeleton name="Icon" style={{ top: 120, left: 24, ...icon }} />
            <StyledSkeleton name="name" style={{ top: 120, left: 64, width: 110, height: 12 }} />
            <StyledSkeleton name="message" style={{ top: 142.5, left: 64, width: 320, height: 32 }} />

            <StyledSkeleton name="Icon" style={{ top: 224, left: 24, ...icon }} />
            <StyledSkeleton name="name" style={{ top: 224, left: 64, width: 110, height: 12 }} />
            <StyledSkeleton name="message" style={{ top: 246.5, left: 64, width: 320, height: 32 }} />

            <StyledSkeleton name="Icon" style={{ top: 328, left: 24, ...icon }} />
            <StyledSkeleton name="name" style={{ top: 328, left: 64, width: 110, height: 12 }} />
            <StyledSkeleton name="message" style={{ top: 350.5, left: 64, width: 320, height: 32 }} />

            <StyledSkeleton name="Icon" style={{ top: 432, left: 24, ...icon }} />
            <StyledSkeleton name="name" style={{ top: 432, left: 64, width: 110, height: 12 }} />
            <StyledSkeleton name="message" style={{ top: 454.5, left: 64, width: 320, height: 32 }} />
        </div>
    )
}

export default ChatListChatsSkeleton;
