import React from "react";
import {isHuntGroup, isUser} from "../../../../types";
import {useContactContext} from "../../../../context/ContactContext/context";
import Icon from "../../../Icon";
import EntityIcon, {GropDisplayUser} from "../../../icons/EntityIcon";
import {selectUsersDictionary} from "../../../../redux/slices";
import {useTypedSelector} from "../../../../redux/hooks";


interface Props {
    noBLF?: boolean
    size?: number
}

export const ContactIcon: React.FC<Props> = ({noBLF, size}) => {
    const allUsers = useTypedSelector(selectUsersDictionary);

    const {contact} = useContactContext();

    if ((contact as any).short_code) {
        return (
            <Icon name='provisionSpeedDial' width={32} height={32}/>
        )
    }

    if (isUser(contact)) {
        return (
            <EntityIcon
                type='user'
                details={{
                    sip_name: contact.name,
                    display_name: contact.nickname,
                    avatar_key: contact.avatar_key,
                    blf: !noBLF,
                    uuid: contact.uuid,
                }}
                size={size}
            />
        )
    }

    if (isHuntGroup(contact)) {
        const details: GropDisplayUser[] = []


        for (let i = 0; i < contact.members.length; i += 1) {
            if (details.length === 2) break;

            const foundUser = allUsers[contact.members[i]];

            if (foundUser) {
                details.push({
                    avatar_key: foundUser.avatar_key,
                    nickname: foundUser.nickname,
                    uuid: foundUser.uuid
                })
            }
        }

        return (
            <EntityIcon
                type='hunt_group'
                details={{
                    uuid: contact.uuid,
                    display_users: details
                }}
                size={size}
            />
        )
    }

    return (
        <EntityIcon
            type='phonebook'
            details={{uuid: contact.uuid}}
            size={size}
        />
    )
}