import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {selectCurrentUserId, setFilePreview} from "../../../../redux/slices";
import styles from "../Attachment.module.scss";
import {api} from "../../../../api";
import {AttachmentNameDisplay, GenericAttachmentProps} from "./GenerticAttachment";

interface Props extends GenericAttachmentProps {
    mode: 'download' | 'delete';
}

export const PdfAttachment: React.FC<Props> = ({
                                                   file,
                                                   mode,
                                               }) => {

    const userUuid = useTypedSelector(selectCurrentUserId);
    const dispatch = useTypedDispatch();

    const {name, size, shared_at, shared_by, uuid} = file;

    async function getPdf() {
        const response = await api.getFileContent({
            userUuid,
            fileUuid: file.uuid,
            responseType: 'arraybuffer'
        });

        return URL.createObjectURL(
            new Blob([response.data], {
                type: 'application/pdf'
            })
        );
    }


    const activatePreview = async (e) => {
        e.stopPropagation();
        const src = mode === 'delete' ? URL.createObjectURL(file) : await getPdf();

        dispatch(setFilePreview({
            src,
            file_name: name,
            file_type: 'pdf',
            file_size: size,
            shared_by,
            shared_at,
            uuid
        }))
    }

    return (
        <>
            <button onClick={activatePreview} className={styles.pdf}>
                <mark>
                    PDF
                </mark>
            </button>
            <div className={styles.file_content_container}>
                <AttachmentNameDisplay
                    file={file}
                />
            </div>
        </>
    )
}