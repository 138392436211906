import React, {ReactNode, useMemo} from 'react';
import {useCallContext} from "../../../../context/CallContext/context";
import styles from './styles.module.scss';
import {useCallerDisplayNameGeneric} from "../../../../helpers";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    addOneToast,
    findContactOnBook,
    ICallerId, parseNumber, selectApiOnlyPhonebooks,
    selectAuthUserByUuid, selectCallerIdById, selectCurrentCallerId,
    setViewContact
} from "../../../../redux/slices";
import {ICall, PhonebookContactEntity} from "../../../../types";
import AriaButton from "../../../AriaComponents/AriaButton";
import EntityIcon from "../../../icons/EntityIcon";
import {useGetContactNameByNumberQuery} from "../../../../redux/services/sipApi";

interface Props {
}

const getRoute = (call: ICall, callerIdData: ICallerId | undefined): string | undefined => {
    if (!call.callee || !call.displayName) return undefined;

    if (call.callee === call.displayName) return undefined;

    if (call.displayName === 'DDI' && callerIdData) {

        if (callerIdData.cli_name === callerIdData.cli_display) {
            return callerIdData.cli_name
        }

        return `${callerIdData.cli_name} (${callerIdData.cli_display})`
    }

    return call.displayName;
}

const UserDisplay: React.FC<{ userUuid: string }> = ({userUuid}) => {
    const {call} = useCallContext();
    const user = useTypedSelector(state => selectAuthUserByUuid(state, userUuid));

    const dispatch = useTypedDispatch();

    if (!user) return (
        <GenericDisplay
            route={undefined}
            userIcon={undefined}
            bottomLines={[
                <h4>{call.displayName}</h4>,
                <p>{call.callee}</p>,
            ]}
        />
    );

    return (
        <GenericDisplay
            route={undefined}
            userIcon={(
                <EntityIcon
                    type="user"
                    details={{
                        display_name: user.nickname,
                        avatar_key: user?.avatar_key,
                        blf: false,
                        uuid: user.uuid,
                    }}
                    size={62}
                />
            )}
            bottomLines={[
                <AriaButton
                    key={`${call.id}_crl${1}`}
                    onClick={() => dispatch(setViewContact(user))}
                >
                    <h4>
                        {user?.nickname}
                    </h4>
                </AriaButton>,
                <p
                    key={`${call.id}_p${1}`}
                >
                    {user.extension}
                </p>,
            ]}
        />
    )
}

const ContactDisplay: React.FC<{ entity: PhonebookContactEntity }> = ({entity}) => {
    const {call} = useCallContext();

    const curCallerId = useTypedSelector(selectCurrentCallerId);
    const callerIdData = useTypedSelector(state => selectCallerIdById(state, curCallerId));

    const dispatch = useTypedDispatch();

    const onContactClick = () => {
        const phonebookUuid = entity.phonebookUuid || entity.details[0]?.phonebook_uuid;
        const contactUuid = entity.uuid || entity.details[0]?.uuid;

        if (!phonebookUuid || !contactUuid) return;

        dispatch(findContactOnBook({
            phonebookUuid,
            contactUuid,
        }))
            .then(r => {
                if (!r) {
                    dispatch(addOneToast({
                        content: `Failed to find ${entity.name || 'contact'} in contacts`,
                        title: "Failed to find contact",
                        type: "error"
                    }))
                }

                dispatch(setViewContact(r))
            })
    }

    const companyName = entity.company_name || entity.details[0]?.company_name;

    return (
        <GenericDisplay
            route={getRoute(call, callerIdData)}
            userIcon={undefined}
            bottomLines={[
                <AriaButton
                    key={`${call.id}_crl${1}`}
                    onClick={() => onContactClick()}
                >
                    <h4>
                        {entity.name || entity.details?.[0]?.name || "Unknown Contact"}
                    </h4>
                </AriaButton>,
                <p key={`${call.id}_crl${2}`}>{entity.number}</p>,
                companyName ? <p key={`${call.id}_crl${3}`}>{companyName}</p> : null
            ]}
        />
    )
}

const ConferenceDisplay: React.FC<{ name: string }> = ({name}) => {
    const {call} = useCallContext();

    return (
        <GenericDisplay
            route={undefined}
            userIcon={undefined}
            bottomLines={[
                <h4
                    style={{marginBottom: 8}}
                    key={`${call.id}_cih`}
                >
                    Conference Call
                </h4>,
                <p
                    key={`${call.id}_crl${2}`}
                >
                    Invited by {name}
                </p>,
            ]}
        />
    )
}


interface DisplayProps {
    route: string | undefined;
    userIcon: ReactNode | undefined;
    bottomLines: ReactNode[];
}

const GenericDisplay: React.FC<DisplayProps> = ({
                                                    route,
                                                    userIcon,
                                                    bottomLines,
                                                }) => {
    const {call} = useCallContext();

    return (
        <div className={styles.call_ringing}>
            <div className={styles.top}>
                <p>
                    {call.isOutbound ? 'Ringing' : 'Incoming Call'}
                </p>
                {route && !call.isOutbound ? (
                    <p>
                        via&nbsp;{route}
                    </p>
                ) : null}
            </div>
            {userIcon ? (
                <div className={styles.middle}>
                    {userIcon}
                </div>
            ) : null}
            <div className={styles.bottom}>
                {bottomLines}
            </div>
        </div>
    )
}

const CallRingingDisplay: React.FC<Props> = () => {
    const {call} = useCallContext();

    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);
    const curCallerId = useTypedSelector(selectCurrentCallerId);

    const callerIdData = useTypedSelector(state => selectCallerIdById(state, curCallerId));

    const {
        type,
        name,
        data
    } = useCallerDisplayNameGeneric(call.callee)

    useGetContactNameByNumberQuery(
        {
            numbers: call.callee ? [parseNumber(call.callee)] : [],
            phonebooks: apiPhonebooks
        },
        {
            skip: apiPhonebooks.length < 1 || type !== undefined || !call.callee
        }
    )

    return useMemo(() => {
        if (call.roomId) {
            return <ConferenceDisplay name={name}/>
        }

        if (type === "user") {
            return <UserDisplay userUuid={data.uuid}/>
        }

        if (type === "contact") {
            return <ContactDisplay entity={data}/>
        }

        if (type === "callerId") {
            return <GenericDisplay
                route={name}
                userIcon={undefined}
                bottomLines={[
                    <h4>{call.callee}</h4>
                ]}
            />
        }

        return <GenericDisplay
            route={getRoute(call, callerIdData)}
            userIcon={undefined}
            bottomLines={[
                <h4>{call.callee}</h4>
            ]}
        />
    }, [type, name, data, callerIdData])
};

export default CallRingingDisplay;
