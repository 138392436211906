import {ReactNode, useEffect} from "react";
import * as Sentry from "@sentry/react";
import styles from "./AppCrash.module.scss";
import {useTypedSelector} from "../../redux/hooks";
import {selectDashboardUrl} from "../../redux/slices";
import {StyledButton} from "../StyledComponents";
import {usePolyglot} from "../../context/Polyglot";
import {useErrorContext} from "../../context/ErrorContext/context";

const AppCrash = () => {
    const dashboardURL = useTypedSelector(selectDashboardUrl);

    const {error, errorInfo} = useErrorContext();

    const {t} = usePolyglot();

    const contactStr = t('phrases.problem_persists_contact');
    let item: ReactNode;

    useEffect(() => {
        Sentry.captureMessage('App Crash', {
            extra: {
                error,
                errorInfo
            },
            tags: {
                info: 'app crash'
            }
        });
    }, []);

    if (dashboardURL) {
        const arr = contactStr.split(t('phrases.contact_us_ref'))
        item = (
            <p>
                {arr[0]}&nbsp;
                <a
                    href={`https://${dashboardURL}/about/contact-us/`}
                    target='_blank'
                    rel='noreferrer'
                >
                    {t('phrases.contact_us_ref')}
                </a>
                {arr[1]}
            </p>
        )
    }

    return (
        <div className={styles.app_crash_container}>
            <h2>{t('phrases.error_chat_error_generic_1')}</h2>
            <p>{t('phrases.refreshing_the_app_may_fix_this')}</p>
            <StyledButton
                buttonStyle='tertiary'
                onClick={() => window.location.reload()}
            >
                <p>
                    {t('actions.refresh')}
                </p>
            </StyledButton>
            {item}
        </div>
    )
}

export default AppCrash;
