import styles from "./Visualizer.module.scss";

interface VisualizerProps {
    volume: any;
    mini?: boolean;
}

const Bar = ({volume}) => {
    // added a bit of randomness to add to the aesthetic
    // eslint-disable-next-line no-param-reassign,no-unused-expressions
    (volume > 0) && (volume += Math.random() / 3);
    const height = volume / 1.8;
    return (
        <span
            className={(volume === 0) ? styles.bar_empty : styles.bar}
            style={{height: `${height}%`}}
        />
    )
}


const Visualizer = ({volume, mini}: VisualizerProps) => (
    <div
        className={styles.visualizer_container}
        data-is-mini={mini}
    >
        {
            volume.map((v, i) => (
                <Bar volume={v} key={i}/>
            ))
        }
    </div>
)

export default Visualizer;
