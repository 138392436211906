/* eslint-disable react/jsx-props-no-spreading */

import {Editable} from "slate-react";
import React, {useCallback} from "react";
import {getMarked} from "../utils/SlateUtilityFunctions";
import styles from '../ChatInput.module.scss';
import {useChatContext} from "../../../../context/ChatContext/context";
import {isIntegration, isThread} from "../../../../types";
import {usePolyglot} from "../../../../context/Polyglot";
import {useChatInputKeydown} from "./hooks";
import TagPopup from "../TagPopup";

const Element = ({attributes, children, element}) => {
    const style = {textAlign: element.align}
    switch (element.type) {
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ({attributes, children, leaf}) => {
    // eslint-disable-next-line no-param-reassign
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>
}

interface Props {
    handleSubmit: (val: any) => void
}

export const Editor: React.FC<Props> = ({handleSubmit}) => {
    const {chat, chatTitle, useChatAction} = useChatContext();
    const {t} = usePolyglot();

    const isChatIntegration = isIntegration(chat);
    const {
        handleKeyDown
    } = useChatInputKeydown(handleSubmit);

    const renderElement = useCallback(props => <Element {...props}/>, []);

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    const handlePaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
        const clipboardData = event.clipboardData || (event as any).dataTransfer;
        const pastedFiles = (await clipboardData.files) || clipboardData.items;

        if (!isChatIntegration && pastedFiles?.length > 0) {
            event.preventDefault();

            useChatAction({
                type: 'add_file_to_upload',
                payload: pastedFiles
            })
        }
    };

    return (
        <>
            <TagPopup/>
            <Editable
                onPaste={handlePaste}
                placeholder={isThread(chat) ? t("actions.reply_in_thread") : t('actions.message_%name', {name: chatTitle})}
                renderPlaceholder={({children, attributes}) => (
                    <span className={styles.placeholder} {...attributes}>
                    {children}
                </span>
                )}
                autoFocus
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            />
        </>
    )
}

export default Editor;
