/* eslint-disable react/jsx-props-no-spreading */
import {LabelAriaProps, useLabel} from 'react-aria';
import {ReactNode} from "react";
import styles from './styles.module.scss';

const AriaLabel = (props: LabelAriaProps) => {
    const {labelProps} = useLabel(props);

    return (
        <label
            className={styles.aria_label}
            {...labelProps}
        >
            {props.label}
        </label>
    );
}

interface BasicProps {
    children: ReactNode
    htmlFor: string
}

export const AriaLabelBasic: React.FC<BasicProps> = ({htmlFor, children}) => (
    <label
        htmlFor={htmlFor}
        className={styles.aria_label}
    >
        {children}
    </label>
)

export default AriaLabel