import {useEffect, useMemo} from 'react';
import {
    getHardwareLabel,
    SmartDiallerApp,
    SmartDiallerNumber,
    SmartDiallerRegistration,
    SmartDiallerType
} from '../../../../types';
import {StyledDropdownOption} from "../../../StyledComponents";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {
    formatSmartDiallerNumberLabel,
    moveCallToFollow,
    returnCallToDevice, selectCurrentUserId,
    selectKeypadHardware, selectSmartDialApps,
    selectSmartDiallerNumbers,
    selectSmartDiallerSelected,
    setSmartDiallerSelection,
    toggleHoldCall,
    toggleMuteCall,
    updateCallDirect
} from '../../../../redux/slices';
import {useCallContext} from "../../../../context/CallContext/context";
import { sipApi } from '../../../../redux/services/sipApi';

interface UseSmartDiallerProps {
    setCallFollow?: ({type, val, label}: { type?: SmartDiallerType, val?: string, label?: string }) => void;
    disable_self?: boolean;
}

export const useSmartDiallerCall = () => {
    const {call} = useCallContext();

    const dispatch = useTypedDispatch();

    const setCallFollow = ({type, val, label}: { type?: SmartDiallerType, val?: string, label?: string }) => {
        if (!call.callSipId) return;

        if (!type || !val || !label) {
            dispatch(returnCallToDevice(call.callSipId))
                .then(() => {
                    dispatch(updateCallDirect({
                        id: call.id,
                        smartDiallerData: undefined
                    }))
                })
            return
        }

        const target = `${type}:${val}`

        if (!call.isMuted) {
            dispatch(toggleMuteCall(call.id, !call.isMuted))
        }

        if (call.onHold) {
            dispatch(toggleHoldCall(call.id, !call.onHold))
        }

        dispatch(moveCallToFollow({callSipId: call.callSipId, target}))
            .then(() => {
                dispatch(updateCallDirect({
                    id: call.id,
                    smartDiallerData: {
                        title: label,
                        data: val,
                        type
                    }
                }))
            })
    }

    const {
        smartDiallerOpts,
    } = useSmartDiallerItems({
        setCallFollow,
        disable_self: call.smartDialOnly
    });
    return {smartDiallerOpts};
}

export const useSmartDiallerItems = ({setCallFollow, disable_self}: UseSmartDiallerProps) => {
    const smartDiallerSelected = useTypedSelector(selectSmartDiallerSelected);
    const smartDiallerNumbers = useTypedSelector(selectSmartDiallerNumbers);
    const smartDiallerApps = useTypedSelector(selectSmartDialApps);
    const {activeRegistrations, unregisteredHardware} = useTypedSelector(selectKeypadHardware);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const devicesQueryState = sipApi.endpoints.getSmartDiallerDevices.useQueryState(userUuid, {});

    const dispatch = useTypedDispatch();

    const handleFMAppSelect = (app: SmartDiallerApp) => {
        dispatch(setSmartDiallerSelection({
            type: 'app',
            data: app.uuid,
            label: app.device_name
        }))
    }

    const handleFMRegistration = (reg: SmartDiallerRegistration) => {
        dispatch(setSmartDiallerSelection({
            type: 'registration',
            data: reg.call_id,
            label: reg.nickName || reg.user_agent,
        }))
    }

    const handleFMNumber = (num: SmartDiallerNumber) => {
        dispatch(setSmartDiallerSelection({
            type: 'number',
            data: num.value,
            label: formatSmartDiallerNumberLabel(num)
        }))
    }

    const clearFM = () => {
        dispatch(setSmartDiallerSelection())
    }

    useEffect(() => {
        if (!smartDiallerSelected || !devicesQueryState.isSuccess) return;

        switch (smartDiallerSelected.type) {
            case "registration":
                if (!activeRegistrations.some(reg =>
                    (reg.call_id === smartDiallerSelected.data)

                )) {
                    clearFM()
                }
                break;
            case "app":
                if (smartDiallerApps && !smartDiallerApps.some(app =>
                    app.uuid === smartDiallerSelected.data
                )) {
                    clearFM()
                }
                break;
        }

    }, [
        smartDiallerApps?.length,
        smartDiallerNumbers?.length,
        smartDiallerSelected,
        activeRegistrations?.length
    ]);

    const smartDiallerOpts = useMemo(() => {
        const base: StyledDropdownOption[] = setCallFollow ? [
            {
                label: 'This Device',
                onSelect: () => setCallFollow({}),
                context: disable_self ? "danger" : undefined,
                disabled: disable_self ? "No audio devices on this device, unable to bring call back" : undefined,
            }
        ] : [
            {
                label: 'This Device',
                onSelect: () => clearFM(),
            }
        ]

        return base
            .concat(
                smartDiallerNumbers?.map((fmn) => ({
                    label: formatSmartDiallerNumberLabel(fmn),
                    onSelect: () => {
                        if (setCallFollow) {
                            setCallFollow({
                                type: 'number',
                                val: fmn.value,
                                label: formatSmartDiallerNumberLabel(fmn)
                            })
                        } else {
                            handleFMNumber(fmn)
                        }
                    },
                })) || []
            )
            .concat(
                (smartDiallerApps || [])
                    .map((fma: SmartDiallerApp) => ({
                        label: fma.device_name,
                        onSelect: () => {
                            if (setCallFollow) {
                                setCallFollow({
                                    type: 'app',
                                    val: fma.uuid,
                                    label: fma.device_name
                                })
                            } else {
                                handleFMAppSelect(fma)
                            }
                        }
                    }))
            )
            .concat(
                (activeRegistrations || []).map(register => ({
                    label: register.nickName || register.user_agent,
                    onSelect: () => {
                        if (setCallFollow) {
                            setCallFollow({
                                type: 'registration',
                                val: register.call_id,
                                label: register.nickName || register.user_agent
                            });
                        } else {
                            handleFMRegistration(register);
                        }
                    }
                }))
            )
            .concat(
                unregisteredHardware.length > 0
                    ? {
                        label: 'Inactive Devices',
                        divider: true,
                    }
                    : []
            )
            .concat(
                (unregisteredHardware || []).map(fmr => ({
                    label: getHardwareLabel(fmr),
                    disabled: true
                }))
            );
    }, [
        smartDiallerApps?.length,
        smartDiallerNumbers?.length,
        activeRegistrations.length,
        unregisteredHardware.length
    ]);

    const getSelectedLabel = (): string => {
        if (!smartDiallerSelected) return 'This Device'
        let found: SmartDiallerApp | SmartDiallerNumber | SmartDiallerRegistration | undefined;

        switch (smartDiallerSelected.type) {
            case "registration":
                if (!activeRegistrations) return 'Loading...';

                found = activeRegistrations.find(reg => reg.call_id === smartDiallerSelected.data)

                return found?.nickName || found?.user_agent || 'Unknown'
            case "app":
                if (!smartDiallerApps) return 'Loading...';
                found = smartDiallerApps.find(app => app.uuid === smartDiallerSelected.data)

                return found?.device_name || 'Unknown';
            case "number":
                found = smartDiallerNumbers?.find(num => num.value === smartDiallerSelected.data)

                return found ? formatSmartDiallerNumberLabel(found) : 'Unknown';
            default:
                return "Unknown"
        }

    }

    return {
        smartDiallerOpts,
        getSelectedLabel,
        isPhonesFetching: devicesQueryState.isFetching
    }
}
