import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { AppDispatch, AppThunk } from '../../store';
import { ICall, IUserStatus } from '../../../types';
import { CallDebounce, CallWhenAvailableUser, statusSlice, StatusStatus } from './slice';
import { getCallHistoryFiller, selectAllCalls } from '../callsSlice';
import { selectPostCallFetcher } from './selectors';
import { selectCurrentUserSipName, selectUserCountryCode } from '../userSlice';

export const deleteCallWhenAvailable = (userId: string) => (dispatch: AppDispatch) => {
    dispatch(statusSlice.actions.deleteCallWhenAvailable({ userId }));
};

export const addCallWhenAvailable = (user: CallWhenAvailableUser) => (dispatch: AppDispatch) => {
    dispatch(deleteCallWhenAvailable(user.user_uuid));
    dispatch(statusSlice.actions.addCallWhenAvailable({ user }));
};

export const setUserStatuses =
    (data: IUserStatus[]): AppThunk =>
    (dispatch: AppDispatch) =>
        dispatch(statusSlice.actions.setUserStatuses(data));

export const updateUserStatus =
    (data: IUserStatus): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(statusSlice.actions.updateUserStatus(data));
    };

export const setStatusApi = (setting: StatusStatus) => (dispatch: AppDispatch) => {
    dispatch(statusSlice.actions.setStatusApi(setting));
};

export const setNewFetchUsers = (newUsers: string[]) => (dispatch: AppDispatch) => {
    dispatch(statusSlice.actions.setNewFetchUsers(newUsers));
};

export const setCallDebounce = (callDebounce?: CallDebounce) => (dispatch: AppDispatch) => {
    dispatch(statusSlice.actions.setCallDebounce(callDebounce));
};

export const checkCallFetcher =
    (data: IUserStatus): AppThunk =>
    (dispatch: AppDispatch, getState) => {
        const state = getState();

        const currCalls = selectAllCalls(state);
        const postCallFetcher = selectPostCallFetcher(state);
        const currentSipName = selectCurrentUserSipName(state);
        const countryCode = selectUserCountryCode(state);

        let foundCall: ICall | undefined;

        switch (data.status) {
            case 'open':
                if (!postCallFetcher) {
                    return;
                }
                dispatch(
                    getCallHistoryFiller({
                        username: currentSipName,
                        startTimestamp: postCallFetcher
                    })
                );
                dispatch(statusSlice.actions.setPostCallFetcher(undefined));
                break;
            case 'early':
            case 'confirmed':
                if (!data.details?.remote || postCallFetcher) {
                    return;
                }
                foundCall = currCalls.find(
                    c => c.callee && parsePhoneNumberFromString(c.callee, countryCode)?.number === data.details?.remote
                );

                if (!foundCall) {
                    dispatch(statusSlice.actions.setPostCallFetcher(parseInt(data.time)));
                }

                break;
            default:
        }
    };
