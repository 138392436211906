import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {initialState, ChatTagAction, ChatTagState, reducer} from "./reducer";

interface Props {
    state: ChatTagState;
    reducer: Dispatch<ChatTagAction>;
}

export const ChatTagContext = createContext<Props | null>(null)

export const useChatTagContext = () => {
    const context = useContext(ChatTagContext)

    if (!context) {
        throw new Error(
            'Component requiring chatTag context rendered outside chatTag'
        )
    }

    const useChatTagAction: (val: ChatTagAction) => void = context.reducer

    return {
        ...context.state,
        useChatTagAction,
    }
}

export const ChatTagProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const chatTagReducer = useReducer(reducer, initialState)

    return (
        <ChatTagContext.Provider value={{state: chatTagReducer[0], reducer: chatTagReducer[1]}}>
            {children}
        </ChatTagContext.Provider>
    )
}

export default ChatTagProvider