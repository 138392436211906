
export const MoodEmojiUpset: React.FC<any> = ({
                                                    ariaHidden,
                                                    title,
                                                }) => (
    <svg
        width={45}
        height={45}
        viewBox="0 0 44 45"
        fill="none"
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">About</title>}
        <desc id="desc">Upset</desc>
            <path d="M21.9724 38.8133C31.186 38.8133 38.6551 31.3442 38.6551 22.1307C38.6551 12.9171 31.186 5.448 21.9724 5.448C12.7589 5.448 5.28974 12.9171 5.28974 22.1307C5.28974 31.3442 12.7589 38.8133 21.9724 38.8133Z" fill="url(#paint0_radial_4_369)"/>
            <path opacity="0.5" d="M21.9724 38.8133C31.186 38.8133 38.6551 31.3442 38.6551 22.1307C38.6551 12.9171 31.186 5.448 21.9724 5.448C12.7589 5.448 5.28974 12.9171 5.28974 22.1307C5.28974 31.3442 12.7589 38.8133 21.9724 38.8133Z" fill="url(#paint1_radial_4_369)"/>
            <path d="M19.1259 16.2603C19.3032 15.6139 18.7089 15.0195 17.5098 14.6963C16.488 14.4252 14.1003 14.3835 12.0253 15.9579C11.6396 16.2499 12.0253 16.5627 12.3694 16.4063C13.5372 15.8537 16.3524 15.4991 18.2814 16.2499C19.0529 16.5522 19.1259 16.2603 19.1259 16.2603Z" fill="url(#paint2_linear_4_369)"/>
            <path d="M16.5193 23.0273C17.4061 23.0273 18.125 21.9396 18.125 20.5979C18.125 19.2561 17.4061 18.1685 16.5193 18.1685C15.6325 18.1685 14.9136 19.2561 14.9136 20.5979C14.9136 21.9396 15.6325 23.0273 16.5193 23.0273Z" fill="url(#paint3_radial_4_369)"/>
            <path d="M16.5193 18.9816C17.2075 18.9816 17.8122 19.5864 18.125 20.4727C18.1042 19.1589 17.3952 18.1058 16.5193 18.1058C15.6435 18.1058 14.9345 19.1589 14.9136 20.4727C15.2264 19.5864 15.8312 18.9816 16.5193 18.9816Z" fill="url(#paint4_linear_4_369)"/>
            <path d="M14.9761 32.1821C15.1117 32.2029 15.2368 32.1612 15.3306 32.0778C17.0302 30.5763 19.6577 29.7631 21.6283 29.7631H21.9724H22.3165C24.2871 29.7631 26.9147 30.5763 28.6142 32.0778C28.708 32.1612 28.8436 32.2029 28.9687 32.1821C29.1043 32.1612 29.1772 32.0152 29.1042 31.9006C27.6549 29.5233 24.9649 28.0531 22.0558 28.0531H21.9724H21.889C18.9695 28.0531 16.2898 29.5233 14.8406 31.9006C14.7572 32.0152 14.8302 32.1612 14.9761 32.1821Z" fill="#643800"/>
            <path d="M14.9762 32.1819C15.1117 32.2027 15.2368 32.161 15.3307 32.0776C17.0302 30.5761 19.6577 29.7628 21.6284 29.7628H21.9725H22.3166C24.2872 29.7628 26.9147 30.5761 28.6142 32.0776C28.7081 32.161 28.8437 32.2027 28.9688 32.1819C29.1043 32.161 29.1773 32.015 29.1043 31.9003C29.0939 31.8795 29.073 31.8586 29.0626 31.8273C28.9271 31.9003 28.7811 31.9733 28.6247 31.8169C27.0086 30.1069 24.6208 29.0851 22.0559 29.0851H21.962H21.8682C19.2928 29.0851 16.8843 30.1173 15.2681 31.8586C15.1221 32.015 14.9866 31.942 14.8511 31.8586C14.8406 31.8795 14.8302 31.8899 14.8198 31.9107C14.7572 32.015 14.8302 32.161 14.9762 32.1819Z" fill="url(#paint5_linear_4_369)"/>
            <path d="M24.819 16.2603C24.6417 15.6139 25.236 15.0195 26.4351 14.6963C27.4569 14.4252 29.8446 14.3835 31.9195 15.9579C32.3053 16.2499 31.9195 16.5627 31.5754 16.4063C30.4076 15.8537 27.5924 15.4991 25.6635 16.2499C24.8919 16.5522 24.819 16.2603 24.819 16.2603Z" fill="url(#paint6_linear_4_369)"/>
            <path d="M27.4152 23.0273C28.302 23.0273 29.0209 21.9396 29.0209 20.5979C29.0209 19.2561 28.302 18.1685 27.4152 18.1685C26.5284 18.1685 25.8095 19.2561 25.8095 20.5979C25.8095 21.9396 26.5284 23.0273 27.4152 23.0273Z" fill="url(#paint7_radial_4_369)"/>
            <path d="M27.4152 18.9816C28.1033 18.9816 28.7081 19.5864 29.0209 20.4727C29 19.1589 28.291 18.1058 27.4152 18.1058C26.5394 18.1058 25.8303 19.1589 25.8095 20.4727C26.1223 19.5864 26.727 18.9816 27.4152 18.9816Z" fill="url(#paint8_linear_4_369)"/>
            <defs>
                <radialGradient id="paint0_radial_4_369" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.6859 15.3697) scale(20.1255 20.1255)">
                    <stop stopColor="#FFE030"/>
                    <stop offset="1" stopColor="#FFB92E"/>
                </radialGradient>
                <radialGradient id="paint1_radial_4_369" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.6859 15.3697) scale(15.8336)">
                    <stop stopColor="#FFEA5F"/>
                    <stop offset="1" stopColor="#FFBC47" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint2_linear_4_369" x1="15.1565" y1="17.4744" x2="15.8773" y2="14.7844" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint3_radial_4_369" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0417 20.6595) rotate(73.8539) scale(2.35714 1.52503)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint4_linear_4_369" x1="16.5217" y1="18.1053" x2="16.5217" y2="20.4743" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
                <linearGradient id="paint5_linear_4_369" x1="21.9684" y1="28.2192" x2="21.9684" y2="30.5551" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
                <linearGradient id="paint6_linear_4_369" x1="28.7585" y1="17.539" x2="28.0377" y2="14.849" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#7A4400"/>
                </linearGradient>
                <radialGradient id="paint7_radial_4_369" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.9353 20.6597) rotate(73.8539) scale(2.35714 1.52503)">
                    <stop offset="0.00132565" stopColor="#7A4400"/>
                    <stop offset="1" stopColor="#643800"/>
                </radialGradient>
                <linearGradient id="paint8_linear_4_369" x1="27.415" y1="18.1053" x2="27.415" y2="20.4743" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00132565" stopColor="#3C2200"/>
                    <stop offset="1" stopColor="#512D00"/>
                </linearGradient>
            </defs>
        </svg>

        )

export default MoodEmojiUpset

