import React, {useState, Suspense} from "react";
import {ChatIcon} from "../../../../../components";
import {ChatStatus} from "../../../ChatHeader/ChatHeader";
import LoadingSpinner from "../../../../../components/loading/LoadingSpinner";
import {useChatContext} from "../../../../../context/ChatContext/context";
import {useTypedSelector} from "../../../../../redux/hooks";
import {
    selectAuthUserByUuid,
    selectChatEntities,
    selectCurrentUserId,
    selectUserStatus
} from "../../../../../redux/slices";
import ModalFormContainer from "../../../../../components/forms/modalFormContainer/ModalFormContainer";
import ChatGroupForm from "../../../../../components/forms/ChatGroupForm";
import {StyledButton} from "../../../../../components/StyledComponents";
import styles from '../styles.module.scss';
import {usePolyglot} from "../../../../../context/Polyglot";

interface Props {
    adminUuids?: string[];
}

const ChatSideBarHeader: React.FC<Props> = ({adminUuids}) => {
    const {chat, chatTitle, parentMessageChatId, noPriorChatHistory} = useChatContext();

    const {t} = usePolyglot()

    const chatDictionary = useTypedSelector(selectChatEntities);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const [showGroupEdit, setShowGroupEdit] = useState<boolean>(false);

    let userStatus: { connected: boolean; last_seen: number; status?: string } | undefined;

    if (chat?.type === 'user') {
        const chatUser = useTypedSelector(state => selectAuthUserByUuid(state, chat.uuid))?.name;
        userStatus = chatUser ? useTypedSelector(state => selectUserStatus(state, chatUser))?.chat : undefined;
    }

    return (
        <div className={styles.chat_sidebar_data}>
            <div className={styles.icon_container}>
                <ChatIcon
                    chat={(parentMessageChatId && chatDictionary[parentMessageChatId]) || chat}
                />
            </div>
            <div className={styles.chat_sidebar_name}>
                <p title={chatTitle}>{chatTitle}</p>
                {!noPriorChatHistory && chat.type === 'user' && userStatus && (
                    <div>
                        <ChatStatus chatStatus={userStatus}/>
                    </div>
                )}
            </div>
            {chat?.type === 'channel' && adminUuids?.includes(userUuid) && (
                <StyledButton
                    size='medium'
                    buttonStyle='tertiary'
                    context='danger'
                    onClick={() => {
                        setShowGroupEdit(true)
                    }}
                >
                    {t("actions.edit")}
                </StyledButton>
            )}
            <ModalFormContainer
                showModal={showGroupEdit}
                setShowModal={(val: boolean) => setShowGroupEdit(val)}
            >
                <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                    {showGroupEdit ? (
                        <ChatGroupForm
                            closeEditor={() => setShowGroupEdit(false)}
                        />
                    ) : null}
                </Suspense>
            </ModalFormContainer>
        </div>
    )
}

export default ChatSideBarHeader
