import React from 'react';
import {Picker, BaseEmoji} from 'emoji-mart-virtualized';
import {usePolyglot} from "../../context/Polyglot";

interface EmojiMenuProps {
    closeMenu: () => void;
    handleEmoji: (val: BaseEmoji) => void
}

export const EmojiMenu: React.FC<EmojiMenuProps> = ({closeMenu, handleEmoji}) => {
    const {t} = usePolyglot();

    return (
        <div
            className='emoji-menu'
            // onMouseLeave={closeMenu}
        >
            <Picker
                set='apple'
                title={t("phrases.pick_your_emoji")}
                perLine={8}
                showPreview={false}
                showSkinTones={false}
                autoFocus
                onClick={(emoji, e) => {
                    handleEmoji(emoji)
                    if (!e.shiftKey) {
                        closeMenu()
                    }
                }}
            />
        </div>
    )
}
