import React, {lazy, Suspense, useState} from "react";
import {
    selectCurrentUserId,
    selectDepartmentByUserUuid,
    selectSelectableAccounts, setViewContact
} from "../../../../../../redux/slices";
import {usePolyglot} from "../../../../../../context/Polyglot";
import {useTypedDispatch, useTypedSelector} from "../../../../../../redux/hooks";
import styles from "./ProfilePicture.module.scss";
import ModalFormContainer from "../../../../../../components/forms/modalFormContainer/ModalFormContainer";
import LoadingSpinner from "../../../../../../components/loading/LoadingSpinner";
import {useAccountAvatarChange} from "../../hooks";
import {StyledButton} from "../../../../../../components/StyledComponents";
import DepartmentTag from "../../../../../../components/buttons/DepartmentTag";
import EntityIcon from "../../../../../../components/icons/EntityIcon";

const ImageEditor = lazy(() => import("../../../../../../components/forms/ImageEditor/ImageEditor"))

const ProfilePicture = () => {
    const user = useTypedSelector(state => state.user);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const accounts = useTypedSelector(selectSelectableAccounts);
    const userAvatar = useTypedSelector(state => state.user.avatar_key);

    const departments = useTypedSelector(state => selectDepartmentByUserUuid(state, userUuid))

    const dispatch = useTypedDispatch();

    const [showProfileImageEditor, setShowProfileImageEditor] = useState<boolean>(false);

    const {t} = usePolyglot();

    const {
        upload,
        uploadLoading,
        uploadSuccess,
        uploadError
    } = useAccountAvatarChange();

    const accountName = accounts?.find(a => a.user_details.uuid === userUuid)?.account_name;

    return (
        <div className={styles.section_group}>
            <div className={styles.profile}>
                <EntityIcon
                    type='user'
                    details={{
                        uuid: user.uuid,
                        avatar_key: user.avatar_key,
                        sip_name: user.name,
                        display_name: user.nickname,
                        blf: false
                    }}
                    size={80}
                    onClick={() => {
                        dispatch(setViewContact(user))
                    }}
                />
                <div className={styles.user_details}>
                    <h2>
                        {`${user.display_name} (${user.extension})`}
                    </h2>
                    <p>{accountName || user.devices[0]?.account_name || ''}</p>
                </div>
            </div>
            <StyledButton
                buttonStyle='tertiary'
                size='small'
                fontWeight='medium'
                onClick={() => {
                    setShowProfileImageEditor(!showProfileImageEditor)
                }} key={3}
            >
                {t("actions.change_image")}
            </StyledButton>
            {departments ? (
                <div className={styles.departments}>
                    {departments.map(dept => (
                        <DepartmentTag
                            key={dept.uuid}
                            department={dept}
                        />
                    ))}
                </div>
            ) : null}
            <ModalFormContainer
                showModal={showProfileImageEditor}
                setShowModal={(val: boolean) => setShowProfileImageEditor(val)}
            >
                <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                    {showProfileImageEditor ? (
                        <ImageEditor
                            title={t("phrases.edit_save_profile_picture")}
                            initialSrc={userAvatar ? `https://storage.googleapis.com/v-profiles/${userAvatar}` : undefined}
                            closeEditor={() => setShowProfileImageEditor(false)}
                            upload={upload}
                            uploadLoading={uploadLoading}
                            uploadSuccess={uploadSuccess}
                            uploadError={uploadError}
                            userPreview
                        />
                    ) : null}
                </Suspense>
            </ModalFormContainer>
        </div>
    )
}

export default ProfilePicture;
