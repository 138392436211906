/* eslint-disable react/jsx-props-no-spreading */
import {CheckboxGroupState, useCheckboxGroupState} from 'react-stately';
import {useCheckboxGroup, useCheckboxGroupItem} from 'react-aria';
import {createContext, ReactNode, useContext, useRef} from "react";
import {AriaCheckBoxGroupProps, AriaCheckBoxProps} from "./types";
import styles from './styles.module.scss';

interface ProviderProps {
    value: CheckboxGroupState;
    children: ReactNode;
}

const CheckboxGroupContext = createContext<CheckboxGroupState | null>(null)

export const useCheckboxContext = () => {
    const context = useContext(CheckboxGroupContext)

    if (!context) {
        throw new Error(
            'Component requiring Checkbox Group context rendered without contact'
        )
    }

    return context
}

export const CheckboxProvider: React.FC<ProviderProps> = ({value, children}) => (
    <CheckboxGroupContext.Provider value={value}>
        {children}
    </CheckboxGroupContext.Provider>
)

export const AriaCheckboxGroup: React.FC<AriaCheckBoxGroupProps> = (props) => {
    const {children, description, errorMessage, validationState} = props;
    const state = useCheckboxGroupState(props);
    const {groupProps, descriptionProps, errorMessageProps} = useCheckboxGroup(props, state);

    if (!state) return null

    return (
        <div
            {...groupProps}
        >
            {/*
            <span
                {...labelProps}
            >
                {label}
            </span>
            */}
            <CheckboxProvider value={state}>
                {children}
            </CheckboxProvider>
            {description && (
                <div
                    {...descriptionProps}
                    style={{fontSize: 12}}>
                    {description}
                </div>
            )}
            {errorMessage && validationState === 'invalid' && typeof errorMessage !== 'function' &&
                (
                    <div {...errorMessageProps} style={{color: 'red', fontSize: 12}}>
                        {errorMessage}
                    </div>
                )}
        </div>
    );
}

export const AriaCheckbox: React.FC<AriaCheckBoxProps> = (props) => {
    const {children, dataTags} = props;
    const state = useCheckboxContext();
    const ref = useRef(null);

    const {inputProps} = useCheckboxGroupItem(props, state, ref);

    // const isDisabled = state.isDisabled || props.isDisabled;
    // const isSelected = state.isSelected(props.value);

    return (
        <label
            className={styles.checkbox_item}
            {...dataTags}
            data-disabled={props.isDisabled || null}
        >
            <input
                {...inputProps}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.currentTarget.click();
                    }
                }}
                ref={ref}
                disabled={props.isDisabled}
            />
            <p
                style={dataTags?.["data-department-color"] ? {
                    color: `${dataTags?.["data-department-color"]}`,
                    textShadow: '0.5px 0.5px 1px rgba(var(--primary-font-color-rgb), 0.5)'
                } : undefined}
            >
                {children}
            </p>
        </label>
    );
}