import React, {FunctionComponent, ReactNode} from 'react';
import {FormProvider} from "react-hook-form";
import {useCallFeedbackForm} from "./hooks";
import {usePolyglot} from "../../../context/Polyglot";
import {CallFeedbackTag, CdrForm} from "../../../types";
import {useTypedSelector} from "../../../redux/hooks";
import MoodEmojiUpsetV from "./children/emojis/MoodEmojiUpsetV";
import MoodEmojiUpset from "./children/emojis/MoodEmojiUpset";
import {MoodEmojiNeutral} from "./children/emojis/MoodEmojiNeutral";
import MoodEmojiHappy from "./children/emojis/MoodEmojiHappy";
import MoodEmojiHappyV from "./children/emojis/MoodEmojiHappyV";
import FormTextField from "../../AriaForm/FormTextField";
import styles from './styles.module.scss'
import formStyles from '../../../sass/globalModules/forms.module.scss'
import FormMultiselectCreatable from "../../AriaForm/FormMultiselectCreatable";
import {
    selectCallFeedbackTags,
    selectCallHistoryById,
    selectCanCreateFeedbackTags, selectCurrentUserSipName, selectPhonebookEntityInArr, selectUserCountryCode
} from "../../../redux/slices";
import LoadingSpinner from "../../loading/LoadingSpinner";
import Icon from "../../Icon";
import FormRadioGroup from "../../AriaForm/FormRadioGroup";
import {StyledButton} from "../../StyledComponents";
import {getNumbersArr} from "../../../pages/phone/call-history/CallHistoryRow/ExternalCallHistoryRow";

interface Props {
    activeForm: feedbackForm;
    active: boolean;
    formBefore: boolean;
    formAfter: boolean;
    changePage: (val: 'back' | 'next') => void;
}

interface feedbackForm extends CdrForm {
    call_uuid: string;
    invite_uuid: string;
}

const moodRadios: { value: '20' | '40' | '60' | '80' | '100', icon: ReactNode }[] = [
    {
        value: '20',
        icon: <MoodEmojiUpsetV/>
    },
    {
        value: '40',
        icon: <MoodEmojiUpset/>
    },
    {
        value: '60',
        icon: <MoodEmojiNeutral/>
    },
    {
        value: '80',
        icon: <MoodEmojiHappy/>
    },
    {
        value: '100',
        icon: <MoodEmojiHappyV/>
    },
]
const textEncoder = new TextEncoder();

const getColor = (val: string): string => {
    // defined colors
    const colors = [
        '#2F97C1', '#1CCAD8', '#FF0022', '#8B80F9',
        '#D64933', '#F05365', '#340068', '#AF1B3F',
        '#63B995', '#FF312E', '#688E26', '#1C448E',
        '#7D5BA6', '#6461A0', '#F42C04', '#4BB3FD',
        '#00487C', '#B80C09', '#4D8B31', '#E83F6F'
    ]
    // get array of unit 8 numbers of characters in the tag
    const encoded = Array.from(textEncoder.encode(val))

    // generate a hash value based on the encoded array
    const hashNumber = encoded.reduce((prev = 0, curr, index) => prev + (curr * (index + 1)))

    // get color based on the modulo of the hash value
    return colors[hashNumber % colors.length];
}

const CallFeedbackForm: FunctionComponent<Props> = ({activeForm, active, formBefore, formAfter, changePage}) => {
    const feedbackTags: CallFeedbackTag[] = useTypedSelector(selectCallFeedbackTags);
    const canCreateFeedbackTags = useTypedSelector(selectCanCreateFeedbackTags);
    const countryCode = useTypedSelector(selectUserCountryCode);
    const userSipName = useTypedSelector(selectCurrentUserSipName);

    const callInfo = useTypedSelector((state) => selectCallHistoryById(state, activeForm.invite_uuid));

    let callee = ''

    if (callInfo) {
        callee = !callInfo.from || callInfo.from.name === userSipName
                ? callInfo.to?.number || ''
                : callInfo.from.number || ''
    }

    const entity = useTypedSelector(state => selectPhonebookEntityInArr(state, getNumbersArr(callee, countryCode)))

    const {
        formMethods,
        onSubmit,
        onClose,
        submitLoading,
        submitError,
    } = useCallFeedbackForm(
        activeForm.call_uuid,
        activeForm.invite_uuid
    )

    const {t} = usePolyglot();

    if (!active) return null

    return (
        <section className={[styles.formContainer, formStyles.formContainer].join(' ')}>
            <section className={formStyles.formHeader}>
                {formBefore ? (
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        onClick={() => changePage('back')}
                        className={styles.backBtn}
                    >
                        <Icon name='arrowLeft'/>
                    </StyledButton>
                ) : null}
                <h2>
                    {t("terms.call_feedback")}
                </h2>
                {formAfter ? (
                    <StyledButton
                        buttonStyle='text'
                        iconOnly
                        dataTags={{
                            'data-pos': 'right',
                        }}
                        onClick={() => changePage('next')}
                        className={styles.nextBtn}
                    >
                        <Icon name='arrowRight'/>
                    </StyledButton>
                ) : null}
                {submitError ? (
                    <p>
                        {t("phrases.error_unable_to_save_feedback_please_try_again")}
                    </p>
                ) : null}
            </section>
            {callInfo ? (
                <div className={styles.call_info}>
                    <div>
                        <p>{entity?.name || callee}</p>
                        {entity ? (
                            <p>({callee})</p>
                        ) : null}
                    </div>
                    <div>
                        {callInfo.call_type === 'inbound' ? (
                            <i className='icon-incoming' title={t("terms.inbound_call", 1)}/>
                        ) : (
                            <i className='icon-outgoing' title={t("terms.outbound_call", 1)}/>
                        )}
                        <span>{new Date(1000 * callInfo.call_duration).toISOString().substr(11, 8)}</span>
                    </div>
                </div>
            ) : null}
            <FormProvider
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...formMethods}
            >
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormMultiselectCreatable
                        name='tag'
                        label={t("actions.add_tags")}
                        options={feedbackTags.map((tag) => (
                            {
                                value: tag.name,
                                label: tag.name
                            }
                        ))}
                        getColor={getColor}
                        noCreate={!canCreateFeedbackTags}
                    />
                    <FormTextField
                        name='note'
                        label={t("terms.call_note", 1)}
                        isTextArea
                    />
                    <FormRadioGroup
                        name='mood'
                        label={t("phrases.what_was_the_mood_of_this_call")}
                        options={moodRadios}
                        orientation='horizontal'
                    />
                    <div className={formStyles.formControls}>
                        {submitLoading ? <LoadingSpinner color='mamba'/> : (
                            <>
                                <StyledButton
                                    buttonStyle='text'
                                    context='danger'
                                    onClick={() => onClose()}
                                >
                                    <p>
                                        {t("actions.cancel")}
                                    </p>
                                </StyledButton>
                                <StyledButton
                                    buttonStyle='primary'
                                    size='large'
                                    type="submit"
                                >
                                    <p>
                                        {t("actions.submit")}
                                    </p>
                                </StyledButton>
                            </>
                        )}
                    </div>
                </form>
            </FormProvider>
        </section>
    );
};

export default CallFeedbackForm;
