import React, {ReactNode} from 'react';
import {AudioVideo, Calls, Chat, Contacts, Index, Shortcodes} from '../settings';
import Notifications from "./SettingsPages/Notifications";
import {selectSettingsPage} from "../../redux/slices";
import {useTypedSelector} from "../../redux/hooks";

interface Props {
}

export const Settings: React.FC<Props> = () => {
    const settingsPage = useTypedSelector(selectSettingsPage)

    let activePage: ReactNode = <Index/>;

    switch (settingsPage) {
        case 'account':
            activePage = <Index/>;
            break;
        case 'calls':
            activePage = <Calls/>
            break;
        case 'chat':
            activePage = <Chat/>
            break;
        case 'notifications':
            activePage = <Notifications/>
            break;
        case 'contacts':
            activePage = <Contacts/>
            break;
        case 'audio':
            activePage = <AudioVideo/>
            break;
        case 'shortcodes':
            activePage = <Shortcodes/>
    }

    return (
        <div className='settings'>
            {activePage}
        </div>
    );
};
