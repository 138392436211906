import React from "react";
import {MeetingRoomApi} from "../../../../types/video";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {selectActiveRoomId, setActiveRoomId, setVideoPage} from "../../../../redux/slices";
import {usePolyglot} from "../../../../context/Polyglot";
import styles from '../styles.module.scss';
import AriaButton from "../../../AriaComponents/AriaButton";
import {getVideoBaseUrl} from "../../../../pages/video/helpers";

interface MeetingRowProps {
    meeting: MeetingRoomApi;
}

export const MeetingRow: React.FC<MeetingRowProps> = ({meeting}) => {
    const activeRoomId = useTypedSelector(selectActiveRoomId);

    const baseUrl = getVideoBaseUrl();

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    const onPreview = () => {
        dispatch(setActiveRoomId(meeting.uuid))
        dispatch(setVideoPage('meeting-preview'))
    }

    return (
        <AriaButton
            className={styles.meeting_row}
            title={t("actions.view_meeting_details")}
            onClick={() => onPreview()}
            data-active={activeRoomId === meeting.uuid}
        >
            <p>{meeting.name}</p>
            <span>{baseUrl}/{meeting.custom_url}</span>
        </AriaButton>
    )
}

export default MeetingRow;