import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {selectCurrentUserId, setFilePreview} from "../../../../redux/slices";
import {AnimatedDiv} from "../../../../animation";
import Icon from "../../../../components/Icon";
import styles from "../Attachment.module.scss";
import {AttachmentNameDisplay, GenericAttachmentProps} from "./GenerticAttachment";
import AriaButton from "../../../../components/AriaComponents/AriaButton";


interface ImageAttachmentProps extends GenericAttachmentProps {
    mini: boolean;
    mode: 'delete' | 'download';
    downloadFile: () => void;
    showOptions: boolean;
}

const Preview = ({file, activatePreview, src}) => (
    <div className={styles.preview_container}>
        <div>
            <img
                className='image-preview'
                src={src}
                alt={file.name}
                onClick={activatePreview}
            />
        </div>
    </div>
)

const MiniPreview = ({file, activatePreview, src}) => {
    const metaData = (file?.metadata && JSON.parse(file.metadata)) || undefined;

    return (
        <div className={styles.preview_container_mini}>
            <div>
                <img
                    className='image-preview'
                    src={metaData?.thumbnail_width ? src.replace("thumbnail=false", "thumbnail=true") : src}
                    alt={file.name}
                    onClick={activatePreview}
                />
            </div>
        </div>
    )
}

interface DownloadBtnProps {
    download: () => void;
}

export const DownloadButton: React.FC<DownloadBtnProps> = ({download}) => (
    <AriaButton
        onClick={() => download()}
        className={styles.download_button}
    >
        <Icon name='arrowDownWithLine'/>
    </AriaButton>
)

export const ImageAttachment = ({
                                    file,
                                    mini,
                                    mode,
                                    downloadFile,
                                    showOptions
                                }: ImageAttachmentProps) => {
    const {name, size, shared_at, shared_by} = file;

    const dispatch = useTypedDispatch();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const src = mode === 'delete' ? URL.createObjectURL(file) : `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${file.uuid}&content=true&thumbnail=false`;

    const activatePreview = (e) => {
        e.stopPropagation();
        dispatch(setFilePreview({
            src,
            file_name: name,
            file_type: 'image',
            file_size: size,
            shared_by,
            shared_at,
            uuid: file.uuid
        }))
    }

    return (
        <>
            {mini ?
                <MiniPreview file={file} activatePreview={activatePreview} src={src}/>
                : <Preview file={file} activatePreview={activatePreview} src={src}/>
            }
            <AnimatedDiv
                visible={mini || showOptions}
                className={styles.file_content_container}
                onClick={(e) => !mini && activatePreview(e)}
                inAnimation={['fade', 'slideFromBottom']}
                transition={{duration: 0.4}}
            >
                <AttachmentNameDisplay file={file}/>
                {!mini &&
                    <DownloadButton download={() =>
                        downloadFile()
                    }/>
                }
            </AnimatedDiv>
        </>
    )
}