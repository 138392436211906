import React, {lazy, useEffect, Suspense} from "react";
import styles from "../../Keypad.module.scss";
import {Answer} from "../../../buttons";
import Icon from "../../../Icon";
import {
    selectAppAllowConference,
    selectLastCalledNumber,
    selectUsersForGroupCall,
    startCall
} from '../../../../redux/slices';
import {playTone, usePrevious} from "../../../../helpers";
import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {usePolyglot} from "../../../../context/Polyglot";
import AriaLongPressButton from "../../../AriaComponents/AriaLongPressButton";
import AriaButton from "../../../AriaComponents/AriaButton";
import {useConference} from "../../ConferenceHooks";

const KeypadContacts = lazy(() => import('../../KeypadContacts/KeypadContacts'))

interface CallCreationProps {
    searchTerm: string;
    setSearchTerm: (val: string) => void;
    makingGroupCall: boolean;
    userSearching: boolean;
    contactSearchInputRef: HTMLInputElement | null;
    setMakingGroupCall: (val: boolean) => void;
    groupMenuOpen?: boolean;
}

interface IKeypadButton {
    button: number | '*' | '#';
    extraText?: string;
    hold?: string;
}

const keypadButtons: IKeypadButton[] = [
    {
        button: 1
    },
    {
        button: 2,
        extraText: 'ABC'
    },
    {
        button: 3,
        extraText: 'DEF'
    },
    {
        button: 4,
        extraText: 'GHI'
    },
    {
        button: 5,
        extraText: 'JKL'
    },
    {
        button: 6,
        extraText: 'MNO'
    },
    {
        button: 7,
        extraText: 'PQRS'
    },
    {
        button: 8,
        extraText: 'TUV'
    },
    {
        button: 9,
        extraText: 'WXYZ'
    },
    {
        button: '*',
    },
    {
        button: 0,
        extraText: '+',
        hold: '+',
    },
    {
        button: '#',
    },
]

export const CallCreation: React.FC<CallCreationProps> = ({
                                                              searchTerm,
                                                              setSearchTerm,
                                                              makingGroupCall,
                                                              userSearching,
                                                              contactSearchInputRef,
                                                              setMakingGroupCall,
                                                              groupMenuOpen,
                                                          }) => {
    const conferenceAllowed = useTypedSelector(selectAppAllowConference);
    const invitationList = useTypedSelector(selectUsersForGroupCall);
    const lastCalled = useTypedSelector(selectLastCalledNumber);

    const prevInvitationLength = usePrevious(invitationList.length)

    const {commitToRoom} = useConference()

    const {t} = usePolyglot()

    const dispatch = useTypedDispatch()

    useEffect(() => {
        if (!prevInvitationLength || invitationList.length > prevInvitationLength) {
            setSearchTerm('');
            contactSearchInputRef?.focus();
        }
    }, [invitationList.length]);

    const keypadPress = (val: string) => {
        if (val === 'delete') {
            setSearchTerm(searchTerm.slice(0, -1))
        } else if (val === 'call') {
            dispatch(startCall(searchTerm));
        } else {
            playTone({key: val});
            setSearchTerm(searchTerm + val)
        }
        contactSearchInputRef?.focus();
    }

    const keypadHold = (val: string) => {
        setSearchTerm(searchTerm + val);
        playTone({key: '0'})
        contactSearchInputRef?.focus();
    }


    const handleCreateConference = () => {
        const invitationNumbers = invitationList.map((c) => {
            if (c.selected_number) {
                return c.selected_number
            }

            if (c.extension_number) {
                return c.extension_number.toString()
            }

            return c.extension.toString()
        })

        commitToRoom({invitationNumbers})
    }

    let display;

    switch (true) {
        case makingGroupCall:
        case userSearching:
            display = (
                <>
                    <Suspense>
                        <KeypadContacts
                            searchTerm={searchTerm}
                            notInCall
                            addToCall={makingGroupCall}
                            isSmall={makingGroupCall && groupMenuOpen}
                        />
                    </Suspense>
                    <div className={styles.callCreationControls}>
                        <div>
                            {conferenceAllowed ? (
                                <AriaButton
                                    className={styles.goBack}
                                    onClick={() => setMakingGroupCall(!makingGroupCall)}
                                    title={t("phrases.single_call")}
                                >
                                    <Icon name={makingGroupCall ? 'single' : 'group'} ariaHidden/>
                                </AriaButton>
                            ) : (
                                <div/>
                            )}
                        </div>
                        <div>
                            <Answer
                                title={t("actions.start_call")}
                                onClick={() =>
                                    makingGroupCall ?
                                        handleCreateConference()
                                        : keypadPress("call")
                                }
                            />
                        </div>
                    </div>
                </>
            )
            break;
        default:
            display = (
                <div className={[styles.number_keypad, styles.active_tab].join(' ')}>
                    <div className={styles.dial}>
                        {keypadButtons.map((kb: IKeypadButton) => {
                            if (kb.hold) {
                                return (
                                    <AriaLongPressButton
                                        key={`${kb.button} ${kb.extraText || ''}`}
                                        longPress={() => keypadHold(kb.hold as string)}
                                        onClick={() => keypadPress(String(kb.button))}
                                        longPressDescription={`Hold to dial ${kb.hold}`}
                                        tabIndex={-1}
                                        aria-hidden="true"
                                    >
                                        <p>
                                            {kb.button}
                                        </p>
                                        {kb.extraText ? (
                                            <span>
                                                {kb.extraText}
                                            </span>
                                        ) : null}
                                    </AriaLongPressButton>
                                )
                            }
                            return (
                                <AriaButton
                                    key={`${kb.button} ${kb.extraText || ''}`}
                                    onClick={() => keypadPress(String(kb.button))}
                                    dataTags={{
                                        'data-center': ['*', '#'].includes(kb.button as any) || null
                                    }}
                                    tabIndex={-1}
                                    aria-hidden="true"
                                >
                                    <p>
                                        {kb.button}
                                    </p>
                                    {kb.extraText ? (
                                        <span>
                                            {kb.extraText}
                                        </span>
                                    ) : null}
                                </AriaButton>
                            )
                        })}
                        {conferenceAllowed ? (
                            <AriaButton
                                onClick={() => setMakingGroupCall(true)}
                                title={t("phrases.create_group_call")}
                                dataTags={{
                                    'data-backspace': true
                                }}
                            >
                                <Icon name='group' width={24} height={24}/>
                            </AriaButton>
                        ) : (
                            <div/>
                        )}
                        {(lastCalled && !searchTerm) ? (
                            <Answer
                                onClick={() => dispatch(startCall(lastCalled?.callee, lastCalled?.display_name))}
                                title={`Call ${lastCalled.display_name ? `${lastCalled.display_name} - ${lastCalled.callee}` : lastCalled.callee}`}
                            />
                        ) : (
                            <Answer
                                onClick={() => keypadPress("call")}
                                title={t("actions.start_call")}
                            />
                        )}
                        <AriaButton
                            onClick={() => keypadPress("delete")}
                            dataTags={{
                                'data-backspace': true
                            }}
                            tabIndex={-1}
                        >
                            <Icon name="keypadDelete"/>
                        </AriaButton>
                    </div>
                </div>
            )
    }

    return (
        <div className={styles.active_tab}>
            {display}
        </div>
    )
}