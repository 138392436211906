/* eslint-disable jsx-a11y/no-autofocus */
import React, {useRef, useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {Slider} from "../../../components";
import {
    changeCallerId, formatSmartDiallerNumberLabel,
    putCallHandling, removeSmartDiallerNumber,
    selectAllCallerIds,
    selectCallHandling,
    selectCallRecording,
    selectCurrentCallerId,
    selectCurrentUserId,
    selectDashboardUrl, selectSmartDiallerNumbers,
    selectGlobalCallRecording,
    selectPhoneSettingByKey,
    updatePhoneSettings, selectAttendedTransferOpt, updateAttendedTransfer, selectCurrentUserQueueAvailability
} from "../../../redux/slices";
import {usePutAvailableInCallQueuesMutation, usePutCallRecordingMutation} from "../../../redux/services/sipApi";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import AdvancedCallHandling from "../AdvancedCallHandling";
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../../components/menus/MenuHeader";
import CallerIDs from "../../../components/menus/CallerIDs";
import styles from '../SettingsPage.module.scss';
import SettingsSlider from "./SettingsComponents/SettingsSlider";
import SettingsDropdown from "./SettingsComponents/SettingsDropdown";
import SettingsSelectedList from "./SettingsComponents/SettingsSelectedList";
import {StyledButton} from "../../../components/StyledComponents";
import Icon from "../../../components/Icon";
import ModalFormContainer from "../../../components/forms/modalFormContainer/ModalFormContainer";
import SmartDiallerNumberRegister from "../../../components/forms/SmartDiallerNumberForm/SmartDiallerNumberRegister";

interface CallsProps {
}

const isElectron = navigator.userAgent.toLowerCase().includes(' electron/');

export const Calls: React.FC<CallsProps> = () => {
    const queueAvailability = useTypedSelector(selectCurrentUserQueueAvailability);
    const attendedTransferDefault = useTypedSelector(selectAttendedTransferOpt);
    const globalCallRecording = useTypedSelector(selectGlobalCallRecording);
    const smartDiallerNumbers = useTypedSelector(selectSmartDiallerNumbers);
    const userCallerUuid = useTypedSelector(selectCurrentCallerId);
    const callRecording = useTypedSelector(selectCallRecording);
    const dashboardURL = useTypedSelector(selectDashboardUrl);
    const callHandling = useTypedSelector(selectCallHandling);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const callerIds = useTypedSelector(selectAllCallerIds);

    const autoAnswerClickToDial = useTypedSelector(state => selectPhoneSettingByKey(state, 'autoAnswerClickToDial'));
    const phonebooksInContacts = useTypedSelector(state => selectPhoneSettingByKey(state, 'phonebooksInContacts'));
    const disableHoldToParkCall = useTypedSelector(state => selectPhoneSettingByKey(state, 'disableHoldToParkCall'));
    const bringAppToFrontOff = useTypedSelector(state => selectPhoneSettingByKey(state, 'bringAppToFrontOff'));
    const callWaitingBeepOff = useTypedSelector(state => selectPhoneSettingByKey(state, 'callWaitingBeepOff'));
    const popParkedCallsTab = useTypedSelector(state => selectPhoneSettingByKey(state, 'popParkedCallsTab'));
    const keepActiveCall = useTypedSelector(state => selectPhoneSettingByKey(state, 'keepActiveCall'));

    const [callHandlingActive, setCallHandlingActive] = useState<boolean>(!!callHandling?.is_active);
    const [returnToSender, setReturnToSender] = useState<boolean>(!!callHandling?.blind_transfer_return_to_sender);
    const [callerIdSliderLoading, setCallerIdSliderLoading] = useState<boolean>(false);
    const [advancedHandlingLoading, setAdvancedHandlingLoading] = useState<boolean>(false);
    const [registeringNumber, setRegisteringNumber] = useState<boolean>(false);

    const userCallerId = callerIds.find(callerId => callerId.uuid === userCallerUuid);
    const prevCallerId = useRef<any | null>(userCallerId || null);

    const [putAvailableInCallQueues, {isLoading: availableInCallQueuesLoading}] = usePutAvailableInCallQueuesMutation();
    const [putCallRecording, callRecordingApiData] = usePutCallRecordingMutation()

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    const handleCallerIDChange = (cid: string | undefined) => {
        if (cid) {
            prevCallerId.current = callerIds?.find(callerId => callerId.uuid === cid);
        }

        try {
            dispatch(
                changeCallerId({
                    userUuid,
                    callerIdUuid: cid || undefined
                })
            )
                .then(() => {
                    setCallerIdSliderLoading(false)
                })
        } catch (e) {
            setCallerIdSliderLoading(false)
        }
    }

    return (
        <div className={styles.container}>
            <MenuHeader title={t("terms.call_settings")}
                        description={t("phrases.adjust_your_call_settings")}/>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.outbound_call", 2)}</h2>
                    <p>{t("phrases.manage_your_outbound_call_settings")}</p>
                </div>
                <div>
                    <div className={styles.section} data-section='caller-id'>
                        <p>{t("terms.caller_id")}</p>
                        <CallerIDs/>
                    </div>
                    <SettingsSlider
                        title={t("phrases.show_my_caller_id")}
                        checked={!!userCallerUuid}
                        onChange={() => {
                            setCallerIdSliderLoading(true)
                            handleCallerIDChange(userCallerUuid ? undefined : (prevCallerId.current?.uuid || callerIds[0]?.uuid))
                        }}
                        loading={callerIdSliderLoading}
                    />
                    <SettingsSlider
                        title={t("phrases.record_call", 1)}
                        checked={globalCallRecording !== undefined ? globalCallRecording : callRecording}
                        onChange={() => {
                            putCallRecording({user_uuid: userUuid, recording: !callRecording})
                        }}
                        loading={callRecordingApiData?.isLoading}
                        disabled={callRecordingApiData?.isLoading || globalCallRecording !== undefined}
                    />
                    <SettingsSlider
                        title={t("phrases.auto_connect_click_to_dial")}
                        checked={autoAnswerClickToDial}
                        onChange={() =>
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'autoAnswerClickToDial',
                                    value: !autoAnswerClickToDial
                                })
                            )
                        }
                    />
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("terms.inbound_call", 2)}</h2>
                    <p>{t("phrases.manage_your_inbound_call_settings")}</p>
                </div>
                <div>
                    <SettingsSlider
                        title={t("phrases.call_waiting_beep")}
                        checked={!callWaitingBeepOff}
                        onChange={() =>
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'callWaitingBeepOff',
                                    value: !callWaitingBeepOff
                                })
                            )
                        }
                    />
                    <SettingsSlider
                        title={t("phrases.available_in_call_queues")}
                        checked={queueAvailability}
                        onChange={() => putAvailableInCallQueues({
                            voip_user_uuid: userUuid,
                            available: !queueAvailability
                        })}
                        disabled={availableInCallQueuesLoading}
                        loading={availableInCallQueuesLoading}
                    />
                    <SettingsSlider
                        title={t("phrases.prioritise_active_call", 2)}
                        checked={keepActiveCall}
                        onChange={() =>
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'keepActiveCall',
                                    value: !keepActiveCall
                                })
                            )
                        }
                    />
                    {isElectron ? (
                        <SettingsSlider
                            title={t("phrases.bring_app_to_front")}
                            checked={!bringAppToFrontOff}
                            onChange={() =>
                                dispatch(
                                    updatePhoneSettings({
                                        setting: 'bringAppToFrontOff',
                                        value: !bringAppToFrontOff
                                    })
                                )
                            }
                        />
                    ) : null}
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("phrases.advanced_call_handling")}
                        <Slider
                            name={t("phrases.advanced_call_handling")}
                            checked={callHandlingActive}
                            onChange={() => {
                                setAdvancedHandlingLoading(true)
                                dispatch(
                                    putCallHandling({
                                        data: {
                                            call_handling: {
                                                blind_transfer_return_to_sender:
                                                returnToSender,
                                                is_active: !callHandlingActive,
                                                route_uuid_when_busy:
                                                callHandling?.route_uuid_when_busy,
                                                route_uuid_when_no_answer:
                                                callHandling?.route_uuid_when_no_answer,
                                                route_uuid_when_unreachable:
                                                callHandling?.route_uuid_when_unreachable
                                            }
                                        },
                                        userUuid
                                    })
                                )
                                    .then(() => {
                                        setCallHandlingActive(!callHandlingActive)
                                        setAdvancedHandlingLoading(false)
                                    });
                            }}
                        />
                    </h2>
                    <p>{t("phrases.manage_your_advanced_call_settings")}</p>
                </div>
                <div>
                    {dashboardURL ? (
                        <>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {advancedHandlingLoading ? (
                                    <LoadingSpinner color='mamba'/>
                                ) :
                                callHandlingActive ? (
                                    <AdvancedCallHandling
                                        callHandlingActive={callHandlingActive}
                                        returnToSender={returnToSender}
                                        setReturnToSender={setReturnToSender}
                                    />
                                ) : null}
                        </>
                    ) : null}
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t('phrases.my_external_numbers')}</h2>
                    <p>{t('phrases.assign_external_numbers')}</p>
                </div>
                <div>
                    <SettingsSelectedList
                        onDelete={(numUuid: string) => dispatch(removeSmartDiallerNumber(numUuid))}
                        options={smartDiallerNumbers?.map(num => ({
                            label: formatSmartDiallerNumberLabel(num),
                            value: num.uuid
                        })) || []}
                        title={t('terms.number', 2)}
                        title_btn={(
                            <StyledButton
                                buttonStyle='tertiary'
                                onClick={() => {
                                    setRegisteringNumber(true)
                                }}
                                iconOnly
                                size='small'
                            >
                                <Icon name='plus'/>
                            </StyledButton>
                        )}
                    />
                    <ModalFormContainer showModal={registeringNumber} setShowModal={setRegisteringNumber}>
                        <SmartDiallerNumberRegister
                            closeEditor={() => setRegisteringNumber(false)}
                        />
                    </ModalFormContainer>
                </div>
            </div>
            <div className={styles.section_group}>
                <div className={styles.heading}>
                    <h2>{t("phrases.call_transfer_and_parking")}</h2>
                    <p>{t("phrases.manage_your_call_transfer_parking_preferences")}</p>
                </div>
                <div>
                    <SettingsSlider
                        title={t("phrases.display_phonebooks_when_transferring")}
                        checked={phonebooksInContacts}
                        onChange={() => {
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'phonebooksInContacts',
                                    value: !phonebooksInContacts
                                })
                            );
                        }}
                    />
                    <SettingsDropdown
                        title={t("phrases.transfer_preference")}
                        options={[
                            {
                                label: t("adjective.transfer_attended"),
                                value: 'attended'
                            },
                            {
                                label: t("adjective.transfer_blind"),
                                value: 'blind'
                            }
                        ]}
                        onSelect={(val) => {
                            dispatch(updateAttendedTransfer(val === 'attended'))
                        }}
                        selected={attendedTransferDefault ? 'attended' : 'blind'}
                        description={
                            <p>
                                <span>{t("adjective.transfer_blind")}</span>: {t("phrases.blind_transfer_description_p2")}<br/>
                                <span>{t("adjective.transfer_attended")}</span>: {t("phrases.blind_transfer_description_p3")}
                            </p>
                        }
                    />
                    <SettingsSlider
                        title={t("phrases.long_press_hold_to_park")}
                        checked={!disableHoldToParkCall}
                        onChange={() => {
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'disableHoldToParkCall',
                                    value: !disableHoldToParkCall
                                })
                            );
                        }}
                    />
                    <SettingsSlider
                        title={t("phrases.show_parking_slot_after_parking_a_call")}
                        checked={popParkedCallsTab}
                        onChange={() => {
                            dispatch(
                                updatePhoneSettings({
                                    setting: 'popParkedCallsTab',
                                    value: !popParkedCallsTab
                                })
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Calls
