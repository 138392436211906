import React, {useMemo, useState} from "react";
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {selectCurrentUserId, setActiveRoomId, setVideoPage} from "../../../redux/slices";
import {useGetAllMeetingsQuery,} from "../../../redux/services/videoApi";
import {TextInput} from "../../forms";
import {usePolyglot} from "../../../context/Polyglot";
import MenuHeader from "../../menus/MenuHeader";
import {StyledButton} from "../../StyledComponents";
import Icon from "../../Icon";
import {MeetingRoomApi} from "../../../types/video";
import MeetingsList from "./children/MeetingsList";
import styles from './styles.module.scss';
import JoinViaIdModal from "./children/JoinViaIdModal";

export type VideoSidebarItem = {
    type: 'meeting'
    data: MeetingRoomApi
} | {
    type: 'divider'
    data: string
};

export const VideoSideBar: React.FC = () => {
    const currentUserUuid = useTypedSelector(selectCurrentUserId);

    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('');

    const dispatch = useTypedDispatch();
    const {t} = usePolyglot();

    const {data: meetingsApi, isLoading} = useGetAllMeetingsQuery(null);

    const dataSet: VideoSidebarItem[] = useMemo((): VideoSidebarItem[] => {
        if (!meetingsApi?.result) return [];

        const openMeetings: {
            type: 'meeting'
            data: MeetingRoomApi
        }[] = [];
        const yourMeetings: {
            type: 'meeting'
            data: MeetingRoomApi
        }[] = [];

        for (let i = 0; i < meetingsApi.result.length; i += 1) {
            const cur = meetingsApi.result[i];

            if (searchValue && cur.name.toLowerCase().includes(searchValue.toLowerCase())) {
                continue;
            }

            if (cur.members.some(mem => mem.user_id === currentUserUuid)) {
                yourMeetings.push({
                    type: 'meeting',
                    data: cur
                })
            } else if (cur.show_to_all) {
                openMeetings.push({
                    type: 'meeting',
                    data: cur
                })
            }

        }

        openMeetings.sort((a, b) => a.data.name.localeCompare(b.data.name));
        yourMeetings.sort((a, b) => a.data.name.localeCompare(b.data.name));

        return [{
            type: 'divider',
            data: 'Your Meetings'
        }]
            .concat(yourMeetings as any[])
            .concat({
                type: 'divider',
                data: 'Public Meetings'
            })
            .concat(openMeetings as any[]) as any;

    }, [searchValue, meetingsApi]);

    return (
        <div
            className={styles.container}
        >
            <MenuHeader
                title={t("terms.video", 1)}
                titleOptions={
                    <div className='menu-header__icon-container'>
                        <StyledButton
                            buttonStyle='tertiary'
                            fontWeight='medium'
                            onClick={() => {
                                dispatch(setVideoPage('meeting-create'))
                                dispatch(setActiveRoomId(null))
                            }}
                            title={t("phrases.new_chat")}
                        >
                            <Icon name='plus' width={24} height={24}/>
                            <p>{t("phrases.new_meeting")}</p>
                        </StyledButton>
                        <div className={styles.id_join}>
                            <StyledButton
                                buttonStyle='tertiary'
                                fontWeight='medium'
                                onClick={() => setShowPopup(!showPopup)}
                            >
                                <Icon name='link' width={24} height={24}/>
                                <p>Join via ID</p>
                            </StyledButton>
                            {showPopup ? (
                                <JoinViaIdModal closeEditor={() => setShowPopup(false)}/>
                            ) : null}
                        </div>
                    </div>
                }
                input={
                    <TextInput
                        name='Chat Search'
                        placeholder={t("actions.search_chat", 2)}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        isSearch
                    />
                }
            />
            <MeetingsList
                dataSet={dataSet}
                loading={isLoading}
            />
        </div>
    )
}

export default VideoSideBar;
