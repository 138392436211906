/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import {
    attendedTransferCall,
    handleFindingATransfer,
    selectAppAllowConference,
    selectInterTenantList,
    selectPhoneSettingByKey,
    selectShortCodes,
    sendDTMF,
    setBeingTransferred,
    setViewContactItem,
    toggleHoldCall,
    toggleMuteCall,
    updateCallDirect
} from '../../../../../redux/slices';
import Icon from '../../../../Icon';
import { useTypedDispatch, useTypedSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import styles from '../../../Keypad.module.scss';
import { IUserStatus } from '../../../../../types';
import TransferringDisplay from './atoms/TransferringDisplay';
import { usePolyglot } from '../../../../../context/Polyglot';
import { useCallContext } from '../../../../../context/CallContext/context';
import AriaButton from '../../../../AriaComponents/AriaButton';
import AriaLongPressButton from '../../../../AriaComponents/AriaLongPressButton';
import SmartDiallerActiveDisplay from './atoms/SmartDiallerActiveDisplay';
import { StyledDropdownOption } from '../../../../StyledComponents';
import StyledList from '../../../../StyledComponents/StyledList';
import { conferenceSockets } from '../../../ConferenceHooks';

export const selectOpenParkingSlotShortcode = createSelector(
    (state: RootState) => state.status.userStatuses,
    (state: RootState) =>
        state.auth.shortcodes?.filter(
            shortcode => shortcode.feature.feature?.toLowerCase() === 'parkretrieve'
        ) || [],
    (userStatuses, parkingSlotShortcodes) => {
        const openParkingSlots = Object.keys(userStatuses).reduce(
            (parkingSlots: IUserStatus[], statusKey) => {
                if (statusKey.includes('@parking') && userStatuses[statusKey].status === 'open') {
                    return [...parkingSlots, userStatuses[statusKey]];
                }

                return parkingSlots;
            },
            []
        );

        return parkingSlotShortcodes.find(
            shortcode =>
                shortcode.feature.slot &&
                openParkingSlots[0]?.sipuser.includes(shortcode.feature.slot)
        )?.short_code;
    }
);

interface Props {
    groupError: boolean;
}

export const OptionsMenu: React.FC<Props> = ({ groupError }) => {
    const { call, speakerActive, membersTabOpen, useCallAction } = useCallContext();

    const [showTenantOpts, setShowTenantOpts] = useState(false);

    const disableHoldToParkCall = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'disableHoldToParkCall')
    );
    const conferenceAllowed = useTypedSelector(selectAppAllowConference);
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);
    const listenShortcode = (useTypedSelector(selectShortCodes) || []).find(
        shortcode => shortcode.feature.feature === 'listen'
    )?.short_code;
    const openParkingSlotShortcode = useTypedSelector(selectOpenParkingSlotShortcode);
    const tenants = useTypedSelector(selectInterTenantList);

    const transferringCallId = call.beingTransferredTo;
    const isListening = listenShortcode && call?.callee?.includes(listenShortcode);

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const SmartDiallerActive = call.smartDiallerData?.title;

    const handleConferenceHold = () => {
        if (!call.socketId) return;

        dispatch(
            updateCallDirect({
                ...call,
                onHold: !call.onHold
            })
        );
        conferenceSockets[call.socketId].socket.call(
            call.onHold ? 'unmute_video' : 'mute_video',
            {},
            {
                onOne: true,
                noId: true
            }
        );
    };

    const handleConferenceMute = () => {
        if (!call.socketId) return;

        dispatch(
            updateCallDirect({
                ...call,
                isMuted: !call.isMuted
            })
        );
        conferenceSockets[call.socketId].socket.call(
            call.isMuted ? 'unmute_audio' : 'mute_audio',
            {},
            {
                onOne: true,
                noId: true
            }
        );
    };

    const handleViewTenant = (tenantUuid: string) => {
        dispatch(
            setViewContactItem({
                type: 'tenant',
                data: tenantUuid
            })
        );

        dispatch(setBeingTransferred(call.id, true));

        if (call) {
            dispatch(toggleHoldCall(call.id, true));
        }
    };

    const tenantOpts: StyledDropdownOption[] = tenants.map(tnt => ({
        label: tnt.name,
        value: tnt.uuid
    }));
    return (
        <div className={styles.options_menu} data-hidden={membersTabOpen ? 'true' : null}>
            {!isListening && !call.smartDialOnly && !SmartDiallerActive ? (
                <div>
                    <AriaButton
                        onClick={() => {
                            if (call.roomId) {
                                handleConferenceMute();
                            } else {
                                dispatch(toggleMuteCall(call.id, !call.isMuted));
                            }
                        }}
                        className={`${call?.isMuted && styles.active_button}`}
                        title={t('actions.mute')}
                    >
                        <Icon name='muteMicrophone' />
                    </AriaButton>
                    <p>{t('actions.mute')}</p>
                </div>
            ) : null}
            {!call.smartDialOnly ? (
                <div>
                    <AriaButton
                        onClick={() =>
                            useCallAction({ type: 'set_current_tab', payload: 'number_keypad' })
                        }
                        title={t('terms.keypad')}
                    >
                        <Icon name='keypadSquare' />
                    </AriaButton>
                    <p>{t('terms.keypad')}</p>
                </div>
            ) : null}
            {!SmartDiallerActive ? (
                <div>
                    <AriaButton
                        onClick={() =>
                            useCallAction({ type: 'set_speaker_active', payload: !speakerActive })
                        }
                        className={speakerActive ? styles.active_button : undefined}
                        title={t('terms.speaker', 1)}
                    >
                        <Icon name='speakerWave' />
                    </AriaButton>
                    <p>{t('terms.speaker', 1)}</p>
                </div>
            ) : null}
            {!transferringCallId ? (
                <div>
                    <AriaButton
                        onClick={() =>
                            useCallAction({ type: 'set_current_tab', payload: 'add_call' })
                        }
                        title={t('phrases.new_call')}
                    >
                        <Icon name='addCall' />
                    </AriaButton>
                    <p>{t('phrases.new_call')}</p>
                </div>
            ) : null}
            {!isListening ? (
                <div>
                    {disableHoldToParkCall || call.roomId ? (
                        <AriaButton
                            onClick={() => {
                                if (call.roomId) {
                                    handleConferenceHold();
                                } else {
                                    dispatch(toggleHoldCall(call.id, !call.onHold));
                                }
                            }}
                            title={t('actions.hold')}
                            className={`${call?.onHold && styles.active_button}`}
                        >
                            <Icon name='hold' />
                        </AriaButton>
                    ) : (
                        <AriaLongPressButton
                            longPress={() => {
                                if (openParkingSlotShortcode && !call.onHold) {
                                    dispatch(sendDTMF(call.id, openParkingSlotShortcode));
                                }
                            }}
                            onClick={() => {
                                dispatch(toggleHoldCall(call.id, !call.onHold));
                            }}
                            longPressDescription={t('phrases.long_press_hold_to_park')}
                            className={call?.onHold ? styles.active_button : undefined}
                        >
                            <Icon name='hold' />
                        </AriaLongPressButton>
                    )}
                    <p>{t('actions.hold')}</p>
                </div>
            ) : null}

            {!isListening && !transferringCallId && !call.roomId ? (
                <>
                    <div>
                        <AriaButton
                            onClick={() => {
                                useCallAction({ type: 'set_current_tab', payload: 'transfer' });
                                if (call?.beingTransferredTo) {
                                    dispatch(
                                        attendedTransferCall(call.id, call.beingTransferredTo)
                                    );
                                } else {
                                    dispatch(setBeingTransferred(call.id, !aTransferIsActive));

                                    if (call) {
                                        dispatch(toggleHoldCall(call.id, true));
                                    }
                                }
                            }}
                            title={t('actions.transfer')}
                        >
                            <Icon name='transfer' />
                        </AriaButton>
                        <p>{t('actions.transfer')}</p>
                    </div>
                    {!transferringCallId && tenants.length > 0 && !call.beingTransferredTo ? (
                        <div className={styles.listed}>
                            <AriaButton
                                onClick={() => {
                                    if (tenants.length === 1) {
                                        handleViewTenant(tenants[0].uuid);
                                    } else {
                                        setShowTenantOpts(!showTenantOpts);
                                    }
                                }}
                                title='Transfer to a Registered Tenant'
                            >
                                <Icon name='switchCalls' />
                            </AriaButton>
                            <p>Bridge Transfer</p>
                            {showTenantOpts ? (
                                <StyledList
                                    options={tenantOpts}
                                    closeList={() => setShowTenantOpts(false)}
                                    onSelect={(v: string) => {
                                        handleViewTenant(v);
                                    }}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </>
            ) : null}
            {!isListening && !transferringCallId && !SmartDiallerActive ? (
                <>
                    {((call.roomId && call.isAdmin) || !call.roomId) &&
                    !groupError &&
                    conferenceAllowed ? (
                        <div>
                            <AriaButton
                                onClick={() =>
                                    useCallAction({
                                        type: 'set_current_tab',
                                        payload: 'add_to_call'
                                    })
                                }
                                title={call.roomId ? t('actions.invite') : t('terms.group_call', 1)}
                            >
                                <Icon name='addContactCircle' />
                            </AriaButton>
                            <p>{call.roomId ? t('actions.invite') : t('terms.group_call', 1)}</p>
                        </div>
                    ) : null}
                    {groupError ? (
                        <p className={styles.group_error}>
                            {t('phrases.error_unable_to_make_conference_call')}
                        </p>
                    ) : null}
                </>
            ) : null}
            {transferringCallId ? <TransferringDisplay /> : null}
            {call.smartDiallerData ? <SmartDiallerActiveDisplay /> : null}
        </div>
    );
};
