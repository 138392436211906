import React, { createContext, useContext, useState } from 'react';
import {INavigationContext, PageTypes} from "../types/navigation";

interface NavigationProps {
    children?: React.ReactNode;
}

const Context = createContext<INavigationContext>({} as INavigationContext);

const NavigationProvider: React.FC<NavigationProps> = ({ children }) => {
    const [page, setPage] = useState<PageTypes>('phone');

    return (
        <Context.Provider
            value={{
                page,
                setPage
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useNavigation = () => useContext(Context);

export default NavigationProvider;
