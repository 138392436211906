import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VideoPage, VideoSliceState} from "../../../types/video";

const initialState: VideoSliceState = {
    page: 'meeting-home',
    activeRoomId: null
};

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        setVideoPage: (state, {payload}: PayloadAction<VideoPage>) => ({
            ...state,
            page: payload
        }),
        setActiveRoomId: (state, {payload}: PayloadAction<string | null>) => ({
            ...state,
            activeRoomId: payload
        }),
    }
});

export const video = videoSlice.reducer;