import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-gb';

import {polyglot} from "./polyglot.lib";

const currentAppLocale = polyglot.locale();

function setMomentLocale(locale) {
    if (locale) {
        moment.locale(locale);
    }
}

setMomentLocale(currentAppLocale)

export { setMomentLocale };

