import FileSaver from 'file-saver';
import {useState} from "react";
import {CircularProgress} from "@mui/material";
import {
    AudioAttachment,
    GenericAttachment,
    ImageAttachment,
    PdfAttachment,
    VideoAttachment
} from "./children";
import styles from './Attachment.module.scss';
import Icon from "../../../components/Icon";
import {useTypedSelector} from "../../../redux/hooks";
import {selectCurrentUserId} from "../../../redux/slices";
import AriaButton from "../../../components/AriaComponents/AriaButton";
import {AnimatedDiv} from "../../../animation";

interface Props {
    file: any;
    handleFileDelete?: (number) => void;
    mini: boolean;
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

const Attachment: React.FC<Props> = ({
                                         file,
                                         handleFileDelete,
                                         mini
                                     }) => {
    const userUuid = useTypedSelector(selectCurrentUserId);

    const [showOptions, setShowOptions] = useState<boolean>(false);

    // const isPreviewCapable =
    //     file.type.includes('image') && file.type !== 'image/heic';
    //
    // if (isPreviewCapable) {
    //     preview = <img src={URL.createObjectURL(file)} alt={t("terms.preview")}/>;
    // } else if (isVoiceNote) {
    //     preview = <div className={styles.preview_button_container}>
    //         <AnimatedDiv visible={!hovered} inAnimation={['fade', 'slideFromTop']}>
    //             <Icon name='microphone'/>
    //         </AnimatedDiv>
    //         <AnimatedDiv visible={hovered} inAnimation={['fade', 'slideFromBottom']}>
    //             <button
    //                 className='audio_preview'
    //                 onClick={() => setPreviewAudio(!previewAudio)}
    //                 title={previewAudio ? t("phrases.close_audio_preview") : t("phrases.preview_audio")}
    //             >
    //                 <Icon name='playFilled'/>
    //             </button>
    //         </AnimatedDiv>
    //     </div>
    // } else if (isAudio) {
    //     preview = <i title={t("phrases.file_audio_icon")} className='icon-mp3'/>
    // } else {
    //     preview = <i title={t("phrases.file_icon")} className='icon-file'/>;
    // }

    const downloadFile = () => {
        const {uuid, name} = file;

        fetch(`/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${uuid}&content=true&thumbnail=false`)
            .then(resp => resp.blob())
            .then(blob => {
                FileSaver.saveAs(blob, name || file.file_name);
            })
            .catch(() => console.warn('error download file'));
    }

    let attachedFile;
    const mode = handleFileDelete ? 'delete' : 'download';
    let isLarge = false;
    let options = true;

    switch (file.type || file.content_type) {
        case 'audio':
        case 'audio/ogg':
        case 'audio/wav':
        case 'audio/mpeg':
        case 'audio/x-m4a':
        case 'audio/aac':
            attachedFile =
                mode === 'download' ? (
                    <AudioAttachment
                        initialSrc={`/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${file.uuid}&content=true&thumbnail=false`}
                        srcName={file.name}
                    />
                ) : (
                    <AudioAttachment
                        initialFile={file}
                    />
                )
            break;
        case 'image/webp':
        case 'image/png':
        case 'image/img':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/sticker+png':
        case 'image/svg+xml':
            attachedFile =
                <ImageAttachment
                    mini={mini}
                    file={file}
                    mode={mode}
                    downloadFile={downloadFile}
                    showOptions={showOptions}
                />
            isLarge = !mini;
            options = mini;
            break;
        case 'video/mp4':
        case 'video/quicktime':
        case 'video/x-msvideo':
        case 'video/x-ms-wmv':
        case 'video/webm':
            attachedFile =
                <VideoAttachment
                    file={file}
                    mode={mode}
                    showOptions={showOptions}
                />
            break;
        case 'application/pdf':
            attachedFile =
                <PdfAttachment
                    file={file}
                    mode={mode}
                />
            break;
        default:
            attachedFile =
                <GenericAttachment
                    file={file}
                />
    }

    return (
        <div
            className={styles.attachment_container}
            data-is-large={isLarge || null}
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
            onFocus={() => setShowOptions(true)}
            onBlur={() => setShowOptions(false)}
            onClick={(e) => {
                e.stopPropagation()
            }}
            data-comp='attachment'
        >
            {file.pending ? (
                <CircularProgress
                    size={40}
                    disableShrink
                    style={{
                        width: '50px',
                        height: '50px',
                        zIndex: 10,
                        padding: '10px'
                    }}
                />
            ) : (<>
                {attachedFile}
                {options ? (
                    <div
                        className={styles.primary_action_container}
                        data-is-download={!handleFileDelete}
                    >
                        <AnimatedDiv
                            visible={showOptions}
                            inAnimation={handleFileDelete ? ['fade'] : ['fade', 'slideFromBottom']}
                        >
                            {handleFileDelete ? (
                                <AriaButton
                                    onClick={handleFileDelete}
                                >
                                    <Icon name='cross'/>
                                </AriaButton>
                            ) : (
                                <AriaButton
                                    className={styles.download_button}
                                    onClick={() => downloadFile()}
                                >
                                    <Icon name='arrowDownWithLine'/>
                                </AriaButton>
                            )}
                        </AnimatedDiv>
                    </div>
                ) : null}
            </>)}
        </div>
    )
}

export default Attachment;
