import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import {
    selectAllUserStatus,
    selectCallWhenAvailableList,
    selectParkingSlots,
    selectStatusApi,
    selectUserAuth,
    setNewFetchUsers,
    setStatusApi,
    setUserStatuses
} from '../../../redux/slices';
import {
    checkStatusesSocketConnection,
    checkTimeRef,
    handleStatusSocketAuth,
    killStatusesSocket,
    recreateStatusSocket,
    subscribeUserStatus,
    useSubscribeUserStatusesQuery
} from '../../../redux/services/statusApi';
import { IParkingSlot, IUser } from '../../../types';

interface Props {}

const UserStatusController: React.FC<Props> = () => {
    const { username, password } = useTypedSelector(selectUserAuth);
    const allUserStatus = useTypedSelector(selectAllUserStatus);
    const parkingSlots = useTypedSelector(selectParkingSlots) || [];
    const whenAvailableList = useTypedSelector(selectCallWhenAvailableList) || [];
    const apiSetting = useTypedSelector(selectStatusApi);

    useSubscribeUserStatusesQuery(null, { skip: !parkingSlots || !username || !password });

    const previousGetList = useRef<string[]>([]);

    const fetchingTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const returnFunc = () => {
            if (fetchingTimeout.current) {
                clearTimeout(fetchingTimeout.current);
            }
        };

        if (apiSetting !== 'fetching') {
            return () => returnFunc();
        }

        fetchingTimeout.current = setTimeout(() => {
            previousGetList.current = [];
            dispatch(setStatusApi('error'));

            killStatusesSocket('error');

            dispatch(recreateStatusSocket());

            setSocketConnected(0);
        }, 1000 * 20);

        return () => returnFunc();
    }, [apiSetting]);

    /**
     * 0 ~ Not connected, not tried
     * 1 ~ Not connected, tried
     * 2 ~ Connected
     */
    const [socketConnected, setSocketConnected] = useState<0 | 1 | 2>(0);

    const dispatch = useTypedDispatch();

    const handleSubscribe = () => {
        const whenAvailableRes: string[] = [];

        whenAvailableList.forEach((item: IUser) => {
            if (!allUserStatus[item.name]) {
                whenAvailableRes.push(item.name);
            }
        });

        const finalArr: string[] = [username].concat(allUserStatus).concat(whenAvailableRes);

        const check = checkTimeRef();

        const newUserList = finalArr.filter(u => !previousGetList.current.includes(u));

        dispatch(setNewFetchUsers(newUserList));
        dispatch(setStatusApi('fetching'));

        const subUsers = () => {
            subscribeUserStatus(
                finalArr,
                parkingSlots.map((slot: IParkingSlot) => slot.slot_number)
            )
                .then(r => {
                    dispatch(setStatusApi('success'));
                    dispatch(setUserStatuses(r.result));
                    previousGetList.current = finalArr;
                })
                .catch(e => {
                    previousGetList.current = [];
                    dispatch(setStatusApi('error'));
                    Sentry.captureMessage('Failed subscribe_user_status', e);

                    killStatusesSocket('error');

                    dispatch(recreateStatusSocket());

                    setSocketConnected(0);
                });
        };

        if (check) {
            subUsers();
        } else {
            handleStatusSocketAuth({
                credentials: {
                    username,
                    password
                },
                onAuth: () => subUsers()
            });
        }
    };

    const interval = useRef<NodeJS.Timeout>();

    useEffect(() => {
        switch (socketConnected) {
            case 0:
                setSocketConnected(1);
                interval.current = setInterval(() => {
                    if (checkStatusesSocketConnection() === 1) {
                        setSocketConnected(2);
                        clearInterval(interval.current);
                    }
                }, 1000);
                break;
            case 1:
                break;
            case 2:
                if (interval.current) {
                    clearInterval(interval.current);
                }
                handleSubscribe();
        }
    }, [allUserStatus, socketConnected]);

    useEffect(() => {
        const handleOffline = () => {
            dispatch(setStatusApi('error'));
        };

        const handleOnline = () => {
            handleSubscribe();
        };

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, [allUserStatus, socketConnected]);

    return null;
};

export default UserStatusController;
