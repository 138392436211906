import React, { FunctionComponent, ReactNode, useRef, useState } from 'react';
import styles from '../forms/NewChat/styles.module.scss';
import AriaTextField from './AriaTextField';
import EntityIcon from '../icons/EntityIcon';
import { IUser } from '../../types';
import AriaButton from './AriaButton';
import { useContacts } from '../sidebars/Contact/ContactListHooks';
import { handleMembersKeydown } from '../forms/NewChat/hooks';
import { useTypedSelector } from '../../redux/hooks';
import { selectCurrentUserId } from '../../redux/slices';

interface Props {
    widgets?: ReactNode;
    extraKeydown?: (e: any) => void;
    placeholder?: string;
    selectedUsers: string[];
    label: string;
    onUserClick: (user: IUser) => void;
    onDelete?: () => void;
}

const AriaUserSearchSelect: FunctionComponent<Props> = ({
    widgets,
    extraKeydown,
    placeholder,
    selectedUsers,
    label,
    onUserClick,
    onDelete
}) => {
    const currentUserId = useTypedSelector(selectCurrentUserId);

    const [searchVal, setSearchVal] = useState<string>('');
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const { mainDisplay, favourites } = useContacts({
        searchQuery: searchVal,
        filter: ['Internal Users']
    });
    const allContacts = favourites.concat(mainDisplay);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = handleMembersKeydown({
        showDropdown,
        setShowDropdown,
        inputRef
    });

    return (
        <div
            className={styles.member_selector}
            onBlur={e => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    setShowDropdown(false);
                }
            }}
            onKeyDown={e => {
                if (
                    e.target === e.currentTarget.firstChild?.lastChild?.lastChild &&
                    e.key === 'Enter'
                ) {
                    setShowDropdown(true);
                } else {
                    handleKeyDown(e);
                }
            }}
        >
            <AriaTextField
                label={label}
                value={searchVal}
                onChange={e => {
                    if (e.length > 0 && !showDropdown) {
                        setShowDropdown(true);
                    }
                    setSearchVal(e);
                }}
                placeholder={placeholder}
                isSearch
                onSearchClick={() => {
                    setShowDropdown(!showDropdown);
                }}
                widgets={widgets}
                ref={inputRef}
                extraKeydown={e => {
                    if (extraKeydown) {
                        extraKeydown(e);
                    }
                    if (e.key === 'Backspace' && !searchVal && onDelete) {
                        onDelete();
                    }
                }}
            />
            {showDropdown && (allContacts.length > 0 ) ?(
                <ul>
                    {(allContacts).map((item) => {
                        const contact = item.data as IUser;
                        const isSelected = selectedUsers.includes(contact.uuid);
                        if (contact.uuid === currentUserId) {
                            return null;
                        }

                        return (
                            <AriaButton
                                key={`GMS_${contact.uuid}`}
                                onClick={() => {
                                    onUserClick(contact);
                                    setSearchVal('');
                                    setShowDropdown(false);
                                }}
                                dataTags={{
                                    'data-is-selected': isSelected || null
                                }}
                            >
                                <EntityIcon
                                    type='user'
                                    details={{
                                        display_name: contact.nickname,
                                        avatar_key: contact.avatar_key,
                                        uuid: contact.uuid
                                    }}
                                    size={24}
                                />
                                <p>{contact.nickname}</p>
                            </AriaButton>
                        );
                    })}
                </ul>
            ) : null}
        </div>
    );
};

export default AriaUserSearchSelect;
