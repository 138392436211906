import {useTypedDispatch, useTypedSelector} from "../../../../redux/hooks";
import {selectCurrentUserId, setFilePreview} from "../../../../redux/slices";
import Icon from "../../../../components/Icon";
import styles from "../Attachment.module.scss";
import {AttachmentNameDisplay, GenericAttachmentProps} from "./GenerticAttachment";
import AriaButton from "../../../../components/AriaComponents/AriaButton";

interface Props extends GenericAttachmentProps {
    mode: 'delete' | 'download';
    showOptions: boolean;
}

export const VideoAttachment: React.FC<Props> = ({
                                                     file,
                                                     mode,
                                                     showOptions,
                                                 }) => {
    const dispatch = useTypedDispatch();

    const userUuid = useTypedSelector(selectCurrentUserId);

    const src = mode === 'delete' ? URL.createObjectURL(file) : `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${file.uuid}&content=true&thumbnail=false`;

    const {name, size, shared_at, shared_by, uuid} = file;

    const activatePreview = () => {
        dispatch(setFilePreview({
            src,
            file_name: name,
            file_type: 'video',
            file_size: size,
            shared_by,
            shared_at,
            uuid
        }))
    }

    return (
        <>
            <div className={styles.preview_button_container}>
                {showOptions ? (
                    <AriaButton
                        onClick={activatePreview}
                    >
                        <Icon name="playFilled"/>
                    </AriaButton>
                ) : (
                    <Icon name="videoFilled"/>
                )}
            </div>
            <div className={styles.file_content_container}>
                <AttachmentNameDisplay
                    file={file}
                />
            </div>
        </>
    )
}