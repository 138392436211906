import {createContext, ErrorInfo, ReactNode, useContext} from "react";

interface Props {
    error: Error;
    errorInfo: ErrorInfo;
}

interface ProviderProps extends Props {
    children: ReactNode;
}

const ErrorContext = createContext<Props | null>(null)

export const useErrorContext = () => {
    const context = useContext(ErrorContext)

    if (!context?.error) {
        throw new Error(
            'Component requiring error context rendered without error'
        )
    }

    return {
        ...context
    }
}

export const ErrorProvider: React.FC<ProviderProps> = ({error, errorInfo, children}) => (
        <ErrorContext.Provider value={{error, errorInfo}}>
            {children}
        </ErrorContext.Provider>
    )

export default ErrorProvider;