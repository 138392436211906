import React from 'react';
import {usePolyglot} from "../../context/Polyglot";
import { useAppContext } from '../../context/AppContext/AppContext';

export const ChatErrorBoundary: React.FC = () => {
    const { appLogoWhite, appLogoAlt } = useAppContext()

    const { t } = usePolyglot();

    return (
        <div className='chat chatErrorBoundary'>
            <div className='chat__view'>
                <div className='chat__welcome'>
                    <img alt={appLogoAlt} src={appLogoWhite} />
                    <h1>
                        {t("phrases.error_chat_error_generic_1")}
                        {t("phrases.error_chat_error_generic_2")}
                    </h1>
                </div>
            </div>
        </div>
    )
};
