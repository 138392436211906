import React from 'react';
import { useCallerDisplayNameGeneric } from '../../../../helpers';
import {
    useGroupCallVolValue
} from '../../../../context/CallContext/GroupCallVolumesContext/context';
import AriaButton from '../../../AriaComponents/AriaButton';
import { useConferenceMethods } from '../../ConferenceHooks';
import { RoomMember } from '../../../../types';
import { useCallContext } from '../../../../context/CallContext/context';

interface Props {
    roomMember: RoomMember;
}

const ConferenceMember: React.FC<Props> = ({ roomMember }) => {
    const { call } = useCallContext();
    const { speakingMap } = useGroupCallVolValue();

    const speaking = speakingMap[roomMember.id] || false;

    const { name } = useCallerDisplayNameGeneric(roomMember.name);

    const { kickUser } = useConferenceMethods();

    return (
        <li>
            <p data-speaking={speaking ? 'yes' : null} title={name}>
                {name}
            </p>
            {call.isAdmin ? (
                <AriaButton
                    onClick={() => {
                        kickUser(roomMember.id);
                    }}
                >
                    <p>Remove</p>
                </AriaButton>
            ) : null}
        </li>
    );
};

export default ConferenceMember;
