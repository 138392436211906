/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    configureStore,
    Action,
    ThunkAction,
    ImmutableStateInvariantMiddlewareOptions,
    SerializableStateInvariantMiddlewareOptions
} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

import { electron } from './middleware/electronMiddleware';
import { sip } from './middleware/sipMiddleware';
import { notification } from './middleware/notificationMiddleware';
import { sentryReduxEnhancer } from './middleware/sentryReduxEnhancer';
import { api } from './services';

// TODO Check these middleware ignores and make sure they're not slowing annything down i.e. bifrost/notifyUserStatus
const middlewareOptions: {
    immutableCheck: ImmutableStateInvariantMiddlewareOptions;
    serializableCheck: SerializableStateInvariantMiddlewareOptions;
} = {
    immutableCheck: {
        ignore: ['status/updateUserStatus'],
        ignoredPaths: [
            'status.userStatuses',
            'sip.calls'
        ],
        warnAfter: 250
    },
    serializableCheck: {
        ignoredActions: [
            'calls/newCall',
            'calls/updateCall',
            'calls/updateCallAudio',
            'calls/updateCallVideo',
            'status/updateUserStatus'
        ],
        ignoredActionPaths: [
            'calls',
            'status'
        ],
        ignoredPaths: [
            'status.userStatuses',
            'sip.calls',
        ],
        warnAfter: 50
    }
};

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware(middlewareOptions).concat(
            api.middleware,
            // @ts-ignore
            electron,
            sip,
            notification
        ),
    devTools: {
        actionsDenylist: ['status/updateUserStatus']
    },
    enhancers: [sentryReduxEnhancer]
});

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}

// @ts-ignore
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
