function getWindowLocale() {
    const {navigator} = window;

    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0]
    }

    return navigator.language || navigator.language || navigator.languages;
}

const navLocale = getWindowLocale();

export default function getNavigatorLocale() {
    // TODO -> check against full name?
    switch (true) {
        case navLocale.includes('fr'):
            return 'fr';
        case navLocale.includes('es'):
            return 'es';
        case navLocale.includes('pl'):
            return 'pl';
        default:
            return 'en-gb'
    }
}
