import React, {memo} from 'react';
import {useMessageContext} from "../../../../context/MessageContext/context";
import {usePolyglot} from "../../../../context/Polyglot";
import {useChatContext} from "../../../../context/ChatContext/context";
import { getDisplayColor, useChatName } from '../../../../helpers';
import { useAppContext } from '../../../../context/AppContext/AppContext';

interface IMessageSenderProps {
}

export const MessageSender: React.FC<IMessageSenderProps> = memo(
    () => {
        const {message} = useMessageContext();
        const {chat} = useChatContext();
        const {memberDictionary} = useChatContext();

        const displayName: string = useChatName(chat, undefined);

        const {t} = usePolyglot();
        const {darkMode} = useAppContext();

        const handleName = () => {
            if (chat.integration?.channel.toLowerCase() === 'sms' && message.from_type === "integration") {
                return displayName
            }

            const member = memberDictionary[message.from];

            if (member) {
                return member.display_name;
            }

            return t("adjective.user_unknown");
        };

        return <h4 className={getDisplayColor(message.from, false, darkMode)}>{handleName()}</h4>;
    },
    (prevProps, nextProps) => prevProps === nextProps
);
