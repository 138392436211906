import { v4 as uuidv4 } from 'uuid';
import {
    IBifrostCallMethod,
    IBifrostSubscribeMethod,
    IdAwaiter,
    IdAwaiterTimeouts,
    NotificationSubscriber
} from '../../types';

type noParamsVoidFunction = () => void;

export class YayWebSocket {
    private socket?: WebSocket;

    private readonly url: string;

    private readonly protocols: string | string[];

    private idAwaiter: IdAwaiter | Record<string, never> = {};

    private idAwaiterTimeouts: IdAwaiterTimeouts | Record<string, never> = {};

    private notificationSubscriber: NotificationSubscriber | Record<string, never> = {};

    public onConnect?: noParamsVoidFunction;

    public onClose?: noParamsVoidFunction;

    constructor(url: string, protocols: string | string[]) {
        this.url = url;
        this.protocols = protocols;
    }

    /**
     * close the websocket with passed args
     */
    public close(code: number, message: string) {
        this.socket?.close(code, message);
    }

    /**
     * create new instance of the websocket with passed args
     * start listening to events
     */
    public connect() {
        switch (this.socket?.readyState) {
            case WebSocket.OPEN:
            case WebSocket.CONNECTING:
            case WebSocket.CLOSING:
                return;
        }
        this.socket = new WebSocket(this.url, this.protocols);
        this.listen();
    }

    /**
     * call ping every 10s and await for the response
     * if call returned error close the websocket and stop ping
     * if 10s passed and still no response also close websocket (this is handled by id awaiter)
     */
    public ping() {
        if (this.socket?.readyState !== WebSocket.OPEN) return;
        this.call('ping')
            .then(() => {
                setTimeout(() => {
                    this.ping();
                }, 10000);
            })
            .catch(error => {
                this.close(4001, error.message);
            });
    }

    /**
     * subscribe to websocket - assign handleFn to method
     */
    public subscribe(method: IBifrostSubscribeMethod, handlerFn: any) {
        this.notificationSubscriber[method] = handlerFn;
    }

    /**
     * step 1: initial checks: if there is no websocket or websocket is not connected immediately reject the promise with error
     * step 2: if initial checks do pass create the data object with id, method and params (optional)
     * step 3: set response awaiter - if no response for the call within 10 second reject with timeout error
     * step 4: if response received within 10s clear the awaiter and resolve with either result or reject with an error
     * ** if response has an error 'token expired' run the onTokenExpire logic and do not reject / resolve
     * ** this allows to handle custom logic ignoring other error handling logic that might cause conflicts
     * ** responses are matched by id => awaiter is assign an ID that is being returned from the response
     * ** if ID do match then run the handler AND if no response with the assigned ID reject with timeout error
     */
    public call<P>(
        method: IBifrostCallMethod,
        params?: P,
        extra?: {
            onOne?: boolean;
            noId?: boolean;
            noAwait?: boolean;
            allowTime?: number;
        }
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                reject(new Error('No Websocket'));
                return;
            }

            if (this.socket.readyState !== WebSocket.OPEN) {
                reject(new Error('Websocket Not Connected'));
                return;
            }

            const data: any = {};
            data.method = method;

            if (extra?.onOne) {
                data.jsonrpc = '2.0';
            }

            if (!extra?.noId) {
                data.id = uuidv4();
            }

            if (!extra?.noId && !extra?.noAwait) {
                this.idAwaiterTimeouts[data.id] = setTimeout(() => {
                    delete this.idAwaiterTimeouts[data.id];
                    console.log('Timeout - Websocket Not Responding', method, params, extra);
                    reject(new Error('Timeout - Websocket Not Responding'));
                }, extra?.allowTime || 10000);

                this.idAwaiter[data.id] = (responseData: any) => {
                    clearTimeout(this.idAwaiterTimeouts[data.id]);
                    delete this.idAwaiterTimeouts[data.id];
                    delete this.idAwaiter[data.id];

                    if (responseData.error?.message === 'token expired') {
                        reject(new Error('Token Expired'));
                        return;
                    }

                    if (responseData.error) {
                        reject(new Error(JSON.stringify(responseData)));
                        return;
                    }

                    resolve(responseData);
                };
            }

            if (params) data.params = params;

            const json = JSON.stringify(data);
            try {
                this.socket?.send(json);
            } catch (error) {
                clearTimeout(this.idAwaiterTimeouts[data.id]);
                reject(error);
            }
        });
    }

    public hasSocket(): boolean {
        return !!this.socket;
    }

    public getReadyState(): number {
        return this.socket?.readyState || 3;
    }

    /**
     * listen to the websocket events
     * on open start ping and run onConnect logic
     * on error trigger close
     * on close invalidate websocket and run onClose logic
     * on message resolve either idAwaiter or notificationSubscriber (i.e. clear awaiters and run handlers logic)
     */
    private listen() {
        if (!this.socket) return;

        this.socket.onopen = () => {
            this.onConnect?.();
        };

        this.socket.onerror = () => {
            this.close(4001, 'websocket error');
        };

        this.socket.onclose = () => {
            this.onClose?.();
        };

        this.socket.onmessage = (e: MessageEvent) => {
            const data: any = JSON.parse(e.data.toString());
            if (data.id) {
                this.idAwaiter[data.id]?.(data);
            } else {
                this.notificationSubscriber[data.method]?.(data);
            }
        };
    }
}
